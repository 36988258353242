import * as yup from "yup";
import { Box, Button, FormControlLabel, Paper, Switch, Typography } from "@mui/material";
import ProcessForm from "./ProcessForm";
import { save, getById, findByCode } from "../../../services/process"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useList as useProcessAttachmentType } from "../../../services/process-attachment-type"
import { useList as useCenters } from "../../../services/centers"
import { getPartnersFromConsolidation, useList as usePartners } from "../../../services/partner"
import { useList as useCarriers } from "../../../services/carrier"
import { useList as useTransportationMode } from "../../../services/transportationMode"
import { useList as useServiceLevel } from "../../../services/serviceLevel"
import { useList as useAirports } from "../../../services/airport"
import { useList as useCustomRegimes } from "../../../services/customRegime"
import { useList as useCargoTreatment } from "../../../services/cargoTraitment"
import { useList as useCurrencies } from "../../../services/currencies"
import { useList as useCustomChannels } from "../../../services/customChannel"
import { useList as useContainerTypes } from "../../../services/containerType"
import { useEffect, useMemo, useRef, useState } from "react";
import { getLocal, getToken } from "../../../storage/auth";
import { PartnerType } from "../../../types/partner";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ProcessFileAttach from "./ProcessFileAttach";
import ProcessInvoice from "./ProcessInvoice";
import ProcessOthers from "./ProcessOthers";
import ProcessShipment from "./ProcessShipment";
import ProcessNotes from "./ProcessNotes";
import { Process as BoardingProcess, BoardingProcessFormData } from "../../../types/process";
import { enqueueSnackbar } from "notistack";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { Warehouse } from "../../../types/warehouse";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { InvoiceStatus } from "../../../types/invoice";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ComboBoxType } from "../../../types/general";
import ProcessEvents from "./ProcessEvents";
import { AttachmentProcessFile } from "../../../types/attachment-process";
interface IParams {
  ID_TIPO_PARCEIRO?: string;
  ATIVO?: boolean;
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  url: string;
  param: string;
}

export interface IPartnerParams {
  ID_TIPO_PARCEIRO: string;
  SIGLA_CENTRO?: string;
}

const nullIfEmptyString = (value: string | number) => (value === '' ? null : value);
const zeroIfEmpty = (value: string | number) => (value === '' ? 0 : value);

const processScheme = yup.object({
  CODIGO_PROCESSO: yup.string(),
  SIGLA_PLANTA_CONSOLID: yup.string().required(),
  CODIGO_HOUSE_HAWB: yup.string().required(),
  CODIGO_MAWB: yup.string(),
  DATA_HAWB: yup.string().required(),
  DATA_ETD: yup.string().nullable(),
  DATA_ETA: yup.string().nullable(),
  DATA_ATA: yup.string().nullable(),
  ID_PARCEIRO_AGENTE_CARGAS: yup.number().required(),
  ID_MODAL: yup.string().required(),
  ID_TRANSPORTADOR: yup.number().required(),
  ID_PRIORIDADE: yup.number(),
  ID_AEROPORTO_ORIGEM: yup.number().required(),
  ID_AEROPORTO_DESTINO: yup.number().required(),
  TOTAL_PECAS: yup.number().required(),
  PESO_BRUTO: yup.mixed<string | number>().required().transform(value => (value && typeof value === 'string' ? value.replace(',', '.') : value)),
  PESO_CUBICO: yup.mixed<string | number>().required().transform(value => (value && typeof value === 'string' ? value?.replace(',', '.') : value)),
  ID_REGIME: yup.number().required(),
  ID_PARCEIRO_BROKER: yup.number().required(),
  ID_MOEDA: yup.number().optional(),
  VALOR_COLETA: yup.mixed<string | number>().transform(zeroIfEmpty).transform(value => (value && typeof value === 'string' ? value?.replace(',', '.') : value)),
  VALOR_PREPAID: yup.mixed<string | number>().transform(zeroIfEmpty).transform(value => (value && typeof value === 'string' ? value?.replace(',', '.') : value)),
  CUSTOM_AUDIT: yup.boolean(),
  DIVERG_PROC: yup.boolean(),
  DSC_DESEMBARACO_DI: yup.string().nullable().transform(nullIfEmptyString),
  DATA_REG_DESEMBARACO_DI: yup.string().nullable().transform(nullIfEmptyString),
  DATA_EXONERACAO_ICMS: yup.string().nullable().transform(nullIfEmptyString),
  DATA_DESEMBARACO_DI: yup.string().nullable().transform(nullIfEmptyString),
  DATA_RECEBIMENTO_PORTARIA: yup.string().nullable().transform(nullIfEmptyString),
  ID_CANAL: yup.mixed<string | number>().nullable().transform(nullIfEmptyString),
  NUMERO_VOO: yup.string().nullable().transform(nullIfEmptyString),
  NOME_NAVIO: yup.string().nullable().transform(nullIfEmptyString),
  CODIGO_VIAGEM_NAVIO: yup.string().nullable().transform(nullIfEmptyString),
  ID_TIPO_CONTAINER: yup.mixed<string | number>().nullable().transform(nullIfEmptyString),
  NUM_CONTEINER: yup.string().nullable().transform(nullIfEmptyString),
  DSC_FREE_TIME: yup.string().nullable().transform(nullIfEmptyString),
  DATA_SAIDA_CAMINHAO: yup.string().nullable().transform(nullIfEmptyString),
  boardingProcessAttachments: yup.array().of(
    yup.object({
      TIPO_ANEXO: yup.string(),
      FILE: yup.mixed().nullable().optional()
    })
  ).required(),
  boardingProcessAttachmentsToDelete: yup.array().of(yup.object()),
});

export default function Process() {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const [token, setToken] = useState("");
  const { id: idBoardingProcess } = useParams<{ id: number | any }>();

  const [freightForwarderParams, setFreightForwarderParams] = useState<IPartnerParams>({
    ID_TIPO_PARCEIRO: PartnerType.FREIGHT_FORWARDER,
  } as IPartnerParams);
  const [userValidation, setUserValidation] = useState<{ id: number }>({} as { id: number });
  const [brokerParams, setBrokerParams] = useState<IPartnerParams>({
    ID_TIPO_PARCEIRO: PartnerType.BROKER,
  } as IPartnerParams);
  const [customRegimeParams, setCustomRegimeParams] = useState<IParams>({
    page: 1,
    limit: 100,
    url: `findPaginate`,
  } as IParams);
  const [defaultParams] = useState<IParams>({
    page: 1,
    limit: 150,
  } as IParams);

  const [currencyParams] = useState<IParams>({
    ATIVO: true,
    page: 1,
    limit: 100,
  } as IParams);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [process, setProcess] = useState<BoardingProcess>({} as BoardingProcess);

  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [deletedInvoiceIds, setDeletedInvoiceIds] = useState<number[]>([]);
  const { boardingProcessAttachmentType } = useProcessAttachmentType(defaultParams, token);
  const { combo: centers } = useCenters(undefined, true);
  const [freightForwarders, setFreightForwarders] = useState<PartnerType[]>([]);
  const [brokers, setBrokers] = useState<PartnerType[]>([]);
  // const { dataFromConsolidation: freightForwarders } = usePartners(freightForwarderParams, token);
  // const { dataFromConsolidation: brokers } = usePartners(brokersParams, token);
  const { data: carriers } = useCarriers(defaultParams, token);
  const { combo: transportationMode } = useTransportationMode(token);
  const { data: serviceLevels } = useServiceLevel(defaultParams, token);
  const { data: airports } = useAirports(defaultParams, token);

  const { data: customRegimes } = useCustomRegimes(customRegimeParams, token);

  const { data: cargoTreatments } = useCargoTreatment(defaultParams, token);
  const { data: currencies } = useCurrencies(currencyParams, token);
  const { data: customChannels } = useCustomChannels(defaultParams, token);
  const { data: containerTypes } = useContainerTypes(defaultParams, token);

  const {
    register,
    formState: { errors: formErrors },
    reset,
    control,
    watch,
    setValue,
    handleSubmit
  } = useForm({
    resolver: yupResolver(processScheme),
  });

  const processCodeFormRef = useRef<HTMLInputElement | null>(null);
  const { ref: processCodeReactHookFormRef, ...processCodeRest } = register("CODIGO_PROCESSO");

  const centerFormRef = useRef<HTMLInputElement | null>(null);
  const { ref: centerReactHookFormRef, ...centerRest } = register("SIGLA_PLANTA_CONSOLID");

  const dataETDRef = useRef<HTMLInputElement | null>(null);
  const freightForwarderRef = useRef<HTMLInputElement | null>(null);
  const serviceLevelRef = useRef<HTMLInputElement | null>(null);

  const [disabledByInvoiceCentroField, disabledByInvoiceAllFields] = useMemo(() => {
    const disabled1 = process?.warehouses?.some((item: Warehouse) => {
      return item?.invoices?.some(({ ID_STATUS_INVOICE }) =>
        ([InvoiceStatus.INTERNATIONAL_TRANSIT, InvoiceStatus.CUSTOMS_CLEARANCE].includes(ID_STATUS_INVOICE)) ||
        (ID_STATUS_INVOICE === InvoiceStatus.DOMESTIC_TRANSIT && !process.DATA_RECEBIMENTO_PORTARIA));
    });

    const disabled2 = process?.warehouses?.some((item: Warehouse) => {
      return item?.invoices?.some(({ ID_STATUS_INVOICE }) =>
        ([InvoiceStatus.FINALIZED].includes(ID_STATUS_INVOICE)) ||
        (ID_STATUS_INVOICE === InvoiceStatus.DOMESTIC_TRANSIT && process.DATA_RECEBIMENTO_PORTARIA));
    });

    return [disabled1, disabled2];
  }, [process?.warehouses]);

  useEffect(() => {
    const getFreightForwarders = async () => {
      const result = await getPartnersFromConsolidation(freightForwarderParams);
      setFreightForwarders(result);
    }

    getFreightForwarders();
  }, [freightForwarderParams])


  useEffect(() => {
    const getBrokers = async () => {
      const result = await getPartnersFromConsolidation(brokerParams);
      setBrokers(result);
    }

    getBrokers();
  }, [brokerParams])

  const onFormSubmit = async (boardingProcessData: BoardingProcessFormData) => {
    const oldBoardingProcessAttachments = boardingProcessData.boardingProcessAttachments.map((attachment) => ({...attachment}));
    try {
      setIsLoading(true);
      const {
        ID_PROCESSO,
        DATA_ATA,
        DATA_ETA,
        DATA_ETD,
        DATA_HAWB,
        DATA_DESEMBARACO_DI,
        DATA_EXONERACAO_ICMS,
        DATA_RECEBIMENTO_PORTARIA,
        DATA_REG_DESEMBARACO_DI,
        DATA_SAIDA_CAMINHAO,
        PESO_BRUTO,
        PESO_CUBICO,
        VALOR_COLETA,
        VALOR_PREPAID,
        SIGLA_PLANTA_CONSOLID,
        attachmentsProcesses,
        ...boardingProcessWithoutDate
      } = boardingProcessData;

      const boardingProcess: BoardingProcess = {
        ID_PROCESSO: ID_PROCESSO ? ID_PROCESSO : null,
        SIGLA_PLANTA_CONSOLID,
        DATA_ATA: !!DATA_ATA ? new Date(DATA_ATA) : null,
        DATA_ETA: !!DATA_ETA ? new Date(DATA_ETA) : null,
        DATA_ETD: !!DATA_ETD ? new Date(DATA_ETD) : null,
        DATA_HAWB: !!DATA_HAWB ? new Date(DATA_HAWB) : null,
        DATA_EXONERACAO_ICMS: DATA_EXONERACAO_ICMS ? new Date(DATA_EXONERACAO_ICMS) : null,
        DATA_DESEMBARACO_DI: DATA_DESEMBARACO_DI ? new Date(DATA_DESEMBARACO_DI) : null,
        DATA_RECEBIMENTO_PORTARIA: DATA_RECEBIMENTO_PORTARIA ? new Date(DATA_RECEBIMENTO_PORTARIA) : null,
        DATA_REG_DESEMBARACO_DI: DATA_REG_DESEMBARACO_DI ? new Date(DATA_REG_DESEMBARACO_DI) : null,
        ID_LOGIN_USUARIO_CADASTRO: userValidation.id,
        DATA_SAIDA_CAMINHAO: DATA_SAIDA_CAMINHAO ? new Date(DATA_SAIDA_CAMINHAO) : null,
        PESO_BRUTO: PESO_BRUTO ? parseFloat(PESO_BRUTO as string) : 0,
        PESO_CUBICO: PESO_CUBICO ? parseFloat(PESO_CUBICO as string) : 0,
        VALOR_COLETA: VALOR_COLETA ? parseFloat(VALOR_COLETA as string) : 0,
        VALOR_PREPAID: VALOR_PREPAID ? parseFloat(VALOR_PREPAID as string) : 0,
        ...boardingProcessWithoutDate
      };

      let invoicesIds: number[] = [];
      for (const warehouse of warehouses) {
        for (const invoice of warehouse.invoices) {
          invoicesIds.push(invoice.ID_INVOICE!);
        }
      }

      boardingProcessData.boardingProcessAttachments = boardingProcessData.boardingProcessAttachments.reduce((previous, rawCurrent) => {
        const current = { ...rawCurrent };
        if ('url' in current) delete current.url;
        if (!('isNewFile' in current)) return previous;
        delete current.isNewFile;
        delete rawCurrent.isNewFile;
        previous.push(current);
        return previous;
      }, [] as AttachmentProcessFile[]);

      const payload = {
        boardingProcess,
        boardingProcessAttachments: boardingProcessData.boardingProcessAttachments,
        boardingProcessAttachmentsToDelete: boardingProcessData.boardingProcessAttachmentsToDelete,
        invoicesIdsToAssociate: invoicesIds,
        invoicesIdsToDelete: deletedInvoiceIds,
      }

      const response = await save(payload);

      if (!response.success) {
        enqueueSnackbar(response.message[0], { variant: "error" });
        boardingProcessData.boardingProcessAttachments = oldBoardingProcessAttachments;
        setValue('boardingProcessAttachments', oldBoardingProcessAttachments);
        return;
      }

      enqueueSnackbar("Boarding Process saved successfully", { variant: "success" });

      setTimeout(() => {
        navigate(`/dashboard`);
      }, 100);
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSearchProcessByCode = async  (processCode?: string) => {
    if (!processCode) {
      enqueueSnackbar("Type an valid Process Code", { variant: "error" });
      return;
    }

    try {
      await setIsLoading(true);

      await reset();
      await setProcess(({} as BoardingProcess));

      const response = await findByCode(processCode);

      if (!response.success) {
        enqueueSnackbar(response.message[0], { variant: "error" });
        return
      }

      await setProcess(response.object);
      setWarehouses(response.object.warehouses);
      urlSearchParams.set('ID', response.object.ID_PROCESSO);
      console.log({urlSearchParams});
      setUrlSearchParams(new URLSearchParams(urlSearchParams));
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error to find process by code", { variant: "error" })
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async (boardingProcessId: number) => {
    const data = await getById(boardingProcessId);
    handleSearchProcessByCode(data?.CODIGO_PROCESSO);
  }

  useEffect(() => {
    const idSearchParams = urlSearchParams.get('ID');
    if (idBoardingProcess || idSearchParams) fetchData(idBoardingProcess || idSearchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idBoardingProcess]);

  useEffect(() => {
    return reset(process);
  }, [process, reset]);

  const getTokenSession = async () => {
    const getUser = (await getLocal("userInfo")) as any;
    const token = getToken();

    if (token) {
      setToken(token);
    }

    setUserValidation(JSON.parse(getUser));
  };

  useEffect(() => {
    getTokenSession();
  }, []);

  const isDataLoading = useMemo(() => {
    return (
      centers === undefined ||
      brokers === undefined ||
      airports === undefined ||
      carriers === undefined ||
      freightForwarders === undefined ||
      currencies === undefined ||
      cargoTreatments === undefined ||
      transportationMode === undefined ||
      customChannels === undefined
    )
  }, [
    centers,
    brokers,
    airports,
    carriers,
    freightForwarders,
    currencies,
    cargoTreatments,
    transportationMode,
    customChannels
  ]);

  const onCenterSelection = (SIGLA_PLANTA_CONSOLID: string) => {
    // const ID_CENTRO = centers
    //   ?.filter((item: ComboBoxType) => item.description.substring(7, 10).indexOf(SIGLA_PLANTA_CONSOLID) > -1)
    //   ?.map((item: ComboBoxType) => item.id)
    //   ?.map((id: number) => `ID_CENTRO[]=${id}`)
    //   ?.join('&');

    setFreightForwarderParams({
      ...freightForwarderParams,
      SIGLA_CENTRO: SIGLA_PLANTA_CONSOLID,
    });

    const { url: customRegimeUrl, ...dataCustomRegime } = customRegimeParams;
    setCustomRegimeParams({
      url: `findPaginate`,
      ...dataCustomRegime
    });

    setBrokerParams({
      ...brokerParams,
      SIGLA_CENTRO: SIGLA_PLANTA_CONSOLID,
    });
  }

  const formatAllacronymPlantConsolidationFromCenters = (centers: any[]) => {
    if (!centers) return [];
    return Array.from(
      new Map(
        centers.map(({ description, ...center }) => {
          const acronymPlantConsolidation = description.substring(7, 10);
          return [acronymPlantConsolidation, {
            ...center,
            description: description.substring(7, 10)
          }];
        })
      ).values()
    )
      .map(({ description }: ComboBoxType) => ({ id: description, description }))
  }

  useEffect(() => {
    if (process?.SIGLA_PLANTA_CONSOLID) {
      onCenterSelection(process?.SIGLA_PLANTA_CONSOLID);
    }
  }, [process?.SIGLA_PLANTA_CONSOLID]);

  return (
    <Box width="100%" marginTop={4}>
      {(isDataLoading || isLoading) && (
        <LoadingOverlay isLoading={true} />
      )}
      <Typography style={{ paddingBottom: 15 }}>
        <strong>Process</strong>
      </Typography>
      <Box width="100%">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Paper style={{ padding: 40 }}>
            <ProcessForm
              process={process}
              centers={formatAllacronymPlantConsolidationFromCenters(centers)}
              carriers={carriers}
              freightForwarders={freightForwarders}
              transportationModes={transportationMode}
              serviceLevels={serviceLevels}
              airports={airports}
              customRegimes={customRegimes}
              cargoTreatments={cargoTreatments}
              brokers={brokers}
              currencies={currencies}
              onCenterSelection={onCenterSelection}
              register={register}
              formErrors={formErrors}
              processCode={watch('CODIGO_PROCESSO', '')}
              control={control}
              handleProcessSearchByCode={handleSearchProcessByCode}
              handleSubmit={handleSubmit}
              processCodeRef={processCodeFormRef}
              processCodeReactHookFormRef={processCodeReactHookFormRef}
              processCodeRest={processCodeRest}
              centerRef={centerFormRef}
              centerReactHookFormRef={centerReactHookFormRef}
              centerRest={centerRest}
              dataETDRef={dataETDRef}
              freightForwarderRef={freightForwarderRef}
              serviceLevelRef={serviceLevelRef}
              disabledByInvoiceAllFields={disabledByInvoiceAllFields}
              disabledByInvoiceCentroField={disabledByInvoiceCentroField}
            />
          </Paper>
          <ProcessFileAttach
            processAttachmentTypes={boardingProcessAttachmentType}
            boardingProcessAttachments={process.attachmentsProcesses}
            setValue={setValue}
            control={control}
            watch={watch}
            formErrors={formErrors}
            hasDisabledFields={disabledByInvoiceAllFields}
          />
          <ProcessInvoice
            warehouses={warehouses}
            setWarehouses={setWarehouses}
            deletedInvoiceIds={deletedInvoiceIds}
            setDeletedInvoiceIds={setDeletedInvoiceIds}
            centerRef={centerFormRef}
            dataETDRef={dataETDRef}
            freightForwaderRef={freightForwarderRef}
            serviceLevelRef={serviceLevelRef}
            centers={centers}
            watch={watch}
            disabledByInvoiceAllFields={disabledByInvoiceAllFields}
          />
          <ProcessOthers
            process={process}
            control={control}
            register={register}
            formErrors={formErrors}
            customChannels={customChannels}
            disabledByInvoiceAllFields={disabledByInvoiceAllFields}
          />
          <ProcessShipment
            process={process}
            control={control}
            register={register}
            formErrors={formErrors}
            containerType={containerTypes}
            disabledByInvoiceAllFields={disabledByInvoiceAllFields}
          />
          {
            process?.ID_PROCESSO &&
            <ProcessNotes idProcess={process?.ID_PROCESSO} />
          }
          <ProcessEvents events={process.eventsProcesses || []} />
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            marginTop={7}
          >
            <Button
              fullWidth
              variant="contained"
              style={{ backgroundColor: "gray", maxWidth: "13%" }}
              onClick={() => navigate(`/dashboard`)}
            >
              Return
            </Button>
            <Box
              display="flex"
              style={{ width: "50%" }}
              gap={4}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Box>
                <Typography fontSize={13}>Customs Audit</Typography>
                <Controller
                  render={({ field: { value } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!value}
                          inputProps={{ "aria-label": "controlled" }}
                          {...register("CUSTOM_AUDIT")}
                          disabled={disabledByInvoiceAllFields}
                        />
                      }
                      label=""
                      labelPlacement="start"
                    />
                  )}
                  name="CUSTOM_AUDIT"
                  control={control}
                />
              </Box>
              {/* <Box>
                <Typography fontSize={13}>Resend E-mail</Typography>
                <Switch />
              </Box> */}
              <Box>
                <Typography fontSize={13}>Discrepancy</Typography>
                <Controller
                  render={({ field: { value } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!value}
                          inputProps={{ "aria-label": "controlled" }}
                          {...register("DIVERG_PROC")}
                          disabled={disabledByInvoiceAllFields}
                        />
                      }
                      label=""
                      labelPlacement="start"
                    />
                  )}
                  name="DIVERG_PROC"
                  control={control}
                />
              </Box>
              {!disabledByInvoiceAllFields && (
                <Button
                  onClick={handleSubmit(onFormSubmit)}
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: "primary", maxWidth: "25%" }}
                >
                  Save
                </Button>
              )}
            </Box>
          </Box>
        </LocalizationProvider>
      </Box>
    </Box>
  )
}