import { hasClaim } from "../../storage/userClaims";

const getRoutes = (menuList: any) => {
  let routes: Array<Object> = [];

  const extractRoutes = (items: any) => {
    items.forEach((item: any) => {
      if (item.route) {
        routes.push(
          { route: item.route, claims: item.claims }
        );
      }
      if (item.items) {
        extractRoutes(item.items);
      }
    });
  };

  extractRoutes(menuList);
  return routes;
};

const allowAccessThisRoute = (menuList: any, pathName: string) => {
  let pathNameCleaned = pathName;
  if(pathNameCleaned.endsWith('/')) {
    pathNameCleaned = pathNameCleaned.slice(0,-1)
  }
  let isAllowed = true;
  const listOfRoutesWithClaims = getRoutes(menuList);
  const foundedRoute = listOfRoutesWithClaims
    .find((routeString) => routeString.route === pathNameCleaned);
  if(foundedRoute && foundedRoute.claims) {
    isAllowed = false;
    foundedRoute.claims.forEach((claim: string) => {
      if(hasClaim(claim)) {
        isAllowed = true;
      }
    });
  }
  return isAllowed
}

export {
  getRoutes,
  allowAccessThisRoute,
}