import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import DataTableDefault from "../../../../../components/DataTable";
import { LogEmailType } from "../../../../../types/logEmail";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Paper, Typography } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useState } from "react";
import { findEmailLogs } from "../../../../../services/logEmail";
import { formatDatetime } from "../../../../utils/date";

interface DataTableProps {
  data: LogEmailType[];
  [x: string]: any;
}
export default function DataTable({
  data,
  ...props
}: DataTableProps) {
  const [dialogDetail, setDialogDetail] = useState(false);
  const [dialogDetailContent, setDialogDetailContent] = useState('');

  const onView = async (id: number) => {
    setDialogDetail(true);
    const response = await findEmailLogs(id)
    setDialogDetailContent(response)
  }

  const renderMenus = (row: any) => {
    return [
      <GridActionsCellItem
        key={`view-${row.ID_EMAIL_LOG}`}
        icon={<VisibilityIcon />}
        label="View"
        onClick={() => onView(row.ID_EMAIL_LOG)}
        color="inherit"
        data-testid="view-button"
      />,
    ];
  };

  const columns: GridColDef[] = [
    {
      field: "centro",
      headerName: "Company/Plant",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 300,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `${params.row.centro.empresa.SIGLA_EMPRESA} - ${params.row.centro.DSC_SIGLA} - ${params.row.centro.NOME_CENTRO}`;
      },
    },
    {
      field: "tipoEmail.DSC_TIPO",
      headerName: "Email Type",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 220,
      renderCell: (params: GridRenderCellParams<any>) => {
        return params.row.tipoEmail.DSC_TIPO;
      },
    },
    {
      field: "DATA_HORA_ENVIO",
      headerClassName: "super-app-theme--header",
      headerName: "Date",
      flex: 1,
      minWidth: 220,
      renderCell: (params: GridRenderCellParams<any>) => {
        return formatDatetime(params.row.DATA_HORA_ENVIO);
      },
    },
    {
      field: "DSC_ASSUNTO",
      headerClassName: "super-app-theme--header",
      headerName: "Subject",
      flex: 1,
      minWidth: 500,
    },
    {
      field: "NOME_ARQUIVO_ANEXO",
      headerClassName: "super-app-theme--header",
      headerName: "Attach",
      flex: 1,
      minWidth: 230,
    },
    {
      field: "loginUsuario",
      headerClassName: "super-app-theme--header",
      headerName: "Login",
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<any>) => {
        return params.row.loginUsuario.DSC_LOGIN;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 150,
      getActions: ({ row }) => renderMenus(row),
    },
  ];
  return (
    <>
      <DataTableDefault
        rowId={data.map((row) => row.ID_LOGIN_USUARIO)}
        data-testid="log-email-data-table"
        data={data}
        columns={columns}
        {...props}
      />
      <Dialog
        open={dialogDetail}
        onClose={() => setDialogDetail(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='lg'
      >
        <DialogContent>
          <DialogTitle id="alert-dialog-title">Email</DialogTitle>
          <DialogContentText id="alert-dialog-description">
            <Paper sx={{ padding: 3, minWidth: '480px', wordBreak: 'break-all' }}>
              <Typography><strong>Sent: </strong>{ formatDatetime(dialogDetailContent?.DATA_HORA_ENVIO) }</Typography>
              <Typography><strong>To: </strong>{ dialogDetailContent?.DSC_EMAIL_TO }</Typography>
              <Typography><strong>CC: </strong>{ dialogDetailContent?.DSC_EMAIL_CC }</Typography>
              <Typography marginBottom={4}><strong>Subject: </strong>{ dialogDetailContent?.DSC_ASSUNTO }</Typography>
              <div dangerouslySetInnerHTML={{ __html: dialogDetailContent?.DSC_MENSAGEM }} />
              {dialogDetailContent?.NOME_ARQUIVO_ANEXO && (
                <Typography marginTop={4}>
                  <strong>Attach: </strong>{ dialogDetailContent?.NOME_ARQUIVO_ANEXO }
                </Typography>
              )}
            </Paper> 
            <Button
              sx={{ marginTop: '14px' }}
              variant="contained"
              startIcon={<ArrowBackIosIcon />}
              onClick={() => setDialogDetail(false)}
            >
              Return
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
