import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { hasClaim } from "../../../../../storage/userClaims";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTableDefault from "../../../../../components/DataTable";

interface DataTableProps {
  onEditAccessControl: (id: number | string) => void;
  onDeleteAccessControl: (id: number | string) => void;
  data: any[];
  [x: string]: any;
}
export default function DataTable({
  onEditAccessControl,
  onDeleteAccessControl,
  onView,
  data,
  ...props
}: DataTableProps) {
  const columns: GridColDef[] = [
    {
      field: "DSC_WEBGROUP",
      headerName: "WEBGROUP",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            sx={{
              display:
                hasClaim("Access Control - Maintenance") ||
                hasClaim("Access Control - Visualization")
                  ? "block"
                  : "none",
            }}
            key={`view-${row.ID_AEROPORTO}`}
            icon={<VisibilityIcon />}
            label="View"
            onClick={() => onView(row.ID_WEBGROUP)}
            color="inherit"
            data-testid="view-button"
          />,
          <GridActionsCellItem
            key={`edit-${row.ID_PAIS}`}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => onEditAccessControl(row.ID_WEBGROUP)}
            color="inherit"
            data-testid="edit-button"
          />,
          <GridActionsCellItem
            key={`delete-${row.ID_WEBGROUP}`}
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => onDeleteAccessControl(row.ID_WEBGROUP)}
            color="inherit"
            data-testid="delete-button"
          />,
        ];
      },
    },
  ];
  return (
    <DataTableDefault
        rowId={data.map((row) => row.ID_WEBGROUP)}
        data-testid="custom-regime-data-table"
        data={data}
        columns={columns}
        {...props}
      />
  );
}
