import { api } from "../../lib/api";
import { getToken } from "../../storage/auth";

const entity = "translations";

export const findTranslations = async (): Promise<any> => {
  const token = await getToken();
  const { data } = await api.get(`/${entity}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const findFieldsToHide = async (): Promise<any> => {
  const token = await getToken();
  const { data } = await api.get(`/customReports/hiddenFields`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const findFieldsRelations = async (): Promise<any> => {
  const token = await getToken();
  const { data } = await api.get(`/reports/dynamicReport/fieldsRelations`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};