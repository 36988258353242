import { useCallback, useEffect, useState } from "react";

import { api } from "../../lib/api";
import { Country } from "../../types/country";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";
import { Combo } from "../../types/combo";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
  status?: boolean;
}

const entity = "countries";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  isCombo?: boolean,
  initialToken?: string
): Promise<{ data: Country[]; meta: List; isLoading: boolean, combo: Combo[] }> | any => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Country[]>([{} as Country]);
  const [meta, setMeta] = useState<List>({} as List);
  const [token, setToken] = useState<string | null>(initialToken || null);
  const [combo, setCombo] = useState<Combo[]>();

  const getTokenSession = () => {
    const getTokens = getToken();
    setToken(getTokens);
  };
  
  useEffect(() => {
    getTokenSession();
  }, []);

  const render = useCallback(() => {
    if (token) {
      if (isCombo === false || isCombo === undefined) {
        return "paginate";
      }
    } else {
      if (isCombo === true) {
        return "conbo";
      }
    }
  }, [isCombo, token]);

  useEffect(() => {
    if (render() === "paginate") {
      (async function () {
        setIsLoading(true);
        const response = await dataTableUtils.findPaginate(params);
        const {total, data, currentPage, totalPages, limit} = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
        setIsLoading(false)
      })();
    }
  }, [isCombo, params, token]);

  useEffect(() => {
    if (render() === "conbo") {
      setIsLoading(true);
      api
        .get(`/${entity}/getCombo`, {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const { data } = response;
          setCombo(data);
        })
        .finally(() => setIsLoading(false));
    }
  }, [isCombo, params, token]);

  return { data, meta, isLoading, combo };
};

export const update = async (
  id: number | string,
  country?: Country
): Promise<Country> => {
  const token = getToken();
  const { data } = await api.patch(`/${entity}/${id}`, country, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};
export const deleteCountry = async (id: number | string): Promise<Country> => {
  const token = getToken();
  const { data } = await api.delete(`/${entity}/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const create = async (country?: Country): Promise<Country> => {
  const token = getToken();
  const { data } = await api.post(`/${entity}`, country, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const isInUse = async (id: number | string): Promise<any> => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/isInUse/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const getCountries = async (): Promise<any> => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/getCombo`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};


export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: "Id", value: "ID_PAIS" },
    { label: "Abbreviation", value: "SIGLA_PAIS" },
    { label: "Description", value: "NOME_PAIS" },
    {
      label: "Status",
      value: (row: any) => (row.ATIVO ? "Active" : "Inactive"),
    },
  ]
  await dataTableUtils.exportAll({params, xlsxData, filename: "Country"});
};
