import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";
import { LogEmailType } from "../../types/logEmail";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
}

const entity = "emailLogs";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string,
  isCombo?: boolean
):
  | Promise<{
      data: LogEmailType[];
      meta: List;
      error: Error;
      isLoading: boolean;
    }>
  | any => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<LogEmailType[]>([]);
  const [meta, setMeta] = useState<List>({} as List);
  const [error] = useState<Error>({} as Error);

  const render = () => {
    if (token) {
      if (isCombo === false || isCombo === undefined) {
        return "pagination";
      }
    }
  };

  useEffect(() => {
    if (render() === "pagination") {
      (async function () {
        setIsLoading(true);
        const response = await dataTableUtils.findPaginate(params);
        const {total, data, currentPage, totalPages, limit} = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
        setIsLoading(false)
      })();
    }
  }, [params, token]);

  return {
    data,
    meta,
    error,
    isLoading,
  };
};

export const findEmailLogs = async (id: number) => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return data;
};

export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: "Company", value: "centro.empresa.SIGLA_EMPRESA" },
    { label: "Center", value: "centro.DSC_SIGLA" },
    { label: "Center abbreviation", value: "centro.NOME_CENTRO" },
    { label: "Email Type", value: "tipoEmail.DSC_TIPO" },
    { label: "Date", value: "DATA_HORA_ENVIO" },
    { label: "Subject", value: "DSC_ASSUNTO" },
    { label: "Attach", value: "NOME_ARQUIVO_ANEXO" },
    { label: "Login", value: "loginUsuario.DSC_LOGIN" },
    {
      label: "Status",
      value: (row: any) => (row.ATIVO ? "Active" : "Inactive"),
    },
  ]
  await dataTableUtils.exportAll({params, xlsxData, filename: "Log Email"});
};

export const findCenterCombo = async () => {
  const token = getToken();
  const response =  await api.get(`/centers/getCentersWithCompaniesCombo`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return response.data
}

export const findEmailTypeCombo = async () => {
  const token = getToken();
  const response =  await api.get(`/emailTypes/getCombo`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return response.data
}