import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { Country } from "../../../../../types/country";

let countryScheme = yup.object({
  SIGLA_PAIS: yup.string().max(3).required(),
  NOME_PAIS: yup.string().max(100).required(),
  ATIVO: yup.boolean().notRequired(),
});

interface CountryFormProps {
  country: Country;
  onSubmit: (country: Country) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly?: boolean;
}

export default function CountryForm({
  country,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  controlEdit,
  readOnly,
}: CountryFormProps) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors: formErrors },
    reset,
  } = useForm({
    resolver: yupResolver(countryScheme),
  });

  useEffect(() => {
    reset(country);
  }, [country, reset]);

  const [erroMap, setErroMap] = useState<string[]>([]);

  useEffect(() => {
    setErroMap(errors);
  }, [errors]);

  const handleFormSubmit = (newCountry: any) => {
    setErroMap([]);
    onSubmit(newCountry);
  };

  const handleCancel = () => {
    setErroMap([]);

    reset({ SIGLA_PAIS: "", NOME_PAIS: "", ATIVO: true });
    onCancel();
  };

  return (
    <>
      <form
        style={{ width: "100%" }}
        onSubmit={handleSubmit(handleFormSubmit)}
        data-testid="country-form"
      >
        {erroMap.map((error) => (
          <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
            {error}
          </Alert>
        ))}
        <Grid
          data-testid="search"
          container
          spacing={2}
          marginTop={1}
          border={1}
          padding={2}
          borderColor={"#7b7b7b"}
          borderRadius={2}
          alignItems="center"
        >
          {controlEdit.loading ? (
            <>loading</>
          ) : (
            <Grid container spacing={2}>
              <Grid item md={2} xs={4}>
                <TextField
                  fullWidth
                  label="Abbreviation"
                  variant="outlined"
                  disabled={controlEdit.isInUse || readOnly}
                  size="small"
                  {...register("SIGLA_PAIS")}
                  error={!!formErrors.SIGLA_PAIS}
                  inputProps={{ maxLength: 3, 'data-testid': 'abbreviation-input' }}
                />
              </Grid>
              <Grid item md={8} xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  variant="outlined"
                  disabled={ !!(controlEdit.isInUse && country.NOME_PAIS)  || readOnly}
                  size="small"
                  {...register("NOME_PAIS")}
                  error={!!formErrors.NOME_PAIS}
                  inputProps={{ maxLength: 100, 'data-testid': "description-input" }}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  render={({ field: { value } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!value}
                          disabled={readOnly}
                          inputProps={{ "aria-label": "controlled" }}
                          {...register("ATIVO")}
                        />
                      }
                      labelPlacement="start"
                      label="Status"
                    />
                  )}
                  name="ATIVO"
                  control={control}
                />
              </Grid>
            </Grid>
          )}

          <Grid container justifyContent="flex-end" spacing={2} marginTop={2}>
            <Grid>
              <Button
                variant="outlined"
                onClick={handleCancel}
                disabled={isLoading}
                data-testid="cancel-button"
              >
                Cancel
              </Button>
            </Grid>
            {!readOnly ? (
            <Grid marginLeft={2}>
              <Button
                variant="contained"
                disabled={isLoading}
                type="submit"
                data-testid="submit-button"
              >
                Confirm
              </Button>
            </Grid>
            ) : null}
          </Grid>
        </Grid>
      </form>
    </>
  );
}
