import { api } from "../../lib/api";
import { getToken } from "../../storage/auth";

const entity = "invoice-attachments";

export const downloadInvoiceAttachment = async (
    id: number
): Promise<Buffer> => {
  const token = getToken();
  const response = await api.get(`/${entity}/${id}/download`, {
    headers: { authorization: `Bearer ${token}` },
    responseType: "arraybuffer",
  });

  return response.data;
};
