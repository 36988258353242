import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Invoice, InvoiceAttachment, InvoiceFormModel } from '../../../types/invoice';
import { GridCloseIcon } from '@mui/x-data-grid';
import { update } from '../../../services/invoice';
import dayjs from 'dayjs';
import { getPartnersCombo } from '../../../services/partner';
import { ComboBoxType } from '../../../types/general';
import { enqueueSnackbar } from 'notistack';
import LoadingOverlay from '../../../components/LoadingOverlay';
  
type TrackingDialogProps = {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  invoice: Invoice;
};

export const formatInvoiceData = (invoice: InvoiceFormModel): FormData => {
  const formData = new FormData();
  const fields = [
      'ID_INVOICE',
      'ID_CENTRO',
      'NUM_INVOICE',
      'DATA_INVOICE',
      'DATA_RECEBIMENTO',
      'ID_PO',
      'ID_TIPO_INVOICE',
      'ID_MOEDA',
      'VALOR_TOTAL',
      'VALOR_TAXAS_EXTRAS',
      'ID_FORNECEDOR',
      'CODIGO_MFIR_FORN',
      'NOME_FORNECEDOR',
      'DSC_ENDERECO1_FORNECEDOR',
      'DSC_ENDERECO2_FORNECEDOR',
      'DSC_CIDADE_FORNECEDOR',
      'DSC_ESTADO_FORNECEDOR',
      'CEP_FORNECEDOR',
      'ID_PAIS_FORNECEDOR',
      'SIGLA_PAIS_FORNECEDOR',
      'ID_INCOTERMS',
      'ID_INCOTERMS_COMPL',
      'DSC_INCOTERMS_COMPL',
      'ID_PRIORIDADE',
      'NUM_TRACKING',
      'ID_MODAL',
      'ID_PAIS_ORIGEM',
      'ID_REGIME',
      'ID_IMPORTADOR',
      'MFIR_IMPORTADOR',
      'NOME_IMPORT',
      'DSC_ENDERECO1_IMPORT',
      'DSC_CIDADE_IMPORT',
      'DSC_ESTADO_IMPORT',
      'CEP_IMPORT',
      'SIGLA_PAIS_IMPORT',
      'CNPJ_IMPORT',
      'DATA_REGISTRO',
      'CAD_AUTOMATICO',
      'ID_PARCEIRO_AGENTE_CARGA',
      'ID_PARCEIRO_DOMESTIC_CARRIER',
      'ID_PARCEIRO_BROKER',
      'ID_WAREHOUSE',
      'ID_STATUS_INVOICE',
      'ID_WAREHOUSE_OLD',
      'NUM_PESO_BRUTO',
      'ID_AUTORIZACAO_EMBARQUE',
      'ID_PROCESSO_EMBARQUE',
      'CODIGO_PROCESSO',
      'INTERCOMPANY',
      'DATA_ESTORNO_1A_CONF',
      'DSC_PEND',
      'DATA_ENC_PENDENCIA',
      'DATA_ENTRADA_PORTARIA',
      'ID_LOGIN_USUARIO',
      'hasDiscrepancy'
    ];
    

  fields.forEach((field: string) => {
    const value = invoice[field as keyof InvoiceFormModel];

    if (!value) {
      return;
    }

    formData.append(field, value.toString());
  });

  invoice?.invoiceAttachments?.forEach((invoiceAttachment: InvoiceAttachment, index: number) => {
    invoiceAttachment.FILE && formData.append("files", invoiceAttachment.FILE);
    invoiceAttachment.ID_INVOICE_ANEXO && formData.append(`invoiceAttachmentsTypes[${index}][ID_INVOICE_ANEXO]`, invoiceAttachment?.ID_INVOICE_ANEXO.toString());
    formData.append(`invoiceAttachmentsTypes[${index}][ID_TIPO_ANEXO]`, invoiceAttachment.ID_TIPO_ANEXO);
  });

  invoice?.invoiceAttachmentsToDelete?.forEach((invoiceAttachment: InvoiceAttachment, index: number) => {
    formData.append(`invoiceAttachmentsToDelete[${index}][ID_INVOICE_ANEXO]`, invoiceAttachment?.ID_INVOICE_ANEXO?.toString() ?? '');
    formData.append(`invoiceAttachmentsToDelete[${index}][ID_TIPO_ANEXO]`, invoiceAttachment.ID_TIPO_ANEXO);
  });

  return formData;
}

const TrackingDialog: FC<TrackingDialogProps> = ({ open, onClose, invoice, onUpdate }) => {
  const [newInvoice, setNewInvoice] = useState(invoice);
  const [partners, setPartners] = useState<ComboBoxType[]>([]);
  const [alertModal, setAlertModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true)
    try {
      const invoiceFormated = formatInvoiceData({
        ...newInvoice,
        DATA_INVOICE: dayjs(newInvoice.DATA_INVOICE).format("MM/DD/YYYY"),
        DATA_RECEBIMENTO: dayjs(newInvoice.DATA_RECEBIMENTO).format("MM/DD/YYYY"),
        VALOR_TOTAL: newInvoice.VALOR_TOTAL.toString(),
        VALOR_TAXAS_EXTRAS: newInvoice.VALOR_TAXAS_EXTRAS ? newInvoice.VALOR_TAXAS_EXTRAS?.toString() : '',
        NUM_PESO_BRUTO: newInvoice.NUM_PESO_BRUTO?.toString()
      })
      await update(invoiceFormated, true, true);
      enqueueSnackbar('Tracking saved successfully', { variant: 'success' })
      onClose()
      onUpdate()
    } catch (error) {
      enqueueSnackbar('Error saving tracking', { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  };

  useEffect(()  => {
      loadCombo()
  }, [])

  const loadCombo = async () => {
    const data = await getPartnersCombo('CARRIER', invoice.ID_CENTRO)
    setPartners(data)
  }
  
  return (
    <Grid marginTop={4} alignItems='center'>
      <LoadingOverlay isLoading={isLoading} />
      <Dialog
        open={alertModal}
        onClose={() => setAlertModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit Tracking</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {newInvoice.NUM_TRACKING || newInvoice.ID_PARCEIRO_DOMESTIC_CARRIER ? 'Are you sure that you want to save this tracking info?' : 'Fill at least one field to save'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {newInvoice.NUM_TRACKING || newInvoice.ID_PARCEIRO_DOMESTIC_CARRIER ? <>
            <Button onClick={() => setAlertModal(false)}>Cancel</Button>
            <Button onClick={handleSave}>Confirm</Button>
          </> : <Button onClick={() => setAlertModal(false)}>Back</Button>}
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={onClose} 
        aria-labelledby="alert-dialog-title" 
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='md'
        style={{padding:'10px'}}>
        <DialogTitle id="form-dialog-title" style={{padding: 7}}>
          <Grid container alignItems={"center"} width={"100%"}>
            <Grid style={{paddingLeft: 10}} sm={10}>Tracking</Grid>
            <Grid sm={2} container justifyContent="center">
              <IconButton
                edge="start"
                color="inherit"
                onClick={onClose}
                aria-label="close"
              >
                <GridCloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            padding: '15px 0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
            gap: '30px'
          }}
        >
          <TextField
            style={{width:'55%'}}
            autoFocus
            margin="dense"
            id="invoice-tracking-number"
            label="Tracking Number/ASN"
            type="text"
            value={newInvoice.NUM_TRACKING}
            onChange={(e) => {
              const { value, selectionStart } = e.target;
              const uppercasedValue = value.toUpperCase();
              setNewInvoice({ ...newInvoice, NUM_TRACKING: uppercasedValue });
              setTimeout(() => {
                e.target.setSelectionRange(selectionStart, selectionStart);
              }, 0);
            }}
          />
          <FormControl style={{width:'55%'}}>
            <InputLabel id="partner-input-label">
              Carrier
            </InputLabel>
            <Select
              labelId="partner-label"
              id="partner-select"
              label="Carrier"
              required
              value={newInvoice.ID_PARCEIRO_DOMESTIC_CARRIER}
              onChange={(e) => setNewInvoice({...newInvoice, ID_PARCEIRO_DOMESTIC_CARRIER: parseInt(e.target.value?.toString())})}
            >
              {partners.map((partner) => (
                <MenuItem
                  key={`partner-${partner.id}`}
                  value={partner.id}
                >
                  {partner.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions style={{padding: 20}}>
          <Button sx={{
              marginRight: "auto",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              color: "currentColor",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                color: "currentColor",
              },
              minWidth:100
            }} variant="contained" onClick={onClose} color="secondary">
            Return
          </Button>
          <Button onClick={() => {
            if (!newInvoice.NUM_TRACKING?.trim() || !newInvoice.ID_PARCEIRO_DOMESTIC_CARRIER) return enqueueSnackbar('Please complete all fields to proceed.', { variant: 'error' });
            setAlertModal(true)}
          } color="primary" variant="contained"
            sx={{
              minWidth:100
            }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default TrackingDialog;
  