import { api } from "../../lib/api";
import { getToken } from "../../storage/auth";

export const getStatuses = async (ID_CENTRO: number[] | null, ID_EMPRESA: number[] | null) => {
  const token = getToken();

  const { data } = await api.get("/invoices/totals/statuses", {
    params: { ID_CENTRO, ID_EMPRESA },
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
}

export const getTotals = async (ID_CENTRO: number[] | null, ID_EMPRESA: number[] | null) => {
  const token = getToken();

  const { data } = await api.get("/invoices/totals", {
    params: { ID_CENTRO, ID_EMPRESA },
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
}

export const getGraphics = async (ID_CENTRO: number[] | null, ID_EMPRESA: number[] | null) => {
  const token = getToken();

  const { data } = await api.get("/dashboard/graphic", {
    params: { ID_CENTRO, ID_EMPRESA },
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
}