import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import DataTableDefault from "../../../../../components/DataTable";
import { Center } from "../../../../../types/center";
import { Company } from "../../../../../types/company";
import { hasClaim } from "../../../../../storage/userClaims";
import { Chip } from "@mui/material";

interface DataTableProps {
  onEditCenter: (id: number | string) => void;
  onDelete: (id: number) => void;
  onViewCenter: (id: number) => void;
  data: Center[];
  companies: Company[];
  [x: string]: any;
}
export default function DataTable({
  onEditCenter,
  onDelete,
  onViewCenter,
  data,
  companies,
  ...props
}: DataTableProps) {
  const renderMenus = (row: any) => {
    return [
      <GridActionsCellItem
        sx={{
          display:
            hasClaim("Center - Maintenance") ||
            hasClaim("Center - Visualization")
              ? "block"
              : "none",
        }}
        key={`view-${row.ID_CENTRO}`}
        icon={<VisibilityIcon />}
        label="View"
        onClick={() => onViewCenter(row.ID_CENTRO)}
        color="inherit"
        data-testid="view-button"
      />,
      <GridActionsCellItem
        sx={{ display: hasClaim("Center - Maintenance") ? "block" : "none" }}
        key={`edit-${row.ID_CENTRO}`}
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={() => onEditCenter(row.ID_CENTRO)}
        color="inherit"
        data-testid="edit-button"
      />,
      <GridActionsCellItem
        sx={{ display: hasClaim("Center - Maintenance") ? "block" : "none" }}
        key={`delete-${row.ID_CENTRO}`}
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => onDelete(row.ID_CENTRO)}
        color="inherit"
        data-testid="delete-button"
      />,
    ];
  };

  const columns: GridColDef[] = [
    {
      field: "NOME_CENTRO",
      headerName: "Company - Plant",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 250,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `${params.row.DSC_SIGLA} - ${params.row.NOME_CENTRO}`;
      },
    },
    {
      field: "GE_SIGLA_EMPRESA",
      headerName: "Company",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `${params.row.GE_SIGLA_EMPRESA} - ${params.row.GE_DSC_EMPRESA}`;
      },
    },
    {
      field: "IND_PLANTA_BLOQUEADA",
      headerName: "Blocked",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<Date>) =>
        params.value ? (
          <Chip label="Blocked" color="primary" />
        ) : (
          <Chip label="Unblocked" variant="outlined" />
        ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }) => renderMenus(row),
    },
  ];

  return (
    <>
      <DataTableDefault
        rowId={data.map((row) => row.ID_CENTRO)}
        data={data}
        columns={columns}
        {...props}
        data-testid="centers-data-table"
      />
    </>
  );
}
