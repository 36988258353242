import { useEffect, useState } from "react";
import { getToken } from "../../storage/auth";
import { WarehouseNote, WarehouseNoteCreationData } from "../../types/warehouse-note";
import { api } from "../../lib/api";

const entity = "warehouse-note"

export const useList = (
  idWarehouse?: number | null,
): {
  warehouseNotes: WarehouseNote[] | undefined,
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>
} => {
  const token = getToken();
  const [warehouseNotes, setWarehouseNotes] = useState<WarehouseNote[]>([]);
  const [refetch, setRefetch] = useState<boolean>(false);

  useEffect(() => {
    (() => {
      if (idWarehouse) {
        api
          .get(`/${entity}/findByWarehouseId/${idWarehouse}`, {
            headers: { authorization: `Bearer ${token}` },
          })
          .then((response: any) => {
            const { data } = response;
            setWarehouseNotes(data.object);
            setRefetch(false);
          })
      }
    })()
  }, [idWarehouse, refetch]);

  return { warehouseNotes, setRefetch };
};

export const create = async (
  warehouseNote: WarehouseNoteCreationData,
): Promise<{ success: boolean, messages: string[] }> => {
  const token = getToken();

  const { data } = await api.post(`/${entity}`, warehouseNote, {
    headers: { authorization: `Bearer ${token}` },
  });

  return { success: data.success, messages: data.messages };
}