import { Download, RemoveRedEye } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";

interface TablesDetailsProps {
  data: { label: string | number, value?: string | number | null | JSX.Element, id?: number }[];
  type?: string;
  onView?: (id?: number) => void;
  onDownload?: (id?: number) => void;
}

const TablesDetails = ({ data, type = 'normal', onDownload, onView }: TablesDetailsProps) => {
  return (
    <>
      {data?.map((item, index) => (
        <Grid
          key={item.label}
          style={{
            margin: "10px 0 6px 0",
            background: (index + 1) % 2 === 0 ? "#e8faff" : "transparent",
          }}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid xs={type === 'attachments' ? 3 : 6}>
            <Typography>{item.label}</Typography>
          </Grid>
          <Grid xs={type === 'attachments' ? 7 : 6}>
            <Typography>{item.value}</Typography>
          </Grid>
          {type === 'attachments' && (
            <Grid
              xs={2}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <IconButton
                style={{ borderRadius: '4px', border: '1px solid #1976d2' }}
                aria-label='Shipment Authorization'
                size='small'
                onClick={() => onView && onView(item.id)}
              >
                <RemoveRedEye style={{ color: '#1976d2' }} />
              </IconButton>
              <IconButton
                style={{ borderRadius: '4px', border: '1px solid #1976d2' }}
                aria-label='Shipment Authorization'
                size='small'
                onClick={() => onDownload && onDownload(item.id)}
              >
                <Download />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}
    </>
  );
};

export default TablesDetails;
