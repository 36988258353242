import { useEffect, useState } from "react";
import { api } from "../../lib/api";
import { Quarantine } from "../../types/quarantine";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
  status?: boolean;
}

const entity = "quarantines";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string,
  isCombo?: boolean
): Promise<{ data: Quarantine[]; meta: List; isLoading: boolean }> | any => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Quarantine[]>([{} as Quarantine]);
  const [meta, setMeta] = useState<List>({} as List);
  const [combo, setCombo] = useState<any>();

  const render = () => {
    if (token) {
      if (isCombo === false || isCombo === undefined) {
        return "paginate";
      }
    }
  };

  useEffect(() => {
    if (render() === "paginate") {
      (async function () {
        setIsLoading(true);
        const response = await dataTableUtils.findPaginate(params);
        const {total, data, currentPage, totalPages, limit} = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
        setIsLoading(false)
      })();
    }
  }, [isCombo, params, token]);

  useEffect(() => {
    if (token && isCombo === true) {
      setIsLoading(true);
      api
        .get(`/${entity}/getCombo`, {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const { data } = response;
          setCombo(data);
        })
        .finally(() => setIsLoading(false));
    }
  }, [isCombo, params, token]);

  return { data, meta, isLoading, combo };
};

export const update = async (
  id: number | string,
  quarantine?: Quarantine
): Promise<Quarantine> => {
  const token = getToken();
  const { data } = await api.patch(`/${entity}/${id}`, quarantine, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const isInUse = async (id: number | string): Promise<any> => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/isInUse/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const deleteResource = async (
  id: number | string
): Promise<Quarantine> => {
  const token = getToken();
  const { data } = await api.delete(`/${entity}/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const create = async (quarantine?: Quarantine): Promise<Quarantine> => {
  const token = getToken();
  const { data } = await api.post(`/${entity}`, quarantine, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: "Id", value: "ID_QUARENTENA" },
    { label: "Code", value: "CODIGO_QUARENTENA" },
    { label: "Description", value: "DESCRICAO" },
    {
      label: "Status",
      value: (row: any) => (row.ATIVO ? "Active" : "Inactive"),
    },
  ]
  await dataTableUtils.exportAll({params, xlsxData, filename: "QuarantineCode"});
};
