import { api } from "../../lib/api";
import { getToken } from "../../storage/auth";
import { AutomaticAuthorizationProps } from "../../types/automaticAuthorization";

const entity = "boardingAuthorizationLog";

export const getById = async (
  ID_REGRA_AUTORIZACAO_EMBARQUE: AutomaticAuthorizationProps["ID_REGRA_AUTORIZACAO_EMBARQUE"],
  page: number,
  limit: number,
): Promise<any> => {
  const token = await getToken();
  const { data } = await api.get(`/${entity}/${ID_REGRA_AUTORIZACAO_EMBARQUE}?page=${page || 1}&limit=${limit || 100}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const fetchByCenter = async (
  ID_CENTRO: AutomaticAuthorizationProps["ID_CENTRO"],
  search: string,
  sort: string,
  order: string,
  page: number,
  limit: number,
): Promise<any> => {
  const token = await getToken();
  const { data } = await api.get(`/${entity}/findByCenterId/${ID_CENTRO}`, {
    params: {
      q: search || '',
      sort: sort || 'updated_at',
      order: (order || 'DESC').toUpperCase(),
      page: page || 1,
      limit: limit || 100,
    },
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};