import { ChangeEvent, useEffect, useMemo, useState } from "react"
import * as yup from "yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  Grid,
  InputLabel,
  FormControl,
  Divider,
  CircularProgress,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  InvoicePackageDetail
} from "../../../../types/packageDetail";
import { create, remove } from "../../../../services/invoicePackageDetails";
import { findAll as findAllUnits } from "../../../../services/unitMeasurement";
import { UnitMeasurement } from "../../../../types/unitMeasurement";
import { findAllActives as findAllPackageTypes } from "../../../../services/packageType";
import { PackageTypesProps } from "../../../../types/packageType";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InvoicePackageTable } from "./table/TablePackageDetails";
import { Invoice, InvoicesSections } from "../../../../types/invoice";
import { enqueueSnackbar } from "notistack";
import { convertToCm, convertToKg } from "../../../utils/conversions";
import { convertToBRNumber, convertToNumber, formatNumberWithThousandsSeparator, handleDecimalInputChange } from "../../../utils/inputs";

type InvoicePackageDetailsProps = {
  invoice: Invoice,
  onUpdate: (sectionNewStatus?: { [key: string]: boolean }) => void
  isDisabled: boolean,
}

export const packageDetailsSchema = yup.object({
  ID_TIPO_PACKAGE: yup.number().required('Package type is required').typeError('Package type is required'),
  ID_UM_PESO_BRUTO: yup.number().required('Unit of weight is required').typeError('Unit of weight is required'),
  NUM_PESO_BRUTO: yup.number().required('Gross weight is required').typeError('Gross weight is required').transform((_, value) => {
    return +((value || '').replace(/\./gi, '').replace(/,/gi, '.'));
  }).positive(),
  NUM_COMPRIMENTO: yup.number().required('Length is required').typeError('Length is required').transform((_, value) => {
    return +((value || '').replace(/\./gi, '').replace(/,/gi, '.'));
  }).positive(),
  ID_UM_COMPRIMENTO: yup.number().required('Unit of length is required').typeError('Unit of length is required'),
  NUM_LARGURA: yup.number().required('Width is required').typeError('Width is required').transform((_, value) => {
    return +((value || '').replace(/\./gi, '').replace(/,/gi, '.'));
  }).positive(),
  ID_UM_LARGURA: yup.number().required('Unit of width is required').typeError('Unit of width is required'),
  NUM_ALTURA: yup.number().required('Height is required').typeError('Height is required').transform((_, value) => {
    return +((value || '').replace(/\./gi, '').replace(/,/gi, '.'));
  }).positive(),
  ID_UM_ALTURA: yup.number().required('Unit of height is required').typeError('Unit of height is required'),
  NUM_PESO_CUBICO: yup.number().transform((_, value) => {
    return +((value || '').replace(/\./gi, '').replace(/,/gi, '.'));
  }).positive() as yup.NumberSchema<number, yup.AnyObject, undefined, ""> | yup.StringSchema<string, yup.AnyObject, undefined, "">,
})

type PackageDetailsFormSchema = {
  ID_TIPO_PACKAGE: number;
  ID_UM_PESO_BRUTO: number;
  NUM_PESO_BRUTO: number;
  NUM_COMPRIMENTO: number;
  ID_UM_COMPRIMENTO: number;
  NUM_LARGURA: number;
  ID_UM_LARGURA: number;
  NUM_ALTURA: number;
  ID_UM_ALTURA: number;
  NUM_PESO_CUBICO: string | number;
}

const lengthUnits = ['CM', 'M', 'MM', 'PE', 'POL', 'YD']
const weightUnits = ['KG', 'G', 'LB', 'OZ'];

const InvoicePackageDetails = ({ invoice, onUpdate, isDisabled }: InvoicePackageDetailsProps) => {
  const [weightMeasurements, setWeightMeasurements] = useState<
    UnitMeasurement[]
  >([])
  const [lengthMeasurements, setLengthMeasurements] = useState<
    UnitMeasurement[]
  >([])
  const [allUnitsOfMeasurements, setAllUnitsOfMeasurements] = useState<
    UnitMeasurement[]
  >([])
  const [packageTypes, setPackageTypes] = useState<PackageTypesProps[]>([])
  const [openCreateModal, setOpenCreateModal] = useState(false)

  const [idLengthSelectedValue, setIdLengthSelectedValue] = useState('')
  const [idWidthSelectedValue, setIdWidthSelectedValue] = useState('')
  const [idHeightSelectedValue, setIdHeightSelectedValue] = useState('')

  const [componentKey, setComponentKey] = useState(0);

  const { register, handleSubmit, formState, reset, control, watch, setValue, unregister } =
    useForm<PackageDetailsFormSchema>({
      defaultValues: {
        ID_TIPO_PACKAGE: undefined,
        ID_UM_PESO_BRUTO: undefined,
        NUM_PESO_BRUTO: undefined,
        NUM_COMPRIMENTO: undefined,
        ID_UM_COMPRIMENTO: undefined,
        NUM_LARGURA: undefined,
        ID_UM_LARGURA: undefined,
        NUM_ALTURA: undefined,
        ID_UM_ALTURA: undefined,
        NUM_PESO_CUBICO: 0,
      },
      resolver: yupResolver(packageDetailsSchema),
    })

  const { errors: formErrors, isSubmitting } = formState;

  const onSubmit = async (data: InvoicePackageDetail) => {
    try {
      const siglaPeso = allUnitsOfMeasurements.find(unit => unit.ID_UM === data.ID_UM_PESO_BRUTO)?.SIGLA || 'KG'

      const siglaLength = lengthMeasurements.find(
        (unit) => unit.ID_UM === ID_UM_COMPRIMENTO
      )?.SIGLA;

      const siglaWidth = lengthMeasurements.find(
        (unit) => unit.ID_UM === ID_UM_LARGURA
      )?.SIGLA;

      const siglaHeight = lengthMeasurements.find(
        (unit) => unit.ID_UM === ID_UM_ALTURA
      )?.SIGLA;

      const lengthInCm = convertToCm(data.NUM_COMPRIMENTO, siglaLength ?? 'CM');
      const widthInCm = convertToCm(data.NUM_LARGURA, siglaWidth ?? 'CM');
      const heightInCm = convertToCm(data.NUM_ALTURA, siglaHeight ?? 'CM');
      const weightInKg = convertToKg(data.NUM_PESO_BRUTO, siglaPeso);

      const idCm = allUnitsOfMeasurements.find(unit => unit.SIGLA === 'CM')?.ID_UM
      const idKg = allUnitsOfMeasurements.find(unit => unit.SIGLA === 'KG')?.ID_UM

      const convertedData = {
        ...data,
        NUM_COMPRIMENTO: lengthInCm,
        NUM_LARGURA: widthInCm,
        NUM_ALTURA: heightInCm,
        NUM_PESO: weightInKg,
        NUM_PESO_BRUTO: weightInKg, 
        ID_UM_PESO_BRUTO: idKg,
        ID_UM_COMPRIMENTO: idCm,
        ID_UM_LARGURA: idCm,
        ID_UM_ALTURA: idCm,
        ID_UM_PESO: idKg,
      };
  
      await create({
        ID_INVOICE: invoice.ID_INVOICE,
        ...convertedData,
      });
  
      enqueueSnackbar('Package details added successfully', { variant: 'success' });
      resetAll();
      onUpdate({ [InvoicesSections.PACKAGE_DETAILS]: true });
      setOpenCreateModal(false);
    } catch (error) {
      enqueueSnackbar('Could not add package details', { variant: 'error' });
    }
  };
  const handleDelete = async (id: number) => {
    try {
      await remove(id)
      enqueueSnackbar('Package details deleted successfully', { variant: 'success' })
      onUpdate({ [InvoicesSections.PACKAGE_DETAILS]: true })
    } catch (error) {
      enqueueSnackbar('Could not delete package details', { variant: 'error' })
    }
  }

  useEffect(() => {
    const loadData = async () => {
      const types = await findAllPackageTypes()
      setPackageTypes(types)

      const units = await findAllUnits()
      setAllUnitsOfMeasurements(units)
      const weightUnitsFiltered = units.filter((unit) =>
        weightUnits.includes(unit.SIGLA)
      )
      const lengthUnitsFiltered = units.filter((unit) =>
        lengthUnits.includes(unit.SIGLA)
      )
      setWeightMeasurements(weightUnitsFiltered)
      setLengthMeasurements(lengthUnitsFiltered)
    }
    loadData()
  }, [])

  const [
    NUM_COMPRIMENTO,
    NUM_LARGURA,
    NUM_ALTURA,
    ID_UM_COMPRIMENTO,
    ID_UM_LARGURA,
    ID_UM_ALTURA,
    ID_UM_PESO_BRUTO
  ] = watch([
    'NUM_COMPRIMENTO',
    'NUM_LARGURA',
    'NUM_ALTURA',
    'ID_UM_COMPRIMENTO',
    'ID_UM_LARGURA',
    'ID_UM_ALTURA',
    'ID_UM_PESO_BRUTO'
  ])

  useEffect(() => {
    const calculateCubicWeight = () => {
      let length = convertToNumber(NUM_COMPRIMENTO);
      let width = convertToNumber(NUM_LARGURA);
      let height = convertToNumber(NUM_ALTURA);

      const conversionFactors = {
        CM: 1,
        M: 100,
        MM: 0.1,
        PE: 30.48,
        POL: 2.54,
        YD: 91.44,
      };
  
      const unitMeasurementLength = lengthMeasurements.find(
        (unit) => unit.ID_UM === ID_UM_COMPRIMENTO
      )?.SIGLA;

      const unitMeasurementWidth = lengthMeasurements.find(
        (unit) => unit.ID_UM === ID_UM_LARGURA
      )?.SIGLA;

      const unitMeasurementHeight = lengthMeasurements.find(
        (unit) => unit.ID_UM === ID_UM_ALTURA
      )?.SIGLA;
  
      if (unitMeasurementLength && unitMeasurementWidth && unitMeasurementHeight) {
        const factorLength = conversionFactors[unitMeasurementLength as keyof typeof conversionFactors];
        const factorWidth = conversionFactors[unitMeasurementWidth as keyof typeof conversionFactors];
        const factorHeight = conversionFactors[unitMeasurementHeight as keyof typeof conversionFactors];
        length *= factorLength;
        width *= factorWidth;
        height *= factorHeight;
  
        const cubicWeight = (length * width * height) / 6000;
        setValue('NUM_PESO_CUBICO', convertToBRNumber(cubicWeight.toFixed(3)));
      }
    }

    calculateCubicWeight()
  }, [
    NUM_COMPRIMENTO,
    NUM_LARGURA,
    NUM_ALTURA,
    ID_UM_COMPRIMENTO,
    ID_UM_LARGURA,
    ID_UM_ALTURA,
    setValue,
    lengthMeasurements,
  ])

  const handleFirstSelectChange = (event: ChangeEvent<{ value: string }>) => {
    const value = event.target.value
    setIdLengthSelectedValue(value)
    setIdWidthSelectedValue(value)
    setIdHeightSelectedValue(value)
    const currentValues = { ...watch() };
    reset({
      ...currentValues,
      ID_UM_COMPRIMENTO: +value,
      ID_UM_LARGURA: +value,
      ID_UM_ALTURA: +value,
    })
  }

  const handleSelectChange = (event: ChangeEvent<{ value: string }>, action: (arg0: string) => void, field: any) => {
    const value = event.target.value
    action(value)
    const currentValues = { ...watch() };
    reset({
      ...currentValues,
      [field]: +value,
    })
  }

  const handleCancel = () => {
    resetAll()
    setOpenCreateModal(false)
  }

  const resetAll = async () => {
    setIdLengthSelectedValue('');
    setIdWidthSelectedValue('');
    setIdHeightSelectedValue('');
    reset({
      ID_TIPO_PACKAGE: undefined,
      ID_UM_PESO_BRUTO: undefined,
      NUM_PESO_BRUTO: undefined,
      NUM_COMPRIMENTO: undefined,
      ID_UM_COMPRIMENTO: undefined,
      NUM_LARGURA: undefined,
      ID_UM_LARGURA: undefined,
      NUM_ALTURA: undefined,
      ID_UM_ALTURA: undefined,
      NUM_PESO_CUBICO: 0,
    })
    unregister()
    setOpenCreateModal(false);
  }

  const handleOpenModal = () => {
    setValue('NUM_PESO_BRUTO', 0)
    unregister()
    setComponentKey(componentKey + 1)
    setOpenCreateModal(true);
  };

  const handleCloseWithCondition = (_: React.MouseEvent | {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      setOpenCreateModal(false);
    }
  }

  const selectedGrossWeightUnit = useMemo(() => {
    return allUnitsOfMeasurements.find(
      (unit) => unit.ID_UM === ID_UM_PESO_BRUTO
    )?.SIGLA?.toLowerCase()
  }, [ID_UM_PESO_BRUTO, allUnitsOfMeasurements])

  const totalGrossWeight = useMemo(() => {
    return invoice?.invoicePackages?.reduce((acc, pkg) => {
      acc += +pkg.NUM_PESO_BRUTO
      return acc;
    }, 0);
  }, [invoice?.invoicePackages])


  const totalCubicWeight = useMemo(() => {
    return invoice?.invoicePackages?.reduce((acc, pkg) => {
      acc += +pkg.NUM_PESO_CUBICO
      return acc;
    }, 0);
  }, [invoice?.invoicePackages])

  return (
    <div style={{ padding: '10px' }}>
      <Button
        variant='outlined'
        onClick={handleOpenModal}
        style={{
          marginTop: '8px',
          marginBottom: '8px',
          display: isDisabled ? 'none' : 'block',
        }}
        disabled={isDisabled}
      >
        New
      </Button>
      {invoice?.invoicePackages && (
        <>
          <InvoicePackageTable
            invoicePackages={invoice.invoicePackages}
            packageTypes={packageTypes}
            unitOfMeasurements={allUnitsOfMeasurements}
            onDelete={handleDelete}
            isDisabled={isDisabled}
          />
          <Grid container direction="column" alignItems="flex-end" marginBottom={5}>
            <Grid item>
              <Typography fontSize={18}>Total Gross Weight: {formatNumberWithThousandsSeparator(totalGrossWeight)?.formatted}</Typography>
            </Grid>
            <Grid item>
              <Typography fontSize={18}>Total Cubic Weight: {formatNumberWithThousandsSeparator(totalCubicWeight)?.formatted}</Typography>
            </Grid>
          </Grid>
        </>
      )}

      <Dialog
        open={openCreateModal}
        key={componentKey}
        onClose={handleCloseWithCondition}
        fullWidth
        maxWidth={'md'}
        disableEscapeKeyDown
      >
        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          Package Details
          <Button onClick={handleCancel}>
            <CloseIcon />
          </Button>
        </DialogTitle>
        <DialogContent style={{ paddingTop: '10px' }} dividers>
          <form onSubmit={handleSubmit(onSubmit as SubmitHandler<PackageDetailsFormSchema>)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name='ID_TIPO_PACKAGE'
                  control={control}
                  render={({ field: { value } }) => (
                    <FormControl fullWidth error={!!formErrors.ID_TIPO_PACKAGE} size="small">
                      <InputLabel id='package-type-label'>
                        Package Type*
                      </InputLabel>
                      <Select
                        labelId='package-type-label'
                        label='Package Type*'
                        {...register('ID_TIPO_PACKAGE')}
                      >
                        {packageTypes?.filter((({ ATIVO }) => ATIVO)).map((type) => (
                          <MenuItem
                            key={type.ID_TIPO_PACKAGE}
                            value={type.ID_TIPO_PACKAGE}
                          >
                            {type.DSC_TIPO_PACKAGE}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name='NUM_PESO_BRUTO'
                  control={control}
                  render={({ fieldState }) => (
                    <TextField
                      fullWidth
                      label='Gross Weight*'
                      error={!!fieldState.error}
                      size="small"
                      onInput={(e: ChangeEvent<HTMLInputElement>) => handleDecimalInputChange(e)}
                      {...register('NUM_PESO_BRUTO')}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3}>
                <Controller
                  name='ID_UM_PESO_BRUTO'
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      error={!!formErrors.ID_UM_PESO_BRUTO}
                      size="small"
                    >
                      <InputLabel id='package-type-label'>
                        Gross Weight Unit*
                      </InputLabel>
                      <Select
                        labelId='ID_UM_PESO_BRUTO-label'
                        label='Unit of Measurement*'
                        {...register('ID_UM_PESO_BRUTO')}
                      >
                        {weightMeasurements?.map((unit) => (
                          <MenuItem key={unit.ID_UM} value={unit.ID_UM}>
                            {unit.SIGLA} - {unit.DESCRICAO}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item xs={3}>
                <Controller
                  name='NUM_COMPRIMENTO'
                  control={control}
                  render={({ fieldState }) => (
                    <TextField
                      fullWidth
                      label='Length*'
                      error={!!fieldState.error}
                      size="small"
                      onInput={(e: ChangeEvent<HTMLInputElement>) => handleDecimalInputChange(e)}
                      {...register('NUM_COMPRIMENTO')}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3}>
                <Controller
                  name='ID_UM_COMPRIMENTO'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      select
                      value={idLengthSelectedValue}
                      {...register('ID_UM_COMPRIMENTO')}
                      onChange={handleFirstSelectChange}
                      error={!!formErrors.ID_UM_COMPRIMENTO}
                      size="small"
                      fullWidth
                      label="Length Unit*"
                    >
                      {lengthMeasurements?.map((unit) => (
                        <MenuItem key={unit.ID_UM} value={unit.ID_UM}>
                          {unit.SIGLA} - {unit.DESCRICAO}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={3}>
                <Controller
                  name='NUM_LARGURA'
                  control={control}
                  render={({ fieldState }) => (
                    <TextField
                      fullWidth
                      label='Width*'
                      error={!!fieldState.error}
                      size="small"
                      onInput={(e: ChangeEvent<HTMLInputElement>) => handleDecimalInputChange(e)}
                      {...register('NUM_LARGURA')}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3}>
                <Controller
                  name='ID_UM_LARGURA'
                  control={control}
                  render={({ fieldState }) => (
                    <TextField
                      select
                      value={idWidthSelectedValue}
                      {...register('ID_UM_LARGURA')}
                      // onChange={handleFirstSelectChange}
                      onChange={(event) => handleSelectChange(event, setIdWidthSelectedValue, 'ID_UM_LARGURA')}
                      error={!!formErrors.ID_UM_LARGURA || !!fieldState.error}
                      size="small"
                      fullWidth
                      label="Width Unit*"
                    >
                      {lengthMeasurements?.map((unit) => (
                        <MenuItem key={unit.ID_UM} value={unit.ID_UM}>
                          {unit.SIGLA} - {unit.DESCRICAO}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={3}>
                <Controller
                  name='NUM_ALTURA'
                  control={control}
                  render={({ fieldState }) => (
                    <TextField
                      fullWidth
                      label='Height*'
                      error={!!fieldState.error}
                      size="small"
                      onInput={(e: ChangeEvent<HTMLInputElement>) => handleDecimalInputChange(e)}
                      {...register('NUM_ALTURA')}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3}>
                <Controller
                  name='ID_UM_ALTURA'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      select
                      value={idHeightSelectedValue}
                      {...register('ID_UM_ALTURA')}
                      // onChange={handleFirstSelectChange}
                      onChange={(event) => handleSelectChange(event, setIdHeightSelectedValue, 'ID_UM_ALTURA')}
                      error={!!formErrors.ID_UM_ALTURA}
                      size="small"
                      fullWidth
                      label="Height Unit*"
                    >
                      {lengthMeasurements?.map((unit) => (
                        <MenuItem key={unit.ID_UM} value={unit.ID_UM}>
                          {unit.SIGLA} - {unit.DESCRICAO}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={3}>
                <Controller
                  name='NUM_PESO_CUBICO'
                  control={control}
                  render={({ fieldState }) => (
                    <TextField
                      fullWidth
                      label="Cubic Weight (kg)"
                      error={!!fieldState.error}
                      disabled
                      size="small"
                      InputLabelProps={{ shrink: !!(watch("NUM_PESO_CUBICO") && (watch("NUM_PESO_CUBICO") as string).length > 0) }}
                      {...register('NUM_PESO_CUBICO')}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: '15px', marginBottom: '15px' }} />
            <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    color: "white",
                  },
                }}
                variant="contained"
                color="inherit"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button 
                disabled={isSubmitting}
                variant="contained" type='submit'
              >
                Add {isSubmitting && <CircularProgress style={{ marginLeft: 8 }} color="inherit" size={14} />}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default InvoicePackageDetails
