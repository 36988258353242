import { Download } from "@mui/icons-material";
import {
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  exportAll,
  useList,
} from "../../../services/logEmail";
import { getLocal, getToken } from "../../../storage/auth";
import DataTable from "./components/DataTable";
import SearchLogEmail from "./components/Search";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
}

export default function LogEmail() {
  const [userValidation, setUserValidation] = useState<string>();
  const [token, setToken] = useState<string>();
  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = (getLocal("userInfo")) as any;
    setToken(getTokens);
    setUserValidation(JSON.parse(getUser));
  };

  useEffect(() => {
    getTokenSession();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const { data, meta, isLoading } = useList(params, token);

  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };

  const exportLogEmail = async () => {
    try {
      await exportAll(params);
    } catch (err) {}
  };

  return (
    <Grid marginTop={4}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h5">Log Email</Typography>
        </Grid>
      </Grid>
      <SearchLogEmail
        handleSearch={handleRequest}
        setParams={setParams}
      />
      <Grid
        container
        marginTop={2}
        marginBottom={2}
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Grid item>
          <Button
            variant="text"
            onClick={exportLogEmail}
            startIcon={<Download />}
          >
            Export XLSX
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <DataTable
          loading={isLoading}
          data={data}
          userValidation={userValidation}
          rowCount={meta.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          paginationModel={paginationModel}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>
    </Grid>
  );
}
