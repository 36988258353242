import { api } from "../../lib/api";
import { getToken } from "../../storage/auth";
import { InvoiceDiscrepancy } from "../../types/invoiceDiscrepancy";

const entity = "invoice-discrepancy";

export const deleteInvoiceDiscrepancyById = async (invoiceDiscrepancyId: number): Promise<{ success: boolean }> => {
  const token = getToken();
  const { data } = await api.delete(`/${entity}/${invoiceDiscrepancyId}`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return data;
};

export const createInvoiceDiscrepancy = async (invoiceDiscrepancy: any): Promise<InvoiceDiscrepancy> => {
  const token = getToken();
  const { data } = await api.post(`/${entity}`, invoiceDiscrepancy, {
    headers: { authorization: `Bearer ${token}` },
  });
  return data;
}

export const closeInvoiceDicrepancyById = async (invoiceDiscrepancyId: number): Promise<InvoiceDiscrepancy> => {
  const token = getToken();
  const { data } = await api.put(`/${entity}/${invoiceDiscrepancyId}/close`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return data;
}
