import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Link, Paper, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ComboBoxType } from "../../../../../types/general";
import InvoiceSearch from "./invoiceSearch";
import InvoicePo from "./invoicePo";
import InvoiceData from "./invoiceData";
import { Accordion } from "../../../../../components/Accordion/Accordion";
import { AccordionSummary } from "../../../../../components/Accordion/AccordionSummary";
import InvoiceAttachments from "./invoiceAttachment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Invoice, InvoiceFormModel, InvoiceStatus, InvoiceType } from "../../../../../types/invoice";
import { useParams } from "react-router-dom";
import { Po } from "../../../../../types/po";
import { getCustomRegimeCombos } from "../../../../../services/customRegime";
import { getPartnersCombo } from "../../../../../services/partner";
import InvoiceDiscrepancy from "../../invoiceDetails/invoiceDiscrepancy";
import dayjs from "dayjs";

const invoiceSchema = yup.object({
  ID_PO: yup.number().nullable(),
  ID_CENTRO: yup.number().nullable(),
  NUM_INVOICE: yup.string(),
  DATA_INVOICE: yup.string(),
  DATA_RECEBIMENTO: yup.string().required('Received Data is required'),
  ID_TIPO_INVOICE: yup.string().required('Invoice Type is required'),
  ID_MOEDA: yup.number().required('Currency is required'),
  VALOR_TOTAL: yup.string().required('Total Value is required').transform(value => value.replace(',', '.')),
  VALOR_TAXAS_EXTRAS: yup.string().nullable().transform(value => value?.replace(',', '.') ?? ''),
  ID_INCOTERMS: yup.number().required('Incoterms is required'),
  DSC_INCOTERMS_COMPL: yup.string(),
  ID_INCOTERMS_COMPL: yup.number().required('Incoterms Complement is required'),
  ID_PRIORIDADE: yup.number().required('Service Level is required'),
  NUM_TRACKING: yup.string().max(50).optional().nullable(),
  ID_PAIS_ORIGEM: yup.number().when("ID_TIPO_INVOICE", (value, schema) => {
    return ["Inbound - International", "Triage - International"].includes(
      value[0]
    )
      ? schema.required()
      : schema.nullable();
  }),
  ID_MODAL: yup.string().when("ID_TIPO_INVOICE", (value, schema) => {
    return ["Inbound - International", "Triage - International"].includes(
      value[0]
    )
      ? schema.required()
      : schema.nullable();
  }),
  ID_PARCEIRO_AGENTE_CARGA: yup
    .number()
    .when("ID_TIPO_INVOICE", (value: string[], schema: yup.Schema) => {
      return ["Inbound - International", "Triage - International"].includes(
        value[0]
      )
        ? schema.required()
        : schema.nullable();
    }),
  ID_REGIME: yup
    .number()
    .when("ID_TIPO_INVOICE", (value: string[], schema: yup.Schema) => {
      return ["Inbound - International", "Triage - International"].includes(
        value[0]
      )
        ? schema.required()
        : schema.nullable();
    }),
  ID_PARCEIRO_DOMESTIC_CARRIER: yup.number().nullable(),
  ID_PARCEIRO_BROKER: yup
    .number()
    .when("ID_TIPO_INVOICE", (value: string[], schema: yup.Schema) => {
      return ["Inbound - International", "Triage - International"].includes(
        value[0]
      )
        ? schema.required()
        : schema.nullable();
    }),
  NUM_PESO_BRUTO: yup
    .string()
    .nullable()
    .optional()
    .matches(/^[0-9.,]*$/, 'Deve conter apenas números, pontos ou vírgulas')
    .transform(value => (value ? value.replace(',', '.') : value)),
  invoiceAttachments: yup.array().of(
    yup.object({
      ID_TIPO_ANEXO: yup.string(),
      FILE: yup.mixed().nullable().optional()
    })
  )
    .min(1, 'At least one attachment with type INVOICE is required')
    .test('at-least-one-invoice', 'At least one attachment with type INVOICE must have FILE', (value): boolean => {
      const invoiceAttachment = value?.find(att => att.ID_TIPO_ANEXO === 'INVOICE');

      if (invoiceAttachment) {
        return !!invoiceAttachment.ID_TIPO_ANEXO || !!invoiceAttachment.FILE;
      }

      return false;
    }),
  invoiceAttachmentsToDelete: yup.array().of(yup.object()),
});

interface InvoiceFormProps {
  readOnly: boolean;
  onSubmit: (partner: InvoiceFormModel) => void;
  onCancel?: () => void;
  onReturn: () => void;
  isLoading?: boolean;
  errors?: string[];
  invoice: InvoiceFormModel;
  setInvoice: React.Dispatch<React.SetStateAction<InvoiceFormModel>>,
  invoiceAttachmentTypes: ComboBoxType[];
  invoiceTypes: ComboBoxType[];
  currencies: ComboBoxType[];
  incoterms: ComboBoxType[];
  incotermsComplements: ComboBoxType[];
  serviceLevel: ComboBoxType[];
  domesticCarrier: ComboBoxType[];
  countries: ComboBoxType[];
  transportationMode: ComboBoxType[];
  onUpdate: () => void;
  onClearErrors: () => void;
}

export default function InvoiceForm({
  invoiceAttachmentTypes,
  invoiceTypes,
  currencies,
  incoterms,
  incotermsComplements,
  serviceLevel,
  domesticCarrier,
  countries,
  transportationMode,
  onSubmit,
  onReturn,
  errors = [],
  invoice,
  onUpdate,
  setInvoice,
  onClearErrors
}: InvoiceFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    control,
    setError,
    clearErrors,
    setValue,
    watch,
    unregister,
  } = useForm({
    defaultValues: {
      ...invoice
    },
    resolver: yupResolver(invoiceSchema),
  });

  const { id: idInvoice } = useParams<{ id: string }>();

  const navigate = useNavigate();
  const [poData, setPoData] = useState<Po|null>();
  const [invoiceDate, setInvoiceDate] = useState<string | null>(null);
  const [receivedDate, setReceivedDate] = useState<string | null>(null);
  const [openPoAccordion, setOpenPoAccordion] = useState(false);
  const [openInvoiceAccordion, setOpenInvoiceAccordion] = useState(false);
  const [valuesPO, setValuesPo] = useState<Partial<Po>|null>(null);
  const [canEditInvoice, setCanEditInvoice] = useState(false);
  const [customRegimes, setCustomRegimes] = useState<ComboBoxType[]>([]);
  const [brokers, setBrokers] = useState<ComboBoxType[]>([]);
  const [freightForwarders, setFreightForwarder] = useState<ComboBoxType[]>([]);
  const [openInvoiceDiscrepanciesAccordion, setOpenInvoiceDiscrepanciesAccordion] = useState(false);
  const [clearError] = useState<boolean>(false);

  const currentIdIncotermsCompl = watch('ID_INCOTERMS_COMPL');
  const invoiceType = watch('ID_TIPO_INVOICE');
  const idPaisOrigem = watch('ID_PAIS_ORIGEM');

  useEffect(() => {
    const DATA_INVOICE = dayjs(invoice.DATA_INVOICE).format('MM/DD/YYYY');
    const DATA_RECEBIMENTO = dayjs(invoice.DATA_RECEBIMENTO).format('MM/DD/YYYY');

    if (invoice && idInvoice) {
      setOpenInvoiceAccordion(true);
      handleCheckIfInvoiceCanBeEdited();

      if (invoice?.invoiceDiscrepancies?.length > 0) {
        setOpenInvoiceDiscrepanciesAccordion(true);
      }

      if (invoice?.po && !poData) {
        setPoData(invoice.po);
      }
    }

    if (!idInvoice) {
      setCanEditInvoice(true);
    }

    if (invoice.ID_INVOICE) {
      reset({
        ...invoice,
        DATA_INVOICE,
        DATA_RECEBIMENTO,
      });
    }

  }, [reset, invoice, idInvoice, poData]);

  useEffect(() => {
    if (poData) {
      setValue("ID_PO", poData?.ID_PO);
    }
  }, [poData, setValue]);

  useEffect(() => {
    if (!incotermsComplements?.length) {
      return;
    }
    const DSC_INCOTERMS_COMPL = incotermsComplements
      .find((item) => item.id === currentIdIncotermsCompl)?.description;
    const currentValues = watch();

    reset({
      ...currentValues,
      DSC_INCOTERMS_COMPL
    });
  }, [currentIdIncotermsCompl]);

  useEffect(() => {
    const currentValues = watch();
    let ID_REGIME = currentValues?.ID_REGIME;

    if (invoiceType !== InvoiceType.INBOUND_INTERNATIONAL) {
      ID_REGIME = undefined;
    } else if (invoiceType === InvoiceType.INBOUND_INTERNATIONAL && !watch('ID_REGIME') && customRegimes.length > 0) {
      ID_REGIME = +customRegimes[0].id
    }

    reset({
      ...currentValues,
      ID_REGIME
    });
  }, [invoiceType]);

  useEffect(() => {
    fetchCenters();
    fetchBrokers();
    fetchFreightForwarders();
  }, [poData]);

  const getCenterId = () => {
    return poData?.poItems && poData.poItems[0] ? poData.poItems[0].ID_CENTRO : undefined;
  }

  const fetchCenters = async () => {
    const response = await getCustomRegimeCombos();
    setCustomRegimes(response);
  }

  const fetchBrokers = async () => {
    const ID_CENTER = getCenterId();

    const response = await getPartnersCombo('BROKER', ID_CENTER);
    setBrokers(response);
    if (!Object.keys(invoice).length) return;
    const current = watch();
    const hasFreightForwarder = current !== null && response.find(({ id }) => id === current?.ID_PARCEIRO_BROKER ?? invoice?.ID_PARCEIRO_BROKER);
    if (!hasFreightForwarder) setValue('ID_PARCEIRO_BROKER', null as unknown as undefined);
  }

  const fetchFreightForwarders = async () => {
    const ID_CENTER = getCenterId();

    const response = await getPartnersCombo('FREIGHT FORWARDER', ID_CENTER);
    setFreightForwarder(response);
    if (!Object.keys(invoice).length) return;
    const current = watch();
    const hasFreightForwarder = current !== null && response.find(({ id }) => id === current?.ID_PARCEIRO_AGENTE_CARGA ?? invoice?.ID_PARCEIRO_AGENTE_CARGA);
    if (!hasFreightForwarder) setValue('ID_PARCEIRO_AGENTE_CARGA', null as unknown as undefined);
  }

  const handleCheckIfInvoiceCanBeEdited = (): void => {
    const statusToEditInvoice = [
      InvoiceStatus.PENDING,
      InvoiceStatus.REGISTERED_INVOICE,
      InvoiceStatus.WAITING_SISTER_INVOICE,
      InvoiceStatus.PENDING_SHIPMENT_AUTHORIZATION
    ]

    if (statusToEditInvoice.includes(invoice?.ID_STATUS_INVOICE)) {
      setOpenInvoiceAccordion(true);
      setOpenPoAccordion(true);
      setCanEditInvoice(true);
    }
  }

  const handleFormSubmit = async (value: any) => {
    const today = dayjs().startOf('day');
    const currentReceivedDate =  dayjs(receivedDate).startOf('day');
    const currentInvoiceDate = dayjs(invoiceDate, 'MM/DD/YYYY').startOf('day');

    if (currentReceivedDate < currentInvoiceDate) return setError('DATA_RECEBIMENTO', { type: 'custom', message: `Invoice Received Date is less than Invoice Date` });
    if (currentReceivedDate > today) return setError('DATA_RECEBIMENTO', { type: 'custom', message: `Invoice Received Date is greater than ${today.format('MM/DD/YY')}` });
    if (currentReceivedDate < today.subtract(4, 'month')) return setError('DATA_RECEBIMENTO', { type: 'custom', message: `Invoice Date must be greater than ${today.subtract(4, 'month').format('MM/DD/YY')}` });

    if ( currentReceivedDate < currentInvoiceDate || currentReceivedDate > today || currentReceivedDate < today.subtract(4, 'month') ) return;

    if (!idInvoice) {
      let dataSend = {
        ...value,
        ID_PO: poData?.ID_PO,
        DATA_INVOICE: invoiceDate || valuesPO?.DATA_INVOICE,
        DATA_RECEBIMENTO: receivedDate,
      };

      if (poData?.NUMERO_PO) {
        dataSend = {
          ...value,
          DATA_INVOICE: invoiceDate || valuesPO?.DATA_INVOICE,
          DATA_RECEBIMENTO: receivedDate,
          NUM_INVOICE: value.NUM_INVOICE,
        };
      }

      !!onSubmit && onSubmit(dataSend);
      return;
    }

    !!onSubmit && onSubmit(value);
  };

  const handleCancel = () => {
    unregister();
    clearErrors();
    onClearErrors();
    setOpenInvoiceAccordion(false);
    setOpenPoAccordion(false);
    setPoData(null);
    setValue("NUM_INVOICE", "");
    setValue("DATA_INVOICE", undefined);
    setInvoice({} as InvoiceFormModel);
    navigate("/invoice");
  }

  const handlePoData = (po: any) => {
    setPoData(po);
    setValue("ID_PO", po?.ID_PO);
  }

  const alertErros = useMemo(() => {
    const errorMessages = Object.keys(formErrors).map((key: string) => {
      return formErrors[key as keyof typeof formErrors]?.message;
    });

    return [...errorMessages, ...errors];
  }, [errors, formErrors]);

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      {alertErros.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}

      <InvoiceSearch
        resetInvoice={reset}
        setOpenPoAccordion={setOpenPoAccordion}
        setOpenInvoiceAccordion={setOpenInvoiceAccordion}
        getPoData={(value) => setValuesPo(value)}
        setPoData={(value) => handlePoData(value)}
        poData={poData}
        register={register}
        watch={watch}
        control={control}
        formErrors={formErrors}
        invoiceDate={invoiceDate}
        getInvoiceDate={(date) => setInvoiceDate(date)}
        invoice={invoice}
        clearErrors={clearError}
        setInvoice={setInvoice}
      />

      <Accordion
        sx={{
          "&.MuiAccordion-root": { border: 0, marginBottom: 3, marginTop: 1 },
        }}
        onChange={() => {
          setOpenPoAccordion(prevState => !prevState);
        }}
        expanded={!!openPoAccordion && !!poData && Object.keys(poData).length > 0}
        disabled={!poData || Object.keys(poData).length === 0}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          applyOpacity={!poData}
        >
          <Typography>PO DATA</Typography>
        </AccordionSummary>
        <Paper
          variant="outlined"
          sx={{ marginTop: 3, paddingX: 2, paddingBottom: 3 }}
        >
          {poData && Object.keys(poData).length > 0 && (
            <InvoicePo poData={poData} />
          )}
        </Paper>
      </Accordion>

      <Accordion
        sx={{ "&.MuiAccordion-root": { border: 0, marginBottom: 3 } }}
        onChange={() => setOpenInvoiceAccordion(!openInvoiceAccordion)}
        expanded={!!invoice?.NUM_INVOICE && openInvoiceAccordion}
        disabled={!invoice?.NUM_INVOICE && !openInvoiceAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>INVOICE DATA</Typography>
        </AccordionSummary>
        <Paper
          variant="outlined"
          sx={{ marginTop: 3, paddingX: 2, paddingBottom: 2 }}
        >
          <InvoiceAttachments
            invoiceAttachmentTypes={invoiceAttachmentTypes}
            watch={watch}
            setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
            register={register}
            formErrors={formErrors}
            control={control}
          />
        </Paper>
        <Paper
          variant="outlined"
          sx={{ marginTop: 3, paddingX: 2, paddingBottom: 3 }}
        >
          <InvoiceData
            formErrors={formErrors}
            setError={setError}
            clearErrors={clearErrors}
            invoiceDate={invoiceDate}
            invoiceTypes={invoiceTypes}
            currencies={currencies}
            incoterms={incoterms}
            incotermsComplements={incotermsComplements}
            serviceLevel={serviceLevel}
            domesticCarrier={domesticCarrier}
            countries={countries}
            transportationMode={transportationMode}
            freightForwarder={freightForwarders}
            broker={brokers}
            customRegimes={customRegimes}
            control={control}
            register={register}
            getReceivedDate={(date) => setReceivedDate(date)}
            watch={watch}
            invoice={invoice as Invoice}
            disableAll={!canEditInvoice}
          />
        </Paper>
      </Accordion>

      <Paper
        variant="outlined"
        sx={{
          marginTop: 3,
          marginBottom: 3,
          paddingX: 2,
          paddingY: 3,
          display: "flex",
          justifyContent: "end",
          gap: 2,
        }}
      >
        <Button
          sx={{
            marginRight: "auto",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            color: "currentColor",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              color: "currentColor",
            },
          }}
          variant="contained"
          color="secondary"
          onClick={onReturn}
        >
          Return
        </Button>
        {
          idInvoice && (
            <Link href={`/invoice/details/${invoice?.ID_INVOICE}`}>
              <Button
                sx={{ marginRight: 2 }}
                variant="contained"
                startIcon={<VisibilityIcon />}
              >
                Details
              </Button>
            </Link>
          )
        }
        {
          canEditInvoice && openInvoiceAccordion && (
            <>
              <Button
                variant="outlined"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
              >
                Confirm
              </Button>
            </>
          )
        }
      </Paper>

      {idInvoice && (
        <Accordion
          sx={{ "&.MuiAccordion-root": { border: 0 } }}
          onChange={() => setOpenInvoiceDiscrepanciesAccordion(!openInvoiceDiscrepanciesAccordion)}
          disabled={!idInvoice}
          expanded={!!idInvoice && openInvoiceDiscrepanciesAccordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            hasData
          >
            <Typography>DISCREPANCY</Typography>
          </AccordionSummary>
          <InvoiceDiscrepancy
            invoice={invoice}
            onUpdate={onUpdate}
          />
        </Accordion>
      )}
    </form>
  );
}
