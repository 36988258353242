import {alpha, Skeleton, styled} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

interface DataTableProps {
  [x: string]: any;
}

export default function DataTableDefault({
  rowId,
  columns,
  data,
  checkboxSelection = false,
  onRowSelectionModelChange,
  loading = false,
  emptyFooter = false,
  ...props
}: DataTableProps) {
  const ODD_OPACITY = 0.1;

  const StripedDataGrid = styled(DataGrid)(({ theme }: any) => ({
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: theme.palette.grey[200],
      maxHeight: "100% !important",
      textOverflow: "inherit !important",
      "&:hover, &.Mui-hovered": {
        backgroundColor: theme.palette.grey[200],
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity
          ),
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
    "& .MuiDataGrid-cell, & .MuiDataGrid-cellContent": {
      textOverflow: "clip",
      textWrap: "wrap !important",
    },
    "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
      {
        textWrap: "wrap !important",
      },
    "& .MuiDataGrid-row": {
      height: "100% !important",
    },
    "& .odd": {
      padding: "20",
    },
  }));

  if (loading) {
    return (
        <>
          {[1, 2].map((item) => (
              <Skeleton
                  key={`datatable0-ske-${item}`}
                  variant="rectangular"
                  sx={{ minHeight: "56px", marginBottom: "6px", height: "100%" }}
              />
          ))}
        </>
    );
  }

  return (
    <div data-testid="DataTableDefault-test">
      <Box
        sx={{
          width: "100%",
          "& .super-app-theme--header": {
            backgroundColor: "rgba(88, 88, 88, 0.55)",
          },
        }}
      >
        <StripedDataGrid
          sx={{
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "22px",
            },
          }}
          disableVirtualization={process.env.NODE_ENV === 'test'}
          getRowHeight={() => 56}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          {...props}
          checkboxSelection={checkboxSelection}
          onRowSelectionModelChange={onRowSelectionModelChange}
          getRowId={(row: any) => `${JSON.stringify(row)}`}
          rows={data}
          columns={columns}
          getRowClassName={(params: any) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          slots={{ ...(emptyFooter && { pagination: () => <div></div> }) }}
        />
      </Box>
    </div>
  );
}
