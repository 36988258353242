import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Autocomplete,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { CompanyCombo } from "../../../../../types/company";
import { AutomaticAuthorizationProps } from "../../../../../types/automaticAuthorization";
import { ComboBoxType } from "../../../../../types/general";
import { findCustomRegime, findShipmentInstruction } from "../../../../../services/automaticAuthorizationRules";

const transportationModeData = [
  {
    value: "AIR",
    label: "AIR",
  },
  {
    value: "ROAD",
    label: "ROAD",
  },
  {
    value: "OCEAN",
    label: "OCEAN",
  },
];

const incotermsFreight = [
  {
    value: "COLLECT",
    label: "COLLECT",
  },
  {
    value: "PREPAID",
    label: "PREPAID",
  },
];

const centerScheme = yup.object({
  DESCRICAO_REGRA: yup.string().max(40).required(),
  ID_CENTRO: yup.number().required(),
  ID_CENTRO_CONSOLIDADOR: yup.number().required(),
  ID_MODAL: yup.string().required(),
  ID_TIPO_MATERIAL: yup.number().required(),
  ID_REGIME: yup.number(),
  ID_FRETE_INCO: yup.string().required(),
  ID_PRIORIDADE: yup.number().required(),
  ID_AEROPORTO_ORIGEM: yup.number().required(),
  ID_AEROPORTO_DESTINO_PRINCIPAL: yup.number().required(),
  ID_AEROPORTO_DESTINO_ALTERNATI: yup.number().required(),
  ID_INSTRUCAO_EMBARQUE: yup.number().required(),
  ATIVO: yup.boolean().notRequired(),
  ID_ORGAO_ANUENTE: yup.number().required(),
});

interface CenterFormProps {
  automaticAuthorization: any;
  onSubmit: (center: AutomaticAuthorizationProps) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  centerCombo?: any[];
  consolidationCenterCombo?: any[];
  materialTypeCombo?: any[];
  serviceLevelCombo?: any[];
  airportCombo?: any[];
  governmentsAuthoritiesCombo?: any[];
  readOnly?: boolean;
}

export default function CenterForm({
                                     automaticAuthorization,
                                     onSubmit,
                                     onCancel,
                                     isLoading = false,
                                     consolidationCenterCombo = [],
                                     errors = [],
                                     controlEdit,
                                     centerCombo,
                                     materialTypeCombo,
                                     serviceLevelCombo,
                                     airportCombo,
                                     governmentsAuthoritiesCombo = [],
                                     readOnly,
                                   }: CenterFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(centerScheme),
  });

  const [erroMap, setErroMap] = useState<string[]>([]);
  const [shipmentInstructionCombo, setShipmentInstructionCombo] = useState<ComboBoxType[]>([] as ComboBoxType[]);
  const [customRegimeCombo, setCustomRegimeCombo] = useState<ComboBoxType[]>([] as ComboBoxType[]);

  useEffect(() => {
      (async function () {
          const customRegimes = await findCustomRegime();
          setCustomRegimeCombo(customRegimes.data);
      })();
  }, []);

    useEffect(() => {
        setErroMap(errors);
    }, [errors]);


  useEffect(() => {
    if (automaticAuthorization?.hasOwnProperty("DESCRICAO_REGRA")) {
      triggerCenter(automaticAuthorization.GC_ID_CENTRO);
      reset(automaticAuthorization);
    }
  }, [automaticAuthorization, reset]);

  const handleFormSubmit = (newCenter: any) => {
    setErroMap([]);
    onSubmit(newCenter);
  };

  const handleCancel = () => {
    setErroMap([]);
    onCancel();
  };

  const triggerCenter = async (id: number) => {
    const shipmentInstructionResponse = await findShipmentInstruction(id);
    setShipmentInstructionCombo(shipmentInstructionResponse.data);
  }

  return (
      <>
        <form
            style={{ width: "100%" }}
            onSubmit={handleSubmit(handleFormSubmit)}
            data-testid="center-form"
        >
          {erroMap?.map((error) => (
              <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
                {error}
              </Alert>
          ))}
          <Grid
              container
              spacing={2}
              marginTop={1}
              border={1}
              padding={2}
              borderColor={"#7b7b7b"}
              borderRadius={2}
              alignItems="center"
          >
            {controlEdit.loading ? (
                <></>
            ) : (
                <>
                  <Grid item lg={4} md={4} xs={12}>
                    <Controller
                        defaultValue={
                            automaticAuthorization?.DESCRICAO_REGRA || undefined
                        }
                        control={control}
                        render={({ field: { value } }) => (
                            <TextField
                                fullWidth
                                type="text"
                                label="Description Rule"
                                disabled={controlEdit.isInUse || readOnly}
                                size="small"
                                error={!!formErrors.DESCRICAO_REGRA}
                                InputLabelProps={{ shrink: !!value }}
                                {...register("DESCRICAO_REGRA")}
                                inputProps={{ maxLength: 40, 'data-testid': 'DESCRICAO_REGRA-input' }}
                            />
                        )}
                        name="DESCRICAO_REGRA"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <Controller
                        defaultValue={
                            automaticAuthorization?.GC_ID_CENTRO || undefined
                        }
                        control={control}
                        render={({ field: { value, ref, onBlur, name, onChange, ...field } }) => (
                            <Autocomplete
                                options={centerCombo || []}
                                disabled={controlEdit.isInUse || readOnly}
                                getOptionLabel={(option) => `${option.description}`}
                                value={
                                    centerCombo?.findLast(
                                        (item: any) => item.id === value
                                    ) || null
                                }
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        {...field}
                                        label="Company - Plant"
                                        size="small"
                                        inputRef={ref}
                                        disabled={controlEdit.isInUse || readOnly}
                                        error={!!formErrors.ID_CENTRO}
                                        variant="outlined"
                                        data-testid="ID_CENTRO-input"
                                    />
                                )}
                                onChange={(_: any, data: any) => {
                                  onChange(data?.id);
                                  triggerCenter(data?.id);
                                }}
                            />
                        )}
                        name="ID_CENTRO"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <Controller
                        defaultValue={
                            automaticAuthorization?.GCC_ID_CENTRO_CONSOLIDADOR ||
                            undefined
                        }
                        control={control}
                        render={({ field: { value, ref, onChange, ...field } }) => (
                            <Autocomplete
                                options={consolidationCenterCombo || []}
                                disabled={controlEdit.isInUse || readOnly}
                                getOptionLabel={(option: CompanyCombo) =>
                                    `${option.description}`
                                }
                                value={
                                    consolidationCenterCombo?.findLast(
                                        (item: any) => item.id === value
                                    ) || null
                                }
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        {...field}
                                        label="Consolidation Center"
                                        size="small"
                                        inputRef={ref}
                                        disabled={controlEdit.isInUse || readOnly}
                                        error={!!formErrors.ID_CENTRO_CONSOLIDADOR}
                                        variant="outlined"
                                    />
                                )}
                                onChange={(_: any, data: any) => {
                                  onChange(data?.id);
                                }}
                            />
                        )}
                        name="ID_CENTRO_CONSOLIDADOR"
                    />
                  </Grid>
                </>
            )}

            <Grid item lg={4} md={4} xs={12}>
              <Controller
                  defaultValue={automaticAuthorization?.ID_MODAL || undefined}
                  control={control}
                  render={({ field: { value, ref, onChange, ...field } }) => (
                      <Autocomplete
                          options={transportationModeData}
                          disabled={controlEdit.isInUse || readOnly}
                          getOptionLabel={(option) => option.label}
                          value={
                              transportationModeData?.findLast(
                                  (item) => item.value === value
                              ) || null
                          }
                          renderInput={(params: any) => (
                              <TextField
                                  {...params}
                                  {...field}
                                  label="Transportation Mode"
                                  size="small"
                                  disabled={controlEdit.isInUse || readOnly}
                                  inputRef={ref}
                                  error={!!formErrors.ID_MODAL}
                                  variant="outlined"
                              />
                          )}
                          onChange={(_: any, data: any) => {
                            onChange(data?.value);
                          }}
                      />
                  )}
                  name="ID_MODAL"
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <Controller
                  defaultValue={
                      automaticAuthorization?.GTM_ID_TIPO_MATERIAL || undefined
                  }
                  control={control}
                  render={({ field: { value, ref, onChange, ...field } }) => (
                      <Autocomplete
                          options={materialTypeCombo || []}
                          disabled={controlEdit.isInUse || readOnly}
                          getOptionLabel={(option) => `${option.description}`}
                          value={
                              materialTypeCombo?.findLast(
                                  (item: any) => item.id === value
                              ) || null
                          }
                          renderInput={(params: any) => (
                              <TextField
                                  {...params}
                                  {...field}
                                  label="Material Type"
                                  size="small"
                                  inputRef={ref}
                                  disabled={controlEdit.isInUse || readOnly}
                                  error={!!formErrors.ID_TIPO_MATERIAL}
                                  variant="outlined"
                              />
                          )}
                          onChange={(_: any, data: any) => {
                            onChange(data?.id);
                          }}
                      />
                  )}
                  name="ID_TIPO_MATERIAL"
              />
            </Grid>
            <Grid item md={4} xl={4} xs={12}>
              <Controller
                  defaultValue={automaticAuthorization?.ID_FRETE_INCO || undefined}
                  control={control}
                  render={({ field: { value, ref, onChange, ...field } }) => (
                      <Autocomplete
                          options={incotermsFreight}
                          disabled={controlEdit.isInUse || readOnly}
                          getOptionLabel={(option) => option.label}
                          value={
                              incotermsFreight?.findLast(
                                  (item) => item.value === value
                              ) || null
                          }
                          renderInput={(params: any) => (
                              <TextField
                                  {...params}
                                  {...field}
                                  label="Freight"
                                  size="small"
                                  disabled={controlEdit.isInUse || readOnly}
                                  inputRef={ref}
                                  error={!!formErrors.ID_FRETE_INCO}
                                  variant="outlined"
                              />
                          )}
                          onChange={(_: any, data: any) => {
                            onChange(data?.value);
                          }}
                      />
                  )}
                  name="ID_FRETE_INCO"
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <Controller
                  control={control}
                  defaultValue={
                      automaticAuthorization?.GP_ID_PRIORIDADE || undefined
                  }
                  render={({ field: { value, ref, onChange, ...field } }) => (
                      <Autocomplete
                          options={serviceLevelCombo || []}
                          disabled={controlEdit.isInUse || readOnly}
                          getOptionLabel={(option) => option.description}
                          value={
                              serviceLevelCombo?.findLast((item) => item.id === value) ||
                              null
                          }
                          renderInput={(params: any) => (
                              <TextField
                                  {...params}
                                  {...field}
                                  label="Service Level"
                                  size="small"
                                  disabled={controlEdit.isInUse || readOnly}
                                  inputRef={ref}
                                  error={!!formErrors.ID_PRIORIDADE}
                                  variant="outlined"
                              />
                          )}
                          onChange={(_: any, data: any) => {
                            onChange(data?.id);
                          }}
                      />
                  )}
                  name="ID_PRIORIDADE"
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <Controller
                  control={control}
                  defaultValue={
                      automaticAuthorization?.GAO_ID_AEROPORTO || undefined
                  }
                  render={({ field: { value, ref, onChange, ...field } }) => (
                      <Autocomplete
                          options={airportCombo || []}
                          disabled={controlEdit.isInUse || readOnly}
                          getOptionLabel={(option) => option.description}
                          value={
                              airportCombo?.findLast((item) => item.id === value) || null
                          }
                          renderInput={(params: any) => (
                              <TextField
                                  {...params}
                                  {...field}
                                  label="Port/Origin Airport"
                                  size="small"
                                  disabled={controlEdit.isInUse || readOnly}
                                  inputRef={ref}
                                  error={!!formErrors.ID_AEROPORTO_ORIGEM}
                                  variant="outlined"
                              />
                          )}
                          onChange={(_: any, data: any) => {
                            onChange(data?.id);
                          }}
                      />
                  )}
                  name="ID_AEROPORTO_ORIGEM"
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <Controller
                  defaultValue={
                      automaticAuthorization?.GAD_ID_AEROPORTO || undefined
                  }
                  control={control}
                  render={({ field: { value, ref, onChange, ...field } }) => (
                      <Autocomplete
                          disabled={controlEdit.isInUse || readOnly}
                          options={airportCombo || []}
                          getOptionLabel={(option) => option.description}
                          value={
                              airportCombo?.findLast((item) => item.id === value) || null
                          }
                          renderInput={(params: any) => (
                              <TextField
                                  {...params}
                                  {...field}
                                  label="Primary Destination"
                                  size="small"
                                  disabled={controlEdit.isInUse || readOnly}
                                  inputRef={ref}
                                  error={!!formErrors.ID_AEROPORTO_DESTINO_PRINCIPAL}
                                  variant="outlined"
                              />
                          )}
                          onChange={(_: any, data: any) => {
                            onChange(data?.id);
                          }}
                      />
                  )}
                  name="ID_AEROPORTO_DESTINO_PRINCIPAL"
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <Controller
                  defaultValue={
                      automaticAuthorization?.GAA_ID_AEROPORTO || undefined
                  }
                  control={control}
                  render={({ field: { value, ref, onChange, ...field } }) => (
                      <Autocomplete
                          disabled={controlEdit.isInUse || readOnly}
                          options={airportCombo || []}
                          getOptionLabel={(option) => option.description}
                          value={
                              airportCombo?.findLast((item) => item.id === value) || null
                          }
                          renderInput={(params: any) => (
                              <TextField
                                  {...params}
                                  {...field}
                                  label="Alternative Destination"
                                  size="small"
                                  disabled={controlEdit.isInUse || readOnly}
                                  inputRef={ref}
                                  error={!!formErrors.ID_AEROPORTO_DESTINO_ALTERNATI}
                                  variant="outlined"
                              />
                          )}
                          onChange={(_: any, data: any) => {
                            onChange(data?.id);
                          }}
                      />
                  )}
                  name="ID_AEROPORTO_DESTINO_ALTERNATI"
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <Controller
                  defaultValue={
                      automaticAuthorization?.GOA_ID_ORGAO_ANUENTE || undefined
                  }
                  control={control}
                  render={({ field: { value, ref, onChange, ...field } }) => (
                      <Autocomplete
                          options={governmentsAuthoritiesCombo}
                          disabled={controlEdit.isInUse || readOnly}
                          getOptionLabel={(option) => option.description}
                          value={
                              governmentsAuthoritiesCombo?.findLast(
                                  (item) => item.id === value
                              ) || null
                          }
                          renderInput={(params: any) => (
                              <TextField
                                  {...params}
                                  {...field}
                                  label="Goverment Authority"
                                  size="small"
                                  disabled={controlEdit.isInUse || readOnly}
                                  inputRef={ref}
                                  error={!!formErrors.ID_ORGAO_ANUENTE}
                                  variant="outlined"
                              />
                          )}
                          onChange={(_: any, data: any) => {
                            onChange(data?.id);
                          }}
                      />
                  )}
                  name="ID_ORGAO_ANUENTE"
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <Controller
                  control={control}
                  defaultValue={automaticAuthorization?.GR_ID_REGIME || undefined}
                  render={({ field: { value, ref, onChange, ...field } }) => (
                      <Autocomplete
                          options={customRegimeCombo || []}
                          disabled={controlEdit.isInUse || readOnly}
                          getOptionLabel={(option) => option.description}
                          value={
                              customRegimeCombo?.findLast((item) => item.id === value) ||
                              null
                          }
                          renderInput={(params: any) => (
                              <TextField
                                  {...params}
                                  {...field}
                                  label="Custom Regime"
                                  size="small"
                                  disabled={controlEdit.isInUse || readOnly}
                                  inputRef={ref}
                                  error={!!formErrors.ID_REGIME}
                                  variant="outlined"
                              />
                          )}
                          onChange={(_: any, data: any) => {
                            onChange(data?.id);
                          }}
                      />
                  )}
                  name="ID_REGIME"
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <Controller
                  control={control}
                  defaultValue={
                      automaticAuthorization?.GIE_ID_INSTRUCAO_EMBARQUE || undefined
                  }
                  render={({ field: { value, ref, onChange, ...field } }) => (
                      <Autocomplete
                          options={shipmentInstructionCombo || []}
                          disabled={controlEdit.isInUse || readOnly}
                          getOptionLabel={(option) => option.description}
                          value={
                              shipmentInstructionCombo?.findLast(
                                  (item) => item.id === value
                              ) || null
                          }
                          renderInput={(params: any) => (
                              <TextField
                                  {...params}
                                  {...field}
                                  label="Shipment Instruction"
                                  size="small"
                                  disabled={controlEdit.isInUse || readOnly}
                                  inputRef={ref}
                                  error={!!formErrors.ID_INSTRUCAO_EMBARQUE}
                                  variant="outlined"
                              />
                          )}
                          onChange={(_: any, data: any) => {
                            onChange(data?.id);
                          }}
                      />
                  )}
                  name="ID_INSTRUCAO_EMBARQUE"
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                  defaultValue={automaticAuthorization?.ATIVO || true}
                  render={({ field: { value } }) => (
                      <FormControlLabel
                          control={
                            <Switch
                                // disabled={controlEdit.isInUse}
                                inputProps={{ "aria-label": "controlled" }}
                                checked={!!value}
                                {...register("ATIVO")}
                                disabled={readOnly}
                            />
                          }
                          labelPlacement="start"
                          label="Status"
                      />
                  )}
                  name="ATIVO"
                  control={control}
              />
            </Grid>
            <Grid container justifyContent="flex-end" marginTop={4}>
              <Grid>
                <Button
                    variant="outlined"
                    onClick={handleCancel}
                    disabled={isLoading}
                    data-testid="cancel-button"
                >
                  Cancel
                </Button>
              </Grid>
              {!readOnly ? (
                  <Grid marginLeft={2}>
                    <Button
                        variant="contained"
                        disabled={isLoading}
                        type="submit"
                        data-testid="submit-button"
                    >
                      Confirm
                    </Button>
                  </Grid>
              ) : null}
            </Grid>
          </Grid>
        </form>
      </>
  );
}
