import { BoardingAuthorization } from "./boardingAuthorization";
import { BoardingProcess } from "./boardingProcess";
import { Center } from "./center";
import { Country } from "./country";
import { Currency } from "./currency";
import { CustomRegime } from "./customRegime";
import { IncotermsType } from "./incoterms";
import { IncotermsComplementType } from "./incotermsComplement";
import { InvoiceDiscrepancy } from "./invoiceDiscrepancy";
import { InvoiceItemPo } from "./invoiceItemPo";
import { InvoiceNote } from "./invoiceNote";
import { InvoicePackageDetail } from "./packageDetail";
import { PartnerService, Supplier } from "./partner";
import { Po } from "./po";
import { ServiceLevel } from "./serviceLevel";
import { Tracking } from "./tracking";
import { UserLogin } from "./userLogin";
import { Warehouse } from "./warehouse";

export interface IFindInvoice {
  dialog?: Dialog,
  action?: string,
  po?: Po,
  invoice?: Invoice,
}

export interface Dialog {
  message: string
  options: DialogAction[]
}

export interface DialogAction {
  action: string
  buttonText: string
}

export enum InvoiceStatus {
  REGISTERED_INVOICE = 'Registered Invoice',
  PENDING = 'Pending',
  PENDING_SHIPMENT_AUTHORIZATION = "Pending Shipment Authorization",
  WAITING_SISTER_INVOICE = 'Waiting Sister Invoice',
  SHIPMENT_AUTHORIZED = "Shipment Authorized",
  INTERNATIONAL_TRANSIT = 'International Transit',
  CUSTOMS_CLEARANCE = 'Customs Clearance',
  DOMESTIC_TRANSIT = 'Domestic Transit',
  CONCLUDED = 'Concluded',
  FINALIZED = 'Finalized',
  CANCELED = 'Canceled',
}

export enum InvoiceType {
  INBOUND_INTERNATIONAL = 'Inbound - International',
  INBOUND_DOMESTIC = 'Inbound - Domestic',
  TRIAGE_DOMESTIC = 'Triage - Domestic',
  TRIAGE_INTERNATIONAL = 'Triage - International',
}

export enum TransportationMode {
  AIR = 'AIR',
  ROAD = 'ROAD',
  OCEAN = 'OCEAN',
}

export interface Invoice {
  ID_INVOICE?: number
  centro: Center
  ID_CENTRO?: number
  NUM_INVOICE: string
  DATA_INVOICE: Date
  DATA_RECEBIMENTO: Date
  po: Po
  ID_PO?: number
  ID_TIPO_INVOICE: InvoiceType
  currency: Currency
  ID_MOEDA: number
  VALOR_TOTAL: number
  VALOR_TAXAS_EXTRAS?: number
  fornecedor: Supplier
  ID_FORNECEDOR: number
  CODIGO_MFIR_FORN: string
  NOME_FORNECEDOR: string
  DSC_ENDERECO1_FORNECEDOR?: string
  DSC_ENDERECO2_FORNECEDOR?: string
  DSC_CIDADE_FORNECEDOR?: string
  DSC_ESTADO_FORNECEDOR?: string
  CEP_FORNECEDOR?: string
  paisFornecedor?: Country
  ID_PAIS_FORNECEDOR?: number
  SIGLA_PAIS_FORNECEDOR?: string
  incoterms: IncotermsType
  ID_INCOTERMS: number
  incotermsComplement: IncotermsComplementType
  ID_INCOTERMS_COMPL: number
  DSC_INCOTERMS_COMPL?: string
  serviceLevel: ServiceLevel
  ID_PRIORIDADE: number
  NUM_TRACKING?: string
  ID_MODAL?: TransportationMode
  paisOrigem: Country
  ID_PAIS_ORIGEM?: number
  customsRegime: CustomRegime
  ID_REGIME?: number
  idImportadorCentro: Center
  ID_IMPORTADOR?: number
  MFIR_IMPORTADOR?: string
  NOME_IMPORT?: string
  DSC_ENDERECO1_IMPORT?: string
  DSC_CIDADE_IMPORT?: string
  DSC_ESTADO_IMPORT?: string
  CEP_IMPORT?: string
  SIGLA_PAIS_IMPORT?: string
  CNPJ_IMPORT?: string
  DATA_REGISTRO?: Date
  CAD_AUTOMATICO: boolean
  parceiroAgenteCarga: PartnerService
  ID_PARCEIRO_AGENTE_CARGA?: number
  parceiroDomesticCarrier: PartnerService
  ID_PARCEIRO_DOMESTIC_CARRIER?: number
  parceiroBroker: PartnerService
  ID_PARCEIRO_BROKER?: number
  warehouse: Warehouse
  ID_WAREHOUSE?: number
  ID_STATUS_INVOICE: InvoiceStatus
  warehouseOld: Warehouse
  ID_WAREHOUSE_OLD?: number
  NUM_PESO_BRUTO?: number
  autorizacaoEmbarque: BoardingAuthorization
  ID_AUTORIZACAO_EMBARQUE?: number
  processoEmbarque: BoardingProcess
  ID_PROCESSO_EMBARQUE?: number
  CODIGO_PROCESSO?: string
  INTERCOMPANY: boolean
  DATA_ESTORNO_1A_CONF?: Date
  DSC_PEND?: string
  DATA_ENC_PENDENCIA?: Date
  DATA_ENTRADA_PORTARIA?: Date
  loginUsuario: UserLogin
  ID_LOGIN_USUARIO: number
  invoicePackages: InvoicePackageDetail[]
  invoiceDiscrepancies: InvoiceDiscrepancy[];
  invoiceItemsPo: InvoiceItemPo[]
  invoiceNotes: InvoiceNote[]
  invoiceAttachments: InvoiceAttachment[]
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  trackingNumber?: Tracking
}

export type InvoiceFormModel = {
  ID_INVOICE?: number;
  ID_CENTRO?: number;
  NUM_INVOICE: string;
  DATA_INVOICE: string;
  DATA_RECEBIMENTO: string;
  ID_PO?: number;
  ID_TIPO_INVOICE: InvoiceType;
  ID_MOEDA: number;
  VALOR_TOTAL: string;
  VALOR_TAXAS_EXTRAS: string;
  ID_FORNECEDOR: number;
  CODIGO_MFIR_FORN: string;
  NOME_FORNECEDOR: string;
  DSC_ENDERECO1_FORNECEDOR?: string;
  DSC_ENDERECO2_FORNECEDOR?: string;
  DSC_CIDADE_FORNECEDOR?: string;
  DSC_ESTADO_FORNECEDOR?: string;
  CEP_FORNECEDOR?: string;
  ID_PAIS_FORNECEDOR?: number;
  SIGLA_PAIS_FORNECEDOR?: string;
  ID_INCOTERMS: number;
  ID_INCOTERMS_COMPL: number;
  DSC_INCOTERMS_COMPL?: string;
  ID_PRIORIDADE: number;
  NUM_TRACKING?: string;
  ID_MODAL?: TransportationMode;
  ID_PAIS_ORIGEM?: number;
  ID_REGIME?: number;
  ID_IMPORTADOR?: number;
  MFIR_IMPORTADOR?: string;
  NOME_IMPORT?: string;
  DSC_ENDERECO1_IMPORT?: string;
  DSC_CIDADE_IMPORT?: string;
  DSC_ESTADO_IMPORT?: string;
  CEP_IMPORT?: string;
  SIGLA_PAIS_IMPORT?: string;
  CNPJ_IMPORT?: string;
  DATA_REGISTRO?: Date;
  CAD_AUTOMATICO: boolean;
  ID_PARCEIRO_AGENTE_CARGA?: number;
  ID_PARCEIRO_DOMESTIC_CARRIER?: number;
  ID_PARCEIRO_BROKER?: number;
  ID_WAREHOUSE?: number;
  ID_STATUS_INVOICE: InvoiceStatus;
  ID_WAREHOUSE_OLD?: number;
  NUM_PESO_BRUTO?: string;
  ID_AUTORIZACAO_EMBARQUE?: number;
  ID_PROCESSO_EMBARQUE?: number;
  CODIGO_PROCESSO?: string;
  INTERCOMPANY: boolean;
  DATA_ESTORNO_1A_CONF?: Date;
  DSC_PEND?: string;
  DATA_ENC_PENDENCIA?: Date;
  DATA_ENTRADA_PORTARIA?: Date;
  ID_LOGIN_USUARIO: number;
  invoiceAttachments?: InvoiceAttachment[];
  invoiceAttachmentsToDelete?: {
    ID_TIPO_ANEXO: string;
    ID_ANEXO_INVOICE: number;
  }[];
  hasDiscrepancy?: { DSC_OBSERVACAO: string; }[]
  po?: Po;
  invoiceItemsPo: InvoiceItemPo[];
  invoiceDiscrepancies: InvoiceDiscrepancy[];
}

export type InvoiceAttachment = {
  ID_INVOICE_ANEXO?: number;
  ID_TIPO_ANEXO: string;
  FILE?: File;
  FILE_NAME?: string;
  DSC_PATH?: string;
  NOME_ARQUIVO: string;
}

export const closedStatuses = [
  InvoiceStatus.CANCELED,
  InvoiceStatus.CONCLUDED,
  InvoiceStatus.FINALIZED
];

export const openStatuses = [
  InvoiceStatus.REGISTERED_INVOICE,
  InvoiceStatus.PENDING,
  InvoiceStatus.WAITING_SISTER_INVOICE,
  InvoiceStatus.PENDING_SHIPMENT_AUTHORIZATION,
  InvoiceStatus.SHIPMENT_AUTHORIZED,
  InvoiceStatus.INTERNATIONAL_TRANSIT,
  InvoiceStatus.CUSTOMS_CLEARANCE,
  InvoiceStatus.DOMESTIC_TRANSIT,
];

export type InvoiceStatusInAndOutSLA = {
  [key: string]: {
      IN_SLA: number,
      OUT_SLA: number
  }
}

export const InvoiceStatusDate: { [key: string]: string } = {
  [InvoiceStatus.REGISTERED_INVOICE]: 'created_at',
  [InvoiceStatus.PENDING]: 'DATA_PENDING',
  [InvoiceStatus.PENDING_SHIPMENT_AUTHORIZATION]: 'DATA_PENDING_SHIP_AUTH',
  [InvoiceStatus.WAITING_SISTER_INVOICE]: 'DATA_WAITING_SISTER',
  [InvoiceStatus.SHIPMENT_AUTHORIZED]: 'DATA_SHIP_AUTH',
  [InvoiceStatus.INTERNATIONAL_TRANSIT]: 'DATA_INTERN_TRANSIT',
  [InvoiceStatus.CUSTOMS_CLEARANCE]: 'DATA_CUSTOM_CLEARENCE',
  [InvoiceStatus.DOMESTIC_TRANSIT]: 'DATA_DOMESTIC_TRANSIT',
};

export enum InvoicesSections {
  INVOICE_DETAILS = 'Invoice Details',
  WAREHOUSE_RECEIPT = 'Warehouse Receipt',
  PACKAGE_DETAILS = 'Package Details',
  INVOICE_NOTES = 'Invoice Notes',
  DISCREPANCY = 'Discrepancy',
}