import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { ComboBoxType } from "../../types/general";

let searchScheme = yup.object({
  searchGeneral: yup.mixed().notRequired(),
  ATIVO: yup.string().notRequired(),
  ID_CENTRO: yup.number().notRequired(),
  CODIGO_EDI: yup.string().notRequired(),
  CODIGO_SAP_EDI: yup.string().notRequired(),
  ID_FRETE_INCO: yup.string().notRequired(),
  ID_MODAL_TRANSPORTE: yup.string().notRequired(),
  TIPO_INTERFACE: yup.string().notRequired(),
  ID_CENTRO_CONSOLIDADOR: yup.number().notRequired(),
  ID_TIPO_MATERIAL: yup.number().notRequired(),
  ID_PRIORIDADE: yup.number().notRequired(),
  ID_AEROPORTO_ORIGEM: yup.number().notRequired(),
  ID_AEROPORTO_DESTINO_PRINCIPAL: yup.number().notRequired(),
  ID_TIPO_EMAIL: yup.number().notRequired(),
  ID_TIPO_PARCEIRO: yup.number().notRequired(),
  ID_PARCEIRO_AGENTE_CARGA: yup.number().notRequired(),
  DG: yup.boolean().notRequired(),
  ID_PAIS_ORIGEM: yup.number().notRequired(),
  ID_PAIS_DESTINO: yup.number().notRequired(),
});
interface ISearch {
  onChange: (parmas: IParams) => void;
  disableStatus?: boolean;
  centerPlant?: any[];
  slaType?: boolean;
  ediCode?: boolean;
  sapCode?: boolean;
  isIconterms?: any;
  transportationMode?: any;
  isInterfaceBy?: boolean;
  consolidationCenterCombo?: any[];
  materialTypeCombo?: any[];
  serviceLevelCombo?: any[];
  airportCombo?: any[];
  isDestinationAirport?: boolean;
  isGeneral?: boolean;
  useEmailTypesCombo?: any[];
  originCountryCombo?: ComboBoxType[],
  destinationCountryCombo?: ComboBoxType[],
  dg?: boolean,
  partnerFreightForwarderCombo: ComboBoxType[],
}

interface IParams {
  page: number;
  searchGeneral?: string;
  status?: boolean;
}
export default function Search({
  onChange,
  disableStatus,
  centerPlant,
  slaType = false,
  ediCode = false,
  sapCode = false,
  isIconterms,
  transportationMode,
  isInterfaceBy = false,
  consolidationCenterCombo,
  isDestinationAirport = false,
  materialTypeCombo,
  serviceLevelCombo,
  useEmailTypesCombo,
  airportCombo,
  originCountryCombo,
  destinationCountryCombo,
  dg,
  partnerFreightForwarderCombo,
  isGeneral = true,
  data,
}: ISearch) {
  const { control } = useForm({
    resolver: yupResolver(searchScheme),
  });
  const [params, setParams] = useState<IParams>({} as IParams);
  const statusMock = [
    { label: "Active", status: true },
    { label: "Inactive", status: false },
  ];

  const interfaceMock = [
    { label: "FILE", value: "FILE" },
    { label: "API", value: "API" },
  ];

  const checkboxOptions = [{ label: "True", value: true }, { label: "False", value: false }];

  const handleSearch = () => {
    onChange({ ...params, page: 1 });
  };

  return (
    <Grid
      data-testid="search"
      container
      spacing={3}
      marginTop={4}
      alignItems="center"
    >
      {!!isGeneral && (
        <Grid item xs={3}>
          <TextField
            data-testid="search-searchGeneral-input"
            fullWidth
            type="text"
            label="General Search"
            defaultValue={null}
            size="small"
            onChange={(e) => {
              setParams((prevState) => ({
                ...prevState,
                searchGeneral: e.target.value,
              }));
            }}
            onKeyDown={(event: React.KeyboardEvent) => {
              if (event.key === 'Enter') {
                handleSearch();
              }
            }}
          />
        </Grid>
      )}
      {!!ediCode && (
        <Grid item xs={2}>
          <TextField
            data-testid="search-searchGeneral-input"
            fullWidth
            type="text"
            label="EDI Code"
            defaultValue={null}
            size="small"
            onChange={(e) => {
              setParams((prevState) => ({
                ...prevState,
                CODIGO_EDI: e.target.value,
              }));
            }}
            onKeyDown={(event: React.KeyboardEvent) => {
              if (event.key === 'Enter') {
                handleSearch();
              }
            }}
          />
        </Grid>
      )}
      {!!sapCode && (
        <Grid item xs={2}>
          <TextField
            data-testid="search-searchGeneral-input"
            fullWidth
            type="text"
            label="Sap Code"
            defaultValue={null}
            size="small"
            onChange={(e) => {
              setParams((prevState) => ({
                ...prevState,
                CODIGO_SAP_EDI: e.target.value,
              }));
            }}
            onKeyDown={(event: React.KeyboardEvent) => {
              if (event.key === 'Enter') {
                handleSearch();
              }
            }}
          />
        </Grid>
      )}

      {!!centerPlant && (
        <Grid item xs={5} data-testid="search-status-select">
          <Controller
              control={control}
              render={({ field: { value, ref, onChange, ...field } }) => (
                  <Autocomplete
                      options={centerPlant}
                      getOptionLabel={(option) => option.description}
                      value={
                        data?.ID_CENTRO
                            ? centerPlant?.findLast((item: any) => item.id === data.ID_CENTRO)
                            : centerPlant?.findLast((item: any) => item.id === value) || null
                      }
                      renderInput={(params: any) => (
                          <TextField
                              {...params}
                              {...field}
                              label="Company - Plant"
                              size="small"
                              inputRef={ref}
                              variant="outlined"
                          />
                      )}
                      onChange={(_: any, data: any) => {
                        onChange(data?.id || null);
                        setParams((prevState) => ({
                          ...prevState,
                          ID_CENTRO: data?.id,
                        }));
                      }}
                  />
              )}
              name="ID_CENTRO"
          />
        </Grid>
      )}

      {!!slaType && (
        <Grid item lg={3} xs={12} data-testid="search-status-select">
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={[
                  {
                    description: "FREIGHT FORWARDER",
                    label: "FREIGHT FORWARDER",
                  },
                  {
                    description: "CARRIER",
                    label: "CARRIER",
                  },
                  {
                    description: "BROKER",
                    label: "BROKER",
                  }
                ]}
                getOptionLabel={(option) => option.description}
                value={
                  [
                    {
                      description: "FREIGHT FORWARDER",
                      label: "FREIGHT FORWARDER",
                    },
                    {
                      description: "CARRIER",
                      label: "CARRIER",
                    },
                    {
                      description: "BROKER",
                      label: "BROKER",
                    }
                  ].findLast((item: any) => item?.description === value) ||
                  null
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Type"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.description || null);
                  setParams((prevState) => ({
                    ...prevState,
                    ID_TIPO_PARCEIRO: data?.description,
                  }));
                }}
              />
            )}
            name="ID_TIPO_PARCEIRO"
          />
        </Grid>
      )}

      {!!useEmailTypesCombo && (
        <Grid item xs={5} data-testid="search-status-select">
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={useEmailTypesCombo || []}
                getOptionLabel={(option) => `${option.description}`}
                value={
                  useEmailTypesCombo?.findLast(
                    (item: any) => item.id === value
                  ) || null
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Type"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(parseInt(data?.id));
                  setParams((prevState) => ({
                    ...prevState,
                    ID_TIPO_EMAIL: data?.id,
                  }));
                }}
              />
            )}
            name="ID_TIPO_EMAIL"
          />
        </Grid>
      )}

      {!!materialTypeCombo && (
        <Grid item lg={4} md={4} xs={12}>
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={materialTypeCombo || []}
                getOptionLabel={(option) => `${option.description}`}
                value={
                  materialTypeCombo?.findLast(
                    (item: any) => item.id === value
                  ) || null
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Material Type"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.id);
                  setParams((prevState) => ({
                    ...prevState,
                    ID_TIPO_MATERIAL: data?.id,
                  }));
                }}
              />
            )}
            name="ID_TIPO_MATERIAL"
          />
        </Grid>
      )}

      {!!isInterfaceBy && (
        <Grid item xs={3} data-testid="search-status-select">
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={interfaceMock}
                getOptionLabel={(option) => option.label}
                value={
                  interfaceMock?.findLast(
                    (item: any) => item.value === value
                  ) || null
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Interface by"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.value || null);
                  setParams((prevState) => ({
                    ...prevState,
                    TIPO_INTERFACE: data?.value,
                  }));
                }}
              />
            )}
            name="TIPO_INTERFACE"
          />
        </Grid>
      )}

      {!!transportationMode && (
        <Grid item xs={4} data-testid="search-status-select">
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={transportationMode || []}
                getOptionLabel={(option) => option.label}
                value={
                  transportationMode?.findLast(
                    (item: any) => item?.value === value
                  ) || null
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Transportation Mode"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.value || null);
                  setParams((prevState) => ({
                    ...prevState,
                    ID_MODAL_TRANSPORTE: data?.value,
                  }));
                }}
              />
            )}
            name="ID_MODAL_TRANSPORTE"
          />
        </Grid>
      )}

      {!!isIconterms && (
        <Grid item xs={4} data-testid="search-status-select">
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={isIconterms}
                getOptionLabel={(option) => option.description}
                value={
                  isIconterms?.findLast((item: any) => item.id === value) ||
                  null
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Incoterms Freight Type"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.id || null);
                  setParams((prevState) => ({
                    ...prevState,
                    ID_FRETE_INCO: data?.description,
                  }));
                }}
              />
            )}
            name="ID_FRETE_INCO"
          />
        </Grid>
      )}
      {!!consolidationCenterCombo && (
        <Grid item lg={4} md={4} xs={12}>
          <Controller
              control={control}
              render={({ field: { value, ref, onChange, ...field } }) => (
                  <Autocomplete
                      options={consolidationCenterCombo || []}
                      getOptionLabel={(option) => `${option.description}`}
                      value={
                        data?.ID_CENTRO_CONSOLIDADOR
                            ? consolidationCenterCombo?.findLast((item: any) => item.id === data.ID_CENTRO_CONSOLIDADOR)
                            : consolidationCenterCombo?.findLast((item: any) => item.id === value) || null
                      }
                      renderInput={(params: any) => (
                          <TextField
                              {...params}
                              {...field}
                              label="Consolidation Center"
                              size="small"
                              inputRef={ref}
                              variant="outlined"
                          />
                      )}
                      onChange={(_: any, data: any) => {
                        onChange(data?.id || null);
                        setParams((prevState) => ({
                          ...prevState,
                          ID_CENTRO_CONSOLIDADOR: data?.id,
                        }));
                      }}
                  />
              )}
              name="ID_CENTRO_CONSOLIDADOR"
          />
        </Grid>
      )}

      {!!originCountryCombo && (
        <Grid item lg={3.5} md={4} xs={12}>
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={originCountryCombo || []}
                getOptionLabel={(option) => option.description}
                value={
                  originCountryCombo?.findLast((item) => item.id === value) ||
                  null
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Origin Country"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.id);
                  setParams((prevState) => ({
                    ...prevState,
                    ID_PAIS_ORIGEM: data?.id,
                  }));
                }}
              />
            )}
            name="ID_PAIS_ORIGEM"
          />
        </Grid>
      )}

      {!!destinationCountryCombo && (
        <Grid item lg={3.5} md={4} xs={12}>
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={destinationCountryCombo || []}
                getOptionLabel={(option) => option.description}
                value={
                  destinationCountryCombo?.findLast((item) => item.id === value) ||
                  null
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Destination Country"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.id);
                  setParams((prevState) => ({
                    ...prevState,
                    ID_PAIS_DESTINO: data?.id,
                  }));
                }}
              />
            )}
            name="ID_PAIS_DESTINO"
          />
        </Grid>
      )}

      {!!serviceLevelCombo && (
        <Grid item lg={4} md={4} xs={12}>
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={serviceLevelCombo || []}
                getOptionLabel={(option) => option.description}
                value={
                  serviceLevelCombo?.findLast((item) => item.id === value) ||
                  null
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Service Level"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.id);
                  setParams((prevState) => ({
                    ...prevState,
                    ID_PRIORIDADE: data?.id,
                  }));
                }}
              />
            )}
            name="ID_PRIORIDADE"
          />
        </Grid>
      )}
      {!!partnerFreightForwarderCombo && (
        <Grid item lg={4} md={4} xs={12}>
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={partnerFreightForwarderCombo || []}
                getOptionLabel={(option) => option.description}
                value={
                  partnerFreightForwarderCombo?.findLast((item) => item.id === value) ||
                  null
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Freight Forwarder"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.id);
                  setParams((prevState) => ({
                    ...prevState,
                    ID_PARCEIRO_AGENTE_CARGA: data?.id,
                  }));
                }}
              />
            )}
            name="ID_PARCEIRO_AGENTE_CARGA"
          />
        </Grid>
      )}

      {!!dg && (
        <Grid item lg={4} md={4} xs={12}>
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={checkboxOptions}
                getOptionLabel={(option) => option.label}
                value={
                  checkboxOptions?.findLast((item) => item.value === value) || null
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="DG"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.value);
                  setParams((prevState) => ({
                    ...prevState,
                    DG: data?.value,
                  }));
                }}
              />
            )}
            name="DG"
          />
        </Grid>
      )}

      {!!airportCombo && (
        <Grid item lg={4} md={4} xs={12}>
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={airportCombo || []}
                getOptionLabel={(option) => option.description}
                value={
                  airportCombo?.findLast((item) => item.id === value) || null
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Origin Airport"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.id);
                    setParams((prevState) => ({
                        ...prevState,
                        ID_AEROPORTO_ORIGEM: data?.id,
                    }));
                }}
              />
            )}
            name="ID_AEROPORTO_ORIGEM"
          />
        </Grid>
      )}
      {!!isDestinationAirport && (
        <Grid item lg={4} md={4} xs={12}>
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={airportCombo || []}
                getOptionLabel={(option) => option.description}
                value={
                  airportCombo?.findLast((item) => item.id === value) || null
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Primary Destination"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.id);
                    setParams((prevState) => ({
                        ...prevState,
                        ID_AEROPORTO_DESTINO_PRINCIPAL: data?.id,
                    }));
                }}
              />
            )}
            name="ID_AEROPORTO_DESTINO_PRINCIPAL"
          />
        </Grid>
      )}
      {!disableStatus && (
        <Grid item xs={2} data-testid="search-status-select">
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={statusMock}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => {
                  return (
                    option.label === value.label &&
                    option?.status === value?.status
                  );
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Status"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.status || null);
                  setParams((prevState) => ({
                    ...prevState,
                    ATIVO: data?.status,
                  }));
                }}
              />
            )}
            name="ATIVO"
          />
        </Grid>
      )}

      <Grid item xs={2} alignItems="flex-end">
        <Button
          variant="contained"
          onClick={handleSearch}
          fullWidth
          data-testid="search-button"
        >
          Search
        </Button>
      </Grid>
    </Grid>
  );
}
