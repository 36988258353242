import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import DataTableDefault from "../../../../components/DataTable";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useState} from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";

dayjs.extend(utc);

interface DataTableProps {
  data;
  loading: boolean;
  [x: string]: any;
}

export default function DataTable({
  data,
  loading,
  ...props
}: DataTableProps) {

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);

  const handleOpenDialog = (rowData: any) => {
    setSelectedRowData(rowData);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRowData(null);
  };

  const renderMenus = (row: any) => {
    return [
      <GridActionsCellItem
          key={`view-${row.ID_INTEGRACAO_REPROC}`}
          icon={<VisibilityIcon />}
          label="View"
          onClick={() => handleOpenDialog(row)}
          color="inherit"
          data-testid="view-button"
      />,
    ];
  };
  const columns: GridColDef[] = [
    {
      field: "ID_INTEGRACAO_REPROC",
      headerName: "ID",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "ID_INTEGRACAO_SAP",
      headerName: "INTERFACE NAME",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "DATA_ENTRADA",
      headerName: "DATE",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueFormatter: (params) => {
        const dateValue = params.value; // algo como "2024-12-05T16:45:09.000Z"
        return dayjs.utc(dateValue).format("MM/DD/YYYY HH:mm:ss");
      }
    },
    {
      field: "TIMESTAMP_INTEGRACAO",
      headerName: "TIMESTAMP",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueFormatter: (params) => {
        const dateValue = params.value; // algo como "2024-12-05T16:45:09.000Z"
        return dayjs.utc(dateValue).format("MM/DD/YYYY HH:mm:ss");
      }
    },
    {
      field: "DATA_PROCESSAMENTO",
      headerName: "PROCESS DATE",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueFormatter: (params) => {
        const dateValue = params.value; // algo como "2024-12-05T16:45:09.000Z"
        return dayjs.utc(dateValue).format("MM/DD/YYYY HH:mm:ss");
      }
    },
    {
      field: "actions",
      type: "actions",
      headerName: "DATA (JSON)",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 150,
      getActions: ({ row }) => renderMenus(row),
    },
  ];

  return (
      <>
        <DataTableDefault
            data={data[0] || []}
            columns={columns}
            loading={loading}
            {...props}
        />

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Data</DialogTitle>
          <DialogContent>
            {selectedRowData && (
                <div>
                  <pre style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '4px', overflow: 'auto' }}>
                    <code>
                      {JSON.stringify(selectedRowData.JSON_INTEGRACAO, null, 2)}
                    </code>
                  </pre>
                </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
  );
}
