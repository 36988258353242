import { Download } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { isAxiosError } from "axios";
import { useEffect, useState } from "react";

import Search from "../../../components/Search";
import {
  create,
  deleteIncotermsComplement,
  exportAll,
  update,
  useList,
  isInUse,
} from "../../../services/incotermsComplement";
import { getLocal, getToken } from "../../../storage/auth";
import { IncotermsComplementType } from "../../../types/incotermsComplement";
import DataTable from "./components/DataTable";
import IncotermsComplementForm from "./components/IncotermsComplementForm";
import { hasClaim } from "../../../storage/userClaims";
import { useSnackbar } from "notistack";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
}

export default function IncotermsComplements() {
  const { enqueueSnackbar } = useSnackbar();
  const [incotermsComplement, setIncotermsComplement] =
    useState<IncotermsComplementType>({
      ATIVO: true,
    } as IncotermsComplementType);
  const [incotermsComplementId, setIncotermsComplementId] = useState<string | number>();
  const [isSaving, setIsSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [token, setToken] = useState<string>();
  const [userValidation, setUserValidation] = useState<string>();
  const [controlEdit, setControlEdit] = useState({ loading: false, isInUse: false });
  const [readOnly, setReadOnly] = useState<boolean>(false);



  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = (getLocal("userInfo")) as any;
    setToken(getTokens);
    setUserValidation(JSON.parse(getUser));
  };

  useEffect(() => {
    getTokenSession();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [errors, setErrors] = useState<string[]>();
  const { data, meta, isLoading } = useList(params, token);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };

  const handleEditIncotermsComplement = async (id: string | number) => {
    setErrors([]);
    setReadOnly(false);
    const editIncotermsComplement = data.find((item: IncotermsComplementType) => item.ID_INCOTERMS_COMPL === id);

    if (editIncotermsComplement) {
      const { ID_INCOTERMS_COMPL, DSC_INCOTERMS_COMPL, ATIVO } = editIncotermsComplement;
      setIncotermsComplement({ ID_INCOTERMS_COMPL, DSC_INCOTERMS_COMPL, ATIVO });
      setModalOpen(true);
    }

    try {
      setControlEdit({ loading: true, isInUse: true });
      const { inUse } = await isInUse(id);
      setControlEdit({ loading: false, isInUse: inUse });
    } catch (error) {
      enqueueSnackbar("It's not posible to edit, try again later", { variant: "error" });
      setControlEdit({ loading: false, isInUse: true });
    }
  };

  const handleDeleteIncotermsComplementConfirmation = (id: string | number) => {
    setIncotermsComplementId(id);
    setOpenDeleteModal(true);
  };

  const handleDeleteIncotermsComplement = async () => {
    if (incotermsComplementId) {
      try {
        setIsSaving(true);
        await deleteIncotermsComplement(incotermsComplementId);
        enqueueSnackbar("Incoterms Complement deleted successful", {
          variant: "success",
        });
        setParams({ ...params });
      } catch (err: any) {
        if (err.response.status) {
          enqueueSnackbar("Incoterms Complement is being used", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(err.response.data.message[0], { variant: "error" });
        }
      }
      setIsSaving(false);
      setOpenDeleteModal(false);
    }
  };

  const handleFormSubmit = async (
    newIncotermsComplement: IncotermsComplementType
  ) => {
    const incotermsComplementDto = {
      DSC_INCOTERMS_COMPL: newIncotermsComplement.DSC_INCOTERMS_COMPL,
      ATIVO: newIncotermsComplement.ATIVO,
    };

    try {
      setIsSaving(true);
      if (newIncotermsComplement.ID_INCOTERMS_COMPL) {
        await update(
          newIncotermsComplement.ID_INCOTERMS_COMPL,
          incotermsComplementDto
        );
        enqueueSnackbar("Incoterms Complement updated successful", {
          variant: "success",
        });
      } else {
        await create(incotermsComplementDto);
        enqueueSnackbar("Incoterms Complement created successful", {
          variant: "success",
        });
      }
      setIncotermsComplement({ ATIVO: true } as IncotermsComplementType);
      setParams({ ...params });
      setModalOpen(false);
      setIsSaving(false);
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setErrors([err.response?.data?.message]);
      }
      setIsSaving(false);
    }
    setIsSaving(false);
  };

  const exportIncotermsComplement = async () => {
    try {
      await exportAll(params);
    } catch (err) {}
  };

  const handleCancelEditIncotermsComplement = () => {
    setIncotermsComplement({} as IncotermsComplementType);
    setErrors([]);
    setModalOpen(false);
    setControlEdit({
      isInUse: false,
      loading: false,
    });
  };

  const onViewIncotermsComplement = (id: string | number) => {
    setErrors([]);
    setReadOnly(true);
    const viewIncotermsComplement = data.find((item: IncotermsComplementType) => item.ID_INCOTERMS_COMPL === id);

    if (viewIncotermsComplement) {
      const { ID_INCOTERMS_COMPL, DSC_INCOTERMS_COMPL, ATIVO } = viewIncotermsComplement;
      setIncotermsComplement({ ID_INCOTERMS_COMPL, DSC_INCOTERMS_COMPL, ATIVO });
      setModalOpen(true);
    }
  };

  return (
    <Grid marginTop={4}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={8}>
          <Typography variant="h5">Incoterms Complement</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" marginTop={1}>
        <Grid item xs={2}>
          {!modalOpen ? (
            <Grid item xs={2}>
              <Button
                variant="contained"
                onClick={() => {
                  setErrors([])
                  setModalOpen(true)
                }}
                fullWidth
                sx={{
                  display: hasClaim("Incoterms Compl - Maintenance")
                    ? "block"
                    : "none",
                }}
                data-testid="new-button"
              >
                New
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {modalOpen ? (
        <Grid container alignItems="center">
          <IncotermsComplementForm
            onSubmit={handleFormSubmit}
            incotermsComplement={incotermsComplement}
            onCancel={handleCancelEditIncotermsComplement}
            isLoading={isSaving}
            errors={errors}
            readOnly={readOnly}
            controlEdit={controlEdit}
          />
        </Grid>
      ) : null}
      <Search onChange={handleRequest} />
      <Grid container marginTop={2} justifyContent="flex-end">
        <Button
          variant="text"
          onClick={exportIncotermsComplement}
          startIcon={<Download />}
        >
          Export XLSX
        </Button>
      </Grid>
      <Grid>
        <DataTable
          loading={isLoading}
          data={data}
          userValidation={userValidation}
          onDeleteIncotermsComplement={
            handleDeleteIncotermsComplementConfirmation
          }
          onEditIncotermsComplement={handleEditIncotermsComplement}
          onViewIncotermsComplement={onViewIncotermsComplement}
          rowCount={meta.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          paginationModel={paginationModel}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete Incoterms Complement
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete this Incoterms Complement?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button onClick={handleDeleteIncotermsComplement} data-testid="delete-modal-confirm">Confirm</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
