import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { Warehouse } from "../../../../../types/warehouse";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import { PickupZone } from "../../../../../types/pickupZone";
import { WrQuarantineData } from "../../../../../types/wrQuarantine";
import dayjs from "dayjs";
import { PartnerService } from "../../../../../types/partner";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import { ComboBoxType } from "../../../../../types/general";
import { InvoicePackageDetail } from "../../../../../types/packageDetail";
import { formatDecimalFree, handleFormatDecimalChange } from "../../../../utils/inputs";
import DatePickerWrapper from '../../../../../components/wrappers/DatePickerWrapper';

const nullIfEmptyString = (value: string | number) => (value === '' ? null : value);
const strToNumber = (value: string | number) => (Number(value));

let warehouseScheme = yup.object({
  NUM_WAREHOUSE: yup.string().required(),
  ID_CENTRO_CONSOLIDADOR: yup.number().required(),
  ID_PAIS_ORIGEM_MATERIAL: yup.number().required(),
  ID_TIPO_MATERIAL: yup.number().required(),
  DSC_TRACKING_NUMBER: yup.string().nullable().transform(nullIfEmptyString),
  ID_PRIORIADE_PICK_UP: yup.mixed<string | number>().nullable().transform(nullIfEmptyString).transform(strToNumber),
  DSC_FULL_TRUCK: yup.string().nullable().transform(nullIfEmptyString),
  ID_PICK_UP_ZONE: yup.mixed<string | number>().nullable().transform(nullIfEmptyString).transform(strToNumber),
  DATA_PICK_UP: yup.string().nullable().transform(nullIfEmptyString),
  DATA_ON_HAND: yup.string().nullable().transform(nullIfEmptyString),
  QUANT_PESO_BRUTO: yup.string().required(),
  QTD_INVOICE_WAREHOUSE: yup.number().required(),
  QUANT_PESO_CUBICO: yup.string().required(),
  QTD_TOTAL_VOLUME: yup.number().required(),
});

type WarehouseFormSchema = Partial<Warehouse> & { 
  QUANT_PESO_BRUTO?: string | number, 
  QUANT_PESO_CUBICO?: string | number 
  ID_CENTRO_CONSOLIDADOR?: string | number | null,
  ID_PICK_UP_ZONE: string | number | null,
  ID_PRIORIADE_PICK_UP: string | number | null,
};

interface WarehouseFormProps {
  warehouse?: Warehouse;
  countriesCombo?: ComboBoxType[];
  materialTypeCombo?: ComboBoxType[];
  pickupZonePriorityCombo?: ComboBoxType[];
  consolidationCenterCombo?: ComboBoxType[];
  consolidationCenterSelected: PartnerService | undefined;
  pickupZones?: PickupZone[];
  fullTruck: ComboBoxType[];
  onSubmit: (warehouse: any) => void;
  onCancel: () => void;
  onUnlink: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit?: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly?: boolean;
  open: boolean;
  handleClose: () => void;
  setNumWarehouse: React.Dispatch<React.SetStateAction<string>>;
  wrQuarantines?: WrQuarantineData[];
  mode?: string,
  invoicePackages?: InvoicePackageDetail[];
  quarantineError?: boolean;
  autocompletions: Record<'NUM_PESO_BRUTO'|'NUM_PESO_CUBICO', string | number>
  isInvoiceTriage?: boolean
  fetchedByWarehouseNumber: string | number;
}

export default function WarehouseDialogForm({
  warehouse,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  handleClose: handleCloseExternal,
  open,
  countriesCombo,
  materialTypeCombo,
  pickupZonePriorityCombo,
  consolidationCenterCombo,
  pickupZones,
  onUnlink,
  setNumWarehouse,
  consolidationCenterSelected,
  mode = 'add',
  fullTruck,
  invoicePackages,
  wrQuarantines,
  quarantineError,
  autocompletions,
  isInvoiceTriage,
  fetchedByWarehouseNumber
}: WarehouseFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    watch,
    control,
    unregister,
    setValue,
    getValues
  } = useForm<WarehouseFormSchema>({
    resolver: yupResolver(warehouseScheme),
    defaultValues: {
      ID_CENTRO_CONSOLIDADOR: undefined,
      ID_PICK_UP_ZONE: null,
      ID_PRIORIADE_PICK_UP: null,
      QUANT_PESO_BRUTO: isNaN(warehouse!.QUANT_PESO_BRUTO) ? '' : warehouse!.QUANT_PESO_BRUTO,
      QUANT_PESO_CUBICO: isNaN(warehouse!.QUANT_PESO_CUBICO) ? '' : warehouse!.QUANT_PESO_CUBICO,
      ...warehouse,
    }
  });

  const [numWarehouseField, setNumWarehouseField] = useState<string>("");
  const [grossWeightValue, setGrossWeightValue] = useState<number | string | null>('');
  const [cubicWeightValue, setCubicWeightValue] = useState<number | string | null>('');
  const [isEditable, setIsEditable] = useState(!!(warehouse?.ID_WAREHOUSE ?? fetchedByWarehouseNumber));
  const [searchInputDisabled, setSearchInputDisabled] = useState(!!warehouse?.ID_WAREHOUSE);

  useEffect(() => {
    if (warehouse) {
      const hasWarehouseId = !!warehouse.ID_WAREHOUSE;
      setIsEditable(hasWarehouseId || !!fetchedByWarehouseNumber);
      setSearchInputDisabled(hasWarehouseId);
    }
  }, [warehouse]);

  useEffect(() => {
    reset({ 
      ...(fetchedByWarehouseNumber ? getValues() : null),
      ...warehouse,
      ...(!warehouse.ID_WAREHOUSE && fetchedByWarehouseNumber ? {
        QUANT_PESO_BRUTO: (isNaN(warehouse!.QUANT_PESO_BRUTO) ? '' : warehouse!.QUANT_PESO_BRUTO) || (typeof autocompletions?.NUM_PESO_BRUTO === 'number' ? autocompletions.NUM_PESO_BRUTO.toFixed(2) : autocompletions?.NUM_PESO_BRUTO), 
        QUANT_PESO_CUBICO: (isNaN(warehouse!.QUANT_PESO_CUBICO) ? '' : warehouse!.QUANT_PESO_CUBICO) || (typeof autocompletions?.NUM_PESO_CUBICO === 'number' ? autocompletions.NUM_PESO_CUBICO.toFixed(3) : autocompletions?.NUM_PESO_CUBICO),
      } : null)
    });
  }, [warehouse, reset, autocompletions]);

  useEffect(() => {
    if (warehouse?.QUANT_PESO_BRUTO && warehouse.QUANT_PESO_BRUTO > 0) {
      setGrossWeightValue(warehouse.QUANT_PESO_BRUTO);
    } else {
      setGrossWeightValue(null);
    }

    if (warehouse?.QUANT_PESO_CUBICO && warehouse.QUANT_PESO_CUBICO > 0) {
      setCubicWeightValue(warehouse.QUANT_PESO_CUBICO);
    } else {
      setCubicWeightValue(null);
    }
  }, [warehouse, autocompletions]);

  const changeableWeightValue = useMemo(() => {
    const parsedGrossWeightValue = grossWeightValue && +grossWeightValue.toString().replace(',','.');
    const parsedCubicWeightValue = cubicWeightValue && +cubicWeightValue.toString().replace(',','.');

    if (!parsedGrossWeightValue && !parsedCubicWeightValue) {
      let grossWeightSum = 0;
      let cubicWeightSum = 0;
      invoicePackages?.forEach((invoicePackage) => {
        grossWeightSum += Number(invoicePackage.NUM_PESO_BRUTO);
        cubicWeightSum += Number(invoicePackage.NUM_PESO_CUBICO);
      });

      setGrossWeightValue(grossWeightSum);
      setCubicWeightValue(cubicWeightSum);

      if (cubicWeightSum > grossWeightSum) return cubicWeightSum;
      if (grossWeightSum > cubicWeightSum) return grossWeightSum;
      return grossWeightSum;
    }

    if (!parsedGrossWeightValue && parsedCubicWeightValue) {
      return parsedCubicWeightValue;
    }

    if (parsedGrossWeightValue && !parsedCubicWeightValue) {
      return parsedGrossWeightValue;
    }

    if (parsedGrossWeightValue && parsedCubicWeightValue) {
      if (parsedGrossWeightValue > parsedCubicWeightValue) return parsedGrossWeightValue;
      if (parsedGrossWeightValue < parsedCubicWeightValue) return parsedCubicWeightValue;

      return parsedCubicWeightValue;
    }

    return 0;
  }, [grossWeightValue, cubicWeightValue]);

  const handleSearch = () => {
    const foundWarehouse = false;
    setIsEditable(!foundWarehouse);
  };

  const handleClose = () => {
    handleCloseExternal();
    reset({});
  }
  
  return (
    <Dialog open={open} maxWidth="xl">
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <form
        style={{ width: "100%" }}
        onSubmit={handleSubmit(onSubmit)}
        data-testid="quarantine-form"
      >
        {errors.map((error) => (
          <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
            {error}
          </Alert>
        ))}
        <DialogTitle display="flex" justifyContent="space-between">
          WAREHOUSE RECEIPT
          <CloseIcon onClick={() => handleClose()} sx={{ color: 'black', cursor: "pointer" }} />
        </DialogTitle>
        <Divider color="gray" />
        <Divider color="gray" />
        <DialogContent>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box display="flex" width={700} alignItems="center" flexDirection="row" gap={3}>
              <TextField
                size="small"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="name"
                label="WR Number*"
                type="string"
                variant="outlined"
                {...register("NUM_WAREHOUSE")}
                onChange={(event) => {
                  setNumWarehouseField(event.target.value);
                }}
                disabled={!!warehouse?.ID_AUTORIZACAO_EMBARQUE || searchInputDisabled}
                error={!!formErrors.NUM_WAREHOUSE}
              />
              <Controller
                render={({ field: { value, onChange } }) => (
                  <TextField
                    size="small"
                    select
                    label="Origin Consolidation Center*"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    {...register("ID_CENTRO_CONSOLIDADOR")}
                    onChange={onChange}
                    value={value ?? null}
                    disabled={searchInputDisabled}
                    error={!!formErrors.ID_CENTRO_CONSOLIDADOR}
                  >
                    {consolidationCenterCombo?.map((consolidationCenter) => (
                      <MenuItem key={consolidationCenter.id} value={consolidationCenter.id}>{consolidationCenter.description}</MenuItem>
                    ))}
                  </TextField>
                )}
                name="ID_CENTRO_CONSOLIDADOR"
                control={control}
              />
              <Button disabled={!!warehouse?.ID_AUTORIZACAO_EMBARQUE || searchInputDisabled} onClick={() => {
                  setNumWarehouse(numWarehouseField);
                  handleSearch();
                }} size="large" variant="contained">
                <SearchIcon />
              </Button>
            </Box>

            {mode === 'edit' && (<Button
              onClick={() => {
                reset();
                onUnlink();
              }}
              startIcon={<AutoFixNormalIcon />}
              variant="outlined"
              size="medium"
              color="warning"
              disabled={!!warehouse?.ID_AUTORIZACAO_EMBARQUE || isInvoiceTriage}
            >
              Unlink WR
            </Button>)}
          </Box>
          <Grid paddingLeft="5px">
            {warehouse?.ID_STATUS_WAREHOUSE && (<Typography variant="body1"><strong>{warehouse.ID_STATUS_WAREHOUSE}</strong></Typography>)}
          </Grid>
          <Grid container marginTop={3} spacing={2}>
            <Grid item xs={12 / 5}>
              <Controller
                name="ID_PAIS_ORIGEM_MATERIAL"
                control={control}
                defaultValue={warehouse?.ID_PAIS_ORIGEM_MATERIAL}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    size="small"
                    select
                    disabled={!!warehouse?.ID_AUTORIZACAO_EMBARQUE || !isEditable}
                    label="Origin*"
                    fullWidth
                    margin="dense"
                    onChange={onChange}
                    value={value ?? null}
                    InputLabelProps={{ shrink: true }}
                    error={!!formErrors.ID_PAIS_ORIGEM_MATERIAL}
                  >
                    {countriesCombo?.map((country) => (
                      <MenuItem key={country.id} value={country.id}>{country.description}</MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12 / 5}>
              <TextField
                size="small"
                fullWidth
                disabled={!!warehouse?.ID_AUTORIZACAO_EMBARQUE || !isEditable}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 50 }}
                margin="dense"
                defaultValue={warehouse?.DSC_TRACKING_NUMBER ?? null}
                {...register("DSC_TRACKING_NUMBER")}
                label="Delivered Tracking Number"
                type="text"
                variant="outlined"
                value={watch('DSC_TRACKING_NUMBER')}
                error={!!formErrors.DSC_TRACKING_NUMBER}
              />
            </Grid>
            <Grid item xs={12 / 5}>
              <TextField
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                defaultValue={warehouse?.QTD_INVOICE_WAREHOUSE}
                {...register("QTD_INVOICE_WAREHOUSE")}
                margin="dense"
                label="Total Invoice Qty*"
                disabled={!!warehouse?.ID_AUTORIZACAO_EMBARQUE || !isEditable}
                type="tel"
                variant="outlined"
                error={!!formErrors.QTD_INVOICE_WAREHOUSE}
              />
            </Grid>
            <Grid item xs={12 / 5}>
              <TextField
                size="small"
                disabled={!!warehouse?.ID_AUTORIZACAO_EMBARQUE || !isEditable}
                fullWidth
                InputLabelProps={{ shrink: true }}
                defaultValue={warehouse?.QTD_TOTAL_VOLUME}
                {...register("QTD_TOTAL_VOLUME")}
                margin="dense"
                label="Total Volume Qty*"
                type="tel"
                variant="outlined"
                error={!!formErrors.QTD_TOTAL_VOLUME}
              />
            </Grid>
            <Grid item xs={12 / 5}>
              <Controller
                name="DATA_ON_HAND"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <DatePickerWrapper
                    closeOnSelect
                    sx={{
                      "& .MuiInputBase-root": { height: "39px" },
                      width: 1,
                      marginTop: 1
                    }}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        error: !!formErrors.DATA_ON_HAND,
                        InputLabelProps: {
                          shrink: true
                        }
                      },
                    }}
                    defaultValue={warehouse?.DATA_ON_HAND ? dayjs(new Date(warehouse?.DATA_ON_HAND)) : null}
                    value={quarantineError ? null : value ? dayjs(value) : null}
                    label="Ready to Ship Date"
                    format="MM/DD/YYYY"
                    {...register("DATA_ON_HAND")}
                    onChange={(date) => {
                      const formattedDate = date && dayjs(date).isValid() ? dayjs(date).toISOString() : null;
                      onChange(formattedDate);
                    }}
                    disabled={!isEditable}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12 / 5}>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <TextField
                    size="small"
                    select
                    disabled={!!warehouse?.ID_AUTORIZACAO_EMBARQUE || !isEditable}
                    label="Material Type*"
                    fullWidth
                    margin="dense"
                    value={value ?? null}
                    defaultValue={warehouse?.ID_TIPO_MATERIAL}
                    onChange={onChange}
                    InputLabelProps={{ shrink: true }}
                    error={!!formErrors.ID_TIPO_MATERIAL}
                  >
                    {materialTypeCombo?.map((materialType) => (
                      <MenuItem key={materialType.id} value={materialType.id}>{materialType.description}</MenuItem>
                    ))}
                  </TextField>
                )}
                name="ID_TIPO_MATERIAL"
                control={control}
              />
            </Grid>
            <Grid item xs={12 / 5}>
              <Controller 
                name="QUANT_PESO_BRUTO"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      size="small"
                      fullWidth
                      disabled={!!warehouse?.ID_AUTORIZACAO_EMBARQUE || !isEditable}
                      InputLabelProps={{ shrink: true }}
                      margin="dense"
                      label="Gross Weight*"
                      error={!!formErrors.QUANT_PESO_BRUTO}
                      {...field}
                      value={formatDecimalFree(field.value ?? '', { integerDigits: 17 }).formatted}
                      onChange={(event) => {
                        const { raw } = handleFormatDecimalChange(event, { integerDigits: 17 }, true);
                        setGrossWeightValue(raw.toString().replace(',', '.'));
                        field.onChange(event);
                      }}
                      type="text"
                      variant="outlined"
                    />
                  )
                }}  
              />
            </Grid>
            <Grid item xs={12 / 5}>
              <Controller 
                  name="QUANT_PESO_CUBICO"
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        size="small"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        {...register("QUANT_PESO_CUBICO")}
                        margin="dense"
                        label="Cubic Weight*"
                        InputProps={{
                          inputProps: {
                            min: 0
                          }
                        }}
                        error={!!formErrors.QUANT_PESO_CUBICO}
                        {...field}
                        disabled={!!warehouse?.ID_AUTORIZACAO_EMBARQUE || !isEditable}
                        value={formatDecimalFree(field.value ?? '', { integerDigits: 17 }).formatted}
                        onChange={(event) => {
                          const { raw } = handleFormatDecimalChange(event, { integerDigits: 17 }, true);
                          setCubicWeightValue(raw.toString().replace(',', '.'));
                          field.onChange(event);
                        }}
                        variant="outlined"
                      />
                    )
                  }}  
                />
            </Grid>
            <Grid item xs={12 / 5}>
              <TextField
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                margin="dense"
                type="text"
                label="Chargeable Weight*"
                value={!isEditable ? '' : formatDecimalFree(changeableWeightValue ?? '', { integerDigits: 17 }).formatted}
                defaultValue={!isEditable ? '' : formatDecimalFree(changeableWeightValue ?? '', { integerDigits: 17 }).formatted}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={12 / 5}>

            </Grid>
            <Grid item xs={12 / 5}>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <TextField
                    size="small"
                    select
                    disabled={!!warehouse?.ID_AUTORIZACAO_EMBARQUE || !isEditable}
                    label="Pick up Priority"
                    fullWidth
                    margin="dense"
                    onChange={onChange}
                    value={value ?? null}
                    InputLabelProps={{ shrink: true }}
                    defaultValue={warehouse?.ID_PRIORIADE_PICK_UP}
                  >
                    {pickupZonePriorityCombo?.map((pickupZonePriority) => (
                      <MenuItem key={pickupZonePriority.id} value={pickupZonePriority.id}>{pickupZonePriority.description}</MenuItem>
                    ))}
                  </TextField>
                )}
                name="ID_PRIORIADE_PICK_UP"
                control={control}
              />
            </Grid>
            <Grid item xs={12 / 5}>
              <Controller
                name="ID_PICK_UP_ZONE"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    size="small"
                    select
                    disabled={!!warehouse?.ID_AUTORIZACAO_EMBARQUE || !isEditable}
                    label="Pick up Zone"
                    fullWidth
                    margin="dense"
                    onChange={onChange}
                    value={value ?? null}
                    InputLabelProps={{ shrink: true }}
                    defaultValue={warehouse?.ID_PICK_UP_ZONE}
                  >
                    {pickupZones?.map((pickupZone) => (
                      <MenuItem key={pickupZone.ID_PICK_UP_ZONE} value={pickupZone.ID_PICK_UP_ZONE}>{pickupZone.SIGLA} - {pickupZone.DESCRICAO}</MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12 / 5}>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <TextField
                    size="small"
                    select
                    disabled={!!warehouse?.ID_AUTORIZACAO_EMBARQUE || !isEditable}
                    label="Full Truck"
                    fullWidth
                    margin="dense"
                    onChange={onChange}
                    value={value ?? null}
                    defaultValue={warehouse?.DSC_FULL_TRUCK}
                    InputLabelProps={{ shrink: true }}
                  >
                    {fullTruck?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
                    ))}
                  </TextField>
                )}
                name="DSC_FULL_TRUCK"
                control={control}
              />
            </Grid>
            <Grid item xs={12 / 5}>
              <Controller
                name="DATA_PICK_UP"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <DatePickerWrapper
                    closeOnSelect
                    sx={{
                      "& .MuiInputBase-root": { height: "39px" },
                      width: 1,
                      marginTop: 1
                    }}
                    defaultValue={warehouse?.DATA_PICK_UP ? dayjs(new Date(warehouse?.DATA_PICK_UP)) : null}
                    value={value ? dayjs(value) : null}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        error: !!formErrors.DATA_PICK_UP,
                        InputLabelProps: {
                          shrink: true
                        }
                      },
                    }}
                    label="Pick Up Date"
                    format="MM/DD/YYYY"
                    {...register("DATA_PICK_UP")}
                    onChange={(date) => {
                      const formattedDate = date && dayjs(date).isValid() ? dayjs(date).toISOString() : null;
                      onChange(formattedDate);
                    }}
                    disabled={!isEditable}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12 / 5}>

            </Grid>

          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions style={{ justifyContent: "space-between", padding: 21 }}>
          <Button style={{ backgroundColor: "gray", width: "10%" }} variant="contained"
            onClick={(e) => {
              e.preventDefault();
              unregister();
              onCancel();
            }
          }
          >
            Return
          </Button>
          <Button
            style={{ width: "10%" }}
            size="medium"
            variant="contained"
            type="submit"
            disabled={!!warehouse?.ID_AUTORIZACAO_EMBARQUE || !isEditable}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}