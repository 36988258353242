import {Download} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import {useEffect, useState} from "react";

import {create, deleteCenter, exportAll, isInUse, update, useList } from "../../../services/centers";
import {useList as useCompaniesList} from "../../../services/companies";
import {useList as useCountriesList} from "../../../services/countries";
import {getToken} from "../../../storage/auth";
import {Center} from "../../../types/center";
import CenterForm from "./components/CenterForm";
import DataTable from "./components/DataTable";
import Search from "./components/Search";
import {hasClaim} from "../../../storage/userClaims";
import useForms from "../../utils/forms/useForm.hook";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
}
export default function Centers() {
  const [token, setToken] = useState<string>();

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    setToken(getTokens);
  };

  useEffect(() => {
    getTokenSession();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [paramsComplement] = useState({
    page: 1,
    limit: 10,
    ATIVO: true,
  });

  const { data: companies } = useCompaniesList(paramsComplement, token);
  const { combo: countries } = useCountriesList(paramsComplement, true);

  const { data, meta, error, isLoading } = useList(params);

  const {
    selectedData,
    controlEdit,
    isSaving,
    modalOpen,
    setModalOpen,
    openDeleteModal,
    setOpenDeleteModal,
    errors,
    readOnly,
    paginationModel,
    setPaginationModel,

    handleRequest,
    handleEditData,
    handleDeleteDataConfirmation,
    handleDeleteData,
    handleFormSubmit,
    exportData,
    handleCancelEditData,
    onViewData,
    onNew,
  } = useForms({
    actions: {
      create,
      deleteData: deleteCenter,
      exportAll,
      update,
      isInUse
    },
    configs:{
      findKey: "ID_CENTRO",
      entityName: "Company - Plant",
    },

    data,
    params,
    setParams
  });


  return (
    <div id="centers">
      <Grid marginTop={4}>
        {error.message}
        <Grid container spacing={1} direction="column">
          <Grid item xs={10}>
            <Typography variant="h5">Center (Plant)</Typography>
          </Grid>
          {!modalOpen ? (
            <Grid item xs={2} marginTop={1}>
              <Button
                sx={{
                  display: hasClaim("Center - Maintenance") ? "block" : "none",
                }}
                variant="contained"
                onClick={() => onNew()}
              >
                New
              </Button>
            </Grid>
          ) : null}
        </Grid>
        {modalOpen ? (
          <Grid container alignItems="center">
            <CenterForm
              companies={companies}
              countries={countries}
              onSubmit={handleFormSubmit}
              center={selectedData}
              onCancel={handleCancelEditData}
              isLoading={isSaving}
              errors={errors}
              controlEdit={controlEdit}
              readOnly={readOnly}
            />
          </Grid>
        ) : null}
        <Search onChange={handleRequest} />
        <Grid container marginTop={4} justifyContent="flex-end">
          <Button
            variant="text"
            onClick={exportData}
            startIcon={<Download />}
          >
            Export XLSX
          </Button>
        </Grid>
        <Grid>
          <DataTable
            loading={isLoading}
            data={data}
            companies={companies}
            onDelete={handleDeleteDataConfirmation}
            rowCount={meta.total || 0}
            onPaginationModelChange={(e: any) => {
              setPaginationModel(e);
              handleRequest({ page: e.page + 1, limit: e.pageSize });
            }}
            paginationModel={paginationModel}
            onEditCenter={handleEditData}
            onViewCenter={onViewData}
            pagination
            initialState={{
              pagination: { paginationModel: { pageSize: params.limit } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            paginationMode="server"
          />
        </Grid>
        <Dialog
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Center</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure that you want to delete this Center?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
            <Button onClick={handleDeleteData}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
}
