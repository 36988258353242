import { Download } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { isAxiosError } from "axios";
import { useEffect, useState } from "react";

import Search from "../../../components/Search";
import {
  create,
  deleteCargo,
  exportAll,
  update,
  useList,
  isInUse,
} from "../../../services/containerType";
import { getLocal, getToken } from "../../../storage/auth";
import CustomRegimeForm from "./components/Form";
import DataTable from "./components/DataTable";
import { hasClaim } from "../../../storage/userClaims";
import { ContainerTypesProps } from "../../../types/containerType";
import { useSnackbar } from "notistack";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
}

export default function ContainerType() {
  const { enqueueSnackbar } = useSnackbar();
  const [containerType, setcontainerType] = useState<ContainerTypesProps>({
    ATIVO: true,
  } as ContainerTypesProps);

  const [containerTypeId, setcontainerTypeId] = useState<string | number>();
  const [isSaving, setIsSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userValidation, setUserValidation] = useState<string>();
  const [token, setToken] = useState<string>();
  const [controlEdit, setControlEdit] = useState({
    loading: false,
    isInUse: false,
  });
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = (getLocal("userInfo")) as any;
    setToken(getTokens);
    setUserValidation(JSON.parse(getUser));
  };

  useEffect(() => {
    getTokenSession();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [errors, setErrors] = useState<string[]>();
  const { data, meta, isLoading } = useList(params, token);

  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const handleEditShipment = async (id: string | number) => {
    setErrors([])
    setReadOnly(false);
    const editCustomRegime = data.filter(
      (item: ContainerTypesProps) => item.ID_TIPO_CONTEINER === id
    );
    if (editCustomRegime.length) {
      const data = editCustomRegime[0];
      setcontainerType({
        ...data,
      });
      setModalOpen(true);
    }
    try {
      setControlEdit({
        loading: true,
        isInUse: true,
      });
      const { inUse } = await isInUse(id);
      setControlEdit({
        loading: false,
        isInUse: inUse,
      });
    } catch (error) {
      enqueueSnackbar("It's not posible to edit, try again later", {
        variant: "error",
      });
      setControlEdit({
        loading: false,
        isInUse: true,
      });
    }
  };

  const handleDeleteCustomRegimeConfirmation = (id: string | number) => {
    setcontainerTypeId(id);
    setOpenDeleteModal(true);
  };

  const handleDeleteCustomRegime = async () => {
    if (containerTypeId) {
      try {
        setIsSaving(true);
        await deleteCargo(containerTypeId);
        enqueueSnackbar("Container Type deleted successful", {
          variant: "success",
        });
        setParams({ ...params });
      } catch (err: any) {
        if (err.response.status) {
          enqueueSnackbar("Container Type is being used", { variant: "error" });
        } else {
          enqueueSnackbar(err.response.data.message[0], { variant: "error" });
        }
      }
      setIsSaving(false);
      setOpenDeleteModal(false);
    }
  };

  const handleFormSubmit = async (newShipment: ContainerTypesProps) => {
    try {
      setIsSaving(true);
      if (newShipment.ID_TIPO_CONTEINER) {
        await update(newShipment.ID_TIPO_CONTEINER, newShipment);
        enqueueSnackbar("Container Type updated successful", {
          variant: "success",
        });
      } else {
        await create(newShipment);
        enqueueSnackbar("Container Type created successful", {
          variant: "success",
        });
      }
      setcontainerType({ ATIVO: true } as ContainerTypesProps);
      setParams({ ...params });
      setModalOpen(false);
      setIsSaving(false);
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setErrors([err.response?.data?.message]);
      }
      setIsSaving(false);
    }
    setIsSaving(false);
  };

  const exportCustomsRegimes = async () => {
    try {
      await exportAll(params);
    } catch (err) {}
  };

  const handleCancelEditCustomRegime = () => {
    setcontainerType({} as ContainerTypesProps);
    setErrors([]);
    setModalOpen(false);
    setControlEdit({
      loading: false,
      isInUse: false,
    });
  };

  const onViewCustomRegime = (id: string | number) => {
    setErrors([]);
    setReadOnly(true);

    const editCustomRegime = data.filter(
      (item: ContainerTypesProps) => item.ID_TIPO_CONTEINER === id
    );
    if (editCustomRegime.length) {
      const data = editCustomRegime[0];
      setcontainerType({
        ...data,
      });
    }
    setModalOpen(true);
  };

  return (
    <Grid marginTop={4}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h5">Container Type</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" marginTop={1}>
        <Grid item xs={2}>
          {!modalOpen ? (
            <Grid item xs={2}>
              <Button
                sx={{
                  display: hasClaim("Container Type - Maintenance")
                    ? "block"
                    : "none",
                }}
                variant="contained"
                onClick={() => {
                  setControlEdit({
                    loading: false,
                    isInUse: false,
                  });
                  setErrors([])
                  setModalOpen(true);
                }}
                fullWidth
                data-testid='new-button'
              >
                New
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {modalOpen ? (
        <Grid container alignItems="center">
          <CustomRegimeForm
            onSubmit={handleFormSubmit}
            containerTypes={containerType}
            onCancel={handleCancelEditCustomRegime}
            isLoading={isSaving}
            errors={errors}
            controlEdit={controlEdit}
            readOnly={readOnly}
          />
        </Grid>
      ) : null}
      <Search onChange={handleRequest} />
      <Grid
        container
        marginTop={2}
        marginBottom={2}
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Grid item>
          <Button
            variant="text"
            onClick={exportCustomsRegimes}
            startIcon={<Download />}
          >
            Export XLSX
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <DataTable
          loading={isLoading}
          data={data}
          userValidation={userValidation}
          onDelete={handleDeleteCustomRegimeConfirmation}
          onEdit={handleEditShipment}
          onView={onViewCustomRegime}
          rowCount={meta.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          pagination
          paginationModel={paginationModel}
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>

      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Container Type</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete this Container Type?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button onClick={handleDeleteCustomRegime} data-testid="delete-modal-confirm">Confirm</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
