import { useEffect, useMemo, useState } from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { enqueueSnackbar } from "notistack";
import { findInvoicePoItems } from "../../../../../services/invoice";
import { InvoiceCompositionDetails } from "./invoiceCompositionDetails";
import DataTable from "../table/tableInvoicePoItems";
import { ComboBoxType } from "../../../../../types/general";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import { Invoice, InvoicesSections, InvoiceStatus } from "../../../../../types/invoice";
import { InvoiceItemPo } from "../../../../../types/invoiceItemPo";
import { formatNumberWithThousandsSeparator } from "../../../../utils/inputs";

interface InvoiceCompositionProps {
  invoice?: Invoice | null;
  countries: ComboBoxType[];
  unitsOfMeasurement: ComboBoxType[];
  onClose: (sectionNewStatus?: { [key: string]: boolean }) => void;
  setHasInvoicePo: (hasInvoicePo: boolean) => void;
}

const ENABLED_TO_EDIT = [
  InvoiceStatus.PENDING,
  InvoiceStatus.REGISTERED_INVOICE,
  InvoiceStatus.PENDING_SHIPMENT_AUTHORIZATION,
  InvoiceStatus.WAITING_SISTER_INVOICE,
]

export const InvoiceComposition = ({
  invoice,
  countries,
  unitsOfMeasurement,
  onClose,
  setHasInvoicePo
}: InvoiceCompositionProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invoicePoItems, setInvoicePoItems] = useState<InvoiceItemPo[]>([]);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const handleClose = () => {
    setOpenEditModal(false);
    if (onClose) {
      onClose({ [InvoicesSections.INVOICE_DETAILS]: true });
    }
  }

  const handleUpdate = () => {
    getInvoicePoItems();
  }

  const getInvoicePoItems = async () => {
    setIsLoading(true);

    try {
      const data = await findInvoicePoItems(invoice?.ID_INVOICE!);
      setInvoicePoItems(data);
    } catch (error) {
      enqueueSnackbar("Error loading invoice PO items", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchInvoicePoItems = async () => {
      setIsLoading(true);
  
      try {
        const data = await findInvoicePoItems(invoice?.ID_INVOICE!);
        setInvoicePoItems(data);
      } catch (error) {
        enqueueSnackbar("Error loading invoice PO items", { variant: "error" });
      } finally {
        setIsLoading(false);
      }
    };

    if (invoice?.ID_INVOICE) {
      fetchInvoicePoItems();
    }
  }, [invoice?.ID_INVOICE]);

  useEffect(() => {
    setHasInvoicePo(!!invoicePoItems && invoicePoItems.length > 0);
  }, [invoicePoItems]);

  const totalValue = useMemo(() => {
    return (invoicePoItems || []).reduce((acc, invoicePoItem) => {
      return acc + (+invoicePoItem.VALOR_TOTAL || 0)
    }, 0);
  }, [invoicePoItems]);

  if (!invoice?.po) {
    return <></>;
  }

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <Paper
        variant="outlined"
        sx={{ marginTop: 3, paddingX: 2, paddingBottom: 3 }}
      >
        <>
          <Grid style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            {
              ENABLED_TO_EDIT.includes(invoice.ID_STATUS_INVOICE) && (
                <Button onClick={() => setOpenEditModal(true)} variant="outlined">
                  <EditOutlined />
                  Edit Details
                </Button>
              )
            }
          </Grid>
          <Grid marginBottom={3}>
            <DataTable 
              data={invoicePoItems} 
              rowCount={invoicePoItems.length}
              pagination
            />
          </Grid>
          <Grid container direction="column" alignItems="flex-end" marginBottom={5}>
            <Grid item>
              <Typography fontSize={18}>Total Invoice Details: {formatNumberWithThousandsSeparator(totalValue)?.formatted}</Typography>
            </Grid>
          </Grid>
        </>
      </Paper>

      <InvoiceCompositionDetails
        isOpen={openEditModal}
        onClose={handleClose}
        invoiceId={invoice.ID_INVOICE!}
        po={invoice.po}
        onUpdate={handleUpdate}
        invoicePoItems={invoicePoItems}
        countries={countries}
        unitsOfMeasurement={unitsOfMeasurement}
      />
    </>
  );
};
