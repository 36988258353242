import { Radio } from "@mui/material/";
import DescriptionIcon from "@mui/icons-material/Description";
import DataTableDefault from "../../../../../components/DataTable";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { PoItem } from "../../../../../types/poItem";
import { InvoiceItemPo } from "../../../../../types/invoiceItemPo";
import { formatNumber } from "../../../../../lib/format-number";
import { enqueueSnackbar } from "notistack";

interface DataTableProps {
  onSelectPoItemInvoices: (poItem: PoItem) => void;
  onSelectPoItem: (poItem: PoItem) => void;
  selectedPoItem: PoItem | null;
  data: PoItem[];
  rowCount: number;
  invoicePoItems: InvoiceItemPo[];
  [x: string]: any;
  isEditing?: boolean;
}

export default function DataTable({
  data = [],
  selectedPoItem,
  onSelectPoItem,
  onSelectPoItemInvoices,
  invoicePoItems,
  isEditing,
  ...props
}: DataTableProps) {

  const isPoItemInInvoice = (poItemId: number) => {
    return invoicePoItems.some((invoicePoItem: { ID_PO_LIN: number; }) => invoicePoItem.ID_PO_LIN === poItemId);
  };

  const formatIntField = (row: PoItem, field: keyof PoItem) => {
    const value = row[field];
    return value && +value >= 0 ? +value : "";
  };

  const columns: GridColDef[] = [
    {
      field: "N",
      headerName: "",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: ({ row }) => {
        const disableField = ['REMESSA_FINAL', 'BLOQUEIO_LEGADO', 'LINHA_BLOQUEADA']
          .find((field) => row[field]);
        return (
          <Radio
            key={row.ID_PO_LIN}
            checked={selectedPoItem?.ID_PO_LIN === row.ID_PO_LIN}
            onChange={() => disableField ? enqueueSnackbar(`It is not possible to select this line, it has ${disableField} active.`, { variant: "error" }) : onSelectPoItem(row)}
            value="a"
            name="radio-buttons"
            inputProps={{ "aria-label": "A" }}
            disabled={isPoItemInInvoice(row.ID_PO_LIN) || isEditing}
          />
        );
      },
    },
    {
      field: "NUM_LIN_PO",
      headerName: "PO Line",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 80,
    },
    {
      field: "NUM_CEMB_MAT",
      headerName: "CEMB",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }) => {
        return [formatIntField(row, "NUM_CEMB_MAT")];
      }
    },
    {
      field: "NUM_PN",
      headerName: "Part Number",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 180,
      renderCell: ({ row }) => [<span style={{ whiteSpace: 'pre' }}>{row.NUM_PN}</span>]
    },
    {
      field: "DSC_PN",
      headerName: "PN Description",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 240,
    },
    {
      field: "QTDE_PED",
      headerName: "Quantity",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }) => {
        return [formatNumber(row.QTDE_PED, 3)];
      }
    },
    {
      field: "SIGLA_UM",
      headerName: "Unit",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "CURRENCY",
      headerName: "Currency",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }) => {
        return [row.po?.moedaPo?.SIGLA_MOEDA];
      },
    },
    {
      field: "VLR_PRECO_LIQ",
      headerName: "Unit Value",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 160,
      renderCell: ({ row }) => {
        return [formatNumber(+row.VLR_PRECO_LIQ)];
      }
    },
    {
      field: "NUM_UNIDADE_PRECO",
      headerName: "Unit Price",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }) => {
        return [formatNumber(+row.NUM_UNIDADE_PRECO, 3)];
      }
    },
    {
      field: "DG",
      headerName: "DG",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: ({ row }) => {
        return [+row.DSC_DG === 1 ? "Yes" : "No"];
      },
    },
    {
      field: "SIGLA_CENTRO",
      headerName: "Center(Plant)",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "DSC_DEPOSITO",
      headerName: "DEP",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "DSC_ENDERECO_ENTREGA",
      headerName: "Address DEP",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }) => {
        const address = [
          row.DSC_ENDERECO_ENTREGA,
          row.DSC_CIDADE_ENTREGA,
          row.DSC_ESTADO_ENTREGA,
        ]
          .filter((item) => !!item)
          .join(", ");

        return [address];
      },
    },
    {
      field: "INVOICE",
      headerName: "Invoice",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }) => {
        return (
          <GridActionsCellItem
            key={`select-po-item-invoices-${row.ID_INVOICE_PO_ITEM}`}
            icon={<DescriptionIcon />}
            label="select-po-item-invoices"
            data-testid="select-po-item-invoices-button"
            onClick={() => onSelectPoItemInvoices(row)}
          />
        );
      },
    },
    {
      field: "DSC_HTS",
      headerName: "HTS Code",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "DSC_FALTA_LIC_EXPO",
      headerName: "Missing Lic EXPO",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 140,
      renderCell: ({ row }) => {
        return [row.DSC_FALTA_LIC_EXPO === true ? 'Yes' : 'No'];
      }
    },
    {
      field: "DSC_SPI",
      headerName: "SPI",
      headerClassName: "super-app-theme--header",
      flex: 1,
    }
  ];

  return (
    <>
      <DataTableDefault
        rowId={data?.map((row) => row.ID_PO_ITEM)}
        data-testid="unit-data-table"
        data={data}
        columns={columns}
        {...props}
      />
    </>
  );
}
