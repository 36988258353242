import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { ComboBoxType } from "../../../../../types/general";
import { findConsolidationCenterByCenter } from "../../../../../services/automaticAuthorizationSchedule";
import { useSnackbar } from "notistack";

export default function SearchAutomaticAuthorizationscheduler({
  dataCenterCombo,
  onChange,
  params,
  setParams,
  exportAutomaticAuthorization,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [consolidationCenterCombo, setConsolidationCenterCombo] = useState<ComboBoxType[]>([] as ComboBoxType[]);
  const [centerSelected, setCenterSelected] = useState<number>();
  const [consolidationCenterSelected, setConsolidationCenterSelected] = useState<number>();

  const handleSearch = () => {
    if(!centerSelected || !consolidationCenterSelected) {
      enqueueSnackbar("You must to select Company - Plant and Consolidation Center", { variant: "info" });
      return;
    }
    onChange({
      ...params,
      page: 1,
      ID_CENTRO: centerSelected,
      ID_CENTRO_CONSOLIDADOR: consolidationCenterSelected,
    });
  };

  const findConsolidationCenter = async (id: number | undefined) => {
    if(id) {
      const consolidationCenter = await findConsolidationCenterByCenter(id);
      setConsolidationCenterCombo(consolidationCenter);
    }
  }

  return (
    <>
      <form>
        <Grid container spacing={2} marginTop={6} marginBottom={2}>
          <Grid item xs={4}>
            <Autocomplete
              options={dataCenterCombo || []}
              getOptionLabel={(option) => option.description}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Company - Plant"
                  size="small"
                  variant="outlined"
                  data-testid="company-plant"
                />
              )}
              onChange={(_: any, data: any) => {
                onChange(data?.id || null);
                findConsolidationCenter(data?.id);
                setCenterSelected(data?.id);
                setParams((prevState) => ({ ...prevState, ID_CENTRO: data?.id }));
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={consolidationCenterCombo || []}
              getOptionLabel={(option) => option.description}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Consolidation Center"
                  size="small"
                  variant="outlined"
                  data-testid="consolidation-center"
                />
              )}
              onChange={(_: any, data: any) => {
                onChange(data?.id || null);
                setConsolidationCenterSelected(data?.id);
                setParams((prevState) => ({ ...prevState, ID_CENTRO_CONSOLIDADOR: data?.id }));
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSearch}
              data-testid="search-button"
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
      {/*<Grid*/}
      {/*  container*/}
      {/*  marginTop={2}*/}
      {/*  marginBottom={2}*/}
      {/*  spacing={2}*/}
      {/*  alignItems="center"*/}
      {/*  justifyContent="flex-end"*/}
      {/*>*/}
      {/*  <Grid item>*/}
      {/*    <Button*/}
      {/*      variant="text"*/}
      {/*      onClick={exportAutomaticAuthorization}*/}
      {/*      startIcon={<Download />}*/}
      {/*    >*/}
      {/*      Export XLSX*/}
      {/*    </Button>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
    </>
  )
}