import { Autocomplete, Button, Chip, FormControlLabel, Grid, Switch, Table, TableBody, TableCell, TableRow, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import { SelectedCenterType } from "../../../../../types/partner";
import { ComboBoxType } from "../../../../../types/general";
import { useSnackbar } from "notistack";
import VisibilityIcon from "@mui/icons-material/Visibility";

interface CenterFormProps {
  readOnly: boolean,
  entityInUse: boolean,
  setValue: any,
  watch: any,
  register: any,
  control: any,
  formErrors: any,
  center: ComboBoxType[],
  selectedCenter: SelectedCenterType
  setSelectedCenter: (center: SelectedCenterType) => void
  editEmailMode: boolean
  editEmailModeCopy: boolean
  editModeCenter: boolean
  setEditModeCenter: (editMode:boolean) => void
}

const key = 'partner_centers'

export default function CenterForm({
  readOnly,
  entityInUse,
  setValue,
  watch,
  register,
  control,
  formErrors,
  center,
  selectedCenter,
  setSelectedCenter,
  editEmailMode,
  editEmailModeCopy,
  editModeCenter,
  setEditModeCenter,
}: CenterFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStatus, setSelectedStatus] = useState(true);
  

  function getCenters() {
    let centersOld = watch(key);
    if(centersOld === undefined) {
      centersOld = [];
    }
    return centersOld;
  }

  function deleteOnList(id: number) {
    setEditModeCenter(false);
    const centersOld = getCenters();
    const centerFiltered = centersOld.filter((centerOld: any) => centerOld.ID_CENTRO !== id);
    setValue(key, centerFiltered);
    setSelectedCenter({} as SelectedCenterType)
  }

  function editOnList(id: number) {
    setEditModeCenter(true);
    const foundedCenters = centers.find((center) => center.ID_CENTRO === id);
    const foundedCenter = center.find((center) => center.id === id);

    setSelectedCenter({ id: foundedCenter.id, description: foundedCenter?.description } as SelectedCenterType)
    setSelectedStatus(foundedCenters.ATIVO)
  }

  function addItem() {
    if(selectedCenter.id === undefined) {
      enqueueSnackbar("You must select at least one Center!", { variant: "error" });
      return;
    }
    const centersOld = getCenters();
    const alreadyAdd = centersOld.find((centerOld: any) => centerOld.ID_CENTRO === selectedCenter.id);
    if(alreadyAdd) {
      enqueueSnackbar("Center already registered for the partner!", { variant: "error" });
      return;
    }

    const centerToAdd = {
      ATIVO: selectedStatus,
      ID_CENTRO: selectedCenter.id,
      DESCRICAO: selectedCenter.description,
    }
    centersOld.push(centerToAdd);
    setValue(key, centersOld)
    const selectedToSave = selectedCenter;
    setSelectedCenter({} as SelectedCenterType)
    setTimeout(() => {
      setSelectedCenter(selectedToSave)
    }, 100)
  }

  function handleSwitch(event) {
    setSelectedStatus(event.target.checked);
  }

  function cancelEditCenter() {
    setEditModeCenter(false);
    setSelectedStatus(true);
  }

  function confirmEditCenter() {
    const centersOld = getCenters();
    const changedCenter = centersOld.map((centerOld: any) => {
      if(centerOld.ID_CENTRO === selectedCenter.id) {
        centerOld.ATIVO = selectedStatus;
      }
      return centerOld
    });
    setValue(key, changedCenter);
    setEditModeCenter(false);
    setSelectedStatus(true);
  }

  const centers = watch(key);

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
        <Autocomplete
          options={center}
          getOptionLabel={(option) => option?.description || ''}
          value={selectedCenter}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disabled={readOnly || editEmailMode || editEmailModeCopy || editModeCenter}
          renderInput={(params: any) => (
            <TextField
              {...params}
              label="Company - Plant*"
              size="small"
              variant="outlined"
              disabled={readOnly || editEmailMode || editEmailModeCopy || editModeCenter}
              error={!!formErrors.partner_centers?.message}
            />
          )}
          onChange={(_: any, data: any) => {
            setSelectedCenter(data);
          }}
          clearIcon={null}
        />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={selectedStatus}
                onChange={(event) => handleSwitch(event)}
                inputProps={{ "aria-label": "controlled" }}
                disabled={readOnly || editEmailMode || editEmailModeCopy}
              />
            }
            labelPlacement="start"
            label="Status"
          />
          {(!readOnly && !editEmailMode && !editEmailModeCopy && !editModeCenter) &&
              <Button
                  sx={{ marginLeft: "16px" }}
                  variant="outlined"
                  onClick={() => addItem()}
                  data-testid="add-button"
              >
                <AddIcon fontSize="small" />
              </Button>
          }
          {editModeCenter &&
            <>
              <Button
                sx={{ marginLeft: '16px', marginRight: '4px' }}
                variant="outlined"
                onClick={() => cancelEditCenter()}
                disabled={readOnly}
                color="error"
              >
                <CloseIcon fontSize="small" />
              </Button>

              <Button
                sx={{ marginLeft: '16px', marginRight: '4px' }}
                variant="outlined"
                onClick={() => confirmEditCenter()}
                disabled={readOnly}
              >
                <CheckIcon fontSize="small" />
              </Button>
            </>
          }
        </Grid>
        <Grid item xs={6} marginTop={1}>
          <Table size="small">
            <TableBody>
              {centers?.map((centerList) => (
                <TableRow
                  key={centerList.ID_CENTRO}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" colSpan={3}>
                    {center.find((centerTofind) => centerTofind.id === centerList.ID_CENTRO)?.description}
                  </TableCell>
                  <TableCell align="right">
                    {centerList.ATIVO ? <Chip label="Active" color="primary" />
                    : <Chip label="Inactive" variant="outlined" />}
                  </TableCell>
                  <TableCell sx={{ width: '80px' }} align="right">
                    <Button
                      sx={{ minWidth: 0 }}
                      color="primary"
                      onClick={() => editOnList(centerList.ID_CENTRO)}
                      disableRipple={true}
                      disabled={editEmailMode || editEmailModeCopy}
                    >
                      {readOnly ? <VisibilityIcon /> : <EditIcon />}
                    </Button>
                    <Button
                      sx={{ minWidth: 0 }}
                      color="error"
                      onClick={() => deleteOnList(centerList.ID_CENTRO)}
                      data-testid="delete-button"
                      disabled={readOnly || entityInUse || editEmailMode || editEmailModeCopy}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </>
  )
}