import { Alert, Autocomplete, Button, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useMemo, useState } from "react";
import DataTableDefault from "../DataTable";
import { CustomRegimeCopy } from "../../types/customRegime";

interface DataTableProps {
    handleCopy?: (customRegime: any, newCustomRegimes: any) => void;
    onCancel?: () => void;
    isLoading?: boolean;
    comboRegime: any[];
    dataCenterCombo?: {
        id: number;
        description: string;
    }[];
    getCenters: (center: any) => Promise<{ data: any[] }>;
    idKey: string;
    columns: any[];
}

export default function Copy({
        handleCopy,
        onCancel,
        dataCenterCombo,
        isLoading = false,
        getCenters,
        idKey,
        columns,
        comboRegime,
        ...props
    }: DataTableProps) {
    const { handleSubmit, formState: { errors: formErrors }, control } = useForm();
    let copySelectedRow: string | any[] = [];
    const [selectedCenter, setSelectedCenter] = useState<number | null>(null);
    const [data, setData] = useState<any[]>([]);
    const [error, setError] = useState(false);

    const destinationCenterWithoutRegime = useMemo(() => comboRegime?.filter((item) => item[idKey] === null), [comboRegime, idKey]);

    const centerWithRegime = useMemo(() => dataCenterCombo?.filter((item) =>
        !destinationCenterWithoutRegime.some((destItem) => destItem.id === item.id)
    ), [dataCenterCombo, destinationCenterWithoutRegime]);

    const handleCancel = () => onCancel && onCancel();

    const originCenterChange = async (center: any) => {
        setSelectedCenter(center?.id || null);
        const centers = await getCenters(center);
        setData(centers?.data || []);
    };

    const dataRender = useMemo(() => {
        return data.filter((item) => item.GC_ID_CENTRO === selectedCenter);
    }, [data, selectedCenter]);


    const selectCopyTable = (event: any[]) =>  copySelectedRow = event.map((item) => JSON.parse(item));

    const handleFormSubmit = (newCustomRegimes: any) => {
        setError(false);
        if (copySelectedRow.length === 0) {
            setError(true);
            return;
        }
        handleCopy && handleCopy(copySelectedRow, newCustomRegimes);
    };

    return (
        <Grid>
            {error && (
                <Alert severity="error" style={{ marginBottom: 20 }} data-testid="select-one-alert">
                    You need to select at least one item
                </Alert>
            )}
            <form onSubmit={handleSubmit(handleFormSubmit)} data-testid="customRegime-form">
                <Grid container marginBottom={2} spacing={2}>
                    <Grid item xs={5}>
                        <Controller
                            control={control}
                            name="ID_REGIME_ORIGIN"
                            render={({ field: { value, onChange, ...field } }) => (
                                <Autocomplete
                                    options={centerWithRegime || []}
                                    getOptionLabel={(option) => option.description}
                                    value={centerWithRegime?.find((item) => item.id === value) || null}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            {...field}
                                            label="Origin Company - Plant"
                                            size="small"
                                            error={!!formErrors.ID_REGIME_ORIGIN}
                                            variant="outlined"
                                            data-testid="origin-center"
                                        />
                                    )}
                                    onChange={(_, data) => {
                                        onChange(data?.id || null);
                                        originCenterChange(data);
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Controller
                            control={control}
                            name="ID_CENTRO_DESTINATION"
                            render={({ field: { value, onChange, ...field } }) => (
                                <Autocomplete
                                    options={destinationCenterWithoutRegime || []}
                                    getOptionLabel={(option: CustomRegimeCopy) => option.description}
                                    value={destinationCenterWithoutRegime?.find((item) => item.id === value) || null}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            {...field}
                                            label="Destination Company - Plant"
                                            size="small"
                                            error={!!formErrors.ID_CENTRO_DESTINATION}
                                            variant="outlined"
                                            data-testid="destination-center"
                                        />
                                    )}
                                    onChange={(_, data) => {
                                        onChange(data?.id || null);
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant="contained"
                            disabled={isLoading}
                            type="submit"
                            data-testid="submit-button"
                            style={{ width: "100%" }}
                        >
                            Copy
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Grid item>
                <DataTableDefault
                    rowId={data.map((row) => row)}
                    data-testid="custom-regime-data-table"
                    columns={columns}
                    data={dataRender}
                    checkboxSelection={true}
                    onRowSelectionModelChange={selectCopyTable}
                />
            </Grid>
            <Grid container justifyContent="flex-end" marginTop={3} spacing={2}>
                <Grid>
                    <Button
                        variant="outlined"
                        onClick={handleCancel}
                        disabled={isLoading}
                        data-testid="cancel-button"
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}
