import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { UserLogin } from "../../types/userLogin";
import { List } from "../../types/resquest";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
  status?: boolean;
}

const entity = "user-login";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string | null,
  isCombo?: boolean
):
  | Promise<{ data: UserLogin[]; meta: List; isLoading: boolean }>
  | any => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<UserLogin[]>([{} as UserLogin]);
  const [meta, setMeta] = useState<List>({} as List);
  const [combo, setCombo] = useState<any>();

  const render = () => {
    if (token) {
      if (isCombo === false || isCombo === undefined) {
        return "paginate";
      }
    }
  };

  useEffect(() => {
    if (render() === "paginate") {
      (async function () {
        setIsLoading(true);
        const response = await dataTableUtils.findPaginate(params);
        const {total, data, currentPage, totalPages, limit} = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
        setIsLoading(false)
      })();
    }
  }, [isCombo, params, token]);

  useEffect(() => {
    if (token && isCombo === true) {
      setIsLoading(true);
      api
        .get(`/${entity}/getCombo`, {
          params,
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const { data } = response;
          setCombo(data);
        })
        .finally(() => setIsLoading(false));
    }
  }, [isCombo, params, token]);

  return { data, meta, isLoading, combo };
};