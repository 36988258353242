import DataTableDefault from "../../../../../components/DataTable";
import { FreightForwarderRule } from "../../../../../types/freightForwarderRule";

interface DataTableProps {
  data: FreightForwarderRule[];
  [x: string]: any;
}
export default function DataTable({
  data,
  center,
  companies,
  userValidation,
  columns,
  ...props
}: DataTableProps) {
  return (
    <>
      <DataTableDefault
        rowId={data.map((row) => row.ID_REGRA_AGENTE_CARGA)}
        data-testid="custom-regime-data-table"
        data={data}
        columns={columns}
        {...props}
      />
    </>
  );
}
