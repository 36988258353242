import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Autocomplete, Button, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { ConsolidationCenter } from "../../../../../types/consolidationCenter";

let consolidationCenterScheme = yup.object({
  NOME: yup.string().notRequired(),
  CODIGO_EDI: yup.string().notRequired(),
  ID_PAIS: yup.number().notRequired(),
  ID_AEROPORTO: yup.number().notRequired(),
  ATIVO: yup.boolean().notRequired(),
  searchGeneral: yup.string().notRequired(),
});

interface ConsolidationCentersFormProps {
  airport: any[];
  countries: any[];
  onSearch: (consolidationCenter: ConsolidationCenter) => void;
  isLoading?: boolean;
  errors?: string[];
}

export default function AvancedSearch({
  airport,
  countries,
  onSearch,
  isLoading = false,
  errors = [],
}: ConsolidationCentersFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    control,
  } = useForm({
    resolver: yupResolver(consolidationCenterScheme),
  });

  const statusMock = [
    { label: "Active", status: true },
    { label: "Inactive", status: false },
  ];

  const handleFormSubmit = (consolidationCenter: any) => {
    onSearch({ ...consolidationCenter, page: 1 });
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="consolidation-center-avanced-search"
    >
      {errors.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid container spacing={2} marginTop={2} marginBottom={2}>
        <Grid item xs={4}>
          <Controller
            control={control}
            render={({ field: { value } }) => (
              <TextField
                fullWidth
                type="text"
                label="General Search"
                size="small"
                variant="outlined"
                defaultValue={value}
                error={!!formErrors.searchGeneral}
                InputLabelProps={{ shrink: !!value }}
                {...register("searchGeneral")}
              />
            )}
            name="searchGeneral"
          />
        </Grid>
        {/* <Grid item md={10} xs={12}>
          <Controller
            control={control}
            render={({ field: { value } }) => (
              <TextField
                fullWidth
                type="text"
                label="Description"
                size="small"
                variant="outlined"
                defaultValue={value}
                error={!!formErrors.NOME}
                InputLabelProps={{ shrink: !!value }}
                {...register("NOME")}
              />
            )}
            name="NOME"
          />
        </Grid> */}
        {/* <Grid item md={2} xs={12}>
          <Controller
            control={control}
            render={({ field: { value } }) => (
              <TextField
                fullWidth
                type="text"
                label="EDI Code"
                size="small"
                variant="outlined"
                defaultValue={value}
                error={!!formErrors.CODIGO_EDI}
                InputLabelProps={{ shrink: !!value }}
                {...register("CODIGO_EDI")}
              />
            )}
            name="CODIGO_EDI"
          />
        </Grid> */}
        {/* <Grid item md={5} xs={12}>
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={airport || []}
                getOptionLabel={(option) => `${option.description}`}
                value={airport?.findLast((item) => item.id === value) || null}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Origin airport"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => onChange(data?.id)}
              />
            )}
            name="ID_AEROPORTO"
          />
        </Grid> */}
        <Grid item xs={3}>
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={countries || []}
                getOptionLabel={(option) => `${option.description}`}
                value={countries?.findLast((item) => item.id === value) || null}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Select country"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => onChange(data?.id)}
              />
            )}
            name="ID_PAIS"
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={statusMock}
                getOptionLabel={(option: { label: string; status: boolean }) =>
                  option.label
                }
                isOptionEqualToValue={(
                  option: { label: string; status: boolean },
                  value: { label: string; status: boolean }
                ) => {
                  return (
                    option.label === value.label &&
                    option.status === value.status
                  );
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Status"
                    size="small"
                    inputRef={ref}
                    error={!!formErrors.ATIVO}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.status);
                }}
              />
            )}
            name="ATIVO"
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            disabled={isLoading}
            type="submit"
            data-testid="submit-button"
            fullWidth
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
