import {api} from "../../lib/api";
import {useEffect, useState} from "react";

import {List} from "../../types/resquest";
import {getToken} from "../../storage/auth";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";
import {ComboBoxType} from "../../types/general";
import {EdiFileItem} from "../../types/ediFile";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
}

const entity = "edi";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList
): { data: EdiFileItem[]; meta: List; isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<EdiFileItem[]>([]);
  const [meta, setMeta] = useState<List>({} as List);
  useEffect(() => {
      (async function () {
        if(params.interface) {
          setIsLoading(true);
          params.url = 'files'
          const response = await dataTableUtils.findPaginate(params);
          const {total, files, currentPage, totalPages, limit} = response.data;
          setData(files);
          setMeta((prevState) => ({
            ...prevState,
            total,
            totalPages,
            limit,
            currentPage,
          }));
          setIsLoading(false)
        }
      })();
  }, [params]);

  return { data, meta, isLoading };
};

export const findFreightForwarder = async (): Promise<ComboBoxType[]> => {
  const token = getToken();
  const { data } = await api.get('/freightForwarders/getCombo', {
    headers: { authorization: `Bearer ${token}` },
  });
  return data.filter((dataItem) => dataItem.description === 'DSV' || dataItem.description === 'SCHENKER')
};


export const findFileStatus = async (): Promise<ComboBoxType[]> => {
  const token = getToken();
  const { data } = await api.get('/edi/getFileStatusCombo', {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const findFileType = async (): Promise<ComboBoxType[]> => {
  const token = getToken();
  const { data } = await api.get('/edi/getFileTypeCombo', {
    headers: { authorization: `Bearer ${token}` },
  });
  return data;
};

export const findLogDetail = async (id: number): Promise<any> => {
  const token = getToken();
  const { data } = await api.get(`edi/files/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const findFileContent = async (id: number): Promise<any> => {
  const token = getToken();
  const { data } = await api.get(`edi/files/view/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return data;
};

export const reprocessEdi = async (id: number): Promise<any> => {
  const { data } = await api.get(`/edi/files/reprocess/${id}`);

  return data;
};
