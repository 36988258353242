import { AccordionDetails, Box, Button, Typography } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import WarehouseDialogForm from "./WarehouseDialogForm";
import { MutableRefObject, useEffect, useMemo, useState } from "react";
import { Warehouse, WarehouseDetails as IWarehouseDetails, WarehouseCreationData } from "../../../../../types/warehouse";
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import UpdateIcon from '@mui/icons-material/Update';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Accordion } from "../../../../../components/Accordion/Accordion";
import { AccordionSummary } from "../../../../../components/Accordion/AccordionSummary";
import { findDetails, save, useFind, useFindDetails } from "../../../../../services/warehouse";
import { unlinkInvoiceWarehouse as unlinkInvoiceWarehouseRequest } from "../../../../../services/invoice";
import { useList as useCountries } from "../../../../../services/countries";
import { useList as useMaterialType } from "../../../../../services/materialType";
import { findPartnerById } from "../../../../../services/partner";
import { useList as usePickupZone } from "../../../../../services/pickupZones";
import { useList as useServiceLevel } from "../../../../../services/serviceLevel";
import { useList as useConsolidationCenter } from "../../../../../services/consolidationCenter";
import { useList as useWrQuarantine } from "../../../../../services/wrQuarantine";
import { useList as useWarehouseNote } from "../../../../../services/warehouse-note";
import { useList as useQuarantine } from "../../../../../services/quarantine";
import { FulltruckEnum } from "../../../../../types/fulltruck";
import { enqueueSnackbar } from "notistack";
import WarehouseDetails from "./WarehouseDetails";
import WarehouseOldDialog from "./WarehouseOldDialog";
import WarehouseQuarantineAccordion from "./WarehouseQuarantineAccordion";
import { Invoice, InvoiceType } from "../../../../../types/invoice";
import WarehouseNoteAccordion from "./WarehouseNoteAccordion";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ComboBoxType } from "../../../../../types/general";
import { PartnerService } from "../../../../../types/partner";
import dayjs from "dayjs";

type WarehouseAccordionProps = Readonly<{
	token?: string,
	invoice: Invoice | null,
	idUser?: number,
	ref?: MutableRefObject<HTMLDivElement | null>,
	onChange?: () => void,
	expanded?: boolean,
	isDisabled: boolean
}>;

const fullTruck: ComboBoxType[] = [
	{ id: FulltruckEnum.NO, description: FulltruckEnum.NO },
	{ id: FulltruckEnum.YES, description: FulltruckEnum.YES },
]

export default function WarehouseAccordion({ token, invoice, idUser, ref, onChange, expanded, isDisabled }: WarehouseAccordionProps) {
	const [isWarehouseDialogFormVisible, setIsWarehouseDialogFormVisible] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showWarehouseOld, setShowWarehouseOld] = useState<boolean>(false);
	const [hasData, setHasData] = useState<boolean>(false);
	const [warehouseOldDetails, setWarehouseOldDetails] = useState<IWarehouseDetails | undefined>({} as IWarehouseDetails);
	const [params] = useState<IParams>({
		page: 1,
		limit: 50,
	} as IParams);
	const [quarentineParams] = useState<IParams>({
		page: 1,
		limit: 50,
		url: `findPaginate?ATIVO=${true}`
	} as IParams);
	const [formMode, setFormMode] = useState<string>('add');
	const [partner, setPartner] = useState<PartnerService>()
	const [quarantineError, setQuarantineError] = useState(false);

	const {
		warehouse,
		setRefetch: setRefetchWarehouse,
		setNumWarehouse,
		fetchedByNumber: fetchedByWarehouseNumber,
		setFetchedByNumber,
		setWarehouse
	} = useFind(invoice?.ID_INVOICE);
	const { warehouseDetails, setRefetch: setRefetchWarehouseDetails } = useFindDetails(warehouse?.ID_WAREHOUSE!);
	const { wrQuarantines, setRefetch: setRefetchWrQuarantines } = useWrQuarantine(warehouse?.ID_WAREHOUSE);
	const { warehouseNotes, setRefetch: setRefetchWarehouseNotes } = useWarehouseNote(warehouse?.ID_WAREHOUSE);

	useEffect(() => {
		const getPartner = async () => {
			const partnerResponse = await findPartnerById(invoice?.ID_PARCEIRO_AGENTE_CARGA!);
			setPartner(partnerResponse);
		}

		if (invoice?.ID_PARCEIRO_AGENTE_CARGA) {
			getPartner();
		}
	}, [invoice?.ID_PARCEIRO_AGENTE_CARGA]);

	useEffect(() => {
		setHasData(!!warehouse?.created_at);
	}, [warehouse]);

	const { data: quarantines } = useQuarantine(quarentineParams, token);

	const handleOpenWarehouseDialogForm = (mode = 'add') => {
		setIsWarehouseDialogFormVisible(true);
		setFormMode(mode);
	}

	const handleCloseWarehouseDialogForm = () => {
		setIsWarehouseDialogFormVisible(false);
		setFetchedByNumber(false);
		if (!invoice?.warehouse?.ID_WAREHOUSE) setWarehouse({} as any);
		setFormMode('add');
		setErrors([]);
	}

	const handleOpenOldWarehouseDialog = async () => {
		if (invoice?.ID_WAREHOUSE_OLD) {
			const warehouseOldDetailsData = await findDetails(invoice.ID_WAREHOUSE_OLD);
			setWarehouseOldDetails(warehouseOldDetailsData);
			setShowWarehouseOld(true);
		}
	}

	const { combo: countriesCombo } = useCountries({}, true);
	const { combo: materialTypeCombo } = useMaterialType(params, token, true);
	const { data: pickupZones } = usePickupZone(params, token);
	const { combo: pickupPriorityCombo } = useServiceLevel(params, token, true);
	const { combo: consolidationCenterCombo } = useConsolidationCenter(params, token, true);

	const onFormSubmit = async (warehouse: Warehouse) => {
		try {
			let changeableWeightValue = 0;

			warehouse.QUANT_PESO_BRUTO = Number(warehouse.QUANT_PESO_BRUTO.toString().replace(',', '.'));
			warehouse.QUANT_PESO_CUBICO = Number(warehouse.QUANT_PESO_CUBICO.toString().replace(',', '.'));
			if (warehouse.QUANT_PESO_CUBICO > warehouse.QUANT_PESO_BRUTO) changeableWeightValue = warehouse.QUANT_PESO_CUBICO;
			if (warehouse.QUANT_PESO_CUBICO < warehouse.QUANT_PESO_BRUTO) changeableWeightValue = warehouse.QUANT_PESO_BRUTO;

			const defaultParams = {
				DSC_FULL_TRUCK: null,
				QUANT_VOLUME_ONHAND: null,
				DSC_HOUSE: null,
				DATA_QUARENTENA: null,
				DATA_1_CONFERENCIA: null,
				QUANT_VOLUME_RECEBIDO: null,
			}

			if (quarantineError) warehouse.DATA_ON_HAND = null;

			const warehouseCreationData: WarehouseCreationData = {
				...defaultParams,
				...warehouse,
				ID_PRIORIADE_PICK_UP: warehouse?.ID_PRIORIADE_PICK_UP || null,
				ID_PICK_UP_ZONE: warehouse?.ID_PICK_UP_ZONE || null,
				ID_CENTRO: warehouse?.ID_CENTRO || invoice?.ID_CENTRO || null,
				DATA_ON_HAND: warehouse.DATA_ON_HAND ? new Date(warehouse.DATA_ON_HAND) : null,
				DATA_PICK_UP: warehouse.DATA_PICK_UP ? new Date(warehouse.DATA_PICK_UP) : null,
				QUANT_PESO_TAXADO: changeableWeightValue,
			}

			if (invoice?.parceiroAgenteCarga?.ID_CENTRO_CONSOLIDADOR.toString() !== warehouse?.ID_CENTRO_CONSOLIDADOR.toString()) {
				enqueueSnackbar(`The Consolidator Center informed is not the same consolidator center associated with the Freight Forwarder on the invoice: ${consolidationCenterCombo.find((item: ComboBoxType) => item.id === invoice?.parceiroAgenteCarga?.ID_CENTRO_CONSOLIDADOR)?.description || ''} `, { variant: "error" });
				return;
			}

			const { success, message } = await save(warehouseCreationData, invoice?.ID_INVOICE);
			if (success) {
				enqueueSnackbar("Warehouse saved successfully", {
					variant: "success",
				});
				setFetchedByNumber(false);
				setNumWarehouse("");
				setRefetchWarehouse(true);
				setRefetchWarehouseDetails(true);
				handleCloseWarehouseDialogForm();
				setQuarantineError(false);
				window.location.reload();
			} else {
				setErrors(message);
				if (message[0].includes('WR has open quarantine')) setQuarantineError(true);
			}

			setIsLoading(false);
		} catch (error: any) {
			setErrors([...errors, error.message])
			setIsLoading(false);
		}
	}

	const onFormCancel = () => {
		setErrors([]);
		setNumWarehouse('');
		handleCloseWarehouseDialogForm();
	}

	const unlinkInvoiceWarehouse = async () => {
		const response = await unlinkInvoiceWarehouseRequest(invoice?.ID_INVOICE);

		if (response) {
			enqueueSnackbar("Warehouse unlinked successfully", {
				variant: "success",
			});

			handleCloseWarehouseDialogForm();
			setRefetchWarehouse(true);
			window.location.reload();
			return;
		}

		enqueueSnackbar("Error trying to  unlink Warehouse", {
			variant: "error",
		});

		return;
	}

	const warehouseDetailsList = useMemo(() => {
		return [
			{
				content: warehouseDetails?.ORIGIN_CONSOLIDATION_CENTER,
				title: 'Origin Consolidation Center',
			},
			{
				content: warehouseDetails?.ORIGIN,
				title: 'Origin',
			},
			{
				content: warehouseDetails?.DELIVERED_TRACKING_NUMBER,
				title: 'Delivered Tracking Number',
			},
			{
				content: warehouseDetails?.MATERIAL_TYPE,
				title: 'Material Type',
			},
			{
				content: warehouseDetails?.TOTAL_INVOICED_QTY,
				title: 'Total Invoice Qty',
			},
			{
				content: warehouseDetails?.TOTAL_VOLUME_QTY,
				title: 'Total Volume Qty',
			},
			{
				content: warehouseDetails?.PICK_UP_PRIORITY,
				title: 'Pick up Priority',
			},
			{
				content: warehouseDetails?.FULL_TRUCK,
				title: 'Full Truck',
			},
			{
				content: warehouseDetails?.READY_TO_SHIP_DATE ? dayjs(warehouseDetails?.READY_TO_SHIP_DATE).format("MM/DD/YYYY") : undefined,
				title: 'Ready to Ship Date',
			},
			{
				content: warehouseDetails?.PICK_UP_ZONE,
				title: 'Pick up Zone',
			},
			{
				content: warehouseDetails?.PICK_UP_DATE ? dayjs(warehouseDetails?.PICK_UP_DATE).format("MM/DD/YYYY") : undefined,
				title: 'Pick up Date',
			},
			{
				content: warehouseDetails?.ON_HOLD_DATE ? dayjs(warehouseDetails?.ON_HOLD_DATE).format("MM/DD/YYYY") : undefined,
				title: 'On Hold Date',
			},
			{
				content: String(warehouseDetails?.GROSS_WEIGHT),
				title: 'Gross Weight',
			},
			{
				content: String(warehouseDetails?.CUBIC_WEIGHT),
				title: 'Cubic Weight',
			},
			{
				content: String(warehouseDetails?.CHANGEABLE_WEIGHT),
				title: 'Changeable Weight',
			},
			{
				content: warehouseDetails?.QUARENTINE_DATE ? dayjs(warehouseDetails?.QUARENTINE_DATE).format("MM/DD/YYYY") : undefined,
				title: 'Quarantine Date',
			},
		]
	}, [warehouseDetails])

	const warehouseOldDetailsList = useMemo(() => {
		return [
			{
				content: warehouseOldDetails?.ORIGIN_CONSOLIDATION_CENTER,
				title: 'Origin Consolidation Center',
			},
			{
				content: warehouseOldDetails?.ORIGIN,
				title: 'Origin',
			},
			{
				content: warehouseOldDetails?.DELIVERED_TRACKING_NUMBER,
				title: 'Delivered Tracking Number',
			},
			{
				content: warehouseOldDetails?.MATERIAL_TYPE,
				title: 'Material Type',
			},
			{
				content: warehouseOldDetails?.TOTAL_INVOICED_QTY,
				title: 'Total Invoice Qty',
			},
			{
				content: warehouseOldDetails?.TOTAL_VOLUME_QTY,
				title: 'Total Volume Qty',
			},
			{
				content: warehouseOldDetails?.PICK_UP_PRIORITY,
				title: 'Pick up Priority',
			},
			{
				content: warehouseOldDetails?.FULL_TRUCK,
				title: 'Full Truck',
			},
			{
				content: warehouseOldDetails?.READY_TO_SHIP_DATE ? dayjs(warehouseOldDetails?.READY_TO_SHIP_DATE).format("MM/DD/YYYY") : undefined,
				title: 'Ready to Ship Date',
			},
			{
				content: warehouseOldDetails?.PICK_UP_ZONE,
				title: 'Pick up Zone',
			},
			{
				content: warehouseOldDetails?.PICK_UP_DATE ? dayjs(warehouseOldDetails?.PICK_UP_DATE).format("MM/DD/YYYY") : undefined,
				title: 'Pick up Date',
			},
			{
				content: warehouseOldDetails?.ON_HOLD_DATE ? dayjs(warehouseOldDetails?.ON_HOLD_DATE).format("MM/DD/YYYY") : undefined,
				title: 'On Hold Date',
			},
			{
				content: String(warehouseOldDetails?.GROSS_WEIGHT),
				title: 'Gross Weight',
			},
			{
				content: String(warehouseOldDetails?.CUBIC_WEIGHT),
				title: 'Cubic Weight',
			},
			{
				content: String(warehouseOldDetails?.CHANGEABLE_WEIGHT),
				title: 'Changeable Weight',
			},
			{
				content: warehouseOldDetails?.QUARENTINE_DATE ? dayjs(warehouseOldDetails?.QUARENTINE_DATE).format("MM/DD/YYYY") : undefined,
				title: 'Quarantine Date',
			},
		]
	}, [warehouseOldDetails]);

	return (
		<Accordion
			sx={{
				'&.MuiAccordion-root': { border: 0, marginBottom: 3, marginTop: 1 },
			}}
			ref={ref}
			onChange={onChange}
			expanded={expanded}
		>
			<AccordionSummary
				expandIcon={<GridExpandMoreIcon />}
				aria-controls='panel1a-content'
				applyOpacity={!hasData}
			>
				<Typography>Warehouse Receipt</Typography>
			</AccordionSummary>
			<AccordionDetails style={{ marginTop: 17, padding: 2 }}>
				{warehouse?.ID_WAREHOUSE ? (
					<Box display="flex" justifyContent="space-between">
						<Box>
							{![InvoiceType.TRIAGE_DOMESTIC, InvoiceType.TRIAGE_INTERNATIONAL].includes(invoice?.ID_TIPO_INVOICE as InvoiceType) && <Button 
								variant="outlined" 
								startIcon={<EditIcon />} 
								onClick={() => handleOpenWarehouseDialogForm('edit')} 
								disabled={isDisabled}
								style={{
									display: isDisabled ? 'none' : 'inline-flex',
									alignItems: 'center',
								}}
							>
								Edit
							</Button>}
						</Box>
						<Box>
							<Button 
								disabled={!invoice?.ID_WAREHOUSE_OLD}
								onClick={() => handleOpenOldWarehouseDialog()} 
								startIcon={<UpdateIcon />} 
								variant="outlined" 
								color="warning"
								style={ isDisabled ? {
									display: 'flex',
									alignItems: 'flex-end',
									justifyContent: 'flex-end',
								} : {} }
							>
								WR Old
							</Button>
						</Box>
					</Box>				
				) : (
					<Button 
						variant="contained" 
						startIcon={<AddIcon />} 
						onClick={() => handleOpenWarehouseDialogForm('add')}
						disabled={isDisabled}
						style={{
							display: isDisabled ? 'none' : 'inline-flex',
							alignItems: 'center',
						}}
					>
						Edit
					</Button>
				)}
				{warehouse?.ID_WAREHOUSE && !fetchedByWarehouseNumber && (
					<WarehouseDetails
						warehouseNumber={warehouse.NUM_WAREHOUSE}
						warehouseFields={warehouseDetailsList ? warehouseDetailsList : []}
						warehouseDialogOld={false}
						warehouseStatus={warehouse.ID_STATUS_WAREHOUSE}
					/>
				)}
				{warehouseOldDetails?.NUM_WAREHOUSE && (
					<WarehouseOldDialog
						open={showWarehouseOld}
						handleClose={() => setShowWarehouseOld(false)}
						warehouseNumber={warehouseOldDetails?.NUM_WAREHOUSE}
						wrStatus={warehouseOldDetails.ID_STATUS_WAREHOUSE}
						warehouseFields={warehouseOldDetailsList ? warehouseOldDetailsList : []}
					/>
				)}
				{warehouse?.ID_WAREHOUSE && (
					<WarehouseQuarantineAccordion
						wrQuarantines={wrQuarantines}
						quarantines={quarantines}
						invoiceId={invoice?.ID_INVOICE!}
						idWarehouse={warehouse.ID_WAREHOUSE}
						setRefetchWrQuarantines={setRefetchWrQuarantines}
						setRefetchWarehouse={setRefetchWarehouse}
						idLoginUsuario={idUser}
						isDisabled={isDisabled}
					/>
				)}
				{warehouse?.ID_WAREHOUSE && (
					<WarehouseNoteAccordion
						idWarehouse={warehouse.ID_WAREHOUSE}
						idUser={idUser}
						warehouseNotes={warehouseNotes}
						setRefetchWarehouseNotes={setRefetchWarehouseNotes}
						isDisabled={isDisabled}
					/>
				)}
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<WarehouseDialogForm
						countriesCombo={countriesCombo}
						materialTypeCombo={materialTypeCombo}
						pickupZonePriorityCombo={pickupPriorityCombo}
						pickupZones={pickupZones}
						setNumWarehouse={setNumWarehouse}
						consolidationCenterCombo={consolidationCenterCombo}
						fullTruck={fullTruck}
						isLoading={isLoading}
						onSubmit={onFormSubmit}
						onCancel={onFormCancel}
						onUnlink={unlinkInvoiceWarehouse}
						open={isWarehouseDialogFormVisible}
						handleClose={handleCloseWarehouseDialogForm}
						warehouse={warehouse}
						errors={errors}
						wrQuarantines={wrQuarantines}
						consolidationCenterSelected={partner}
						mode={formMode}
						invoicePackages={invoice?.invoicePackages}
						quarantineError={quarantineError}
						fetchedByWarehouseNumber={fetchedByWarehouseNumber}
						isInvoiceTriage={!!(invoice?.ID_TIPO_INVOICE && [InvoiceType.TRIAGE_DOMESTIC, InvoiceType.TRIAGE_INTERNATIONAL].includes(invoice.ID_TIPO_INVOICE))}
						autocompletions={invoice?.invoicePackages.length ? (
							invoice.invoicePackages.reduce((previous, current) => {
								previous.NUM_PESO_BRUTO += +(current.NUM_PESO_BRUTO ?? 0);
								previous.NUM_PESO_CUBICO += +(current.NUM_PESO_CUBICO ?? 0);
								return previous;
							}, { NUM_PESO_BRUTO: 0, NUM_PESO_CUBICO: 0, })
						) : (
							{ NUM_PESO_BRUTO: '', NUM_PESO_CUBICO: '', }
						)}
					/>
				</LocalizationProvider>
			</AccordionDetails>
		</Accordion>
	)
}