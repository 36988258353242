import { Alert, Box, Dialog, DialogContent, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { buyerGroupResults } from "../../../../../types/buyerGroupSup";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface DialogResultBuyerGroupSupProps {
  openDialogResult: boolean;
  setOpenDialogResult: (value: boolean) => void;
  resultBuyerGroupSup: buyerGroupResults;
}

export default function DialogResultBuyerGroupSup({
  openDialogResult,
  setOpenDialogResult,
  resultBuyerGroupSup,
}: DialogResultBuyerGroupSupProps) {
  return (
    <Dialog
      open={openDialogResult}
      onClose={() => setOpenDialogResult(false)}
      scroll='paper'
      fullWidth={true}
      maxWidth='lg'
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title" marginBottom={4}>
        <Box sx={{ float: 'right' }}>
          <IconButton data-testid="close-button" aria-label="delete" onClick={() => setOpenDialogResult(false)}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Box>
        Upload Suppliers Manager - Log
        <Grid container spacing={4} marginTop={4}>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              value={resultBuyerGroupSup.registers}
              label="Number of records"
              variant="outlined"
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              value={resultBuyerGroupSup.registersSaved }
              label="Number of saved records"
              variant="outlined"
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              value={resultBuyerGroupSup.registersError}
              label="Number of records with errors"
              variant="outlined"
              fullWidth
              disabled={true}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {resultBuyerGroupSup.errors.length > 0 ? 
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell variant='head'>Occurrence</TableCell>
                      <TableCell>Row</TableCell>
                      <TableCell>Column</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resultBuyerGroupSup.errors.map((row) => (
                      <TableRow key={`table-row-${row.row}`}>
                        <TableCell>{row.message}</TableCell>
                        <TableCell>{row.row}</TableCell>
                        <TableCell>{row.column}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              : 
              <Alert severity="success" style={{ marginBottom: 20 }}>
                All entries saved successfully!
              </Alert>
            }
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}