import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { Quarantine } from "../../../../../types/quarantine";

let quarantineScheme = yup.object({
  CODIGO_QUARENTENA: yup.string().max(10).required(),
  DESCRICAO: yup.string().max(100).required(),
  ATIVO: yup.boolean().notRequired(),
});

interface QuarantineFormProps {
  quarantine: Quarantine;
  onSubmit: (quarantine: Quarantine) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly?: boolean;
}

export default function QuarantineForm({
  quarantine,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  controlEdit,
  readOnly,
}: QuarantineFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(quarantineScheme),
  });

  useEffect(() => {
    reset(quarantine);
  }, [quarantine, reset]);

  const handleFormSubmit = (newItem: any) => {
    onSubmit(newItem);
  };

  const handleCancel = () => {
    reset({ CODIGO_QUARENTENA: "", DESCRICAO: "", ATIVO: true });
    onCancel();
  };
  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="quarantine-form"
    >
      {errors.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="search"
        container
        marginTop={1}
        border={1}
        padding={2}
        spacing={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >
        <Grid container spacing={2} marginBottom={2}>
          <Grid item md={2} xs={4}>
            <TextField
              fullWidth
              label="Code"
              variant="outlined"
              disabled={controlEdit.isInUse || readOnly}
              size="small"
              {...register("CODIGO_QUARENTENA")}
              error={!!formErrors.CODIGO_QUARENTENA}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 10, 'data-testid': 'code-input' }}
            />
          </Grid>
          <Grid item md={8} xs={8}>
            <TextField
              fullWidth
              label="Description"
              variant="outlined"
              size="small"
              disabled={controlEdit.isInUse || readOnly}
              {...register("DESCRICAO")}
              error={!!formErrors.DESCRICAO}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 100, 'data-testid': 'description-input' }}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              render={({ field: { value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!value}
                      disabled={readOnly}
                      inputProps={{ "aria-label": "controlled" }}
                      {...register("ATIVO")}
                    />
                  }
                  labelPlacement="start"
                  label="Status"
                />
              )}
              name="ATIVO"
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" marginTop={2} spacing={2}>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={isLoading}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          {!readOnly ? (
            <Grid marginLeft={2}>
              <Button
                  variant="contained"
                  disabled={isLoading}
                  type="submit"
                  data-testid="submit-button"
              >
                Confirm
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
}
