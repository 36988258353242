import {
    Box,
    Button,
    Grid,
    TextField,
    Autocomplete,
    Chip,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import { getSendVisibilitiesMail } from "../../../../../services/report";

export default function ListAndSendMail({
    emails,
    idCentro,
    graphs,
}) {
    const {
        handleSubmit,
        formState: { errors: formErrors },
        control,
    } = useForm();

    async function handleSendMail(data) {
        try {
            // setIsLoading(true);
            const response = await getSendVisibilitiesMail({
                ID_CENTRO: idCentro,
                emails: data.ID_CENTRO,
                graphs,
            });
            // setData(response);
        } catch (error) {
            console.error('Error fetching send visibilities:', error);
        } finally {
            // setIsLoading(false);
        }
    }

    return (
        <form
            onSubmit={handleSubmit(handleSendMail)}
            data-testid="consolidation-center-avanced-search"
        >
            <Grid container spacing={2} marginTop={2} marginBottom={2}>
                <Grid item xs={12} md={6} lg={3}>
                    <Controller
                        name="ID_CENTRO"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                multiple
                                options={emails}
                                getOptionLabel={(option) => option || ""}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue?.map((option, index) => (
                                        <Chip
                                            label={option}
                                            {...getTagProps({ index })}
                                            onDelete={() => {
                                                const updatedValue = tagValue?.filter(
                                                    (item) => item !== option
                                                );
                                                field.onChange(updatedValue);
                                            }}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="E-mails"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                )}
                                onChange={(_, newValue) => {
                                    field.onChange(newValue);
                                }}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={2}>
                    <Button
                        variant="outlined"
                        type="submit"
                        sx={{ textTransform: "none", whiteSpace: "nowrap" }}
                        fullWidth
                        startIcon={<EmailIcon fontSize="small" color="primary" />}
                        data-testid="send-mail-button"
                    >
                        Send Mail
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
