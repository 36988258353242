import { useEffect, useState } from "react";
import { api } from "../../lib/api";
import { List } from "../../types/resquest";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";
import { FreightForwarderRule } from "../../types/freightForwarderRule";
import { ComboBox } from "../../types/comboBox";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
}

const entity = "freightForwarderRules";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  isCombo?: boolean
): {
  data: FreightForwarderRule[];
  meta: List;
  isLoading: boolean;
  center: ComboBox[];
  country: ComboBox[];
  serviceLevel: ComboBox[];
  freightForwarder: ComboBox[];
  error: Error;
  broker: ComboBox[];
} => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<FreightForwarderRule[]>([{} as FreightForwarderRule]);
  const [meta, setMeta] = useState<List>({} as List);
  const [error, setError] = useState<Error>({} as Error);
  const [center, setCenter] = useState<ComboBox[]>([]);
  const [country, setCountry] = useState<ComboBox[]>([]);
  const [serviceLevel, setServiceLevel] = useState<ComboBox[]>([]);
  const [broker, setBroker] = useState<ComboBox[]>([]);
  const [freightForwarder, setFreightForwarder] = useState<ComboBox[]>([]);

  const render = () => {
      if (isCombo === false || isCombo === undefined) {
        return "pagination";
      }
  };

  useEffect(() => {
    if (render() === "pagination") {
      (async function () {
        setIsLoading(true);
        const response = await dataTableUtils.findPaginate(params);
        const {total, data, currentPage, totalPages, limit} = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
        
        api
        .get(`/centers/getCentersWithCompaniesCombo`)
        .then((response) => {
          const { data } = response;
          setCenter(data);
        })
        .catch(setError)
      api
        .get(`/countries/getCombo`)
        .then((response) => {
          const { data } = response;
          setCountry(data);
        })
        .catch(setError)
      api
        .get(`/serviceLevels/getCombo`)
        .then((response) => {
          const { data } = response;
          setServiceLevel(data);
        })
        .catch(setError)
      api
        .get(`/partners/getCombo?partnerType=FREIGHT FORWARDER`)
        .then((response) => {
          const { data } = response;
          setFreightForwarder(data);
        })
        .catch(setError)

        api
          .get("/partners/getCombo?partnerType=BROKER")
          .then((response) => {
            const { data } = response;
            setBroker(data);
          });

      setIsLoading(false)
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return { data, meta, isLoading, center, country, serviceLevel, freightForwarder, error, broker };
};

export const deleteFreightForwarderRule = async (
  id: number | string
): Promise<FreightForwarderRule> => {
  const { data } = await api.delete(`/${entity}/${id}`);

  return data;
};

export const create = async (freightForwarder: FreightForwarderRule): Promise<FreightForwarderRule> => {
  const { data } = await api.post(`/${entity}`, freightForwarder);

  return data;
};

export const update = async (id: number | string, freightForwarder: FreightForwarderRule): Promise<FreightForwarderRule> => {
  const { data } = await api.patch(`/${entity}/${id}`, freightForwarder);

  return data;
};

export const createCopy = async (
  freightForwarder: FreightForwarderRule[]
): Promise<FreightForwarderRule[]> => {
  const { data } = await api.post(`/${entity}/copy`, freightForwarder);

  return data;
};

export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: "Company", value: "GE_SIGLA_EMPRESA" },
    { label: "Center Abreviation", value: "GC_DSC_SIGLA" },
    { label: "Center Name", value: "GC_NOME_CENTRO" },
    { label: "Origin Country Abreviation", value: "GPO_SIGLA_PAIS" },
    { label: "Origin Country Name", value: "GPO_NOME_PAIS" },
    { label: "Destination Country Abreviation", value: "GPD_SIGLA_PAIS" },
    { label: "Destination Country Name", value: "GPD_NOME_PAIS" },
    { label: "DG",
      value: (row: any) => (row.DG ? "TRUE" : "FALSE"),
    },
    { label: "Service Level Name", value: "GPRI_DSC_PRIORIDADE" },
    { label: "Freight Forwarder Partner Name", value: "GPAR_NOME_PARCEIRO" },
  ]
  await dataTableUtils.exportAll({params, xlsxData, filename: "RulesForFreightForwarder"});
};

export const getCenters = async (center: { id: any; }) => {
  if(!center?.id) return;
  const { data } =  await dataTableUtils.findPaginate({
    ID_CENTRO: center.id,
    ATIVO: true,
    page: 1
  });
  return data;
}