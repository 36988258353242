import { Box, Grid, Typography } from "@mui/material";
import { Invoice } from "../../../../types/invoice";

interface POContentProps {
  invoice: Invoice | null;
}

const POContent = ({ invoice }: POContentProps) => {
  return (
    <Box>
      <Grid
        style={{
          marginTop: 30,
        }}
        container
      >
        <Grid
          xs={4}
        >
          <div>
            <Typography
              style={{
                margin: "0 4px 0 10px",
              }}
              sx={{ fontWeight: "bold" }}
              variant="h6"
            >
              PO:
            </Typography>
            <Typography
              style={{
                margin: "0 4px 0 10px",
                color: "#5b9dc7",
              }}
              sx={{ fontWeight: "bold" }}
              variant="h5"
            >
              {invoice?.po?.NUMERO_PO}
            </Typography>
          </div>
          <div
            style={{
              margin: "20px 4px 0 10px",
            }}
          >
            <Typography><b>Supplier</b></Typography>
            <Typography>
              <b>
                {invoice?.po?.CODIGO_MFIR_FORNECEDOR} - {invoice?.po?.NOME_FORNECEDOR}
              </b>
            </Typography>
            <Typography>
              {invoice?.po?.fornecedor?.DSC_ENDERECO1} {invoice?.po?.fornecedor?.DSC_ENDERECO2}
            </Typography>
            <Typography>
              {invoice?.po?.fornecedor?.DSC_CIDADE} - {invoice?.po?.fornecedor?.DSC_ESTADO}
            </Typography>
            <Typography>
              {invoice?.po?.fornecedor?.DSC_CEP}
            </Typography>
            <Typography>
              {invoice?.po?.fornecedor?.pais?.SIGLA_PAIS}
            </Typography>
          </div>
        </Grid>

        <Grid
          xs={8}
          style={{
            paddingRight: 5
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>Plant</Typography>
          <div
            style={{
              padding: 4,
              border: "1px solid rgba(0, 0, 0, 0.6)",
              width: "100%",
              minHeight: "20px",
              borderRadius: 4,
            }}
          >
            <Typography>
              {
                !invoice?.po
                ? null
                : `${invoice?.po?.SIGLA_EMPRESA} - ${invoice?.centro?.DSC_SIGLA} - ${invoice?.centro?.NOME_CENTRO}; ${invoice?.centro?.DSC_ENDERECO}, ${invoice?.centro?.DSC_CIDADE} - ${invoice?.centro?.DSC_ESTADO}; ${invoice?.centro?.pais?.SIGLA_PAIS} - ${invoice?.centro?.pais?.NOME_PAIS}`
              }
            </Typography>
          </div>
          <Grid container>
            <Grid xs={6}>
              <Typography
                style={{
                  marginTop: 25,
                }}
                sx={{ fontWeight: "bold" }}
              >
                Bill To
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {invoice?.po?.NOME_PAGADOR}
              </Typography>
              <Typography>
                {invoice?.po?.DSC_ENDERECO_PAGADOR}
              </Typography>
              <Typography>
                {invoice?.po?.CIDADE_PAGADOR} {invoice?.po?.ESTADO_PAGADOR ? `- ${invoice?.po?.ESTADO_PAGADOR}` : ''}
              </Typography>
              <Typography>
                {invoice?.po?.COD_POSTAL_PAGADOR}
              </Typography>
              <Typography>
                {invoice?.po?.SIGLA_PAIS_PAGADOR}
              </Typography>
            </Grid>
            <Grid xs={6} textAlign="right">
              <Typography
                style={{
                  marginTop: 25,
                }}
              >
                Incoterms: {invoice?.po?.SIGLA_INCOTERMS}
              </Typography>
              <Typography>
                Incoterms Complement: {invoice?.po?.DSC_INCOTERMS_COMPL}
              </Typography>
              <Typography>PAYMENT: {invoice?.po?.DSC_CATEGORIA_PGTO}</Typography>
              <Typography>
                PURCHASE: {`${invoice?.po?.CODIGO_GRUPO_COMPR} - ${invoice?.po?.grupoComprador.NOME_COMPRADOR}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default POContent;
