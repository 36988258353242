import { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, FilledTextFieldProps, Grid, OutlinedTextFieldProps, Paper, StandardTextFieldProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, TextFieldVariants, Typography } from "@mui/material";
import InvoiceHeader from "../invoiceDetails/InvoiceHeader";
import { useNavigate, useParams } from "react-router-dom";
import { getInvoice } from "../../../../services/invoiceDetails";
import { getToken } from "../../../../storage/auth";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Invoice } from "../../../../types/invoice";
import { enqueueSnackbar } from "notistack";
import SearchIcon from "@mui/icons-material/Search";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import { JSX } from "react/jsx-runtime";
import DataTable from "./components/DataTable";
import dayjs from "dayjs";
import { useList } from "../../../../services/invoiceHistory";
import { DatePicker } from "@mui/x-date-pickers";
import { ComboBoxType } from "../../../../types/general";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useList as useUserLogin } from "../../../../services/user-login";
import { HistoryTypes, HistoryTypesType, InvoiceHistoryService } from "../../../../types/invoiceHistory";

interface IParams {
  ID_INVOICE: number;
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
}

type SearchParamsProps = {
  ID_LOGIN: number;
  ID_INVOICE: number;
  ID_TIPO_HISTORICO_INVOICE: HistoryTypesType[];
  DATA_OCORRENCIA_INITIAL: Date;
  DATA_OCORRRENCIA_FINAL: Date;
}

type JsonObject = { [key: string]: any };

interface ComparisonValues {
  from: string;
  to: string;
}

const historyTypes = Object.values(HistoryTypes).map((key: string) => ({ id: key, description: key  }));

const InvoiceLog = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [openLogModal, setOpenLogModal] = useState(false);
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useState<SearchParamsProps>();
  const [selected, setSelected] = useState<InvoiceHistoryService>();
  const [params, setParams] = useState<IParams>({
    ID_INVOICE: id,
    page: 1,
    limit: 10,
    order: 'DESC',
  } as unknown as IParams);
  const { data = [], meta = {} } = useList(params, token) || {};
  const { combo: logins } = useUserLogin(params, token, true);
  
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };
  
  const fetchInvoiceByID = async (invoiceId: number) => {
    setIsLoading(true)
    try {
      const data = await getInvoice(invoiceId)
      setInvoice(data)
      onChangeSearchParam('ID_INVOICE')(invoiceId);
    } catch (err) {
      enqueueSnackbar('Could not fetch invoice', { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const getTokenSession = () => {
    const token = getToken();

    if (token) {
      setToken(token);
    }
  };

  useEffect(() => {
    getTokenSession();
  }, []);

  useEffect(() => {
    if (id) fetchInvoiceByID(+id);
  }, [id]);

  const onReturnToPreviousPage = () => {
    navigate(-1);
  };

  const onChangeSearchParam = (field: string) => (value: any) => {
    setSearchParams((oldState: any) => ({ ...oldState, [field]: value }));
  }

  const getComparisonValues = (
    field: string,
    oldObj: JsonObject = {},
    newObj: JsonObject = {}
  ): ComparisonValues => {
    const oldObjValue = String(oldObj[field] ?? '');
    const newObjValue = String(newObj[field] ?? '');
  
    // const isAltered = newObj.hasOwnProperty(field) && oldObjValue !== newObjValue;
  
    return {
      from: oldObjValue,
      to: newObjValue,
    };
  };  

  return (
    <Grid marginTop={4}>
      <LoadingOverlay isLoading={isLoading} />
      <Grid container spacing={1} direction='column' marginBottom={4}>
        <Grid item xs={10}>
          <Typography variant='h5'>Invoice History</Typography>
        </Grid>
      </Grid>
      <Paper
        variant='outlined'
        sx={{ marginTop: 3, paddingX: 2, paddingBottom: 2 }}
      >
        <InvoiceHeader invoice={invoice} />
      </Paper>

      <Paper
        variant="outlined"
        sx={{ marginTop: 3, paddingX: 2, paddingBottom: 2 }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container marginTop={1} spacing={2}>
            <Grid item>
              <DatePicker
                label="Initial Date"                
                format="MM/DD/YYYY"
                slotProps={{ textField: { size: 'small' } }}
                onChange={(date: Date|string|null|undefined) => {
                  console.log(date)
                  const formattedDate = date && dayjs(date).isValid() ? dayjs(date).format("YYYY-MM-DD") : null;
                  onChangeSearchParam('DATA_OCORRENCIA_INITIAL')(formattedDate);
                  
                }}
                renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined; } & Omit<OutlinedTextFieldProps | FilledTextFieldProps | StandardTextFieldProps, "variant">) => (
                  <TextField
                    {...params}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item textAlign="center" alignContent="center">
              until
            </Grid>
            <Grid item>
              <DatePicker
                label="Final Date"                
                format="MM/DD/YYYY"
                slotProps={{ textField: { size: 'small' } }}
                onChange={(date: Date|string|null|undefined) => {
                  const formattedDate = date && dayjs(date).isValid() ? dayjs(date).format("YYYY-MM-DD") : null;
                  onChangeSearchParam('DATA_OCORRENCIA_FINAL')(formattedDate);
                }}
                renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined; } & Omit<OutlinedTextFieldProps | FilledTextFieldProps | StandardTextFieldProps, "variant">) => (
                  <TextField
                    {...params}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={3}>
              <Autocomplete
                options={logins}
                getOptionLabel={(option) => option.description}
                value={
                  logins?.findLast((item: ComboBoxType) => item?.id === (searchParams?.ID_LOGIN!))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Login"
                    size="small"
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChangeSearchParam('ID_LOGIN_USUARIO')(data?.id);
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <Autocomplete
                multiple
                options={historyTypes}
                getOptionLabel={(option) => option.description}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="History Type"
                    size="small"
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChangeSearchParam('ID_TIPO_HISTORICO_INVOICE')(data?.map((item: ComboBoxType) => item?.id));
                }}
              />
            </Grid>

            <Grid item xs={1}>
              <Button
                sx={{ marginLeft: 2 }}
                variant="contained"
                onClick={() => handleRequest({ ...searchParams })}
              >
                <SearchIcon />
              </Button>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Paper>

      <Grid marginTop={2}>
        <DataTable
          loading={isLoading}
          data={data}
          logins={logins}
          onView={(params: InvoiceHistoryService) => {
            setSelected(params);
            setOpenLogModal(true);
          }}
          rowCount={meta?.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          pagination
          paginationModel={paginationModel}
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>

      <Dialog
        open={openLogModal}
        onClose={() => setOpenLogModal(false)}
        scroll='paper'
        fullWidth={true}
        maxWidth='lg'
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title" marginBottom={4}>
          <Box sx={{ float: 'right' }}>
            <IconButton data-testid="close-button" aria-label="delete" onClick={() => setOpenLogModal(false)}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
          Invoice History - {invoice?.NUM_INVOICE}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {selected?.LEGADO ? selected?.LOG_INVOICE_MIGRADA : selected?.JUSTIFICATIVA}
          </DialogContentText>

          <Grid container spacing={4}>
          <Grid item xs={12}>
            {selected?.JSON_NOVO && selected?.JSON_ANTIGO && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell variant='head'>Field</TableCell>
                      <TableCell>From</TableCell>
                      <TableCell>To</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys({ ...selected?.JSON_NOVO, ...selected?.JSON_ANTIGO }).map((field, index) => {
                      const { from, to } = getComparisonValues(field, selected?.JSON_ANTIGO, selected?.JSON_NOVO);

                      return (
                        <TableRow key={`table-row-${index}`}>
                          <TableCell>{field}</TableCell>
                          <TableCell>{from}</TableCell>
                          <TableCell>{to}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
        </DialogContent>
      </Dialog>

      <Paper
        variant="outlined"
        sx={{
          marginTop: 3,
          marginBottom: 3,
          paddingX: 2,
          paddingY: 3,
          display: "flex",
          justifyContent: "end",
          gap: 2,
        }}
      >
        <Button
          sx={{
            marginRight: "auto",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            color: "currentColor",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              color: "currentColor",
            },
          }}
          variant="contained"
          color="secondary"
          onClick={onReturnToPreviousPage}
        >
          Return
        </Button>
      </Paper>
    </Grid>
  )
}

export default InvoiceLog
