import { api } from "../../lib/api";
import { getToken } from "../../storage/auth";

const entity = "purchaseOrders";

export const findPoItems = async (poId: number, params?: any) => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/${poId}/items`, {
    params,
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const findBuyerGroupByNumeroPO = async (NUMERO_PO: string | number | undefined, ID_CENTRO: number) => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/buyerGroup`, {
    params: { NUMERO_PO, ID_CENTRO },
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};