export async function loginWithToken(token: string): Promise<{
  data: { token: string; name: string; email: string };
}> {
  return {
    data: {
      name: "kristian",
      email: "kristian@teces.com",
      token,
    },
  };
}

export function urlLoginEmbraer(): string {
  return `${process.env.REACT_APP_EMBRAER_URL}/embraer-oauth2/v1/authorize?client_id=${process.env.REACT_APP_AUTH_ID}&scope=${process.env.REACT_APP_AUTH_SCOPE}&redirect_uri=${process.env.REACT_APP_AUTH_CALLBACK_URL}&response_type=token`
}