import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { Center } from "../../types/center";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
}

const entity = "logInterface";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string,
  isCombo?: boolean
):
  | Promise<{
      data: Center[];
      meta: List;
      error: Error;
      isLoading: boolean;
    }>
  | any => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Center[]>([]);
  const [meta, setMeta] = useState<List>({} as List);
  const [error] = useState<Error>({} as Error);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const response = await dataTableUtils.findPaginate(params);
      const {total, data, currentPage, totalPages, limit} = response.data;
      setData(data);
      setMeta((prevState) => ({
        ...prevState,
        total,
        totalPages,
        limit,
        currentPage,
      }));
      setIsLoading(false)
    })();
  }, [params, token]);

  return {
    data,
    meta,
    error,
    isLoading,
  };
};

export const findCenterCombo = async () => {
  const token = getToken();
  const response =  await api.get(`/centers/getCentersWithCompaniesCombo`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return response.data
}

export const findInterfaceCombo = async () => {
  const token = getToken();
  const response =  await api.get(`/${entity}/getInterfaceName`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return response.data
}

export const findLogDetails = async (id: number) => {
  const token = getToken();
  const response =  await api.get(`/${entity}/findLogDetails/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return response.data
}
