import {
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useList } from "../../../services/report";
import BasicFormReport from "./components/Form/BasicFormReport";
import DataTable from "./components/DataTable";
import CustomReport from "./customReports/customReport";
import { ComboBoxType } from "../../../types/general";
import FormOperationalDiscreppancy from "./components/Form/FormOperacionalDiscrepancy";
import FormGeneral from "./components/Form/FormGeneral";
import FormInvoiceMigo from "./components/Form/FormInvoiceMigo";

export interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
  ID_RELATORIO_CUSTOM?: number;
}

interface ReportsProps {
  ID_RELATORIO: number;
  title: string;
}

export default function Reports({
  ID_RELATORIO,
  title,
}: ReportsProps) {
  const [reportSelected, setReportSelected] = useState<ComboBoxType | null>(null);
  const [customReportCombo, setCustomReportCombo] = useState<ComboBoxType[]>([]);
  const [errors, setErrors] = useState<string[]>();
  const [customReport, setCustomReport] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const { data, dataRaw, meta, isLoading, reportStructure, setIsLoading } = useList(params) || {};
  
  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams, limit: params.limit }));
  };

  if(customReport) {
    return <CustomReport
      ID_RELATORIO={ID_RELATORIO}
      setCustomReport={setCustomReport}
      reportSelected={reportSelected}
      setReportSelected={setReportSelected}
      customReportCombo={customReportCombo}
      setCustomReportCombo={setCustomReportCombo}
    /> 
  }

  return (
    <Grid marginTop={4}>
      <Grid container spacing={1} direction="column">
        <Grid item xs={10}>
          <Typography variant="h5">{ title }</Typography>
        </Grid>
      </Grid>
      {ID_RELATORIO === 2 ?
          <FormOperationalDiscreppancy
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            ID_RELATORIO={ID_RELATORIO}
            setParams={setParams}
            errors={errors}
            setCustomReport={setCustomReport}
            reportSelected={reportSelected}
            setReportSelected={setReportSelected}
            customReportCombo={customReportCombo}
            setCustomReportCombo={setCustomReportCombo}
          />
        : ID_RELATORIO === 8 ?
          <FormInvoiceMigo
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            ID_RELATORIO={ID_RELATORIO}
            setParams={setParams}
            errors={errors}
            setCustomReport={setCustomReport}
            reportSelected={reportSelected}
            setReportSelected={setReportSelected}
            customReportCombo={customReportCombo}
            setCustomReportCombo={setCustomReportCombo}
          />
        : ID_RELATORIO === 11 ?
          <FormGeneral
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            ID_RELATORIO={ID_RELATORIO}
            setParams={setParams}
            errors={errors}
            setCustomReport={setCustomReport}
            reportSelected={reportSelected}
            setReportSelected={setReportSelected}
            customReportCombo={customReportCombo}
            setCustomReportCombo={setCustomReportCombo}
          />
        : <BasicFormReport
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            ID_RELATORIO={ID_RELATORIO}
            setParams={setParams}
            errors={errors}
            setCustomReport={setCustomReport}
            reportSelected={reportSelected}
            setReportSelected={setReportSelected}
            customReportCombo={customReportCombo}
            setCustomReportCombo={setCustomReportCombo}
          />
        }
      
      { isLoading &&
        <Box sx={{
          marginTop: '30px',
          display: 'flex',
          justifyContent: 'space-evenly',
          flexDirection: 'column',
          alignItems: 'center',
          height: '150px',
          }}
        >
          <CircularProgress size={50}/>
          <Typography>
            Loading...
          </Typography>
        </Box>
      }
      { (params.ID_RELATORIO_CUSTOM && !isLoading) &&
        <DataTable
          data={data}
          dataRaw={dataRaw}
          reportStructure={reportStructure}
          rowCount={meta?.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          ID_RELATORIO={ID_RELATORIO}
          paginationModel={paginationModel}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
        />
      }
    </Grid>
  );
}
