import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { BoardingAuthorization } from "../../types/boardingAuthorization";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
}

const entity = "boardingAuthorizations";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string,
  isCombo?: boolean
): {
  data: BoardingAuthorization[];
  meta: List;
  isLoading: boolean;
  combo: any[];
  error: Error;
} => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<BoardingAuthorization[]>([{} as BoardingAuthorization]);
  const [meta, setMeta] = useState<List>({} as List);
  const [error, setError] = useState<Error>({} as Error);
  const [combo, setCombo] = useState<any>();

  const render = () => {
    if (token) {
      if (isCombo === false || isCombo === undefined) {
        return "pagination";
      }
    }
  };
  useEffect(() => {
    if (render() === "pagination") {
      (async function () {
        setIsLoading(true);
        const response = await dataTableUtils.findPaginate(params);
        const {total, data, currentPage, totalPages, limit} = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
        setIsLoading(false)
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, token, isCombo]);

  useEffect(() => {
    if (token && isCombo === true) {
      setIsLoading(true);
      api
        .get(`/${entity}/getCombo`, {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const { data } = response;
          setCombo(data);
        })
        .catch(setError)
        .finally(() => setIsLoading(false));
    }
  }, [isCombo, params, token]);

  return { data, meta, isLoading, combo, error };
};

export const update = async (
  id: number | string,
  currency?: BoardingAuthorization
): Promise<BoardingAuthorization> => {
  const token = await getToken();
  const { data } = await api.patch(`/${entity}/${id}`, currency, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const deleteShipment = async (
  id: number | string
): Promise<BoardingAuthorization> => {
  const token = await getToken();
  const { data } = await api.delete(`/${entity}/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const isInUse = async (id: number | string): Promise<any> => {
  const token = await getToken();
  const { data } = await api.get(`/${entity}/isInUse/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const get = async (id: number): Promise<any> => {
  const token = await getToken();
  const { data } = await api.get(`/${entity}/${id}`,{
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const create = async (currency: any): Promise<any> => {
  const token = await getToken();
  const { data } = await api.post(`/${entity}`, currency, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};