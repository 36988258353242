import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Autocomplete,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { CarrierProps } from "../../../../../types/carrier";

let customRegimeScheme = yup.object({
  SIGLA_TRANSPORTADOR: yup.string().max(6).required(),
  CODIGO_TRANSPORTADOR: yup.string().max(3).notRequired(),
  NOME_TRANSPORTADOR: yup.string().max(40).required(),
  ID_MODAL_TRANSPORTE: yup.string().required(),
  ATIVO: yup.boolean().notRequired(),
});

interface CarrierFormProps {
  carrier: CarrierProps;
  onSubmit: (customRegime: CarrierProps) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly?: boolean;
}

export default function Carrier({
  carrier,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  controlEdit,
  readOnly,
}: CarrierFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(customRegimeScheme),
  });

  const transportationModeData = [
    {
      value: "AIR",
      label: "AIR",
    },
    {
      value: "ROAD",
      label: "ROAD",
    },
    {
      value: "OCEAN",
      label: "OCEAN",
    },
  ];

  useEffect(() => {
    reset(carrier);
  }, [carrier, reset]);

  const handleFormSubmit = (carrier: CarrierProps) => {
    onSubmit(carrier);
  };

  const handleCancel = () => {
    reset({
      ID_MODAL_TRANSPORTE: "",
      NOME_TRANSPORTADOR: "",
      SIGLA_TRANSPORTADOR: "",
    });
    onCancel();
  };

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit as any)}
      data-testid="customRegime-form"
    >
      {errors.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="search"
        container
        spacing={2}
        marginTop={1}
        border={1}
        padding={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >
        {controlEdit.loading ? (
          <>Loading</>
        ) : (
          <Grid
            marginBottom={1}
            container
            justifyContent="flex-end"
            spacing={2}
          >
            <Grid item xs={3}>
              <Controller
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    type="text"
                    label="Carrier Abbreviation"
                    size="small"
                    variant="outlined"
                    defaultValue={value}
                    disabled={controlEdit.isInUse || readOnly}
                    error={!!formErrors.SIGLA_TRANSPORTADOR}
                    InputLabelProps={{ shrink: !!value }}
                    {...register("SIGLA_TRANSPORTADOR")}
                    inputProps={{ maxLength: 6, 'data-testid': 'SIGLA_TRANSPORTADOR-input' }}
                  />
                )}
                name="SIGLA_TRANSPORTADOR"
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    type="text"
                    label="Code"
                    size="small"
                    variant="outlined"
                    defaultValue={value}
                    disabled={controlEdit.isInUse || readOnly}
                    error={!!formErrors.CODIGO_TRANSPORTADOR}
                    InputLabelProps={{ shrink: !!value }}
                    {...register("CODIGO_TRANSPORTADOR")}
                    inputProps={{ maxLength: 3, 'data-testid': 'CODIGO_TRANSPORTADOR-input' }}
                  />
                )}
                name="CODIGO_TRANSPORTADOR"
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    type="text"
                    label="Carrier Name"
                    size="small"
                    variant="outlined"
                    disabled={controlEdit.isInUse || readOnly}
                    defaultValue={value}
                    error={!!formErrors.NOME_TRANSPORTADOR}
                    InputLabelProps={{ shrink: !!value }}
                    {...register("NOME_TRANSPORTADOR")}
                    inputProps={{ maxLength: 40, 'data-testid': 'NOME_TRANSPORTADOR-input' }}
                  />
                )}
                name="NOME_TRANSPORTADOR"
              />
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        )}
        <Grid
          marginBottom={5}
          container
          justifyContent="flex-start"
          spacing={2}
        >
          <Grid item xs={4}>
            <Controller
              defaultValue={carrier.ID_MODAL_TRANSPORTE || undefined}
              control={control}
              render={({ field: { value, ref, onBlur, name, onChange, ...field } }) => (
                <>
                  <Autocomplete
                    options={transportationModeData || []}
                    getOptionLabel={(option) => option.label}
                    disabled={controlEdit.isInUse || readOnly}
                    value={
                      transportationModeData?.findLast(
                        (item: any) => item?.value === value
                      ) || null
                    }
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        {...field}
                        disabled={controlEdit.isInUse || readOnly}
                        label="Transportation Mode"
                        size="small"
                        inputRef={ref}
                        error={!!formErrors.ID_MODAL_TRANSPORTE}
                        variant="outlined"
                        data-testid="ID_MODAL_TRANSPORTE-input"
                      />
                    )}
                    onChange={(_: any, data: any) => {
                      onChange(data?.value || null);
                    }}
                  />
                </>
              )}
              name="ID_MODAL_TRANSPORTE"
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              render={({ field: { value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!value}
                      disabled={readOnly}
                      inputProps={{ "aria-label": "controlled" }}
                      {...register("ATIVO")}
                    />
                  }
                  labelPlacement="start"
                  label="Status"
                />
              )}
              name="ATIVO"
              control={control}
            />
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
        {/* asd */}
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={isLoading}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          {!readOnly ? (
          <Grid marginLeft={2}>
            <Button
              variant="contained"
              disabled={isLoading}
              type="submit"
              data-testid="submit-button"
            >
              Confirm
            </Button>
          </Grid>
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
}
