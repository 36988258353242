import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import DataTableDefault from "../../../../../components/DataTable";
import { AutomaticAuthorizationProps } from "../../../../../types/automaticAuthorization";
import { hasClaim } from "../../../../../storage/userClaims";
import { Chip } from "@mui/material";

interface DataTableProps {
  onEditCenter: (id: number | string) => void;
  onDelete: (id: number) => void;
  onViewCenter: (id: number) => void;
  data: AutomaticAuthorizationProps[];
  [x: string]: any;
}
export default function DataTable({
  onEditCenter,
  onDelete,
  onViewCenter,
  data,
  ...props
}: DataTableProps) {
  const renderMenus = (row: any) => {
    return [
      <GridActionsCellItem
        sx={{
          display:
            hasClaim("Shipment Authorization - Rules - Maintenance") ||
            hasClaim("Shipment Authorization - Rules - Visualization")
              ? "block"
              : "none",
        }}
        key={`view-${row.ID_REGRA_AUTORIZACAO_EMBARQUE}`}
        icon={<VisibilityIcon />}
        label="View"
        onClick={() => onViewCenter(row.ID_REGRA_AUTORIZACAO_EMBARQUE)}
        color="inherit"
        data-testid="view-button"
      />,
      <GridActionsCellItem
        sx={{
          display: hasClaim("Shipment Authorization - Rules - Maintenance")
            ? "block"
            : "none",
        }}
        key={`edit-${row.ID_REGRA_AUTORIZACAO_EMBARQUE}`}
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={() => onEditCenter(row.ID_REGRA_AUTORIZACAO_EMBARQUE)}
        color="inherit"
        data-testid="edit-button"
      />,
      <GridActionsCellItem
        sx={{
          display: hasClaim("Shipment Authorization - Rules - Maintenance")
            ? "block"
            : "none",
        }}
        key={`delete-${row.ID_REGRA_AUTORIZACAO_EMBARQUE}`}
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => onDelete(row.ID_REGRA_AUTORIZACAO_EMBARQUE)}
        color="inherit"
        data-testid="delete-button"
      />,
    ];
  };

  const transportationModeData = [
    {
      value: 377,
      label: "AIR",
    },
    {
      value: 378,
      label: "ROAD",
    },
    {
      value: 379,
      label: "OCEAN",
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "DESCRICAO_REGRA",
      headerName: "Description Rule",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "NOME_CENTRO",
      headerName: "Company - Plant",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `${params.row.GE_SIGLA_EMPRESA} - ${params.row.GC_DSC_SIGLA} - ${params.row.GC_NOME_CENTRO}`;
      },
    },
    {
      field: "GCC_NOME",
      headerName: "Consolidation Center",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "ID_MODAL",
      headerName: "Transportation Mode",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<any>) => {
        return transportationModeData
          .find((transportationMode) => transportationMode.value === params.row.ID_MODAL)?.label;
      },
    },
    {
      field: "GTM_DESCRICAO",
      headerName: "Material Type",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "GOA_DSC_ORGAO_ANUENTE",
      headerName: "Goverment Authority",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "GR_DESCRICAO_REGIME",
      headerName: "Custom Regime",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "GP_DSC_PRIORIDADE",
      headerName: "Service Level",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "GAO_DSC_AEROPORTO",
      headerName: "Port/Origin Airport",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "ATIVO",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<Date>) =>
        params.value ? (
          <Chip label="Active" color="primary" />
        ) : (
          <Chip label="Inactive" variant="outlined" />
        ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }) => renderMenus(row),
    },
  ];

  return (
    <>
      <DataTableDefault
        rowId={data.map((row) => row.ID_REGRA_AUTORIZACAO_EMBARQUE)}
        data={data}
        columns={columns}
        {...props}
        data-testid="centers-data-table"
      />
    </>
  );
}
