import Shipments from ".";
import ShipmentAuthorization from "./shipmentAuthorization";

export const shipmentsRoutes = [
  {
    path: "/shipmentInstructions",
    element: <Shipments />,
  },
  {
    path: "/shipmentAuthorization",
    element: <ShipmentAuthorization />,
  },
  {
    path: "/shipmentAuthorization/:id",
    element: <ShipmentAuthorization />,
  }
];
