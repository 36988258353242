import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { ServiceLevel } from "../../../../../types/serviceLevel";

let serviceLevelScheme = yup.object({
  DSC_PRIORIDADE: yup.string().max(30).required(),
  CODIGO_SAP: yup.string().max(3).required(),
  ATIVO: yup.boolean().notRequired(),
});

interface ServiceLevelFormProps {
  serviceLevel: ServiceLevel;
  onSubmit: (serviceLevel: ServiceLevel) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly?: boolean;
}

export default function ServiceLevelForm({
  serviceLevel,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  controlEdit,
  readOnly,
}: ServiceLevelFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(serviceLevelScheme),
  });

  useEffect(() => {
    reset(serviceLevel);
  }, [serviceLevel, reset]);

  const handleFormSubmit = (newServiceLevels: any) => {
    onSubmit(newServiceLevels);
  };

  const handleCancel = () => {
    reset({ DSC_PRIORIDADE: "", CODIGO_SAP: "" });
    onCancel();
  };
  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="service-level-form"
    >
      {errors.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="search"
        container
        spacing={2}
        marginTop={1}
        border={1}
        padding={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            disabled={controlEdit.isInUse || readOnly}
            size="small"
            {...register("DSC_PRIORIDADE")}
            error={!!formErrors.DSC_PRIORIDADE}
            inputProps={{ maxLength: 30, 'data-testid': 'DSC_PRIORIDADE-input' }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="SAP Code"
            variant="outlined"
            disabled={controlEdit.isInUse || readOnly}
            size="small"
            {...register("CODIGO_SAP")}
            error={!!formErrors.CODIGO_SAP}
            inputProps={{ maxLength: 3, 'data-testid': 'CODIGO_SAP-input' }}
          />
        </Grid>
        <Grid item xs={1}>
          <Controller
            render={({ field: { value } }) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={!!value}
                    disabled={readOnly}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("ATIVO")}
                  />
                }
                labelPlacement="start"
                label="Status"
              />
            )}
            name="ATIVO"
            control={control}
          />
        </Grid>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={isLoading}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          {!readOnly ? (
            <Grid marginLeft={2}>
              <Button
                  variant="contained"
                  disabled={isLoading}
                  type="submit"
                  data-testid="submit-button"
              >
                Confirm
              </Button>
            </Grid>
          ) : null }
        </Grid>
      </Grid>
    </form>
  );
}
