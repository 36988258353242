import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Button, Grid, TextField, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { GridColDef } from "@mui/x-data-grid";
import Add from "@mui/icons-material/Add";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import DataTableDefault from "../../../../../../components/DataTable";
import { addNote, getNotes } from "../../../../../../services/triage";
import { formatDate } from "../../../../../utils/date-formater";
import { isAxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { getUserClaim } from "../../../../../../storage/userClaims";

const triageNotesSchema = yup.object({
  DSC_NOTA: yup.string().required("Note is required"),
});

type Notes = {
  ID_DIVERGENCIA: number;
  ID_TRIAGEM: number;
  DSC_NOTA: string;
  DATA_CAD: string;
  ID_LOGIN_USUARIO: number;
  loginUser: {
    DSC_LOGIN: string;
  }
}

interface DataTableProps {
  [x: string]: any;
}

const DataTableNotes = React.memo(({
  triage,
  ...props
}: DataTableProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    control,
    watch,
  } = useForm({
    defaultValues: {
      DSC_NOTA: ""
    },
    resolver: yupResolver(triageNotesSchema),
  });

  const { id } = useParams<{ id: string }>();
  const userClaims = getUserClaim();
  const [notes, setNotes] = useState<Notes[]>([]);

  useEffect(() => {
    fetchNotes();
  }, []);

  const isEnabled = useMemo(() => {
    return userClaims.some((claim) => ["Triage - Analysis", "Triage - Register", "Triage - Buyer"].includes(claim));
}, [userClaims]);

  const fetchNotes = useCallback(async () => {
    try {
      if (id) {
        const response = await getNotes(+id);
        setNotes(response);
      }
    } catch (error) {
      if (isAxiosError(error)) {
        enqueueSnackbar(`${error?.response?.data?.message}`, { variant: "error" });
      }
    }
  }, [id]);

  const handleAddNote = useCallback(async () => {
    try {
      if (!id) return;
      
      const userInfo = JSON.parse(localStorage.getItem("userInfo") ?? "");

      const body = {
        ID_TRIAGEM: +id,
        DSC_NOTA: watch("DSC_NOTA"),
        ID_WAREHOUSE: triage.ID_WAREHOUSE,
        ID_LOGIN_USUARIO: userInfo.id
      }

      await addNote(body);

      fetchNotes();

      reset();
    } catch (error) {
      if (isAxiosError(error)) {
        enqueueSnackbar(`${error?.response?.data?.message}`, { variant: "error" });
      }
    }
  }, [id, watch, triage.ID_WAREHOUSE, fetchNotes, reset]);

  const alertErros = useMemo(() => {
    const errorMessages = Object.keys(formErrors).map((key: string) => {
      return formErrors[key as keyof typeof formErrors]?.message;
    });

    return [...errorMessages];
  }, [formErrors]);

  const columns: GridColDef[] = [
    {
      field: "DATA_CAD",
      headerName: "DATE",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: ({ row }) => {
        return [formatDate(row.DATA_CAD)]
      }
    },
    {
      field: "DSC_LOGIN",
      headerName: "LOGIN",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: ({ row }) => {
        return [row?.loginUser?.DSC_LOGIN || "-"]
      }
    },
    {
      field: "DSC_NOTA",
      headerName: "NOTES",
      headerClassName: "super-app-theme--header",
      cellClassName: 'wrap-cell-scroll',
      flex: 1
    },
  ];

  return (
    <>
      <Typography marginBottom={2} fontWeight="bold">Notes</Typography>
      <form
        onSubmit={handleSubmit(handleAddNote)}
      >
        {alertErros.map((error) => (
          <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
            {error}
          </Alert>
        ))}

        <Grid marginBottom={3} container spacing={2}>
          <Grid item xs={10}>
            <Controller
              defaultValue=""
              name="DSC_NOTA"
              control={control}
              render={() => (
                <TextField
                  data-testid="description-input"
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  {...register("DSC_NOTA")}
                  error={!!formErrors.DSC_NOTA}
                />
              )
            }
          />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              type="submit"
              disabled={!isEnabled}
            >
              <Add />
            </Button>
          </Grid>
        </Grid>
      </form>

      <MemoizedDataTableDefault
        rowId={notes?.map((note) => note.ID_DIVERGENCIA)}
        data-testid="unit-data-table"
        data={notes}
        columns={columns}
        {...props}
      />
    </>
  );
});


const MemoizedDataTableDefault = React.memo((props: any) => {
  return <DataTableDefault {...props} />
});

export default DataTableNotes;
