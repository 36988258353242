import styled from "@emotion/styled";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';

const AccordionSummary = styled((props: AccordionSummaryProps & { applyOpacity?: boolean }) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
    sx={{
      '& .MuiAccordionSummary-expandIconWrapper svg': {
        fill: '#fff !important',
      },
    }}
  />
))(({ theme, applyOpacity }) => ({
  color: '#fff',
  backgroundColor: applyOpacity ? '#8ba2b5' : '#1976d2',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    // marginLeft: theme?.spacing(1),
  },
}));

export {
  AccordionSummary
}