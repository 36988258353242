import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { Accordion } from "../../../../../components/Accordion/Accordion";
import { AccordionSummary } from "../../../../../components/Accordion/AccordionSummary";
import { AccordionDetails, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { create } from "../../../../../services/warehouse-note";
import { enqueueSnackbar } from "notistack";
import CloseIcon from '@mui/icons-material/Close';
import { WarehouseNote } from "../../../../../types/warehouse-note";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import dayjs from "dayjs";

type WarehouseNoteAccordionProps = Readonly<{
  warehouseNotes?: WarehouseNote[],
  idWarehouse?: number,
  idUser?: number,
  setRefetchWarehouseNotes: React.Dispatch<React.SetStateAction<boolean>>,
  isDisabled: boolean
}>;

type WarehouseNoteDialogProps = {
  open: boolean,
  handleClose: () => void,
  setNoteContent: React.Dispatch<React.SetStateAction<string>>,
  required: boolean,
  setRequired: React.Dispatch<React.SetStateAction<boolean>>,
  handleAddNote: () => void,
}

type WarehouseNoteItemProps = {
  warehouseNote: WarehouseNote
}

export default function WarehouseNoteAccordion({ warehouseNotes, idWarehouse, idUser, setRefetchWarehouseNotes, isDisabled }: WarehouseNoteAccordionProps) {
  const [noteContent, setNoteContent] = useState<string>("");
  const [noteContentRequired, setNoteContentRequired] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isWarehouseNoteDialogOpen, setIsWarehouseNoteDialogOpen] = useState<boolean>(false);
  const [hasData, setHasData] = useState(false);

  const handleOpenWarehouseNoteDialog = () => {
    setIsWarehouseNoteDialogOpen(true);
  }

  const handleCloseWarehouseNoteDialog = () => {
    setIsWarehouseNoteDialogOpen(false);
  }

  const handleAddNewWarehouseNote = async () => {
    if (!idWarehouse) {
      enqueueSnackbar("Error trying to create Warehouse note", {
        variant: "error",
      });
      return;
    }

    if (noteContent === "") {
      setNoteContentRequired(true);
      enqueueSnackbar("Note description is required", {
        variant: "error",
      });
      return;
    }

    try {
      setIsLoading(true);
      if (idUser) {
        const { success } = await create({
          ID_WAREHOUSE: idWarehouse,
          DESCRICAO_NOTA: noteContent,
          ID_LOGIN_USUARIO: idUser,
          DATA_NOTA: new Date(),
        });

        if (success) {
          setIsLoading(false);
          enqueueSnackbar("Warehouse note created successfully", {
            variant: "success",
          });
          handleCloseWarehouseNoteDialog();
          setRefetchWarehouseNotes(true);

          return;
        }
      }

      setIsLoading(false);
      enqueueSnackbar("Error trying to create Warehouse note", {
        variant: "error",
      });
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar("Error trying to create Warehouse note", {
        variant: "error",
      });
    } finally {
      setNoteContent("");
    }
  }

  useEffect(() => {
    setHasData((warehouseNotes?.length ?? 0) > 0);
  }, [warehouseNotes]);

  return (
    <Box paddingX={5}>
      <LoadingOverlay isLoading={isLoading} />
      <Accordion
        sx={{
          '&.MuiAccordion-root': { border: 0 },
        }}
      >
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          applyOpacity={!hasData}
        >
          <Typography>Notes</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ marginTop: 17, padding: 2 }}>
          <Button 
            onClick={handleOpenWarehouseNoteDialog} 
            style={{
              display: isDisabled ? 'none' : 'inline-flex',
              alignItems: 'center',
              width: "13%" 
            }}
            size="large" 
            variant="outlined" 
            disabled={isDisabled}
          >
            New
          </Button>
          <Grid container marginTop={3}>
            <Grid item xs={12} bgcolor="gray" padding={1}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography>Date</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography>Login</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Notes</Typography>
                </Grid>
              </Grid>
            </Grid>
            {warehouseNotes?.map((warehouseNote, index) => (
              <WarehouseNoteItem
                key={index}
                warehouseNote={warehouseNote}
              />
            ))}
          </Grid>
          <WarehouseNoteDialog
            open={isWarehouseNoteDialogOpen}
            setNoteContent={setNoteContent}
            handleClose={handleCloseWarehouseNoteDialog}
            required={noteContentRequired}
            handleAddNote={handleAddNewWarehouseNote}
            setRequired={setNoteContentRequired}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

const WarehouseNoteDialog = ({
  open,
  handleClose,
  setNoteContent,
  required,
  setRequired,
  handleAddNote
}: WarehouseNoteDialogProps) => {
  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <DialogTitle>Warehouse Notes</DialogTitle>
        <CloseIcon onClick={() => handleClose()} sx={{ color: 'black', cursor: "pointer", marginRight: 2 }} />
      </Box>
      <Divider />
      <DialogContent style={{ height: 300, paddingBottom: 50 }}>
        <Typography>Notes</Typography>
        <TextField
          multiline
          style={{ width: "100%", resize: "none" }}
          minRows={8}
          onChange={(event) => {
            setRequired(false);
            setNoteContent(event.target.value)
          }}
          error={required}
        />
      </DialogContent>
      <Divider />
      <DialogActions style={{ justifyContent: "space-between", padding: 21 }}>
        <Button style={{ backgroundColor: "gray", width: "20%" }} variant="contained" onClick={() => handleClose()}>Cancel</Button>
        <Button onClick={handleAddNote} style={{ width: "20%" }} size="medium" variant="contained" type="submit">Add</Button>
      </DialogActions>
    </Dialog>
  )
}

const WarehouseNoteItem = ({ warehouseNote }: WarehouseNoteItemProps) => {
  const warehouseNoteDateFormatted = useMemo(() => {
    return dayjs(warehouseNote.DATA_NOTA).format("MM/DD/YYYY");
  }, [warehouseNote]);

  return (
    <Grid item xs={12} paddingX={2} paddingY={1}>
      <Grid container>
        <Grid item xs={4}>
          <Typography>{warehouseNoteDateFormatted}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{warehouseNote.LOGIN_USUARIO_DESC}</Typography>
        </Grid>
        <Grid item xs={6}>
          {warehouseNote.DESCRICAO_NOTA.split('\n').map(nota => (
            <Typography>{nota}</Typography>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}