export function isValidDate(dateString: string): boolean {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(dateString)
}

export function formatDate(dateString: string): string {
  if (isValidDate(dateString)) {
      const date = new Date(dateString);
      const formattedDate = `${('0' + (date.getMonth() + 1)).slice(-2)}/${('0' + date.getDate()).slice(-2)}/${date.getFullYear()}`;
      return formattedDate;
  } else {
      return dateString;
  }
}

export function formatDatetime(dateString: string): string {
  if (isValidDate(dateString)) {
    const date = new Date(dateString);
    const formattedDate = `${('0' + (date.getMonth() + 1)).slice(-2)}/${('0' + date.getDate()).slice(-2)}/${date.getFullYear()} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
    return formattedDate;
  } else {
    return dateString;
  }
}