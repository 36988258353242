import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { ContainerTypesProps } from "../../../../../types/containerType";

let customRegimeScheme = yup.object({
  CODIGO_EDI: yup.string().max(6).required(),
  DESCRICAO: yup.string().max(40).required(),
  ATIVO: yup.boolean().notRequired(),
});

interface FormProps {
  containerTypes: ContainerTypesProps;
  onSubmit: (customRegime: ContainerTypesProps) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
    readOnly?: boolean;
}

export default function ContainerType({
  containerTypes,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  controlEdit,
  readOnly
}: FormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(customRegimeScheme),
  });

  const [erroMap, setErroMap] = useState<string[]>([]);

  useEffect(() => {
    setErroMap(errors);
  }, [errors]);

  useEffect(() => {
    reset(containerTypes);
  }, [containerTypes, reset]);

  const handleFormSubmit = (containerTypesProps: ContainerTypesProps) => {
    setErroMap([]);
    onSubmit(containerTypesProps);
  };

  const handleCancel = () => {
    setErroMap([]);
    reset({ DESCRICAO: "", CODIGO_EDI: "" });
    onCancel();
  };

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit as any)}
      data-testid="customRegime-form"
    >
      {erroMap.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="search"
        container
        spacing={2}
        marginTop={1}
        border={1}
        padding={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >
        <Grid marginBottom={5} container justifyContent="flex-end" spacing={2}>
          <Grid item xs={4}>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="Description"
                  size="small"
                  variant="outlined"
                  disabled={controlEdit.isInUse || readOnly}
                  defaultValue={value}
                  error={!!formErrors.DESCRICAO}
                  InputLabelProps={{ shrink: !!value }}
                  {...register("DESCRICAO")}
                  inputProps={{ maxLength: 40, 'data-testid': 'DESCRICAO-input' }}
                />
              )}
              name="DESCRICAO"
            />
          </Grid>
          <Grid item xs={5}>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="EDI Code"
                  size="small"
                  variant="outlined"
                  disabled={controlEdit.isInUse || readOnly}
                  defaultValue={value}
                  error={!!formErrors.CODIGO_EDI}
                  InputLabelProps={{ shrink: !!value }}
                  {...register("CODIGO_EDI")}
                  inputProps={{ maxLength: 6, 'data-testid': 'CODIGO_EDI-input' }}
                />
              )}
              name="CODIGO_EDI"
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              render={({ field: { value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!value}
                      disabled={readOnly}
                      inputProps={{ "aria-label": "controlled" }}
                      {...register("ATIVO")}
                    />
                  }
                  labelPlacement="start"
                  label="Status"
                />
              )}
              name="ATIVO"
              control={control}
            />
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={isLoading}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          {!readOnly ? (
          <Grid marginLeft={2}>
            <Button
              variant="contained"
              disabled={isLoading}
              type="submit"
              data-testid="submit-button"
            >
              Confirm
            </Button>
          </Grid>
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
}
