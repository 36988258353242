import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { FreigthForwarderType } from "../../types/freightForwarder";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
}

const entity = "freightForwarders";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string
): { data: FreigthForwarderType[]; meta: List; isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<FreigthForwarderType[]>([
    {} as FreigthForwarderType,
  ]);
  const [meta, setMeta] = useState<List>({} as List);

  useEffect(() => {
    if (token) {
      (async function () {
        setIsLoading(true);
        const response = await dataTableUtils.findPaginate(params);
        const {total, data, currentPage, totalPages, limit} = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
        setIsLoading(false)
      })();
    }
  }, [params, token]);

  return { data, meta, isLoading };
};

export const update = async (
  id: number | string,
  airport?: FreigthForwarderType
): Promise<FreigthForwarderType> => {
  const { data } = await api.patch(`/${entity}/${id}`, airport);

  return data;
};

export const isInUse = async (id: number | string): Promise<any> => {
  const { data } = await api.get(`/${entity}/isInUse/${id}`);

  return data;
};

export const deleteFreigthForwarder = async (
  id: number | string
): Promise<FreigthForwarderType> => {
  const { data } = await api.delete(`/${entity}/${id}`);

  return data;
};

export const create = async (
  airport?: FreigthForwarderType
): Promise<FreigthForwarderType> => {
  const { data } = await api.post(`/${entity}`, airport);

  return data;
};

export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: "Id", value: "ID_AGENTE_CARGA" },
    { label: "Agente name", value: "NOME_AGENTE" },
    { label: "Interface type", value: "TIPO_INTERFACE" },
    { label: "Name archive", value: "NOME_ARQUIVO" },
    { label: "Url interface", value: "URL_INTERFACE" },
    {
      label: "Status",
      value: (row: any) => (row.ATIVO ? "Active" : "Inactive"),
    },
  ]
  await dataTableUtils.exportAll({params, xlsxData, filename: "InterfaceFreigthForwarder"});
};
