import { useCallback, useEffect, useState } from "react";
import { api } from "../../lib/api";
import { List } from "../../types/resquest";
import { ServiceLevel } from "../../types/serviceLevel";
import { getToken } from "../../storage/auth";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";
import { ComboBox } from "../../types/comboBox";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
  status?: boolean;
}

const entity = "serviceLevels";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string,
  isCombo?: boolean
): {
  data: ServiceLevel[];
  meta: List;
  isLoading: boolean;
  combo: any[];
  error: Error;
} => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ServiceLevel[]>([{} as ServiceLevel]);
  const [meta, setMeta] = useState<List>({} as List);
  const [error, setError] = useState<Error>({} as Error);
  const [combo, setCombo] = useState<any>();

  const render = useCallback(() => {
    if (token) {
      if (isCombo === false || isCombo === undefined) {
        return "pagination";
      }
    }
  }, [token, isCombo]);

  useEffect(() => {
    if (render() === "pagination") {
      (async function () {
        setIsLoading(true);
        const response = await dataTableUtils.findPaginate(params);
        const {total, data, currentPage, totalPages, limit} = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
        setIsLoading(false)
      })();
    }
  }, [params, token, isCombo, render]);

  useEffect(() => {
    if (token && isCombo === true) {
      setIsLoading(true);
      api
        .get(`/${entity}/getCombo`, {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const { data } = response;
          setCombo(data);
        })
        .catch(setError)
        .finally(() => setIsLoading(false));
    }
  }, [isCombo, params, token]);

  return { data, meta, isLoading, combo, error };
};

export const update = async (
  id: number | string,
  serviceLevel?: ServiceLevel
): Promise<ServiceLevel> => {
  const { data } = await api.patch(`/${entity}/${id}`, serviceLevel);

  return data;
};
export const deleteServiceLevel = async (
  id: number | string
): Promise<ServiceLevel> => {
  const { data } = await api.delete(`/${entity}/${id}`);

  return data;
};

export const isInUse = async (id: number | string): Promise<any> => {
  const { data } = await api.get(`/${entity}/isInUse/${id}`);

  return data;
};

export const create = async (
  serviceLevel?: ServiceLevel
): Promise<ServiceLevel> => {
  const { data } = await api.post(`/${entity}`, serviceLevel);

  return data;
};

export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: "Id", value: "ID_PRIORIDADE" },
    { label: "Description", value: "DSC_PRIORIDADE" },
    { label: "Código SAP", value: "CODIGO_SAP" },
    {
      label: "Status",
      value: (row: ServiceLevel) => (row.ATIVO ? "Active" : "Inactive"),
    },
  ]

  await dataTableUtils.exportAll({params, xlsxData, filename: "ServiceLevel"});
};

export const getServiceLevels = async (): Promise<ComboBox[]> => {
  const { data } = await api
    .get(`/${entity}/getCombo`);

  return data;
}