import { useDrop } from 'react-dnd';
import { Grid, Paper, Typography } from '@mui/material';
import DraggableItem from './DraggableItem';
import { ConsolidationCenter } from '../../../../../types/partner';

type DropableAreaProps = {
  items: any[];
  total: any[];
  setItems: (data: any) => void;
  dropItems: (data: any) => void;
  consolidationCenters: ConsolidationCenter[];
}

const DroppableArea = ({ items, total, setItems, dropItems, consolidationCenters }: DropableAreaProps) => {
  const [, drop] = useDrop(() => ({
    accept: "ITEM",
    drop: (item: any) => {
      setItems((oldItems: any) => [...oldItems, item]);
      dropItems((oldItems: any) => [...oldItems.filter((drop: any) => drop.id !== item.id)]);
    },
  }));

  return (
    <Paper
      ref={drop}
      elevation={0}
      sx={{
        height: JSON.stringify(items) === JSON.stringify(total) ? 'max-content' : `${((total?.length || 1) * 350)}px`,
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        border: 2,
        borderColor: '#fff',
        borderStyle: 'dashed',
        borderRadius: 1,
        padding: 2,
        margin: 1,
      }}
    >
      <Grid item xs={12}>
        {items.length <= 0 && (
          <Typography variant="h6" color="text.secondary" textAlign="center">
            waiting for selection
          </Typography>
        )}
        {items.map((droppedItem: any) => (
          <DraggableItem 
            key={droppedItem.id} 
            item={droppedItem} 
            consolidationCenters={consolidationCenters} 
          />
        ))}
      </Grid>
    </Paper>
  );
};

export default DroppableArea;
