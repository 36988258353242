import { DatePicker } from "@mui/x-date-pickers";

const DatePickerWrapper: typeof DatePicker = (props) => {
  return (
    <DatePicker
      {...props}
      slotProps={{
        ...props.slotProps,
        textField: {
          ...props.slotProps?.textField,
          onKeyUp(e) {
            console.log(e);
            if (e.key !== 'Tab') return;
            e.preventDefault()
            e.stopPropagation();
            e.target.nextElementSibling?.firstChild.click();
          }
        },
        dialog: {
          disableRestoreFocus: true,
          disableAutoFocus: true,
          disableEnforceFocus: true,
          disablePortal: true,
        },
        popper: {
          disablePortal: true,
        },
      }}
    />
  )
}

export default DatePickerWrapper;