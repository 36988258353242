import {
    Alert,
    Box,
    Button,
    Grid,
    TextField,
    Autocomplete,
    FormControlLabel,
    Checkbox,
    TextFieldProps
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import DatePickerWrapper from "../../../../components/wrappers/DatePickerWrapper";


export default function Search({
   interfaces,

   setParams,
   onSubmit,
   errors = [],
  }) {
  const {
    handleSubmit,
    formState: { errors: formErrors },
    control,
  } = useForm();

  const handleFormSubmit = (data: any) => {
      onSubmit(data);
  };

  return (
      <form
          onSubmit={handleSubmit(handleFormSubmit)}
          data-testid="avanced-search"
      >

        <Grid
            container
            spacing={2}
            marginTop={1}
            marginBottom={5}
            sx={{
                border: '1px solid #ccc',
                borderRadius: '4px'
            }}
        >
            <Grid margin={3} container>
                {errors.length > 0 && (
                    <Grid item marginBottom={2} md={12}>
                        {errors.map((error) => (
                            <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
                                {error}
                            </Alert>
                        ))}
                    </Grid>
                )}

              <Grid item md={3}>
                <Controller
                    name="interfaceName"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            options={interfaces}
                            getOptionLabel={(option) => option || ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Interface Name"
                                    variant="outlined"
                                    size="small"
                                    data-testid="search-input"
                                    fullWidth
                                />
                            )}
                            onChange={(_, newValue) => {
                              field.onChange(newValue);
                            }}
                        />
                    )}
                />
              </Grid>

                <Grid item xs={3}>
                    <Controller
                        name="initialDate"
                        control={control}
                        defaultValue={null}
                        render={({ field: { onChange, ref, value }, ...field }) => (
                            <DatePickerWrapper
                                label="Initial Date"
                                value={value ? dayjs(value) : null}
                                inputRef={ref}
                                format="MM/DD/YYYY"
                                slotProps={{ textField: { size: 'small' } }}
                                closeOnSelect
                                onChange={(date: Date|string|null|undefined) => {
                                    const formattedDate = date && dayjs(date).isValid() ? dayjs(date).format("MM/DD/YYYY") : null;
                                    onChange(formattedDate);
                                    // if (getInvoiceDate) {
                                    //     getInvoiceDate(formattedDate);
                                    // }
                                    // whenBlurTimeout();
                                }}
                                renderInput={(params: TextFieldProps) => (
                                    <TextField
                                        {...params}
                                        {...field}
                                        variant="outlined"
                                        error={!!formErrors.initialDate}
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Controller
                        name="finalDate"
                        control={control}
                        defaultValue={null}
                        render={({ field: { onChange, ref, value }, ...field }) => (
                            <DatePickerWrapper
                                label="Final Date"
                                value={value ? dayjs(value) : null}
                                inputRef={ref}
                                format="MM/DD/YYYY"
                                slotProps={{ textField: { size: 'small' } }}
                                closeOnSelect
                                onChange={(date: Date|string|null|undefined) => {
                                    const formattedDate = date && dayjs(date).isValid() ? dayjs(date).format("MM/DD/YYYY") : null;
                                    onChange(formattedDate);
                                    // if (getInvoiceDate) {
                                    //     getInvoiceDate(formattedDate);
                                    // }
                                    // whenBlurTimeout();
                                }}
                                renderInput={(params: TextFieldProps) => (
                                    <TextField
                                        {...params}
                                        {...field}
                                        variant="outlined"
                                        error={!!formErrors.finalDate}
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>

              <Grid item xs={2}>
            <Button
                variant="outlined"
                type="submit"
                sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
                fullWidth
                startIcon={<SearchIcon />}
                data-testid="search-button"
            >
                Search
            </Button>
          </Grid>
            </Grid>
        </Grid>


        <Grid container spacing={2} marginTop={1} marginBottom={5}>
            <Grid item xs={6} md={3}>
                <TextField
                    data-testid="search-searchGeneral-input"
                    fullWidth
                    type="text"
                    label="Search"
                    size="small"
                    onChange={(e) => {
                        setParams((prevState) => ({
                            ...prevState,
                            search: e.target.value.trim(),
                        }));
                    }}
                />
            </Grid>
        </Grid>
      </form>
  );
}
