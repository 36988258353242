import {
  Autocomplete,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { ComboBoxType } from "../../../../../types/general";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { findCenterCombo, findEmailTypeCombo } from "../../../../../services/logEmail";
import dayjs from "dayjs";

interface SearchLogProps {
  setParams: (params: any) => void;
  handleSearch: (params?: any) => void;
}

let logEmailScheme = yup.object({
  searchGeneral: yup.string().notRequired(),
  centers: yup.array().of(
    yup.number().notRequired()
  ),
  emailTypes: yup.array().of(
    yup.number().notRequired()
  ),
  startDate: yup.string().notRequired(),
  endDate: yup.string().notRequired(),
});

export default function SearchLogEmail({
  setParams,
  handleSearch,
}: SearchLogProps) {

  const [centerCombo, setCenterCombo] = useState<ComboBoxType[]>([]);
  const [emailTypeCombo, setEmailTypeCombo] = useState<ComboBoxType[]>([]);

  const {
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(logEmailScheme),
  });

  const handleFormSearch = (searchParams: any) => {
    setParams((prevState: any) => ({
      ...prevState,
      page: 1,
      searchGeneral: searchParams.searchGeneral,
      centers: searchParams.centers,
      emailTypes: searchParams.emailTypes,
      startDate: searchParams.startDate ? searchParams.startDate.split('T')[0] : undefined,
      endDate: searchParams.endDate ? searchParams.startDate.split('T')[0] : undefined,
    }));
  }

    useEffect(() => {
        const fetchData = async () => {
            const findCenter = await findCenterCombo();
            const findEmailType = await findEmailTypeCombo();

            setCenterCombo(findCenter);
            setEmailTypeCombo(findEmailType);
        };

        fetchData();
    }, []);


    return (
    <form
      onSubmit={handleSubmit(handleFormSearch)}
      style={{ width: "100%" }}
      data-testid="edi-file-form"
    ><Grid container spacing={2} marginTop={4} rowGap={1}>
        <Grid item md={4} xs={6}>
            <TextField
                data-testid="search-searchGeneral-input"
                fullWidth
                type="text"
                label="General Search"
                defaultValue={null}
                size="small"
                onChange={(e) => {
                    setParams((prevState) => ({
                        ...prevState,
                        searchGeneral: e.target.value,
                    }));
                }}
                onKeyDown={(event: React.KeyboardEvent) => {
                    if (event.key === 'Enter') {
                        handleSearch();
                    }
                }}
            />
        </Grid>
        <Grid item md={4} xs={6}>
            <Controller
                control={control}
                render={({ field: { value, ref, onChange, ...field } }) => (
                    <Autocomplete
                        options={centerCombo || []}
                        getOptionLabel={(option: ComboBoxType) => `${option.description}`}
                        multiple
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                {...field}
                                label="Company/Plant"
                                size="small"
                                inputRef={ref}
                                variant="outlined"
                            />
                        )}
                        onChange={(_, data: any) => {
                            const selectedValues = data.map(option => option.id);
                            onChange(selectedValues);
                        }}
                    />
                )}
                name="centers"
            />
        </Grid>
        <Grid item md={4} xs={6}>
            <Controller
                control={control}
                render={({ field: { value, ref, onChange, ...field } }) => (
                    <Autocomplete
                        options={emailTypeCombo || []}
                        getOptionLabel={(option: ComboBoxType) => `${option.description}`}
                        multiple
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                {...field}
                                label="Email Type"
                                size="small"
                                inputRef={ref}
                                variant="outlined"
                            />
                        )}
                        onChange={(_, data: any) => {
                            const selectedValues = data.map(option => option.id);
                            onChange(selectedValues);
                        }}
                    />
                )}
                name="emailTypes"
            />
        </Grid>

        {/* Ajustes para os DatePickers */}
        <Grid item md={3} xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                    name="startDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <DatePicker
                            label="Initial Date"
                            value={value ? dayjs(value) : null} // Convertendo para dayjs
                            onChange={(date) => onChange(date ? dayjs(date).toISOString() : null)}
                            slotProps={{
                                textField: {
                                    variant: "outlined",
                                    fullWidth: true,
                                    size: "small",
                                },
                            }}
                        />
                    )}
                />
            </LocalizationProvider>
        </Grid>

        <Grid item md={3} xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                    name="endDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <DatePicker
                            label="Final Date"
                            value={value ? dayjs(value) : null} // Convertendo para dayjs
                            onChange={(date) => onChange(date ? dayjs(date).toISOString() : null)}
                            slotProps={{
                                textField: {
                                    variant: "outlined",
                                    fullWidth: true,
                                    size: "small",
                                },
                            }}
                        />
                    )}
                />
            </LocalizationProvider>
        </Grid>

        <Grid item md={2} xs={2}>
            <Button
                startIcon={<SearchIcon />}
                variant="outlined"
                data-testid="submit-button"
                type="submit"
            >
                Search
            </Button>
        </Grid>
    </Grid>
    </form>
  );
}
