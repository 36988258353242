import React from 'react'
import MaintenanceDataLabel from '.';

export const maintenanceLabelDataRoutes = [
  {
    path: "/labelData",
    element: <MaintenanceDataLabel />,
  },
  {
    path: "/labelData/:labelId",
    element: <MaintenanceDataLabel />,
  },
];