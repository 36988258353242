import { api } from "../../lib/api";
import { getToken } from "../../storage/auth";

interface IList {
  ID_TRIAGEM?: number;
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
  status?: boolean;
}

function getApiData(url: string, params?: IList) {
  const token = getToken();

  return api.get(url, {
    params,
    headers: {authorization: `Bearer ${token}`},
  }).then((response) => response.data);
}


export const getBuyerGroupCombo = async (params?: IList) => {
  const response = await getApiData("/buyerGroups/combo", params);

  return response;
}