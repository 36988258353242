import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { ConsolidationCenter } from "../../types/consolidationCenter";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
}

const entity = "consolidationCenters";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string,
  isCombo?: boolean
):
  | Promise<{ data: ConsolidationCenter[]; meta: List; isLoading: boolean }>
  | any => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ConsolidationCenter[]>([
    {} as ConsolidationCenter,
  ]);
  const [meta, setMeta] = useState<List>({} as List);
  const [error, setError] = useState<Error>({} as Error);
  const [combo, setCombo] = useState<any>();

  const render = () => {
    if (token) {
      if (isCombo === false || isCombo === undefined) {
        return "pagination";
      }
    }
  };

  useEffect(() => {
    if (render() === "pagination") {
      (async function () {
        setIsLoading(true);
        const response = await dataTableUtils.findPaginate(params);
        const {total, data, currentPage, totalPages, limit} = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
        setIsLoading(false)
      })();
    }
  }, [isCombo, params, token]);

  useEffect(() => {
    if (token && isCombo === true) {
      setIsLoading(true);
      api
        .get(`/${entity}/getCombo`, {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const { data } = response;
          setCombo(data);
        })
        .catch(setError)
        .finally(() => setIsLoading(false));
    }
  }, [isCombo, params, token]);

  return { data, meta, isLoading, error, combo };
};

export const update = async (
  id: number | string,
  airport?: ConsolidationCenter
): Promise<ConsolidationCenter> => {
  const token = getToken();
  const { data } = await api.patch(`/${entity}/${id}`, airport, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const deleteConsolidationCenter = async (
  id: number | string
): Promise<ConsolidationCenter> => {
  const token = getToken();
  const { data } = await api.delete(`/${entity}/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const isInUse = async (id: number | string): Promise<any> => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/isInUse/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const listFreightForward = async (): Promise<any> => {
  const { data } = await api.get(`/${entity}/listFreightForward`);

  return data;
};

export const create = async (
  airport?: ConsolidationCenter
): Promise<ConsolidationCenter> => {
  const token = getToken();
  const { data } = await api.post(`/${entity}`, airport, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: "Id", value: "ID_CENTRO_CONSOLIDADOR" },
    { label: "Description", value: "NOME" },
    { label: "Edi code", value: "CODIGO_EDI" },
    { label: "Country abbreviation", value: "country_SIGLA_PAIS" },
    { label: "Country description", value: "country_NOME_PAIS" },
    { label: "Airport abbreviation", value: "airport_SIGLA_AEROPORTO" },
    { label: "Airport description", value: "airport_DSC_AEROPORTO" },
    {
      label: "Status",
      value: (row: any) => (row.ATIVO ? "Active" : "Inactive"),
    },
  ]
  await dataTableUtils.exportAll({params, xlsxData, filename: "ConsolidationCenter"});
};
