import { Dialog, DialogTitle } from "@mui/material";
import WarehouseDetails from "./WarehouseDetails";
import CloseIcon from '@mui/icons-material/Close';

type WarehouseOldDialogProps = {
  open: boolean,
  handleClose: () => void,
  warehouseNumber: string,
  warehouseFields: {title?: string, content?: string}[]
  wrStatus?: string;
}

export default function WarehouseOldDialog({ open, handleClose, warehouseNumber, warehouseFields, wrStatus }: WarehouseOldDialogProps) {
  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <DialogTitle display="flex" alignItems="center" style={{padding: 10}}>
        <CloseIcon
          onClick={() => handleClose()}
          style={{cursor: "pointer"}}
        />
      </DialogTitle>
      <WarehouseDetails warehouseNumber={warehouseNumber} warehouseStatus={wrStatus} warehouseFields={warehouseFields} warehouseDialogOld={true} />
    </Dialog>
  )
}