import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Chip } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import DataTableDefault from "../../../../../components/DataTable";
import { hasClaim } from "../../../../../storage/userClaims";
import { PackageTypesProps } from "../../../../../types/packageType";

interface DataTableProps {
  onEdit: (id: number | string) => void;
  onDelete: (id: number | string) => void;
  onView: (id: number | string) => void;
  data: PackageTypesProps[];
  [x: string]: any;
}
export default function DataTable({
  onEdit,
  onDelete,
  onView,
  data,
  ...props
}: DataTableProps) {
  const renderMenus = (row: any) => {
    return [
      <GridActionsCellItem
        sx={{
          display:
            hasClaim("Package Type - Maintenance") ||
            hasClaim("Package Type - Visualization")
              ? "block"
              : "none",
        }}
        key={`view-${row.ID_TIPO_PACKAGE}`}
        icon={<VisibilityIcon />}
        label="View"
        onClick={() => onView(row.ID_TIPO_PACKAGE)}
        color="inherit"
        data-testid="view-button"
      />,
      <GridActionsCellItem
        sx={{
          display: hasClaim("Package Type - Maintenance") ? "block" : "none",
        }}
        key={`edit-${row.ID_TIPO_PACKAGE}`}
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={() => onEdit(row.ID_TIPO_PACKAGE)}
        color="inherit"
        data-testid="edit-button"
      />,
      <GridActionsCellItem
        sx={{
          display: hasClaim("Package Type - Maintenance") ? "block" : "none",
        }}
        key={`delete-${row.ID_TIPO_PACKAGE}`}
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => onDelete(row.ID_TIPO_PACKAGE)}
        color="inherit"
        data-testid="delete-button"
      />,
    ];
  };

  const columns: GridColDef[] = [
    {
      field: "DSC_TIPO_PACKAGE",
      headerName: "Description",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 300,
    },
    // {
    //   field: "CODIGO_SAP_EDI",
    //   headerName: "EDI CODE",
    //   headerClassName: "super-app-theme--header",
    //   flex: 1,
    //   minWidth: 200,
    // },
    {
      field: "ATIVO",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Date>) =>
        params.value ? (
          <Chip label="Active" color="primary" />
        ) : (
          <Chip label="Inactive" variant="outlined" />
        ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }) => renderMenus(row),
    },
  ];
  return (
    <>
      <DataTableDefault
        rowId={data.map((row) => row.ID_TIPO_PACKAGE)}
        data-testid="custom-regime-data-table"
        data={data}
        columns={columns}
        {...props}
      />
    </>
  );
}
