import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Chip } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import DataTableDefault from "../../../../../components/DataTable";
import { AirportService } from "../../../../../types/airport";
import { hasClaim } from "../../../../../storage/userClaims";

interface DataTableProps {
  onEditAirport: (id: number | string) => void;
  onDeleteAirport: (id: number | string) => void;
  onViewAirport: (id: number | string) => void;
  data: AirportService[];
  loading: boolean;
  [x: string]: any;
}
export default function DataTable({
  onEditAirport,
  onDeleteAirport,
  onViewAirport,
  data,
  loading,
  ...props
}: DataTableProps) {
  const renderMenus = (row: any) => {
    return [
      <GridActionsCellItem
        sx={{
          display:
            hasClaim("Airport - Maintenance") ||
            hasClaim("Airport - Visualization")
              ? "block"
              : "none",
        }}
        key={`view-${row.ID_AEROPORTO}`}
        icon={<VisibilityIcon />}
        label="View"
        onClick={() => onViewAirport(row.ID_AEROPORTO)}
        color="inherit"
        data-testid="view-button"
      />,
      <GridActionsCellItem
        sx={{ display: hasClaim("Airport - Maintenance") ? "block" : "none" }}
        key={`edit-${row.ID_AEROPORTO}`}
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={() => onEditAirport(row.ID_AEROPORTO)}
        color="inherit"
        data-testid="edit-button"
      />,
      <GridActionsCellItem
        sx={{ display: hasClaim("Airport - Maintenance") ? "block" : "none" }}
        key={`delete-${row.ID_AEROPORTO}`}
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => onDeleteAirport(row.ID_AEROPORTO)}
        color="inherit"
        data-testid="delete-button"
      />,
    ];
  };

  const columns: GridColDef[] = [
    {
      field: "SIGLA_AEROPORTO",
      headerName: "Airport",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `${params.row.SIGLA_AEROPORTO} - ${params.row.DSC_AEROPORTO}`;
      },
    },
    {
      field: "ATIVO",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Date>) =>
        params.value ? (
          <Chip label="Active" color="primary" />
        ) : (
          <Chip label="Inactive" variant="outlined" />
        ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }) => renderMenus(row),
    },
  ];
  return (
    <>
      <DataTableDefault
        rowId={data?.map((row) => row.ID_AEROPORTO)}
        data-testid="airport-data-table"
        data={data}
        columns={columns}
        loading={loading}
        {...props}
      />
    </>
  );
}
