import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CheckIcon from '@mui/icons-material/Check';
import { Typography, Button, Switch, styled } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { green, red } from "@mui/material/colors";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";

import DataTableDefault from "../../../../../components/DataTable";
import { DiscrepancyProps } from "../../../../../types/discrepancy";
import { hasClaim } from "../../../../../storage/userClaims";
import { InvoiceDiscrepancy } from "../../../../../types/invoiceDiscrepancy";

interface DataTableProps {
  onCloseInvoiceDiscrepancy: (id: number) => void;
  onDelete: (id: number) => void;
  data: DiscrepancyProps[] | InvoiceDiscrepancy[];
  isDisabled: boolean;
  [x: string]: any;
}
export default function DataTable({
  onCloseInvoiceDiscrepancy,
  onDelete,
  onView,
  data = [],
  isDisabled,
  ...props
}: DataTableProps) {

  const formatDate = (dateString: string) => {
    if (!dateString) return ''

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const renderMenus = (row: any) => {
    if (row.discrepancy.DIVERG_AUTO || row.DATA_FECHAMENTO) {
      return [];
    }

    return [
      <GridActionsCellItem
        sx={{
          display: hasClaim("Discrepancy - Maintenance") ? "block" : "none",
          color: red[500],
        }}
        key={`delete-${row.ID_INVOICE_DIVERGENCIA}`}
        icon={<DeleteIcon />}
        label="Delete"
        data-testid="delete-button"
        onClick={() => onDelete(row.ID_INVOICE_DIVERGENCIA)}
        disabled={isDisabled}
      />,
    ];
  };

  const renderMenusSwitch = (row: any) => {
    if (row.DATA_FECHAMENTO) {
      const formattedDate = formatDate(row.DATA_FECHAMENTO);
      return [
        <span
            key={`close-${row.ID_INVOICE_DIVERGENCIA}`}
        >{formattedDate}</span>
      ];
    }

    if (row.discrepancy.DIVERG_AUTO) {
      return []; 
    }

    return [
      <Switch
        key={`close-${row.ID_INVOICE_DIVERGENCIA}`}
        data-testid="close-button"
        onClick={() => onCloseInvoiceDiscrepancy(row.ID_INVOICE_DIVERGENCIA)}
        disabled={isDisabled}
      />
    ];
  };

  const renderInformation = (row: any) => {
    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} placement="top" />
    ))(() => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        border: "1px solid #dadde9",
      },
    }));
    return [
      <HtmlTooltip
        key={`info-${row.ID_INVOICE_DIVERGENCIA}`}
        title={<Typography color="inherit">{row.DSC_OBSERVACAO}</Typography>}
      >
        <Button>
          <ArticleOutlinedIcon color="action" />
        </Button>
      </HtmlTooltip>,
    ];
  };

  const columns: GridColDef[] = [
    {
      field: "DSC_DIVERGENCIA",
      headerName: "Discrepancy",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 200,
      sortComparator: (v1, v2, row1, row2) => {
        const disc1 = JSON.parse(String(row1.rowNode.id) ?? "");
        const disc2 = JSON.parse(String(row2.rowNode.id) ?? "");
   
        return disc1?.discrepancy?.DSC_DIVERGENCIA?.localeCompare(disc2.discrepancy.DSC_DIVERGENCIA);
      },
      renderCell: ({ row }) => {
        return [row?.discrepancy?.DSC_DIVERGENCIA]
      }
    },
    {
      field: "NUM_LIN_PO",
      headerName: "Po Line",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "DSC_Auto",
      headerName: "Auto",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: ({ row }) => {
        return row?.discrepancy?.DIVERG_AUTO ? [
          <GridActionsCellItem
            sx={{
              color: green[500],
            }}
            key={`auto-${row.ID_INVOICE_DIVERGENCIA}`}
            icon={<CheckIcon />}
            label="Auto"
            data-testid="auto-button"
          />,
        ] : [
          <span
            key={`auto-${row.ID_INVOICE_DIVERGENCIA}`}
          >-</span>
        ]}
    },
    {
      field: "DATA_ABERTURA",
      headerName: "Open",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueFormatter: (params) => {
        return formatDate(params.value);
      },
    },
    {
      field: "ID_LOGIN_USUARIO_ABERTURA",
      headerName: "Login",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: ({ row }) => {
        return [row?.openUserLogin?.DSC_LOGIN || "-"]
      }
    },
    {
      field: "DSC_OBSERVACAO",
      headerName: "Notes",
      width: 5,
      sortable: false,
      headerClassName: "super-app-theme--header",
      flex: 1,
      type: "actions",
      getActions: ({ row }) => renderInformation(row),
    },
    {
      field: "NUMERO_PO",
      headerName: "PO",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: ({ row }) => {
        return [row?.po?.NUMERO_PO]
      }
    },
    {
      field: "DATA_FECHAMENTO",
      type: "actions",
      headerName: "Closed",
      sortable: true,
      headerClassName: "super-app-theme--header",
      flex: 1,
      getActions: ({ row }) => renderMenusSwitch(row),
    },
    {
      field: "ID_LOGIN_USUARIO_FECHAMENTO",
      headerName: "Login",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: ({ row }) => {
        return [row?.closedUserLogin?.DSC_LOGIN || "-"]
      }
    },
    {
      field: "DSC_Actions",
      type: "actions",
      headerName: "",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 30,
      getActions: ({ row }) => renderMenus(row),
    },
  ];

  return (
    <>
      <DataTableDefault
        rowId={data?.map((row) => row.ID_DIVERGENCIA)}
        data-testid="unit-data-table"
        data={data}
        columns={columns}
        getRowHeight={() => 60}
        {...props}
      />
    </>
  );
}

