import { Box, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";

interface GoodsTriageHeaderProps {
  triage: any;
}

const GoodsTriageHeader = ({ triage }: GoodsTriageHeaderProps) => {
  return (
    <Box>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid>
          <Grid
            style={{ marginBottom: 0 }}
            container
            alignItems="center"
            marginY={2}
            spacing={2}
          >
            <Typography
              style={{
                margin: "0 4px 0 10px",
              }}
              sx={{ fontWeight: "bold" }}
              variant="h6"
            >
              {triage?.DSC_WAREHOUSE}
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>|</Typography>
            <Typography
              style={{
                margin: "0 4px 0 4px",
              }}
            >
              {triage?.ID_TIPO_INVOICE}
            </Typography>
          </Grid>
          <Typography style={{ margin: 0 }}>{triage?.DSC_CARRIER} - {triage?.DATA_RECEB && dayjs(triage?.DATA_RECEB).format('MM/DD/YYYY HH:mm')}</Typography>
        </Grid>
        <Grid>
          <Typography sx={{ fontWeight: "bold" }} variant="h6">
            {triage?.ID_STATUS_TRIAGEM}
          </Typography>
          <Typography variant="caption">STATUS</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GoodsTriageHeader;
