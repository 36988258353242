import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Chip } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import DataTableDefault from "../../../../../components/DataTable";
import { Country } from "../../../../../types/country";
import { hasClaim } from "../../../../../storage/userClaims";

interface DataTableProps {
  onEditCountry: (id: number | string) => void;
  onDeleteCountry: (id: number | string) => void;
  onViewCountry: (id: number | string) => void;
  data: Country[];
  [x: string]: any;
}
export default function DataTable({
  onEditCountry,
  onDeleteCountry,
  onViewCountry,
  data,
  userValidation,
  ...props
}: DataTableProps) {
  const renderMenus = (row: any) => {
    return [
      <GridActionsCellItem
        sx={{
          display:
            hasClaim("Country - Maintenance") ||
            hasClaim("Country - Visualization")
              ? "block"
              : "none",
        }}
        key={`view-${row.ID_PAIS}`}
        icon={<VisibilityIcon />}
        label="View"
        onClick={() => onViewCountry(row.ID_PAIS)}
        color="inherit"
        data-testid="view-button"
      />,
      <GridActionsCellItem
        sx={{ display: hasClaim("Country - Maintenance") ? "block" : "none" }}
        key={`edit-${row.ID_PAIS}`}
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={() => onEditCountry(row.ID_PAIS)}
        color="inherit"
        data-testid="edit-button"
      />,
      <GridActionsCellItem
        sx={{ display: hasClaim("Country - Maintenance") ? "block" : "none" }}
        key={`delete-${row.ID_PAIS}`}
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => onDeleteCountry(row.ID_PAIS)}
        color="inherit"
        data-testid="delete-button"
      />,
    ];
  };

  const columns: GridColDef[] = [
    {
      field: "country_NOME_PAIS",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 300,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `${params.row.SIGLA_PAIS} - ${params.row.NOME_PAIS}`;
      },
    },
    {
      field: "ATIVO",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Date>) =>
        params.value ? (
          <Chip label="Active" color="primary" />
        ) : (
          <Chip label="Inactive" variant="outlined" />
        ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }) => renderMenus(row),
    },
  ];
  return (
    <>
      <DataTableDefault
        rowId={data.map((row) => row.ID_PAIS)}
        data-testid="countries-data-table"
        data={data}
        columns={columns}
        {...props}
      />
    </>
  );
}
