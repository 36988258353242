import { Box, Button, Table, TableBody, TableCell, TableRow, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import { isEmailValid } from "../../../../utils/strings";
import {enqueueSnackbar} from "notistack";

const emailType = 'Email CC'

export default function EmailCopyForm({
  readOnly,
  setValue,
  watch,
  register,
  control,
  formErrors,
  selectedCenter,
  editEmailMode,
  setEditEmailMode,
}) {
  const [activeEmail, setActiveEmail] = useState('');
  const [oldEmail, setOldEmail] = useState('');
  const [key, setKey] = useState('');
  const [allowFillEmail, setAllowFillEmail] = useState(false);

  function getEmails() {
    let emailsOld = watch(key);
    if(emailsOld === undefined) {
      emailsOld = [];
    }
    return emailsOld;
  }

  function deleteOnList(email: string) {
    email = email.toLowerCase();
    setEditEmailMode(false);
    const EmailsOld = getEmails();
    const emailFiltered = EmailsOld.filter((EmailOld: string) => EmailOld.DSC_EMAIL !== email);
    setValue(key, emailFiltered);
  }

  function editOnList(email: string) {
    email = email.toLowerCase();
    setActiveEmail(email);
    setOldEmail(email);
    setEditEmailMode(true);
  }

  function cancelEditEmail() {
    setActiveEmail('');
    setEditEmailMode(false);
  }

  function confirmEditEmail() {
    if(oldEmail === activeEmail) {
      enqueueSnackbar("You must to change email or cancel!", { variant: "error" });
      return;
    }
    if(!isEmailValid(activeEmail) || activeEmail === '') {
      enqueueSnackbar("Email invalid!", { variant: "error" });
      return;
    }
    const emailsOld = getEmails();
    const alreadyAdd = emailsOld.find((email) => email.DSC_EMAIL === activeEmail);
    if(alreadyAdd) {
      enqueueSnackbar("Email already registered!", { variant: "error" });
      return;
    }

    deleteOnList(oldEmail);
    addItem();
  }

  function addItem() {
    if(!isEmailValid(activeEmail) || activeEmail === '') {
      enqueueSnackbar("Email invalid!", { variant: "error" });
      return;
    }
    const emailsOld = getEmails();
    const alreadyAdd = emailsOld.find((email) => email.DSC_EMAIL === activeEmail);
    if(alreadyAdd) {
      enqueueSnackbar("Email already registered!", { variant: "error" });
      return;
    }

    emailsOld.push({
      DSC_EMAIL: activeEmail,
      ID_TIPO_DESTINO_EMAIL: emailType,
    });
    setValue(key, emailsOld);
    setActiveEmail('');
  }

  useEffect(() => {
    const partnerCenters = watch('partner_centers');
    if (selectedCenter && partnerCenters) {
      let indexKey = '';
      for (let key = 0; partnerCenters.length > key; key++) {
        if (partnerCenters[key].ID_CENTRO === selectedCenter.id) {
          indexKey = key;
        }
      }
      if (indexKey !== '') {
        setAllowFillEmail(true)
      } else {
        setAllowFillEmail(false)
      }
      setKey(`partner_centers[${indexKey}].emails`);
    }
  }, [selectedCenter, watch]);

  if(!allowFillEmail) {
    return <></>
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <TextField
          fullWidth
          label="Email CC"
          variant="outlined"
          size="small"
          inputProps={{
            maxLength: 50,
            "data-testid": "email-cc-input"
        }}
          onChange={(event) => setActiveEmail(event.target.value.toLowerCase())}
          value={activeEmail}
          disabled={readOnly}
        />
        {!editEmailMode ? (
            <Button
                sx={{ marginLeft: '16px', marginRight: '4px' }}
                variant="outlined"
                onClick={() => addItem()}
                disabled={readOnly}
                data-testid="add-button"
            >
              <AddIcon fontSize="small" />
            </Button>
        ) : (
          <>
            <Button
              sx={{ marginLeft: '16px', marginRight: '4px' }}
              variant="outlined"
              onClick={() => cancelEditEmail()}
              disabled={readOnly}
              color="error"
              data-testid="cancel-button"
            >
              <CloseIcon fontSize="small" />
            </Button>

            <Button
              sx={{ marginLeft: '16px', marginRight: '4px' }}
              variant="outlined"
              onClick={() => confirmEditEmail()}
              disabled={readOnly}
              data-testid="confirm-button"
            >
              <CheckIcon fontSize="small" />
            </Button>
          </>
        )}

      </Box>
      <Table size="small">
        <TableBody>
          {watch(key)?.filter((email) => email.ID_TIPO_DESTINO_EMAIL === emailType).map((email) => (
            <TableRow
              key={email.DSC_EMAIL}
              sx={{ "&:last-child td, &:last-child th": { border: 0 }}}
            >
              <TableCell component="th" scope="row">
                {email.DSC_EMAIL}
              </TableCell>
              <TableCell sx={{ width: '80px' }} align="right">
                <Button
                  sx={{ minWidth: 0 }}
                  color="primary"
                  onClick={() => editOnList(email.DSC_EMAIL)}
                  disableRipple={true}
                  disabled={readOnly}
                >
                  <EditIcon />
                </Button>
                <Button
                  sx={{ minWidth: 0 }}
                  color="error"
                  disabled={readOnly}
                  onClick={() => deleteOnList(email.DSC_EMAIL)}
                >
                  <DeleteIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}