import { Download } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { useList as useAirportList } from "../../../services/airport";
import { create, deleteConsolidationCenter, exportAll, update, useList, isInUse } from "../../../services/consolidationCenter";
import { useList as useCountriesList } from "../../../services/countries";
import { getLocal, getToken } from "../../../storage/auth";
import { ConsolidationCenter } from "../../../types/consolidationCenter";
import AvancedSearch from "./components/AvancedSearch";
import ConsolidationCenterForm from "./components/consolidationCenterForm";
import DataTable from "./components/DataTable";
import { hasClaim } from "../../../storage/userClaims";
import useForms from "../../utils/forms/useForm.hook";

interface IParams {
  page?: number;
  keyword: string;
  sort: string;
  limit?: number;
  uri: string;
  param: string;
}

const formatData = (data: ConsolidationCenter) => {
    return {
        ...data,
      ID_PAIS: data.country_ID_PAIS,
      ID_AEROPORTO: data.airport_ID_AEROPORTO,
    };
}

export default function ConsolidationCenters() {

  const [token, setToken] = useState<string>();
  const [userValidation, setUserValidation] = useState<string>();
  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [errorsSearch] = useState<string[]>();
  const { data, meta, isLoading } = useList(params, token);
  const { combo: comboAirport } = useAirportList(undefined, token, true);
  const { combo: comboCountries } = useCountriesList(undefined, true);

  const hasDefaultData = data.find((item: any) => item.FLAG_INTERCOMPANY === true);

  const {
    selectedData,
    controlEdit,
    isSaving,
    modalOpen,
    setModalOpen,
    openDeleteModal,
    setOpenDeleteModal,
    errors,
    readOnly,
    paginationModel,
    setPaginationModel,

    handleRequest,
    handleEditData,
    handleDeleteDataConfirmation,
    handleDeleteData,
    handleFormSubmit,
    exportData,
    handleCancelEditData,
    onViewData,
    onNew,
  } = useForms({
    actions: {
      create,
      deleteData: deleteConsolidationCenter,
      exportAll,
      update,
      isInUse
    },
    configs:{
      findKey: "ID_CENTRO_CONSOLIDADOR",
      entityName: "Consolidation Center",
    },

    data,
    params,
    setParams
  });

  // todo: refactor this function to hook
  const avancedSearch = async (search: ConsolidationCenter) => {
    const dtoSearch = {
      ...(search.searchGeneral !== "" ? { searchGeneral: search.searchGeneral } : { searchGeneral: undefined }),
      ...(search.NOME !== "" ? { NOME: search.NOME } : { NOME: undefined }),
      ID_PAIS: search.ID_PAIS,
      ID_AEROPORTO: search.ID_AEROPORTO,
      ...(search.CODIGO_EDI !== "" ? { CODIGO_EDI: search.CODIGO_EDI } : { CODIGO_EDI: undefined }),
      ATIVO: search.ATIVO,
    };
    setParams((prevState) => ({ ...prevState, ...dtoSearch, page: 1 }));
  };

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = (getLocal("userInfo")) as any;
    setToken(getTokens);
    setUserValidation(JSON.parse(getUser));
  };

  useEffect(() => {
    getTokenSession();
  }, []);

  return (
    <Grid marginTop={4}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={8}>
          <Typography variant="h5">Consolidation Center</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" marginTop={1}>
        <Grid item xs={2}>
          {!modalOpen ? (
            <Grid item xs={2}>
              <Button
                sx={{
                  display: hasClaim("Consolidation Center - Maintenance")
                    ? "block"
                    : "none",
                }}
                variant="contained"
                onClick={() => onNew()}
                fullWidth
              >
                New
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {modalOpen ? (
        <Grid container alignItems="center">
          <ConsolidationCenterForm
            onSubmit={handleFormSubmit}
            consolidationCenter={selectedData}
            airport={comboAirport}
            countries={comboCountries}
            hasDefaultData={hasDefaultData}
            onCancel={handleCancelEditData}
            isLoading={isSaving}
            errors={errors}
            controlEdit={controlEdit}
            readOnly={readOnly}
          />
        </Grid>
      ) : null}
      <AvancedSearch
        onSearch={avancedSearch}
        airport={comboAirport}
        countries={comboCountries}
        isLoading={isSaving}
        errors={errorsSearch}
      />
      <Grid container marginTop={4} justifyContent="flex-end">
        <Button
          variant="text"
          onClick={exportData}
          startIcon={<Download />}
        >
          Export XLSX
        </Button>
      </Grid>
      <Grid>
        <DataTable
          loading={isLoading}
          data={data}
          hasDefaultData={hasDefaultData}
          userValidation={userValidation}
          airPorts={[]}
          countries={[]}
          onDeleteConsolidationCenter={handleDeleteDataConfirmation}
          onEditConsolidationCenter={(data: ConsolidationCenter) => handleEditData(+data, formatData) }
          onViewConsolidationCenter={(data: ConsolidationCenter) =>  onViewData(+data, formatData) }
          rowCount={meta.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          paginationModel={paginationModel}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete Consolidation Center
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete this Consolidation Center?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button onClick={handleDeleteData}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
