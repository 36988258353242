import { GridColDef } from "@mui/x-data-grid";
import DataTableDefault from "../../../../../components/DataTable";
import { InvoiceNote } from "../../../../../types/invoiceNote";
import Tooltip from "@mui/material/Tooltip";

interface DataTableProps {
  data: InvoiceNote[]
}
export default function DataTable({
  data = [],
  ...props
}: DataTableProps) {
  const formatDate = (dateString: string) => {
    if (!dateString) return ''

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };


  const getRowHeight = (item: { model: { ID_INVOICE_NOTA: number } } ) => {
    const row = data.find((row) => row.ID_INVOICE_NOTA === item.model?.ID_INVOICE_NOTA);
    const lineCount = row?.DSC_NOTA.split('\n').length || 0;
    return lineCount > 1 ? 75 + (lineCount - 1) * 20 : 75;
  };

  const columns: GridColDef[] = [
    {
      field: 'DATA_NOTA',
      headerName: 'Date',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      valueFormatter: (params) => {
        return formatDate(params.value)
      },
    },
    {
      field: 'ID_USUARIO',
      headerName: 'Login',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      renderCell: ({ row }) => {
        return [row.loginUsuario?.DSC_LOGIN]
      }
    },
    {
      field: 'DSC_NOTA',
      headerName: 'Notes',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      resizable: true,
      renderCell: ({ row }) => {
        const notes = row.DSC_NOTA?.split('\n');
        return [
          <Tooltip title={row.DSC_NOTA} followCursor>
            <div 
              style={{ display: 'flex', flexDirection: 'column', maxHeight: 80, overflow: 'hidden' }}
              onCopy={() => window.navigator?.clipboard.writeText(row.DSC_NOTA)}
            >
              {notes.map((note, index) => (<div key={index}>{note}</div>))}
            </div>
          </Tooltip>
        ]
      }
    },
  ]

  return (
    <>
      <DataTableDefault
        rowId={data?.map((row) => row.ID_INVOICE_NOTA)}
        data-testid='unit-data-table'
        data={data}
        columns={columns}
        getRowHeight={getRowHeight}
        {...props}
      />
    </>
  )
}
