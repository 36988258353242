import { yupResolver } from "@hookform/resolvers/yup";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  Alert,
  Button,
  Grid,
} from "@mui/material";
import { ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import * as yup from "yup";
import InputUploadWithFileText from "../../../../../components/Input/InputUploadWithFileText";
import DownloadIcon from '@mui/icons-material/Download';

let buyerGroupSupScheme = yup.object({
  file: yup.mixed()
});

interface BuyerGroupFormProps {
  onSubmit: (file: { file: File}) => void;
  isLoading?: boolean;
  errors?: string[];
}

export default function BuyerGroupForm({
  onSubmit,
  isLoading = false,
  errors = [],
}: BuyerGroupFormProps) {
  const {
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(buyerGroupSupScheme),
  });

  const handleFormSubmit = (file: { file: File}) => {
    if(Object.keys(file).length === 0) {
      enqueueSnackbar("You must select one file to upload", {
        variant: "error",
      });
      return;
    }
    onSubmit(file);
  };

  const uploadFile = (event: ChangeEvent<HTMLInputElement> | any) => {
    if(event.target instanceof HTMLInputElement || event.customEvent) {
      const files = event.target.files;
      if(files) {
        const fileToAdd = files[0];
        setValue('file', fileToAdd);
      }
    }
  }

  return (
    <form
      style={{ width: "100%", marginTop: '20px' }}
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="buyer-group-form"
    >
      {errors.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }} data-testid="error-alert">
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="search"
        container
        marginTop={1}
        border={1}
        padding={2}
        spacing={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >
        <Grid container spacing={2} marginBottom={2} marginTop={2} marginLeft={2}>
          <Grid item lg={5} md={7} xs={12} alignContent='center'>
            <InputUploadWithFileText
              onInput={uploadFile}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </Grid>
          <Grid item md={4} xs={4}>
            <Button
              data-testid="submit-button-form"
              variant="contained"
              disabled={isLoading}
              type="submit"
              startIcon={<CloudUploadIcon />}
              color='primary'
            >
              Upload
            </Button>
          </Grid>
        </Grid>
        <Grid container sx={{ display: 'flex', justifyContent: 'end' }} marginRight={2}>
          <a href='/examples/CPRDSUP-example.xlsx' download>
            <Button variant="outlined" endIcon={<DownloadIcon />}>
              Download Example
            </Button>
          </a>
        </Grid>
      </Grid>
    </form>
  );
}
