import { AccordionDetails, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Paper, TextField, Typography } from "@mui/material";
import { Accordion } from "../../../components/Accordion/Accordion";
import { AccordionSummary } from "../../../components/Accordion/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { ProcessNotesType } from "../../../types/process";
import { fetchNotesProcess, save } from "../../../services/process-notes";
import { formatDate } from "../../utils/date";
import { enqueueSnackbar } from "notistack";

type ProcessNotesGridItemProps = {
  date: string,
  login: string,
  note: string,
}

type ProcessNotesProps =  {
  idProcess: number
}

const ProcessNotes: React.FC<ProcessNotesProps> = ({ idProcess }) => {
  const [alertModal, setAlertModal] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [newNote, setNewNote] = useState<Partial<ProcessNotesType>>({})
  const [notes, setNotes] = useState<ProcessNotesGridItemProps[]>();

  const fetchData = async () => {
    try {
      const data = await fetchNotesProcess(idProcess);
      setNotes(data);
    } catch (error) {
      enqueueSnackbar('Error fetching notes', { variant: "error" });
      console.error('Error fetching notes:', error);
    }
  };

  const handleSave = async () => {
    try {
      await save({ ...newNote, ID_PROCESSO: idProcess });
      setOpenModal(false);
      setAlertModal(false);
      setNewNote({});
      await fetchData();
    } catch (error) {
      enqueueSnackbar('Error save note', { variant: "error" });
      console.error('Error save note:', error);
    }
  }

  useEffect(() => {
    fetchData()
  }, []);

  const onCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <>
      <Grid marginTop={4} alignItems='center'>
        <Dialog
        open={alertModal}
        onClose={() => setAlertModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Process Notes</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {newNote ? 'Are you sure that you want to save this house info?': 'This field is required to save.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {newNote ? <>
            <Button onClick={() => setAlertModal(false)}>Cancel</Button>
            <Button onClick={handleSave}>Confirm</Button>
          </> : <Button onClick={() => setAlertModal(false)}>Back</Button>}
        </DialogActions>
      </Dialog>
      <Dialog open={openModal} onClose={onCloseModal} 
        aria-labelledby="alert-dialog-title" 
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth= 'md' 
        >
        <DialogTitle id="form-dialog-title" style={{padding: 7}}>
          <Grid container>
              <Grid style={{paddingLeft: 10}} sm={10}>Process Notes</Grid>
              <Grid sm={2} container justifyContent="right">
              <IconButton
                  edge="start"
                  color="inherit"
                  onClick={onCloseModal}
                  aria-label="close"
              >
                  <GridCloseIcon />
              </IconButton>
              </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers={true} 
            style={{textAlign: 'center'}}>
          <Typography style={{textAlign: 'left'}}>Notes</Typography>
          <TextField
            autoFocus
            margin="dense"
            id="invoice-tracking-number"
            type="text"
            style={{width:'100%', marginTop:0}}
            value={newNote.DESCRICAO_NOTA}
            onChange={(e) => setNewNote({...newNote, DESCRICAO_NOTA: e.target.value})}
            multiline
            fullWidth
            InputProps={{ style: { height: '90%' } }}
            minRows={8}
          />
        </DialogContent>
        <DialogActions style={{padding: 20}}>
          <Button sx={{
              marginRight: "auto",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              color: "currentColor",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                color: "currentColor",
              },
              minWidth:100
            }} variant="contained" onClick={onCloseModal} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => setAlertModal(true)} color="primary" variant="contained"
            sx={{
              minWidth:100
            }}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
    <Accordion
      sx={{
        '&.MuiAccordion-root': {
          border: 0,
          marginBottom: 3,
          marginTop: 7
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography>Notes</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ marginTop: 10 }}>
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          style={{ maxWidth: "10%" }}
          onClick={() => setOpenModal(true)}
        >
          New
        </Button>
        <Grid container marginTop={2}>
          <Grid item xs={12} bgcolor="gray" padding={1}>
            <Grid container>
              <Grid item xs={2}>
                <Typography>Date</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Login</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>Notes</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {notes?.map((note: any) => 
          <ProcessNotesGridItem 
            date={formatDate(note.DATA_NOTA)} 
            login={note.loginUsuarioProcess?.DSC_LOGIN} 
            note={note.DESCRICAO_NOTA}
          />
        )}
        
      </AccordionDetails>
    </Accordion>
    </>
  )
}

const ProcessNotesGridItem = ({ date, login, note }: ProcessNotesGridItemProps) => {
  return (
    <Grid item xs={12} padding={1}>
      <Grid container>
        <Grid item xs={2}>
          <Typography>{date}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{login}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{note}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ProcessNotes