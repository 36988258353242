import { Box, Grid, Typography } from "@mui/material";

interface InvoiceHeaderProps {
  invoice: any;
}

const InvoiceHeader = ({ invoice }: InvoiceHeaderProps) => {
  return (
    <Box>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid>
          <Grid
            style={{ marginBottom: 0 }}
            container
            alignItems="center"
            marginY={2}
            spacing={2}
          >
            <Typography
              style={{
                margin: "0 4px 0 10px",
              }}
              sx={{ fontWeight: "bold" }}
              variant="h6"
            >
              {invoice?.NUM_INVOICE}
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>|</Typography>
            <Typography
              style={{
                margin: "0 4px 0 4px",
              }}
            >
              {invoice?.ID_TIPO_INVOICE}
            </Typography>
            <Typography>| ID: {invoice?.ID_INVOICE}</Typography>
          </Grid>
          <Typography style={{ margin: 0 }}>Invoice</Typography>
        </Grid>
        <Grid>
          <Typography sx={{ fontWeight: "bold" }} variant="h6">
            {invoice?.ID_STATUS_INVOICE}
          </Typography>
          <Typography variant="caption">STATUS</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvoiceHeader;
