export enum TriageStatus {
  WAITING_ATTACHMENTS = "Waiting Attachments",
  UNDER_ANALYSIS = "Under Analysis",
  MISSING_INVOICE = "Missing Invoice",
  PENDING = "Pending",
  WAITING_TO_MOVE_TO_RECEIVING = "Waiting to Move to Receiving",
  CANCELED = "Canceled",
  FINALIZED = "Finalized",
  UNDER_LOGISTICS_ANALYSIS = "Under Logistics Analysis",
}

export enum InvoiceType {
  INBOUND_INTERNATIONAL = "Inbound - International",
  INBOUND_DOMESTIC = "Inbound - Domestic",
  TRIAGE_DOMESTIC = "Triage - Domestic",
  TRIAGE_INTERNATIONAL = "Triage - International"
}

export type Triage = {
  ID_TRIAGEM: number;
  ID_WAREHOUSE: number;
  DSC_WAREHOUSE: string;
  DSC_CARRIER?: string;
  QTDE_VOL_TOTAL: number;
  DATA_RECEB: Date;
  ID_CENTRO: number;
  ID_TIPO_INVOICE: InvoiceType;
  ID_STATUS_TRIAGEM: TriageStatus;
  CODIGO_GRUPO_COMPRADOR?: string;
  ID_GRUPO_COMPRADOR?: number;
  NUM_PO?: number;
  DATA_WAITING_ATTACH?: Date;
  DATA_UNDER_ANALYSIS?: Date;
  DATA_MISSING?: Date;
  DATA_PENDING?: Date;
  DATA_LOGISTICS?: Date;
  DATA_RECEIVING?: Date;
  DATA_ESTN_UNDER_ANALYSIS?: Date;
  DATA_ESTN_UNDER_MISSING?: Date;
  DATA_ESTN_UNDER_PENDING?: Date;
  DATA_ESTN_UNDER_LOGISTICS?: Date;
  DATA_ESTN_UNDER_RECEIVING?: Date;
  triageInvoices: any[];
}

export const initialTriage: Triage = {
  ID_TRIAGEM: 0,
  ID_WAREHOUSE: 0,
  DSC_WAREHOUSE: "",
  QTDE_VOL_TOTAL: 0,
  DATA_RECEB: new Date(),
  ID_CENTRO: 0,
  ID_TIPO_INVOICE: InvoiceType.INBOUND_DOMESTIC,
  ID_STATUS_TRIAGEM: TriageStatus.WAITING_ATTACHMENTS,
  triageInvoices: []
}
