/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import { PartnerService } from "../../types/partner";
import { IFindInvoice, Invoice } from "../../types/invoice";
import { enqueueSnackbar } from "notistack";

const entity = "invoices";

export const useList = (
    id: number,
    token?: string
):
    | Promise<{ data: PartnerService[]; meta: List; isLoading: boolean }>
    | any => {
  const [isLoading, setIsLoading] = useState(true);
  const [invoice, setInvoice] = useState();

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      api
          .get(`${entity}/${id}`, {
            headers: { authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const { data } = response;
            setInvoice(data);
            setIsLoading(false);
          })
          .catch((error) => {
            enqueueSnackbar("Could not load invoice", { variant: "error" });
          });
    }
  }, [token]);

  return {
    isLoading,
    invoice,
  };
};

export const update = async (
    id: number | string,
    invoice?: PartnerService
): Promise<PartnerService> => {
  const token = getToken();
  const { data } = await api.patch(`/${entity}/${id}`, invoice, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};
export const getInvoice = async (
    invoiceId: number,
): Promise<Invoice> => {
  const token = getToken();
  const { data } = await api
      .get(`${entity}/${invoiceId}`, {
        headers: { authorization: `Bearer ${token}` },
      })
  return data
};

export const create = async (
    invoice: Partial<Invoice>,
    save: boolean
): Promise<Invoice> => {
  const token = getToken();
  const { data } = await api.post(`/${entity}/create`, invoice, {
    params: {
      save: save,
    },
    headers: { authorization: `Bearer ${token}` },
  });
  return data;
};

export const searchInvoice = async (findParams?: {
  NUMERO_PO?: string;
  NUM_INVOICE: string;
  DATA_INVOICE: string;
}): Promise<IFindInvoice> => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/find`, {
    headers: { authorization: `Bearer ${token}` },
    params: findParams,
  });
  return data;
};

export const remove = async (
    ID_INVOICE: number,
    reason: string,
    forceWarehouse?: boolean,
): Promise<Invoice> => {
  const token = getToken();
  const { data } = await api.delete(`/${entity}/${ID_INVOICE}`, {
    data: {
      reason,
      forceWarehouse,
    },
    headers: { authorization: `Bearer ${token}` },
  });
  return data;
};

export const validateInvoiceById = async (id: number, changeServiceLevel?: null|'yes'|'no'): Promise<any> => {
  const token = getToken();
  const { data } = await api.post(`/${entity}/${id}/validate`, {}, {
    headers: { authorization: `Bearer ${token}` },
    params: {
      changeServiceLevel,
    },
  });
  return data;
}
