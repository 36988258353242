import { Country } from "./country";

export type PartnerPaginate = {
  GC_ID_CENTRO_CONSOLIDADOR: number;
  GC_NOME: string;
  GC_ID_PAIS: number;
  GC_ID_AEROPORTO: number;
  GC_CODIGO_EDI: string;
  GC_ATIVO: boolean;
  GP_ID_PAIS: number;
  GP_SIGLA_PAIS: string;
  GP_NOME_PAIS: string;
  GP_ATIVO: boolean;
  GA_ID_AGENTE_CARGA: number;
  GA_NOME_AGENTE: string;
  GA_TIPO_INTERFACE: string;
  GA_ATIVO: boolean;
  ID_PARCEIRO: number;
  NOME_PARCEIRO: string;
  ID_TIPO_PARCEIRO: number;
  ID_CENTRO_CONSOLIDADOR: number;
}

export type PartnerService = {
  ID_PARCEIRO: number;
  NOME_PARCEIRO: string;
  NUM_MFIR: string;
  ID_FORNECEDOR?: number;
  ID_TIPO_PARCEIRO: number;
  ID_PAIS: number;
  ID_CENTRO_CONSOLIDADOR: number;
  ID_AGENTE_CARGA: number;
  CODIGO_EDI: string;
  supplier?: Supplier;
  partner_centers: PartnerCenterService[]
  consolidation_center?: ConsolidationCenter;
}

export type ConsolidationCenter = {
  ID_PAIS: number;
  NOME: string;
}

export type Supplier = {
  ID_FORNECEDOR: number;
  CODIGO_MFIR: string;
  NOME_FORN: string;
  DSC_ENDERECO1: string;
  DSC_ENDERECO2: string;
  DSC_CIDADE: string;
  DSC_ESTADO: string;
  ID_PAIS: number;
  DSC_CEP: number
  DSC_DOCUMENTO: string;
  pais?: Country;
}

export type PartnerCenterService = {
  ID_PARCEIRO_CENTRO?: number;
  ID_PARCEIRO?: number;
  ID_CENTRO: number;
  ATIVO: boolean;
  emails: PartnerCenterEmailService[];
}

export type PartnerCenterEmailService = {
  ID_PARCEIRO_CENTRO_EMAIL?: number;
  DSC_EMAIL: string;
  ID_PARCEIRO_CENTRO?: number;
  ID_TIPO_DESTINO_EMAIL?: string;
}

export type SelectedCenterType = {
  id: number;
  description: string;
}

export type CenterType = {
  ID_CENTRO: number;
  ATIVO: boolean;
}

export enum PartnerType {
  FREIGHT_FORWARDER = "FREIGHT FORWARDER",
  CARRIER = "CARRIER",
  BROKER = "BROKER"
}

export const PartnerTypeIds: { [key in PartnerType]: number } = {
  [PartnerType.FREIGHT_FORWARDER]: 750,
  [PartnerType.CARRIER]: 751,
  [PartnerType.BROKER]: 752
};
