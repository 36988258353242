import React from 'react';
import { Card } from '@mui/material';
import { useDrag } from 'react-dnd';
import InfoCard from './InfoCard';

function DraggableItem({ item, consolidationCenters }: any) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "ITEM",
    item,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <Card 
      sx={{ 
        margin: 'auto', 
        mb: 5,
        opacity: isDragging ? 0.5 : 1,
        cursor: 'pointer',
      }} 
      ref={drag}
    >
      <InfoCard 
        data={item} 
        consolidationCenters={consolidationCenters} 
      />
    </Card>
  );
}

export default DraggableItem;
