import { api } from "../../lib/api";
import {useEffect, useState} from "react";

const entity = "integrationReprocessing";

export const useList = (
    params,
): { data: []; isLoading: boolean } => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<[]>([]);
    useEffect(() => {
        (async function () {
            setIsLoading(true);
            try {
                const response = await api.get(`/${entity}/list`, { params });

                setData(response.data);
            } catch (error) {
                console.log(error)
            } finally {
                setIsLoading(false);
            }
        })();
    }, [params]);
    return { data, isLoading };
};

export const getInterfaces = async () => {
    const { data } = await api.get(`/${entity}/interfaces`);

    return data;
};