import VisibilityIcon from "@mui/icons-material/Visibility";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import DataTableDefault from "../../../../../../components/DataTable";
import dayjs from "dayjs";
import { Button, Checkbox } from "@mui/material";
import { InvoiceHistoryService } from "../../../../../../types/invoiceHistory";

interface DataTableProps {
  onView: (id: InvoiceHistoryService) => void;
  data: any[];
  loading: boolean;
  logins: any[],
  [x: string]: any;
}
export default function DataTable({
  onView,
  data,
  logins,
  loading,
  ...props
}: DataTableProps) {
  const columns: GridColDef[] = [
    {
      field: "DATA_OCORRENCIA",
      headerName: "Datetime",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Date>) => (
        dayjs(params.value).format("MM/DD/YYYY HH:mm:ss")
      )
    },
    {
      field: "ID_TIPO_ACAO",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "ID_LOGIN_USUARIO",
      headerName: "Login",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        const userId = params?.value?.toString();
        const user = logins?.find((item: any) => item?.id?.toString() === userId);
        return user?.description || '--';
      }
    },
    {
      field: "ID_TIPO_HISTORICO_INVOICE",
      headerName: "History Type",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Changes",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      renderCell: ({ row }) => (
        <Button
          onClick={() => onView(row)}
          style={{ border: '1px solid', borderRadius: '5px', paddingTop: 0, paddingBottom: 0 }}
          color="inherit"
          data-testid="view-button"
        >
          <VisibilityIcon style={{ fontSize: '11px' }} /> <span style={{ fontSize: '11px' }}>VIEW</span>
        </Button>
      )
    },
    {
      field: "LEGADO",
      headerName: "Legacy",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Boolean>) => (
        params.value && <Checkbox
          checked={params.value}
          disabled
          style={{ color: '#00bcd4' }}
        />
      )
    },
  ];
  return (
    <>
      <DataTableDefault
        rowId={data?.map((row) => row.ID_INVOICE_HISTORICO)}
        data-testid="invoice-history-data-table"
        data={data}
        columns={columns}
        loading={loading}
        {...props}
      />
    </>
  );
}
