import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { CustomChannelProps } from "../../../../../types/customChannel";

let serviceLevelScheme = yup.object({
  DESCRICAO: yup.string().max(30).required(),
  CODIGO_EDI: yup.string().max(3).required(),
  ATIVO: yup.boolean().notRequired(),
});

interface CustomChannelFormProps {
  serviceLevel: CustomChannelProps;
  onSubmit: (serviceLevel: CustomChannelProps) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly?: boolean;
}

export default function CustomChannelForm({
  serviceLevel,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  controlEdit,
  readOnly
}: CustomChannelFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(serviceLevelScheme),
  });
  const [erroMap, setErroMap] = useState<string[]>([]);

  useEffect(() => {
    setErroMap(errors);
  }, [errors]);

  useEffect(() => {
    reset(serviceLevel);
  }, [serviceLevel, reset]);

  const handleFormSubmit = (newServiceLevels: any) => {
    setErroMap([]);
    onSubmit(newServiceLevels);
  };

  const handleCancel = () => {
    setErroMap([]);
    reset({ DESCRICAO: "", CODIGO_EDI: "" });
    onCancel();
  };
  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="service-level-form"
    >
      {erroMap.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="search"
        container
        spacing={2}
        marginTop={1}
        marginBottom={2}
        border={1}
        padding={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >
        <Grid item xs={5}>
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            disabled={controlEdit.isInUse || readOnly}
            size="small"
            {...register("DESCRICAO")}
            error={!!formErrors.DESCRICAO}
            inputProps={{ maxLength: 30, 'data-testid': 'DESCRICAO-input' }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="EDI Code"
            disabled={controlEdit.isInUse || readOnly}
            variant="outlined"
            size="small"
            {...register("CODIGO_EDI")}
            error={!!formErrors.CODIGO_EDI}
            inputProps={{ maxLength: 3, 'data-testid': 'CODIGO_EDI-input' }}
          />
        </Grid>
        <Grid item xs={1}>
          <Controller
            render={({ field: { value } }) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={!!value}
                    disabled={readOnly}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("ATIVO")}
                  />
                }
                labelPlacement="start"
                label="Status"
              />
            )}
            name="ATIVO"
            control={control}
          />
        </Grid>
        <Grid marginTop={4} container justifyContent="flex-end" spacing={2}>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={isLoading}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          {!readOnly ? (
          <Grid marginLeft={2}>
            <Button
              variant="contained"
              disabled={isLoading}
              type="submit"
              data-testid="submit-button"
            >
              Confirm
            </Button>
          </Grid>
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
}
