import { useRef, useState } from 'react';
import { useSetStateWrapper } from './wrappers';

export const useReferredState = <T>(initialData: T) => {
  const [value, setValue] = useState(initialData);
  const ref = useRef(initialData);
  const setState = useSetStateWrapper(
    setValue,
    (newValue) => (ref.current = newValue),
  );
  return [value, setState, ref] as const;
};
