import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { airportRoutes } from "./private/airports/routes";
import { centerRoutes } from "./private/centers/routes";
import { consolidationCenterRoutes } from "./private/consolidationCenter/routes";
import { countryRoutes } from "./private/countries/routes";
import { currencyRoutes } from "./private/currencies/routes";
import { customRegimeRoutes } from "./private/customRegime/routes";
import { freigthForwarderRoutes } from "./private/freigthForwarder/routes";
import { incotermRoutes } from "./private/Incoterms/routes";
import { incotermsComplementRoutes } from "./private/incotermsComplement/routes";
import PrivateLayout from "./private/layout";
import { pickupZoneRoutes } from "./private/pickupZone/routes";
import { unitMeasurementRoutes } from "./private/unitMeasurement/routes";
import { serviceLevelRoutes } from "./private/serviceLevel/routes";
import SignIn from "./public/signin";
import User from "./public/user";
import { accessControlRoutes } from "./private/access-control/routes";
import { materialTypesRoutes } from "./private/materialType/routes";
import LoginTemp from "./public/logintemp";
import { quarantineRoutes } from "./private/quarantine/routes";
import { discrepancytRoutes } from "./private/discrepancy/routes";
import { shipmentsRoutes } from "./private/shipment/routes";
import { cargoTreatment } from "./private/cargoTreatment/routes";
import { containerType } from "./private/containerType/routes";
import { packageType } from "./private/packageType/routes";
import { locationBinRoutes } from "./private/locationbin/routes";
import { CarrierRoutes } from "./private/carrier/routes";
import { customChannelRoutes } from "./private/customChannel/routes";
import { partnerRoutes } from "./private/partner/routes";
import { slaRoutes } from "./private/sla/routes";
import { automaticAuthorizationRoutes } from "./private/automaticAuthorizationRule/routes";
import { automaticAuthorizationLogRoutes } from "./private/automaticAuthorizationLog/routes";
import { maintenanceEmailRoutes } from "./private/maintenanceEmail/routes";
import { automaticAuthorizationSchedulerRoutes } from "./private/automaticAuthorizationScheduler/routes";
import { operationalRoutes } from "./private/operational/routes";
import { consultRoutes } from "./private/consult/routes";
import { invoiceRoutes } from "./private/invoice/routes";
import { freightForwarderRules } from "./private/freightForwarderRule/routes";
import { buyerGroupSupRoutes } from "./private/buyerGroupSup/routes";
import Logout from "./public/user/logout";
import { ediFileRoutes } from "./private/ediFile/routes";
import Home from "./private/home";
import { dashboardRoutes } from "./private/dashboard/routes";
import { processRoutes } from "./private/process/routes";
import { goodsTriageRoutes } from "./private/goodsTriage/routes";
import { maintenanceLabelDataRoutes } from "./private/maintenanceLabelData/routes";
import { logInterfaceRoutes } from "./private/logInterface/routes";
import { logEmailRoutes } from "./private/logEmail/routes";
import { reportScheduleRoutes } from "./private/reportSchedule/routes";
import { reportRoutes } from "./private/reports/routes";
import {interfaceReprocessRoutes} from "./private/interfaceReprocess/routes";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      ...quarantineRoutes,
      ...incotermRoutes,
      ...centerRoutes,
      ...countryRoutes,
      ...currencyRoutes,
      ...airportRoutes,
      ...freigthForwarderRoutes,
      ...consolidationCenterRoutes,
      ...incotermsComplementRoutes,
      ...serviceLevelRoutes,
      ...customRegimeRoutes,
      ...pickupZoneRoutes,
      ...unitMeasurementRoutes,
      ...accessControlRoutes,
      ...materialTypesRoutes,
      ...discrepancytRoutes,
      ...shipmentsRoutes,
      ...cargoTreatment,
      ...containerType,
      ...packageType,
      ...locationBinRoutes,
      ...CarrierRoutes,
      ...customChannelRoutes,
      ...partnerRoutes,
      ...automaticAuthorizationRoutes,
      ...automaticAuthorizationLogRoutes,
      ...maintenanceEmailRoutes,
      ...automaticAuthorizationSchedulerRoutes,
      ...operationalRoutes,
      ...invoiceRoutes,
      ...slaRoutes,
      ...freightForwarderRules,
      ...buyerGroupSupRoutes,
      ...consultRoutes,
      ...dashboardRoutes,
      ...ediFileRoutes,
      ...processRoutes,
      ...goodsTriageRoutes,
      ...maintenanceLabelDataRoutes,
      ...logInterfaceRoutes,
      ...logEmailRoutes,
      ...reportScheduleRoutes,
      ...reportRoutes,
      ...interfaceReprocessRoutes,
    ],
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/user/callback",
    element: <User />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/logintemp",
    element: <LoginTemp />,
  },
]);

export default function Router() {
  return <RouterProvider router={router} />;
}
