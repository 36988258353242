import React, { useEffect, useState } from "react";
import { Download } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

import Search from "../../../components/Search";
import { useList } from "../../../services/Incoterms";
import { useListShipping } from "../../../services/incotermsShiping";
import { getLocal, getToken } from "../../../storage/auth";
import { hasClaim } from "../../../storage/userClaims";
import { IncotermShipping } from "../../../types/incotermsShipping";
import DataTable from "./components/DataTable";
import IncotermForm from "./components/IncotermForm";
import useForms from "../../utils/forms/useForm.hook";


import { create, deleteIncoterms, exportAll, update, isInUse } from "../../../services/Incoterms";

export default function Incoterms() {
  const [token, setToken] = useState<string>();
  const [userValidation, setUserValidation] = useState<string>();
  const [params, setParams] = useState({ page: 1, limit: 10 });

  const getTokenSession = () => {
    const getTokens = getToken() as any;
    const getUser = getLocal("userInfo") as any;
    setToken(getTokens);
    setUserValidation(JSON.parse(getUser));
  };

  useEffect(() => { getTokenSession() }, []);

  const { data, meta, isLoading } = useList(params, token);
  const { data: shippings } = useListShipping(undefined, token);

  const {
    selectedData,
    controlEdit,
    isSaving,
    modalOpen,
    setModalOpen,
    openDeleteModal,
    setOpenDeleteModal,
    errors,
    readOnly,
    paginationModel,
    setPaginationModel,

    handleRequest,
    handleEditData,
    handleDeleteDataConfirmation,
    handleDeleteData,
    handleFormSubmit,
    exportData,
    handleCancelEditData,
    onViewData,
    onNew,
  } = useForms({
    actions: {
      create,
      deleteData: deleteIncoterms,
      exportAll,
      update,
      isInUse
    },
    configs:{
      findKey: "ID_INCOTERMS",
      entityName: "Incoterms",
    },

    data,
    params,
    setParams
  });

  return (
      <Grid marginTop={4}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={9}>
            <Typography variant="h5">Incoterms</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" marginTop={1}>
          <Grid item xs={2}>
            {!modalOpen ? (
                <Grid item xs={2}>
                  <Button
                      variant="contained"
                      onClick={() => { onNew() }}
                      fullWidth
                      sx={{ display: hasClaim("Incoterms - Maintenance") ? "block" : "none" }}
                  >
                    New
                  </Button>
                </Grid>
            ) : null}
          </Grid>
        </Grid>
        {modalOpen ? (
            <Grid container alignItems="center">
              <IncotermForm
                  onSubmit={handleFormSubmit}
                  onCancel={handleCancelEditData}
                  incoterm={selectedData}
                  incotermsShipping={shippings || ([] as IncotermShipping[])}
                  controlEdit={controlEdit}
                  isLoading={isSaving}
                  readOnly={readOnly}
                  errors={errors}
              />
            </Grid>
        ) : null}

        <Search isIconterms={shippings} onChange={handleRequest} />
        <Grid container marginTop={4} justifyContent="flex-end">
          <Button
              variant="text"
              onClick={exportData}
              startIcon={<Download />}
              data-testid="export-button"
          >
            Export XLSX
          </Button>
        </Grid>
        <Grid>
          <DataTable
              loading={isLoading}
              data={data}
              userValidation={userValidation}
              onDeleteIncoterm={handleDeleteDataConfirmation}
              onEditIncoterm={handleEditData}
              onViewIncoterm={onViewData}
              rowCount={meta.total || 0}
              onPaginationModelChange={(e: any) => {
                setPaginationModel(e);
                handleRequest({ page: e.page + 1, limit: e.pageSize });
              }}
              paginationModel={paginationModel}
              pagination
              initialState={{
                pagination: { paginationModel: { pageSize: params.limit } },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              paginationMode="server"
          />
        </Grid>
        <Dialog
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            data-testid="delete-dialog"
        >
          <DialogTitle id="alert-dialog-title">Delete Incoterms</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure that you want to delete this Incoterms?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
            <Button onClick={handleDeleteData} data-testid="dialog_confirm">Confirm</Button>
          </DialogActions>
        </Dialog>
      </Grid>
  );
}
