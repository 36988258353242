export enum HistoryTypes {
  TRIAGE = "Triage",
  PIECES = "Pieces",
  ATTACHMENT = "Attachment",
  INVOICE = "Invoice"
}

export type HistoryTypesType = keyof typeof HistoryTypes;

export type TriageHistoryService = {
  ID_TRIAGEM_HISTORICO: number;
  ID_TRIAGEM: number;
  DATA_OCORRENCIA: Date;
  ID_TIPO_ACAO: string;
  JSON_ANTIGO: any;
  JSON_NOVO: any;
  ID_LOGIN_USUARIO: number;
  LOG_TRIAGEM_MIGRADA: string;
  LEGADO: boolean;
  JUSTIFICATIVA: string;
  ID_TIPO_HISTORICO_TRIAGEM: HistoryTypesType;
};
