import { Box, Grid, TextField } from "@mui/material";

interface InvoicePoProps {
  poData: any
}

export default function InvoicePo({ poData }: InvoicePoProps) {
  return (
    <Box>
      <Grid container marginY={2} spacing={2}>

        <Grid item xs={12}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value={`${poData?.SIGLA_EMPRESA ?? ''} - ${poData?.poItems?.[0]?.centro?.DSC_SIGLA ?? ''} - ${poData?.poItems?.[0]?.centro?.NOME_CENTRO ?? ''} - ${poData?.poItems?.[0]?.centro?.DSC_ENDERECO ?? ''} - ${poData?.poItems?.[0]?.centro?.DSC_CIDADE ?? ''} - ${poData?.poItems?.[0]?.centro?.DSC_ESTADO ?? ''} - ${poData?.poItems?.[0]?.centro?.pais?.SIGLA_PAIS ?? ''} - ${poData?.poItems?.[0]?.centro?.pais?.NOME_PAIS ?? ''}`}
            fullWidth
            label="Company - Plant (Ship To)"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value={poData?.SIGLA_INCOTERMS}
            fullWidth
            label="Incoterms"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value={poData?.DSC_INCOTERMS_COMPL}
            fullWidth
            label="Incoterms Complement"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value={poData?.SIGLA_MOEDA_PO}
            fullWidth
            label="Currency"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value={poData?.DSC_CATEGORIA_PGTO}
            fullWidth
            label="Payment"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value={`${poData?.grupoComprador?.CODIGO_GRUPO} - ${poData?.grupoComprador?.NOME_COMPRADOR}`}
            fullWidth
            label="Purchase Group"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>

        <Grid item xs={2}>
          <TextField
            InputLabelProps={{ shrink: true }}
            fullWidth
            label="Extra Expenses - Type"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            InputLabelProps={{ shrink: true }}
            fullWidth
            label="Extra Expenses Description"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            InputLabelProps={{ shrink: true }}
            fullWidth
            label="Extra Expenses Value"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            InputLabelProps={{ shrink: true }}
            fullWidth
            label="Extra Expenses Currency"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>

        <Grid item xs={2}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value={poData?.fornecedor?.CODIGO_MFIR}
            fullWidth
            label="Supplier MFIR"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value={poData?.fornecedor?.NOME_FORN}
            fullWidth
            label="Name"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value={`${poData?.fornecedor?.pais?.SIGLA_PAIS} - ${poData?.fornecedor?.pais?.NOME_PAIS}`}
            fullWidth
            label="Country"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>

        <Grid item xs={7}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value={poData?.fornecedor?.DSC_ENDERECO1}
            fullWidth
            label="Address"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value={poData?.fornecedor?.DSC_CIDADE}
            fullWidth
            label="City"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value={poData?.fornecedor?.DSC_ESTADO}
            fullWidth
            label="State"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>

        <Grid item xs={5}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value={poData?.NOME_PAGADOR}
            fullWidth
            label="Bill To Name"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={7}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value={poData?.DSC_ENDERECO_PAGADOR}
            fullWidth
            label="Address"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>


        <Grid item xs={5}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value={poData?.CIDADE_PAGADOR}
            fullWidth
            label="City"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value={poData?.ESTADO_PAGADOR}
            fullWidth
            label="State"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            InputLabelProps={{ shrink: true }}
            value={poData?.SIGLA_PAIS_PAGADOR}
            fullWidth
            label="Country"
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>
      </Grid>
    </Box>
  )
}
