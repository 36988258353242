import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { isAxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { getLocal, getToken } from "../../../storage/auth";
import ReportScheduleForm from "./components/Form";
import DataTable from "./components/DataTable";
import { hasClaim } from "../../../storage/userClaims";
import SearchAutomaticAuthorizationscheduler from "./components/Search";
import { ReportScheduleItem, ReportScheduleProps } from "../../../types/reportSchedule";
import { create, deleteAuthorizationSchedule, findReport, update, useList } from "../../../services/reportSchedule";
import { ComboBoxType } from "../../../types/general";
import { getCentersWithCompaniesCombo } from "../../../services/centers";

export interface IParams {
  page?: number;
  keyword?: string;
  sort?: string;
  limit?: number;
  uri?: string;
  param?: string;
}

export default function ReportSchedule() {
  const [reportSchedule, setReportSchedule] =
    useState<ReportScheduleProps>({
      AGEND_MONDAY: false,
      AGEND_TUESDAY: false,
      AGEND_WEDNESDAY: false,
      AGEND_THURSDAY: false,
      AGEND_FRIDAY: false,
      AGEND_SATURDAY: false,
      AGEND_SUNDAY: false,
    } as ReportScheduleProps);

  const [reportScheduleId, setReportScheduleId] = useState<string | number>();
  const [reportCombo, setReportCombo] = useState<ComboBoxType[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userValidation, setUserValidation] = useState<string>();
  const [token, setToken] = useState<string>();
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = (getLocal("userInfo")) as any;
    setToken(getTokens);
    if(getUser) {
      setUserValidation(JSON.parse(getUser));
    }
  };

  useEffect(() => {
    const fetchReport = async () => {
      getTokenSession();
      try {
        const response = await findReport();
        setReportCombo(response);
      } catch (error) {
        console.error("Erro ao buscar o relatório", error);
      }
    };

    fetchReport();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [errors, setErrors] = useState<string[]>();
  const { data, meta, isLoading } = useList(params, token);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [dataCenterCombo, setDataCenterCombo] = useState([] as ComboBoxType[]);

  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };

  const handleEdit = async (id: string | number) => {
    setErrors([])
    setReadOnly(false);
    const editReportSchedule = data.filter(
      (item: ReportScheduleItem) =>
        item.ID_AGENDAMENTO_RELATORIO === id
    );
    if (editReportSchedule.length) {
      const data = editReportSchedule[0];
      setReportSchedule({
        ...data,
      });
      setModalOpen(true);
    }
  };

  const handleDeleteConfirmation = (id: string | number) => {
    setReportScheduleId(id);
    setOpenDeleteModal(true);
  };

  const handleDelete = async () => {
    if (reportScheduleId) {
      try {
        setIsSaving(true);
        await deleteAuthorizationSchedule(reportScheduleId);
        enqueueSnackbar(
          "Automatic Scheduler Report deleted successful",
          {
            variant: "success",
          }
        );
        setParams({ ...params });
      } catch (err: any) {
        if (err.response.status) {
          enqueueSnackbar("Automatic Scheduler Report is being used", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(err.response.data.message[0], { variant: "error" });
        }
      }
      setIsSaving(false);
      setOpenDeleteModal(false);
    }
  };

  const handleFormSubmit = async (
    values: ReportScheduleProps
  ) => {
    try {
      setIsSaving(true);
      if (values.ID_AGENDAMENTO_RELATORIO) {
        const result = await update(
          values.ID_AGENDAMENTO_RELATORIO,
          values
        );
        enqueueSnackbar(
          "Automatic Scheduler Report updated successful",
          {
            variant: "success",
          }
        );
        const paramsRequest = {
          ID_CENTRO: result.ID_CENTRO,
          ID_RELATORIO_CUSTOM: result.ID_RELATORIO_CUSTOM,
          ID_RELATORIO: result.ID_RELATORIO,
        };
        setParams((prevState) => ({ ...prevState, ...paramsRequest }));
      } else {
        const result = await create(values);
        enqueueSnackbar(
          "Automatic Authorization Scheduler created successful",
          {
            variant: "success",
          }
        );
        const paramsRequest = {
          ID_CENTRO: result.ID_CENTRO,
          ID_RELATORIO_CUSTOM: result.ID_RELATORIO_CUSTOM,
          ID_RELATORIO: result.ID_RELATORIO,
        };
        setParams((prevState) => ({ ...prevState, ...paramsRequest }));
      }
      setReportSchedule({
        AGEND_MONDAY: false,
        AGEND_TUESDAY: false,
        AGEND_WEDNESDAY: false,
        AGEND_THURSDAY: false,
        AGEND_FRIDAY: false,
        AGEND_SATURDAY: false,
        AGEND_SUNDAY: false,
      } as ReportScheduleProps);
      setModalOpen(false);
      setIsSaving(false);
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setErrors([err.response?.data?.message]);
      }
      setIsSaving(false);
    }
    setIsSaving(false);
  };

  const handleCancelEdit = () => {
    setReportSchedule(
      {} as ReportScheduleProps
    );
    setErrors([]);
    setModalOpen(false);
  };
  const onView = (id: string | number) => {
    setErrors([]);
    setReadOnly(true);

    const edit = data.filter(
      (item: ReportScheduleItem) =>
        item.ID_AGENDAMENTO_RELATORIO === id
    );
    if (edit.length) {
      const data = edit[0];
      setReportSchedule({
        ...data,
      });
    }
    setModalOpen(true);
  };

  useEffect(() => {
    getCentersWithCompaniesCombo({
      withoutBlocked: true,
    }).then((centers) => {
      setDataCenterCombo(centers);
    })
  }, [])

  return (
    <Grid marginTop={4}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h5">
            Automatic Scheduler Report
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" marginTop={1}>
        <Grid item xs={2}>
          {!modalOpen ? (
            <Grid item xs={2}>
              <Button
                sx={{
                  display: hasClaim(
                    "Schedule Reports - Maintenance"
                  )
                    ? "block"
                    : "none",
                }}
                variant="contained"
                onClick={() => {
                  setErrors([])
                  setReadOnly(false);
                  setModalOpen(true);
                }}
                fullWidth
              >
                New
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {modalOpen ? (
        <Grid container alignItems="center">
          <ReportScheduleForm
            centerWithRegime={dataCenterCombo}
            reportCombo={reportCombo}
            onSubmit={handleFormSubmit}
            reportScheduler={reportSchedule}
            onCancel={handleCancelEdit}
            isLoading={isSaving}
            errors={errors}
            readOnly={readOnly}
          />
        </Grid>
      ) : null}
      {!isSaving && <SearchAutomaticAuthorizationscheduler
          dataCenterCombo={dataCenterCombo}
          onChange={handleRequest}
          params={params}
          setParams={setParams}
        />
      }
      <Grid marginTop={2} >
        <DataTable
          loading={isLoading}
          data={data}
          center={dataCenterCombo}
          userValidation={userValidation}
          onDelete={handleDeleteConfirmation}
          onEdit={handleEdit}
          onView={onView}
          rowCount={meta.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          paginationModel={paginationModel}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete Automatic Scheduler Report
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete this Automatic Scheduler Report?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button onClick={handleDelete}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
