import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EmailIcon from "@mui/icons-material/EmailOutlined";

import {Chip, Typography} from "@mui/material";
import {GridActionsCellItem, GridColDef, GridRenderCellParams,} from "@mui/x-data-grid";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses, TooltipProps} from "@mui/material/Tooltip";

import DataTableDefault from "../../../../../components/DataTable";
import {hasClaim} from "../../../../../storage/userClaims";
import {useMemo} from "react";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }: any) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    minWidth: 100,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
  },
}));

interface DataTableProps {
  onEdit: (id: number | string) => void;
  onDelete: (id: number | string) => void;
  onView: (id: number | string) => void;
  data: any[];
  [x: string]: any;
}

interface EmailCellRendererProps {
  emails: string[];
}

export const EmailCellRenderer: React.FC<EmailCellRendererProps> = ({ emails }) => {
  return (
      <LightTooltip
          title={
            <>
              {emails.map((email) => (
                  <div key={email}>
                    <Typography>{email}</Typography>
                  </div>
              ))}
            </>
          }
      >
        <Chip
            icon={<EmailIcon fontSize="small" color="primary" />}
            label="E-mails"
            variant="outlined"
        />
      </LightTooltip>
  );
};

const useMemoizedEmails = (emails) => {
  return useMemo(() => {
    return emails?.map((item) => item.GCTEG_DSC_EMAIL);
  }, [emails]);
}

const EmailCell = ({ params }) => {
  const emails = useMemoizedEmails(params.row?.EMAILS);
  return <EmailCellRenderer emails={emails} />;
};

export default function DataTable({
  onEdit,
  onDelete,
  onView,
  data,
  ...props
}: DataTableProps) {
  const renderMenus = (row: any) => {
    return [
      <GridActionsCellItem
        sx={{
          display:
            hasClaim("Email Type Center - Maintenance") ||
            hasClaim("Email Type Center - Visualization")
              ? "block"
              : "none",
        }}
        key={`view-${row.ID_CENTRO_TIPO_EMAIL}`}
        icon={<VisibilityIcon />}
        label="View"
        onClick={() => onView(row.ID_CENTRO_TIPO_EMAIL)}
        color="inherit"
        data-testid="view-button"
      />,
      <GridActionsCellItem
        sx={{
          display: hasClaim("Email Type Center - Maintenance")
            ? "block"
            : "none",
        }}
        key={`edit-${row.ID_CENTRO_TIPO_EMAIL}`}
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={() => onEdit(row.ID_CENTRO_TIPO_EMAIL)}
        color="inherit"
        data-testid="edit-button"
      />,
      <GridActionsCellItem
        sx={{
          display: hasClaim("Email Type Center - Maintenance")
            ? "block"
            : "none",
        }}
        key={`delete-${row.ID_CENTRO_TIPO_EMAIL}`}
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => onDelete(row.ID_CENTRO_TIPO_EMAIL)}
        color="inherit"
        data-testid="delete-button"
      />,
    ];
  };

  const columns: GridColDef[] = [
    {
      field: "GC_NOME_CENTRO",
      headerName: "Company - Plant",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `${params.row.GE_SIGLA_EMPRESA} - ${params.row.GC_DSC_SIGLA} - ${params.row.GC_NOME_CENTRO}`;
      },
    },
    {
      field: "GTE_DSC_TIPO",
      headerName: "Type",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "DSC_TIPO",
      headerName: "E-mail",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params) => <EmailCell params={params} />,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }) => renderMenus(row),
    },
  ];
  return (
    <>
      <DataTableDefault
        rowId={data?.map((row) => row.ID_CENTRO_TIPO_EMAIL)}
        data-testid="quarantine-data-table"
        data={data}
        columns={columns}
        {...props}
      />
    </>
  );
}
