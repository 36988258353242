import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion } from "../../../components/Accordion/Accordion";
import { AccordionSummary } from "../../../components/Accordion/AccordionSummary";
import { AccordionDetails, Grid, Typography } from "@mui/material";
import { EventProcess } from "../../../types/eventProcess";

type ProcessEventsProps = {
  events: EventProcess[],
}

export default function ProcessEvents({
  events,
}: ProcessEventsProps) {
  return (
    <Accordion
      sx={{
        '&.MuiAccordion-root': {
          border: 0,
          marginBottom: 3,
          marginTop: 7
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography>Events</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container paddingY={0.5} paddingX={2} style={{ backgroundColor: "gray", marginTop: 25 }}>
          <Grid item xs={12 / 5}>
            <Typography>Activity</Typography>
          </Grid>
          <Grid item xs={12 / 5}>
            <Typography>Date</Typography>
          </Grid>
          <Grid item xs={12 / 5}>
            <Typography>Hour</Typography>
          </Grid>
          <Grid item xs={12 / 5}>
            <Typography>Local</Typography>
          </Grid>
          <Grid item xs={12 / 5}>
            <Typography>Note</Typography>
          </Grid>
        </Grid>
        {events.map((event) => (
          <Grid container key={event.ID_PROCESSO_EVENTO} paddingY={0.5} paddingX={2} style={{ marginTop: 7 }}>
            <Grid item xs={12 / 5}>
              <Typography variant="body2">{event.ATIVIDADE}</Typography>
            </Grid>
            <Grid item xs={12 / 5}>
              <Typography variant="body2">{event.DATA_ATIVIDADE ? dayjs(event.DATA_ATIVIDADE).format('MM/DD/YYYY') : ''}</Typography>
            </Grid>
            <Grid item xs={12 / 5}>
              <Typography variant="body2">{event.HORA_ATIVIDADE ? dayjs(event.HORA_ATIVIDADE).format('HH:mm:ss') : ''}</Typography>
            </Grid>
            <Grid item xs={12 / 5}>
              <Typography variant="body2">{event.LOCAL}</Typography>
            </Grid>
            <Grid item xs={12 / 5}>
              <Typography variant="body2">{event.DSC_OBS}</Typography>
            </Grid>
          </Grid>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}
