import { Download } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { isAxiosError } from "axios";
import { useEffect, useState } from "react";

import Search from "../../../components/Search";
import {
  create,
  deleteResource,
  exportAll,
  update,
  useList,
  isInUse,
} from "../../../services/quarantine";
import { getLocal, getToken } from "../../../storage/auth";
import { Quarantine } from "../../../types/quarantine";
import QuarantineForm from "./components/QuarantineForm";
import DataTable from "./components/DataTable";
import { hasClaim } from "../../../storage/userClaims";
import { useSnackbar } from "notistack";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
}

export default function QuarantineComponent() {
  const { enqueueSnackbar } = useSnackbar();
  const [quarantineId, setQuarantineId] = useState<string | number>();
  const [quarantine, setQuarantine] = useState<Quarantine>({
    ATIVO: true,
  } as Quarantine);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [controlEdit, setControlEdit] = useState({
    loading: false,
    isInUse: false,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [token, setToken] = useState<string>();
  const [userValidation, setUserValidation] = useState<string>();
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = (getLocal("userInfo")) as any;
    setUserValidation(JSON.parse(getUser));
    setToken(getTokens);
  };
  useEffect(() => {
    getTokenSession();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [errors, setErrors] = useState<string[]>();
  const { data, meta, isLoading } = useList(params, token);

  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };

  const handleView = (id: string | number) => {
    setErrors([]);
    setReadOnly(true);

    const edit = data.find((item: any) => item.ID_QUARENTENA === id);

    if (edit) {
      setQuarantine(edit);
      setFormOpen(true);
    }
  };

  const handleEdit = async (id: string | number) => {
    setErrors([]);
    setReadOnly(false);

    const editItem = data.find((item: any) => item.ID_QUARENTENA === id);

    if (editItem) {
      setQuarantine(editItem);
      setFormOpen(true);
    }

    try {
      setControlEdit({
        loading: true,
        isInUse: true,
      });
      const { inUse } = await isInUse(id);
      setControlEdit({
        loading: false,
        isInUse: inUse,
      });
    } catch (error) {
      enqueueSnackbar("It's not posible to edit, try again later", {
        variant: "error",
      });
      setControlEdit({
        loading: false,
        isInUse: true,
      });
    }
  };

  const handleDeleteConfirmation = (id: string | number) => {
    setQuarantineId(id);
    setOpenDeleteModal(true);
  };

  const handleDelete = async () => {
    if (quarantineId) {
      try {
        setIsSaving(true);
        await deleteResource(quarantineId);
        enqueueSnackbar("Quarantine Code deleted successful", {
          variant: "success",
        });
        setParams({ ...params });
      } catch (err: any) {
        if (err.response.status) {
          enqueueSnackbar("Quarantine Code is being used", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(err.response.data.message[0], { variant: "error" });
        }
      }
      setIsSaving(false);
      setOpenDeleteModal(false);
    }
  };

  const handleFormSubmit = async (newQuarantine: Quarantine) => {
    try {
      setIsSaving(true);
      if (newQuarantine.ID_QUARENTENA) {
        await update(newQuarantine.ID_QUARENTENA, newQuarantine);
        enqueueSnackbar("Quarantine Code updated successful", {
          variant: "success",
        });
      } else {
        await create(newQuarantine);
        enqueueSnackbar("Quarantine Code created successful", {
          variant: "success",
        });
      }
      setQuarantine({ ATIVO: true } as Quarantine);
      setParams({ ...params });
      setFormOpen(false);
      setIsSaving(false);
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setErrors([err.response?.data?.message]);
      }
      setIsSaving(false);
    }
    setIsSaving(false);
  };

  const exportItem = async () => {
    try {
      await exportAll(params);
    } catch (err) {}
  };

  const handleCancelEdit = () => {
    setQuarantine({} as Quarantine);
    setErrors([]);
    setFormOpen(false);
    setControlEdit({
      loading: false,
      isInUse: false,
    });
  };

  return (
    <Grid marginTop={4}>
      <Grid container spacing={1} direction="column">
        <Grid item xs={10}>
          <Typography variant="h5">Quarantine Code</Typography>
        </Grid>
        {!formOpen ? (
          <Grid item xs={2} marginTop={1}>
            <Button
              sx={{
                display: hasClaim("Quarantine - Maintenance")
                  ? "block"
                  : "none",
              }}
              variant="contained"
              onClick={() => {
                setControlEdit({
                  loading: false,
                  isInUse: false,
                });
                setErrors([])
                setFormOpen(true);
              }}
              data-testid="new-button"
            >
              New
            </Button>
          </Grid>
        ) : null}
      </Grid>
      {formOpen ? (
        <Grid container alignItems="center">
          <QuarantineForm
            onSubmit={handleFormSubmit}
            quarantine={quarantine}
            onCancel={handleCancelEdit}
            isLoading={isSaving}
            errors={errors}
            controlEdit={controlEdit}
            readOnly={readOnly}
          />
        </Grid>
      ) : null}
      <Search onChange={handleRequest} />
      <Grid container marginTop={4} justifyContent="flex-end">
        <Button variant="text" onClick={exportItem} startIcon={<Download />}>
          Export XLSX
        </Button>
      </Grid>
      <Grid>
        <DataTable
          loading={isLoading}
          data={data}
          onDelete={handleDeleteConfirmation}
          onEdit={handleEdit}
          onView={handleView}
          userValidation={userValidation}
          rowCount={meta?.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          paginationModel={paginationModel}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete Quarantine Code
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete this Quarantine Code?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button onClick={handleDelete} data-testid="delete-modal-confirm"data-testid="delete-modal-confirm">Confirm</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
