import jwtDecode, {JwtPayload} from "jwt-decode";

export const setToken = (token: string) => {
  localStorage.setItem(
    `${process.env.REACT_APP_LOCALSTORAGE_KEY}-token`,
    token
  );
};

export const deleteToken = () => {
  localStorage.removeItem(`${process.env.REACT_APP_LOCALSTORAGE_KEY}-token`);
}

export const setLocal = (keyName: string, token: string) => {
  localStorage.setItem(keyName, token);
};

export const getLocal = (keyName: string) => {
  try {
    return localStorage.getItem(keyName);
  } catch (err) {
    console.log("err", err);
  }
  return false;
};

export const removeLocal = (keyName: string) => {
  try {
    localStorage.removeItem(keyName);
  } catch (err) {
    console.log("err", err);
  }
};

export const getToken = (): string => {
  return localStorage.getItem(
    `${process.env.REACT_APP_LOCALSTORAGE_KEY}-token`
  ) || "";
};

export const getSession = () => {
  let token = getToken();
  if (!token) {
    return { exp: 0 } as JwtPayload;
  }
  return jwtDecode<JwtPayload>(token);
};