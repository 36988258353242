import "./card-pipeline.css";
import registerInvoice from "./images/Register Invoice.png";
import pending from "./images/Pending.png";
import waitingSisterInvoice from "./images/Waiting Sister Invoice.png";
import pendingShipmentAuthorized from "./images/Pending Shipment Authorized.png";
import shipmentAuthorization from "./images/Shipment Authorization.png";
import internationalTransit from "./images/International  Transit.png";
import customsClearance from "./images/Customs Clearance.png";
import domesticTransit from "./images/Domestic Transit.png";
import { Typography } from "@mui/material";
import { InvoiceStatus } from "../../types/invoice";

const statusesIcons = [
  {
    status: InvoiceStatus.REGISTERED_INVOICE,
    icon: registerInvoice,
  },
  {
    status: InvoiceStatus.PENDING,
    icon: pending,
  },
  {
    status: InvoiceStatus.WAITING_SISTER_INVOICE,
    icon: waitingSisterInvoice,
  },
  {
    status: InvoiceStatus.PENDING_SHIPMENT_AUTHORIZATION,
    icon: pendingShipmentAuthorized,
  },
  {
    status: InvoiceStatus.SHIPMENT_AUTHORIZED,
    icon: shipmentAuthorization,
  },
  {
    status: InvoiceStatus.INTERNATIONAL_TRANSIT,
    icon: internationalTransit,
  },
  {
    status: InvoiceStatus.CUSTOMS_CLEARANCE,
    icon: customsClearance,
  },
  {
    status: InvoiceStatus.DOMESTIC_TRANSIT,
    icon: domesticTransit,
  },
];

export default function CardPipeline({
  status,
  inSla,
  outSla,
  selectedStatus,
  selectedSla,
  onSelectStatus,
  onSelectSLA
}: {
  status: string,
  inSla: number,
  outSla: number,
  selectedStatus: string|null|undefined,
  selectedSla: string|boolean|null|undefined,
  onSelectStatus: (e: any) => void,
  onSelectSLA: (status: string, inSla: boolean) => void
}) {
  const getIcon = (status: string): string => {
    const icon = statusesIcons.find((item) => item.status.trim().toLowerCase() === status.trim().toLowerCase());
    return icon ? icon.icon : "";
  }

  const handleGetClassByStatus = (): string => {
    const initialStatus = "Registered Invoice";

    if (status === initialStatus && status === selectedStatus) {
      return "card-pipeline first selected";
    }

    if (status === initialStatus) {
      return "card-pipeline first";
    }

    if (status === selectedStatus) {
      return "card-pipeline selected"
    }

    return "card-pipeline";
  }

  return (
    <div 
      onClick={() => onSelectStatus({ target: { value: status } })} 
      className={handleGetClassByStatus()}
    >
      <div className="row icon-area">
        <img 
          height={28} 
          alt={status} 
          src={getIcon(status)} 
          className="icon-card-pipeline"
        />
        <Typography color="#fff" fontSize={14}>{ status }</Typography>
      </div>

      <div className="totals">
        <div className="row-between">
          <div className={`circle in-sla ${selectedStatus === status && typeof selectedSla === 'boolean' && selectedSla === true && 'selected'}`} onClick={(e) => onSelectSLA(status, true)}>
            <Typography>{ inSla || 0 }</Typography>
          </div>
          <div className={`circle out-of-sla ${selectedStatus === status && typeof selectedSla === 'boolean' && selectedSla === false && 'selected'}`} onClick={(e) => onSelectSLA(status, false)}>
            <Typography>{ outSla || 0 }</Typography>
          </div>
        </div>
      </div> 
    </div>
  )
}