import { Chip } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { PickupZone } from "../../../../../types/pickupZone";
import DataTableDefault from "../../../../../components/DataTable";
import { hasClaim } from "../../../../../storage/userClaims";

interface DataTableProps {
  onEditPickupZone: (id: number | string) => void;
  onDeletePickupZone: (id: number | string) => void;
  onViewPickupZone: (id: number | string) => void;
  data: PickupZone[];
  [x: string]: any;
}
export default function DataTable({
  onEditPickupZone,
  onDeletePickupZone,
  onViewPickupZone,
  data,
  userValidation,
  ...props
}: DataTableProps) {
  const renderMenus = (row: any) => {
    return [
      <GridActionsCellItem
        sx={{
          display:
            hasClaim("Pickup Zone - Maintenance") ||
            hasClaim("Pickup Zone - Visualization")
              ? "block"
              : "none",
        }}
        key={`view-${row.ID_PICK_UP_ZONE}`}
        icon={<VisibilityIcon />}
        label="View"
        onClick={() => onViewPickupZone(row.ID_PICK_UP_ZONE)}
        color="inherit"
        data-testid="view-button"
      />,
      <GridActionsCellItem
        sx={{
          display: hasClaim("Pickup Zone - Maintenance") ? "block" : "none",
        }}
        key={`edit-${row.ID_PICK_UP_ZONE}`}
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={() => onEditPickupZone(row.ID_PICK_UP_ZONE)}
        color="inherit"
        data-testid="edit-button"
      />,
      <GridActionsCellItem
        sx={{
          display: hasClaim("Pickup Zone - Maintenance") ? "block" : "none",
        }}
        key={`delete-${row.ID_PICK_UP_ZONE}`}
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => onDeletePickupZone(row.ID_PICK_UP_ZONE)}
        color="inherit"
        data-testid="delete-button"
      />,
    ];
  };

  const columns: GridColDef[] = [
    {
      field: "SIGLA",
      headerName: "Pick-up Zone",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `${params.row.SIGLA} - ${params.row.DESCRICAO}`;
      },
    },
    {
      field: "ORIGEM",
      headerName: "Origin",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return params.row.ID_TIPO_EMBARQUE;
      }
    },
    {
      field: "ATIVO",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Date>) =>
        params.value ? (
          <Chip label="Active" color="primary" />
        ) : (
          <Chip label="Inactive" variant="outlined" />
        ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }) => renderMenus(row),
    },
  ];

  return (
    <DataTableDefault
      rowId={data.map((row: any) => row.ID_PICK_UP_ZONE)}
      data-testid="pickupzone-data-table"
      data={data}
      columns={columns}
      {...props}
    />
  );
}
