import { Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Button, Box } from '@mui/material';

export interface ConfirmDialogType {
  open: boolean;
  message?: string;
  title?: string;
  callbackOnConfirm?: (element?: any) => void;
  callbackOnCancel?: (element?: any) => void;
}

interface ConfirmDialogProps {
  dialogMeta: ConfirmDialogType;
}

const ConfirmDialog = ({ dialogMeta, }: ConfirmDialogProps) => {
  return (
    <Box>
      <Dialog 
        open={dialogMeta.open} 
        aria-labelledby="alert-dialog-title" 
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogMeta.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMeta.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dialogMeta.callbackOnCancel()}>Disagree</Button>
          <Button onClick={() => dialogMeta.callbackOnConfirm()} autoFocus data-testid="agree-button">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ConfirmDialog;