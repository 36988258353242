import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { MaterialType } from "../../../../../types/materialType";

let materialTypeScheme = yup.object({
  CODIGO_EDI: yup.string().max(3).required(),
  DESCRICAO: yup.string().max(100).required(),
  ATIVO: yup.boolean().notRequired(),
});

interface MaterialTypeFormProps {
  materialType: MaterialType;
  onSubmit: (materialType: MaterialType) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly?: boolean;
}

export default function MaterialTypeForm({
  materialType,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  controlEdit,
  readOnly
}: MaterialTypeFormProps) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors: formErrors },
    reset,
  } = useForm({
    resolver: yupResolver(materialTypeScheme),
  });

  useEffect(() => {
    reset(materialType);
  }, [materialType, reset]);

  const handleFormSubmit = (newMaterialType: any) => {
    onSubmit(newMaterialType);
  };

  const handleCancel = () => {
    reset({ CODIGO_EDI: "", DESCRICAO: "", ATIVO: true });
    onCancel();
  };

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="materialType-form"
    >
      {errors.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="search"
        container
        spacing={2}
        marginTop={1}
        border={1}
        padding={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >
        <Grid container spacing={2}>
          <Grid item md={8} xs={12}>
            <TextField
              fullWidth
              label="Description"
              variant="outlined"
              disabled={controlEdit.isInUse || readOnly}
              size="small"
              {...register("DESCRICAO")}
              error={!!formErrors.DESCRICAO}
              inputProps={{ maxLength: 100, 'data-testid': "DESCRICAO-input" }}
            />
          </Grid>
          <Grid item md={2} xs={4}>
            <TextField
              fullWidth
              label="EDI Code"
              variant="outlined"
              size="small"
              disabled={controlEdit.isInUse || readOnly}
              {...register("CODIGO_EDI")}
              error={!!formErrors.CODIGO_EDI}
              inputProps={{ maxLength: 3, 'data-testid': "CODIGO_EDI-input" }}
            />
          </Grid>
          <Grid item md={2} xs={2}>
            <Controller
              render={({ field: { value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!value}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={readOnly}
                      {...register("ATIVO")}
                    />
                  }
                  labelPlacement="start"
                  label="Status"
                />
              )}
              name="ATIVO"
              control={control}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" spacing={2} marginTop={2}>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={isLoading}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          {!readOnly ? (
              <Grid marginLeft={2}>
                <Button
                    variant="contained"
                    disabled={isLoading}
                    type="submit"
                    data-testid="submit-button"
                >
                  Confirm
                </Button>
              </Grid>
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
}
