export const useSetStateWrapper = <T,>(
  setState: React.Dispatch<React.SetStateAction<T>>, 
  onChange: (changedValue: T) => void
): React.Dispatch<React.SetStateAction<T>> => {
  return (newStateValue) => {
    setState((currentState) => {
      const newValue = typeof newStateValue === 'function'
        ? (newStateValue as (prevState: T) => T)(currentState)
        : newStateValue;
      onChange(newValue);
      return newValue;
    });
  };
}