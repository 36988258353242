import { Box, Button, styled } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ChangeEvent, useState } from "react";
import { enqueueSnackbar } from "notistack";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface InputUploadProps {
  onInput: (event: ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  maxSizeMB?: number;
  onDrop: (files: FileList) => void;
  disabled?: boolean;
  customText?: string;
  hideIcon?: boolean;
}

export default function InputUpload({ 
  onInput, 
  accept = '*', 
  maxSizeMB = 10, 
  onDrop,
  disabled = false,
  customText,
  hideIcon = false
}: InputUploadProps) {
  const [key, setKey] = useState(0);
  const [onHover, setOnHover] = useState(false);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setOnHover(false);
    onDrop(event.dataTransfer.files);
  }

  const handleChange = (event: any): void => {
    const fileSizeMB = event.target.files[0].size / (1024 * 1024);

    if (fileSizeMB > maxSizeMB) {
      enqueueSnackbar(`File size must be less than ${maxSizeMB}MB`, { variant: "error" });
      return;
    }

    onInput(event);
    setKey(key + 1);
  }

  return (
    <Box
      onDrop={handleDrop}
      onDragOver={(e) => {
        e.preventDefault();
        setOnHover(true);
      }}
      onDragLeaveCapture={(e) => {
        e.preventDefault();
        setOnHover(false);
      }}
    >
      <Button
        style={{
          ...onHover ? { backgroundColor: '#1565c0' } : {},
        }}
        component="label"
        variant="contained"
        startIcon={!hideIcon ? <CloudUploadIcon /> : <></>}
        disabled={disabled}
      >
        {!customText ? 'Upload file / Drag File' : customText}
        <VisuallyHiddenInput
          key={key}
          accept={accept}
          type="file"
          onChange={handleChange}
          disabled={disabled}
          data-testid="input-upload-file"
        />
      </Button>
    </Box>
  )
}