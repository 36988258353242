import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { ComboBoxType } from "../../../../../types/general";
import { enqueueSnackbar } from "notistack";
import { findReport, findReportCustom } from "../../../../../services/reportSchedule";
import { IParams } from "../..";

interface SearchReportScheduleProps {
  dataCenterCombo: ComboBoxType[],
  onChange: (params: any) => void,
  params: IParams,
  setParams: (params: IParams) => void,
}

export default function SearchReportSchedule({
  dataCenterCombo,
  onChange,
  params,
  setParams,
}: Readonly<SearchReportScheduleProps>) {

  const [centerSelected, setCenterSelected] = useState<number>();
  const [reportCombo, setReportCombo] = useState<ComboBoxType[]>([] as ComboBoxType[]);
  const [reportSelected, setReportSelected] = useState<number>();
  const [filterModelCombo, setFilterModelCombo] = useState<ComboBoxType[]>([] as ComboBoxType[]);
  const [filterModelSelected, setFilterModelSelected] = useState<number>();

  const handleSearch = () => {
    if(!centerSelected || !reportSelected || !filterModelSelected) {
      enqueueSnackbar("You must to select Company - Plant, Report and Filter model", { variant: "info" });
      return;
    }
    onChange({
      ...params,
      page: 1,
      ID_CENTRO: centerSelected,
      ID_RELATORIO: reportSelected,
      ID_RELATORIO_CUSTOM: filterModelSelected,
    });
  };

  const resetParams = () => {
    setParams({ page: 1, limit: 10, });
  }



  const triggerFindFilterModel = async (id: number | undefined) => {
    setFilterModelSelected(undefined);
    if(id) {
      const filterCustom = await findReportCustom(id);
      setFilterModelCombo(filterCustom);
    }
  }

  useEffect(() => {
    const triggerFindReport = async () => {
      const report = await findReport();
      setReportCombo(report);
    }

    triggerFindReport();
  }, [])

  return (
    <form>
      <Grid container spacing={2} marginTop={6} marginBottom={2}>
        <Grid item xs={5}>
          <Autocomplete
            data-testid="company-input"
            options={dataCenterCombo || []}
            getOptionLabel={(option) => option.description}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Company - Plant"
                size="small"
                variant="outlined"
              />
            )}
            onChange={(_: any, data: any) => {
              setCenterSelected(data?.id);
              resetParams();
            }}
          />
        </Grid>
        <Grid item xs={3.5}>
          <Autocomplete
            data-testid="report-input"
            options={reportCombo || []}
            getOptionLabel={(option) => option.description}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Report"
                size="small"
                variant="outlined"
              />
            )}
            onChange={(_: any, data: any) => {
              onChange(data?.id || null);
              triggerFindFilterModel(data?.id);
              setReportSelected(data?.id);
              resetParams();
            }}
          />
        </Grid>
        <Grid item xs={3.5}>
          <Autocomplete
            data-testid="filter-input"
            options={filterModelCombo || []}
            getOptionLabel={(option) => option.description}
            value={
              filterModelCombo?.findLast((item) => item?.id === filterModelSelected) || null
            }
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Filter model"
                size="small"
                variant="outlined"
              />
            )}
            onChange={(_: any, data: any) => {
              onChange(data?.id || null);
              setFilterModelSelected(data?.id);
              resetParams();
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            data-testid="submit-button"
            variant="contained"
            fullWidth
            onClick={handleSearch}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}