import { Download } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { isAxiosError } from "axios";
import { useEffect, useState } from "react";

import Search from "../../../components/Search";
import { create, deleteAirport, exportAll, update, useList, isInUse } from "../../../services/airport";
import { getLocal, getToken } from "../../../storage/auth";
import { Airport } from "../../../types/airport";
import AirportForm from "./components/AirportForm";
import DataTable from "./components/DataTable";
import { hasClaim } from "../../../storage/userClaims";
import { useSnackbar } from "notistack";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
}

export default function Airports() {
  const { enqueueSnackbar } = useSnackbar();
  const [airportId, setAirportId] = useState<string | number>();
  const [airport, setAirport] = useState<Airport>({ status: true } as Airport);
  const [isSaving, setIsSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [token, setToken] = useState<string>();
  const [userValidation, setUserValidation] = useState<string>();
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = (getLocal("userInfo")) as any;
    try {
      setUserValidation(JSON.parse(getUser));
      setToken(getTokens);
    } catch (err) {
      // console.log(err)
    }
  };
  useEffect(() => {
    getTokenSession();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [errors, setErrors] = useState<string[]>();
  const { data = [], meta = {}, isLoading } = useList(params, token) || {};
  const [controlEdit, setControlEdit] = useState({
    loading: false,
    isInUse: false,
  });
  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };

  const handleEditAirport = async (id: string | number) => {
    setErrors([])
    setReadOnly(false);
    const editAirport = data.filter((item: any) => item.ID_AEROPORTO === id);
    if (editAirport.length) {
      const { ID_AEROPORTO, SIGLA_AEROPORTO, DSC_AEROPORTO, ATIVO } =
        editAirport[0];
      setAirport({
        id: ID_AEROPORTO,
        airportAbbreviation: SIGLA_AEROPORTO,
        description: DSC_AEROPORTO,
        status: ATIVO,
      });
      setModalOpen(true);
    }

    try {
      setControlEdit({
        loading: true,
        isInUse: true,
      });
      const { inUse } = await isInUse(id);
      setControlEdit({
        loading: false,
        isInUse: inUse,
      });
    } catch (error) {
      enqueueSnackbar("It's not posible to edit, try again later", {
        variant: "error",
      });
      setControlEdit({
        loading: false,
        isInUse: true,
      });
    }
  };

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const handleDeleteAirportConfirmation = (id: string | number) => {
    setAirportId(id);
    setOpenDeleteModal(true);
  };

  const handleDeleteAirport = async () => {
    if (airportId) {
      try {
        setIsSaving(true);
        await deleteAirport(airportId);
        enqueueSnackbar("Airport deleted successful", { variant: "success" });
        setParams({ ...params });
      } catch (err: any) {
        if (err.response.status) {
          enqueueSnackbar("Airport is being used", { variant: "error" });
        } else {
          enqueueSnackbar(err.response.data.message[0], { variant: "error" });
        }
      }
      setIsSaving(false);
      setOpenDeleteModal(false);
    }
  };

  const handleFormSubmit = async (newAirport: Airport) => {
    const airportDto = {
      SIGLA_AEROPORTO: newAirport.airportAbbreviation,
      DSC_AEROPORTO: newAirport.description,
      ATIVO: newAirport.status,
    };

    try {
      setIsSaving(true);
      if (newAirport.id) {
        await update(newAirport.id, airportDto);
        enqueueSnackbar("Airport updated successful", { variant: "success" });
      } else {
        await create(airportDto);
        enqueueSnackbar("Airport created successful", { variant: "success" });
      }
      setAirport({ status: true } as Airport);
      setParams({ ...params });
      setModalOpen(false);
      setIsSaving(false);
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setErrors([err.response?.data?.message]);
      }
      setIsSaving(false);
    }
    setIsSaving(false);
  };

  const exportAirports = async () => {
    try {
      await exportAll(params);
    } catch (err) {}
  };

  const handleCancelEditAirport = () => {
    setAirport({} as Airport);
    setErrors([]);
    setModalOpen(false);
    setControlEdit({
      loading: false,
      isInUse: false,
    });
  };

  const handleViewAirport = async (id: string | number) => {
    setErrors([])
    const viewAirport = data.filter((item: any) => item.ID_AEROPORTO === id);
    setReadOnly(true);

    if (viewAirport.length) {
      const { ID_AEROPORTO, SIGLA_AEROPORTO, DSC_AEROPORTO, ATIVO } = viewAirport[0];
      setAirport({
        id: ID_AEROPORTO,
        airportAbbreviation: SIGLA_AEROPORTO,
        description: DSC_AEROPORTO,
        status: ATIVO,
      });
      setModalOpen(true);
    }
  }

  return (
    <Grid marginTop={4}>
      <Grid container spacing={1} direction="column">
        <Grid item xs={10}>
          <Typography variant="h5">Port/Airport</Typography>
        </Grid>
        {!modalOpen ? (
          <Grid item xs={2} marginTop={1}>
            <Button
              sx={{
                display: hasClaim("Airport - Maintenance") ? "block" : "none",
              }}
              variant="contained"
              onClick={() => {
                setControlEdit({
                  loading: false,
                  isInUse: false,
                });
                setErrors([])
                setReadOnly(false);
                setModalOpen(true);
              }}
              data-testid="new-button"
            >
              New
            </Button>
          </Grid>
        ) : null}
      </Grid>
      {modalOpen ? (
        <Grid container alignItems="center">
          <AirportForm
            onSubmit={handleFormSubmit}
            controlEdit={controlEdit}
            airport={airport}
            onCancel={handleCancelEditAirport}
            isLoading={isSaving}
            errors={errors}
            readOnly={readOnly}
          />
        </Grid>
      ) : null}
      <Search onChange={handleRequest} />
      <Grid container marginTop={4} justifyContent="flex-end">
        <Button
          variant="text"
          onClick={exportAirports}
          startIcon={<Download />}
        >
          Export XLSX
        </Button>
      </Grid>
      <Grid>
        <DataTable
          loading={isLoading}
          data={data}
          onDeleteAirport={handleDeleteAirportConfirmation}
          onEditAirport={handleEditAirport}
          onViewAirport={handleViewAirport}
          userValidation={userValidation}
          rowCount={meta?.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          pagination
          paginationModel={paginationModel}
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Airport</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete this Airport?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button onClick={handleDeleteAirport} data-testid="delete-modal-confirm">Confirm</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
