export function formatNumber(value: number, decimalQuantity?: number) {
  if (!value) return '';

  let formatedNumber = Number(value)?.toFixed(decimalQuantity || 2).replace('.', ',');
  
  let parts = formatedNumber.split(',');
  
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  
  return parts.join(',');
}