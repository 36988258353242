import {
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import DataTableDefault from "../../../../../components/DataTable";
import { BuyerGroupSupService } from "../../../../../types/buyerGroupSup";

interface DataTableProps {
  data: BuyerGroupSupService[];
  loading: boolean;
  [x: string]: any;
}
export default function DataTable({
  data,
  loading,
  ...props
}: DataTableProps) {

  const columns: GridColDef[] = [
    {
      field: "GCEN_DSC_SIGLA",
      headerName: "Company - Plant",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `${params.row.GEMP_SIGLA_EMPRESA} - ${params.row.GCEN_DSC_SIGLA} - ${params.row.GCEN_NOME_CENTRO}`;
      },
    },
    {
      field: "GC_CODIGO_GRUPO",
      headerName: "Code Buyer Group",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "GC_NOME_COMPRADOR",
      headerName: "Buyer Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "NOME_SUPERVISOR",
      headerName: "Supervisor Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "NOME_GERENTE",
      headerName: "Manager Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
  ];
  return (
    <>
      <DataTableDefault
        data={data}
        columns={columns}
        loading={loading}
        {...props}
      />
    </>
  );
}
