"use client";
import { Box } from "@mui/material";
import { urlLoginEmbraer } from "../../../services/auth";

export default function SignIn() {

  window.location.href = urlLoginEmbraer();

  return (
    <Box sx={{ bg: '#100690' }} />
  );
}
