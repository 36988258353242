export const translate = (tableName: string, keyToTranslate: string, translations: any) => {
  return keyToTranslate
    .split('.')
    .map((key) => translations?.[tableName]?.[key] ? translations[tableName][key] : key)
    .join('/');
}

export const translateByField = (
  keyToTranslate: string,
  translationsFields: any,
): string => {
  if(!translationsFields) {
    return keyToTranslate
  }
  for (const group of Object.values(translationsFields)) {
    for (const [key, value] of Object.entries(group)) {
      if (key === keyToTranslate) {
        return value;
      }
    }
  }
  return keyToTranslate;
};

export const translateByFieldLast = (keyToTranslate: string, translationsFields: any) => {
  const keyToTranslateArray = keyToTranslate
    .split('.')
    .map((key) => translationsFields?.[key] ? translationsFields[key] : key);
    
  return keyToTranslateArray[keyToTranslateArray.length - 1];
}

export const translateRelation = (key: string, translations) => {
  if(key === 'GTS_INVOICE') {
    return '';
  }
  return (translations['RELATION'][key]) ? translations['RELATION'][key] : '';
}

export const getTableName = (key: string, relations: Array<any>, ID_RELATORIO: number) => {
  const keyArray = key.split('.');
  const keyToTranslate = keyArray[keyArray.length - 1];

  if (keyArray.length === 1) {
    if(ID_RELATORIO === 10 || ID_RELATORIO === 9) {
      return {
        tableName: 'GTS_TRIAGEM',
        keyToTranslate,
      };
    }
    return {
      tableName: 'GTS_INVOICE',
      keyToTranslate,
    };
  }

  let tableName = keyArray[keyArray.length - 2];

  const relation = relations.find((relation) => tableName === relation.columnName);

  return {
    tableName: relation?.tableName || 'DefaultTableName',
    keyToTranslate: keyToTranslate || key,
  };
}
