import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Autocomplete,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { Company } from "../../../../../types/company";
import { CargoTreatmentsProps } from "../../../../../types/cargoTratament";

let customRegimeScheme = yup.object({
  ID_CENTRO: yup.number().required(),
  DSC_TRAT_CARGA: yup.string().max(100).required(),
  ATIVO: yup.boolean().notRequired(),
});

interface CargoProps {
  cargoTreatment: CargoTreatmentsProps;
  center: any[];
  companies: Company[];
  onSubmit: (customRegime: CargoTreatmentsProps) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly?: boolean;
}

export default function CargoTreatment({
  cargoTreatment,
  center,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  controlEdit,
  readOnly
}: CargoProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(customRegimeScheme),
  });

  useEffect(() => {
    reset(cargoTreatment);
  }, [cargoTreatment, reset]);

  const handleFormSubmit = (cargoTreatment: CargoTreatmentsProps) => {
    onSubmit(cargoTreatment);
  };

  const handleCancel = () => {
    reset({ DSC_TRAT_CARGA: "" });
    onCancel();
  };

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit as any)}
      data-testid="customRegime-form"
    >
      {errors.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="search"
        container
        spacing={2}
        marginTop={1}
        border={1}
        padding={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >
        {controlEdit.loading ? (
          <>Loading</>
        ) : (
          <Grid
            marginBottom={5}
            container
            justifyContent="flex-end"
            spacing={2}
          >
            <Grid item xs={4}>
              <Controller
                defaultValue={cargoTreatment.GC_ID_CENTRO || undefined}
                control={control}
                disabled={readOnly}
                render={({ field: { value, ref, onChange, onBlur, name, ...field } }) => (
                  <>
                    <Autocomplete
                      options={center || []}
                      disabled={controlEdit.isInUse || readOnly}
                      getOptionLabel={(option) => option.description}
                      value={
                        center?.findLast((item) => item.id === value) || null
                      }
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          {...field}
                          label="Company - Plant"
                          size="small"
                          disabled={controlEdit.isInUse || readOnly}
                          inputRef={ref}
                          error={!!formErrors.ID_CENTRO}
                          variant="outlined"
                          data-testid="company-plant-input"
                        />
                      )}
                      onChange={(_: any, data: any) => onChange(data?.id)}
                    />
                  </>
                )}
                name="ID_CENTRO"
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    type="text"
                    label="Description"
                    size="small"
                    variant="outlined"
                    defaultValue={value}
                    disabled={controlEdit.isInUse || readOnly}
                    error={!!formErrors.DSC_TRAT_CARGA}
                    InputLabelProps={{ shrink: !!value }}
                    {...register("DSC_TRAT_CARGA")}
                    inputProps={{ maxLength: 100, 'data-testid': 'description' }}
                    multiline
                    maxRows={2}
                  />
                )}
                name="DSC_TRAT_CARGA"
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                render={({ field: { value } }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={readOnly}
                        checked={!!value}
                        inputProps={{ "aria-label": "controlled" }}
                        {...register("ATIVO")}
                      />
                    }
                    labelPlacement="start"
                    label="Status"
                  />
                )}
                name="ATIVO"
                control={control}
              />
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        )}
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={isLoading}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          <Grid marginLeft={2}>
            {!readOnly ? (
            <Button
              variant="contained"
              disabled={isLoading}
              type="submit"
              data-testid="submit-button"
            >
              Confirm
            </Button>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
