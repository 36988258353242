import { Download } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { isAxiosError } from "axios";
import { useEffect, useState } from "react";

import Search from "../../../components/Search";
import {
  create,
  deleteFreigthForwarder,
  exportAll,
  update,
  useList,
  isInUse,
} from "../../../services/freigthForwarder";
import { getLocal, getToken } from "../../../storage/auth";
import { FreigthForwarderType } from "../../../types/freightForwarder";
import DataTable from "./components/DataTable";
import FreigthForwarderForm from "./components/freigthForwarderForm";
import { hasClaim } from "../../../storage/userClaims";
import { useSnackbar } from "notistack";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
}

export default function FreigthForwarder() {
  const { enqueueSnackbar } = useSnackbar();
  const [freigthForwarder, setFreigthForwarder] =
    useState<FreigthForwarderType>({
      ATIVO: true,
      INTERFACE_ARQUIVO: true,
      INTERFACE_URL: false,
    } as FreigthForwarderType);
  const [freigthForwarderId, setFreigthForwarderId] = useState<
    string | number
  >();
  const [isSaving, setIsSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [token, setToken] = useState<string>();
  const [userValidation, setUserValidation] = useState<string>();
  const [controlEdit, setControlEdit] = useState({
    loading: false,
    isInUse: false,
  });
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = getLocal("userInfo") as any;
    setToken(getTokens);
    setUserValidation(JSON.parse(getUser));
  };

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    getTokenSession()
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [errors, setErrors] = useState<string[]>();
  const { data, meta, isLoading } = useList(params, token);

  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };

  const handleEditFreigthForwarder = async (id: string | number) => {
    setErrors([])
    setReadOnly(false);
    const editFreigthForwarder = data.find(
        (item: FreigthForwarderType) => item.ID_AGENTE_CARGA === id
    );

    if (editFreigthForwarder) {
      const {
        ID_AGENTE_CARGA,
        NOME_AGENTE,
        TIPO_INTERFACE,
        NOME_ARQUIVO,
        URL_INTERFACE,
        ATIVO,
      } = editFreigthForwarder;

      setFreigthForwarder({
        ID_AGENTE_CARGA,
        NOME_AGENTE,
        INTERFACE_ARQUIVO: TIPO_INTERFACE === "FILE",
        INTERFACE_URL: TIPO_INTERFACE === "API",
        NOME_ARQUIVO,
        URL_INTERFACE,
        ATIVO,
      });
    }
    setModalOpen(true);
    try {
      setControlEdit({
        loading: true,
        isInUse: true,
      });
      const { inUse } = await isInUse(id);
      setControlEdit({
        loading: false,
        isInUse: inUse,
      });
    } catch (error) {
      enqueueSnackbar("It's not posible to edit, try again later", {
        variant: "error",
      });
      setControlEdit({
        loading: false,
        isInUse: true,
      });
    }
  };

  const handleDeleteFreigthForwarderConfirmation = (id: string | number) => {
    setFreigthForwarderId(id);
    setOpenDeleteModal(true);
  };

  const handleDeleteFreigthForwarder = async () => {
    if (freigthForwarderId) {
      try {
        setIsSaving(true);
        await deleteFreigthForwarder(freigthForwarderId);
        enqueueSnackbar("Interface - Freigth Forwarder deleted successful", {
          variant: "success",
        });
        setParams({ ...params });
      } catch (err: any) {
        if (err.response.status) {
          enqueueSnackbar("Interface - Freigth Forwarder is being used", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(err.response.data.message[0], { variant: "error" });
        }
      }
      setIsSaving(false);
      setOpenDeleteModal(false);
    }
  };

  const handleFormSubmit = async (
    newFreigthForwarder: FreigthForwarderType
  ) => {
    const freigthForwarderDto = {
      ID_AGENTE_CARGA: newFreigthForwarder.ID_AGENTE_CARGA,
      NOME_AGENTE: newFreigthForwarder.NOME_AGENTE,
      NOME_ARQUIVO: newFreigthForwarder.NOME_ARQUIVO,
      ...(newFreigthForwarder.INTERFACE_ARQUIVO
        ? { TIPO_INTERFACE: "FILE" }
        : { TIPO_INTERFACE: "API" }),
      URL_INTERFACE: newFreigthForwarder.URL_INTERFACE,
      ATIVO: newFreigthForwarder.ATIVO,
    };

    try {
      setIsSaving(true);
      if (newFreigthForwarder.ID_AGENTE_CARGA) {
        await update(newFreigthForwarder.ID_AGENTE_CARGA, freigthForwarderDto);
        enqueueSnackbar("Interface - Freigth Forwarder updated successful", {
          variant: "success",
        });
      } else {
        await create(freigthForwarderDto);
        enqueueSnackbar("Interface - Freigth Forwarder created successful", {
          variant: "success",
        });
      }
      setFreigthForwarder({ ATIVO: true } as FreigthForwarderType);
      setParams({ ...params });
      setModalOpen(false);
      setIsSaving(false);
    } catch (err: unknown) {
      if (isAxiosError(err)) setErrors([err.response?.data?.message]);
      setIsSaving(false);
    }
    setIsSaving(false);
  };

  const exportFreigthForwarders = async () => {
    try {
      await exportAll(params);
    } catch (err) {}
  };

  const handleCancelEditFreigthForwarder = () => {
    setFreigthForwarder({} as FreigthForwarderType);
    setErrors([]);
    setModalOpen(false);
    setControlEdit({
      loading: false,
      isInUse: false,
    });
  };

  const onViewFreigthForwarder = (id: string | number) => {
    setErrors([]);
    setReadOnly(true);
    const editFreigthForwarder = data.find(
        (item: FreigthForwarderType) => item.ID_AGENTE_CARGA === id
    );

    if (editFreigthForwarder) {
      const {
        ID_AGENTE_CARGA,
        NOME_AGENTE,
        TIPO_INTERFACE,
        NOME_ARQUIVO,
        URL_INTERFACE,
        ATIVO,
      } = editFreigthForwarder;

      setFreigthForwarder({
        ID_AGENTE_CARGA,
        NOME_AGENTE,
        INTERFACE_ARQUIVO: TIPO_INTERFACE === "FILE",
        INTERFACE_URL: TIPO_INTERFACE === "API",
        NOME_ARQUIVO,
        URL_INTERFACE,
        ATIVO,
      });
      setModalOpen(true);
    }
  }

  return (
    <Grid marginTop={4}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={8}>
          <Typography variant="h5">Interface - Freigth Forwarder</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" marginTop={1}>
        <Grid item xs={2}>
          {!modalOpen ? (
            <Grid item xs={2}>
              <Button
                variant="contained"
                onClick={() => {
                  setControlEdit({
                    loading: false,
                    isInUse: false,
                  });
                  setReadOnly(false);
                  setErrors([])
                  setFreigthForwarder({
                    ATIVO: true,
                    INTERFACE_ARQUIVO: true,
                    INTERFACE_URL: false,
                  } as FreigthForwarderType);
                  setModalOpen(true);
                }}
                fullWidth
                sx={{
                  display: hasClaim("Interface Freight Forwarder - Maintenance")
                    ? "block"
                    : "none",
                }}
                data-testid='new-button'
              >
                New
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {modalOpen ? (
        <Grid container alignItems="center">
          <FreigthForwarderForm
            onSubmit={handleFormSubmit}
            freigthForwarder={freigthForwarder}
            onCancel={handleCancelEditFreigthForwarder}
            isLoading={isSaving}
            errors={errors}
            controlEdit={controlEdit}
            readOnly={readOnly}
          />
        </Grid>
      ) : null}
      <Search isInterfaceBy onChange={handleRequest} />
      <Grid container marginTop={4} justifyContent="flex-end">
        <Button
          variant="text"
          onClick={exportFreigthForwarders}
          startIcon={<Download />}
        >
          Export XLSX
        </Button>
      </Grid>
      <Grid>
        <DataTable
          loading={isLoading}
          data={data}
          userValidation={userValidation}
          onDeleteFreigthForwarder={handleDeleteFreigthForwarderConfirmation}
          onEditFreigthForwarder={handleEditFreigthForwarder}
          onViewFreigthForwarder={onViewFreigthForwarder}
          rowCount={meta.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          paginationModel={paginationModel}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete Interface - Freigth Forwarder
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete this Interface - Freigth
            Forwarder?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button onClick={handleDeleteFreigthForwarder} data-testid='delete-modal-confirm'>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
