import { useState, useEffect, useMemo } from "react";
import { Button, Grid, Paper } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { isAxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import DataTablePieces from "./components/tablePieces";
import DataTableNotes from "./components/tableNotes";
import UploadDocument from "./components/uploadDocument";
import Form from "./components/form";
import { getFiles, getPieces, getTriage } from "../../../../services/triage";
import { Triage, TriageStatus, initialTriage } from "./types/triage";
import { Piece } from "./types/pieces";
import { TriageFile } from "./types/files";
import DataTableInvoices from "./components/tableInvoices";
import GoodsTriageHeader from "./GoodsTriageHeader";
import { removeFile, uploadFile, getFile } from "../../../../services/triage";
import { getUserClaim } from "../../../../storage/userClaims";

export default function GoodsTriageDetails() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [triage, setTriage] = useState<Triage>(initialTriage);
  const [pieces, setPieces] = useState<Piece[]>([]);
  const [files, setFiles] = useState<TriageFile[]>([]);
  const userClaims = getUserClaim();

  useEffect(() => {
    fetchAll();
  }, []);

  const fetchAll = () => {
    fetchTriage();
    fetchPieces();
    fetchFiles();
  }

  const fetchTriage = async () => {
    try {
      if (id) {
        const response = await getTriage(+id);
        setTriage(response);
      }
    } catch (error) {
      if (isAxiosError(error)) {
        enqueueSnackbar(`${error?.response?.data?.message}`, { variant: "error" });
      }
    }
  }

  const fetchPieces = async () => {
    try {
      if (id) {
        const response = await getPieces(+id);
        setPieces(response);
      }
    } catch (error) {
      if (isAxiosError(error)) {
        enqueueSnackbar(`${error?.response?.data?.message}`, { variant: "error" });
      }
    }
  }

  const fetchFiles = async () => {
    try {
      if (id) {
        const response = await getFiles(+id);
        setFiles(response);
      }
    } catch (error) {
      if (isAxiosError(error)) {
        enqueueSnackbar(`${error?.response?.data?.message}`, { variant: "error" });
      }
    }
  }

  const onLog = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const url = `/goods-triage/log/${id}`;
    return (event.ctrlKey || event.metaKey) ? window.open(url, '_blank') : navigate(url);
  };

  const filesSectionEnable = useMemo(() => {
    if (triage.ID_STATUS_TRIAGEM === TriageStatus.WAITING_ATTACHMENTS) {
      const isEnabled = userClaims.some((claim) => ["Triage - Register"].includes(claim));
      return { files: isEnabled, stream: true };
    }

    if (
        [
          TriageStatus.MISSING_INVOICE,
          TriageStatus.UNDER_ANALYSIS,
          TriageStatus.UNDER_LOGISTICS_ANALYSIS,
          TriageStatus.PENDING
        ].includes(triage.ID_STATUS_TRIAGEM as TriageStatus)
    ) {
      const isEnabled = userClaims.some((claim) => ["Triage - Analysis"].includes(claim));
      return { files: isEnabled, stream: true };
    }

    if (
      [
        TriageStatus.UNDER_LOGISTICS_ANALYSIS,
        TriageStatus.PENDING
      ].includes(triage.ID_STATUS_TRIAGEM as TriageStatus)
    ) {
      return { files: true, stream: true };
    }

    if (
      [
        TriageStatus.WAITING_TO_MOVE_TO_RECEIVING,
        TriageStatus.FINALIZED
      ].includes(triage.ID_STATUS_TRIAGEM as TriageStatus)
    ) {
      return { files: false, stream: true };
    }

    return { files: false, stream: true };
  }, [userClaims, triage.ID_STATUS_TRIAGEM]);

  return (
    <div>

      <Paper
        variant='outlined'
        sx={{ marginTop: 3, paddingX: 2, paddingBottom: 2 }}
      >
        <GoodsTriageHeader triage={triage} />
      </Paper>

      <Paper
        variant="outlined"
        sx={{
          marginTop: 2,
          marginBottom: 2,
          paddingX: 2,
          paddingY: 1,
          gap: 2,
          borderRadius: 3
        }}
      >
          <Grid container marginTop={1} spacing={2}>
            <Grid item xs={7}>
              {
                pieces && (
                  <DataTablePieces 
                    data={pieces}
                    triageCenter={triage.ID_CENTRO}
                    triageStatus={triage.ID_STATUS_TRIAGEM}
                    onUpdate={fetchAll}
                  />
                )
              }
            </Grid>
            <Grid item xs={5}>
              <UploadDocument 
                attachments={files}
                onFinish={fetchFiles}
                isDisabled={!filesSectionEnable.files}
                isDisabledStream={!filesSectionEnable.stream}
                removeFile={removeFile}
                uploadFile={uploadFile}
                getFile={getFile}
                id={id!}
              />
            </Grid>
        </Grid>
        
        <Grid item marginTop={2}>
          <Grid marginTop={2} item xs={6}>
            <Paper
              variant="outlined"
              sx={{
                marginTop: 2,
                marginBottom: 2,
                paddingX: 2,
                paddingY: 1,
                gap: 2,
                borderRadius: 3
              }}
            >
              <DataTableInvoices
                triage={triage}
                onUpdate={fetchAll}
              />
            </Paper>
          </Grid>
        </Grid>

      </Paper>

      <Grid container spacing={2}>
        <Grid marginTop={2} item xs={6}>
          <Paper
            variant="outlined"
            sx={{
              marginTop: 2,
              marginBottom: 2,
              paddingX: 2,
              paddingY: 1,
              gap: 2,
              borderRadius: 3
            }}
          >
            <DataTableNotes 
              triage={triage}
            />
          </Paper>
        </Grid>
        
        <Grid marginTop={2} item xs={6}>
          <Paper
            variant="outlined"
            sx={{
              marginTop: 2,
              marginBottom: 2,
              paddingX: 2,
              paddingY: 4,
              gap: 2,
              borderRadius: 3
            }}
          >
            <Form 
              triageStatus={triage.ID_STATUS_TRIAGEM}
              totalFiles={files?.length ?? 0}
              triage={triage}
              onUpdate={fetchAll}
            />
          </Paper>
        </Grid>
      </Grid>

      <Paper
        variant="outlined"
        sx={{
          marginTop: 3,
          marginBottom: 3,
          paddingX: 2,
          paddingY: 3,
          display: "flex",
          justifyContent: "end",
          gap: 2,
        }}
      >
        <Button
          sx={{
            marginRight: "auto",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            color: "currentColor",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              color: "currentColor",
            },
          }}
          variant="contained"
          color="secondary"
          onClick={() => navigate(-1)}
        >
          Return
        </Button>
        <Button
          variant="outlined"
          sx={{ width: '92px' }}
          onClick={onLog}
        >
          Log
        </Button>
      </Paper>

    </div>
  )
}