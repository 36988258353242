import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { FreigthForwarderType } from "../../../../../types/freightForwarder";

let airportScheme = yup.object({
  NOME_AGENTE: yup.string().max(50).required(),
  INTERFACE_ARQUIVO: yup.boolean().required(),
  INTERFACE_URL: yup.boolean().required(),
  ATIVO: yup.boolean().notRequired(),
  NOME_ARQUIVO: yup
    .string()
    .max(150)
    .when("INTERFACE_ARQUIVO", {
      is: true,
      then: (schema) => schema.required(),
    }),
  URL_INTERFACE: yup
    .string()
    .max(200)
    .when("INTERFACE_URL", {
      is: true,
      then: (schema) => schema.required(),
    }),
});

interface FreightForwarderFormProps {
  freigthForwarder: FreigthForwarderType;
  onSubmit: (freigthForwarder: FreigthForwarderType) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly?: boolean;
}

export default function FreightForwarderForm({
  freigthForwarder,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  controlEdit,
  readOnly,
}: FreightForwarderFormProps) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors: formErrors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(airportScheme),
  });
  const initInterfaces = freigthForwarder.INTERFACE_ARQUIVO
  const [interfaces, setInterfaces] = useState(initInterfaces);

  useEffect(() => {
    reset(freigthForwarder);
  }, [freigthForwarder, reset]);

  const handleFormSubmit = (newFreigthForwarder: FreigthForwarderType) => {
    const newDataForm = {
      ID_AGENTE_CARGA: newFreigthForwarder.ID_AGENTE_CARGA,
      NOME_AGENTE: newFreigthForwarder.NOME_AGENTE,
      INTERFACE_ARQUIVO: newFreigthForwarder.INTERFACE_ARQUIVO,
      INTERFACE_URL: newFreigthForwarder.INTERFACE_URL,
      ...(!interfaces ? { URL_INTERFACE: newFreigthForwarder.URL_INTERFACE } : { URL_INTERFACE: "" }),
      ...(interfaces ? { NOME_ARQUIVO: newFreigthForwarder.NOME_ARQUIVO } : { NOME_ARQUIVO: "" }),
      ATIVO: newFreigthForwarder.ATIVO,
    };
    onSubmit(newDataForm);
  };

  const handleCancel = () => {
    reset({ NOME_AGENTE: "", NOME_ARQUIVO: "" });
    onCancel();
  };

  useEffect(() => {
      setInterfaces(freigthForwarder.INTERFACE_ARQUIVO);
  }, [freigthForwarder]);

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="freigthForwarder-form"
    >
      {errors.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="search"
        container
        spacing={2}
        marginTop={1}
        border={1}
        padding={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={controlEdit.isInUse || readOnly}
              label="Interface Name"
              variant="outlined"
              size="small"
              {...register("NOME_AGENTE")}
              InputLabelProps={{ shrink: true }}
              error={!!formErrors.NOME_AGENTE}
              inputProps={{ maxLength: 50, 'data-testid': 'NOME_AGENTE-input' }}
            />
          </Grid>
          <Grid item xs={2}>
            <RadioGroup
              data-testid="INTERFACE_ARQUIVO-radio"
              aria-labelledby="radio-buttons-group-label"
              value={interfaces}
              row
              onChange={(e) => {
                const result = JSON.parse(e.target.value);
                setInterfaces(result);
                setValue("INTERFACE_ARQUIVO", result);
                setValue("INTERFACE_URL", !result);
              }}
            >
              <FormControlLabel
                data-testid="INTERFACE_ARQUIVO-radio-active"
                value="true"
                control={<Radio />}
                label="File"
                disabled={controlEdit.isInUse || readOnly}
              />
              <FormControlLabel
                data-testid="INTERFACE_ARQUIVO-radio-inactive"
                value="false"
                control={<Radio />}
                disabled={controlEdit.isInUse || readOnly}
                label="API"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={10}>
            {interfaces ? (
              <TextField
                disabled={controlEdit.isInUse || readOnly}
                fullWidth
                label="File name"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                {...register("NOME_ARQUIVO")}
                error={!!formErrors.NOME_ARQUIVO}
                inputProps={{ maxLength: 150, 'data-testid': 'NOME_ARQUIVO-input' }}
              />
            ) : (
              <TextField
                disabled={controlEdit.isInUse || readOnly}
                fullWidth
                label="Api name"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                {...register("URL_INTERFACE")}
                error={!!formErrors.URL_INTERFACE}
                inputProps={{ maxLength: 200, 'data-testid': 'URL_INTERFACE-input' }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field: { value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!value}
                      disabled={readOnly}
                      inputProps={{ "aria-label": "controlled" }}
                      {...register("ATIVO")}
                    />
                  }
                  labelPlacement="start"
                  label="Status"
                />
              )}
              name="ATIVO"
              control={control}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={isLoading}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          {!readOnly ? (
          <Grid marginLeft={2}>
            <Button
              variant="contained"
              disabled={isLoading}
              type="submit"
              data-testid="submit-button"
            >
              Confirm
            </Button>
          </Grid>
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
}
