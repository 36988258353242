"use client";
import { Box } from "@mui/material";
import { api } from "../../../lib/api";
import { setToken } from "../../../storage/auth";
import { useNavigate } from "react-router-dom";

export default function LoginTemp() {
  const navigate = useNavigate();
  api.get('/auth/createTokenMock').then((result) => {
    setToken(result.data.access_token);
    navigate(`/`);
  })

  return (
    <Box sx={{ bg: '#100690' }}  data-testid="box" />
  );
}