import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Autocomplete, Button, Grid, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { AutomaticAuthorizationScheduleProps } from "../../../../../types/automaticAuthorizationSchedule";

let customRegimeScheme = yup.object({
  ID_CENTRO: yup.number().required(),
  ID_CENTRO_CONSOLIDADOR: yup.number().required(),
  DSC_PERIODO: yup.string().required(),
  HORA_AGEND: yup.string().required(),
  AGEND_MONDAY: yup.boolean().notRequired(),
  AGEND_TUESDAY: yup.boolean().notRequired(),
  AGEND_WEDNESDAY: yup.boolean().notRequired(),
  AGEND_THURSDAY: yup.boolean().notRequired(),
  AGEND_FRIDAY: yup.boolean().notRequired(),
  AGEND_SATURDAY: yup.boolean().notRequired(),
  AGEND_SUNDAY: yup.boolean().notRequired(),
});

interface AutomaticScheduleProps {
  automaticAuthorizationScheduler: any;
  centerWithRegime: any[];
  onSubmit: (values: AutomaticAuthorizationScheduleProps) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  consolidationCenterCombo: any[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly?: boolean;
}

export default function AutomaticAuthorizationSchedule({
  automaticAuthorizationScheduler,
  centerWithRegime,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  consolidationCenterCombo,
  readOnly,
  controlEdit,
}: AutomaticScheduleProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(customRegimeScheme),
  });
  const [erroMap, setErroMap] = useState<string[]>([]);

  useEffect(() => {
    setErroMap(errors);
  }, [errors]);

  useEffect(() => {
    reset(automaticAuthorizationScheduler);
  }, [automaticAuthorizationScheduler, reset]);

  const handleFormSubmit = (values: any) => {
    setErroMap([]);
    const hasASelectDay = Object.keys(values).filter(
      (item) => values[item] === true
    );
    if (hasASelectDay.length === 0) {
      setErroMap(["You need a select at list one day"]);
      return;
    }

    onSubmit(values);
  };

  const handleCancel = () => {
    // reset({ : "" });
    setErroMap([]);
    onCancel();
  };

    const hourList = useMemo(() => {
        const hours = [];

        for (let hour = 1; hour <= 12; hour++) {
            const formatHour = hour.toString().padStart(2, "0");

            const id = `${formatHour}:00`;
            const label = `${formatHour}:00`;

            hours.push({ id, label });
        }

        return hours;
    }, []);

  const listPeriod = [
    {
      id: "AM",
      label: "AM",
    },
    {
      id: "PM",
      label: "PM",
    },
  ];

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit as any)}
      data-testid="customRegime-form"
    >
      {erroMap?.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="search"
        container
        spacing={2}
        marginTop={1}
        border={1}
        padding={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >
        <Grid
          marginBottom={5}
          container
          justifyContent="flex-start"
          spacing={2}
        >
          <Grid item xs={6}>
            <Controller
              defaultValue={undefined}
              control={control}
              render={({ field: { value, ref, onChange, ...field } }) => (
                <Autocomplete
                  options={centerWithRegime || []}
                  getOptionLabel={(option) => option.description}
                  disabled={readOnly || controlEdit.isInUse}
                  value={
                    centerWithRegime?.findLast((item) => item?.id === value) ||
                    null
                  }
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      {...field}
                      label="Company - Plant"
                      size="small"
                      inputRef={ref}
                      error={!!formErrors.ID_CENTRO}
                      disabled={readOnly || controlEdit.isInUse}
                      variant="outlined"
                    />
                  )}
                  onChange={(_: any, data: any) => {
                    onChange(data?.id || null);
                  }}
                />
              )}
              name="ID_CENTRO"
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              defaultValue={undefined}
              control={control}
              render={({ field: { value, ref, onChange, ...field } }) => (
                <Autocomplete
                  disabled={readOnly || controlEdit.isInUse}
                  options={consolidationCenterCombo || []}
                  getOptionLabel={(option) => option.description}
                  value={
                    consolidationCenterCombo?.findLast(
                      (item) => item?.id === value
                    ) || null
                  }
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      {...field}
                      label="Consolidation Center"
                      size="small"
                      inputRef={ref}
                      error={!!formErrors.ID_CENTRO_CONSOLIDADOR}
                      disabled={readOnly || controlEdit.isInUse}
                      variant="outlined"
                    />
                  )}
                  onChange={(_: any, data: any) => {
                    onChange(data?.id || null);
                  }}
                />
              )}
              name="ID_CENTRO_CONSOLIDADOR"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Controller
              defaultValue={undefined}
              control={control}
              render={({ field: { value, ref, onChange, ...field } }) => (
                <Autocomplete
                  disabled={readOnly}
                  options={hourList || []}
                  getOptionLabel={(option) => option.label}
                  value={
                    hourList?.findLast((item) => item?.id === String(value)) || null
                  }
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      {...field}
                      label="Time"
                      size="small"
                      inputRef={ref}
                      disabled={readOnly}
                      error={!!formErrors.HORA_AGEND}
                      variant="outlined"
                    />
                  )}
                  onChange={(_: any, data: any) => {
                    onChange(data?.id || null);
                  }}
                />
              )}
              name="HORA_AGEND"
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <Controller
              defaultValue={undefined}
              control={control}
              render={({ field: { value, ref, onChange, ...field } }) => (
                <Autocomplete
                  disabled={readOnly}
                  options={listPeriod || []}
                  getOptionLabel={(option) => option.label}
                  value={
                    listPeriod?.findLast(
                      (item) => item?.id === String(value)
                    ) || null
                  }
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      {...field}
                      label="Period"
                      size="small"
                      inputRef={ref}
                      error={!!formErrors.DSC_PERIODO}
                      disabled={readOnly}
                      variant="outlined"
                    />
                  )}
                  onChange={(_: any, data: any) => {
                    onChange(data?.id || null);
                  }}
                />
              )}
              name="DSC_PERIODO"
            />
          </Grid>
          <Grid item md={1} xs={2}>
            <Controller
              defaultValue={automaticAuthorizationScheduler.AGEND_MONDAY}
              render={({ field: { value } }) => (
                <>
                  <FormControlLabel
                    value={!!value}
                    disabled={readOnly}
                    control={
                      <Checkbox
                        checked={!!value}
                        disabled={readOnly}
                        {...register("AGEND_MONDAY")}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Monday"
                    labelPlacement="top"
                  />
                </>
              )}
              name="AGEND_MONDAY"
              control={control}
            />
          </Grid>
          <Grid item md={1} xs={2}>
            <Controller
              render={({ field: { value } }) => (
                <FormControlLabel
                  value={!!value}
                  disabled={readOnly}
                  control={
                    <Checkbox
                      checked={!!value}
                      disabled={readOnly}
                      {...register("AGEND_TUESDAY")}
                    />
                  }
                  label="Tuesday"
                  labelPlacement="top"
                />
              )}
              name="AGEND_TUESDAY"
              control={control}
            />
          </Grid>
          <Grid item md={1} xs={2}>
            <Controller
              render={({ field: { value } }) => (
                <FormControlLabel
                  value={!!value}
                  disabled={readOnly}
                  control={
                    <Checkbox
                      checked={!!value}
                      disabled={readOnly}
                      {...register("AGEND_WEDNESDAY")}
                    />
                  }
                  label="Wednesday"
                  labelPlacement="top"
                />
              )}
              name="AGEND_WEDNESDAY"
              control={control}
            />
          </Grid>
          <Grid item md={1} xs={2}>
            <Controller
              render={({ field: { value } }) => (
                <FormControlLabel
                  value={!!value}
                  disabled={readOnly}
                  control={
                    <Checkbox
                      checked={!!value}
                      disabled={readOnly}
                      {...register("AGEND_THURSDAY")}
                    />
                  }
                  label="Thursday"
                  labelPlacement="top"
                />
              )}
              name="AGEND_THURSDAY"
              control={control}
            />
          </Grid>
          <Grid item md={1} xs={2}>
            <Controller
              render={({ field: { value } }) => (
                <FormControlLabel
                  value={!!value}
                  disabled={readOnly}
                  control={
                    <Checkbox
                        checked={!!value}
                        disabled={readOnly}
                        {...register("AGEND_FRIDAY")}
                    />
                  }
                  label="Friday"
                  labelPlacement="top"
                />
              )}
              name="AGEND_FRIDAY"
              control={control}
            />
          </Grid>
          <Grid item md={1} xs={2}>
            <Controller
              render={({ field: { value } }) => (
                <FormControlLabel
                  value={!!value}
                  disabled={readOnly}
                  control={
                    <Checkbox
                      checked={!!value}
                      disabled={readOnly}
                      {...register("AGEND_SATURDAY")}
                    />
                  }
                  label="Saturday"
                  labelPlacement="top"
                />
              )}
              name="AGEND_SATURDAY"
              control={control}
            />
          </Grid>
          <Grid item md={1} xs={2}>
            <Controller
              render={({ field: { value } }) => (
                <FormControlLabel
                  value={!!value}
                  disabled={readOnly}
                  control={
                    <Checkbox
                        checked={!!value}
                        disabled={readOnly}
                        {...register("AGEND_SUNDAY")}
                    />
                  }
                  label="Sunday"
                  labelPlacement="top"
                />
              )}
              name="AGEND_SUNDAY"
              control={control}
            />
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={isLoading}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Grid>
            {!readOnly ? (
              <Grid marginLeft={2}>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  type="submit"
                  data-testid="submit-button"
                >
                  Confirm
                </Button>
              </Grid>
            ) : null}
        </Grid>
      </Grid>
    </form>
  );
}
