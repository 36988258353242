import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { getToken } from "../../storage/auth";
import { IncotermsType } from "../../types/incoterms";
import { List } from "../../types/resquest";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
}

const entity = "incoterms";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string
): { data: IncotermsType[]; meta: List; isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<IncotermsType[]>([{} as IncotermsType]);
  const [meta, setMeta] = useState<List>({} as List);

  useEffect(() => {
    if (token) {
      (async function () {
        setIsLoading(true);

        try {
          const response = await dataTableUtils.findPaginate(params);

          const {total, data, currentPage, totalPages, limit} = response.data;
          setMeta((prevState) => ({
            ...prevState,
            total,
            totalPages,
            limit,
            currentPage,
          }));
          setData(data);
          setIsLoading(false)
        } catch (error) {
            setIsLoading(false);
        }
      })();
    }
  }, [params, token]);

  return { data, meta, isLoading };
};

export const update = async (
  id: number | string,
  incoterm: IncotermsType
): Promise<IncotermsType> => {
  const token = getToken();
  const { data } = await api.patch(`/${entity}/${id}`, incoterm, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const isInUse = async (id: number | string): Promise<any> => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/isInUse/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const deleteIncoterms = async (
  id: number | string
): Promise<number> => {
  const { status } = await api.delete(`/${entity}/${id}`);

  return status;
};

export const create = async (
  incoterms?: IncotermsType
): Promise<IncotermsType> => {
  const token = getToken();
  const { data } = await api.post(`/${entity}`, incoterms, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: "Id", value: "ID_INCOTERMS" },
    { label: "Abbreviation", value: "SIGLA" },
    {
      label: "Incoterm Freight Type",
      value: "ID_FRETE_INCO",
    },
    {
      label: "Status",
      value: (row: any) => (row.ATIVO ? "Active" : "Inactive"),
    },
  ]
  await dataTableUtils.exportAll({params, xlsxData, filename: "Incoterms"});
};
