import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { Center } from "../../types/center";
import { List } from "../../types/resquest";
import { CustomRegimeCopy } from "../../types/customRegime";
import { DataTableUtils } from "../../components/DataTable/DataTableUtils";
import { SearchParams } from "../../types/dashboard";
import { ComboBox } from "../../types/comboBox";
import { ComboBoxType } from "../../types/general";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
}

const entity = "centers";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  isCombo?: boolean
):
  | Promise<{
    data: Center[];
    meta: List;
    error: Error;
    isLoading: boolean;
  }>
  | any => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Center[]>([]);
  const [meta, setMeta] = useState<List>({} as List);
  const [error, setError] = useState<Error>({} as Error);
  const [combo, setCombo] = useState<ComboBoxType[]>();
  const [comboShipment, setComboShipment] = useState<any>();
  const [comboSla, setComboSla] = useState<any>();
  const [freightForwarderRule, setFreightForwarderRule] = useState<any>();
  const [comboCargoTreiatments, setComboCargoTreatments] = useState<any>();
  const [comboRegime, setComboRegime] = useState<CustomRegimeCopy>(
    {} as CustomRegimeCopy
  );

  const [comboLocationBin, setComboLocationBin] = useState();

  const render = () => {
      if (isCombo === false || isCombo === undefined) {
        return "pagination";
      }
  };

  useEffect(() => {
    if (render() === "pagination") {
      (async function () {
        try {
            setIsLoading(true);
            const response = await dataTableUtils.findPaginate(params);
            if(response === undefined) return;

            const { total, data, currentPage, totalPages, limit } = response.data;
            setData(data);
            setMeta((prevState) => ({
                ...prevState,
                total,
                totalPages,
                limit,
                currentPage,
            }));
            setIsLoading(false);
        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
      })();
    }
  }, [isCombo, params]);

  useEffect(() => {
    if (isCombo === true) {
      setIsLoading(true);
      api
        .get(`/${entity}/getCentersWithCompaniesCombo`, {
          params,
        })
        .then((response) => {
          const { data } = response;
          setCombo(data);
        })
        .catch(setError)
        .finally(() => setIsLoading(false));
    }
  }, [isCombo, params]);

  useEffect(() => {
    if (isCombo === true) {
      setIsLoading(true);
      api
        .get(`/${entity}/getCenters/shipmentInstruction`, {
          params,
        })
        .then((response) => {
          const { data } = response;
          setComboShipment(data);
        })
        .catch(setError)
        .finally(() => setIsLoading(false));
    }
  }, [isCombo, params]);


  useEffect(() => {
    if (isCombo === true) {
      setIsLoading(true);
      api
        .get(`/${entity}/getCenters/freightForwarderRules`, {
          params,
        })
        .then((response) => {
          const { data } = response;
          setFreightForwarderRule(data);
        })
        .catch(setError)
        .finally(() => setIsLoading(false));
    }
  }, [isCombo, params]);

  useEffect(() => {
    if (isCombo === true) {
      setIsLoading(true);
      api
        .get(`/customsRegimes/getCombo`, {
          params,
        })
        .then((response) => {
          const { data } = response;
          setComboRegime(data);
        })
        .catch(setError)
        .finally(() => setIsLoading(false));
    }
  }, [isCombo, params]);

  useEffect(() => {
    if (isCombo === true) {
      setIsLoading(true);
      api
        .get(`/${entity}/getCenters/cargoTreatment`, {
          params,
        })
        .then((response) => {
          const { data } = response;
          setComboCargoTreatments(data);
        })
        .catch(setError)
        .finally(() => setIsLoading(false));
    }
  }, [isCombo, params]);

  useEffect(() => {
    if (isCombo === true) {
      setIsLoading(true);
      api
        .get(`/${entity}/getCenters/locationBin`, {
          params,
        })
        .then((response) => {
          const { data } = response;
          setComboLocationBin(data);
        })
        .catch(setError)
        .finally(() => setIsLoading(false));
    }
  }, [isCombo, params]);

  useEffect(() => {
    if (isCombo === true) {
      setIsLoading(true);
      api
        .get(`/${entity}/getCenters/sla`, {
          params,
        })
        .then((response) => {
          const { data } = response;
          setComboSla(data);
        })
        .catch(setError)
        .finally(() => setIsLoading(false));
    }
  }, [isCombo, params]);

  return {
    data,
    combo,
    meta,
    error,
    isLoading,
    comboRegime,
    comboSla,
    freightForwarderRule,
    comboShipment,
    comboCargoTreiatments,
    comboLocationBin,
  };
};

export const getCenters = async (ID_EMPRESA: number[]): Promise<Center[]> => {
  const { data } = await api.get(`/centers/getAll`, {
    params: { ID_EMPRESA }
  });

  return data;
};

export const update = async (id: number, center?: Center): Promise<Center> => {
  try {
    const { data } = await api.patch(`/${entity}/${id}`, center);

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const create = async (center?: Center): Promise<Center> => {
  try {
    const { data } = await api.post(`/${entity}`, center);

    return data;
  } catch (error) {
    console.log(error.response.data);
    return error.response.data;
  }
};


export const isInUse = async (id: number | string): Promise<any> => {
  const { data } = await api.get(`/${entity}/isInUse/${id}`);

  return data;
};

export const deleteCenter = async (id: number): Promise<boolean> => {
  const { data } = await api.delete(`/${entity}/${id}`);

  return data;
};

export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: "Id", value: "ID_CENTRO" },
    { label: "Name", value: "NOME_CENTRO" },
    { label: "Abbreviation", value: "DSC_SIGLA" },
    { label: "Address", value: "DSC_ENDERECO" },
    { label: "State", value: "DSC_ESTADO" },
    { label: "City", value: "DSC_CIDADE" },
    { label: "Company abbreviation", value: "GE_SIGLA_EMPRESA" },
    { label: "Company description", value: "GE_DSC_EMPRESA" },
    { label: "Country abbreviation", value: "GP_SIGLA_PAIS" },
    { label: "Country description", value: "GP_NOME_PAIS" },
    { label: "Document", value: "DSC_CNPJ" },
    { label: "Postal code", value: "DSC_CX_POSTAL" },
    { label: "MFIR", value: "COD_MFIR" },
    {
      label: "Vss",
      value: (row: Center) => (row.IND_PLANTA_VSS ? "Yes" : "No"),
    },
    {
      label: "Blocked",
      value: (row: Center) => (row.IND_PLANTA_BLOQUEADA ? "Yes" : "No"),
    },
    {
      label: "Validate OGTM",
      value: (row: Center) => (row.IND_LICENCA_OGTM ? "Yes" : "No"),
    },
  ]
  await dataTableUtils.exportAll({ params, xlsxData, filename: "Center" });
};

export const getCentersWithCompaniesCombo = async (filters?: SearchParams): Promise<ComboBox[]> => {
  const { data } = await api.get(`/${entity}/getCentersWithCompaniesCombo`, {
    params: filters
  });

  return data;
};
