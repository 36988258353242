import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Chip } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import DataTableDefault from "../../../../../components/DataTable";
import { ConsolidationCenter } from "../../../../../types/consolidationCenter";
import { hasClaim } from "../../../../../storage/userClaims";

interface DataTableProps {
  onEditConsolidationCenter: (id: number | string) => void;
  onDeleteConsolidationCenter: (id: number | string) => void;
  data: ConsolidationCenter[];
  [x: string]: any;
}
export default function DataTable({
  onEditConsolidationCenter,
  onDeleteConsolidationCenter,
  onViewConsolidationCenter,
  data,
  airPorts,
  countries,
  userValidation,
  ...props
}: DataTableProps) {
  const renderMenus = (row: any) => {
    return [
      <GridActionsCellItem
        sx={{
          display:
            hasClaim("Consolidation Center - Maintenance") ||
            hasClaim("Consolidation Center - Visualization")
              ? "block"
              : "none",
        }}
        key={`view-${row.ID_CENTRO_CONSOLIDADOR}`}
        icon={<VisibilityIcon />}
        label="View"
        onClick={() => onViewConsolidationCenter(row.ID_CENTRO_CONSOLIDADOR)}
        color="inherit"
        data-testid="view-button"
      />,
      <GridActionsCellItem
        sx={{
          display: hasClaim("Consolidation Center - Maintenance")
            ? "block"
            : "none",
        }}
        key={`edit-${row.ID_CENTRO_CONSOLIDADOR}`}
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={() => onEditConsolidationCenter(row.ID_CENTRO_CONSOLIDADOR)}
        color="inherit"
        data-testid="edit-button"
      />,
      <GridActionsCellItem
        sx={{
          display: hasClaim("Consolidation Center - Maintenance")
            ? "block"
            : "none",
        }}
        key={`delete-${row.ID_CENTRO_CONSOLIDADOR}`}
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => onDeleteConsolidationCenter(row.ID_CENTRO_CONSOLIDADOR)}
        color="inherit"
        data-testid="delete-button"
      />,
    ];
  };

  const columns: GridColDef[] = [
    {
      field: "NOME",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "country_NOME_PAIS",
      headerName: "Country",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `${params.row.country_SIGLA_PAIS} - ${params.row.country_NOME_PAIS}`;
      },
    },
    {
      field: "ATIVO",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Date>) =>
          params.value ? (
              <Chip label="Active" color="primary" />
          ) : (
              <Chip label="Inactive" variant="outlined" />
          ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }) => renderMenus(row),
    },
  ];
  return (
    <>
      <DataTableDefault
        rowId={data.map((row) => row.ID_CENTRO_CONSOLIDADOR)}
        data-testid="consolidation-center-data-table"
        data={data}
        columns={columns}
        {...props}
      />
    </>
  );
}
