import * as yup from "yup";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChangeEvent, useEffect, useState, InputHTMLAttributes } from "react";
import {
  AccordionDetails,
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  Webgroup,
  FreightForwarder,
  ListPermissionsClass,
  Permission,
} from "../../../../../types/accessControl";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionSummary } from "../../../../../components/Accordion/AccordionSummary";
import { Accordion } from "../../../../../components/Accordion/Accordion";

let accessControlScheme = yup.object({
  DSC_WEBGROUP: yup.string().required(),
});

interface AccessControlFormProps {
  readOnly: boolean;
  accessControl: Webgroup;
  onSubmit: (accessControl: Webgroup) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  permissionsClass: ListPermissionsClass[];
  setPermissionsClass: (permissionClass :ListPermissionsClass[]) => void;
  freightForwarder: FreightForwarder[];
  listFreightForwarder: FreightForwarder[];
  setListFreightForwarder: (freightForwarder: FreightForwarder[]) => void;
}

export default function AccessControlForm({
  readOnly,
  accessControl,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  permissionsClass,
  setPermissionsClass,
  freightForwarder,
  listFreightForwarder,
  setListFreightForwarder,
}: AccessControlFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [freightForwarderSelected, setFreightForwarderSelected] =
    useState<string>("");
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
  } = useForm({
    resolver: yupResolver(accessControlScheme),
  });

  useEffect(() => {
    reset({
      ID_WEBGROUP: accessControl.ID_WEBGROUP,
      DSC_WEBGROUP: accessControl.DSC_WEBGROUP ?? '',
    });
  }, [accessControl, reset]);

  const handleFormSubmit = (newAccessControl: any) => {
    let foundOneActive = false;
    const funcionality: Permission[] = [];
    permissionsClass.forEach((permissions) => {
      permissions.functionality.forEach((functionality) => {
        foundOneActive = true;
        funcionality.push(functionality);
      })
    })

    if(!foundOneActive) {
      enqueueSnackbar("You must select at least one permission", {
        variant: "error",
      });
      return;
    }
    newAccessControl.functionalities = funcionality;
    newAccessControl.freight_forwarder = listFreightForwarder;
    onSubmit(newAccessControl);
  };

  const handleCancel = () => {
    onCancel();
  };

  const addFreightForwarder = () => {
    if (!freightForwarderSelected) {
      return;
    }
    const localList: FreightForwarder[] = [...listFreightForwarder];
    const found = localList.find(
      (localItem) => localItem.id === parseInt(freightForwarderSelected)
    );
    if (found) {
      enqueueSnackbar("Has this Freight Forwarder already been selected", {
        variant: "error",
      });
      return;
    }
    const foundToAdd = freightForwarder.find(
      (freightForwarderOption) =>
        freightForwarderOption.id === parseInt(freightForwarderSelected)
    );
    if (freightForwarder && foundToAdd) {
      localList.push(foundToAdd);
      setListFreightForwarder(localList);
    }
  };

  const handleFreightForwarder = (item: SelectChangeEvent) => {
    setFreightForwarderSelected(item.target.value);
  };

  const handlePermissionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const changedPermissions = permissionsClass.map((permission) => {
      permission.functionality = permission?.functionality.map((funcionality) => {
        if (funcionality.ID_FUNCIONALIDADE === parseInt(event.target.value)) {
          funcionality.ATIVO = event.target.checked;
        }
        return funcionality;
      });
      return permission;
    })
    setPermissionsClass(changedPermissions);
  }

  const deleteOnList = (id: number) => {
    const localList: FreightForwarder[] = [...listFreightForwarder];
    const filteredList = localList.filter((localItem) => localItem.id !== id);
    setListFreightForwarder(filteredList);
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="accessControl-form"
    >
      {errors.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid container marginBottom={4}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Webgroup"
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
            error={!!formErrors.DSC_WEBGROUP}
            inputProps={{ 'data-testid': 'DSC_WEBGROUP-input' }}
            disabled={readOnly}
            {...register("DSC_WEBGROUP")}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12}>
          <Box>
            {permissionsClass.map((permissions) => (
              <Accordion key={`acordion-${permissions.ID_CLASSE_FUNCIONALIDADE}`}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  hasData
                >
                  <Typography>{permissions.DESCRICAO}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List dense={true}>
                    <Grid container>
                      {permissions.functionality.map((permission) => (
                        <Grid
                          key={`permission${permission.ID_FUNCIONALIDADE}`}
                          lg={6} md={6} sm={12} xs={12}
                          item
                        >
                          <ListItem>
                            <Grid container alignItems="center">
                              <Grid item xs={8}>
                                <ListItemText primary={permission.DESCRICAO} />
                              </Grid>
                              <Grid item xs={4}>
                                <Switch
                                  value={permission.ID_FUNCIONALIDADE}
                                  checked={permission.ATIVO}
                                  onChange={handlePermissionChange}
                                  disabled={readOnly}
                                  inputProps={
                                    { 'data-testid': `switch-${permission.ID_FUNCIONALIDADE}` } as InputHTMLAttributes<HTMLInputElement>
                                  }
                                />
                              </Grid>
                            </Grid>
                          </ListItem>
                        </Grid>
                      ))}
                    </Grid>
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Grid>
      </Grid>
      <Grid marginTop={6} container>
        <Grid item lg={4} sm={6} xs={8}>
          <FormControl size="small" fullWidth>
            <InputLabel id="freight-forwarder-label">
              Freight Forwarder
            </InputLabel>
            <Select
              labelId="freight-forwarder-label"
              label="Freight Forwarder"
              autoWidth
              value={freightForwarderSelected}
              onChange={handleFreightForwarder}
            >
              {freightForwarder.map((freightForwarderOption) => (
                <MenuItem
                  key={`freight-forwarder-${freightForwarderOption.id}`}
                  value={freightForwarderOption.id}
                >
                  {freightForwarderOption.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {!readOnly && <Button
          sx={{ marginLeft: "16px" }}
          variant="outlined"
          onClick={addFreightForwarder}
        >
          <AddIcon fontSize="small" />
        </Button>
        }
      </Grid>
      <Grid marginTop={2} container>
        <Grid item xs={4}>
          <Table sx={{ minWidth: { md: 650, xs: 350 }}} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Freight Forwarder</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listFreightForwarder.map((freightForwarder) => (
                <TableRow
                  key={freightForwarder.description}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {freightForwarder.description}
                  </TableCell>
                  <TableCell align="right">
                    {!readOnly && (
                      <Button
                        color="error"
                        onClick={() => deleteOnList(freightForwarder.id)}
                      >
                        <DeleteIcon />
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>

      <Grid container marginTop={2} justifyContent="flex-end" spacing={2}>
        <Grid>
          <Button
            variant="outlined"
            onClick={handleCancel}
            disabled={isLoading}
            data-testid="cancel-button"
          >
            Cancel
          </Button>
        </Grid>
        {!readOnly &&<Grid marginLeft={2}>
          <Button
            variant="contained"
            disabled={isLoading}
            type="submit"
            data-testid="submit-button"
          >
            Confirm
          </Button>
        </Grid>}
      </Grid>
      <Divider sx={{ marginTop: 4 }} />
    </form>
  );
}
