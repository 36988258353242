import { getSession } from "../storage/auth";
import { useEffect, useState } from "react";

export function isSessionValid(session): boolean {
    return !!(session && session.exp && session.exp * 1000 > new Date().getTime());
}

export default function useMiddleware({ route }: { route: string }): {
    isLogged: boolean;
    isLoading: boolean;
} {
    const [isLoading, setIsLoading] = useState(true);
    const [isLogged, setIsLogged] = useState(false); // Inicia com falso para indicar que o usuário não está logado inicialmente

    useEffect(() => {
        const checkSession = async () => {
            setIsLoading(true);
            try {
                const session = await getSession(); // Aguarda a resolução da sessão
                setIsLogged(isSessionValid(session));
            } catch (error) {
                setIsLogged(false); // Se ocorrer erro, assume que o usuário não está logado
            }
            setIsLoading(false);
        };

        checkSession();
    }, [route]);

    return { isLogged, isLoading };
}
