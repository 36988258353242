import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import DataTableDefault from "../../../../../components/DataTable";
import { PartnerPaginate } from "../../../../../types/partner";
import { hasClaim } from "../../../../../storage/userClaims";

interface DataTableProps {
  onEdit: (id: number | string) => void;
  onDelete: (id: number | string) => void;
  onView: (id: number | string) => void;
  data: PartnerPaginate[];
  loading: boolean;
  [x: string]: any;
}
export default function DataTable({
  onEdit,
  onDelete,
  onView,
  data,
  loading,
  ...props
}: DataTableProps) {
  const renderMenus = (row: any) => {
    return [
      <GridActionsCellItem
        sx={{
          display:
            hasClaim("Partner - Maintenance") ||
            hasClaim("Partner - Visualization")
              ? "block"
              : "none",
        }}
        key={`view-${row.ID_PARCEIRO}`}
        icon={<VisibilityIcon />}
        label="View"
        onClick={() => onView(row.ID_PARCEIRO)}
        color="inherit"
        data-testid="view-button"
      />,
      <GridActionsCellItem
        sx={{ display: hasClaim("Partner - Maintenance") ? "block" : "none" }}
        key={`edit-${row.ID_PARCEIRO}`}
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={() => onEdit(row.ID_PARCEIRO)}
        color="inherit"
        data-testid="edit-button"
      />,
      <GridActionsCellItem
        sx={{ display: hasClaim("Partner - Maintenance") ? "block" : "none" }}
        key={`delete-${row.ID_PARCEIRO}`}
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => onDelete(row.ID_PARCEIRO)}
        color="inherit"
        data-testid="delete-button"
      />,
    ];
  };

  const columns: GridColDef[] = [
    {
      field: "NOME_PARCEIRO",
      headerName: "Partner",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `${params.row.NOME_PARCEIRO}`;
      },
    },
    {
      field: "ID_TIPO_PARCEIRO",
      headerClassName: "super-app-theme--header",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "GC_NOME",
      headerClassName: "super-app-theme--header",
      headerName: "Consolidation Center",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }) => renderMenus(row),
    },
  ];
  return (
    <>
      <DataTableDefault
        rowId={data?.map((row) => row.ID_PARCEIRO)}
        data={data}
        columns={columns}
        loading={loading}
        {...props}
      />
    </>
  );
}
