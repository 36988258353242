import { useEffect, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Box, IconButton, MenuItem, Select, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

import DataTableDefault from "../../../../../../components/DataTable";
import { getBINSCombo, updatePiece } from "../../../../../../services/triage";
import { enqueueSnackbar } from "notistack";
import { isAxiosError } from "axios";
import { Piece } from "../../types/pieces";
import { getUserClaim } from "../../../../../../storage/userClaims";
import { TriageStatus } from "../../types/triage";

const disableStatus = 'Waiting to Move to Receiving';

interface DataTableProps {
  data: Piece[];
  triageStatus: string;
  triageCenter: number;
  [x: string]: any;
}
export default function DataTablePieces({
  data = [],
  triageStatus,
  triageCenter,
  ...props
}: DataTableProps) {
  const userClaims = getUserClaim();
  const [editRowId, setEditRowId] = useState<number | null>();
  const [locationValue, setLocationValue] = useState("");
  const [idLocationBin, setIdLocationBin] = useState<number>();
  const [locationBinDescription, setLocationBinDescription] = useState<string>();
  const [binsCombo, setBinsCombo] = useState<{ id: number, description: string }[]>([]);

  useEffect(() => {
    if (triageCenter) handleLoadBinsCombo()
  }, [triageCenter]);

  const isEnabled = useMemo(() => {
    if (triageStatus === TriageStatus.WAITING_ATTACHMENTS) {
      return userClaims.some((claim) => ["Triage - Register", "Triage - Analysis"].includes(claim));
    }

    if (triageStatus === TriageStatus.UNDER_ANALYSIS) {
      return userClaims.some((claim) => ["Triage - Register", "Triage - Analysis"].includes(claim));
    }

    if (triageStatus === TriageStatus.MISSING_INVOICE) {
      return userClaims.some((claim) => ["Triage - Register", "Triage - Analysis", "Triage - Buyer"].includes(claim));
    }

    if ([TriageStatus.UNDER_LOGISTICS_ANALYSIS, TriageStatus.PENDING].includes(triageStatus as TriageStatus)) {
      return true;
    }

    return false;
  }, [userClaims, triageStatus]);

  const handleLoadBinsCombo = async () => {
    try {
      const response = await getBINSCombo({ ID_CENTRO: triageCenter });

      setBinsCombo(response);
    } catch (error) {
      if (isAxiosError(error)) {
        enqueueSnackbar(`${error?.response?.data?.message}`, { variant: "error" });
      }
    }
  }

  const handleEditClick = (id: number, currentValue: string) => {
    setEditRowId(id);
    setLocationValue(currentValue);
  };

  const handleLocationChange = (event: any) => {
    const id = event.target.value;

    setIdLocationBin(id);
    setLocationValue(id);

    const bin = binsCombo.filter(bin => bin.id === id);

    setLocationBinDescription(bin[0].description);
  };

  const handleSave = async (currentRow: Piece) => {
    try {
      currentRow.DSC_LOCATION = locationBinDescription
      currentRow.ID_LOCATION_BIN = idLocationBin

      await updatePiece({
        ID_TRIAGEM_VOLUME: editRowId,
        ID_LOCATION_BIN: idLocationBin,
        DSC_LOCATION: locationBinDescription
      });

      setEditRowId(null);
    } catch (error) {
      enqueueSnackbar("Error to update Location", { variant: "error" });
    }
  };

  const handleCancel = () => {
    setEditRowId(null);
  };

  const columns: GridColDef[] = [
    {
      field: "edit",
      headerName: "",
      headerClassName: "super-app-theme--header",
      flex: editRowId ? 0.6 : 0.4,
      renderCell: (params) => (
        triageStatus !== disableStatus &&
          editRowId === params.row?.ID_TRIAGEM_VOLUME ? [
          <IconButton disabled={!isEnabled} onClick={() => handleSave(params.row)}>
            <SaveIcon color="success" />
          </IconButton>,
          <IconButton disabled={!isEnabled} onClick={handleCancel}>
            <CloseIcon color="error" />
          </IconButton>
        ] : (
          <IconButton
            disabled={!isEnabled}
            onClick={() => handleEditClick(params.row.ID_TRIAGEM_VOLUME, params.row.DSC_LOCATION)}>
            <EditIcon />
          </IconButton>
        )
      )
    },
    {
      field: "DSC_REFERENCIA_VOL",
      headerName: "Reference",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "NUM_VOL",
      headerName: "Pieces",
      headerClassName: "super-app-theme--header",
      flex: 0,
    },
    {
      field: "DSC_LOCATION",
      headerName: "Bin",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params) => {
        if (editRowId && editRowId === params.row.ID_TRIAGEM_VOLUME && triageStatus !== disableStatus) {
          const isLocationBinID = typeof locationValue !== 'number';
          return (
            <Box maxWidth={250} display="flex">
              <Select
                value={isLocationBinID ? params?.row?.ID_LOCATION_BIN : locationValue}
                onChange={handleLocationChange}
                autoFocus
                fullWidth
                sx={{ width: '100%' }}
              >
                {binsCombo.length > 0 && binsCombo.map((bin, index) => (
                  <MenuItem key={index} value={bin.id}>{bin.description}</MenuItem>
                ))}
              </Select>
            </Box>
          );
        }

        return [params.row.DSC_LOCATION]
      },
    },
  ];

  return (
    <>
      <Typography marginBottom={2} fontWeight="bold">Pieces</Typography>
      <DataTableDefault
        rowId={data?.map((row) => row.ID_TRIAGEM_VOLUME)}
        data-testid="unit-data-table"
        data={data}
        columns={columns}
        {...props}
      />
    </>
  );
}
