import React from "react";
import { styled } from "@mui/material";
import { GridActionsCellItem, GridColDef, DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { InvoicePackageDetail } from "../../../../../types/packageDetail";
import { PackageTypesProps } from "../../../../../types/packageType";
import { UnitMeasurement } from "../../../../../types/unitMeasurement";

interface DataTableProps {
  invoicePackages: InvoicePackageDetail[]
  packageTypes: PackageTypesProps[]
  unitOfMeasurements: UnitMeasurement[]
  onDelete: (id: number) => void
  isDisabled: boolean
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  borderRadius: 0,
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#D3D3D3',
    color: 'black',
    fontSize: '1rem',
    fontWeight: 'bold',
    borderRadius: 0,
  },
  '& .MuiDataGrid-cell': {
    border: `0 ${theme.palette.divider}`,
  },
  '& .MuiDataGrid-row': {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
  },
}))



export const InvoicePackageTable: React.FC<DataTableProps> = ({
  invoicePackages,
  packageTypes,
  unitOfMeasurements,
  onDelete,
  isDisabled,
}) => {
  const packageColumns: GridColDef[] = [
    { field: 'DESC_TIPO_PACKAGE', headerName: 'Package Type', flex: 1 },
    { field: 'NUM_PESO_BRUTO', headerName: 'Gross Weight', flex: 1 },
    { field: 'NUM_COMPRIMENTO', headerName: 'Length', flex: 1 },
    { field: 'NUM_LARGURA', headerName: 'Width', flex: 1 },
    { field: 'NUM_ALTURA', headerName: 'Height', flex: 1 },
    { field: 'NUM_PESO_CUBICO', headerName: 'Cubic Weight', flex: 1 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label='Delete'
          style={{ color: 'red' }}
          onClick={() => onDelete(params.id as number)}
          disabled={isDisabled}
        />,
      ],
    },
  ]

  const formatNumber = (number: number, ID_UM: number, fixed: number = 2): string => {
    const unit = unitOfMeasurements.find((um) => um.ID_UM === ID_UM)

    let formattedNumber = Number(number) % 1 === 0
      ? Number(number).toFixed(0)
      : Number(number).toFixed(fixed)
  
    return formattedNumber.replace('.', ',') + ' ' + unit?.SIGLA?.toLowerCase()
  }

  const packageRows = invoicePackages?.map(
    (pkg: InvoicePackageDetail) => ({
      ID_INVOICE_PACKAGE: pkg.ID_INVOICE_PACKAGE,
      DESC_TIPO_PACKAGE: packageTypes.find(
        (type) => type.ID_TIPO_PACKAGE === pkg.ID_TIPO_PACKAGE
      )?.DSC_TIPO_PACKAGE,
      NUM_PESO_BRUTO: formatNumber(pkg.NUM_PESO_BRUTO, pkg.ID_UM_PESO_BRUTO!),
      NUM_COMPRIMENTO: formatNumber(pkg.NUM_COMPRIMENTO, pkg.ID_UM_COMPRIMENTO!),
      NUM_LARGURA: formatNumber(pkg.NUM_LARGURA, pkg!.ID_UM_LARGURA!),
      NUM_ALTURA: formatNumber(pkg.NUM_ALTURA, pkg!.ID_UM_ALTURA!),
      NUM_PESO_CUBICO: formatNumber(pkg.NUM_PESO_CUBICO, unitOfMeasurements.find(item => item.SIGLA === 'KG')?.ID_UM!, 3),
    })
  )

  return (
    <div style={{ minHeight: 100, width: '100%' }}>
      <StyledDataGrid
        rows={packageRows}
        columns={packageColumns}
        getRowId={(row) => row.ID_INVOICE_PACKAGE}
        hideFooterPagination
        sx={{
          boxShadow: 0,
          border: 0,
        }}
      />
    </div>
  )
}
