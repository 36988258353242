import {api} from "../../lib/api";
import xlsx from "json-as-xlsx";

export class DataTableUtils {
    private entity: string;

    constructor(entity: string) {
        this.entity = entity;
    }

    findPaginate = async (params: any) => {
        return api.get(`/${this.entity}/${params.url ?? 'findPaginate'}`, {
            params,
        });
    }

    exportAll = async ({params, xlsxData, filename, url, receivedData } : {
        params: any,
        xlsxData: any,
        filename: string,
        url?: string,
        receivedData?: any
    }) => {
        if(!receivedData) {
            const { data } = await this.findPaginate({
                ...params,
                page: 1,
                limit: 9999,
                url
            });

            receivedData = data.data;
        }

        let settings = {
            fileName: filename,
            extraLength: 3,
            writeMode: "writeFile",
            writeOptions: {},
            RTL: false,
        };

        xlsx([{
            columns: xlsxData,
            sheet: filename,
            content: receivedData,
        }], settings);
    }
}