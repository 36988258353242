import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Autocomplete,
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import * as yup from "yup";

import { ConsolidationCenter } from "../../../../../types/consolidationCenter";

let consolidationCenterScheme = yup.object({
  NOME: yup.string().max(100).required(),
  CODIGO_EDI: yup.string().max(6).required(),
  ID_PAIS: yup.number().required(),
  ID_AEROPORTO: yup.number().required(),
  ATIVO: yup.boolean().notRequired(),
  FLAG_INTERCOMPANY: yup.boolean().notRequired(),
  airport_DSC_AEROPORTO: yup.string().notRequired(),
  airport_ID_AEROPORTO: yup.number().notRequired(),
  country_NOME_PAIS: yup.string().notRequired(),
  country_SIGLA_PAIS: yup.string().notRequired(),
});

interface ConsolidationCentersFormProps {
  consolidationCenter: any;
  airport: any[];
  countries: any[];
  onSubmit: (consolidationCenter: ConsolidationCenter) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly?: boolean;
  hasDefaultData?: boolean;
}

export default function ConsolidationCenterForm({
  consolidationCenter,
  airport,
  countries,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  controlEdit,
  readOnly,
  hasDefaultData
}: ConsolidationCentersFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(consolidationCenterScheme),
  });

  useEffect(() => {
    if (consolidationCenter.hasOwnProperty("CODIGO_EDI")) {
      reset(consolidationCenter);
    }
  }, [consolidationCenter, reset]);

  const handleFormSubmit = (consolidationCenter: any) => {
    onSubmit(consolidationCenter);
  };

  const handleCancel = () => {
    reset({ NOME: "", ATIVO: true });
    onCancel();
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [tempSwitchValue, setTempSwitchValue] = useState(false);
  const [defaultData] = useState(hasDefaultData);

  const handleSwitchChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean) | null | undefined; }; }) => {
    if(defaultData && event.target.checked) {
      setTempSwitchValue(event.target.checked);
      setOpenDialog(true);
    } else {
      setValue('FLAG_INTERCOMPANY', !!event.target.checked);
    }
  };

  const handleCloseDialog = (agree: boolean) => {
    setOpenDialog(false);
    if (agree) {
      setValue('FLAG_INTERCOMPANY', tempSwitchValue);
    }
  };

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="consolidationCenter-form"
    >
      {errors?.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="search"
        container
        spacing={2}
        marginTop={1}
        border={1}
        padding={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >
        <Grid container spacing={2} marginBottom={2}>
          <Grid item md={12} xs={12}>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="Name"
                  size="small"
                  variant="outlined"
                  defaultValue={value}
                  disabled={controlEdit.isInUse || readOnly}
                  error={!!formErrors.NOME}
                  InputLabelProps={{ shrink: !!value }}
                  {...register("NOME")}
                  inputProps={{ maxLength: 100 }}
                />
              )}
              name="NOME"
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="EDI Code"
                  size="small"
                  variant="outlined"
                  defaultValue={value}
                  disabled={controlEdit.isInUse || readOnly}
                  error={!!formErrors.CODIGO_EDI}
                  InputLabelProps={{ shrink: !!value }}
                  {...register("CODIGO_EDI")}
                  inputProps={{ maxLength: 6 }}
                />
              )}
              name="CODIGO_EDI"
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <Controller
              defaultValue={
                consolidationCenter.airport_ID_AEROPORTO || undefined
              }
              control={control}
              render={({ field: { value, ref, onChange, ...field } }) => (
                <Autocomplete
                  options={airport || []}
                  getOptionLabel={(option) => `${option.description}`}
                  disabled={readOnly}
                  value={airport.findLast((item) => item.id === value) || null}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      {...field}
                      label="Origin airport"
                      disabled={readOnly}
                      size="small"
                      inputRef={ref}
                      error={!!formErrors.ID_AEROPORTO}
                      variant="outlined"
                    />
                  )}
                  onChange={(_: any, data: any) => onChange(data?.id)}
                />
              )}
              name="ID_AEROPORTO"
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <Controller
                name="ID_PAIS"
                control={control}
                defaultValue={consolidationCenter.ID_PAIS || null}
                render={({ field: { value, onChange, ref, ...field } }) => (
                    <Autocomplete
                        options={countries || []}
                        getOptionLabel={(option) => `${option.description}`}
                        getOptionSelected={(option, value) => option.id === value}
                        value={countries.find((item) => item.id === value) || null}
                        onChange={(_, data) => onChange(data?.id || null)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                {...field}
                                label="Select country"
                                size="small"
                                inputRef={ref}
                                disabled={controlEdit.isInUse || readOnly}
                                error={!!formErrors.ID_PAIS}
                                variant="outlined"
                            />
                        )}
                        disabled={controlEdit.isInUse || readOnly}
                    />
                )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              defaultValue={consolidationCenter.ATIVO || false}
              render={({ field: { value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!value}
                      disabled={readOnly}
                      inputProps={{ "aria-label": "controlled" }}
                      {...register("ATIVO")}
                    />
                  }
                  labelPlacement="start"
                  label="Status"
                />
              )}
              name="ATIVO"
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" marginTop={1} spacing={2}>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={isLoading}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          {!readOnly ? (
          <Grid marginLeft={2}>
            <Button
              variant="contained"
              disabled={isLoading}
              type="submit"
              data-testid="submit-button"
            >
              Confirm
            </Button>
          </Grid>
          ) : null}
        </Grid>
      </Grid>

      <Dialog
          open={openDialog}
          onClose={() => handleCloseDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Change Intercompany Default (EDI)"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <>
              There is already a default defined to <strong>{defaultData?.NOME}</strong>
              <br/><br/>
              Do you want to change it? It will not take effect until the change is saved.
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog(false)}>Cancel</Button>
          <Button onClick={() => handleCloseDialog(true)} autoFocus>Confirm</Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
