export enum HistoryTypes {
  INVOICE = 'Invoice',
  DISCREPANCY = 'Discrepancy',
  PACKAGE = 'Package',
  WAREHOUSE = 'Warehouse',
  QUARENTINE = 'Quarentine',
  INVOICE_ITEM = 'Invoice item'
}

export type HistoryTypesType = keyof typeof HistoryTypes;

export type InvoiceHistoryService = {
  ID_INVOICE_HISTORICO: number;
  ID_INVOICE: number;
  DATA_OCORRENCIA: Date;
  ID_TIPO_ACAO: string;
  JSON_ANTIGO: any;
  JSON_NOVO: any;
  ID_LOGIN_USUARIO: number;
  LOG_INVOICE_MIGRADA: string;
  LEGADO: boolean;
  JUSTIFICATIVA: string;
  ID_TIPO_HISTORICO_INVOICE: HistoryTypesType;
};
