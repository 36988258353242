import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Button, Grid, TextField, Alert } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { useList } from "../../../../../services/companies";
import { CompanyCombo } from "../../../../../types/company";

let consolidationCenterScheme = yup.object({
  NOME_CENTRO: yup.string().notRequired(),
  ID_EMPRESA: yup.number().notRequired(),
  DSC_SIGLA: yup.string().notRequired(),
  searchGeneral: yup.string().notRequired(),
  IND_PLANTA_VSS: yup.boolean().notRequired(),
  IND_PLANTA_BLOQUEADA: yup.boolean().notRequired(),
  IND_LICENCA_OGTM: yup.boolean().notRequired(),
});

interface ISearch {
  onChange: (parmas: IParams) => void;
}

interface IParams {
  NOME_CENTRO: string;
  ID_EMPRESA: number;
  SIGLA_CENTRO: string;
  IND_PLANTA_VSS: boolean;
  IND_PLANTA_BLOQUEADA: boolean;
  IND_LICENCA_OGTM: boolean;
  DSC_SIGLA?: string;
  searchGeneral?: string;
}

export default function Search({ onChange }: ISearch) {
  const [errors] = useState([]);
  const { data: companies } = useList();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    control,
  } = useForm({
    resolver: yupResolver(consolidationCenterScheme),
  });

  const blocked = [
    {
      label: "Blocked",
      value: true,
    },
    {
      label: "Unblocked",
      value: false,
    },
  ];

  const handleFormSubmit = (consolidationCenter: any) => {
    onChange({ ...consolidationCenter, page: 1 });
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="centers-search"
    >
      {errors.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid container spacing={2} marginTop={2} marginBottom={2} wrap="wrap">
        <Grid item xs={4}>
          <Controller
            control={control}
            render={({ field: { value } }) => (
              <TextField
                fullWidth
                type="text"
                label="General Search"
                size="small"
                variant="outlined"
                defaultValue={value}
                error={!!formErrors.searchGeneral}
                InputLabelProps={{ shrink: !!value }}
                {...register("searchGeneral")}
              />
            )}
            name="searchGeneral"
          />
        </Grid>
        {/* <Grid item xs={2}>
          <Controller
            control={control}
            render={({ field: { value } }) => (
              <TextField
                fullWidth
                type="text"
                label="Abbreviation"
                size="small"
                variant="outlined"
                defaultValue={value}
                error={!!formErrors.DSC_SIGLA}
                InputLabelProps={{ shrink: !!value }}
                {...register("DSC_SIGLA")}
              />
            )}
            name="DSC_SIGLA"
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            render={({ field: { value } }) => (
              <TextField
                fullWidth
                type="text"
                label="Center"
                size="small"
                variant="outlined"
                defaultValue={value}
                error={!!formErrors.NOME_CENTRO}
                InputLabelProps={{ shrink: !!value }}
                {...register("NOME_CENTRO")}
              />
            )}
            name="NOME_CENTRO"
          />
        </Grid> */}
        <Grid item xs={3}>
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={companies}
                getOptionLabel={(option: CompanyCombo) =>
                  `${option.description}`
                }
                value={
                  companies.findLast((item: any) => item.id === value) || null
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Select Company"
                    size="small"
                    inputRef={ref}
                    error={!!formErrors.ID_EMPRESA}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.id);
                }}
              />
            )}
            name="ID_EMPRESA"
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={blocked}
                getOptionLabel={(option) => `${option.label}`}
                value={
                  blocked.findLast((item: any) => item.value === value) || null
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Blocked"
                    size="small"
                    inputRef={ref}
                    error={!!formErrors.IND_PLANTA_BLOQUEADA}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.value);
                }}
              />
            )}
            name="IND_PLANTA_BLOQUEADA"
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            // disabled={isLoading}
            type="submit"
            data-testid="submit-button"
            fullWidth
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
