import { Download } from "@mui/icons-material";
import {
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { isAxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";

import Search from "./components/Search";
import { create, getCompanyCenter, useList, exportAll } from "../../../services/buyerGroupSup";
import { getLocal, getToken } from "../../../storage/auth";
import BuyerGroupForm from "./components/BuyerGroupForm";
import DataTable from "./components/DataTable";
import DialogResultBuyerGroupSup from "./components/Dialog";
import { buyerGroupResults } from "../../../types/buyerGroupSup";
import { ComboBoxType } from "../../../types/general";

export interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
  ID_CENTRO ?: number;
  CODIGO_GRUPO ?: number;
}

export default function BuyerGroup() {
  const [isSaving, setIsSaving] = useState(false);
  const [token, setToken] = useState<string>();
  const [userValidation, setUserValidation] = useState<string>();

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = (getLocal("userInfo")) as any;
    try {
      setUserValidation(JSON.parse(getUser));
      setToken(getTokens);
    } catch (err) {
      // console.log(err)
    }
  };

  useEffect(() => {
    getTokenSession();
    getCompanyCenter()
    .then((result) => setCenterPlant(result));
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [errors, setErrors] = useState<string[]>();
  const [resultBuyerGroupSup, setResultBuyerGroupSup] = useState<buyerGroupResults>();
  const [openDialogResult, setOpenDialogResult] = useState<boolean>(true);
  const [centerPlant, setCenterPlant] = useState<ComboBoxType[]>([] as ComboBoxType[]);
  
  const { data, meta, isLoading } = useList(params, token);
  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const handleFormSubmit = async (fileUpload: any) => {
    try {
      setErrors([]);
      setIsSaving(true);
      const resultBuyerGrouoSup = await create(fileUpload);
      enqueueSnackbar("Upload Suppliers Manager executed successfully", { variant: "success" });
      setParams({ ...params });
      setIsSaving(false);
      setResultBuyerGroupSup(resultBuyerGrouoSup.data);
      setOpenDialogResult(true);
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setErrors([err.response?.data?.message]);
      }
      setIsSaving(false);
    }
    setIsSaving(false);
  };

  const exportBuyerGroupsSup = async () => {
    try {
      await exportAll(params);
    } catch (err) {}
  };

  return (
    <Grid marginTop={4}>
      <Grid container spacing={1} direction="column">
        <Grid item xs={10}>
          <Typography variant="h5">Upload Suppliers Manager</Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <BuyerGroupForm
          onSubmit={handleFormSubmit}
          isLoading={isSaving}
          errors={errors}
        />
      </Grid>
      {resultBuyerGroupSup &&
        <DialogResultBuyerGroupSup
          openDialogResult={openDialogResult}
          setOpenDialogResult={setOpenDialogResult}
          resultBuyerGroupSup={resultBuyerGroupSup}
        />
      }
      <Search onChange={handleRequest} dataCenterCombo={centerPlant} params={params} setParams={setParams}/>
      <Grid container marginTop={4} justifyContent="flex-end">
        <Button
          variant="text"
          onClick={exportBuyerGroupsSup}
          startIcon={<Download />}
        >
          Export XLSX
        </Button>
      </Grid>
      <Grid>
        <DataTable
          loading={isLoading}
          data={data}
          userValidation={userValidation}
          rowCount={meta?.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          pagination
          paginationModel={paginationModel}
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>
    </Grid>
  );
}
