import { SetStateAction, useState} from "react";
import {isAxiosError} from "axios";
import {useSnackbar} from "notistack";

interface Actions {
    create: (data: any) => Promise<any>;
    deleteData: (id: string | number) => Promise<any>;
    exportAll: (params: any) => Promise<void>;
    update?: (id: string | number, data: any) => Promise<any>;
    isInUse?: (id: string | number) => Promise<{ inUse: boolean }>;
    createCopy?: (items: any[]) => Promise<any>;
}

interface Configs {
    findKey?: string;
    entityName?: string;
}

const useForms = ({
                      data,
                      params,
                      setParams,
                      actions: {create, deleteData, exportAll, update, isInUse, createCopy} = {} as Actions,
                      configs: {findKey, entityName} = {} as Configs,
                  }) => {
    const {enqueueSnackbar} = useSnackbar();
    const [selectedData, setSelectedData] = useState({ATIVO: true});
    const [selectedDataId, setSelectedDataId] = useState<string | number>();
    const [controlEdit, setControlEdit] = useState({loading: false, isInUse: false});
    const [isSaving, setIsSaving] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalCopyOpen, setModalCopyOpen] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [errors, setErrors] = useState<string[]>();
    const [readOnly, setReadOnly] = useState(false);
    const [paginationModel, setPaginationModel] = useState({page: 0, pageSize: 10});


    const handleRequest = (requestParams: any) => {
        setParams((prevState: any) => ({...prevState, ...requestParams}));
    };

    const onViewData = (id: string | number | undefined, formatData?: (arg0: any) => SetStateAction<any>) => {
        setErrors([]);
        setReadOnly(true);
        const editData = data.filter((item) => item[findKey] === id);
        setSelectedData(formatData ? formatData(editData[0]) : editData[0]);
        setModalOpen(true);
    };

    const handleEditData = async (id: string | number | undefined, formatData?: (arg0: any) => SetStateAction<any>) => {
        setErrors([]);
        setReadOnly(false);
        const editData = data.filter((item) => item[findKey] === id);
        if (editData.length) {
            setSelectedData(formatData ? formatData(editData[0]) : editData[0]);
            setModalOpen(true);
        }
        try {
            setControlEdit({ loading: true, isInUse: true });
            let response;
            if(isInUse) {
                response = await isInUse(id);
            }
            setControlEdit({ loading: false, isInUse: response?.inUse ? response?.inUse : false });
        } catch (error) {
            enqueueSnackbar("It's not possible to edit, try again later", { variant: "error" });
            setControlEdit({ loading: false, isInUse: true });
        }
    };

    const handleDeleteDataConfirmation = (id: string | number) => {
        setSelectedDataId(id);
        setOpenDeleteModal(true);
    };

    const handleDeleteData = async () => {
        if (selectedDataId) {
            try {
                setIsSaving(true);
                await deleteData(selectedDataId);
                enqueueSnackbar(`${entityName} deleted successfully`, { variant: "success" });
                setParams({ ...params });
            } catch (err: any) {
                enqueueSnackbar(`${entityName} is being used`, { variant: "error" });
            }
            setIsSaving(false);
            setOpenDeleteModal(false);
        }
    };

    const handleFormSubmit = async (data) => {
        try {
            setIsSaving(true);
            if (data[findKey]) {
                await update(data[findKey], data);
                enqueueSnackbar(`${entityName} updated successfully`, { variant: "success" });
            } else {
                await create(data);
                enqueueSnackbar(`${entityName} created successfully`, { variant: "success" });
            }
            setSelectedData({ ATIVO: true });
            setParams({ ...params });
            setModalOpen(false);
        } catch (err: unknown) {
            if (isAxiosError(err)) {
                setErrors([err.response?.data?.message]);
            }
        } finally {
            setIsSaving(false);
        }
    };

    const exportData = async () => {
        try {
            await exportAll(params);
        } catch (err) {}
    };

    const handleCancelEditData = () => {
        setSelectedData({});
        setErrors([]);
        setModalOpen(false);
        setControlEdit({ loading: false, isInUse: false });
    };

    const onNew = () => {
        setControlEdit({
            loading: false,
            isInUse: false,
        });
        setErrors([]);
        setReadOnly(false);
        setModalOpen(true);
    }


    const handleCopy = async (copyItems, newItems, mapFunction) => {
        if (!createCopy) {
            enqueueSnackbar("Copy function is not available", { variant: "error" });
            return;
        }

        try {
            setIsSaving(true);
            const copyToValue = copyItems.map((row) => {
                return mapFunction(row, newItems)
            });

            await createCopy(copyToValue);

            setParams({ ...params });
            setIsSaving(false);
            setModalCopyOpen(false);
            setParams({ ...params });
            enqueueSnackbar(`${entityName} copied successful`, { variant: "success" });
        } catch (error: any) {
            setIsSaving(false);
            setErrors([error]);
        }
    };

    const handleCancelCopy = () => {
        setParams({
            ...params,
            limit: 10,
        });
        setModalCopyOpen(false);
    };


    return {
        selectedData,
        setSelectedData,
        controlEdit,
        isSaving,
        modalOpen,
        setModalOpen,
        modalCopyOpen,
        setModalCopyOpen,
        openDeleteModal,
        setOpenDeleteModal,
        errors,
        params,
        readOnly,

        handleRequest,
        handleEditData,
        handleDeleteDataConfirmation,
        handleDeleteData,
        handleFormSubmit,
        exportData,
        handleCancelEditData,
        onViewData,
        onNew,
        handleCopy,
        handleCancelCopy,

        paginationModel,
        setPaginationModel,
    };
};

export default useForms;
