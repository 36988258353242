import { useEffect, useState } from "react";
import { List } from "../../types/resquest";
import { api } from "../../lib/api";
import { UnitMeasurement } from "../../types/unitMeasurement";
import { getToken } from "../../storage/auth";
import { DataTableUtils } from "../../components/DataTable/DataTableUtils";

interface IList {
  keyword?: string
  page?: number
  limit?: number
  sort?: string
}

const entity = "unitsOfMeasurement";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
    params?: IList,
): {
  data: UnitMeasurement[]
  meta: List
  error: Error
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<UnitMeasurement[]>([]);
  const [meta, setMeta] = useState<List>({} as List);
  const [error] = useState<Error>({} as Error);

  useEffect(() => {
      ;(async function () {
        setIsLoading(true)
        const response = await dataTableUtils.findPaginate(params)
        const { total, data, currentPage, totalPages, limit } = response.data
        setData(data)
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }))
        setIsLoading(false)
      })()
  }, [params])

  return { data, meta, error, isLoading }
}

export const create = async (
    unitMeasurement?: UnitMeasurement
): Promise<UnitMeasurement> => {
  const { data } = await api.post(`/${entity}`, unitMeasurement, {
    headers: { Authorization: 'Bearer ' + getToken() },
  })

  return data
}

export const isInUse = async (id: number | string): Promise<any> => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/isInUse/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  })

  return data
}

export const findById = async (id: number) => {
  return await api.get(`/${entity}/${id}`)
}

export const findAll = async (): Promise<UnitMeasurement[]> => {
  const response = await api.get<UnitMeasurement[]>(`/${entity}`)
  return response.data
}

export const getUnitsOfMeasurement = async () => {
  const token = getToken();

  const { data } = await api.get(`/${entity}/getCombo`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const update = async (
    id: number,
    unitMeasurement?: UnitMeasurement
): Promise<UnitMeasurement> => {
  const { data } = await api.patch(
    `/${entity}/${unitMeasurement?.ID_UM}`,
    unitMeasurement,
    { headers: { Authorization: 'Bearer ' + getToken() } }
  )

  return data
}
export const deleteUnitMeasurement = async (id: number): Promise<UnitMeasurement> => {
  const { data } = await api.delete(`/${entity}/${id}`, {
    headers: { Authorization: 'Bearer ' + getToken() },
  })

  return data
}

export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: 'Id', value: 'ID_UM' },
    { label: 'Abbreviation', value: 'SIGLA' },
    { label: 'Description', value: 'DESCRICAO' },
    {
      label: 'Status',
      value: (row: any) => (row.ATIVO ? 'Active' : 'Inactive'),
    },
  ]

  await dataTableUtils.exportAll({
    params,
    xlsxData,
    filename: 'UnitOfMeasurement',
  })
}
