import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Autocomplete,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { Company } from "../../../../../types/company";
import { CustomRegime } from "../../../../../types/customRegime";

let customRegimeScheme = yup.object({
  DESCRICAO_REGIME: yup.string().max(50).required(),
  CODIGO_SAP: yup.string().max(6).required(),
  ATIVO: yup.boolean().notRequired(),
});

interface CustomRegimeFormProps {
  customRegime: CustomRegime;
  companies: Company[];
  onSubmit: (customRegime: CustomRegime) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly?: boolean;
}

export default function CustomRegimeForm({
  customRegime,
  center,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  controlEdit,
  readOnly
}: CustomRegimeFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(customRegimeScheme),
  });

  useEffect(() => {
    reset(customRegime);
  }, [customRegime, reset]);

  const handleFormSubmit = (newCustomRegimes: CustomRegime) => {
    onSubmit(newCustomRegimes);
  };

  const handleCancel = () => {
    reset({ DESCRICAO_REGIME: "", CODIGO_SAP: "" });
    onCancel();
  };

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit as any)}
      data-testid="customRegime-form"
    >
      {errors.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="search"
        container
        spacing={2}
        marginTop={1}
        border={1}
        padding={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item xs={9}>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="Description"
                  size="small"
                  variant="outlined"
                  disabled={controlEdit.isInUse || readOnly}
                  defaultValue={value}
                  error={!!formErrors.DESCRICAO_REGIME}
                  InputLabelProps={{ shrink: !!value }}
                  {...register("DESCRICAO_REGIME")}
                  inputProps={{ maxLength: 50 }}
                />
              )}
              name="DESCRICAO_REGIME"
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="SAP Code"
                  size="small"
                  disabled={controlEdit.isInUse || readOnly}
                  variant="outlined"
                  defaultValue={value}
                  error={!!formErrors.CODIGO_SAP}
                  InputLabelProps={{ shrink: !!value }}
                  {...register("CODIGO_SAP")}
                  inputProps={{ maxLength: 6 }}
                />
              )}
              name="CODIGO_SAP"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field: { value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!value}
                      disabled={readOnly}
                      inputProps={{ "aria-label": "controlled" }}
                      {...register("ATIVO")}
                    />
                  }
                  labelPlacement="start"
                  label="Status"
                />
              )}
              name="ATIVO"
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={isLoading}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          {!readOnly ? (
          <Grid marginLeft={2}>
            <Button
              variant="contained"
              disabled={isLoading}
              type="submit"
              data-testid="submit-button"
            >
              Confirm
            </Button>
          </Grid>
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
}
