import * as yup from 'yup';

export function isEmailValid(email: string) {
  let schema = yup.object().shape({
    email: yup
        .string()
        .required('Email is required')
        .email('Not a proper email')
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, 'Not a proper email'), // Enforce proper domain
  });

  return schema.isValidSync({
    email,
  });
}


export function isConvertibleToNumber(str: string): boolean {
  return /^-?\d+(\.\d+)?$/.test(str);
}