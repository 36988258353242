import React, { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { getToken } from "../../storage/auth";
import { ProcessNotesType } from "../../types/process";

const entity = "notes-process";

export const save = async (notesProcess: Partial<ProcessNotesType>) => {
  const token = getToken()
    return await api.post(`/${entity}`, notesProcess, {
        headers: { authorization: `Bearer ${token}` },
    })
}

export const fetchNotesProcess = async (idProcess: number) => {
    const token = getToken()
    try {
      const response = await api.get(`/${entity}/${idProcess}`, {
        headers: { authorization: `Bearer ${token}` },
      });
      return response.data
    } catch (error) {
      console.error('Error fetching notes process:', error)
      throw error
    }
  }