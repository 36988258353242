import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { ComboBoxType } from "../../../../../types/general";
import { findCodeBuyerGroupSup } from "../../../../../services/buyerGroupSup";
import { IParams } from "../..";

interface SearchBuyerGroupSupProps {
  dataCenterCombo: ComboBoxType[],
  onChange: (params: IParams) => void,
  params: IParams,
  setParams: (params: IParams) => void,
}

export default function SearchBuyerGroupSup({
  dataCenterCombo,
  onChange,
  params,
  setParams,
}: SearchBuyerGroupSupProps) {

  const [codeBuyerGroup, setCodeBuyerGroup] = useState<ComboBoxType[]>([] as ComboBoxType[]);
  const [centerSelected, setCenterSelected] = useState<number>();
  const [codeBuyerGroupSelected, setCodeBuyerGroupSelected] = useState<number>();
  const [textSelected, setTextSelected] = useState<string>();

  const handleSearch = () => {
    if(textSelected) {
      onChange({
        ...params,
        page: 1,
        ID_CENTRO: centerSelected,
        CODIGO_GRUPO: codeBuyerGroupSelected,
        searchGeneral: textSelected,
      });
    } else {
      onChange({
        ...params,
        page: 1,
        ID_CENTRO: centerSelected,
        CODIGO_GRUPO: codeBuyerGroupSelected,
      });
    } 

  };

  const findCodeBuyerGroup = async (id: number | undefined) => {
    if(id) {
      const codeBuyerGroup = await findCodeBuyerGroupSup(id);
      setCodeBuyerGroup(codeBuyerGroup);
      setCodeBuyerGroupSelected(undefined);
    }
  }

  return (
    <>
      <form>
        <Grid container spacing={2} marginTop={2}>
          <Grid item md={3} xs={6}>
            <TextField
              fullWidth
              type="text"
              label="General Search"
              defaultValue={null}
              size="small"
              data-testid="search-general"
              onChange={(e) => {
                setTextSelected(e.target.value)
              }}
              onKeyDown={(event: React.KeyboardEvent) => {
                if (event.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <Autocomplete
              options={dataCenterCombo || []}
              getOptionLabel={(option) => option.description}
              data-testid="company-autocomplete"
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  data-testid="company-textfield"
                  label="Company - Plant"
                  size="small"
                  variant="outlined"
                />
              )}
              onChange={(_: any, data: any) => {
                // onChange(data?.id || null);
                findCodeBuyerGroup(data?.id);
                setCenterSelected(data?.id);
              }}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <Autocomplete
              options={codeBuyerGroup || []}
              getOptionLabel={(option) => option.description}
              data-testid="buyer-group-autobcomplete"
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Code Buyer Group"
                  size="small"
                  variant="outlined"
                />
              )}
              value={
                codeBuyerGroup?.findLast((item) => item.id === codeBuyerGroupSelected) || null
              }
              onChange={(_: any, data: any) => {
                // onChange(data?.id || null);
                setCodeBuyerGroupSelected(data?.id);;
              }}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <Button
              data-testid="submit-button"
              variant="contained"
              fullWidth
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}