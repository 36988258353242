import { Download } from "@mui/icons-material";

import { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import DataTable from "./components/DataTable";
import Search from "../../../components/Search";
import {
  create,
  deletePickupZone,
  exportAll,
  update,
  useList,
  isInUse,
} from "../../../services/pickupZones";
import PickupZoneForm from "./components/PickupZoneForm";
import { PickupZone } from "../../../types/pickupZone";
import { hasClaim } from "../../../storage/userClaims";
import {getLocal, getToken} from "../../../storage/auth";
import { isAxiosError } from "axios";
import { useSnackbar } from "notistack";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  token: string;
}
export default function PickupZones() {
  const { enqueueSnackbar } = useSnackbar();
  const [pickupZone, setPickupZone] = useState<PickupZone>({
    ATIVO: true,
  } as PickupZone);
  const [token, setToken] = useState<string>("");
  const [pickupZoneId, setPickupZoneId] = useState<string | number>();
  const [isSaving, setIsSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userValidation, setUserValidation] = useState<string>();
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [controlEdit, setControlEdit] = useState({
    loading: false,
    isInUse: false,
  });

  const getTokenSession = () => {
    const getUser = (getLocal("userInfo")) as any;
    const getTokens = getToken();

    setUserValidation(JSON.parse(getUser));
    setToken(getTokens ? getTokens : "");
  };
  useEffect(() => {
    getTokenSession();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [errors, setErrors] = useState<string[]>();
  const { data, meta, isLoading } = useList(params, token);

  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };

  const handleEditPickupZone = async (id: string | number) => {
    setErrors([]);
    setReadOnly(false)
    const editPickupZone = data.find((item: any) => item.ID_PICK_UP_ZONE === id);

    if (editPickupZone) {
      const { ID_PICK_UP_ZONE, SIGLA, DESCRICAO, ID_TIPO_EMBARQUE, ATIVO } = editPickupZone;
      setPickupZone({ ID_PICK_UP_ZONE, SIGLA, DESCRICAO, ID_TIPO_EMBARQUE, ATIVO });
      setModalOpen(true);
    }

    try {
      setControlEdit({
        loading: true,
        isInUse: true,
      });
      const { inUse } = await isInUse(id);
      setControlEdit({
        loading: false,
        isInUse: inUse,
      });
    } catch (error) {
      enqueueSnackbar("It's not posible to edit, try again later", {
        variant: "error",
      });
      setControlEdit({
        loading: false,
        isInUse: true,
      });
    }
  };

  const handleDeletePickupZoneConfirmation = (id: string | number) => {
    setPickupZoneId(id);
    setOpenDeleteModal(true);
  };

  const handleDeletePickupZone = async () => {
    if (pickupZoneId)
      try {
        setIsSaving(true);
        await deletePickupZone(pickupZoneId);
        enqueueSnackbar("Pick-up Zone deleted successful", {
          variant: "success",
        });
        setParams({ ...params });
        setOpenDeleteModal(false);
      } catch (err: any) {
        if (err.response.status) {
          enqueueSnackbar("Pick-up Zone is being used", { variant: "error" });
        } else {
          enqueueSnackbar(err.response.data.message[0], { variant: "error" });
        }
      }
    setIsSaving(false);
    setOpenDeleteModal(false);
  };

  const handleFormSubmit = async (newPickupZone: PickupZone) => {
    try {
      setIsSaving(true);
      if (newPickupZone.ID_PICK_UP_ZONE) {
        await update(newPickupZone.ID_PICK_UP_ZONE, newPickupZone);
        enqueueSnackbar("Pick-up Zone updated successful", {
          variant: "success",
        });
      } else {
        await create(newPickupZone);
        enqueueSnackbar("Pick-up Zone created successful", {
          variant: "success",
        });
      }
      setPickupZone({ ATIVO: true } as PickupZone);
      setParams({ ...params });
      setModalOpen(false);
      setIsSaving(false);
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setErrors([err.response?.data?.message]);
      }
      setIsSaving(false);
    }
    setIsSaving(false);
  };

  const exportPickupZones = async () => {
    try {
      await exportAll(params);
    } catch (err) {}
  };

  const handleCancelEditPickupZone = () => {
    setPickupZone({} as PickupZone);
    setErrors([]);
    setControlEdit({
      loading: false,
      isInUse: false,
    });
    setModalOpen(false);
  };

  const onViewPickupZone = (id: string | number) => {
    setErrors([]);
    setReadOnly(true)
    const editPickupZone = data.find((item: any) => item.ID_PICK_UP_ZONE === id);

    if (editPickupZone) {
      const { ID_PICK_UP_ZONE, SIGLA, DESCRICAO, ID_TIPO_EMBARQUE, ATIVO } = editPickupZone;
      setPickupZone({ ID_PICK_UP_ZONE, SIGLA, DESCRICAO, ID_TIPO_EMBARQUE, ATIVO });
      setModalOpen(true);
    }
  };

  return (
    <Grid marginTop={4}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h5">Pick-up Zone</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" marginTop={1}>
        <Grid item xs={2}>
          {!modalOpen ? (
            <Grid item xs={2}>
              <Button
                sx={{
                  display: hasClaim("Pickup Zone - Maintenance")
                    ? "block"
                    : "none",
                }}
                variant="contained"
                onClick={() => {
                  setControlEdit({
                    loading: false,
                    isInUse: false,
                  });
                  setErrors([])
                  setModalOpen(true);
                }}
                fullWidth
                data-testid="new-button"
              >
                New
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {modalOpen ? (
        <Grid container alignItems="center">
          <PickupZoneForm
            onSubmit={handleFormSubmit}
            pickupZone={pickupZone}
            onCancel={handleCancelEditPickupZone}
            isLoading={isSaving}
            errors={errors}
            controlEdit={controlEdit}
            readOnly={readOnly}
          />
        </Grid>
      ) : null}
      <Search onChange={handleRequest} />
      <Grid container marginTop={2} justifyContent="flex-end">
        <Button
          variant="text"
          onClick={exportPickupZones}
          startIcon={<Download />}
        >
          Export XLSX
        </Button>
      </Grid>
      <Grid>
        <DataTable
          loading={isLoading}
          data={data}
          userValidation={userValidation}
          onDeletePickupZone={handleDeletePickupZoneConfirmation}
          onEditPickupZone={handleEditPickupZone}
          onViewPickupZone={onViewPickupZone}
          rowCount={meta.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          paginationModel={paginationModel}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Pick-up Zone</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete this Pick-up Zone?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button onClick={handleDeletePickupZone} data-testid="delete-modal-confirm">Confirm</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
