import "./card-pipeline-triage.css";
import { useSearchParams } from "react-router-dom";
import WaitingAttachments from "./images/WaitingAttachments.png";
import UnderAnalysis from "./images/UnderAnalysis.png";
import MissingInvoice from "./images/MissingInvoice.png";
import UnderLogisticsAnalysis from "./images/UnderLogistcsAnalysis.png";
import Pending from "./images/Pending.png";
import WaitingToMoveToReceiving from "./images/WaitingToMoveToReceiving.png";
import { Typography } from "@mui/material";
import { TriageStatus } from "../../../../types/triage";

const statusesIcons = [
  {
    status: TriageStatus.WAITING_ATTACHMENTS,
    icon: WaitingAttachments,
  },
  {
    status: TriageStatus.UNDER_ANALYSIS,
    icon: UnderAnalysis,
  },
  {
    status: TriageStatus.MISSING_INVOICE,
    icon: MissingInvoice,
  },
  {
    status: TriageStatus.UNDER_LOGISTICS_ANALYSIS,
    icon: UnderLogisticsAnalysis,
  },
  {
    status: TriageStatus.PENDING,
    icon: Pending,
  },
  {
    status: TriageStatus.WAITING_TO_MOVE_TO_RECEIVING,
    icon: WaitingToMoveToReceiving,
  },
];

export default function CardPipelineTriage({
  status,
  total,
  selectedStatus,
  onSelectStatus,
}: {
  status: string,
  total: number,
  selectedStatus: string|undefined,
  onSelectStatus: (status: TriageStatus) => void
}) {
  const getIcon = (status: string): string => {
    const icon = statusesIcons.find((item) => item.status.trim().toLowerCase() === status.trim().toLowerCase());
    return icon ? icon.icon : "";
  }

  const handleGetClassByStatus = (): string => {
    const firstStatus = TriageStatus.WAITING_ATTACHMENTS;

    if (status === firstStatus && status === selectedStatus) {
      return "card-pipeline-triage-first-selected";
    }

    if (status === firstStatus) {
      return "card-pipeline-triage-first";
    }

    if (status === selectedStatus) {
      return "card-pipeline-triage-selected"
    }

    return "card-pipeline-triage";
  }

  return (
    <div
      onClick={() => onSelectStatus(status as TriageStatus)} 
      className={handleGetClassByStatus()}
    >
      <div className="inner-card">
        <div className="row triage-icon-area">
          <img 
            height={40} 
            alt={status} 
            src={getIcon(status)} 
            className="icon-card-pipeline"
          />
          <Typography width={70} color="#fff" fontSize={13}>{ status }</Typography>
        </div>

        <div className="triage-totals">
          <div className="flex-end">
            <div className="triage-circle">
              { total }
            </div>
          </div>
        </div> 
      </div> 
    </div>
  )
}