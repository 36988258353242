export class Logger {
    safe_routes = [
        '/auth/login',
        '/auth/refreshToken',
        '/auth/logout',
        '/auth/decode',
        '/frontLogger/registerLog'
    ];

    returnPhrase = "It is not possible to perform this action at the moment. Please try again later.";

    createLog(response) {
        if (!(response instanceof Error)) return Promise.resolve(response);

        if (response.response?.data?.hasOwnProperty('success')) {
            return Promise.reject(response);
        } else if (!this.safe_routes.includes(response?.config?.url)) {
            return Promise.reject({
                data: {
                    message: this.returnPhrase
                }
            });
        }

        return Promise.reject(response);
    }
}