import { api } from "../../lib/api";
import { getToken } from "../../storage/auth";
import { AutomaticAuthorizationProps } from "../../types/automaticAuthorization";

const entity = "consult";

type IPayload = {
  center: AutomaticAuthorizationProps["ID_CENTRO"][];
  type: string;
  reference: string;
  sort: string;
  orderField: string;
  page: number;
  limit: number;
}

export const paginateConsult = async (payload: IPayload): Promise<any> => {
  const { center, type, reference, sort, orderField, page, limit } = payload;
  const token = await getToken();
  const { data } = await api.get(`/${entity}/find`, {
    params: {
      center: center || [],
      type: type || '',
      q: reference || '',
      sort: sort || 'updated_at',
      order: (orderField || 'DESC').toUpperCase(),
      page: page || 1,
      limit: limit || 100,
    },
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};