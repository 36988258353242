import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { IncotermShipping } from "../../types/incotermsShipping";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
}

const entity = "incotermsFreightTypes";

export const useListShipping = (
  params?: IList,
  token?: string
): { data: IncotermShipping[]; isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<IncotermShipping[]>([
    {} as IncotermShipping,
  ]);
  useEffect(() => {
    (async function () {
      setIsLoading(true);
      try {
        const response = await api.get(`/${entity}/getCombo`, {
          headers: {authorization: `Bearer ${token}`},
        })

        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error)
      }
    })();
  }, [params, token]);
  return { data, isLoading };
};
