import Search from "../../../components/Search";
import {
  create,
  deleteAccessControl,
  update,
  useList,
} from "../../../services/accessControl";

import { useState } from "react";

import { isAxiosError } from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import DataTable from "./components/DataTable";
import AccessControlForm from "./components/AccessControlForm";
import { Webgroup } from "../../../types/accessControl";
import { useSnackbar } from "notistack";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  orderField?: string;
}
export default function AccessControlList() {
  const { enqueueSnackbar } = useSnackbar();
  
  const [isSaving, setIsSaving] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
    orderField: "DSC_WEBGROUP",
  } as IParams);

  const [errors, setErrors] = useState<string[]>();
  const {
    data,
    meta,
    isLoading,
    permissionsClass,
    setPermissionsClass,
    freightForwarder,
    accessControlId,
    setAccessControlId,
    accessControl,
    setAccessControl,
    listFreightForwarder,
    setListFreightForwarder,
  } = useList(params);

  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };

  const handleEditAccessControl = (id: string | number) => {
    resetPermissionClass();
    window.location.href = '#header-title';
    setAccessControlId('');
    setTimeout(() => {
      setAccessControlId(id);
    }, 50)
    setErrors([]);
    setFormOpen(true);
    setReadOnly(false);
  };

  const handleViewAccessControl = (id: string | number) => {
    resetPermissionClass();
    window.location.href = '#header-title';
    setAccessControlId('');
    setTimeout(() => {
      setAccessControlId(id);
    }, 50)
    setErrors([]);
    setFormOpen(true);
    setReadOnly(true);
  }

  const handleDeleteAccessControlConfirmation = (id: string | number) => {
    setAccessControlId('');
    setTimeout(() => {
      setAccessControlId(id);
    }, 50)
    setOpenDeleteModal(true);
  };

  const handleFormOpen = (formOpen: boolean) => {
    setFormOpen(formOpen);
  }

  const handleNewFormOpen = (formOpen: boolean) => {
    setAccessControlId(undefined)
    setListFreightForwarder([])
    setErrors([])
    handleFormOpen(formOpen);
    resetPermissionClass();
  }

  const handleDeleteAccessControl = async () => {
    if (accessControlId)
      try {
        setIsSaving(true);
        await deleteAccessControl(accessControlId);
        enqueueSnackbar("Access Control deleted successful", { variant: "success" });
        setParams({ ...params });
      } catch (err) {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      }
    setIsSaving(false);
    setOpenDeleteModal(false);
  };

  const resetPermissionClass = () => {
    const permissionReseted = permissionsClass.map((permissions) => {
      permissions.functionality = permissions.functionality.map((functionality) => {
        functionality.ATIVO = false;
        return functionality;
      })
      return permissions;
    })
    setPermissionsClass(permissionReseted);
  }

  const handleFormSubmit = async (newAccessControl: Webgroup) => {
    setIsSaving(true);
    try {
      if (newAccessControl.ID_WEBGROUP) {
        await update(newAccessControl.ID_WEBGROUP, newAccessControl);
        enqueueSnackbar("Access Control updated successful", {
          variant: "success",
        });
      } else {
        await create(newAccessControl);
        enqueueSnackbar("Access Control created successful", {
          variant: "success",
        });
      }
      resetPermissionClass();
      setParams({ ...params });
      setFormOpen(false);
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setErrors([err.response?.data?.message]);
      } else {
        resetPermissionClass();
      }
    }
    setIsSaving(false);
  };

  const handleCancelEditAccessControl = () => {
    setAccessControl({ DSC_WEBGROUP: '' } as Webgroup);
    resetPermissionClass();
    setErrors([]);
    setFormOpen(false);
    setReadOnly(false);
  };

  return (
    <Grid marginTop={4}>
      <Grid container spacing={1} direction="column">
        <Grid item xs={9}>
          <Typography variant="h5" id="header-title">Access Control</Typography>
        </Grid>

        {!formOpen && (
          <Grid item xs={2}>
            <Button
              variant="contained"
              onClick={() => handleNewFormOpen(true)}
              data-testid="new-button"
            >
              New
            </Button>
          </Grid>
        )}
      </Grid>
      {formOpen && 
        <Grid marginTop={4}>
          <AccessControlForm
            readOnly={readOnly}
            onSubmit={handleFormSubmit}
            accessControl={accessControl}
            permissionsClass={permissionsClass}
            setPermissionsClass={setPermissionsClass}
            onCancel={handleCancelEditAccessControl}
            isLoading={isSaving}
            errors={errors}
            freightForwarder={freightForwarder}
            listFreightForwarder={listFreightForwarder}
            setListFreightForwarder={setListFreightForwarder}
          />
        </Grid>
      }
      <Search onChange={handleRequest} disableStatus={true}/>
      <Grid container marginTop={4} justifyContent="flex-end"></Grid>
      <Grid>
        <DataTable
          loading={isLoading}
          data={data}
          onDeleteAccessControl={handleDeleteAccessControlConfirmation}
          onEditAccessControl={handleEditAccessControl}
          rowCount={meta.total || 0}
          onView={handleViewAccessControl}
          onPaginationModelChange={(e: any) => {
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Webgroup</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete this Webgroup?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button onClick={handleDeleteAccessControl}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
