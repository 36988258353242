import { api } from "../../lib/api";
import { getToken } from "../../storage/auth";
import { InvoicePackageDetail } from "../../types/packageDetail";
import { PartnerService } from "../../types/partner";

const entity = "invoice-package";

export const create = async (
  invoicePackageDetail: InvoicePackageDetail,
): Promise<PartnerService> => {
  const token = getToken()
  const { data } = await api.post(`/${entity}`, invoicePackageDetail, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
  return data
}

export const remove = async (
  ID_INVOICE_PACKAGE: number,
  forceDelete?: boolean
): Promise<PartnerService | any> => {
  const token = await getToken()
  const { data } = await api.delete(`/${entity}/${ID_INVOICE_PACKAGE}`, {
    data: {
      forceDelete,
    },
    headers: { authorization: `Bearer ${token}` },
  })
  return data
}
