import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import FolderIcon from "@mui/icons-material/Folder";
import ReplayIcon from "@mui/icons-material/Replay";
import DataTableDefault from "../../../../../components/DataTable";
import { formatDate } from "../../../../utils/date";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { EdiFileItem, EdiFileLogs } from "../../../../../types/ediFile";
import { findFileContent, findLogDetail, reprocessEdi } from "../../../../../services/ediFile";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { enqueueSnackbar } from "notistack";
import { hasClaim } from "../../../../../storage/userClaims";

interface DataTableProps {
  viewFile: (id: number) => void;
  reprocess: (id: number | string) => void;
  data: EdiFileItem[];
  [x: string]: any;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function DataTable({
  data,
  ...props
}: DataTableProps) {

  const [dialogLogDetail, setDialogLogDetail] = useState(false);
  const [dialogDataDetail, setDialogDataDetail] = useState<EdiFileLogs>({} as EdiFileLogs);

  const [dialogFile, setDialogFile] = useState(false);
  const [dialogFileData, setDialogFileData] = useState<string>('');

  const [isLoading, setIsLoading] = useState(false);

  const [confirmProcess, setConfirmProcess] = useState(false);
  const [reprocessId, setReprocessId] = useState<number>();

  const logDetail = async (row: any) => {
    setDialogLogDetail(true);

    const logDetail = await findLogDetail(row.log.ID_EDI_ARQUIVO_DADOS)
    setDialogDataDetail(logDetail.fileDetails?.logs?.[0]);
  }

  const viewFile = async (row: any) => {
    setDialogFile(true);
    findFileContent(row.fileId).then((response) => {
      setDialogFileData(response.fileContent);
    })
  }

  const handleConfirmProcess = async () => {
    try {
      setConfirmProcess(false);
      setIsLoading(true);
      if (reprocessId) {
        const response = await reprocessEdi(reprocessId);
        setIsLoading(false);

        if (response.status === 404) {
          enqueueSnackbar("Reprocess unsuccessful", { variant: "danger" });
        } else {
          enqueueSnackbar("Reprocess successfully", { variant: "success" });
        }
      }
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar("An error occurred during reprocess", { variant: "danger" });
      console.error("Error during reprocess:", error);
    }
  };

  const openConfirmModal = (id) => {
    setConfirmProcess(true);
    setReprocessId(id);
  }

  const renderMenus = (row: any) => {
    return [
      <Tooltip title='Log Detail'>
        <GridActionsCellItem
            key={`log-detail-${row?.log?.ID_EDI_ARQUIVO_DADOS}`}
            icon={<SearchIcon />}
            label="Log Detail"
            onClick={() => logDetail(row)}
            color="inherit"
            data-testid="log-detail-button"
            title="Log Detail"
            disabled={ !row.logFileName }
        />
      </Tooltip>,
      <Tooltip title='File View'>
        <GridActionsCellItem
            key={`file-view-${row?.fileId}`}
            icon={<FolderIcon />}
            label="FileView"
            onClick={() => viewFile(row)}
            color="inherit"
            data-testid="file-view-button"
        />
      </Tooltip>,
      <Tooltip
        title='Reprocess'
      >
        <GridActionsCellItem
            key={`reprocess-${row?.fileId}`}
            icon={<ReplayIcon />}
            label="Reprocess"
            onClick={() => openConfirmModal(row?.fileId)}
            color="inherit"
            data-testid="reprocess-button"
            disabled={ !['PO', 'AUTHORIZATION', 'SUPPLIER'].includes(row.fileType) }
        />
      </Tooltip>,
    ];
  };

  const columns: GridColDef[] = [
    {
      field: "fileName",
      headerName: "File Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 400,
      renderCell: (params: GridRenderCellParams) => (
          <Typography variant="body2" data-testid="file-name">
            {params.value}
          </Typography>
      ),
    },
    {
      field: "fileData",
      headerName: "File Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<Date>) => formatDate(params.value)
    },
    {
      field: "logFileName",
      headerClassName: "super-app-theme--header",
      headerName: "Log File Name",
      flex: 1,
      minWidth: 450,
    },
    {
      field: "logFileData",
      headerClassName: "super-app-theme--header",
      headerName: "Log File Date",
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<Date>) => formatDate(params.value)
    },
    {
      field: "fileType",
      headerClassName: "super-app-theme--header",
      headerName: "File Type",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "fileStatus",
      headerClassName: "super-app-theme--header",
      headerName: "File Status",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "fileMessage",
      headerClassName: "super-app-theme--header",
      headerName: "File Message",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }) => renderMenus(row),
    },
  ];

  const unescapedText = dialogFileData.replace(/\\t/g, '\t').replace(/\\n/g, '\n');

  return (
      <>
        <DataTableDefault
            rowId={data.map((row) => row?.log?.ID_EDI_ARQUIVO_DADOS)}
            data-testid="edi-file-data-table"
            data={data}
            columns={columns}
            {...props}
            loading={isLoading}
            getRowHeight={() => 35}
        />
        <Dialog
            open={dialogLogDetail}
            onClose={() => setDialogLogDetail(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='lg'
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box sx={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
                <Typography><strong>EDI Log Name:</strong> { dialogDataDetail?.logFileName }</Typography>
                <Typography><strong>Path:</strong> { dialogDataDetail?.path }</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                <Typography><strong>Date:</strong> { formatDate(dialogDataDetail?.date) }</Typography>
                <Typography><strong>FileType:</strong> { dialogDataDetail?.fileType }</Typography>
                <Typography><strong>File Status:</strong> { dialogDataDetail?.fileStatus }</Typography>
              </Box>
              <Divider sx={{ marginTop: '18px' }} />
              <Table sx={{ minWidth: 960 }} aria-label="customized table" size="small">
                <TableHead>
                  <TableRow sx={{ height: '35xp' }}>
                    <TableCell>Line Number</TableCell>
                    <TableCell align="right">Register Type</TableCell>
                    <TableCell align="right">Key Value</TableCell>
                    <TableCell align="right">Key Complement</TableCell>
                    <TableCell minWidth={450} align="right">Error Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dialogDataDetail?.occurrences && dialogDataDetail?.occurrences?.map((row) => (
                      <StyledTableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.lineNumber}
                        </TableCell>
                        <TableCell align="right">{row.registerType}</TableCell>
                        <TableCell align="right">{row.keyValue}</TableCell>
                        <TableCell align="right">{row.keyComplement}</TableCell>
                        <TableCell width={450} align="right">{row.errorMessage}</TableCell>
                      </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
              <Button
                  sx={{ marginTop: '14px' }}
                  variant="contained"
                  startIcon={<ArrowBackIosIcon />}
                  onClick={() => setDialogLogDetail(false)}
              >
                Return
              </Button>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog
            open={dialogFile}
            onClose={() => setDialogFile(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='lg'
            fullWidth
        >

          <DialogContent>
            <DialogTitle id="alert-dialog-title">File Content</DialogTitle>
            <DialogContentText id="alert-dialog-description">
        <textarea
            readOnly
            value={unescapedText}
            style={{
              width: '100%',
              height: '300px',
              whiteSpace: 'pre',
              fontFamily: 'monospace',
              fontSize: '14px',
              border: '1px solid #ccc',
              padding: '10px',
              boxSizing: 'border-box'
            }}
        />
              <Button
                  sx={{ marginTop: '14px' }}
                  variant="contained"
                  startIcon={<ArrowBackIosIcon />}
                  onClick={() => setDialogFile(false)}
              >
                Return
              </Button>
            </DialogContentText>
          </DialogContent>



        </Dialog>

        <Dialog
            open={confirmProcess}
            onClose={() => setConfirmProcess(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirm Reprocess</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure that you want to confirm reprocess?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmProcess(false)}>Cancel</Button>
            <Button onClick={handleConfirmProcess} data-testid="delete-modal-confirm">Confirm</Button>
          </DialogActions>
        </Dialog>
      </>
  );
}
