export const convertToCm = (value: number, unit: string): number => {
  const conversionFactors = {
    CM: 1,
    M: 100,
    MM: 0.1,
    PE: 30.48,
    POL: 2.54,
    YD: 91.44,
  };

  return value * (conversionFactors[unit as keyof typeof conversionFactors] || 1);
};

export const convertToKg = (value: number, unit: string): number => {
  const conversionFactors = {
    KG: 1,
    G: 0.001,
    MG: 0.000001,
    LB: 0.453592,
    OZ: 0.0283495,
    TON: 1000,
  };

  return value * (conversionFactors[unit as keyof typeof conversionFactors] || 1);
};