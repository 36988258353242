import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import { AutomaticAuthorizationScheduleProps } from "../../types/automaticAuthorizationSchedule";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";
import { ComboBoxType } from "../../types/general";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
  ID_CENTRO?: number;
  ID_CENTRO_CONSOLIDADOR?: number;
}

const entity = "boardingAuthorizationSchedule";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string
): { data: any[]; meta: List; isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [meta, setMeta] = useState<List>({} as List);
  useEffect(() => {
    setIsLoading(true);
    setData([]);
    if (token && params?.ID_CENTRO && params?.ID_CENTRO_CONSOLIDADOR) {

      (async function () {
        setIsLoading(true);
        const response = await dataTableUtils.findPaginate(params);
        const {total, data, currentPage, totalPages, limit} = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
        setIsLoading(false)
      })();
    }
  }, [params, token]);

  return { data, meta, isLoading };
};

export const update = async (
  id: number | string,
  values?: AutomaticAuthorizationScheduleProps
): Promise<AutomaticAuthorizationScheduleProps> => {
  const token = getToken();
  const { data } = await api.patch(`/${entity}/${id}`, values, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const isInUse = async (id: number | string): Promise<any> => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/isInUse/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const deleteShipment = async (
  id: number | string
): Promise<AutomaticAuthorizationScheduleProps> => {
  const token = getToken();
  const { data } = await api.delete(`/${entity}/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const findConsolidationCenterByCenter = async (
  id: number | string
): Promise<ComboBoxType[]> => {
  const token = getToken();
  const { data } = await api.get(`/boardingAuthorizationSchedule/consolidationCenter/getCombo?ID_CENTRO=${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const create = async (values: any): Promise<any> => {
  const token = getToken();
  const { data } = await api.post(`/${entity}`, values, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: "Company", value: "GE_SIGLA_EMPRESA" },
    { label: "Center Abreviation", value: "GC_DSC_SIGLA" },
    { label: "Center Name", value: "GC_NOME_CENTRO" },

    { label: "Consolidation Center", value: "GCC_NOME" },

    { label: "Schedule Time", value: "HORA_AGEND" },
    { label: "Schedule Period", value: "DSC_PERIODO" },

    { label: "Monday", value: (row: any) => (row.AGEND_MONDAY ? "True" : "False") },
    { label: "Tuesday", value: (row: any) => (row.AGEND_TUESDAY ? "True" : "False") },
    { label: "Wednesday", value: (row: any) => (row.AGEND_WEDNESDAY ? "True" : "False") },
    { label: "Thursday", value: (row: any) => (row.AGEND_THURSDAY ? "True" : "False") },
    { label: "Friday", value: (row: any) => (row.AGEND_FRIDAY ? "True" : "False") },
    { label: "Saturday", value: (row: any) => (row.AGEND_SATURDAY ? "True" : "False") },
    { label: "Sunday", value: (row: any) => (row.AGEND_SUNDAY ? "True" : "False") },
  ];

  await dataTableUtils.exportAll({params, xlsxData, filename: "AuthomaticAuthorizationSchedule"});
};
