import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import DataTableDefault from "../../../../../components/DataTable";
import {SlaService} from "../../../../../types/sla";
import { hasClaim } from "../../../../../storage/userClaims";

interface DataTableProps {
  onEdit: (id: number | string) => void;
  onView: (id: number | string) => void;
  data: SlaService[];
  loading: boolean;
  [x: string]: any;
}

export default function DataTable({
                                    onEdit,
                                    onView,
                                    data,
                                    loading,
                                    ...props
                                  }: DataTableProps) {

  const transportationModeData: { [key: string]: string } = {
    "AIR": "AIR",
    "ROAD":  "ROAD",
    "OCEAN": "OCEAN",
  }

  const renderMenus = (row: any) => {
    return [
      <GridActionsCellItem
          sx={{
            display:
                hasClaim("SLA - Maintenance") ||
                hasClaim("SLA - Visualization")
                    ? "block"
                    : "none",
          }}
          key={`view-${row.ID_SLA}`}
          icon={<VisibilityIcon />}
          label="View"
          onClick={() => onView(row.ID_SLA)}
          color="inherit"
          data-testid="view-button"
      />,
      <GridActionsCellItem
          sx={{ display: hasClaim("SLA - Maintenance") ? "block" : "none" }}
          key={`edit-${row.ID_SLA}`}
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => onEdit(row.ID_SLA)}
          color="inherit"
          data-testid="edit-button"
      />,
    ];
  };

  const columns: GridColDef[] = [
    {
      field: "GC_NOME_CENTRO",
      headerName: "COMPANY/PLANT",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `${params.row.GE_SIGLA_EMPRESA} - ${params.row.GC_DSC_SIGLA} - ${params.row.GC_NOME_CENTRO}`;
      },
    },
    {
      field: "ID_TIPO_INVOICE",
      headerClassName: "super-app-theme--header",
      headerName: "INVOICE TYPE",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "ID_STATUS_INVOICE",
      headerClassName: "super-app-theme--header",
      headerName: "STATUS",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "GD_DSC_DIVERGENCIA",
      headerClassName: "super-app-theme--header",
      headerName: "RULE",
      flex: 1,
    },
    {
      field: "MODAL",
      headerClassName: "super-app-theme--header",
      headerName: "MODAL",
      minWidth: 80,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `${transportationModeData[params.row.ID_MODAL] || ""}`;
      },
    },
    {
      field: "GP_DSC_PRIORIDADE",
      headerClassName: "super-app-theme--header",
      headerName: "SERVICE LEVEL",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "ID_FRETE_INCO",
      headerClassName: "super-app-theme--header",
      headerName: "FREIGHT INCOTERM",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "SLA_PRINCIPAL",
      headerClassName: "super-app-theme--header",
      headerName: "MAIN SLA",
      minWidth: 130,
      flex: 1,
    },
    {
      field: "SLA_SECUNDARIO",
      headerClassName: "super-app-theme--header",
      headerName: "SLA SECONDARY",
      minWidth: 130,
      flex: 1,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }) => renderMenus(row),
    },
  ];
  return (
      <>
        <DataTableDefault
            rowId={data?.map((row) => row.ID_SLA)}
            data={data}
            columns={columns}
            loading={loading}
            {...props}
        />
      </>
  );
}
