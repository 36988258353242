import { isAxiosError } from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import { Download } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

import Search from "../../../components/Search";
import {
  create,
  deleteConfimation,
  exportAll,
  update,
  isInUse,
  useList,
} from "../../../services/discrepancy";
import { getToken } from "../../../storage/auth";
import { hasClaim } from "../../../storage/userClaims";
import { DiscrepancyProps } from "../../../types/discrepancy";
import DataTable from "./components/DataTable";
import DiscrepanciesForm from "./components/DiscrepanciesForm";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
}

export default function Discrepancy() {
  const { enqueueSnackbar } = useSnackbar();
  const [discrepancyID, setDiscrepancyID] = useState<number | null>(null);
  const [unitMeasurement, setunitMeasurement] = useState<DiscrepancyProps>({ ATIVO: true } as DiscrepancyProps);
  const [isSaving, setIsSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [token, setToken] = useState<string>("");
  const [controlEdit, setControlEdit] = useState({
    loading: false,
    isInUse: false,
  });
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const getTokenSession = () => {
    const getTokens = getToken();
    setToken(getTokens ? getTokens : "");
  };

  useEffect(() => {
    getTokenSession();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [errors, setErrors] = useState<string[]>();
  const { data, meta } = useList(params, token);

  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };

  const handleView = (id: string | number) => {
    setErrors([]);
    setReadOnly(true)
    setunitMeasurement(data.findLast((item) => item.ID_DIVERGENCIA === id) || ({} as DiscrepancyProps));
    setModalOpen(true);
  }

  const handleEditDiscrepancy = async (id: string | number) => {
    setErrors([]);
    setReadOnly(false)
    setunitMeasurement(data.findLast((item) => item.ID_DIVERGENCIA === id) || ({} as DiscrepancyProps));
    setModalOpen(true);

    try {
      setControlEdit({
        loading: true,
        isInUse: true,
      });
      const { inUse } = await isInUse(id);
      setControlEdit({
        loading: false,
        isInUse: inUse,
      });
    } catch (error) {
      enqueueSnackbar("It's not posible to edit, try again later", {
        variant: "error",
      });
      setControlEdit({
        loading: false,
        isInUse: true,
      });
    }
  };

  const handleDelete = (id: number) => {
    setDiscrepancyID(id);
    setOpenDeleteModal(true);
  };

  const handleDeleteConfirmation = async () => {
    if (discrepancyID) {
      try {
        setIsSaving(true);
        await deleteConfimation(discrepancyID);
        enqueueSnackbar("Discrepancy deleted successful", {
          variant: "success",
        });
        setParams({ ...params });
      } catch (err: any) {
        if (err.response.status) {
          enqueueSnackbar("Discrepancy is being used", { variant: "error" });
        } else {
          enqueueSnackbar(err.response.data.message[0], { variant: "error" });
        }
      }
      setIsSaving(false);
      setOpenDeleteModal(false);
    }
  };

  const handleFormSubmit = async (newDiscrepancy: any) => {
    try {
      setIsSaving(true);
      if (newDiscrepancy.ID_DIVERGENCIA) {
        await update(newDiscrepancy);
        enqueueSnackbar("Discrepancy updated successful", {
          variant: "success",
        });
      } else {
        await create(newDiscrepancy);
        enqueueSnackbar("Discrepancy created successful", {
          variant: "success",
        });
      }
      setParams({ ...params });
      setModalOpen(false);
      setIsSaving(false);
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setErrors([err.response?.data?.message]);
      }
      setIsSaving(false);
    }
    setIsSaving(false);
  };

  const exportDiscrepancy = async () => {
    try {
      await exportAll(params);
    } catch (err) {}
  };

  const handleCancel = () => {
    setunitMeasurement({} as DiscrepancyProps);
    setErrors([]);
    setModalOpen(false);
    setControlEdit({
      loading: false,
      isInUse: false,
    });
  };

  return (
    <Grid marginTop={4}>
      <Grid container spacing={1} direction="column">
        <Grid item xs={10}>
          <Typography variant="h5">Discrepancy</Typography>
        </Grid>
        {!modalOpen ? (
          <Grid item xs={2} marginTop={1}>
            <Button
              data-testid="new-add-button"
              sx={{
                display: hasClaim("Discrepancy - Maintenance")
                  ? "block"
                  : "none",
              }}
              variant="contained"
              onClick={() => {
                setControlEdit({
                  loading: false,
                  isInUse: false,
                });
                setErrors([])
                setReadOnly(false)
                setModalOpen(true);
              }}
            >
              New
            </Button>
          </Grid>
        ) : null}
      </Grid>
      {modalOpen ? (
        <Grid container alignItems="center">
          <DiscrepanciesForm
            data-testid="discrepancies-form"
            onSubmit={handleFormSubmit}
            discrepancy={unitMeasurement}
            onCancel={handleCancel}
            isLoading={isSaving}
            errors={errors}
            controlEdit={controlEdit}
            readOnly={readOnly}
          />
        </Grid>
      ) : null}
      <Search onChange={handleRequest} />
      <Grid container marginTop={4} justifyContent="flex-end">
        <Button
          variant="text"
          onClick={exportDiscrepancy}
          startIcon={<Download />}
        >
          Export XLSX
        </Button>
      </Grid>
      <Grid>
        <DataTable
          data={data}
          onDelete={handleDelete}
          onEdit={handleEditDiscrepancy}
          onView={handleView}
          rowCount={meta?.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          paginationModel={paginationModel}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Discrepancy</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete this Discrepancy?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button
            data-testid="confirmation-button"
            onClick={handleDeleteConfirmation}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
