import { Alert, Button, Grid, TextField, Autocomplete, Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SlaService } from "../../../../../types/sla";
import { ComboBoxType } from "../../../../../types/general";

let slaScheme = yup.object({
    ID_CENTRO: yup.number().required(), // Convertendo para número
    ID_TIPO_INVOICE: yup.string().required(),
    ID_STATUS_INVOICE: yup.string().required(),
    ID_MODAL: yup.string().required(),
    TODAS_DIVERGENCIAS: yup.boolean().required(),
    ID_DIVERGENCIA: yup.string().when("TODAS_DIVERGENCIAS", {
        is: false,
        then(schema) {
            return schema.required();
        },
        otherwise(schema) {
            return schema.nullable(true);
        },
    }),
    ID_PRIORIDADE: yup.number().required(), // Convertendo para número
    ID_FRETE_INCO: yup.string().required(),
    SLA_PRINCIPAL: yup.string().required().max(3),
    SLA_SECUNDARIO: yup.string().required().max(3),
});

interface SlaFormProps {
    readOnly: boolean;
    entityInUse: boolean;

    sla: SlaService;
    slaId: number;

    center: ComboBoxType[];
    autoDiscrepancy: ComboBoxType[];
    invoiceType: ComboBoxType[];
    serviceLevels: ComboBoxType[];
    TransportationMode: ComboBoxType[];
    discrepancy: ComboBoxType[];
    TriageInvoiceStatus: ComboBoxType[];
    IncotermsFreightType: ComboBoxType[];

    isEditing: boolean;
    onSubmit: (sla: SlaService) => void;
    onCancel: () => void;
    isLoading?: boolean;
    errors?: string[];
}

export default function SlaForm({
        readOnly,
        sla,
        slaId,
        center,
        autoDiscrepancy,
        invoiceType,
        serviceLevels,
        TransportationMode,
        discrepancy,
        TriageInvoiceStatus,
        IncotermsFreightType,
        isEditing,
        onSubmit,
        onCancel,
        errors = [],
    }: SlaFormProps) {
    const { handleSubmit, reset, control, watch } = useForm({
        resolver: yupResolver(slaScheme),
    });

    const allValues = watch();

    useEffect(() => {
        reset(sla);
    }, [sla, slaId, reset]);

    const handleFormSubmit = (newSla: any) => {
        onSubmit({
            ...newSla,
            SLA_PRINCIPAL: parseInt(newSla.SLA_PRINCIPAL),
            SLA_SECUNDARIO: parseInt(newSla.SLA_SECUNDARIO),
        });
    };

    const handleCancel = () => {
        reset({});
        onCancel();
    };

    return (
        <form
            style={{ width: "100%" }}
            onSubmit={handleSubmit(handleFormSubmit)}
            data-testid="sla-form"
        >
            {errors.map((error) => (
                <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
                    {error}
                </Alert>
            ))}

            <Grid
                data-testid="search"
                container
                marginTop={1}
                border={1}
                padding={2}
                spacing={2}
                borderColor={"#7b7b7b"}
                borderRadius={2}
                alignItems="center"
            >
                <Grid container spacing={2} marginBottom={2}>
                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="ID_CENTRO"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={center}
                                    getOptionLabel={(option) => option?.description || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Company - Plant"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            disabled={isEditing}
                                        />
                                    )}
                                    onChange={(_, newValue) => {
                                        field.onChange(newValue ? parseInt(newValue.id, 10) : "");
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="ID_TIPO_INVOICE"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={invoiceType}
                                    getOptionLabel={(option) => option?.description || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Invoice Type"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            disabled={isEditing}
                                        />
                                    )}
                                    onChange={(_, newValue) => {
                                        field.onChange(newValue ? newValue.description : "");
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="ID_STATUS_INVOICE"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={TriageInvoiceStatus}
                                    getOptionLabel={(option) => option?.description || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Status Invoice"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            disabled={isEditing}
                                        />
                                    )}
                                    onChange={(_, newValue) => {
                                        field.onChange(newValue ? newValue.description : "");
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="ID_MODAL"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={TransportationMode}
                                    getOptionLabel={(option) => option?.description || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Modal"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            disabled={isEditing}
                                        />
                                    )}
                                    onChange={(_, newValue) => {
                                        field.onChange(newValue ? newValue.description : "");
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="TODAS_DIVERGENCIAS"
                            control={control}
                            render={({ field }) => (
                                <Box display="flex" alignItems="center">
                                    <Box marginRight={4} marginLeft={1}>
                                        Discrepancy
                                    </Box>
                                    <RadioGroup
                                        {...field}
                                        row
                                        data-testid="radio-discrepancy"
                                        onChange={(e) => {
                                            field.onChange(JSON.parse(e.target.value));
                                        }}
                                    >
                                        {discrepancy.map((option, index) => (
                                            <Box key={option.id} marginRight={index !== discrepancy.length - 1 ? 4 : 0}>
                                                <FormControlLabel
                                                    value={option.id}
                                                    control={<Radio />}
                                                    label={option.description}
                                                    disabled={isEditing || readOnly}
                                                />
                                            </Box>
                                        ))}
                                    </RadioGroup>
                                </Box>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="ID_DIVERGENCIA"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={autoDiscrepancy}
                                    getOptionLabel={(option) => option?.description || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Specific Auto Discrepancy"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            disabled={allValues?.TODAS_DIVERGENCIAS !== false || isEditing}
                                        />
                                    )}
                                    onChange={(_, newValue) => {
                                        field.onChange(newValue ? newValue.description : "");
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="ID_PRIORIDADE"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={serviceLevels}
                                    getOptionLabel={(option) => option?.description || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Service Level"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            disabled={isEditing}
                                        />
                                    )}
                                    onChange={(_, newValue) => {
                                        field.onChange(newValue ? parseInt(newValue.id, 10) : "");
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="ID_FRETE_INCO"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={IncotermsFreightType}
                                    getOptionLabel={(option) => option?.description || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Incoterm Freight"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            disabled={isEditing}
                                        />
                                    )}
                                    onChange={(_, newValue) => {
                                        field.onChange(newValue ? newValue.description : "");
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="SLA_PRINCIPAL"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Principal SLA"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    inputProps={{ maxLength: 3 }}
                                    onChange={(e) => {
                                        const inputValue = e.target.value.replace(/\D/g, '');
                                        field.onChange(parseInt(inputValue));
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="SLA_SECUNDARIO"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Secondary SLA"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    inputProps={{ maxLength: 3 }}
                                    onChange={(e) => {
                                        const inputValue = e.target.value.replace(/\D/g, '');
                                        field.onChange(parseInt(inputValue));
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <Grid container justifyContent="flex-end" marginTop={2} spacing={2}>
                    <Grid>
                        <Button
                            variant="outlined"
                            onClick={handleCancel}
                            data-testid="cancel-button"
                        >
                            Cancel
                        </Button>
                    </Grid>
                    {!readOnly ? (
                        <Grid marginLeft={2}>
                            <Button
                                variant="contained"
                                type="submit"
                                data-testid="submit-button"
                            >
                                Confirm
                            </Button>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        </form>
    );
}
