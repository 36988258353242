import { CircularProgress } from "@mui/material";

type LoadingOverlayProps = {
  isLoading: boolean;
};

const LoadingOverlay = ({ isLoading }: LoadingOverlayProps) => {
  return isLoading ? (
    <div className="loading-overlay">
      <div className="loading-overlay-content">
        <CircularProgress color="primary" size={60} />
      </div>
    </div>
  ) : null;
};

export default LoadingOverlay;
