import { Button } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import DataTableDefault from '../../../components/DataTable'
import { SearchParams } from '../../../types/triage'
import React, { useState } from 'react'
import { Triage } from '../../../types/triage'
import { CarrierProps } from '../../../types/carrier'
import { useNavigate, useSearchParams } from 'react-router-dom'

type DashboardTriagesTableProps = {
  data: Triage[],
  count: number,
  carriers: CarrierProps[],
  params: IParams,
  searchParams: SearchParams,
  handleRequest: (key: any) => void
  onChangeSearchParam: (key: string) => { (e: any): void }
}

export default function DashboardTriagesTable({
  data,
  count,
  carriers,
  params,
  searchParams,
  handleRequest,
  onChangeSearchParam
}: DashboardTriagesTableProps) {
  const [urlSearchParams, setURLSearchParams] = useSearchParams();
  const [paginationModel, setPaginationModel] = useState({
    page: searchParams.page && !isNaN(+searchParams.page) ? +searchParams.page - 1 : 0,
    pageSize: searchParams.pageSize || 10,
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [menuRowId, setMenuRowId] = useState<number | null>(null);
  const [menuPosition, setMenuPosition] = useState<{ top: number, left: number } | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, rowId: number) => {
    const buttonRect = event.currentTarget.getBoundingClientRect();
    setAnchorEl(event.currentTarget);
    setMenuPosition({ top: buttonRect.bottom, left: buttonRect.left });
    setMenuRowId(rowId);
  };

  const handleClose = () => setAnchorEl(null);

  const handleTriageDetails = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, triage: Triage) => {
    const path = `/goods-triage/details/${triage.ID_TRIAGEM}`;
    return (event.ctrlKey || event.metaKey) ? window.open(window.location.origin + path, '_blank') : navigate(path);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'AGING',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
    },
    {
      field: 'WR',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return [row?.warehouse?.NUM_WAREHOUSE]
      },
    },
    {
      field: 'CARRIER',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      type: "singleSelect",
      renderCell: ({ row }) => {
        return [row?.DSC_CARRIER]
      },
      valueOptions: [carriers.map((carrier) => carrier.NOME_TRANSPORTADOR)],
    },
    {
      field: 'REFERENCE',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return [row?.triageVolumes.length > 0 && row?.triageVolumes?.[0].DSC_REFERENCIA_VOL]
      },
    },
    {
      field: 'PIECE NUMBER',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      renderCell: ({ row }) => {
        return [row?.QTDE_VOL_TOTAL || 0]
      },
      flex: 1,
    },
    {
      field: 'RECEIVED DATE',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return [`${new Date(row?.DATA_RECEB).getDay()}/${new Date(row?.DATA_RECEB).getMonth()}/${new Date(row?.DATA_RECEB).getFullYear()}`]
      },
    },
    {
      field: 'BIN LOCATION',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return [(row?.triageVolumes.length > 0 && row?.triageVolumes[0].DSC_LOCATION) || '']
      },
    },
    {
      field: 'STATUS',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return [row.ID_STATUS_TRIAGEM]
      }
    },
    {
      field: 'DSC_Actions',
      type: 'actions',
      headerName: 'Actions',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Button
            aria-describedby={id}
            onClick={(event) => handleTriageDetails(event, row)}
            style={{ border: '1px solid', borderRadius: '5px', paddingTop: 0, paddingBottom: 0, fontSize: '12px' }}
          >
            VIEW
          </Button>
        </>
      ),
    },
  ]

  return (
    <>
    <div style={{ overflowX: 'auto' }}>
      <DataTableDefault
        rowId={data?.map((row) => row.ID_TRIAGEM)}
        data-testid='unit-data-table'
        data={data}
        columns={columns}
        rowCount={count}
        onPaginationModelChange={(e: any) => {
          setPaginationModel(e)
          handleRequest({ page: e.page + 1, limit: e.pageSize });
          
          const newUrlSearchParams = new URLSearchParams(urlSearchParams);
          newUrlSearchParams.set('page', e.page + 1);
          newUrlSearchParams.set('pageSize', e.pageSize);
          setURLSearchParams(newUrlSearchParams);
        }}
        pagination
        paginationModel={paginationModel}
        initialState={{
          pagination: { paginationModel: { pageSize: params.limit } },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationMode="server"
        pinnedColumns={['DSC_Actions']}
      />
      </div>
    </>
  )
}
