import { useEffect, useMemo, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InvoiceHeader from "./InvoiceHeader";
import POContent from "./POContent";
import InvoiceContent from "./InvoiceContent";
import { AccordionSummary } from "../../../../components/Accordion/AccordionSummary";
import { Accordion } from "../../../../components/Accordion/Accordion";
import { useNavigate, useParams } from "react-router-dom";
import { getInvoice, validateInvoiceById, remove } from "../../../../services/invoiceDetails";
import { getLocal, getToken } from "../../../../storage/auth";
import { Invoice, InvoicesSections, InvoiceType } from "../../../../types/invoice";
import ReasonDeleteDialog from "../../../../components/Dialog/ReasonDeleteDialog";
import { enqueueSnackbar } from "notistack";
import InvoiceNotes from "./invoiceNotes";
import { InvoiceComposition } from "./InvoiceComposition";
import { ComboBoxType } from "../../../../types/general";
import { getCountries } from "../../../../services/countries";
import { getUnitsOfMeasurement } from "../../../../services/unitMeasurement";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import InvoicePackageDetails from "./InvoicePackageDetails";
import InvoiceDiscrepancy from "./invoiceDiscrepancy";
import WarehouseAccordion from "./warehouse";
import { formatNumberWithThousandsSeparator  } from "../../../utils/inputs";
import { InvoiceStatus } from "../../../../types/invoice";
import { isAxiosError } from "axios";
import { ServiceLevel } from "../../../../types/serviceLevel";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import TrackingStatusDialog from "./TrackingStatusDialog";

const InvoiceDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [userValidation, setUserValidation] = useState<{ claims: string[], id?: number }>();
  const [openReasonDeleteDialog, setOpenReasonDeleteDialog] = useState(false);
  const [hasData, setHasData] = useState<boolean>(false);
  const [hasDataInvoiceNotes, setHasInvoiceNotes] = useState<boolean>(false);
  const [hasDataInvoiceDiscrepancies, setHasInvoiceDiscrepancies] = useState<boolean>(false);
  const [hasDataInvoicePo, setHasInvoicePo] = useState<boolean>(false);
  const [countries, setCountries] = useState<ComboBoxType[]>([]);
  const [unitsOfMeasurement, setUnitsOfMeasurement] = useState<ComboBoxType[]>([]);
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>(
    Object.values(InvoicesSections).reduce((acc: { [key: string]: boolean }, section) => {
      acc[section] = false;
      return acc;
    }, {})
  );
  const [openServiceLevelDialog, setOpenServiceLevelDialog] = useState<boolean>(false);
  const [invoiceServiceLevel, setInvoiceServiceLevel] = useState<ServiceLevel>();
  const [invoicePoItemServiceLevel, setInvoicePoItemServiceLevel] = useState<ServiceLevel>();
  const [isOpenTrackingDialog, setIsOpenTrackingDialog] = useState(false);

  const fetchInvoiceByID = async (invoiceId: number) => {
    setIsLoading(true)
    try {
      const data = await getInvoice(invoiceId)
      setInvoice(data)
    } catch (err) {
      enqueueSnackbar('Could not fetch invoice', { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const getTokenSession = () => {
    const getUser = (getLocal("userInfo")) as any;
    const token = getToken();

    if (token) {
      setToken(token);
    }

    setUserValidation(JSON.parse(getUser));
  };

  const fetchCountries = async () => {
    try {
      const data = await getCountries();
      setCountries(data);
    } catch (error) {
      enqueueSnackbar("Error loading countries", { variant: "error" });
    }
  }

  const fetchUnitsOfMeasurement = async () => {
    try {
      const data = await getUnitsOfMeasurement();
      setUnitsOfMeasurement(data);
    } catch (error) {
      enqueueSnackbar("Error loading units of measurement", { variant: "error" });
    }
  }

  useEffect(() => {
    getTokenSession();
    fetchCountries();
    fetchUnitsOfMeasurement();
  }, [])

  useEffect(() => {
    const { invoicePackages, invoiceNotes, invoiceDiscrepancies } = invoice || {};

		setHasData(!!invoicePackages && invoicePackages.length > 0);
		setHasInvoiceNotes(!!invoiceNotes && invoiceNotes.length > 0);
		setHasInvoiceDiscrepancies(!!invoiceDiscrepancies && invoiceDiscrepancies.length > 0);
	}, [invoice]);

  const showWarehouseReceipt = useMemo(() => {
    return invoice?.ID_WAREHOUSE || ![
      InvoiceType.TRIAGE_DOMESTIC,
      InvoiceType.INBOUND_DOMESTIC,
    ].includes(invoice?.ID_TIPO_INVOICE!)
  }, [invoice?.ID_TIPO_INVOICE])

  const handleUpdate = (sectionNewStatus?: { [key: string]: boolean }) => {
    fetchInvoiceByID(+invoice?.ID_INVOICE!);
    if (sectionNewStatus) {
      setOpenSections({
        ...openSections,
        ...sectionNewStatus
      });
    }
  }

  useEffect(() => {
    if (id) {
      fetchInvoiceByID(+id)
    }
  }, [id])

  const handleConfirm = async (changeServiceLevel: null|'yes'|'no') => {
    setIsLoading(true);
    try {
      const response = await validateInvoiceById(Number(id), changeServiceLevel);
      setInvoice(response);
      enqueueSnackbar('Invoice validated', { variant: "success" });
      setOpenServiceLevelDialog(false);
      setInvoiceServiceLevel(undefined);
      setInvoicePoItemServiceLevel(undefined);
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 400 && error.response.data.object.invoiceServiceLevel) {
        setInvoiceServiceLevel(error.response.data.object.invoiceServiceLevel);
        setInvoicePoItemServiceLevel(error.response.data.object.invoicePoItemServiceLevel);
        setOpenServiceLevelDialog(true);
      } else {
        enqueueSnackbar('Could not validate invoice', { variant: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  }

  const onRemove = async () => setOpenReasonDeleteDialog(true)

  const onLog = () => {
    window.location.href = `/invoice/log/${id}`;
  }

  const onToggleTrackingStatus = () => setIsOpenTrackingDialog((status) => !status);

  const onReturnToPreviousPage = () => {
    navigate(-1);
  };

  const isDisabled = useMemo(() => [
    InvoiceStatus.SHIPMENT_AUTHORIZED, 
    InvoiceStatus.INTERNATIONAL_TRANSIT, 
    InvoiceStatus.CUSTOMS_CLEARANCE, 
    InvoiceStatus.DOMESTIC_TRANSIT, 
    InvoiceStatus.CONCLUDED, 
    InvoiceStatus.FINALIZED,
    InvoiceStatus.CANCELED
  ].includes(invoice?.ID_STATUS_INVOICE as InvoiceStatus), [invoice?.ID_STATUS_INVOICE]);

  return (
    <Grid marginTop={4}>
      <LoadingOverlay isLoading={isLoading} />
      <Grid container spacing={1} direction='column' marginBottom={4}>
        <Grid item xs={10}>
          <Typography variant='h5'>Invoice Details</Typography>
        </Grid>
      </Grid>

      <Paper
        variant='outlined'
        sx={{ marginTop: 3, paddingX: 2, paddingBottom: 2 }}
      >
        <InvoiceHeader invoice={invoice} />
      </Paper>

      <Paper
        variant='outlined'
        sx={{ marginTop: 3, paddingX: 2, paddingBottom: 2 }}
      >
        <InvoiceContent invoice={invoice} />
      </Paper>

      <Paper
        variant='outlined'
        sx={{ marginTop: 3, paddingX: 2, paddingBottom: 2 }}
      >
        <POContent invoice={invoice} />
      </Paper>

      <Accordion
        sx={{
          '&.MuiAccordion-root': { border: 0, marginBottom: 3, marginTop: 1 },
        }}
        disabled={!invoice?.ID_PO}
        onChange={() => setOpenSections({
          ...openSections,
          [InvoicesSections.INVOICE_DETAILS]: !openSections[InvoicesSections.INVOICE_DETAILS]
        })}
        expanded={openSections[InvoicesSections.INVOICE_DETAILS]}
      >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            applyOpacity={!hasDataInvoicePo}
          >
            <Typography>Invoice Details</Typography>
          </AccordionSummary>
          <InvoiceComposition
            invoice={invoice}
            countries={countries}
            unitsOfMeasurement={unitsOfMeasurement}
            onClose={handleUpdate}
            setHasInvoicePo={setHasInvoicePo}
          />
      </Accordion>

      <Accordion
        sx={{
          '&.MuiAccordion-root': { border: 0, marginBottom: 3, marginTop: 1 },
        }}
        onChange={() => setOpenSections({
          ...openSections,
          [InvoicesSections.PACKAGE_DETAILS]: !openSections[InvoicesSections.PACKAGE_DETAILS]
        })}
        expanded={openSections[InvoicesSections.PACKAGE_DETAILS]}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          applyOpacity={!hasData}
        >
          <Typography>Package Details</Typography>
        </AccordionSummary>
        <InvoicePackageDetails invoice={invoice as Invoice} onUpdate={handleUpdate} isDisabled={isDisabled} />
      </Accordion>
      
      {showWarehouseReceipt  && (
        <WarehouseAccordion
          invoice={invoice}
          token={token ? token : undefined}
          idUser={userValidation?.id}
          onChange={() => setOpenSections({
            ...openSections,
            [InvoicesSections.WAREHOUSE_RECEIPT]: !openSections[InvoicesSections.WAREHOUSE_RECEIPT]
          })}
          expanded={openSections[InvoicesSections.WAREHOUSE_RECEIPT]}
          isDisabled={isDisabled}
        />
      )}

      <Accordion
        sx={{
          '&.MuiAccordion-root': { border: 0, marginBottom: 3, marginTop: 1 },
        }}
        onChange={() => setOpenSections({
          ...openSections,
          [InvoicesSections.INVOICE_NOTES]: !openSections[InvoicesSections.INVOICE_NOTES]
        })}
        expanded={openSections[InvoicesSections.INVOICE_NOTES]}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          applyOpacity={!hasDataInvoiceNotes}
        >
          <Typography>Invoice Notes</Typography>
        </AccordionSummary>
        <InvoiceNotes invoice={invoice as Invoice} onUpdate={handleUpdate}></InvoiceNotes>
      </Accordion>

      <Accordion
        sx={{
          '&.MuiAccordion-root': { border: 0, marginBottom: 3, marginTop: 1 },
        }}
        onChange={() => setOpenSections({
          ...openSections,
          [InvoicesSections.DISCREPANCY]: !openSections[InvoicesSections.DISCREPANCY]
        })}
        expanded={openSections[InvoicesSections.DISCREPANCY]}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          applyOpacity={!hasDataInvoiceDiscrepancies}
        >
          <Typography>Discrepancy</Typography>
        </AccordionSummary>
        <InvoiceDiscrepancy invoice={invoice} onUpdate={handleUpdate} isDisabled={isDisabled} />
      </Accordion>

      <Grid container direction="column" alignItems="flex-end" marginBottom={5}>
        <Grid item>
          <Typography fontSize={18}>Extra Charges: {formatNumberWithThousandsSeparator(invoice?.VALOR_TAXAS_EXTRAS)?.formatted}</Typography>
        </Grid>
        <Grid item>
          <Typography fontSize={18}>Total Invoice Value: {formatNumberWithThousandsSeparator(invoice?.VALOR_TOTAL)?.formatted}</Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent='space-between'>
        <Button
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            color: "currentColor",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              color: "currentColor",
            },
          }}
          variant="contained"
          color="secondary"
          onClick={onReturnToPreviousPage}
        >
          Return
        </Button>
        {userValidation?.claims.includes('Invoice - Cancel') && (
          <Button
            variant="outlined"
            onClick={onRemove}
            disabled={isDisabled}
          >
            Cancel Invoice
          </Button>
        )}

        <Grid sx={{ display: 'flex', gap: 3 }}>
          {(invoice && [InvoiceType.INBOUND_DOMESTIC, InvoiceType.TRIAGE_DOMESTIC].includes(invoice.ID_TIPO_INVOICE)) && [InvoiceStatus.DOMESTIC_TRANSIT, InvoiceStatus.FINALIZED].includes(invoice.ID_STATUS_INVOICE) && (
            <Button
              variant="outlined"
              onClick={onToggleTrackingStatus}
              color="warning"
              endIcon={<InsertDriveFileIcon />}
            >
              Tracking Status
            </Button>
          )}

          <Button
            variant="outlined"
            onClick={onLog}
          >
            Log
          </Button>
        </Grid>
        <Button
          variant="contained"
          type="submit"
          onClick={() => handleConfirm(null)}
          disabled={isDisabled}
        >
          Confirm
        </Button>
      </Grid>
        
      <TrackingStatusDialog 
        onClose={onToggleTrackingStatus}
        carrier={invoice?.parceiroDomesticCarrier?.NOME_PARCEIRO}
        data={invoice?.trackingNumber}
        open={isOpenTrackingDialog}
      />

      <ReasonDeleteDialog
        open={openReasonDeleteDialog}
        onCancel={() => {
          setOpenReasonDeleteDialog(false)
          handleUpdate()
        }}
        onOk={(reason: string, forceWarehouse: boolean | undefined) => {
          return remove(+invoice?.ID_INVOICE!, reason, forceWarehouse)
        }}
      />

      <Dialog open={openServiceLevelDialog} onClose={() => setOpenServiceLevelDialog(false)}>
        <DialogTitle>
          <Typography>The service level defined by the invoice composition {invoicePoItemServiceLevel?.DSC_PRIORIDADE} is different from the invoice's service level {invoiceServiceLevel?.DSC_PRIORIDADE}.</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>Would you like to change the service level to {invoicePoItemServiceLevel?.DSC_PRIORIDADE}?</Typography>
        </DialogContent>
        <DialogActions>
          <Button data-testid="change-service-level-no-button" onClick={() => handleConfirm('no')}>No</Button>
          <Button data-testid="change-service-level-no-button" onClick={() => handleConfirm('yes')} variant="contained">Yes</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default InvoiceDetails
