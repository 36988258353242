import { useCallback, useEffect, useState } from "react";

import { List } from "../../types/resquest";
import { PartnerService } from "../../types/partner";
import { ComboBoxType } from "../../types/general";
import { api } from "../../lib/api";
import { Combo } from "../../types/combo";

const entity = "transportationModes";

export const useList = (
  token?: string,
):
  | Promise<{ combo: Combo[]; isLoading: boolean }>
  | any => {
  const [isLoading, setIsLoading] = useState(true);
  const [combo, setCombo] = useState<Combo[]>([{} as Combo]);

  useEffect(() => {
    (async function () {
      const { data } = await api.get(`${entity}/getCombo`);
      setCombo(data);
    })()
  }, [token]);

  return { combo };
};