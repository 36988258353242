import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Autocomplete,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { Center } from "../../../../../types/center";
import { CompanyCombo } from "../../../../../types/company";

let centerScheme = yup.object({
  NOME_CENTRO: yup.string().max(40).required(),
  DSC_SIGLA: yup.string().max(4).required(),
  DSC_CNPJ: yup.string().max(30).notRequired(),
  COD_MFIR: yup.string().max(10).notRequired().nullable(),
  ID_EMPRESA: yup.number().required(),
  ID_PAIS: yup.number().required(),
  DSC_ENDERECO: yup.string().max(100).required(),
  DSC_CIDADE: yup.string().max(50).required(),
  DSC_ESTADO: yup.string().max(50).required(),
  IND_PLANTA_VSS: yup.boolean().notRequired(),
  IND_PLANTA_BLOQUEADA: yup.boolean().notRequired(),
  IND_LICENCA_OGTM: yup.boolean().notRequired(),
  DSC_CX_POSTAL: yup.string().max(30).notRequired(),
});

interface CenterFormProps {
  center: Center;
  onSubmit: (center: Center) => void;
  onCancel: () => void;
  companies: any[];
  countries: any[];
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly?: boolean;
}

export default function CenterForm({
  center,
  onSubmit,
  onCancel,
  companies = [],
  countries = [],
  isLoading = false,
  errors = [],
  controlEdit,
  readOnly
}: CenterFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(centerScheme),
  });

  const [erroMap, setErroMap] = useState<string[]>([]);

  useEffect(() => {
    setErroMap(errors);
  }, [errors]);

  useEffect(() => {
    if (center.hasOwnProperty("NOME_CENTRO")) {
      reset(center);
    }
  }, [center, reset]);

  const handleFormSubmit = (newCenter: any) => {
    setErroMap([]);
    onSubmit(newCenter);
  };

  const handleCancel = () => {
    setErroMap([]);
    onCancel();
  };

  return (
    <>
      <form
        style={{ width: "100%" }}
        onSubmit={handleSubmit(handleFormSubmit)}
        data-testid="center-form"
      >
        {erroMap?.map((error) => (
          <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
            {error}
          </Alert>
        ))}
        <Grid
          container
          spacing={2}
          marginTop={1}
          border={1}
          padding={2}
          borderColor={"#7b7b7b"}
          borderRadius={2}
          alignItems="center"
        >
          {controlEdit.loading ? (
            <>Loading</>
          ) : (
            <>
              <Grid item lg={3} md={3} xs={12}>
                <Controller
                  control={control}
                  render={({ field: { value } }) => (
                    <TextField
                      fullWidth
                      type="text"
                      label="MFIR"
                      disabled={controlEdit.isInUse ||readOnly}
                      size="small"
                      defaultValue={value}
                      error={!!formErrors.COD_MFIR}
                      InputLabelProps={{ shrink: !!value }}
                      {...register("COD_MFIR")}
                      inputProps={{ maxLength: 10 }}
                    />
                  )}
                  name="COD_MFIR"
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <Controller
                  control={control}
                  render={({ field: { value } }) => (
                    <TextField
                      // style={{ minWidth: "400px" }}
                      fullWidth
                      type="text"
                      label="Name"
                      variant="outlined"
                      size="small"
                      defaultValue={value}
                      disabled={controlEdit.isInUse || readOnly}
                      error={!!formErrors.NOME_CENTRO}
                      InputLabelProps={{ shrink: !!value }}
                      {...register("NOME_CENTRO")}
                      inputProps={{ maxLength: 40, 'data-testid': 'center-name-input' }}
                    />
                  )}
                  name="NOME_CENTRO"
                />
              </Grid>
              <Grid item lg={3} md={3} xs={12}>
                <Controller
                  control={control}
                  render={({ field: { value } }) => (
                    <TextField
                      fullWidth
                      type="text"
                      label="Abbreviation"
                      size="small"
                      disabled={controlEdit.isInUse || readOnly}
                      error={!!formErrors.DSC_SIGLA}
                      defaultValue={center.DSC_SIGLA}
                      InputLabelProps={{ shrink: !!value }}
                      {...register("DSC_SIGLA")}
                      inputProps={{ maxLength: 4, 'data-testid': 'dsc_sigla-input' }}
                    />
                  )}
                  name="DSC_SIGLA"
                />
              </Grid>
            </>
          )}

          <Grid item lg={5} md={5} xs={12}>
            <Controller
              defaultValue={center.GE_ID_EMPRESA || undefined}
              control={control}
              render={({ field: { value, ref, onBlur, name, onChange, ...field } }) => (
                <Autocomplete
                  options={companies}
                  disabled={controlEdit.isInUse || readOnly}
                  getOptionLabel={(option: CompanyCombo) =>
                    `${option.description}`
                  }
                  value={
                    companies?.findLast((item: any) => item.id === value) ||
                    null
                  }
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      {...field}
                      label="Select Company"
                      size="small"
                      inputRef={ref}
                      disabled={controlEdit.isInUse || readOnly}
                      error={!!formErrors.ID_EMPRESA}
                      variant="outlined"
                      data-testid="company-plant-input"
                    />
                  )}
                  onChange={(_: any, data: any) => {
                    onChange(data?.id);
                  }}
                />
              )}
              name="ID_EMPRESA"
            />
          </Grid>
          <Grid item lg={3} md={3} xs={12}>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  // style={{ minWidth: "320px" }}
                  fullWidth
                  type="text"
                  label="Document"
                  size="small"
                  disabled={controlEdit.isInUse || readOnly}
                  error={!!formErrors.DSC_CNPJ}
                  defaultValue={center.DSC_CNPJ}
                  InputLabelProps={{ shrink: !!value }}
                  {...register("DSC_CNPJ")}
                  inputProps={{ maxLength: 30 }}
                />
              )}
              name="DSC_CNPJ"
            />
          </Grid>
          <Grid item md={4} xl={4} xs={12}>
            <Controller
              defaultValue={center.GP_ID_PAIS || undefined}
              control={control}
              render={({ field: { value, ref, onBlur, name, onChange, ...field } }) => (
                <Autocomplete
                  options={countries}
                  disabled={controlEdit.isInUse || readOnly}
                  getOptionLabel={(option) => {
                    return `${option.description}`;
                  }}
                  value={
                    countries?.findLast((item) => item.id === value) ||
                    null
                  }
                  defaultValue={center.GP_ID_PAIS || undefined}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      {...field}
                      label="Select Country"
                      size="small"
                      disabled={controlEdit.isInUse || readOnly}
                      inputRef={ref}
                      error={!!formErrors.ID_PAIS}
                      variant="outlined"
                      data-testid="ID_PAIS-input"
                    />
                  )}
                  onChange={(_: any, data: any) => {
                    onChange(data?.id);
                  }}
                />
              )}
              name="ID_PAIS"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="Address"
                  disabled={controlEdit.isInUse || readOnly}
                  size="small"
                  error={!!formErrors.DSC_ENDERECO}
                  defaultValue={center.DSC_ENDERECO}
                  InputLabelProps={{ shrink: !!value }}
                  {...register("DSC_ENDERECO")}
                  inputProps={{ maxLength: 100, 'data-testid': 'DSC_ENDERECO-input' }}
                />
              )}
              name="DSC_ENDERECO"
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  type="text"
                  disabled={controlEdit.isInUse || readOnly}
                  label="City"
                  size="small"
                  error={!!formErrors.DSC_CIDADE}
                  defaultValue={center.DSC_CIDADE}
                  InputLabelProps={{ shrink: !!value }}
                  {...register("DSC_CIDADE")}
                  inputProps={{ maxLength: 50, 'data-testid': 'DSC_CIDADE-input' }}
                />
              )}
              name="DSC_CIDADE"
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="State"
                  size="small"
                  disabled={controlEdit.isInUse || readOnly}
                  defaultValue={center.DSC_ESTADO}
                  InputLabelProps={{ shrink: !!value }}
                  error={!!formErrors.DSC_ESTADO}
                  {...register("DSC_ESTADO")}
                  inputProps={{ maxLength: 50, 'data-testid': 'DSC_ESTADO-input' }}
                />
              )}
              name="DSC_ESTADO"
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              render={({ field: { value }}) => (
                <TextField
                  fullWidth
                  type="text"
                  label="Postal Code"
                  size="small"
                  disabled={controlEdit.isInUse || readOnly}
                  error={!!formErrors.DSC_CX_POSTAL}
                  defaultValue={value}
                  InputLabelProps={{ shrink: !!value }}
                  {...register("DSC_CX_POSTAL")}
                  inputProps={{ maxLength: 30 }}
                />
              )}
              name="DSC_CX_POSTAL"
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              render={({ field: { value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      disabled={controlEdit.isInUse || readOnly}
                      checked={!!value}
                      inputProps={{ "aria-label": "controlled" }}
                      {...register("IND_PLANTA_VSS")}
                    />
                  }
                  labelPlacement="start"
                  label="VSS"
                />
              )}
              name="IND_PLANTA_VSS"
              control={control}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              render={({ field: { value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      disabled={readOnly}
                      inputProps={{ "aria-label": "controlled" }}
                      checked={!!value}
                      {...register("IND_PLANTA_BLOQUEADA")}
                    />
                  }
                  labelPlacement="start"
                  label="Blocked"
                />
              )}
              name="IND_PLANTA_BLOQUEADA"
              control={control}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              render={({ field: { value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      disabled={readOnly}
                      inputProps={{ "aria-label": "controlled" }}
                      checked={!!value}
                      {...register("IND_LICENCA_OGTM")}
                    />
                  }
                  labelPlacement="start"
                  label="Validate OGTM"
                />
              )}
              name="IND_LICENCA_OGTM"
              control={control}
            />
          </Grid>
          <Grid container justifyContent="flex-end" marginTop={4}>
            <Grid>
              <Button
                variant="outlined"
                onClick={handleCancel}
                disabled={isLoading}
                data-testid="cancel-button"
              >
                Cancel
              </Button>
            </Grid>
            {!readOnly ? (
            <Grid marginLeft={2}>
              <Button
                variant="contained"
                disabled={isLoading}
                type="submit"
                data-testid="submit-button"
              >
                Confirm
              </Button>
            </Grid>
            ) : null}
          </Grid>
        </Grid>
      </form>
    </>
  );
}
