import { useState } from "react";
import * as yup from "yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { create } from "../../../../services/invoiceNotes";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InvoiceNotesTable from "./table/tableNotes";
import { Invoice } from "../../../../types/invoice";
import { InvoiceNote } from "../../../../types/invoiceNote";
import { enqueueSnackbar } from "notistack";

type InvoiceNotesProps = {
  invoice: Invoice;
  onUpdate: () => void;
};

export const invoiceNoteSchema = yup.object({
  DSC_NOTA: yup.string().required('Description note is required'),
})

const InvoiceNotes = ({ invoice, onUpdate }: InvoiceNotesProps) => {
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const { register, handleSubmit, control } = useForm({
    resolver: yupResolver(invoiceNoteSchema),
  });

  const onSubmit = async (data: Partial<InvoiceNote>): Promise<void> => {
    try {
      await create({
        ID_INVOICE: invoice.ID_INVOICE,
        ...data,
      });
      setOpenCreateModal(false);
      enqueueSnackbar("Note created successfully", { variant: "success" });
      onUpdate();
    } catch (error) {
      enqueueSnackbar("Error to create note", { variant: "error" });
    }
  };

  return (
    <div style={{ padding: "10px" }}>
      <Button
        variant="outlined"
        onClick={() => setOpenCreateModal(true)}
        style={{ marginTop: "8px", marginBottom: "8px" }}
      >
        New
      </Button>
      {invoice?.invoiceNotes && (
        <InvoiceNotesTable data={invoice.invoiceNotes}></InvoiceNotesTable>
      )}

      <Dialog
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          Invoice Notes
          <Button onClick={() => setOpenCreateModal(false)}>
            <CloseIcon />
          </Button>
        </DialogTitle>
        <DialogContent style={{ paddingTop: '15px' }} dividers>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="DSC_NOTA"
                  control={control}
                  render={({ fieldState }) => (
                    <TextField
                      fullWidth
                      label="Notes"
                      error={!!fieldState.error}
                      multiline={true}
                      minRows={8}
                      inputProps={{ maxLength: 1000 }}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                      {...register("DSC_NOTA")}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <DialogActions  style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderTop: '1px solid rgba(0, 0, 0, 0.2)',
              marginTop: '15px',
              marginBottom: '15px'
            }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    color: "white",
                  },
                }}
                onClick={() => setOpenCreateModal(false)}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                Add
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InvoiceNotes;
