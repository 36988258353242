"use client";
import { useNavigate } from "react-router-dom";
import { Alert, Backdrop, Box, Button } from "@mui/material";

export default function Logout() {
  const navigate = useNavigate();

  return <>
    <Backdrop
      sx={{ backgroundColor: '#1976d2', color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >  
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
       
        <img
          src="/images/logo.png"
          alt="Embraer"
          data-testid="logo-header"
        />
        <Alert sx={{ marginTop: 2 }} severity="info">Logged out successfully.</Alert>
        <Button sx={{ marginTop: 4, color: '#1976d2', backgroundColor: '#fff', '&:hover': {
          backgroundColor: '#e1f5fe',
        } }} variant="contained" onClick={() => navigate('/')}>Login</Button>
      </Box>
    </Backdrop>
  </>
}
