import { useEffect, useState } from "react";
import { List } from "../../types/resquest";
import { api } from "../../lib/api";
import { DiscrepancyProps } from "../../types/discrepancy";
import { getToken } from "../../storage/auth";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
}

const entity = "discrepancies";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string
): {
  data: DiscrepancyProps[];
  meta: List;
  error: Error;
  isLoading: boolean;
} => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<DiscrepancyProps[]>([]);
  const [meta, setMeta] = useState<List>({} as List);
  const [error] = useState<Error>({} as Error);

  useEffect(() => {
    if (token) {
      (async function () {
        setIsLoading(true);
        const response = await dataTableUtils.findPaginate(params);
        const {total, data, currentPage, totalPages, limit} = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
        setIsLoading(false)
      })();
    }
  }, [params, token]);

  return { data, meta, error, isLoading };
};

export const create = async (
  discrepancy?: DiscrepancyProps
): Promise<DiscrepancyProps> => {
  const token = getToken();
  const { data } = await api.post(`/${entity}`, discrepancy, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const getDiscrepancies = async () => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/getComboManual`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
}

export const findById = async (id: number) => {
  const token = getToken();
  return await api.get(`/${entity}/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });
};

export const isInUse = async (id: number | string): Promise<any> => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/isInUse/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const update = async (
  discrepancy?: DiscrepancyProps
): Promise<DiscrepancyProps> => {
  const token = getToken();

  const { data } = await api.patch(
    `/${entity}/${discrepancy?.ID_DIVERGENCIA}`,
    discrepancy,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );

  return data;
};
export const deleteConfimation = async (id: number): Promise<boolean> => {
  const token = getToken();

  const { data } = await api.delete(`/${entity}/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

    return data;
};

export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: "Description", value: "DSC_DIVERGENCIA" },
    { label: "Message", value: "DSC_MENSAGEM" },
    {
      label: "Status",
      value: (row: any) => (row.ATIVO ? "Active" : "Inactive"),
    },
  ]
  await dataTableUtils.exportAll({params, xlsxData, filename: "Discrepancy"});
};
