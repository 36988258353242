import { Alert, Box, Button, Grid, TextField, Autocomplete, FormControlLabel, Checkbox } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { ComboBoxType } from "../../../../../types/general";

interface SlaSearchProps {
  center: ComboBoxType[];
  invoiceType: ComboBoxType[];
  TriageInvoiceStatus: ComboBoxType[];
  TransportationMode: ComboBoxType[];
  serviceLevels: ComboBoxType[];
  IncotermsFreightType: ComboBoxType[];
  discrepancy: ComboBoxType[];
  onSearch: (Sla: any) => void;
  isLoading?: boolean;
  errors?: string[];
}

export default function Search({
    center,
    invoiceType,
    TriageInvoiceStatus,
    TransportationMode,
    serviceLevels,
    IncotermsFreightType,
    discrepancy,

    onSearch,
    errors = [],
  }: SlaSearchProps) {
  const {
    handleSubmit,
    formState: { errors: formErrors },
    control,
  } = useForm();

  const handleFormSubmit = (sla: any) => {
    if (
        (sla.all_discrepancies && sla.specific_discrepancies) ||
        (!sla.all_discrepancies && !sla.specific_discrepancies)
    )
      sla.TODAS_DIVERGENCIAS = null;
    else
      sla.TODAS_DIVERGENCIAS =
          sla.all_discrepancies && !sla.specific_discrepancies;

    onSearch(sla);
  };

  return (
      <form
          onSubmit={handleSubmit(handleFormSubmit)}
          data-testid="consolidation-center-avanced-search"
      >
        {errors.map((error) => (
            <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
              {error}
            </Alert>
        ))}

        <Grid container spacing={2} marginTop={2} marginBottom={2}>
          <Grid item xs={12} md={6} lg={3}>
            <Controller
                name="ID_CENTRO"
                control={control}
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        options={center}
                        getOptionLabel={(option) => option?.description || ""}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Company - Plant"
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        )}
                        onChange={(_, newValue) => {
                          field.onChange(newValue ? parseInt(newValue.id, 10) : "");
                        }}
                    />
                )}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Controller
                name="ID_TIPO_INVOICE"
                control={control}
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        options={invoiceType}
                        getOptionLabel={(option) => option?.description || ""}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Invoice Type"
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        )}
                        onChange={(_, newValue) => {
                          field.onChange(newValue ? newValue.description : "");
                        }}
                    />
                )}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Controller
                name="ID_STATUS_INVOICE"
                control={control}
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        options={TriageInvoiceStatus}
                        getOptionLabel={(option) => option?.description || ""}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Status Invoice"
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        )}
                        onChange={(_, newValue) => {
                          field.onChange(newValue ? newValue.description : "");
                        }}
                    />
                )}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Controller
                name="ID_MODAL"
                control={control}
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        options={TransportationMode}
                        getOptionLabel={(option) => option?.description || ""}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Modal"
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        )}
                        onChange={(_, newValue) => {
                          field.onChange(newValue ? newValue.description : "");
                        }}
                    />
                )}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Controller
                name="ID_PRIORIDADE"
                control={control}
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        options={serviceLevels}
                        getOptionLabel={(option) => option?.description || ""}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Service Level"
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        )}
                        onChange={(_, newValue) => {
                          field.onChange(newValue ? parseInt(newValue.id, 10) : "");
                        }}
                    />
                )}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Controller
                name="ID_FRETE_INCO"
                control={control}
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        options={IncotermsFreightType}
                        getOptionLabel={(option) => option?.description || ""}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Incoterm Freight"
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        )}
                        onChange={(_, newValue) => {
                          field.onChange(newValue ? newValue.description : "");
                        }}
                    />
                )}
            />
          </Grid>

          <Grid item md={6} xs={12} lg={4}>
            <Box display="flex" alignItems="center">
              <Box marginRight={4} marginLeft={1}>Discrepancies</Box>
              <Controller
                  name="all_discrepancies"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                          value={!!value}
                          control={<Checkbox checked={!!value} />}
                          onChange={(e) => onChange(e.target.checked)}
                          label="All"
                      />
                  )}
              />
              <Controller
                  name="specific_discrepancies"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                          value={!!value}
                          control={<Checkbox checked={!!value} />}
                          onChange={(e) => onChange(e.target.checked)}
                          label="Specific"
                      />
                  )}
              />
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Button
                variant="contained"
                type="submit"
                fullWidth
                data-testid="search-button"
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
  );
}
