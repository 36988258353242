import Invoice from ".";
import InvoiceDatails from "./invoiceDetails";
import InvoiceLog from "./log";

export const invoiceRoutes = [
  {
    path: "/invoice",
    element: <Invoice />,
  },
  {
    path: "/invoice/log/:id",
    element: <InvoiceLog />,
  },
  {
    path: "/invoice/details/:id",
    element: <InvoiceDatails />,
  },
  {
    path: "/invoice/:id",
    element: <Invoice />,
  }
];
