import { useEffect, useState } from "react";

import { api } from "../../lib/api";

import {
  Webgroup,
  FreightForwarder,
  ListPermissionsClass,
  Permission,
} from "../../types/accessControl";
import { List } from "../../types/resquest";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
  orderField?: string;
}

const entity = "permissions";

export const useList = (
    params?: IList
): Promise<{
  data: Webgroup[];
  meta: List;
  isLoading: boolean;
  permissionsClass: ListPermissionsClass[];
  freightForwarder: FreightForwarder[];
}> => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Webgroup[]>([{} as Webgroup]);
  const [meta, setMeta] = useState<List>({} as List);
  const [permissionsClass, setPermissionsClass] = useState<ListPermissionsClass[]>([]);
  const [freightForwarder, setFreightForwarder] = useState<FreightForwarder[]>([]);
  const [accessControlId, setAccessControlId] = useState<string | number | undefined>();
  const [accessControl, setAccessControl] = useState<Webgroup>({ DSC_WEBGROUP: "" } as Webgroup);
  const [listFreightForwarder, setListFreightForwarder] = useState<FreightForwarder[]>([]);

  const fillFreightForwarder = (permissionsSaved: { gtsWebgroupFreightForwarder: any[]; }) => {
    const freightForwarderSelected =
        permissionsSaved.gtsWebgroupFreightForwarder.map(
            (freightForwarderSaved) => freightForwarderSaved.ID_AGENTE_CARGA
        );
    const localFreightForwarder = [...freightForwarder];

    const foundToAdd = localFreightForwarder.filter(
        (freightForwarderOption) =>
            freightForwarderSelected.includes(freightForwarderOption.id)
    );
    setListFreightForwarder(foundToAdd);
  };

  useEffect(() => {
    (async () => {
        setIsLoading(true);
        const response = await api.get(`/${entity}/findPaginate/webGroup`, { params })
        const { total, data, currentPage, totalPages, limit } = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
      setIsLoading(false);
    })();
  }, [params]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtém as permissões
        const permissions = await getListPermission();
        setPermissionsClass(permissions);

        // Obtém os freight forwarders
        const freightForwarders = await getListFreightForwarder();
        setFreightForwarder(freightForwarders);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    // Chama a função assíncrona dentro do useEffect
    fetchData();
  }, []);



  useEffect(() => {
    const fillPermission = (permissionsSaved: { gtsWebgroupFunctionality: any[]; }) => {
      const functionsSaved = permissionsSaved?.gtsWebgroupFunctionality
          .filter((functionality: Permission) => {
            return functionality.ATIVO
          })
          .map((permission: { functionality: any; }) => {
            return permission.functionality
          });

      const permissionFilled = permissionsClass.map((permissions) => {
        permissions.functionality = permissions.functionality.map(
            (functionality) => {
              functionsSaved.forEach((functionSaved: { ID_FUNCIONALIDADE: number; }) => {
                if (
                    functionality.ID_FUNCIONALIDADE ===
                    functionSaved.ID_FUNCIONALIDADE
                ) {
                  functionality.ATIVO = true;
                }
              });
              return functionality;
            }
        );
        return permissions;
      });
      setPermissionsClass(permissionFilled);
    };

    const fetchPermissions = async () => {
      if (accessControlId) {
        try {
          const permissionsResponse = await findPermissionById(accessControlId);
          const permissionsSaved = permissionsResponse.object;
          setAccessControl(permissionsSaved);
          // fillFreightForwarder(permissionsSaved);

          if (freightForwarder.length > 0) {
            fillPermission(permissionsSaved);
            fillFreightForwarder(permissionsSaved);
          }
        } catch (error) {
          console.error('Error fetching permissions:', error);
        }
      }
    };

    // Only proceed if freightForwarder has data
    if (freightForwarder.length > 0) {
      fetchPermissions();
    }
  }, [accessControlId, freightForwarder]);


  return {
    data,
    meta,
    isLoading,
    permissionsClass,
    setPermissionsClass,
    freightForwarder,
    accessControlId,
    setAccessControlId,
    setFreightForwarder,
    fillFreightForwarder,
    accessControl,
    setAccessControl,
    listFreightForwarder,
    setListFreightForwarder,
  };
};

export const getListFreightForwarder = async (): Promise<
    FreightForwarder[]
> => {
  const { data } = await api.get(`/freightForwarders/getCombo`);

  return data;
};

export const getListPermission = async (): Promise<ListPermissionsClass[]> => {
  let { data } = await api.get(`/${entity}/list/class/functionality`, {
    params: { page: 1, limit: 100, orderField: "DESCRICAO" },
  });

  return data;
};

export const findPermissionById = async (id: string | number) => {
  const { data } = await api.get(`/${entity}/list/${id}/webgroup`);
  return data;
};

export const update = async (
    id: number | string,
    accessControl?: Webgroup
): Promise<Webgroup> => {
  const { data } = await api.put(
      `/${entity}/edit/${id}/webgroup`,
      accessControl,
  );

  return data;
};
export const deleteAccessControl = async (
    id: number | string
): Promise<Webgroup> => {
  const { data } = await api.delete(`/${entity}/delete/${id}/webgroup`);

  return data;
};

export const create = async (accessControl?: Webgroup): Promise<Webgroup> => {
  const { data } = await api.post(
      `/${entity}/add/webgroup`,
      accessControl,
  );

  return data;
};
