import { ChangeEvent, useRef, useState } from "react";
import './InputUploadWithFileText.css';
import { Box, Button, TextField } from "@mui/material";

interface InputUploadProps {
  onInput: (event: ChangeEvent<HTMLInputElement> | any) => void;
  accept?: string
}

export default function InputUploadWithFileText({ onInput, accept = '*' }: InputUploadProps) {
  const [filename, setFilename] = useState('');
  const inputFile = useRef<HTMLInputElement>(null);
  const [dragging, setDragging] = useState(false);

  const triggerOnInput = (event) => {
    setFilename(event?.target?.files?.[0]?.name);
    onInput(event);
  }

  const handleOpenInput = () => {
    inputFile?.current?.click();
  }

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    setDragging(false);

    if (event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      setFilename(file.name);
      const syntheticEvent = {
        target: {
          files: event.dataTransfer.files
        },
        customEvent: true,
      };
      onInput(syntheticEvent);
    }
  };

  return (
    <Box 
      style={{ 
        display: 'flex', 
        border: dragging ? '2px dashed #3f51b5' : 'none',
        borderRadius: '4px',
        backgroundColor: dragging ? '#f0f0f0' : 'transparent',
        flex: 0
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <input
        ref={inputFile}
        style={{ display: 'none' }}
        data-testid="file-upload"
        id="files"
        className="file-input"
        type="file"
        onInput={triggerOnInput}
        accept={accept}
      />
      <TextField
        sx={{
          flex: 1,
          width: 280,
          '& fieldset': { 
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0',
          }
        }}
        value={filename ?? ''}
        label="File"
        variant="outlined"
        size="small"
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
      <Button
        sx={{
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0',
        }}
        variant="contained"
        onClick={() => handleOpenInput()}
      >
        Search file
      </Button>
    </Box>
  )
}