import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import { FC, useState } from 'react';
import { Invoice } from '../../../types/invoice';
import { GridCloseIcon } from '@mui/x-data-grid';
import { updateHAWB } from '../../../services/invoice';
import { enqueueSnackbar } from 'notistack';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { Axios, isAxiosError } from 'axios';

type HouseDialogProps = {
  open: boolean;
  onClose: () => void;
  invoice: Invoice;
  onUpdate: () => void;
};

const HouseDialog: FC<HouseDialogProps> = ({ open, onClose, invoice, onUpdate }) => {
  const [newHAWB, setNewHAWB] = useState('');
  const [alertModal, setAlertModal] = useState(false)
  const [isLoading,setIsLoading] = useState(false)
  const handleSave = async () => {
    setIsLoading(true)
    try {
      await updateHAWB({...invoice.warehouse, DSC_HOUSE: newHAWB, ID_INVOICE: invoice.ID_INVOICE, ORIGINAL_DSC_HOUSE: invoice.warehouse.DSC_HOUSE,})
      enqueueSnackbar('House updated successfully', { variant: "success" });
      setAlertModal(false)
      onClose()
      onUpdate()
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        const res = error.response.data;
        const message = res.message?.length ? res.message[0] : 'Could not update house';
        enqueueSnackbar(message, { variant: "error" });
      } else {
        enqueueSnackbar('Could not update house', { variant: "error" });
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Grid marginTop={4} alignItems='center'>
      <LoadingOverlay isLoading={isLoading} />
      <Dialog
        open={alertModal}
        onClose={() => setAlertModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit House</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {newHAWB ? 'Are you sure that you want to save this house info?': 'This field is required to save.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {newHAWB ? <>
            <Button onClick={() => setAlertModal(false)}>Cancel</Button>
            <Button onClick={handleSave}>Confirm</Button>
          </> : <Button onClick={() => setAlertModal(false)}>Back</Button>}
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth= 'md'
        style={{padding:'10%'}}>
        <DialogTitle id="form-dialog-title" style={{padding: 7}}>
          <Grid container alignItems={"center"} width={"100%"}>
            <Grid style={{paddingLeft: 10}} sm={10}>House</Grid>
            <Grid sm={2} container justifyContent="center">
              <IconButton
                edge="start"
                color="inherit"
                onClick={onClose}
                aria-label="close"
              >
                <GridCloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers={true}
          style={{textAlign: 'center', padding: '10%'}}>
          <TextField
            autoFocus
            margin="dense"
            id="invoice-tracking-number"
            data-testid="invoice-tracking-number"
            label="Reference"
            type="text"
            style={{width:'55%'}}
            value={newHAWB}
            onChange={(e) => setNewHAWB(e.target.value)}
          />
        </DialogContent>
        <DialogActions style={{padding: 20}}>
          <Button sx={{
            marginRight: "auto",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            color: "currentColor",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              color: "currentColor",
            },
            minWidth:100
          }} variant="contained" onClick={onClose} color="secondary">
            Return
          </Button>
          <Button onClick={() => {
            if (!newHAWB || !newHAWB.trim()) enqueueSnackbar('Required field', { variant: "error" });
            setAlertModal(true);
          }} color="primary" variant="contained"
            sx={{
              minWidth:100
            }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default HouseDialog;
