import { Alert, Box, Button, Grid, TextField, Autocomplete, FormControlLabel, Checkbox } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";


export default function Search({
   centers,

    onCenterChange,
    errors = [],
  }) {
  const {
    handleSubmit,
    formState: { errors: formErrors },
    control,
  } = useForm();

  const handleFormSubmit = (sla: any) => {
      onCenterChange(sla);
  };

  return (
      <form
          onSubmit={handleSubmit(handleFormSubmit)}
          data-testid="consolidation-center-avanced-search"
      >
        {errors.map((error) => (
            <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
              {error}
            </Alert>
        ))}

        <Grid container spacing={2} marginTop={2} marginBottom={2}>
          <Grid item xs={12} md={6} lg={3}>
            <Controller
                name="ID_CENTRO"
                control={control}
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        options={centers}
                        getOptionLabel={(option) => option?.description || ""}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Company - Plant"
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        )}
                        onChange={(_, newValue) => {
                          field.onChange(newValue ? parseInt(newValue.id, 10) : "");
                        }}
                    />
                )}
            />
          </Grid>

          <Grid item xs={2}>
            <Button
                variant="outlined"
                type="submit"
                sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
                fullWidth
                startIcon={<SearchIcon />}
                data-testid="search-button"
            >
                Generate Charts
            </Button>
          </Grid>
        </Grid>
      </form>
  );
}
