import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    LabelList,
} from 'recharts';
import { useCurrentPng } from 'recharts-to-png';

const HorizontalBarChart = ({
                                data,
                                agingMaxField,
                                managerName,
                                qtdTriagemField,
                                qtdTriagemNameField,
                                onImageGenerated,
                            }) => {
    const [getPng, { ref: chartRef }] = useCurrentPng();

    const handleGenerateImage = async () => {
        try {
            const dataUrl = await getPng();
            if (dataUrl && onImageGenerated) {
                onImageGenerated(dataUrl);
            }
        } catch (err) {
            console.error('Erro ao gerar a imagem:', err);
        }
    };

    React.useEffect(() => {
        if (data && data.length > 0) {
            setTimeout(() => {
                handleGenerateImage();
            }, 2500);
        }
    }, [data]);


    const renderCustomLabel = (props) => {
        if (!data) return null;
        const { x, y, width, height, value, index } = props;
        const maxAging = data[index][agingMaxField];
        const displayValue = `${value} (Max Aging: ${maxAging})`;

        return (
            <text
                x={x + width + 5}
                y={y + height / 2}
                dy={4}
                fill="#000"
                textAnchor="start"
                fontSize="12"
                fontWeight="bold"
                style={{ fontFamily: 'Roboto, sans-serif' }}
            >
                {displayValue}
            </text>
        );
    };

    const maxnum_qtd_triagem = Math.max(
        ...(Array.isArray(data) ? data.map(item => item[qtdTriagemField] ?? 0) : [0])
    );

    const maxXAxis = maxnum_qtd_triagem + (maxnum_qtd_triagem * 0.4);

    return (
        <BarChart
            ref={chartRef} // Atribua a referência aqui
            width={800}
            height={300}
            data={data}
            layout="vertical"
            margin={{ top: 20, right: 100, left: 150, bottom: 5 }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
                type="number"
                domain={[0, maxXAxis]}
                style={{ fontFamily: 'Roboto, sans-serif', fontSize: '12px' }}
            />
            <YAxis
                dataKey={managerName}
                type="category"
                width={150}
                style={{ fontFamily: 'Roboto, sans-serif', fontSize: '12px' }}
            />
            <Tooltip
                contentStyle={{
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '12px',
                }}
            />
            <Bar
                dataKey={qtdTriagemField}
                fill="#1b75b6"
                name={qtdTriagemNameField}
            >
                <LabelList
                    dataKey={qtdTriagemField}
                    content={renderCustomLabel}
                />
            </Bar>
        </BarChart>
    );
};

export default HorizontalBarChart;
