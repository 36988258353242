import { Box, Card, Grid, Paper, Typography } from "@mui/material";
import { formatDecimal } from "../../../../utils/inputs";

type WarehouseDetailItemRow = {
  title?: string,
  content?: string,
}

type WarehouseDetailItemProps = {
  items: WarehouseDetailItemRow[]
}

const WarehouseDetailItem = ({ items }: WarehouseDetailItemProps) => {
  return (
    <Grid item xs={12 / 4}>
      {items.map((item, index) => {
        return (
          <Box key={item.title} display="flex" justifyContent="space-between" gap={3} bgcolor={!(index % 2 === 0) ? "#e8faff" : "transparent"}>
            <Typography fontSize={11}>
              {item.title}
            </Typography>
            <Typography fontSize={11} alignSelf="flex-start">
              <strong style={{ wordBreak: 'break-word' }}>
                {item.content}
              </strong>
            </Typography>
          </Box>
        )
      })}
    </Grid>
  )
}

type WarehouseDetailsProps = {
  warehouseNumber: string,
  warehouseFields: { title?: string, content?: string }[],
  warehouseDialogOld: boolean,
  warehouseStatus?: string | number | null;
}

export default function WarehouseDetails({ warehouseNumber, warehouseFields, warehouseDialogOld, warehouseStatus }: WarehouseDetailsProps) {
  return (
    <Paper
      variant="outlined"
      sx={{ marginTop: !warehouseDialogOld ? 3 : 0, padding: 2, paddingBottom: !warehouseDialogOld ? 2 : 0 }}
    >
      <Typography>
        <strong>
          {warehouseNumber}{warehouseStatus &&  ` - ${warehouseStatus}`}
        </strong>
      </Typography>
      <Grid container spacing={3} style={{ padding: !warehouseDialogOld ? 0 : 4, paddingTop: !warehouseDialogOld ? 3 : 0 }}>
        {/* 4 rows per column */}
        <WarehouseDetailItem items={warehouseFields.slice(0, 4)} />
        <WarehouseDetailItem items={warehouseFields.slice(4, 8)} />
        <WarehouseDetailItem items={warehouseFields.slice(8, 12)} />
        <WarehouseDetailItem items={warehouseFields.slice(12, 16)
          .map(({ title, content }) => ({
              title, 
              content: content === "null" ? '' : (content && (isNaN(+content) ? content : formatDecimal((+content!).toFixed(2), { fractionDigits: 2 }).formatted)) 
            }))
        } />
      </Grid>
    </Paper>
  )
}