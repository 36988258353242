import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ConfirmDialog from './ConfirmDialog';
import { enqueueSnackbar } from 'notistack';
import { TextField } from '@mui/material';

type ReasonDeleteDialogProps = {
  open: boolean;
  onCancel: () => void;
  onOk: (reason: string, forceWarehouse: boolean) => void;
}

function ReasonDeleteDialog({ open, onCancel, onOk }: ReasonDeleteDialogProps) {
    const [openForceWarehouse, setOpenForceWarehouse] = useState(false);
    const [reason, setReason] = useState('');
    const [error, setError] = useState(false);

    const onSubmit = async (forceWarehouse: boolean) => {
      if (!reason.trim()) {
        setError(true);
        return;
      }

      try {
        const response = await onOk(reason, forceWarehouse);
        if (!response?.success) throw Error(response);
        enqueueSnackbar("Invoice removed successful", {
          variant: "success",
        });
        onCancel();
      } catch(error) {
        console.error(error);
        // @ts-ignore
        const { data, status } = error?.response || {};
        const { message: messageText } = data || {}
        if (status === 410) {
          setOpenForceWarehouse(true);
          return;
        }

        enqueueSnackbar(messageText.join(' '), { variant: "error" });
      }
    };

    return (
      <div>
        <Dialog open={open} onClose={onCancel}>
          <DialogTitle>Are you sure you want to cancel the invoice? The entire composition thereof will be deleted</DialogTitle>
          <DialogContent>
            <TextField
              placeholder="Describe the reason..."
              size="small"
              style={{ width: '100%' }}
              multiline
              rows={2}
              maxRows={4}
              variant="outlined"
              value={reason}
              required
              error={error}
              helperText={error ? "This field is required." : ""} // Mensagem de erro
              onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => {
                setError(false);
                setReason(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button data-testid="cancel-button" onClick={onCancel}>CANCEL</Button>
            <Button data-testid="confirm-button" onClick={() => onSubmit(false)} disabled={!reason} variant="contained">CONFIRM</Button>
          </DialogActions>
        </Dialog>

        <ConfirmDialog
          dialogMeta={{
            open: openForceWarehouse,
            title: 'The invoice is associated with a Warehouse, do you want to proceed with the cancellation?',
            callbackOnCancel: () => setOpenForceWarehouse(false),
            callbackOnConfirm: () => {
              onSubmit(true);
              setOpenForceWarehouse(false);
            }
          }}
        ></ConfirmDialog>
      </div>
    );
}

export default ReasonDeleteDialog;