import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { IncotermsComplementType } from "../../../../../types/incotermsComplement";

let incotermsComplementScheme = yup.object({
  DSC_INCOTERMS_COMPL: yup.string().max(30).required(),
  ATIVO: yup.boolean().notRequired(),
});

interface IncotermsComplementFormProps {
  incotermsComplement: IncotermsComplementType;
  onSubmit: (incotermsComplement: IncotermsComplementType) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly?: boolean;
}

export default function IncotermsComplementForm({
  incotermsComplement,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  controlEdit,
  readOnly,
}: IncotermsComplementFormProps) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors: formErrors },
    reset,
  } = useForm({
    resolver: yupResolver(incotermsComplementScheme),
  });

  useEffect(() => {
    reset(incotermsComplement);
  }, [incotermsComplement, reset]);

  const handleFormSubmit = (newIncotermsComplement: any) => {
    onSubmit(newIncotermsComplement);
  };

  const handleCancel = () => {
    reset({ DSC_INCOTERMS_COMPL: "", ATIVO: true });
    onCancel();
  };

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="incoterms-complement-form"
    >
      {errors.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="form-test"
        container
        spacing={2}
        marginTop={1}
        border={1}
        padding={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              disabled={controlEdit.isInUse || readOnly}
              variant="outlined"
              size="small"
              {...register("DSC_INCOTERMS_COMPL")}
              error={!!formErrors.DSC_INCOTERMS_COMPL}
              inputProps={{ maxLength: 30, 'data-testid': 'DSC_INCOTERMS_COMPL-input' }}
            />
          </Grid>
          <Grid item xs={1}>
            <Controller
              defaultValue={true}
              render={({ field: { value } }) => (
                <>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!value}
                        disabled={readOnly}
                        inputProps={{ "aria-label": "controlled" }}
                        {...register("ATIVO")}
                      />
                    }
                    labelPlacement="start"
                    label="Status"
                  />
                </>
              )}
              name="ATIVO"
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={isLoading}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          { !readOnly && (
              <Grid marginLeft={2}>
                <Button
                    variant="contained"
                    disabled={isLoading}
                    type="submit"
                    data-testid="submit-button"
                >
                  Confirm
                </Button>
              </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  );
}
