"use client";
import { useNavigate } from "react-router-dom";
import { signIn } from "../../../services/signin";
import { useEffect, useState } from "react";
import { deleteToken, removeLocal, setLocal, setToken } from "../../../storage/auth";
import { AxiosError } from "axios";
import { Alert, Backdrop, Box, CircularProgress } from "@mui/material";

export default function User() {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        const paramsSide = window.location.href.split('#')[1];
        const params = paramsSide.split('&');
        const token = params[0].split('=')[1];
        const response = await signIn(token);
        if (response?.message && response.message.length > 0) {
          removeLocal('userInfo');
          deleteToken();
          setMessage(response.message[0]);
        } else {
          setLocal('userInfo', JSON.stringify(response.user));
          setToken(response.access_token);
          navigate(`/`);
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 401) {
            deleteToken();
          } else if (error.response?.status === 403) {
            window.location.reload();
          } else {
            deleteToken();
          }
          navigate(`/`);
        } else {
          deleteToken();
          navigate(`/`);
        }
      } finally {
        setIsLoading(false);
      }
    };

    authenticateUser();
  }, []);

  return <>
    <Backdrop
      sx={{ backgroundColor: '#1976d2', color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
        <img
          src="/images/logo.png"
          alt="Embraer"
          data-testid="logo-header"
        />
        {message && <Alert severity="error">{message}</Alert>}
        {isLoading && <CircularProgress color="inherit" />}
      </Box>
    </Backdrop>
  </>
}
