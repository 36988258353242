import { Grid, TextField, Button, Box, MenuItem } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { Combo } from "../../../types/combo";
import { PartnerPaginate } from "../../../types/partner";
import { CarrierProps } from "../../../types/carrier";
import { ServiceLevel } from "../../../types/serviceLevel";
import { AirportService } from "../../../types/airport";
import { CustomRegime } from "../../../types/customRegime";
import { Currency } from "../../../types/currency";
import { Control, Controller, FieldErrors, UseFormRegister } from "react-hook-form";
import { BoardingProcessFormData, Process } from "../../../types/process";
import { Invoice } from "../../../types/invoice";
import dayjs from "dayjs";
import { formatDecimalFree, handleFormatDecimalChange } from "../../utils/inputs";
import DatePickerWrapper from "../../../components/wrappers/DatePickerWrapper";

type ProcessFormProps = {
  process?: Process,
  centers?: Combo[],
  carriers?: CarrierProps[],
  freightForwarders?: PartnerPaginate[],
  transportationModes?: Combo[],
  serviceLevels?: ServiceLevel[],
  airports?: AirportService[],
  customRegimes?: CustomRegime[],
  brokers?: PartnerPaginate[],
  currencies?: Currency[],
  onCenterSelection: (value: string) => void,
  register: UseFormRegister<Process>,
  formErrors: FieldErrors<Process>,
  control: Control<BoardingProcessFormData>,
  processCode: string,
  handleProcessSearchByCode: (processCode?: string) => void,
  processCodeRef: React.MutableRefObject<HTMLInputElement | null>,
  centerRef: React.MutableRefObject<HTMLInputElement | null>,
  dataETDRef: React.MutableRefObject<HTMLInputElement | null>,
  freightForwarderRef: React.MutableRefObject<HTMLInputElement | null>,
  serviceLevelRef: React.MutableRefObject<HTMLInputElement | null>,
  disabledByInvoiceCentroField: Invoice | undefined,
  disabledByInvoiceAllFields: Invoice | undefined,
}

export default function ProcessForm({
  process,
  centers,
  carriers,
  freightForwarders,
  customRegimes,
  cargoTreatments,
  airports,
  transportationModes,
  serviceLevels,
  brokers,
  currencies,
  onCenterSelection,
  register,
  control,
  formErrors,
  handleProcessSearchByCode,
  processCodeRef,
  centerRef,
  dataETDRef,
  freightForwarderRef,
  serviceLevelRef,
  disabledByInvoiceAllFields,
  disabledByInvoiceCentroField,
}: ProcessFormProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      gap={4}
    >
      <Box display="flex" gap={3}>
        <TextField
          InputLabelProps={{ shrink: true }}
          label="Process Code"
          size="small"
          error={!!formErrors.CODIGO_PROCESSO}
          {...register("CODIGO_PROCESSO")}
          inputRef={processCodeRef}
          defaultValue={process?.CODIGO_PROCESSO}
          disabled={!!process?.CODIGO_PROCESSO}
        />
        <Button
          variant="contained"
          size="large"
          style={{ backgroundColor: "primary" }}
          color="primary"
          onClick={() => handleProcessSearchByCode(processCodeRef.current?.value)}
        >
          <SearchIcon />
        </Button>
      </Box>

      <Box display="flex" gap={4}>
        <Controller
          name="SIGLA_PLANTA_CONSOLID"
          control={control}
          render={({ field: { value, onChange, ...restFields }, fieldState: { error } }) => {
            return (
              <TextField
                select
                error={!!formErrors.SIGLA_PLANTA_CONSOLID}
                InputLabelProps={{ shrink: true }}
                fullWidth
                style={{
                  maxWidth: "35%"
                }}
                label="Plant for Process Consolidation*"
                size="small"
                type="tel"
                defaultValue={process?.SIGLA_PLANTA_CONSOLID ?? null}
                {...register("SIGLA_PLANTA_CONSOLID")}
                value={value ?? null}
                {...restFields}
                inputRef={centerRef}
                disabled={!!(disabledByInvoiceCentroField || disabledByInvoiceAllFields)}
                onChange={(event) => {
                  onCenterSelection(event.target.value);
                  onChange(event);
                }}
              >
                {centers?.map((center) => (
                  <MenuItem key={center.id} value={center.id}>
                    {center.description}
                  </MenuItem>
                ))}
              </TextField>
            );
          }}
        />

      </Box>
      <Grid container spacing={3} style={{ maxWidth: "70%" }}>
        <Grid item xs={12 / 3}>
          <Controller
            name="CODIGO_HOUSE_HAWB"
            control={control}
            render={({ field: { value, ...props } }) => {
              return (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  value={value?.toUpperCase()}
                  {...props}
                  label="HAWB*"
                  size="small"
                  error={!!formErrors.CODIGO_HOUSE_HAWB}
                  defaultValue={process?.CODIGO_HOUSE_HAWB}
                  disabled={!!disabledByInvoiceAllFields}
                  fullWidth
                  inputProps={{ maxLength: 20 }}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12 / 3}>
          <TextField
            InputLabelProps={{ shrink: true }}
            {...register("CODIGO_MAWB")}
            defaultValue={process?.CODIGO_MAWB}
            label="MAWB"
            size="small"
            error={!!formErrors.CODIGO_MAWB}
            disabled={!!disabledByInvoiceAllFields}
            fullWidth
            inputProps={{ maxLength: 20 }}
          />
        </Grid>
        <Grid item xs={12 / 3}>
          <Controller
            name="DATA_HAWB"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              const defaultDate = process?.DATA_HAWB ? dayjs(new Date(process.DATA_HAWB)) : null;
              const currentValue = value ? dayjs(new Date(value)) : defaultDate;

              return (
                <DatePickerWrapper
                  closeOnSelect
                  label="Date HAWB*"
                  defaultValue={defaultDate}
                  value={currentValue}
                  disabled={!!disabledByInvoiceAllFields}
                  {...register("DATA_HAWB")} // Make sure register is correctly set up
                  ref={null}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                      error: !!formErrors.DATA_HAWB,
                      InputLabelProps: {
                        shrink: true
                      },
                    },
                  }}
                  format="MM/DD/YYYY"
                  onChange={(date: any) => {
                    const formattedDate = date && dayjs(date).isValid() ? dayjs(date).toISOString() : null;
                    onChange(formattedDate);
                  }}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ maxWidth: "70%" }}>
        <Grid item xs={12 / 3}>
          <Controller
            name="DATA_ETD"
            control={control}
            render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
              const defaultValue = process?.DATA_ETD ? dayjs(new Date(process.DATA_ETD)) : null
              const currentValue = value ? dayjs(new Date(value)) : defaultValue;

              return (
                <DatePickerWrapper
                  closeOnSelect
                  label="ETD"
                  defaultValue={defaultValue}
                  value={currentValue}
                  disabled={!!disabledByInvoiceAllFields}
                  {...register("DATA_ETD")}
                  ref={null}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                      error: !!formErrors.DATA_ETD,
                      InputLabelProps: {
                        shrink: true
                      },
                    },
                  }}
                  inputRef={dataETDRef}
                  format="MM/DD/YYYY"
                  onChange={(date: any) => {
                    const formattedDate = date && dayjs(date).isValid() ? dayjs(date).toISOString() : null;
                    onChange(formattedDate);
                  }}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12 / 3}>
          <Controller
            name="DATA_ETA"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              const defaultValue = process?.DATA_ETA ? dayjs(new Date(process.DATA_ETA)) : null;
              const currentValue = value ? dayjs(new Date(value)) : defaultValue;

              return (
                <DatePickerWrapper
                  closeOnSelect
                  label="ETA"
                  {...register("DATA_ETA")}
                  ref={null}
                  defaultValue={defaultValue}
                  value={currentValue}
                  disabled={!!disabledByInvoiceAllFields}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                      error: !!formErrors.DATA_ETA,
                      InputLabelProps: {
                        shrink: true
                      },
                    },
                  }}
                  format="MM/DD/YYYY"
                  onChange={(date: any) => {
                    const formattedDate = date && dayjs(date).isValid() ? dayjs(date).toISOString() : null;
                    onChange(formattedDate);
                  }}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12 / 3}>
          <Controller
            name="DATA_ATA"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              const defaultValue = process?.DATA_ATA ? dayjs(new Date(process.DATA_ATA)) : null
              const currentValue = value ? dayjs(new Date(value)) : defaultValue;

              return (
                <DatePickerWrapper
                  closeOnSelect
                  label="ATA"
                  defaultValue={defaultValue}
                  disabled={!!disabledByInvoiceAllFields}
                  value={currentValue}
                  {...register("DATA_ATA")}
                  ref={null}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                      error: !!formErrors.DATA_ATA,
                      InputLabelProps: {
                        shrink: true
                      },
                    },
                  }}
                  format="MM/DD/YYYY"
                  onChange={(date: any) => {
                    const formattedDate = date && dayjs(date).isValid() ? dayjs(date).toISOString() : null;
                    onChange(formattedDate);
                  }}
                />
              )
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ maxWidth: "70%" }}>
        <Grid item xs={12 / 3}>
          <Controller
            name="ID_PARCEIRO_AGENTE_CARGAS"
            control={control}
            render={({ field: { value }, fieldState: { error } }) => {
              return (
                <TextField
                  select
                  {...register("ID_PARCEIRO_AGENTE_CARGAS")}
                  defaultValue={process?.ID_PARCEIRO_AGENTE_CARGAS ?? null}
                  value={value ?? null}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="Freight Forwarder*"
                  size="small"
                  error={!!formErrors.ID_PARCEIRO_AGENTE_CARGAS}
                  disabled={!!disabledByInvoiceAllFields}
                  inputRef={freightForwarderRef}
                >
                  {freightForwarders?.map((freightForwarder) => (
                    <MenuItem key={freightForwarder.ID_PARCEIRO} value={freightForwarder.ID_PARCEIRO}>
                      {freightForwarder.NOME_PARCEIRO}
                    </MenuItem>
                  ))}
                </TextField>
              )
            }}
          />
        </Grid>
        <Grid item xs={12 / 3}>
          <Controller
            name="ID_TRANSPORTADOR"
            control={control}
            render={({ field: { value }, fieldState: { error } }) => {
              return (
                <TextField
                  select
                  defaultValue={process?.ID_TRANSPORTADOR ?? null}
                  value={value ?? null}
                  {...register("ID_TRANSPORTADOR")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="Carrier*"
                  size="small"
                  error={!!formErrors.ID_TRANSPORTADOR}
                  disabled={!!disabledByInvoiceAllFields}
                  type="tel"
                >
                  {carriers?.sort((previous, next) => previous.SIGLA_TRANSPORTADOR > next.SIGLA_TRANSPORTADOR ? 1 : -1)?.map((carrier) => (
                    <MenuItem key={carrier.ID_TRANSPORTADOR} value={carrier.ID_TRANSPORTADOR}>
                      {carrier.SIGLA_TRANSPORTADOR} - {carrier.NOME_TRANSPORTADOR}</MenuItem>
                  ))}
                </TextField>
              )
            }}
          />
        </Grid>
        <Grid item xs={12 / 3}>
          <TextField
            select
            defaultValue={process?.ID_MODAL ? process.ID_MODAL : "AIR"}
            {...register("ID_MODAL")}
            InputLabelProps={{ shrink: true }}
            fullWidth
            label="Transportation Mode*"
            error={!!formErrors.ID_MODAL}
            size="small"
            disabled={!!disabledByInvoiceAllFields}
            type="tel"
          >
            {transportationModes?.map((transportationMode) => (
              <MenuItem key={transportationMode.id} value={transportationMode.id}>{transportationMode.description}</MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ maxWidth: "70%" }}>
        <Grid item xs={12 / 3}>
          <Controller
            name="ID_PRIORIDADE"
            control={control}
            render={({ field: { value }, fieldState: { error } }) => {
              return (
                <TextField
                  select
                  {...register("ID_PRIORIDADE")}
                  value={value ?? null}
                  defaultValue={process?.ID_PRIORIDADE ?? null}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="Service Level"
                  size="small"
                  error={!!formErrors.ID_PRIORIDADE}
                  type="tel"
                  disabled={!!disabledByInvoiceAllFields}
                  inputRef={serviceLevelRef}
                >
                  {serviceLevels?.map((serviceLevel) => (
                    <MenuItem key={serviceLevel.ID_PRIORIDADE} value={serviceLevel.ID_PRIORIDADE}>{serviceLevel.DSC_PRIORIDADE}</MenuItem>
                  ))}
                </TextField>
              )
            }}
          />

        </Grid>
        <Grid item xs={12 / 3}>
          <Controller
            name="ID_AEROPORTO_ORIGEM"
            control={control}
            render={({ field: { value }, fieldState: { error } }) => {
              return (
                <TextField
                  select
                  {...register("ID_AEROPORTO_ORIGEM")}
                  defaultValue={process?.ID_AEROPORTO_ORIGEM}
                  value={value ?? null}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="Origin Port/Airport*"
                  size="small"
                  disabled={!!disabledByInvoiceAllFields}
                  error={!!formErrors.ID_AEROPORTO_ORIGEM}
                >
                  {airports?.map((airport) => (
                    <MenuItem key={airport.ID_AEROPORTO} value={airport.ID_AEROPORTO}>{airport.SIGLA_AEROPORTO}-{airport.DSC_AEROPORTO}</MenuItem>
                  ))}
                </TextField>
              )
            }}
          />

        </Grid>
        <Grid item xs={12 / 3}>
          <Controller
            name="ID_AEROPORTO_DESTINO"
            control={control}
            render={({ field: { value }, fieldState: { error } }) => {
              return (
                <TextField
                  select
                  {...register("ID_AEROPORTO_DESTINO")}
                  defaultValue={process?.ID_AEROPORTO_DESTINO ?? null}
                  value={value ?? null}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="Destination*"
                  size="small"
                  disabled={!!disabledByInvoiceAllFields}
                  error={!!formErrors.ID_AEROPORTO_DESTINO}
                >
                  {airports?.map((airport) => (
                    <MenuItem key={airport.ID_AEROPORTO} value={airport.ID_AEROPORTO}>{airport.SIGLA_AEROPORTO}-{airport.DSC_AEROPORTO}</MenuItem>
                  ))}
                </TextField>
              )
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ maxWidth: "70%" }}>
        <Grid item xs={12 / 3}>
          <Controller
            name="TOTAL_PECAS"
            control={control}
            defaultValue={formatDecimalFree(process?.TOTAL_PECAS || '', { integerDigits: 25 }).formatted}
            render={({ field: { value, onChange, ...restField } }) => (
              <TextField
                type="text"
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="Total Pieces*"
                variant="outlined"
                size="small"
                disabled={!!disabledByInvoiceAllFields}
                {...restField}
                value={value ? formatDecimalFree(value, { integerDigits: 25 }).formatted : ''}
                error={!!formErrors.PESO_BRUTO}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  const transformedValue = inputValue.replace(',', '.');
                  const { raw } = formatDecimalFree(transformedValue, { integerDigits: 25 });
                  onChange(raw);
                }}
              />
            )}
          />

        </Grid>
        <Grid item xs={12 / 3}>
          <Controller
            name="PESO_BRUTO"
            control={control}
            defaultValue={formatDecimalFree(process?.PESO_BRUTO || '', { integerDigits: 25 }).formatted}
            render={({ field: { value, onChange, ...restField } }) => (
              <TextField
                type="text"
                fullWidth
                label="Gross Weight*"
                variant="outlined"
                size="small"
                disabled={!!disabledByInvoiceAllFields}
                {...restField}
                value={value ? formatDecimalFree(value, { integerDigits: 25 }).formatted : ''}
                error={!!formErrors.PESO_BRUTO}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  const transformedValue = inputValue.replace(',', '.');
                  const { raw } = formatDecimalFree(transformedValue, { integerDigits: 25 });
                  onChange(raw);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12 / 3}>
          <Controller
            name="PESO_CUBICO"
            control={control}
            defaultValue={formatDecimalFree(process?.PESO_CUBICO || '', { integerDigits: 25 }).formatted}
            render={({ field: { value, onChange, ...restField } }) => (
              <TextField
                type="text"
                fullWidth
                label="Cubic Weight*"
                variant="outlined"
                size="small"
                disabled={!!disabledByInvoiceAllFields}
                {...restField}
                value={value ? formatDecimalFree(value, { integerDigits: 25 }).formatted : ''}
                error={!!formErrors.PESO_CUBICO}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  const transformedValue = inputValue.replace(',', '.');
                  const { raw } = formatDecimalFree(transformedValue, { integerDigits: 25 });
                  onChange(raw);
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ maxWidth: "70%" }}>
        <Grid item xs={12 / 3}>
          <Controller
            name="ID_REGIME"
            control={control}
            render={({ field: { value }, fieldState: { error } }) => {
              return <TextField
                select
                {...register("ID_REGIME")}
                defaultValue={process?.ID_REGIME ?? null}
                value={value ?? null}
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="Custom Regime*"
                error={!!formErrors.ID_REGIME}
                disabled={!!disabledByInvoiceAllFields}
                size="small"
              >
                {customRegimes?.map((customRegime) => (
                  <MenuItem key={customRegime.ID_REGIME} value={customRegime.ID_REGIME}>{customRegime.DESCRICAO_REGIME}</MenuItem>
                ))}
              </TextField>
            }}
          />
        </Grid>
        <Grid item xs={12 / 1.5}>
          <Controller
            name="ID_PARCEIRO_BROKER"
            control={control}
            render={({ field: { value }, fieldState: { error } }) => {
              return (
                <TextField
                  select
                  defaultValue={process?.ID_PARCEIRO_BROKER}
                  value={value ?? null}
                  {...register("ID_PARCEIRO_BROKER")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="Broker*"
                  error={!!formErrors.ID_PARCEIRO_BROKER}
                  size="small"
                  disabled={!!disabledByInvoiceAllFields}
                >
                  {brokers?.map((broker) => (
                    <MenuItem key={broker.ID_PARCEIRO} value={broker.ID_PARCEIRO}>
                      {broker.NOME_PARCEIRO}
                    </MenuItem>
                  ))}
                </TextField>
              )
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ maxWidth: "70%" }}>
        <Grid item xs={12 / 3}>
          <Controller
            name="ID_MOEDA"
            control={control}
            render={({ field: { value }, fieldState: { error } }) => {
              return (
                <TextField
                  select
                  {...register("ID_MOEDA")}
                  value={value ?? null}
                  defaultValue={process?.ID_MOEDA ?? null}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="Currency*"
                  size="small"
                  error={!!formErrors.ID_MOEDA}
                  disabled={!!disabledByInvoiceAllFields}
                >
                  {currencies?.map((currency) => (
                    <MenuItem key={currency.ID_MOEDA} value={currency.ID_MOEDA}>{currency.SIGLA_MOEDA}-{currency.DSC_MOEDA}</MenuItem>
                  ))}
                </TextField>
              )
            }}
          />

        </Grid>
        <Grid item xs={12 / 3}>
          <Controller
            name="VALOR_COLETA"
            control={control}
            defaultValue={formatDecimalFree(process?.VALOR_COLETA || '', { integerDigits: 18 }).formatted}
            render={({ field: { value, onChange, ...restField } }) => (
              <TextField
                type="text"
                fullWidth
                label="Collect Value"
                variant="outlined"
                size="small"
                disabled={!!disabledByInvoiceAllFields}
                {...restField}
                value={value && formatDecimalFree(value, { integerDigits: 18 }).formatted}
                error={!!formErrors.VALOR_COLETA}
                onChange={(event) => {
                  const { raw } = handleFormatDecimalChange(event, { integerDigits: 18 }, true);
                  onChange(raw);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12 / 3}>
          <Controller
            name="VALOR_PREPAID"
            control={control}
            defaultValue={formatDecimalFree(process?.VALOR_PREPAID || '', { integerDigits: 18 }).formatted}
            render={({ field: { value, onChange, ...restField } }) => (
              <TextField
                type="text"
                fullWidth
                label="Prepaid Value"
                variant="outlined"
                size="small"
                disabled={!!disabledByInvoiceAllFields}
                {...restField}
                value={value && formatDecimalFree(value, { integerDigits: 18 }).formatted}
                error={!!formErrors.VALOR_PREPAID}
                onChange={(event) => {
                  const { raw } = handleFormatDecimalChange(event, { integerDigits: 18 }, true);
                  onChange(raw);
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  )
}