import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Autocomplete, Button, FormControl, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import { Quarantine } from "../../../../../types/quarantine";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from "notistack";

let quarantineScheme = yup.object({
  ID_CENTRO: yup.number().required(),
  ID_TIPO_EMAIL: yup.number().required(),
  emailField: yup.string().max(50).email().notRequired(),
});

interface MaitenanceEmailProps {
  quarantine: any;
  centerCombo?: any[];
  onSubmit: (quarantine: Quarantine) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  useEmailTypesCombo: any[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly?: boolean;
}

export default function MaitenanceEmailForm({
  quarantine,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  controlEdit,
  useEmailTypesCombo,
  centerCombo,
  readOnly
}: MaitenanceEmailProps) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    control,
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(quarantineScheme),
  });

  useEffect(() => {
    reset(quarantine);
  }, [quarantine, reset]);

  const [erroMap, setErroMap] = useState<string[]>([]);
  const [list, setList] = useState<any[]>([]);
  const [oldEmail, setOldEmail] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [triggerAdd, setTriggerAdd] = useState(0);

  useEffect(() => {
    if (quarantine.hasOwnProperty("ID_CENTRO")) {
      setList(quarantine.EMAILS.map((item: any) => item.GCTEG_DSC_EMAIL));
    }
  }, [quarantine]);

  useEffect(() => {
    setErroMap(errors);
  }, [errors]);

  const handleCancel = () => {
    setErroMap([]);
    reset({ emailField: "" });
    onCancel();
  };

  const handleFormSubmit = (newItem: any) => {
    setErroMap([]);

    if (list.length <= 0) {
      setErroMap(["You need add at list one email"]);
      return;
    }

    const emailsList = list.map((item) => ({
      DSC_EMAIL: item,
    }));

    delete newItem.emailField;
    onSubmit({
      ...newItem,
      EMAIL_TO: emailsList,
    });
  };

  const addInList = () => {
    const email = getValues().emailField?.toLowerCase();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) {
      enqueueSnackbar("E-mail is not valid!", { variant: "error" });
      setError("emailField", {
        message: 'E-mail is not valid',
      });
      return;
    }

    if(list.includes(email.toLowerCase())) {
      enqueueSnackbar("E-mail already in the list!", { variant: "error" });
      setError("emailField", {
        message: 'E-mail already in the list',
      });
      return;
    }

    delete formErrors.emailField;
    setList([...list, getValues().emailField?.toLowerCase()]);
    reset({
      ...getValues(),
      emailField: "",
    });
  };

  const deleteOnList = (item: string) => {
    const index = list.indexOf(item);
    if (index !== -1) {
      const listCopy = [...list];
      listCopy.splice(index, 1);
      setList(listCopy);
    }
  };

  useEffect(() => {
    if(triggerAdd) {
      addInList();
    }
  }, [triggerAdd])

  function cancelEditEmail() {
    reset({ ...getValues(), emailField: '' });
    setEditMode(false);
    setOldEmail('');
  }

  function editOnList(email: string) {
    reset({ ...getValues(), emailField: email });
    setOldEmail(email);
    setEditMode(true);
  }

  function confirmEditEmail() {
    const activeEmail = getValues().emailField?.toLowerCase();
    if(oldEmail === activeEmail) {
      enqueueSnackbar("You must to change email or cancel!", { variant: "error" });
      return;
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
    if (!emailRegex.test(activeEmail)) {
      enqueueSnackbar("E-mail is not valid!", { variant: "error" });
      setError("emailField", {
        message: 'E-mail is not valid',
      });
      return;
    }

    if(list.includes(activeEmail.toLowerCase())) {
      enqueueSnackbar("E-mail already in the list!", { variant: "error" });
      setError("emailField", {
        message: 'E-mail already in the list',
      });
      return;
    }
    const listCopy = [...list];
    const filteredList = listCopy.filter((EmailOld: string) => EmailOld !== oldEmail);
    setList(filteredList);
    setTriggerAdd(triggerAdd + 1)
    setOldEmail('');
    setEditMode(false);
  }

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="quarantine-form"
    >
      {erroMap.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="search"
        container
        marginTop={1}
        border={1}
        padding={2}
        spacing={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >
        <Grid container spacing={2} marginBottom={2}>
          <Grid item md={4} xs={4}>
            <Controller
              defaultValue={quarantine?.GC_ID_CENTRO || undefined}
              control={control}
              render={({ field: { value, ref, onChange, ...field } }) => (
                <>
                  <Autocomplete
                    options={centerCombo || []}
                    disabled={controlEdit.isInUse || quarantine?.ID_CENTRO_TIPO_EMAIL || readOnly}
                    getOptionLabel={(option) => `${option.description}`}
                    value={
                      centerCombo?.findLast((item: any) => item.id === value) ||
                      null
                    }
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        {...field}
                        label="Company - Plant"
                        size="small"
                        inputRef={ref}
                        disabled={controlEdit.isInUse || quarantine?.ID_CENTRO_TIPO_EMAIL || readOnly}
                        error={!!formErrors.ID_CENTRO}
                        variant="outlined"
                      />
                    )}
                    onChange={(_: any, data: any) => {
                      onChange(data?.id);
                    }}
                  />
                </>
              )}
              name="ID_CENTRO"
            />
          </Grid>
          <Grid item md={6} xs={8}>
            <Controller
              control={control}
              render={({ field: { value, ref, onChange, ...field } }) => (
                <Autocomplete
                  options={useEmailTypesCombo || []}
                  disabled={controlEdit.isInUse || quarantine?.ID_CENTRO_TIPO_EMAIL || readOnly}
                  getOptionLabel={(option) => `${option.description}`}
                  value={
                    useEmailTypesCombo?.findLast(
                      (item: any) => item.id === value
                    ) || null
                  }
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      {...field}
                      label="Type"
                      size="small"
                      inputRef={ref}
                      disabled={controlEdit.isInUse || quarantine?.ID_CENTRO_TIPO_EMAIL || readOnly}
                      error={!!formErrors.ID_TIPO_EMAIL}
                      variant="outlined"
                    />
                  )}
                  onChange={(_: any, data: any) => {
                    onChange(parseInt(data?.id));
                  }}
                />
              )}
              name="ID_TIPO_EMAIL"
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item lg={6} sm={6} xs={8}>
            <FormControl size="small" fullWidth>
              <Controller
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    disabled={controlEdit.isInUse || readOnly}
                    type="text"
                    label="E-mail to"
                    size="small"
                    variant="outlined"
                    defaultValue={value}
                    error={!!formErrors.emailField}
                    InputLabelProps={{ shrink: !!value }}
                    {...register("emailField")}
                    inputProps={{ maxLength: 100 }}
                    multiline
                  />
                )}
                name="emailField"
              />
            </FormControl>
          </Grid>
          {editMode ?
            <>
              <Button
                sx={{ marginLeft: '16px', marginRight: '4px' }}
                variant="outlined"
                onClick={() => cancelEditEmail()}
                disabled={readOnly}
                color="error"
              >
                <CloseIcon fontSize="small" />
              </Button>

              <Button
                sx={{ marginLeft: '16px', marginRight: '4px' }}
                variant="outlined"
                onClick={() => confirmEditEmail()}
                disabled={readOnly}
              >
                <CheckIcon fontSize="small" />
              </Button>
            </>
          :
            <Button
              disabled={controlEdit.isInUse || readOnly}
              sx={{ marginLeft: "16px" }}
              variant="outlined"
              onClick={addInList}
            >
              <AddIcon fontSize="small" />
            </Button>  
          }
        </Grid>
        {/* lista */}
        <Grid marginTop={2} container>
          <Grid item xs={4}>
            <Table
              sx={{ minWidth: { md: 650, xs: 350 } }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>List E-mail</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((item, index) => (
                  <TableRow
                    key={item}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        color="primary"
                        disabled={controlEdit.isInUse || readOnly}
                        onClick={() => editOnList(item)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        color="error"
                        disabled={controlEdit.isInUse || readOnly}
                        onClick={() => deleteOnList(item)}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        {/*  */}
        <Grid container justifyContent="flex-end" marginTop={2} spacing={2}>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={isLoading}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          {!readOnly ? (
              <Grid marginLeft={2}>
                <Button
                    variant="contained"
                    disabled={isLoading}
                    type="submit"
                    data-testid="submit-button"
                >
                  Confirm
                </Button>
              </Grid>
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
}
