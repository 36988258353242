import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Chip } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import DataTableDefault from "../../../../../components/DataTable";
import { IncotermsType } from "../../../../../types/incoterms";
import { hasClaim } from "../../../../../storage/userClaims";

interface DataTableProps {
  onEditIncoterm: (id: number | string) => void;
  onDeleteIncoterm: (id: number | string) => void;
  onViewIncoterm: (id: number | string) => void;
  data: IncotermsType[];
  [x: string]: any;
}
export default function DataTable({
  onEditIncoterm,
  onDeleteIncoterm,
  onViewIncoterm,
  data,
  userValidation,
  ...props
}: DataTableProps) {
  const renderMenus = (row: any) => {
    return [
      <GridActionsCellItem
        key={`view-${row.ID_INCOTERMS}`}
        icon={<VisibilityIcon />}
        label="View"
        onClick={() => onViewIncoterm(row.ID_INCOTERMS)}
        color="inherit"
        data-testid="view-button"
      />,
      <GridActionsCellItem
        sx={{
          display: hasClaim("Incoterms - Maintenance") ? "block" : "none",
        }}
        key={`edit-${row.ID_INCOTERMS}`}
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={() => onEditIncoterm(row.ID_INCOTERMS)}
        color="inherit"
        data-testid="edit-button"
      />,
      <GridActionsCellItem
        sx={{
          display: hasClaim("Incoterms - Maintenance") ? "block" : "none",
        }}
        key={`delete-${row.ID_INCOTERMS}`}
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => onDeleteIncoterm(row.ID_INCOTERMS)}
        color="inherit"
        data-testid="delete-button"
      />,
    ];
  };

  const columns: GridColDef[] = [
    {
      field: "SIGLA",
      headerName: "Abbreviation",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "ID_FRETE_INCO",
      headerClassName: "super-app-theme--header",
      headerName: "Incoterms Freight Type",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "ATIVO",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Date>) =>
        params.value ? (
          <Chip label="Active" color="primary" />
        ) : (
          <Chip label="Inactive" variant="outlined" />
        ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }) => renderMenus(row),
    },
  ];

  return (
    <>
      <DataTableDefault
        rowId={data.map((row) => row.ID_INCOTERMS)}
        data-testid="incoterms-data-table"
        data={data}
        columns={columns}
        {...props}
      />
    </>
  );
}
