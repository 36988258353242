import { Alert, Button, Grid, TextField, TextFieldProps } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { searchInvoice } from "../../../../../services/invoice";
import InvoiceDialog from "./invoiceDialog";
import { DialogAction, IFindInvoice, InvoiceFormModel } from "../../../../../types/invoice";
import dayjs from "dayjs";
import { Control, Controller, FieldErrors, UseFormRegister } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { Po } from "../../../../../types/po";
import DatePickerWrapper from "../../../../../components/wrappers/DatePickerWrapper";

interface PoDataProps {
  DATA_INVOICE: Date | string;
}

const actions = {
  NEW_INVOICE: 'new-invoice',
  EDIT_INVOICE: 'edit-invoice',
  VIEW_INVOICE: 'view-invoice',
  TRY_AGAIN: 'try-again',
  NOTHING: 'nothing',
}

interface InvoiceSearchProps {
  setPoData: (element: Partial<Po>) => void;
  resetInvoice: (element: InvoiceFormModel) => void;
  setOpenPoAccordion: (element: boolean) => void;
  setOpenInvoiceAccordion: (element: boolean) => void;
  getPoData?: (values: PoDataProps) => void;
  poData?: Po | null | undefined;
  setInvoice?: React.Dispatch<React.SetStateAction<InvoiceFormModel>>;
  register: UseFormRegister<InvoiceFormModel>;
  watch: (key?: string) => {};
  control: Control<InvoiceFormModel>;
  formErrors: FieldErrors<InvoiceFormModel>;
  getInvoiceDate?: (value: Date|string|null) => void;
  invoice: InvoiceFormModel;
  clearErrors: boolean;
  invoiceDate: string | null;
}

type Payload = { poNumber?: string | null, NUM_INVOICE?: string | null | {}, invoiceDate?: string | null };

export default function InvoiceSearch({
  setPoData,
  resetInvoice,
  setOpenPoAccordion,
  setOpenInvoiceAccordion,
  getPoData,
  poData,
  setInvoice,
  register,
  watch,
  control,
  formErrors,
  getInvoiceDate,
  invoice,
  clearErrors,
  invoiceDate
}: InvoiceSearchProps) {
  const { id: idInvoice } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogOptions, setDialogOptions] = useState<DialogAction[]>([]);
  const [dialogMessage, setDialogMessage] = useState("");
  const [response, setResponse] = useState<IFindInvoice>({});
  const [alertErrors, setAlertErrors] = useState<string[]>([]);
  const [poNumber, setPoNumber] = useState<string|undefined>(invoice?.po?.NUMERO_PO ?? '');
  const NUM_INVOICE = watch("NUM_INVOICE");

  const pushAlertError = (error: string) => setAlertErrors((alertErrors) => Array.from(new Set([...alertErrors, error]).values()));
  const clearAlertErrors = () => setAlertErrors([]);

  const timeoutRef = useRef<{ timeout: NodeJS.Timeout | null, payload: Payload }>({
    timeout: null,
    payload: { poNumber: null, NUM_INVOICE: null, invoiceDate: null }
  });

  useEffect(() => {
    if (idInvoice && invoice?.po && !poData) {
      setPoNumber(invoice?.po?.NUMERO_PO ?? '');
    }
  }, [idInvoice, invoice.po, poData]);

  useEffect(() => {
    setPoNumber(poData?.NUMERO_PO ?? '');
  }, [poData]);

  const whenBlurTimeout = () => {
    if (idInvoice) return;
    const { current } = timeoutRef;
    if (current.timeout !== null) {
      clearTimeout(current.timeout!);
      current.timeout = null;
    };
    current.timeout = setTimeout(() => {
      const newData = { poNumber, NUM_INVOICE, invoiceDate };
      for (const [key, value] of Object.entries(newData)) {
        const oldValue = current.payload[key as keyof typeof current.payload];
        if (oldValue === null || value === oldValue) continue;
        pushAlertError("You changed your inputs, please click on the searching button to update your invoice");
        break;
      }
    }, 1000);
  }

  useEffect(() => {
    clearAlertErrors();
  }, [clearErrors])

  const findInvoice = async (onDone?: () => void) => {
    if (timeoutRef.current.timeout) clearTimeout(timeoutRef.current.timeout);
    clearAlertErrors();
    const dateSelected = watch("DATA_INVOICE") as string;
    const invoiceNumber = watch("NUM_INVOICE") as string;

    const minDate = dayjs().subtract(12, 'months').startOf('day');
    const today = dayjs().startOf('day');
  
    if (dayjs(dateSelected).startOf('day') < minDate) return pushAlertError(`Invoice Date must be greater than ${minDate.format('MM/DD/YY')}`);
    if (dayjs(dateSelected).startOf('day') > today) return pushAlertError(`Invoice Date must be no later than ${today.format('MM/DD/YY')}`);

    if (!dateSelected || !invoiceNumber) {
      pushAlertError("Invoice Date and Invoice Number is required to search")
      return;
    }

    if (idInvoice && invoice.ID_PO && !poNumber) {
      pushAlertError(`PO number cannot be empty`);
      setPoNumber(poData?.NUMERO_PO ?? '');
      setTimeout(() => {
        clearAlertErrors();
      }, 2000); 
      return;
    }

    const responseRetuned: IFindInvoice = await searchInvoice({
      NUMERO_PO: poNumber,
      NUM_INVOICE: invoiceNumber,
      DATA_INVOICE: dateSelected && dayjs(dateSelected).isValid() ? dayjs(dateSelected).format("MM/DD/YYYY") : "",
    });

    setResponse(responseRetuned);


    if (!idInvoice) {
      if (responseRetuned?.action) {
        handleAction(responseRetuned.action);
      }
  
      if (responseRetuned?.invoice) {
        !!setInvoice && setInvoice((responseRetuned?.invoice as unknown as InvoiceFormModel));
      } else {
        !!setInvoice && setInvoice((prevState) => ({
          ...prevState,
          NUM_INVOICE: invoiceNumber
        }));
      }

      if (responseRetuned.po) {
        setPoData(responseRetuned.po);
        setOpenPoAccordion(true);
      }

      if (!responseRetuned.po) {
        setPoData({});
        setOpenPoAccordion(false);
      }

      if (responseRetuned.dialog) {
        if (responseRetuned.dialog.options[0]?.action === 'nothing') {
          pushAlertError(responseRetuned.dialog.message);
        } else {
          setDialogMessage(responseRetuned.dialog.message);
          setDialogOptions(responseRetuned.dialog.options);
          setOpenDialog(true);
        }

        setOpenInvoiceAccordion(false);
        setOpenPoAccordion(false);
      }
    } else {
      if (responseRetuned.po && responseRetuned.po.ID_PO !== invoice.ID_PO) {
        if (invoice?.invoiceItemsPo?.length > 0) {
          pushAlertError(`PO cannot be changed, there are already items saved in the invoice composition of this PO`);
        } else {
          if (responseRetuned.dialog?.message !== 'Invoice already registered for the provided PO, would you like to edit/consult?')
            setPoData(responseRetuned.po); 
          else {
            setPoNumber(invoice?.po?.NUMERO_PO);
            pushAlertError(responseRetuned.dialog?.message);
            setDialogMessage(responseRetuned.dialog.message);
            setDialogOptions(responseRetuned.dialog.options);
            setOpenDialog(true);
          }
          setOpenPoAccordion(true);
        }
      } else if (responseRetuned.po && responseRetuned.po.ID_PO === invoice.ID_PO) {
        pushAlertError(`Invoice already saved for the new PO number`);
        setPoNumber(poData?.NUMERO_PO ?? '');
        setPoData(responseRetuned.po);
        setOpenPoAccordion(true);
      } else if (responseRetuned.dialog) {
        if (responseRetuned.dialog.options[0]?.action === 'nothing') {
          pushAlertError(responseRetuned.dialog.message);
        } else {
          setDialogMessage(responseRetuned.dialog.message);
          setDialogOptions(responseRetuned.dialog.options);
          setOpenDialog(true);
        }
      } else if (!responseRetuned.po) {
        pushAlertError(`PO number ${poNumber} not found on the system`);
        setPoData({});
        setPoNumber(poData?.NUMERO_PO ?? '');
        setOpenPoAccordion(false);
      }
    }

    onDone?.();
    !!getPoData &&
      getPoData({
        DATA_INVOICE: dateSelected ?? "",
      });
  };

  const handleNewInvoice = () => {
    setOpenInvoiceAccordion(true);
    const currentValues = watch() as InvoiceFormModel;
    resetInvoice({
      ...currentValues,
      invoiceAttachments: []
    });

    if (response?.po) {
      setPoData(response.po);
      setOpenPoAccordion(true);

      return;
    }

    setPoData({});
    setOpenPoAccordion(false);
  };

  const handleAction = (
    action: string | undefined
  ) => {
    setOpenDialog(false);
    const ID_INVOICE = response.invoice?.ID_INVOICE;

    if (action === actions.NOTHING) {
      return;
    }

    if (action === actions.TRY_AGAIN) {
      if (response.dialog?.message === 'Invoice already registered for the provided PO, would you like to edit/consult?') clearAlertErrors();
      warningMessage("Please verify search inputs and search again")
    }

    if (action === actions.EDIT_INVOICE) {
      navigate(`/invoice/${ID_INVOICE}`);
    }

    if (action === actions.NEW_INVOICE) {
      handleNewInvoice();
    }

    if (action === actions.VIEW_INVOICE) {
      navigate(`/invoice/details/${ID_INVOICE}`);
    }
  };

  const warningMessage = (message: string): void => {
    enqueueSnackbar(message, { variant: "warning" });
  }

  return (
    <>
    {alertErrors.map((error) => <Alert key={error} severity="error" style={{ marginBottom: 20 }}>{error}</Alert>)}
      <Grid container marginBottom={3} spacing={2}>
        <Grid item xs={4}>
          <TextField
            data-testid="description-input"
            fullWidth
            label="PO"
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 10
            }}
            value={poNumber}
            onChange={(e) => setPoNumber(e.target.value)}
            onBlur={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (idInvoice) findInvoice();
              whenBlurTimeout();
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            data-testid="description-input"
            fullWidth
            label="Invoice*"
            variant="outlined"
            size="small"
            {...register("NUM_INVOICE")}
            InputLabelProps={
              { 
                shrink: !!(watch("NUM_INVOICE") && (watch("NUM_INVOICE") as string).length > 0)
              }
            }
            disabled={!!idInvoice}
            inputProps={{
              maxLength: 16
            }}
            onBlur={whenBlurTimeout}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="DATA_INVOICE"
            control={control}
            defaultValue={
              (invoice?.DATA_INVOICE && dayjs(invoice?.DATA_INVOICE).isValid() ? dayjs(invoice.DATA_INVOICE).toISOString() : null) as string
            }
            render={({ field: { onChange, ref, value }, ...field }) => (
              <DatePickerWrapper
                label="Invoice Date*"
                disabled={!!idInvoice}
                value={value ? dayjs(value) : null}
                inputRef={ref}
                format="MM/DD/YYYY"
                slotProps={{ textField: { size: 'small' } }}
                closeOnSelect
                onChange={(date: Date|string|null|undefined) => {
                  const formattedDate = date && dayjs(date).isValid() ? dayjs(date).format("MM/DD/YYYY") : null;
                  onChange(formattedDate);
                  if (getInvoiceDate) {
                    getInvoiceDate(formattedDate);
                  }
                  whenBlurTimeout();
                }}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    {...params}
                    {...field}
                    variant="outlined"
                    error={!!formErrors.DATA_INVOICE}
                    InputProps={{
                      shrink: idInvoice || invoice.DATA_INVOICE
                    }}
                  />
                )}
              />
            )}
          />
          {
            !idInvoice && (
              <Button
                sx={{ marginLeft: 2 }}
                variant="contained"
                onClick={() => findInvoice(() => (timeoutRef.current.payload = { poNumber, NUM_INVOICE, invoiceDate }))}
              >
                <SearchIcon />
              </Button>
            )
          }
        </Grid>
        <InvoiceDialog
          open={openDialog}
          message={dialogMessage}
          options={dialogOptions}
          callAction={action => handleAction(action)}
        />
      </Grid>
    </>
  );
}
