import ChevronRight from "@mui/icons-material/ChevronRight";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { bindFocus, bindHover, bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import * as React from "react";
import { useNavigate } from 'react-router-dom';

import { menuList } from "./config";
import { getUserClaim } from "../../../../storage/userClaims";
import { Link } from "react-router-dom";

function useOutsideAlerter(ref, closeMenu) {
    React.useEffect(() => {
        function handleClickOutside(event) {
            if (event.button === 2) return;
            if (ref.current && !ref.current.contains(event.target)) {
                closeMenu();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, closeMenu]);
}

function CascadingMenuItem({ route, label }) {
    return (
        <Link to={route} style={{ textDecoration: 'none', color: 'inherit' }}>
            <MenuItem>
                {label}
            </MenuItem>
        </Link>
    );
}

function CascadingSubmenu({ title, popupId, children }) {
    const popupState = usePopupState({
        popupId,
        variant: "popover",
    });
    const ref = React.useRef(null);
    useOutsideAlerter(ref, popupState.close);

    return (
        <div ref={ref}>
            <MenuItem {...bindHover(popupState)} {...bindFocus(popupState)}>
                <span>{title}</span>
                <ChevronRight />
            </MenuItem>
            <HoverMenu
                {...bindMenu(popupState)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                onClose={popupState.close}
            >
                {children}
            </HoverMenu>
        </div>
    );
}

function filterMenuItems(items, userClaims) {
    return items
        .filter((item) => {
            if (!item.claims || item.claims.length === 0) {
                return true;
            }
            return item.claims.some((claim) => userClaims.includes(claim));
        })
        .map((item) => ({
            ...item,
            items: item.items ? filterMenuItems(item.items, userClaims) : undefined,
        }))
        .filter((item) => !item.items || item.items.length > 0);
}

const CascadingHoverMenus = ({ menu }) => {
    const popupState = usePopupState({
        variant: "popover",
    });
    const ref = React.useRef(null);
    const navigate = useNavigate();
    useOutsideAlerter(ref, popupState.close);

    const handleNavigation = (event, route) => {
        if (event.ctrlKey || event.metaKey || event.button === 1) {
            event.preventDefault();
            window.open(route, "_blank");
        } else {
            navigate(route, { replace: true });
            window.location.reload();
        }
        popupState.close(); // Fecha o menu ao clicar em um item
    };

    return (
        <div ref={ref}>
            <Button
                aria-haspopup="true"
                color="inherit"
                data-testid={`menu-button-${menu.label.toLowerCase()}`}
                {...bindTrigger(popupState)}
            >
                {menu.label}
            </Button>
            <HoverMenu
                {...bindMenu(popupState)}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                onClose={popupState.close}
            >
                {menu.items?.map((item) =>
                    item.items && item.items.length > 0 ? (
                        <CascadingSubmenu key={item.label} title={item.label} popupId={`${item.label}-submenu`}>
                            {item.items.map((subMenu) => (
                                <CascadingMenuItem
                                    key={subMenu.label}
                                    label={subMenu.label}
                                    route={subMenu.route}
                                />
                            ))}
                        </CascadingSubmenu>
                    ) : (
                        <CascadingMenuItem
                            key={item.label}
                            label={item.label}
                            route={item.route}
                        />
                    )
                )}
            </HoverMenu>
        </div>
    );
};

export default function Menu() {
    const userClaims = getUserClaim();
    const filteredMenu = filterMenuItems(menuList, userClaims);

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" data-testid="nav-header">
            {filteredMenu.map((menu) => (
                <CascadingHoverMenus key={menu.label} menu={menu} />
            ))}
        </Grid>
    );
}
