
import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { Company } from "../../types/company";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import { SearchParams } from "../../types/dashboard";
import { validSearchParams } from "../invoice";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
  status?: boolean;
}

const entity = "companies";

export const useList = (
  params?: IList,
  token?: string
): Promise<{ data: Company[]; meta: List; isLoading: boolean }> | any => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Company[]>([{} as Company]);
  useEffect(() => {
    (async function () {
      setIsLoading(true);

      const { data } = await api.get(`/${entity}/getCombo`, {
        params,
      })
      setData(data);
      setIsLoading(false);
    })();
  }, [params, token]);

  return { data, isLoading };
};

export const getInvoiceCompanies = async (params?: SearchParams): Promise<Company[]> => {
  const token = getToken();

  const { data } = await api.get(`/companies`, {
    headers: { authorization: `Bearer ${token}` },
    params: validSearchParams(params)
  });

  return data;
};