import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { Currency } from "../../../../../types/currency";

let currencyScheme = yup.object({
  SIGLA_MOEDA: yup.string().max(3).required(),
  DSC_MOEDA: yup.string().max(30).required(),
  ATIVO: yup.boolean().notRequired(),
});

interface CurrencyFormProps {
  currency: Currency;
  onSubmit: (currency: Currency) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readonly?: boolean;
}

export default function CurrencyForm({
  currency,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  controlEdit,
  readOnly,
}: CurrencyFormProps) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors: formErrors },
    reset,
  } = useForm({
    resolver: yupResolver(currencyScheme),
  });

  useEffect(() => {
    if (currency.hasOwnProperty("SIGLA_MOEDA")) {
      reset(currency);
    }
  }, [currency, reset]);

  const handleFormSubmit = (newCurrency: any) => {
    onSubmit(newCurrency);
  };

  const handleCancel = () => {
    reset({ SIGLA_MOEDA: "", DSC_MOEDA: "", ATIVO: true });
    onCancel();
  };

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="currency-form"
    >
      {errors.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="search"
        container
        marginTop={1}
        border={1}
        padding={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
        marginBottom={2}
      >
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="Abbreviation"
              variant="outlined"
              disabled={controlEdit.isInUse || readOnly}
              size="small"
              {...register("SIGLA_MOEDA")}
              error={!!formErrors.SIGLA_MOEDA}
              inputProps={{ maxLength: 3, 'data-testid': 'abbreviation-input' }}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              label="Description"
              variant="outlined"
              disabled={!!(controlEdit.isInUse && currency.DSC_MOEDA) || readOnly }
              size="small"
              {...register("DSC_MOEDA")}
              error={!!formErrors.DSC_MOEDA}
              inputProps={{ maxLength: 30, 'data-testid': 'description-input' }}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              defaultValue={true}
              render={({ field: { value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!value}
                      disabled={readOnly}
                      inputProps={{ "aria-label": "controlled" }}
                      {...register("ATIVO")}
                    />
                  }
                  labelPlacement="start"
                  label="Status"
                />
              )}
              name="ATIVO"
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" spacing={2} paddingTop={5}>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={isLoading}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          {!readOnly ? (
          <Grid marginLeft={2}>
            <Button
              variant="contained"
              disabled={isLoading}
              type="submit"
              data-testid="submit-button"
            >
              Confirm
            </Button>
          </Grid>
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
}
