import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Box, Button, Dialog, Divider, Grid, TextField, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { GridColDef } from "@mui/x-data-grid";
import Add from "@mui/icons-material/Add";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import UploadDocument from "../uploadDocument";
import DataTableDefault from "../../../../../../components/DataTable";
import { addInvoice, getInvoices, deleteInvoice, getInvoiceFiles, getInvoiceFile, removeInvoiceFile, uploadInvoiceFile, sendEmailInvoice, validateInvoices } from "../../../../../../services/triage";
import { isAxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { TriageStatus } from "../../types/triage";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getUserClaim } from "../../../../../../storage/userClaims";
import LoadingOverlay from "../../../../../../components/LoadingOverlay";
import DatePickerWrapper from "../../../../../../components/wrappers/DatePickerWrapper";

const triageInvoicesSchema = yup.object({
    NUM_INVOICE: yup.string().required("Invoice number is required"),
    DATA_INVO: yup.string().required("Data Invoice is required"),
    NUM_PO: yup.string().required("PO number is required"),
});

type Invoices = {
    ID_TRIAGEM_INVOICE: number;
    ID_TRIAGEM: number;
    DSC_INVOICE: string,
    DSC_PO: string,
    DATA_INVO: string,
    ID_STATUS_INVOICE: string,
}

interface DataTableProps {
    [x: string]: any;
}

const DataTableInvoices = React.memo(({
    triage,
    onUpdate,
    ...props
}: DataTableProps) => {
    const {
        register,
        unregister,
        handleSubmit,
        formState: { errors: formErrors },
        reset,
        control,
        watch,
    } = useForm({
        defaultValues: {
            DATA_INVO: "",
            NUM_INVOICE: "",
            NUM_PO: ""
        },
        resolver: yupResolver(triageInvoicesSchema),
    });

    const { id } = useParams<{ id: string }>();
    const userClaims = getUserClaim();
    const [invoices, setInvoices] = useState<Invoices[]>([]);
    const [showInvoiceFormPopup, setShowInvoiceFormPopup] = useState<boolean>(false);
    const [showInvoiceFilesPopup, setShowInvoiceFilesPopup] = useState<any>(false);
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchInvoices();
    }, []);

    const isEnabled = useMemo(() => {
        if (triage.ID_STATUS_TRIAGEM === TriageStatus.MISSING_INVOICE) {
          return userClaims.some((claim) => ["Triage - Analysis", "Triage - Buyer"].includes(claim));
        }

        if (
            [
                TriageStatus.UNDER_ANALYSIS,
                TriageStatus.UNDER_LOGISTICS_ANALYSIS,
                TriageStatus.PENDING
            ].includes(triage.ID_STATUS_TRIAGEM as TriageStatus)
        ) {
            return userClaims.some((claim) => ["Triage - Analysis"].includes(claim));
        }

        return false;
    }, [userClaims, triage.ID_STATUS_TRIAGEM]);

    const isValidateVisible = useMemo(() => {
        return triage.ID_STATUS_TRIAGEM === TriageStatus.UNDER_LOGISTICS_ANALYSIS;
    }, [triage.ID_STATUS_TRIAGEM]);

    const isSendEmailButtonVisible = useMemo(() => {
        return userClaims && userClaims.some((claim) => {
          return ['Triage - Analysis'].includes(claim);
        });
      }, [userClaims]);
    
      const isDoneButtonVisible = useMemo(() => {
        return userClaims && userClaims.some((claim) => {
          return ['Triage - Buyer'].includes(claim);
        });
      }, [userClaims]);

    const fetchInvoices = useCallback(async () => {
        setIsLoading(true);
        try {
            if (id) {
                const response = await getInvoices(+id);
                setInvoices(response);
            }
        } catch (error) {
            if (isAxiosError(error)) {
                enqueueSnackbar(`${error?.response?.data?.message}`, { variant: "error" });
            }
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    const handleAddInvoice = useCallback(async () => {
        setIsLoading(true);
        try {
            if (!id) return;

			const DATA_INVO = watch("DATA_INVO");
            const body = {
                ID_TRIAGEM: +id,
                DSC_INVOICE: watch("NUM_INVOICE")?.trim(),
                DSC_PO: watch("NUM_PO")?.trim(),
				// TODO: mantive o formato anterior, ja estava no formato YYYY-MM-DD.
				DATA_INVO: DATA_INVO && dayjs(DATA_INVO).format("YYYY-MM-DD"),
            }

            const invoice = await addInvoice(body);

            if (invoice.success) {
                enqueueSnackbar(`Invoice created successfully`, { variant: "success" });
                setShowInvoiceFormPopup(false);
            } else {
                enqueueSnackbar(`${invoice.message}`, { variant: "error" });
                return;
            }

            fetchInvoices();

            reset();
            unregister();
        } catch (error) {
            if (isAxiosError(error)) {
                enqueueSnackbar(`${error?.response?.data?.message}`, { variant: "error" });
            }
        } finally {
            setIsLoading(false);
        }
    }, [id, watch, triage.ID_WAREHOUSE, fetchInvoices, reset]);

    const alertErros = useMemo(() => {
        const errorMessages = Object.keys(formErrors).map((key: string) => {
            return formErrors[key as keyof typeof formErrors]?.message;
        });

        return [...errorMessages];
    }, [formErrors]);

    const handleDeleteInvoice = async (idTriagemInvoice: number) => {
        try {
            await deleteInvoice(idTriagemInvoice);

            await fetchInvoices();

            enqueueSnackbar(`Invoice deleted successfully`, { variant: "success" });
        } catch(error) {
            if (isAxiosError(error)) {
                enqueueSnackbar(`${error?.response?.data?.message}`, { variant: "error" });
            }
        }
    }

    const handleCancel = () => {
        unregister();
        setShowInvoiceFormPopup(false)
    }

    const onSave = () => {
        setIsLoading(true);
        sendEmailInvoice(showInvoiceFilesPopup.ID_TRIAGEM_INVOICE).then(response => {
            enqueueSnackbar("Send e-mail successfully", { variant: "success" });
            setShowInvoiceFilesPopup(false);
            fetchInvoices();
        }).catch(error => {
            enqueueSnackbar(`${error?.response?.data?.message}`, { variant: "error" });
        }).finally(() => setIsLoading(false));
    }

    const onLoad = (row: any) => {
        setShowInvoiceFilesPopup(row);
        fetchFiles(row?.ID_TRIAGEM_INVOICE)
    }

    const fetchFiles = async (ID_TRIAGEM_INVOICE: number) => {
        try {
            const response = await getInvoiceFiles(+ID_TRIAGEM_INVOICE);
            setFiles(response);
        } catch (error) {
          if (isAxiosError(error)) {
            enqueueSnackbar(`${error?.response?.data?.message}`, { variant: "error" });
          }
        }
    }

    const validateInvoice = async () => {
        setIsLoading(true);

        try {
            await validateInvoices(+id!);

            await fetchInvoices();

            enqueueSnackbar(`Invoices validated successfully`, { variant: "success" });
        } catch(error) {
            if (isAxiosError(error)) {
                enqueueSnackbar(`${error?.response?.data?.message}`, { variant: "error" });
            } else {
                enqueueSnackbar('Could not validate invoices', { variant: "error" });
            }
        } finally {
            setIsLoading(false);
        }
    }

    const columns: GridColDef[] = [
        {
            field: "ID",
            headerName: " ",
            headerClassName: "super-app-theme--header",
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Button 
                        data-testid="btn-delete-invoice"
                        onClick={() => onLoad(row)}
                        style={{
                            color: isEnabled ? "purple" : "grey",
                        }}
                        disabled={!isEnabled}
                    >
                        <AttachFileIcon />
                    </Button>
                )
            }
        },
        {
            field: "DSC_INVOICE",
            headerName: "INVOICE",
            headerClassName: "super-app-theme--header",
            flex: 1,
        },
        {
            field: "DATA_INVO",
            headerName: "DATE",
            headerClassName: "super-app-theme--header",
            flex: 1,
            renderCell: ({ row }) => {
                return [row?.DATA_INVO && new Date(row.DATA_INVO).toLocaleDateString('en', { month: '2-digit', day: '2-digit', year: 'numeric' })]
            }
        },
        {
            field: "DSC_PO",
            headerName: "PO",
            headerClassName: "super-app-theme--header",
            flex: 1
        },
        {
            field: "ID_STATUS_INVOICE_TRIAGEM",
            headerName: "STATUS",
            headerClassName: "super-app-theme--header",
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <strong style={{
                        color: "green"
                    }}>
                        {row.ID_STATUS_INVOICE_TRIAGEM}
                    </strong>
                )
            }
        },
        {
            field: "Delete",
            headerName: "",
            headerClassName: "super-app-theme--header",
            renderCell: ({ row }) => {
                return (
                    <Button 
                        data-testid="btn-delete-invoice"
                        onClick={() => handleDeleteInvoice(row.ID_TRIAGEM_INVOICE)}
                        style={{
                            color: "red",
                        }}
                        disabled={!isEnabled}
                    >
                        <DeleteOutlineIcon />
                    </Button>
                )
            }
        }
    ];

    return (
        <>
            <LoadingOverlay isLoading={isLoading} />
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                padding={2}
            >
                <Typography marginBottom={2} fontWeight="bold">Invoices</Typography>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    gap={2}
                    padding={2}
                >
                    {isValidateVisible && isEnabled && (
                        <Button
                            variant="outlined"
                            color="success"
                            type="submit"
                            onClick={validateInvoice}
                            disabled={!isEnabled}
                        >
                            <CheckBoxOutlinedIcon style={{ paddingRight: 9 }} />
                            Validate Invoices
                        </Button>
                    )}
                    <Button
                        variant="outlined"
                        type="submit"
                        onClick={() => setShowInvoiceFormPopup(true)}
                        disabled={!isEnabled}
                    >
                        <Add style={{ paddingRight: 9 }} />
                        Add Invoice
                    </Button>
                </Box>
            </Box>

            <Dialog
                open={showInvoiceFormPopup}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    style: {
                        padding: 20,
                        overflow: 'visible'
                    }
                }}
            >
                <form
                    onSubmit={handleSubmit(handleAddInvoice)}
                >
                    {alertErros.map((error) => (
                        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
                            {error}
                        </Alert>
                    ))}
                    <Typography paddingX={2}>
                        <strong>Invoice</strong>
                    </Typography>
                    <Grid container spacing={2} paddingX={2} paddingY={2}>
                        <Grid item xs={4}>
                            <Controller
                                defaultValue=""
                                name="NUM_INVOICE"
                                control={control}
                                render={() => (
                                    <TextField
                                        data-testid="description-input"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        type="text"
                                        label="Invoice Number"
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        {...register("NUM_INVOICE")}
                                        error={!!formErrors.NUM_INVOICE}
                                    />
                                )
                                }
                            />
                        </Grid>
                        <Grid item xs={4}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<Controller
									name="DATA_INVO"
									control={control}
									render={({ field: { ref, onChange, value, ...field } }) => (
										<DatePickerWrapper
											label="Date Invoice"
											value={value ? dayjs(value) : null}
											inputRef={ref}
											slotProps={{ textField: { size: 'small' } } }
											onChange={(date: Date | string | null | undefined) => {
												const formattedDate = date && dayjs(date).isValid() ? dayjs(date).format("MM/DD/YYYY") : null;
												onChange(formattedDate);
											}}
											renderInput={(params: any) => (
												<TextField
													{...params}
													{...field}
													variant="outlined"
                                                    inputFormat="MM/DD/YYYY"
													error={!!formErrors.DATA_INVO}
													InputLabelProps={{
														shrink: true
													}}
												/>
											)}
										/>
									)}
								/>
							</LocalizationProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                defaultValue=""
                                name="NUM_PO"
                                control={control}
                                render={() => (
                                    <TextField
                                        data-testid="description-input"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        type="text"
                                        fullWidth
                                        label="PO Number"
                                        variant="outlined"
                                        size="small"
                                        {...register("NUM_PO")}
                                        error={!!formErrors.NUM_INVOICE}
                                    />
                                )
                                }
                            />
                        </Grid>
                    </Grid>
                    <Box display="flex" paddingX={2} paddingY={2} justifyContent="space-between">
                        <Button variant="outlined" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={handleSubmit(handleAddInvoice)}>
                            Save
                        </Button>
                    </Box>
                </form>
            </Dialog>

            <Dialog
                open={showInvoiceFilesPopup}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    style: {
                        padding: 20
                    }
                }}
            >
                <UploadDocument
                    attachments={files}
                    onFinish={() => fetchFiles(showInvoiceFilesPopup?.ID_TRIAGEM_INVOICE)}
                    isDisabled={!isEnabled}
                    removeFile={removeInvoiceFile}
                    uploadFile={uploadInvoiceFile}
                    getFile={getInvoiceFile} 
                    id={showInvoiceFilesPopup?.ID_TRIAGEM_INVOICE}          
                />
                <Divider sx={{ marginTop: 4 }} />

                <Grid container marginTop={2} justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <Button
                            variant="outlined"
                            onClick={() => setShowInvoiceFilesPopup(false)}
                            data-testid="cancel-button"
                        >
                            Cancel
                        </Button>
                    </Grid>
                    {isSendEmailButtonVisible && (
                        <Grid item>
                            <Button
                                variant="contained"
                                data-testid="submit-button"
                                onClick={() => onSave()}
                                disabled={!files?.length}
                            >
                                Send E-mail
                            </Button>
                        </Grid>
                    )}
                    {isDoneButtonVisible && (
                        <Grid item>
                            <Button
                                variant="contained"
                                data-testid="done-button"
                                onClick={() => setShowInvoiceFilesPopup(false)}
                            >
                                Done
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Dialog>

            <MemoizedDataTableDefault
                rowId={invoices?.map((invoice) => invoice.ID_TRIAGEM_INVOICE)}
                data-testid="unit-data-table"
                data={invoices}
                columns={columns}
                {...props}
            />
        </>
    );
});


const MemoizedDataTableDefault = React.memo((props: any) => {
    return <DataTableDefault {...props} />
});

export default DataTableInvoices;
