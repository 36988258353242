import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { Center } from "../../types/center";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
}

const entity = "carriers";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string,
  isCombo?: boolean
):
  | Promise<{
      data: Center[];
      meta: List;
      error: Error;
      isLoading: boolean;
    }>
  | any => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Center[]>([]);
  const [meta, setMeta] = useState<List>({} as List);
  const [error] = useState<Error>({} as Error);

  const render = () => {
    if (token) {
      if (isCombo === false || isCombo === undefined) {
        return "pagination";
      }
    }
  };

  useEffect(() => {
    if (render() === "pagination") {
      (async function () {
        setIsLoading(true);
        const response = await dataTableUtils.findPaginate(params);
        const {total, data, currentPage, totalPages, limit} = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
        setIsLoading(false)
      })();
    }
  }, [params, token]);

  return {
    data,
    meta,
    error,
    isLoading,
  };
};

export const update = async (id: number, center?: any): Promise<Center> => {
  const { data } = await api.patch(`/${entity}/${id}`, center);

  return data;
};

export const isInUse = async (id: number | string): Promise<any> => {
  const { data } = await api.get(`/${entity}/isInUse/${id}`);

  return data;
};

export const deleteCenter = async (id: number): Promise<boolean> => {
  const { data } = await api.delete(`/${entity}/${id}`);

  return data;
};

export const create = async (center?: any): Promise<Center> => {
  const { data } = await api.post(`/${entity}`, center);

  return data;
};

export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: "Id", value: "ID_TRANSPORTADOR" },
    { label: "Carrier abbreviation", value: "SIGLA_TRANSPORTADOR" },
    { label: "Code", value: "CODIGO_TRANSPORTADOR" },
    { label: "Description", value: "NOME_TRANSPORTADOR" },
    { label: "Modal", value: "ID_MODAL_TRANSPORTE" },
    {
      label: "Status",
      value: (row: any) => (row.ATIVO ? "Active" : "Inactive"),
    },
  ]
  await dataTableUtils.exportAll({params, xlsxData, filename: "Carrier"});
};
