import {useCallback, useEffect, useState} from "react";

import { api } from "../../lib/api";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import { PartnerService, PartnerType } from "../../types/partner";
import { ComboBoxType } from "../../types/general";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";
import { IPartnerParams } from "../../app/private/process";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
  status?: boolean;
}

const entity = "partners";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string,
):
  | Promise<{ data: PartnerService[]; meta: List; isLoading: boolean }>
  | any => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<PartnerService[]>([{} as PartnerService]);
  const [partnerId, setPartnerId] = useState<string | number>();
  const [country, setCountry] = useState<ComboBoxType[]>([]);
  const [consolidationCenter, setConsolidationCenter] = useState<ComboBoxType[]>([]);
  const [center, setCenter] = useState<ComboBoxType[]>([]);
  const [freightForwarder, setFreightForwarder] = useState<ComboBoxType[]>([]);
  const [meta, setMeta] = useState<List>({} as List);

  const render = useCallback(() => {
    if (token) return "paginate";
  }, [token]);

  useEffect(() => {
    if (render() === "paginate") {
      (async function () {
        setIsLoading(true);
        const response = await dataTableUtils.findPaginate(params);
        const {total, data, currentPage, totalPages, limit} = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
        setIsLoading(false)
      })();

      api.get(`/centers/getCentersWithCompaniesCombo`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const { data } = response;
        setCenter(data);
      })

      api.get(`/freightForwarders/getCombo`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const { data } = response;
        setFreightForwarder(data);
      })

      api.get(`/consolidationCenters/getCombo`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const { data } = response;
        setConsolidationCenter(data);
      })

      api.get(`/countries/getCombo`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const { data } = response;
        setCountry(data);
      })
    }
  }, [params, render, token, partnerId]);

  return { data, meta, isLoading, country, consolidationCenter, center, freightForwarder, partnerId, setPartnerId };
};

export const findPartnerById = async (id: string | number) => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return data;
};

export const findEntityIsInUse = async (id: string | number): Promise<boolean> => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/isInUse/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return data.inUse;
};

export const findConsolidationCenter = async (id: string | number): Promise<ComboBoxType[]> => {
  const token = getToken();
  const { data } = await api.get(`/consolidationCenters/getCombo?country=${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return data;
};

export const update = async (
  id: number | string,
  partner?: PartnerService
): Promise<PartnerService> => {
  const token = getToken();
  const { data } = await api.patch(`/${entity}/${id}`, partner, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const deleteItem = async (id: number | string): Promise<PartnerService> => {
  const token = getToken();
  const { data } = await api.delete(`/${entity}/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const create = async (partner?: PartnerService): Promise<PartnerService> => {
  const token = getToken();
  const { data } = await api.post(`/${entity}`, partner, {
    headers: { authorization: `Bearer ${token}` },
  });
  return data;
};

export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: "Id", value: "partner_ID_PARCEIRO" },
    { label: "Partner name", value: "partner_NOME_PARCEIRO" },
    { label: "MFIR", value: "partner_NUM_MFIR" },
    { label: "Partner type", value: "partner_ID_TIPO_PARCEIRO" },
    { label: "EDI code", value: "partner_CODIGO_EDI" },
    { label: "Country Id", value: "partner_CODIGO_EDI" },
    { label: "Country name", value: "country_id_pais" },
    { label: "Consolidation center id", value: "centro_consolidador_id_centro_consolidador" },
    { label: "Consolidation center name", value: "centro_consolidador_nome" },
    { label: "Interface - Freight Forwarder id", value: "freight_forwarder_id_agente_carga" },
    { label: "Interface - Freight Forwarder name", value: "freight_forwarder_nome_agente" },
    { label: "Company abbreviation", value: "parceiro_center_empresa_sigla_empresa" },
    { label: "Center id", value: "centro_id_centro" },
    { label: "Center abbreviation", value: "centro_sigla" },
    { label: "Center name", value: "centro_nome" },
    { label: "Center active", value: (row: any) => (row.centro_ativo ? "Active" : "Inactive") },
    { label: "Partner email description", value: "parceiro_center_email_descricao" },
    { label: "Partner email destination", value: "parceiro_center_email_destino" },
  ]
  await dataTableUtils.exportAll({ params, xlsxData, filename: "Partner", url: 'exportAll' });
};

export const getPartnersCombo = async (partnerType?: string, ID_CENTRO?: number): Promise<ComboBoxType[]> => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/getCombo`, {
    headers: { authorization: `Bearer ${token}` },
    params: { partnerType, ID_CENTRO },
  });
  return data;
}

export const getPartnersFromConsolidation = async (params: IPartnerParams): Promise<PartnerType[]> => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/findFromConsolidation`, {
    headers: { authorization: `Bearer ${token}` },
    params,
  });
  return data;
}