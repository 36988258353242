"use client";
import {loginWithToken} from "../../services/auth";
import {setToken} from "../../storage/auth";
import {User} from "../../types/user";
import {createContext, useContext, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";

interface IUserContext {
  user: User;
  update: (user: User) => void;
  signIn: (token: string) => void;
  signOut: () => void;
  isLogged: boolean;
}

export const UserContext = createContext({} as IUserContext);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User>({} as User);
  const [isLogged, setIsLogged] = useState(false);
  const navigate = useNavigate();

  const update = (user: User) => {
    setUser(user);
  };

  const signIn = async (loginToken: string) => {
    try {
      const {
        data: { token, ...user },
      } = await loginWithToken(loginToken);
      update(user);
      await setToken(token);
      setIsLogged(true);
    } catch (err) {
      console.log("Signin error", err);
    }
  };

  const signOut = async () => {
    setIsLogged(false);
    update({} as User);
    await setToken("");
    navigate("/logout");
  };

  const providerValue = useMemo(() => ({
    user,
    update,
    signIn,
    signOut,
    isLogged
  }), [user, update, signIn, signOut, isLogged]);

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
};

export function useUser() {
  return useContext(UserContext);
}
