import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { DialogAction } from "../../../../../types/invoice";

export interface ConfirmDialogType {
  open: boolean;
  message?: string;
  title?: string;
  callbackOnConfirm?: (element?: any) => void;
  callbackOnCancel?: (element?: any) => void;
}

interface ConfirmDialogProps {
  open: boolean;
  message: string;
  options: DialogAction[];
  callAction: (action: string | undefined, responseRetuned?: any) => void;
}

const InvoiceDialog = ({
  open,
  message,
  options = [],
  callAction,
}: ConfirmDialogProps) => {
  return (
    <Box>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { message }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {
            options.map((option, index) => (
              <Button
                key={`dialog-invoice-${option.buttonText}`}
                onClick={() => callAction(option.action)}
              >
                { option.buttonText }
              </Button>
            ))
          }
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InvoiceDialog;
