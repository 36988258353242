import React, { useState, useEffect } from 'react';
import { Alert, Autocomplete, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { useList as useCenter } from "../../../../services/centers";
import { useList as useIncoterms } from "../../../../services/Incoterms";
import { useList as useIncotermsComplement } from "../../../../services/incotermsComplement";
import { editLabel, getLabelInfo, saveLabel } from '../../../../services/label';
import { getToken } from "../../../../storage/auth";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Label } from '../../../../types/po';
import LoadingOverlay from "../../../../components/LoadingOverlay";
import { ComboBoxType } from '../../../../types/general';
import { useSnackbar } from 'notistack';

export interface LabelService {
  ID_LABEL?: number | undefined;
  ID_PO: number | null,
  ID_PO_LIN: number | null,
  NUMERO_PO: string,
  NUM_LIN_PO:  string | null,
  SIGLA_CENTRO?: ComboBoxType,
  SIGLA_INCOTERMS?: ComboBoxType,
  DSC_INCOTERMS_COMPL: ComboBoxType,
  NUM_TRACKING_LABEL1: string;
  NUM_TRACKING_LABEL2: string;
}

const initialFormState: LabelService = {
  NUMERO_PO: '',
  NUM_LIN_PO: null,
  ID_PO: null,
  ID_PO_LIN: null,
  SIGLA_CENTRO: {
    id: '',
    description: ''
  },
  SIGLA_INCOTERMS: {
    id: '',
    description: ''
  },
  DSC_INCOTERMS_COMPL: {
    id: '',
    description: ''
  },
  NUM_TRACKING_LABEL1: '',
  NUM_TRACKING_LABEL2: '',
};

const labelSchema = yup.object({
  NUMERO_PO: yup.string(),
  NUM_LIN_PO: yup.string(),
  SIGLA_CENTRO: yup.object({
    id: yup.string(),
    description: yup.string().max(100).required(),
  }),
  SIGLA_INCOTERMS: yup.object({
    id: yup.string(),
    description: yup.string().max(100).required(),
  }),
  DSC_INCOTERMS_COMPL: yup.object({
    id: yup.string(),
    description: yup.string().max(100).required(),
  }),
  NUM_TRACKING_LABEL1: yup.string(),
  NUM_TRACKING_LABEL2: yup.string(),
});

function CreateLabelData ({ data, fetchData }: any) {
  const [token, setToken] = useState<string>();
  const [labelData, setLabelData] = useState<LabelService>(initialFormState);
  const [isLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>();
  const { enqueueSnackbar } = useSnackbar();

  const [params] = useState<IParams>({
    page: 1,
    limit: 100,
  } as IParams);

  const { combo: dataCenterCombo } = useCenter(params, true);

  const { data: dataIncoterms } = useIncoterms(
    params,
    token
  );
  const dataIncotermsCombo = dataIncoterms?.map((item) => ({ id: item?.ID_INCOTERMS, description: item?.SIGLA }))
  const { data: dataIncotermComplements } = useIncotermsComplement(
    params,
    token
  );
  const dataIncotermComplementsCombo = dataIncotermComplements?.map((item) => ({ id: item?.ID_INCOTERMS_COMPL, description: item?.DSC_INCOTERMS_COMPL }))

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    control,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(labelSchema),
  });

  const fetchLabelInfo = async (NUMERO_PO: string, NUM_LIN_PO: string | null) => {
    if (NUMERO_PO && NUM_LIN_PO) {
      try {
        const labelInfo = await getLabelInfo(NUMERO_PO, NUM_LIN_PO);
        if (!labelInfo) return;

        const [poItem] = labelInfo?.poItems || [];

        const SIGLA_CENTRO = { id: poItem.ID_CENTRO, description: `${labelInfo?.SIGLA_EMPRESA} - ${poItem.SIGLA_CENTRO} - ${poItem?.centro?.NOME_CENTRO}` };
        const SIGLA_INCOTERMS = { id: labelInfo.SIGLA_INCOTERMS, description: labelInfo?.SIGLA_INCOTERMS };
        const DSC_INCOTERMS_COMPL = { id: labelInfo.DSC_INCOTERMS_COMPL, description: labelInfo?.DSC_INCOTERMS_COMPL?.toUpperCase() };

        setLabelData((oldState) => ({
          ...oldState,
          ID_PO: labelInfo?.ID_PO,
          ID_PO_LIN: poItem?.ID_PO_LIN,
          SIGLA_CENTRO,
          SIGLA_INCOTERMS,
          DSC_INCOTERMS_COMPL,
        }));

        setValue('SIGLA_CENTRO', SIGLA_CENTRO);
        setValue('SIGLA_INCOTERMS', SIGLA_INCOTERMS);
        setValue('DSC_INCOTERMS_COMPL', DSC_INCOTERMS_COMPL);
      } catch (error) {
        console.error('Failed to fetch label info:', error);
      }
    }
  };

  const getTokenSession = async () => {
    const getTokens = (await getToken()) as any;
    setToken(getTokens);
  };

  const handleFormSubmit = async (labelItem: LabelService) => {
    setErrors([]);
    
    try {
      const newData: Label = {
        ID_LABEL: labelData?.ID_LABEL,
        ID_PO: labelData?.ID_PO!,
        ID_PO_LIN: labelData?.ID_PO_LIN!,
        NUM_TRACKING_LABEL1: labelItem?.NUM_TRACKING_LABEL1,
        NUM_TRACKING_LABEL2: labelItem?.NUM_TRACKING_LABEL2,
      };
      const response = await (newData?.ID_LABEL ? editLabel(newData) : saveLabel(newData));
      if (response?.success) {
        enqueueSnackbar(`Label ${newData?.ID_LABEL ? 'updated' : 'created'} successful`, { variant: "success" });

        reset();
        setLabelData(initialFormState);
        await fetchData();
        return;
      }

      enqueueSnackbar(response?.message?.join(' / '), { variant: "error" });
    } catch(err: any) {
      if (err.response.status) {
        enqueueSnackbar("Label is being used", { variant: "error" });
      } else {
        enqueueSnackbar(err.response.data.message[0], { variant: "error" });
      }
    }
  };

  useEffect(() => {
    getTokenSession();
  }, []);

  useEffect(() => {
    setLabelData({
      ...initialFormState,
      ID_LABEL: data?.label_ID_LABEL!,
      NUMERO_PO: data?.po_NUMERO_PO,
      NUM_LIN_PO: data?.poItem_NUM_LIN_PO,
    });
    fetchLabelInfo(data?.po_NUMERO_PO, data?.poItem_NUM_LIN_PO);
    setValue("NUMERO_PO", data?.po_NUMERO_PO);
    setValue("NUM_LIN_PO", data?.poItem_NUM_LIN_PO);
    setValue("NUM_TRACKING_LABEL1", data?.label_NUM_TRACKING_LABEL1);
    setValue("NUM_TRACKING_LABEL2", data?.label_NUM_TRACKING_LABEL2);
  }, [data]);

  return (
    <Grid marginTop={4}>
      <LoadingOverlay isLoading={isLoading} />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h5">Label Data</Typography>
        </Grid>
      </Grid>

      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        data-testid="label-data-search"
      >
        {errors?.map((error) => (
          <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
            {error}
          </Alert>
        ))}

        <Grid container spacing={2} alignItems="center" marginTop={1}>
          <Grid item xs={12}>
            <Paper
              variant='outlined'
              sx={{ paddingTop: 2, paddingLeft: 5, paddingBottom: 2, paddingRight: 15 }}
            >
              <Grid container spacing={2} alignItems="center" marginTop={1}>
                <Grid item xs={3}>
                  <Controller
                    defaultValue=""
                    name="NUMERO_PO"
                    control={control}
                    render={() => (
                      <TextField
                        data-testid="description-input"
                        InputLabelProps={{
                            shrink: true
                        }}
                        type="text"
                        fullWidth
                        label="PO Number"
                        variant="outlined"
                        size="small"
                        disabled={!!labelData?.ID_LABEL}
                        {...register("NUMERO_PO")}
                        onChange={({ target }) => {
                          setLabelData((oldState) => ({ ...oldState, NUMERO_PO: target?.value }));
                        }}
                        onBlur={({ target }) => {
                          fetchLabelInfo(target?.value, labelData?.NUM_LIN_PO);
                        }}
                        error={!!formErrors?.NUMERO_PO}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Controller
                    defaultValue=""
                    name="NUM_LIN_PO"
                    control={control}
                    render={() => (
                      <TextField
                        data-testid="description-input"
                        InputLabelProps={{
                          shrink: true
                        }}
                        type="text"
                        fullWidth
                        label="PO Line"
                        variant="outlined"
                        size="small"
                        disabled={!!labelData?.ID_LABEL}
                        {...register("NUM_LIN_PO")}
                        onChange={({ target }) => {
                          setLabelData((oldState) => ({ ...oldState, NUM_LIN_PO: target?.value }));
                        }}
                        onBlur={({ target }) => {
                          fetchLabelInfo(labelData?.NUMERO_PO, target?.value);
                        }}
                        error={!!formErrors?.NUM_LIN_PO}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems="center" marginTop={1}>
                <Grid item xs={6}>
                  <Controller
                    control={control}                    
                    name="SIGLA_CENTRO"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={dataCenterCombo || []}
                        getOptionLabel={(option: any) => option.description}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        disabled
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Center/Plant"
                            required
                            size="small"
                            variant="outlined"
                            error={!!formErrors?.SIGLA_CENTRO}
                            disabled
                          />
                        )}
                        onChange={(_, data: any) => {
                          field.onChange(data); 
                          setValue('SIGLA_CENTRO', data, { shouldValidate: true });
                        }}
                        value={field?.value || null}
                        // value={labelData?.SIGLA_CENTRO}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Controller
                    control={control}
                    name="SIGLA_INCOTERMS"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={dataIncotermsCombo}
                        getOptionLabel={(option: any) => option.description}
                        isOptionEqualToValue={(option, value) => option.description === value.description}
                        disabled
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Incoterm"
                            size="small"
                            variant="outlined"
                            error={!!formErrors?.SIGLA_INCOTERMS}
                            disabled
                          />
                        )}
                        onChange={(_, data: any) => {
                          field.onChange(data); 
                          setValue('SIGLA_INCOTERMS', data, { shouldValidate: true });
                        }}
                        value={field.value || null}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="DSC_INCOTERMS_COMPL"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={dataIncotermComplementsCombo}
                        getOptionLabel={(option: any) => option.description}
                        isOptionEqualToValue={(option, value) => option.description === value.description}
                        disabled
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Incoterm Complement"
                            size="small"
                            variant="outlined"
                            error={!!formErrors?.DSC_INCOTERMS_COMPL}
                            disabled
                          />
                        )}
                        onChange={(_, data: any) => {
                          field.onChange(data); 
                          setValue('DSC_INCOTERMS_COMPL', data, { shouldValidate: true });
                        }}
                        value={field.value || null}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems="center" marginTop={1}>
                <Grid item xs={3}>
                  <Controller
                    name="NUM_TRACKING_LABEL1"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        {...params}
                        label="Tracking Number (label 1)"
                        size="small"
                        {...register("NUM_TRACKING_LABEL1")}
                        InputLabelProps={{
                          shrink: true
                        }}
                        style={{ width: '100%' }}
                        multiline
                        error={!!formErrors?.NUM_TRACKING_LABEL1}
                        defaultValue={labelData?.NUM_TRACKING_LABEL1 || ''}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Controller
                    name="NUM_TRACKING_LABEL2"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        {...params}
                        label="Tracking Number (label 2)"
                        size="small"
                        {...register("NUM_TRACKING_LABEL2")}
                        InputLabelProps={{
                          shrink: true
                        }}
                        style={{ width: '100%' }}
                        multiline
                        error={!!formErrors?.NUM_TRACKING_LABEL2}
                        defaultValue={labelData?.NUM_TRACKING_LABEL2 || ''}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            
              <Grid
                container
                marginTop={1}
                marginBottom={1}
                spacing={2}
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  type="submit"
                  data-testid="submit-button"
                >
                  Add
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default CreateLabelData;