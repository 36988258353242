import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { UnitMeasurement } from "../../../../../types/unitMeasurement";

let unitMeasurementScheme = yup.object({
  SIGLA: yup.string().max(3).required(),
  DESCRICAO: yup.string().max(50).required(),
  ATIVO: yup.boolean().notRequired(),
});

interface UnitMeasurementFormProps {
  unitMeasurement: UnitMeasurement;
  onSubmit: (unitMeasurement: UnitMeasurement) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly: boolean;
}

export default function UnitMeasurementForm({
  unitMeasurement,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  controlEdit,
  readOnly
}: UnitMeasurementFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(unitMeasurementScheme),
  });

  const [erroMap, setErroMap] = useState<string[]>([]);

  useEffect(() => {
    setErroMap(errors);
  }, [errors]);

  useEffect(() => {
    if (unitMeasurement.hasOwnProperty("DESCRICAO")) {
      reset(unitMeasurement);
    }
  }, [unitMeasurement, reset]);

  const handleFormSubmit = (newunitMeasurements: any) => {
    setErroMap([]);
    onSubmit(newunitMeasurements);
  };

  const handleCancel = () => {
    setErroMap([]);
    onCancel();
  };
  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="unitMeasurement-form"
    >
      {erroMap.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="search"
        container
        marginTop={1}
        border={1}
        padding={2}
        spacing={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >
        <Grid container spacing={2} marginBottom={2}>
          <Grid item md={2} xs={4}>
            <TextField
              data-testid="unitMeasurementAbbreviation-input"
              fullWidth
              label="Abbreviation"
              variant="outlined"
              size="small"
              {...register("SIGLA")}
              disabled={controlEdit.isInUse || readOnly}
              error={!!formErrors.SIGLA}
              inputProps={{ maxLength: 3 }}
            />
          </Grid>
          <Grid item md={8} xs={6}>
            <TextField
              data-testid="description-input"
              fullWidth
              label="Description"
              variant="outlined"
              size="small"
              disabled={controlEdit.isInUse || readOnly}
              {...register("DESCRICAO")}
              error={!!formErrors.DESCRICAO}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              defaultValue={true}
              render={({ field: { value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!value}
                      disabled={readOnly}
                      inputProps={{ "aria-label": "controlled" }}
                      {...register("ATIVO")}
                    />
                  }
                  labelPlacement="start"
                  label="Ativo"
                />
              )}
              name="ATIVO"
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" marginTop={2} spacing={2}>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={isLoading}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          {!readOnly ? (
            <Grid marginLeft={2}>
              <Button
                  variant="contained"
                  disabled={isLoading}
                  type="submit"
                  data-testid="submit-button"
              >
                Confirm
              </Button>
            </Grid>
          ) : null }
        </Grid>
      </Grid>
    </form>
  );
}
