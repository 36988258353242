import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { ComboBoxType } from "../../../../../types/general";
import { InvoiceItemPo, InvoiceItemPoFormData } from "../../../../../types/invoiceItemPo";
import { formatDecimalFree, handleDecimalInputChange, handleFormatDecimalChange } from "../../../../utils/inputs";

let invoicePoItemScheme = yup.object({
  DSC_PN: yup.string().max(50).required(),
  QTDE_PN: yup.string().required(),
  ID_UM: yup.number().required(),
  NUM_VLR_UNIT: yup.string().required(),
  VALOR_TOTAL: yup.string().required(),
  ID_PAIS_ORIGEM: yup.number().required(),
  NUM_PN: yup.string().max(50).required(),
  DSC_SERIAL_NUMBER: yup.string().max(250).optional(),
  ID_GRUPO_COMPR: yup.number().required(),
  GRUPO_COMPR_NOME: yup.string().max(255).optional(),
  CODIGO_GRUPO: yup.string().max(255).optional(),
  DSC_STAT_LI: yup.string().max(255).optional().nullable(),
  DSC_LI: yup.string().max(255).optional().nullable(),
  DSC_NCM_HTS: yup.string().max(255).optional().nullable(),
  DSC_SPI: yup.string().max(255).optional().nullable(),
});

interface InvoiceCompositionFormProps {
  invoicePoItem: InvoiceItemPo;
  onSubmit: (invoicePoItem: InvoiceItemPoFormData) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  countries: ComboBoxType[];
  unitsOfMeasurement: ComboBoxType[];
}

export default function InvoiceCompositionForm({
  invoicePoItem,
  onSubmit,
  onCancel,
  errors = [],
  countries,
  unitsOfMeasurement,
}: InvoiceCompositionFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      ...invoicePoItem,
      NUM_VLR_UNIT: typeof invoicePoItem?.NUM_VLR_UNIT === 'number'
        ? formatDecimalFree(invoicePoItem?.NUM_VLR_UNIT, { integerDigits: 3 }).formatted
        : invoicePoItem?.NUM_VLR_UNIT,
      VALOR_TOTAL: typeof invoicePoItem?.VALOR_TOTAL === 'number'
        ? invoicePoItem?.VALOR_TOTAL?.toString()
        : invoicePoItem?.VALOR_TOTAL,
      QTDE_PN: typeof invoicePoItem?.QTDE_PN === 'number'
        ? invoicePoItem?.QTDE_PN?.toString()
        : invoicePoItem?.QTDE_PN,
    },
    resolver: yupResolver(invoicePoItemScheme),
  });

  useEffect(() => {
    reset({
      ...invoicePoItem,
      NUM_VLR_UNIT: typeof invoicePoItem?.NUM_VLR_UNIT === 'number'
        ? invoicePoItem?.NUM_VLR_UNIT?.toString()
        : invoicePoItem?.NUM_VLR_UNIT,
      VALOR_TOTAL: typeof invoicePoItem?.VALOR_TOTAL === 'number'
        ? invoicePoItem?.VALOR_TOTAL?.toString()
        : invoicePoItem?.VALOR_TOTAL,
      QTDE_PN: typeof invoicePoItem?.QTDE_PN === 'number'
        ? invoicePoItem?.QTDE_PN?.toString()
        : invoicePoItem?.QTDE_PN,
    });
  }, [invoicePoItem, reset]);

  const handleFormSubmit = (invoicePoItem: InvoiceItemPoFormData) => {
    const modifiedData = {
      ...invoicePoItem,
      NUM_VLR_UNIT: parseFloat(invoicePoItem.NUM_VLR_UNIT.toString().replace(',', '.')),
      QTDE_PN: parseFloat(invoicePoItem.QTDE_PN.toString().replace(',', '.')),
      VALOR_TOTAL: parseFloat(invoicePoItem.VALOR_TOTAL.toString().replace(',', '.')),
    };
    onSubmit(modifiedData);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      {errors.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        container
        marginY={3}
        border={1}
        borderColor={"#7b7b7b"}
        padding={4}
        borderRadius={2}
        alignItems="center"
      >
        <Grid
          marginBottom={1}
          container
          justifyContent="flex-center"
          spacing={2}
        >
          <Grid item xs={3}>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="PN Description"
                  size="small"
                  variant="outlined"
                  defaultValue={value}
                  error={!!formErrors.DSC_PN}
                  InputLabelProps={{ shrink: !!value }}
                  {...register("DSC_PN")}
                  inputProps={{ maxLength: 255 }}
                  required
                />
              )}
              name="DSC_PN"
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="QTDE_PN"
              control={control}
              render={({ field: { value, onChange, ...restField }, }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="Quantity"
                  size="small"
                  variant="outlined"
                  {...restField}
                  value={value && formatDecimalFree(value, { integerDigits: 8 }).formatted}
                  error={!!formErrors.QTDE_PN}
                  InputLabelProps={{ shrink: !!value }}
                  inputProps={{ maxLength: 255 }}
                  required
                  onChange={(event) => {
                    const { raw } = handleFormatDecimalChange(event, { integerDigits: 8 }, true);
                    onChange(raw);
                  }}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleDecimalInputChange(e, 3)}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <FormControl size="small" fullWidth>
                  <InputLabel id="unitOfMeasurement-input-label">
                    Unit
                  </InputLabel>
                  <Select
                    labelId="unitOfMeasurement-label"
                    id="unitOfMeasurement-select"
                    label="Unit"
                    fullWidth
                    size="small"
                    value={value}
                    required
                    error={!!formErrors.ID_UM}
                    {...register("ID_UM")}
                  >
                    {unitsOfMeasurement.map((unitOfMeasurement) => (
                      <MenuItem
                        key={`unitOfMeasurement-${unitOfMeasurement.id}`}
                        value={unitOfMeasurement.id}
                      >
                        {unitOfMeasurement.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              name="ID_UM"
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="NUM_VLR_UNIT"
              control={control}
              render={({ field: { value, onChange, ...restField }, }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="Unit Value"
                  size="small"
                  variant="outlined"
                  {...restField}
                  value={value && formatDecimalFree(value, { integerDigits: 12 }).formatted}
                  error={!!formErrors.NUM_VLR_UNIT}
                  InputLabelProps={{ shrink: !!value }}
                  inputProps={{ maxLength: 255 }}
                  required
                  onChange={(event) => {
                    const { raw } = handleFormatDecimalChange(event, { integerDigits: 12 }, true);
                    onChange(raw);
                  }}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleDecimalInputChange(e, 4)}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              control={control}
              rules={{
                pattern: {
                  value: /^\d{1,11}(,\d{0,3})?$/,
                  message: "Invalid format. Example: 10000,000 or 123421312,234"
                }
              }}
              render={({ field: { value, onChange }, }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="Total Value"
                  size="small"
                  variant="outlined"
                  defaultValue={value && formatDecimalFree(value, { integerDigits: 12 }).formatted}
                  value={value && formatDecimalFree(value, { integerDigits: 12 }).formatted}
                  error={!!formErrors.VALOR_TOTAL}
                  InputLabelProps={{ shrink: !!value }}
                  {...register("VALOR_TOTAL")}
                  inputProps={{ maxLength: 255 }}
                  required
                  onChange={(event) => {
                    const { raw } = handleFormatDecimalChange(event, { integerDigits: 12 }, true);
                    onChange(raw);
                  }}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleDecimalInputChange(e, 4)}
                />
              )}
              name="VALOR_TOTAL"
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <FormControl size="small" fullWidth>
                  <InputLabel id="country-input-label">Country</InputLabel>
                  <Select
                    labelId="country-input-label"
                    id="country-select"
                    label="Country"
                    fullWidth
                    size="small"
                    defaultValue={value}
                    value={value}
                    required
                    error={!!formErrors.ID_PAIS_ORIGEM}
                    {...register("ID_PAIS_ORIGEM")}
                  >
                    {countries.map((country) => (
                      <MenuItem
                        key={`country-${country.id}`}
                        value={country.id}
                      >
                        {country.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              name="ID_PAIS_ORIGEM"
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="Part Number"
                  size="small"
                  variant="outlined"
                  defaultValue={value}
                  error={!!formErrors.NUM_PN}
                  InputLabelProps={{ shrink: !!value }}
                  {...register("NUM_PN")}
                  inputProps={{ maxLength: 255 }}
                  required
                />
              )}
              name="NUM_PN"
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="Serial Number"
                  size="small"
                  variant="outlined"
                  defaultValue={value}
                  error={!!formErrors.DSC_SERIAL_NUMBER}
                  InputLabelProps={{ shrink: !!value }}
                  {...register("DSC_SERIAL_NUMBER")}
                  inputProps={{ maxLength: 255 }}
                />
              )}
              name="DSC_SERIAL_NUMBER"
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="Purchase Group"
                  size="small"
                  variant="outlined"
                  required
                  defaultValue={value}
                  disabled
                  error={!!formErrors.CODIGO_GRUPO}
                  InputLabelProps={{ shrink: !!value }}
                  {...register("CODIGO_GRUPO")}
                  inputProps={{ maxLength: 255 }}
                />
              )}
              name="CODIGO_GRUPO"
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="Purchase Group Name"
                  size="small"
                  variant="outlined"
                  defaultValue={value}
                  disabled
                  error={!!formErrors.GRUPO_COMPR_NOME}
                  InputLabelProps={{ shrink: !!value }}
                  {...register("GRUPO_COMPR_NOME")}
                  inputProps={{ maxLength: 255 }}
                />
              )}
              name="GRUPO_COMPR_NOME"
            />
          </Grid>
          {
            invoicePoItem?.edit && invoicePoItem.edit === true && (
              <Grid item xs={3}>
                <Controller
                  control={control}
                  render={({ field: { value } }) => (
                    <TextField
                      fullWidth
                      type="text"
                      label="Li Status"
                      size="small"
                      variant="outlined"
                      defaultValue={value}
                      disabled
                      error={!!formErrors.DSC_STAT_LI}
                      InputLabelProps={{ shrink: !!value }}
                      {...register("DSC_STAT_LI")}
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                  name="DSC_STAT_LI"
                />
              </Grid>
            ) 
          }
          {
            invoicePoItem?.edit && invoicePoItem.edit === true && (
              <Grid item xs={3}>
                <Controller
                  control={control}
                  render={({ field: { value } }) => (
                    <TextField
                      fullWidth
                      type="text"
                      label="Import Licence"
                      size="small"
                      variant="outlined"
                      defaultValue={value}
                      disabled
                      error={!!formErrors.DSC_LI}
                      InputLabelProps={{ shrink: !!value }}
                      {...register("DSC_LI")}
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                  name="DSC_LI"
                />
              </Grid>
            ) 
          }
          {
            invoicePoItem?.edit && invoicePoItem.edit === true && (
              <Grid item xs={3}>
                <Controller
                  control={control}
                  render={({ field: { value } }) => (
                    <TextField
                      fullWidth
                      type="text"
                      label="HTS Code"
                      size="small"
                      variant="outlined"
                      defaultValue={value}
                      disabled
                      error={!!formErrors.DSC_NCM_HTS}
                      InputLabelProps={{ shrink: !!value }}
                      {...register("DSC_NCM_HTS")}
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                  name="DSC_NCM_HTS"
                />
              </Grid>
            ) 
          }
          {
            invoicePoItem?.edit && invoicePoItem.edit === true && (
              <Grid item xs={3}>
                <Controller
                  control={control}
                  render={({ field: { value } }) => (
                    <TextField
                      fullWidth
                      type="text"
                      label="SPI"
                      size="small"
                      variant="outlined"
                      defaultValue={value}
                      disabled
                      error={!!formErrors.DSC_SPI}
                      InputLabelProps={{ shrink: !!value }}
                      {...register("DSC_SPI")}
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                  name="DSC_SPI"
                />
              </Grid>
            )
          }
        </Grid>
        <Grid container marginTop={1} justifyContent="flex-evenly" spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid container item xs={6} justifyContent={"flex-end"}>
            <Button variant="contained" type="submit">
              {invoicePoItem?.ID_INVOICE_ITEM ? (
                <>
                  Save
                </>
              ) : (
                <>
                  <AddIcon />
                  Add
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
