import {
  Autocomplete,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { useSnackbar } from "notistack";
import { ComboBoxType } from "../../../../../types/general";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { findFileStatus, findFileType, findFreightForwarder } from "../../../../../services/ediFile";

interface BasicFormReportProps {
  setParams: (params: any) => void;
}

let ediFileScheme = yup.object({
  interface: yup.string().notRequired(),
  initialDate: yup.string().notRequired(),
  finalDate: yup.string().notRequired(),
  fileType: yup.string().notRequired(),
  fileStatus: yup.string().notRequired(),
});

export default function SearchEdi({
  setParams,
}: BasicFormReportProps) {

  const [freightForwarderCombo, setFreightForwarderCombo] = useState<ComboBoxType[]>([]);
  const [fileTypeCombo, setFileTypeCombo] = useState<ComboBoxType[]>([]);
  const [fileStatusCombo, setFileStatusCombo] = useState<ComboBoxType[]>([]);

  const {
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(ediFileScheme),
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleFormSearch = (searchParams) => {
    if(!searchParams?.interface) {
      enqueueSnackbar("At least one freight forwarder must be selected.", {
        variant: "warning",
      });
      return;
    }
    setParams((prevState: any) => ({
      ...prevState,
      page: 1,
      interface: searchParams.interface,
      initialDate: searchParams.initialDate,
      finalDate: searchParams.finalDate,
      fileType: searchParams.fileType,
      fileStatus: searchParams.fileStatus,
    }));
  }

  useEffect(() => {
    findFreightForwarder().then((data) => setFreightForwarderCombo(data));
    findFileType().then((data) => setFileTypeCombo(data));
    findFileStatus().then((data) => setFileStatusCombo(data));
  }, [])

  return (
    <form
      onSubmit={handleSubmit(handleFormSearch)}
      style={{ width: "100%" }}
      data-testid="edi-file-form"
    >
      <Grid container spacing={2} marginBottom={2} marginTop={4}>
        <Grid item md={6} xs={6}>
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={freightForwarderCombo || []}
                getOptionLabel={(option: ComboBoxType) =>
                  `${option.description}`
                }
                value={
                  freightForwarderCombo?.findLast(
                    (item: any) => item.id === value
                  ) || null
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Interface - Freight Forwarder"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.id);
                }}
              />
            )}
            name="interface"
          />
        </Grid>

        <Grid item md={6} xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name="initialDate"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DatePicker
                  sx={{
                    "& .MuiInputBase-root": { height: "39px" },
                    "& .MuiFormLabel-root": { top: "-7px" },
                    marginRight: 2,
                  }}
                  label='Initial Date'
                  slotProps={{
                    textField: {
                      variant: "outlined",
                    },
                    field: { clearable: true, onClear: () => onChange(null) },
                  }}
                  onChange={(date: any) => onChange(date)}
                />
              )}
            />
            <Controller
              name="finalDate"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DatePicker
                  sx={{
                    "& .MuiInputBase-root": { height: "39px" },
                    "& .MuiFormLabel-root": { top: "-7px" },
                  }}
                  label='Final Date'
                  slotProps={{
                    textField: {
                      variant: "outlined",
                    },
                    field: { clearable: true, onClear: () => onChange(null) },
                  }}
                  onChange={(date: any) => onChange(date)}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item md={4} xs={6}>
          <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={fileTypeCombo || []}
                getOptionLabel={(option: ComboBoxType) =>
                  `${option.description}`
                }
                value={
                  fileTypeCombo?.findLast(
                    (item: any) => item.id === value
                  ) || null
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="File Type"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.id);
                }}
              />
            )}
            name="fileType"
          />
        </Grid>

        <Grid item md={4} xs={6}>
        <Controller
            control={control}
            render={({ field: { value, ref, onChange, ...field } }) => (
              <Autocomplete
                options={fileStatusCombo || []}
                getOptionLabel={(option: ComboBoxType) =>
                  `${option.description}`
                }
                value={
                  fileStatusCombo?.findLast(
                    (item: any) => item.id === value
                  ) || null
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    {...field}
                    label="File Status"
                    size="small"
                    inputRef={ref}
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChange(data?.id);
                }}
              />
            )}
            name="fileStatus"
          />
        </Grid>

        <Grid item md={2} xs={2}>
          <Button
            startIcon={<SearchIcon />}
            variant="outlined"
            data-testid="submit-button"
            type="submit"
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
