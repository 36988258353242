import { useEffect, useRef, useState } from "react";
import { isAxiosError } from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import { enqueueSnackbar } from "notistack";
import { findPoItems } from "../../../../../services/po";
import PoItemsDataTable from "../table/tablePoItems";
import InvoicePoItemsDataTable from "../table/tableInvoicePoItems";
import InvoiceCompositionForm from "./invoiceCompositionForm";
import {
  createInvoicePoItem,
  removeInvoicePoItem,
  updateInvoicePoItem,
} from "../../../../../services/invoice";
import { Accordion } from "../../../../../components/Accordion/Accordion";
import { AccordionSummary } from "../../../../../components/Accordion/AccordionSummary";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { ComboBoxType } from "../../../../../types/general";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import { PoItem } from "../../../../../types/poItem";
import { InvoiceItemPo, InvoiceItemPoFormData } from "../../../../../types/invoiceItemPo";
import { InvoiceDiscrepancy } from "../../../../../types/invoiceDiscrepancy";
import { Po } from "../../../../../types/po";

type InvoiceCompositionDetailsProps = {
  isOpen: boolean;
  onClose: () => void;
  po: Po;
  invoiceId: number;
  onUpdate: () => void;
  invoicePoItems: InvoiceItemPo[];
  countries: ComboBoxType[];
  unitsOfMeasurement: ComboBoxType[];
};

export const InvoiceCompositionDetails = ({
  isOpen,
  onClose,
  invoiceId,
  po,
  onUpdate,
  invoicePoItems,
  countries,
  unitsOfMeasurement,
}: InvoiceCompositionDetailsProps) => {
  const invoiceFormRef = useRef<HTMLDivElement | null>(null);

  const [poItems, setPoItems] = useState<PoItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPoItem, setSelectedPoItem] = useState<PoItem | null>(null);
  const [selectedPoItemToSeeInvoices, setSelectedPoItemToSeeInvoices] =
    useState<PoItem>();
  const [errors, setErrors] = useState<string[]>([]);
  const [invoicePoItem, setInvoicePoItem] = useState<InvoiceItemPo | null>();
  const [search, setSearch] = useState<string>("");
  const [
    openDeleteInvoicePoItemConfirmation,
    setOpenDeleteInvoicePoItemConfirmation,
  ] = useState<boolean>(false);
  const [openPoItemToSeeInvoices, setOpenPoItemToSeeInvoices] =
    useState<boolean>(false);
  const [invoiceDiscrepancies, setInvoiceDiscrepancies] = useState<
    InvoiceDiscrepancy[]
  >([]);
  const [openDiscrepanciesModal, setOpenDiscrepanciesModal] =
    useState<boolean>(false);
  const [hasData, setHasData] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  const [paginationModelInvoice, setPaginationModelInvoice] = useState({
    page: 1,
    pageSize: 5,
  });

  const getPoItems = async (params?: { search?: string }) => {
    setIsLoading(true);
    try {
      const data = await findPoItems(po.ID_PO!, params);
      console.log({ data });
      setPoItems(data);
    } catch (error) {
      enqueueSnackbar("Error fetching PO items", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectPoItem = (poItem: PoItem) => {
    setSelectedPoItem(poItem);
    setTimeout(() => {
      if (invoiceFormRef.current) {
        invoiceFormRef.current.scrollIntoView({ behavior: 'smooth' });
        invoiceFormRef.current.focus();
      }
    }, 0);
  };

  const handleSelectPoItemInvoices = async (poItem: PoItem) => {
    setSelectedPoItemToSeeInvoices(poItem);
    setOpenPoItemToSeeInvoices(true);
  };

  const handleSelectInvoicePoItem = (invoicePoItem: Partial<InvoiceItemPo>, edit = false) => {
    const parsedPoItem = {
      ...invoicePoItem,
      VALOR_TOTAL: invoicePoItem.VALOR_TOTAL?.toString()?.replace('.', ','),
      edit
    };
    setInvoicePoItem(parsedPoItem as InvoiceItemPo);
    setSelectedPoItem({
      ...invoicePoItem.poItem,
      po: {
        ...invoicePoItem.po,
        grupoComprador: invoicePoItem.grupoComprador
      }
    } as PoItem);
  };

  const resetAll = () => {
    setInvoicePoItem(null);
    setOpenDeleteInvoicePoItemConfirmation(false);
    setSearch("");
    setInvoicePoItem(null);
    setSelectedPoItem(null);
    setErrors([]);
    setInvoiceDiscrepancies([]);
    setOpenDiscrepanciesModal(false);
    onUpdate();
  };

  const handleDeleteInvoicePoItem = async (
    invoicePoItem: Partial<InvoiceItemPo>
  ) => {
    setOpenDeleteInvoicePoItemConfirmation(true);
    setInvoicePoItem(invoicePoItem as InvoiceItemPo);
  };

  const handleDeleteInvoicePoItemConfirm = async () => {
    setIsLoading(true);
    try {
      await removeInvoicePoItem(
        invoiceId,
        invoicePoItem?.ID_INVOICE_ITEM as number
      );
      enqueueSnackbar("Invoice Po Item deleted successfully", {
        variant: "success",
      });
      resetAll();
    } catch (err) {
      setErrors(isAxiosError(err) ? err?.response?.data?.message : (err as { message: string })?.message);
    }
    setIsLoading(false);
  };

  const invoicePoItemDTO = (
    invoicePoItemParams: InvoiceItemPo,
    poItem: PoItem
  ): InvoiceItemPo => {
    return {
      ID_INVOICE: invoiceId,
      ID_INVOICE_ITEM: invoicePoItemParams.ID_INVOICE_ITEM!,
      ID_PO_LIN: poItem.ID_PO_LIN!,
      NUM_LIN_PO: poItem.NUM_LIN_PO,
      DSC_PN: invoicePoItemParams.DSC_PN,
      DSC_NCM_HTS: invoicePoItemParams.DSC_NCM_HTS,
      DSC_SERIAL_NUMBER: invoicePoItemParams.DSC_SERIAL_NUMBER,
      ID_GRUPO_COMPR: +invoicePoItemParams.ID_GRUPO_COMPR,
      ID_PAIS_ORIGEM: +invoicePoItemParams.ID_PAIS_ORIGEM,
      ID_UM: +invoicePoItemParams.ID_UM,
      NUM_PN: invoicePoItemParams.NUM_PN,
      NUM_VLR_UNIT: +invoicePoItemParams.NUM_VLR_UNIT,
      QTDE_PN: +invoicePoItemParams.QTDE_PN,
      VALOR_TOTAL: +invoicePoItemParams.VALOR_TOTAL,
      NUM_CEMB: poItem.NUM_CEMB_MAT,
    } as InvoiceItemPo;
  };

  const handleOnCancel = () => {
    resetAll();
  };

  const handleFormSubmit = async (
    invoicePoItemParams: InvoiceItemPoFormData,
    confirm = false
  ) => {
    setInvoicePoItem(invoicePoItemParams as InvoiceItemPo);
    setIsLoading(true);
    try {
      const invoicePoItem = invoicePoItemDTO(
        invoicePoItemParams as InvoiceItemPo,
        selectedPoItem as PoItem
      );

      let incomingInvoiceDiscrepancies = [];

      if (invoicePoItemParams.ID_INVOICE_ITEM) {
        const { invoiceDiscrepancies } = await updateInvoicePoItem(
          invoiceId,
          invoicePoItem.ID_INVOICE_ITEM!,
          invoicePoItem,
          { confirm }
        );

        if (invoiceDiscrepancies) {
          incomingInvoiceDiscrepancies = invoiceDiscrepancies;
        } else {
          enqueueSnackbar("Invoice Po Item updated successfully", {
            variant: "success",
          });
        }
      } else {
        const { invoiceDiscrepancies } = await createInvoicePoItem(
          invoiceId,
          invoicePoItem,
          { confirm }
        );

        if (invoiceDiscrepancies) {
          incomingInvoiceDiscrepancies = invoiceDiscrepancies;
        } else {
          enqueueSnackbar("Invoice Po Item created successfully", {
            variant: "success",
          });
          resetAll();
        }
      }

      if (incomingInvoiceDiscrepancies.length > 0) {
        setInvoiceDiscrepancies(incomingInvoiceDiscrepancies);
        setOpenDiscrepanciesModal(true);
      } else {
        resetAll();
      }
    } catch (err) {
      enqueueSnackbar("Could not save Invoice Item Po", {
        variant: "error",
      });
    }
    setIsLoading(false);
  };

  const handleSearchPoItem = () => {
    setSelectedPoItem(null);
    getPoItems({ search });
  };

  const handleRefreshSearchPoItem = () => {
    setSearch("");
    setSelectedPoItem(null);
    getPoItems();
  };

  const handleOnClose = () => {
    resetAll();
    onClose();
  };

  useEffect(() => {
    const fetchPoItems = async (params?: { search?: string }) => {
      setIsLoading(true);
      try {
        const data = await findPoItems(po.ID_PO!, params);
        setPoItems(data);
      } catch (error) {
        enqueueSnackbar("Error fetching PO items", {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (po?.ID_PO && isOpen) {
      fetchPoItems();
    }
  }, [po?.ID_PO, isOpen]);

  useEffect(() => {
    if (selectedPoItem) {
      const poItem = poItems.find(
        (item) => item.ID_PO_LIN === selectedPoItem?.ID_PO_LIN
      );

      setInvoicePoItem({
        ...invoicePoItem,
        DSC_NCM_HTS: poItem?.DSC_HTS,
        DSC_SPI: poItem?.DSC_SPI,
        GRUPO_COMPR_NOME: selectedPoItem?.po?.grupoComprador?.NOME_COMPRADOR ?? "",
        CODIGO_GRUPO: selectedPoItem?.po?.grupoComprador?.CODIGO_GRUPO ?? "",
        DSC_PN: selectedPoItem?.DSC_PN ?? "",
        ...(!invoicePoItem?.ID_INVOICE_ITEM && {
          ID_GRUPO_COMPR: po.ID_GRUPO_COMPR,
          CODIGO_GRUPO: po.grupoComprador?.CODIGO_GRUPO ?? "",
          ID_PAIS_ORIGEM: po.ID_PAIS_PAGADOR,
        }),
      } as InvoiceItemPo);
    }
  }, [selectedPoItem]);

  useEffect(() => {
    setHasData(!!invoicePoItems && invoicePoItems.length > 0)
  }, [invoicePoItems]);

  if (!po) {
    return <></>;
  }

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <Dialog
        open={isOpen}
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
        sx={{
          transform: 'translateY(64px)',
          height: 'calc(100% - 64px)'
        }}
      >
        <DialogTitle id="alert-dialog-title" width={"100%"}>
          <Grid container alignItems={"center"} width={"100%"}>
            <Grid sm={10}>Invoice Details</Grid>
            <Grid sm={2} container justifyContent="center">
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleOnClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h5">PO: {po.NUMERO_PO}</Typography>
          <Grid
            data-testid="search"
            container
            marginTop={1}
            alignItems="center"
            marginBottom={4}
          >
            <Grid item xs={4}>
              <TextField
                label="Type Line for search..."
                size="small"
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={1} container justifyContent={"center"}>
              <Button
                variant="contained"
                onClick={handleSearchPoItem}
                data-testid="search-button"
              >
                <SearchIcon />
              </Button>
            </Grid>

            <Grid item xs={1} container justifyContent={"center"}>
              <Button
                variant="outlined"
                onClick={() => handleRefreshSearchPoItem()}
                data-testid="refresh-button"
              >
                <RefreshIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid>
            <PoItemsDataTable
              data={poItems}
              rowCount={poItems.length}
              selectedPoItem={selectedPoItem}
              onSelectPoItem={handleSelectPoItem}
              onSelectPoItemInvoices={handleSelectPoItemInvoices}
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={(e: any) => setPaginationModel(e)}
              invoicePoItems={invoicePoItems}
              isEditing={!!invoicePoItem}
            />
          </Grid>

          {selectedPoItem && (
            <Grid container justifyContent={"center"} paddingX={"5px"}>
              <div ref={invoiceFormRef}>
                <InvoiceCompositionForm
                  invoicePoItem={invoicePoItem!}
                  errors={errors}
                  onSubmit={handleFormSubmit}
                  countries={countries}
                  unitsOfMeasurement={unitsOfMeasurement}
                  onCancel={handleOnCancel}
                />
              </div>
            </Grid>
          )}

          <Grid>
            <Accordion
              sx={{
                "&.MuiAccordion-root": {
                  border: 0,
                  marginBottom: 3,
                  marginTop: 1,
                },
              }}
              defaultExpanded
            >
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                applyOpacity={!hasData}
              >
                <Typography>Invoice PO Line</Typography>
              </AccordionSummary>
              <InvoicePoItemsDataTable
                data={invoicePoItems}
                rowCount={invoicePoItems.length}
                mode="Edit"
                onEdit={(value) => handleSelectInvoicePoItem(value, true)}
                onDelete={handleDeleteInvoicePoItem}
                paginationModel={paginationModelInvoice}
                onPaginationModelChange={(e: any) => setPaginationModelInvoice(e)}
              />
            </Accordion>
          </Grid>
          <DialogActions>
          <Grid container justifyContent="space-between">
            <Button
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                color: "currentColor",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  color: "currentColor",
                },
              }}
              variant="contained"
              color="secondary"
              onClick={handleOnClose}
            >
              Return
            </Button>
          </Grid>
        </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDeleteInvoicePoItemConfirmation}
        onClose={() => setOpenDeleteInvoicePoItemConfirmation(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" width={"100%"}>
          <Grid container alignItems={"center"} width={"100%"}>
            <Grid sm={10}>
              Are you sure you want to delete this Invoice Po Line?
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Button
              onClick={() => setOpenDeleteInvoicePoItemConfirmation(false)}
            >
              Cancel
            </Button>
            <Button
              data-testid="confirmation-button"
              onClick={handleDeleteInvoicePoItemConfirm}
            >
              Confirm
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPoItemToSeeInvoices}
        onClose={() => setOpenPoItemToSeeInvoices(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container alignItems="center">
            <Grid item sm={10}>
              PO Line: {selectedPoItemToSeeInvoices?.NUM_LIN_PO}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item sm={6}>
              <Typography variant="subtitle1"><strong>Invoice</strong></Typography>
            </Grid>
            <Grid item sm={6} style={{ textAlign: 'center' }}>
              <Typography variant="subtitle1"><strong>Quantity</strong></Typography>
            </Grid>
          </Grid>
          {selectedPoItemToSeeInvoices?.invoiceItemsPo.map((invoiceItemPo, index) => (
            <>
              <Grid container alignItems="center" spacing={2}>
                <Grid item sm={6}>
                  <Typography>{invoiceItemPo.invoice.NUM_INVOICE}</Typography>
                </Grid>
                <Grid item sm={6} style={{ textAlign: 'center' }}>
                  <Typography>{+invoiceItemPo.QTDE_PN}</Typography>
                </Grid>
              </Grid>
              {index < selectedPoItemToSeeInvoices.invoiceItemsPo.length - 1 && (
                <Divider style={{ width: '100%', margin: '8px 0' }} />
              )}
            </>
          ))}
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Button onClick={() => setOpenPoItemToSeeInvoices(false)}>
              Close
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDiscrepanciesModal}
        onClose={() => setOpenDiscrepanciesModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" width={"100%"}>
          <Grid container alignItems={"center"} width={"100%"}>
            <Grid sm={10}>
              There are discrepancies for the general data, would you like
              to continue?
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems={"center"} width={"100%"}>
            {invoiceDiscrepancies.map(
              (invoiceDiscrepancy: InvoiceDiscrepancy) => (
                <Grid key={invoiceDiscrepancy.ID_DIVERGENCIA} container>
                  <Grid sm={12}>
                    <Typography>
                      - {invoiceDiscrepancy.discrepancy?.DSC_DIVERGENCIA}
                    </Typography>
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Button onClick={() => setOpenDiscrepanciesModal(false)}>
              Close
            </Button>
            <Button
              data-testid="confirmation-button"
              onClick={() => handleFormSubmit(invoicePoItem! as InvoiceItemPoFormData, true)}
            >
              Confirm
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
