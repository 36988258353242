import { createContext, useEffect, useState, ReactNode, useContext } from 'react'
import { findFieldsRelations, findFieldsToHide } from '../../../../services/translation'
import { EntitiesFieldsToHide } from '../../../../types/report'

const defaultProvider = {
  fieldsToHide: {
    global: [],
    byEntity: [],
    byRelation: [],
  },
  customColumn: [],
  isThisPathOpen: (path: string) => false,
  togglePath: (path: string) => {},
}

const FieldsToHideContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

const FieldsToHideProvider = ({ children }: Props) => {
  const [fieldsToHide, setFieldsToHide] = useState<EntitiesFieldsToHide>(defaultProvider.fieldsToHide);
  const [pathOpen, setPathOpen] = useState<string[]>([]);
  const [customColumn, setCustomColumn] = useState([]);

  useEffect(() => {
    findFieldsToHide().then((response) => {
      const byEntity = response
        .filter((reponseItem) => reponseItem.NOME_TABELA !== 'RELATION')
        .map((reponseItem) => `${reponseItem.NOME_TABELA}.${reponseItem.NOME_ATRIBUTO}`);

      setFieldsToHide({
        byEntity,
        global: ['created_at', 'updated_at'],
      });
    })
  }, [])

  useEffect(() => {
    findFieldsRelations().then((response) => {
      const customColumnsRaw = response.map((fieldRelation) => ({
        name: fieldRelation.NOME_ATRIBUTO,
        route: `/reports/dynamicReport/comboBox?TABLE_NAME=${fieldRelation.NOME_TABELA}&COLUMN=${fieldRelation.NOME_ATRIBUTO}`,
      }))
      const customColumn = [
        {
          name: 'ID_CENTRO',
          route: '/centers/getCentersWithCompaniesCombo',
        },
        {
          name: 'ID_PARCEIRO_AGENTE_CARGA',
          route: '/partners/getComboWithoutCenter?partnerType=FREIGHT FORWARDER',
        },
        {
          name: 'ID_PARCEIRO_DOMESTIC_CARRIER',
          route: '/partners/getComboWithoutCenter?partnerType=CARRIER',
        },
        {
          name: 'ID_PARCEIRO_BROKER',
          route: '/partners/getComboWithoutCenter?partnerType=BROKER',
        },
        {
          name: 'ID_PARCEIRO',
          route: '/partners/getComboWithoutCenter?partnerType=CARRIER',
        },
        {
          name: 'ID_MOEDA',
          route: '/currencies/getCombo',
        },
        {
          name: 'ID_AEROPORTO',
          route: '/airports/getCombo',
        },
      ]

      const customColumnsFiltered = customColumnsRaw
        .filter(column => !customColumn
          .some(customColumnItem => customColumnItem.name === column.name)
        )

      setCustomColumn([ ...customColumnsFiltered, ...customColumn ]);
    })
  }, [])

  const togglePath = (path: string) => {
    if(isThisPathOpen(path)) {
      const newPath = pathOpen.filter((pathItem) => pathItem !== path);
      setPathOpen(newPath);
      return;
    }

    pathOpen.push(path);
    setPathOpen(pathOpen);
  }

  const isThisPathOpen = (path: string) => {
    return pathOpen.some((pathItem) => pathItem === path);
  }

  const values = {
    fieldsToHide,
    customColumn,
    togglePath,
    isThisPathOpen,
  }

  return <FieldsToHideContext.Provider value={values}>{children}</FieldsToHideContext.Provider>
}

const useFieldsToHide = () => useContext(FieldsToHideContext)

export { FieldsToHideContext, FieldsToHideProvider, useFieldsToHide }
