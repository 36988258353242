import { useState, useEffect } from 'react';
import HorizontalBarChart from "../components/Graphs/HorizontalBarChart";
import StackedBarChart from "../components/Graphs/StackedBarChart";
import {getSendVisibilities, getSendVisibilitiesMail} from "../../../../services/report";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Search from "../components/Search";
import { getCentersWithCompaniesCombo } from "../../../../services/centers";
import ListAndSendMail from "../components/Form/ListAndSendMail";

export default function SendVisibilities() {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [centers, setCenters] = useState([]);
    const [selectedCenter, setSelectedCenter] = useState(null);


    const [chartImage, setChartImage] = useState(null);
    const [chartImage2, setChartImage2] = useState(null);
    const [horizontalChartImage, setHorizontalChartImage] = useState(null);
    const [horizontalChartImage2, setHorizontalChartImage2] = useState(null);


    const handleHorizontalChartImageGenerated = (imageBase64) => setHorizontalChartImage(imageBase64);
    const handleHorizontalChartImageGenerated2 = (imageBase64) => setHorizontalChartImage2(imageBase64);
    const handleImageGenerated = (imageBase64) => setChartImage(imageBase64);
    const handleImageGenerated2 = (imageBase64) => setChartImage2(imageBase64);


    const sendImageToBackend = async () => {
        if (chartImage) {
            console.log('Imagem gerada:', chartImage);
        }
        //     try {
        //         const response = await fetch('/api/upload-chart', {
        //             method: 'POST',
        //             headers: {
        //                 'Content-Type': 'application/json',
        //             },
        //             body: JSON.stringify({ image: chartImage }),
        //         });
        //         console.log('Imagem enviada com sucesso!', await response.json());
        //     } catch (err) {
        //         console.error('Erro ao enviar a imagem:', err);
        //     }
        // }

    };

    useEffect(() => {
        async function fetchCenters() {
            try {
                const centersResponse = await getCentersWithCompaniesCombo();
                setCenters(centersResponse);
            } catch (error) {
                console.error('Error fetching centers:', error);
            }
        }
        fetchCenters();
    }, []);
    useEffect(() => {
        if (!selectedCenter?.ID_CENTRO) return;
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getSendVisibilities(selectedCenter.ID_CENTRO);
                setData(response);
            } catch (error) {
                console.error('Error fetching send visibilities:', error);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, [selectedCenter]);


    const TriageGraphConfig = [
        { dataKey: 'Waiting Attachments', fill: '#1976d2', name: 'Waiting Attachments' },
        { dataKey: 'Under Analysis', fill: '#31c299', name: 'Under Analysis' },
        { dataKey: 'Missing Invoice', fill: '#846fde', name: 'Missing Invoice' },
        { dataKey: 'Under Logistics Analysis', fill: '#fb6d5a', name: 'Under Logistics Analysis' },
        { dataKey: 'Pending', fill: '#ffbc56', name: 'Pending' },
        {
            dataKey: 'Waiting to Move to Receiving',
            fill: '#1976d2',
            name: 'Waiting to Move to Receiving',
            hasLabelList: true,
        },
    ];

    const divergenceGraphConfig = [
        { dataKey: 'num_qtd_inv', fill: '#1976d2', name: 'Qtd. Invoice', hasLabelList: true, },
    ];

    return (
        <Grid marginTop={4}>
            <Grid container spacing={1} direction="column">
                <Grid item xs={10}>
                    <Typography variant="h5">Send Visibilities and Reports</Typography>
                </Grid>
            </Grid>

            <Search
                centers={centers}
                onCenterChange={(center) => setSelectedCenter(center)}
            />

            {/*{chartImage && (*/}
            {/*    <div>*/}
            {/*        <h2>Prévia da Imagem:</h2>*/}
            {/*        <img src={chartImage} alt="Gráfico Gerado" />*/}
            {/*    </div>*/}
            {/*)}*/}

            {/*{horizontalChartImage && (*/}
            {/*    <div>*/}
            {/*        <h2>Prévia da Imagem Horizontal:</h2>*/}
            {/*        <img src={horizontalChartImage} alt="Gráfico Horizontal Gerado" />*/}
            {/*    </div>*/}
            {/*)}*/}

            <Grid marginTop={4}>
                {data && (
                    <>
                        <Typography variant="caption">The generated visibilities will be sent to the e-mails above along with the reports. It may take a few minutes for the recipients to receive the e-mail.</Typography>

                        <ListAndSendMail
                            emails={data?.getEmailByCenter}
                            idCentro={selectedCenter?.ID_CENTRO}
                            graphs={
                                [
                                    { image: chartImage, name: 'Divergence' },
                                    { image: horizontalChartImage, name: 'Divergence - Manager Area' },
                                    { image: chartImage2, name: 'Triage' },
                                    { image: horizontalChartImage2, name: 'Manager Area - Triage' },
                                ]
                            }
                        />
                    </>
                )}
            </Grid>

            <Grid marginTop={4}>
                {data?.getDivergence && (
                    <>
                        <Typography variant="h6">Divergence</Typography>
                        <Grid marginTop={4}>
                            <StackedBarChart
                                data={data.getDivergence}
                                dataLegendKey='dat'
                                TriageGraphConfig={divergenceGraphConfig}
                                MediaAgingKey='num_aging_med'
                                HighestAgingKey='num_aging_max'
                                onImageGenerated={handleImageGenerated}
                            />
                        </Grid>
                    </>
                )}
            </Grid>

            <Grid marginTop={4}>
                {data?.getByManager && (
                    <>
                        <Typography variant="h6">Divergence - Manager's Area</Typography>
                        <HorizontalBarChart
                            agingMaxField="num_aging_max"
                            managerName="nome_gerente"
                            qtdTriagemField="num_qtd_triagem"
                            qtdTriagemNameField="Qtd. Invoice"
                            data={data.getByManager}
                            onImageGenerated={handleHorizontalChartImageGenerated}
                        />
                    </>
                )}
            </Grid>



            <Grid marginTop={4}>
                {data?.getTriageData?.length > 0 && (
                    <>
                        <Typography variant="h6">Triage</Typography>
                        <StackedBarChart
                            data={data.getTriageData}
                            dataLegendKey="date"
                            TriageGraphConfig={TriageGraphConfig}
                            MediaAgingKey="media_aging"
                            HighestAgingKey="aging_max"
                                onImageGenerated={handleImageGenerated2}
                        />
                    </>
                )}
            </Grid>


            <Grid marginTop={4}>
                {data?.managerTriage.length > 0 && (
                    <>
                        <Typography variant="h6">Manager Area - Triage</Typography>
                        <HorizontalBarChart
                            agingMaxField="num_aging_max"
                            managerName="NOME_GERENTE"
                            qtdTriagemField="num_qtd_triagem"
                            data={data.managerTriage}
                            onImageGenerated={handleHorizontalChartImageGenerated2}
                        />
                    </>
                )}
            </Grid>
        </Grid>
    );
};
