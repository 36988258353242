import { LocalizationProvider } from "@mui/x-date-pickers";
import CustomReportRow from "./customReportRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFieldsToHide } from "../hooks/fieldsToHide.hook";

export default function CustomReportFields({
  entity,
  relation,
  startOrder,
}) {

  const { fieldsToHide } = useFieldsToHide();

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {entity?.columns?.filter(
          (column) => (
            !fieldsToHide.global.includes(column.columnName)
            && (!column.isPrimary || column.columnName === 'ID_AUTORIZACAO_EMBARQUE')
            && !fieldsToHide?.byEntity?.some(field => field === `${relation.tableName}.${column.columnName}`)
          ))
          .map((column, index) => (
            <CustomReportRow
              key={`list-item-report-${index}`}
              column={column}
              startOrder={startOrder}
              index={index}
              relation={relation}
            />
          ))}
      </LocalizationProvider>
    </>
  )
}