import axios from "axios";
import { getToken } from "../storage/auth";
import { Logger } from "./logger";

const handleLog = new Logger();
const api_timeout = process.env.NODE_ENV === 'test' ? 1 : 30000;

async function logRequest(request) {
  request._log = { headers: request.headers, data: request.data };
  return request;
}

function logAndHandleResponse(response: any) {
  return handleLog.createLog(response);
}

function handleRequestError(error: any) {
  // Logar ou tratar o erro de requisição aqui
  handleLog.createLog(error);
  return Promise.reject(error);
}

// Função para tratar erros de resposta
function handleResponseError(error: any) {
  // Logar ou tratar o erro de resposta aqui
  if(process.env.NODE_ENV === 'test') console.error(error);

  else {
    handleLog.createLog(error);
    return Promise.reject(error);
  }
}

export function createApiInstance(baseURL: string | undefined, timeout: number, headersType: string) {
  const api = axios.create({
    baseURL: baseURL,
    timeout: timeout,
    headers: { 'Content-Type': headersType },
  });

// Usando as funções de tratamento de erro nos interceptadores
  api.interceptors.request.use(logRequest, handleRequestError);
  api.interceptors.response.use(logAndHandleResponse, handleResponseError);

  api.interceptors.request.use((config) => {
    const token = getToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
  return api;
}

export const api = createApiInstance(process.env.REACT_APP_API_URL_CORE, api_timeout, 'application/json');
