import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { Accordion } from "../../../../../components/Accordion/Accordion";
import { AccordionSummary } from "../../../../../components/Accordion/AccordionSummary";
import { AccordionDetails, Box, Button, Grid, MenuItem, Switch, TextField, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add"
import { WrQuarantineData } from "../../../../../types/wrQuarantine";
import React, { useEffect, useMemo, useState } from "react";
import { Quarantine } from "../../../../../types/quarantine";
import { closeWrQuarantine, create } from "../../../../../services/wrQuarantine";
import { enqueueSnackbar } from "notistack";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import dayjs from "dayjs";

type QuarantineGridItemProps = {
  wrQuarantine?: WrQuarantineData,
  header: boolean,
  grayBg: boolean,
  handleCloseWrQuarantine: (idWrQuarantine?: number) => void;
}

const QuarantineGridItem = ({ wrQuarantine, header, grayBg, handleCloseWrQuarantine }: QuarantineGridItemProps) => {
  const dataAberturaFormatted = useMemo(() => {
    if (wrQuarantine?.DATA_ABERTURA) {
      return dayjs(wrQuarantine?.DATA_ABERTURA).format("MM/DD/YYYY");
    }

    return undefined;
  }, [wrQuarantine?.DATA_ABERTURA]);

  const dataEncerramentoFormatted = useMemo(() => {
    if (wrQuarantine?.DATA_ENCERRAMENTO) {
      const day = new Date(wrQuarantine?.DATA_ABERTURA).getDate();
      const month = new Date(wrQuarantine?.DATA_ABERTURA).getMonth();
      const year = new Date(wrQuarantine?.DATA_ABERTURA).getFullYear();

      return `${month < 10 ? `0${month}` : month}/${day < 10 ? 0 : ""}${day}/${year}`;
    }

    return undefined;
  }, [wrQuarantine?.DATA_ABERTURA, wrQuarantine?.DATA_ENCERRAMENTO]);

  const backgroundColor = useMemo(() => {
    if (header) return "gray";
    if (grayBg) return "#E4E4E4";

    return "transparent";
  }, [header, grayBg])

  return (
    <Grid container item bgcolor={backgroundColor} padding={1}>
      <Grid item xs={6}>
        {header && (
          <Typography>
            Description
          </Typography>
        )}
        {!header && (
          <Typography>
            {wrQuarantine?.CODIGO_QUARENTENA} - {wrQuarantine?.DESCRICAO}
          </Typography>
        )}
      </Grid>
      {header && (
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12 / 4}>
              <Typography>Open</Typography>
            </Grid>
            <Grid item xs={12 / 4}>
              <Typography>Login</Typography>
            </Grid>
            <Grid item xs={12 / 4}>
              <Typography>Closed</Typography>
            </Grid>
            <Grid item xs={12 / 4}>
              <Typography>Login</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!header && (
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={12 / 4}>
              <Typography fontSize={12}>{dataAberturaFormatted}</Typography>
            </Grid>
            <Grid item xs={12 / 4}>
              <Typography fontSize={12}>{wrQuarantine?.LOGIN_USUARIO_ABERTURA_DSC}</Typography>
            </Grid>
            <Grid item xs={12 / 4}>
              <Typography
                fontSize={12}
              >
                {
                  wrQuarantine?.LOGIN_USUARIO_FECHAMENTO_DSC ?
                    dataEncerramentoFormatted : (
                      <Switch
                        checked={wrQuarantine?.LOGIN_USUARIO_FECHAMENTO_DSC ? true : false}
                        onClick={
                          () => handleCloseWrQuarantine(wrQuarantine?.ID_WR_QUARENTENA)
                        }
                      />
                    )}
              </Typography>
            </Grid>
            <Grid item xs={12 / 4}>
              <Typography fontSize={12}>{wrQuarantine?.LOGIN_USUARIO_FECHAMENTO_DSC}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

type WarehouseQuarantineAccordionProps = Readonly<{
  invoiceId: number,
  wrQuarantines?: WrQuarantineData[],
  quarantines: Quarantine[],
  idWarehouse?: number,
  setRefetchWrQuarantines: React.Dispatch<React.SetStateAction<boolean>>,
  setRefetchWarehouse: React.Dispatch<React.SetStateAction<boolean>>,
  idLoginUsuario?: number,
  isDisabled: boolean,
}>;

export default function WarehouseQuarentineAccordion({ invoiceId, wrQuarantines, quarantines, idWarehouse, setRefetchWrQuarantines, setRefetchWarehouse, idLoginUsuario, isDisabled }: WarehouseQuarantineAccordionProps) {
  const [selectedQuarantineId, setSelectedQuarantineId] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [hasData, setHasData] = useState(false);

  const handleAddNewWrQuarantine = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!idWarehouse) {
      enqueueSnackbar("Error trying to create Warehouse Quarantine", {
        variant: "error",
      });
      return;
    }

    if (!selectedQuarantineId) {
      enqueueSnackbar("You must select an Quarantine Code", {
        variant: "error",
      });
      return;
    }

    try {
      setIsLoading(true);
      if (idLoginUsuario) {
        const { success, message } = await create(invoiceId, {
          ID_WR_QUARENTENA: null,
          ID_QUARENTENA: selectedQuarantineId,
          ID_WAREHOUSE: idWarehouse,
          DATA_ABERTURA: new Date(),
          DATA_ENCERRAMENTO: null,
          ID_LOGIN_USUARIO_ABERTURA: idLoginUsuario,
          ID_LOGIN_USUARIO_FECHAMENTO: null,
        });

        if (success) {
          setIsLoading(false);
          enqueueSnackbar("Warehouse Quarantine created successfully", {
            variant: "success",
          });
          setRefetchWrQuarantines(true);
          setRefetchWarehouse(true);

          return;
        }

        if (message.length > 0) {
          setIsLoading(false);
          enqueueSnackbar(message[0], {
            variant: "error",
          });

          return;
        }
      }

      setIsLoading(false);
      enqueueSnackbar("Error trying to create Warehouse Quarantine", {
        variant: "error",
      });
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar("Error trying to create Warehouse Quarantine", {
        variant: "error",
      });
    }
  }

  const handleCloseWrQuarantine = async (idWrQuarantine?: number) => {
    try {
      if (!idWrQuarantine) {
        enqueueSnackbar("Error trying to close Warehouse Quarantine", {
          variant: "error",
        });
        return;
      }

      setIsLoading(true);
      if (idLoginUsuario) {
        const { success } = await closeWrQuarantine(invoiceId, idWrQuarantine, idLoginUsuario, idWarehouse);

        if (success) {
          setIsLoading(false);
          enqueueSnackbar("Warehouse Quarantine closed successfully", {
            variant: "success",
          });
          setRefetchWrQuarantines(true);
          setRefetchWarehouse(true);
          return;
        }
      }

      setIsLoading(false);
      enqueueSnackbar("Error trying to close Warehouse Quarantine", {
        variant: "error",
      });
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar("Error trying to close Warehouse Quarantine", {
        variant: "error",
      });
    }
  }

  useEffect(() => {
    setHasData(wrQuarantines !== undefined && !!wrQuarantines[0]?.ID_QUARENTENA);
  }, [wrQuarantines]);

  return ( 
    <Box padding={5}>
      <LoadingOverlay isLoading={isLoading} />
      <Accordion
        sx={{
          '&.MuiAccordion-root': { border: 0 },
        }}
      >
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          applyOpacity={!hasData}
        >
          <Typography>Quarantine</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ marginTop: 17, padding: 2 }}>
          <Box sx={{ width: "45%", padding: 0 }} display="flex" flexDirection="row" gap={3} paddingX={3}>
            <TextField
              select
              sx={{ width: "55%" }}
              fullWidth
              label="Quarantine Code"
              size="small"
              onChange={(event) => {
                setSelectedQuarantineId(Number(event.target.value));
              }}
              disabled={isDisabled}
            >
              {quarantines.map((quarantine) => (
                <MenuItem key={quarantine?.ID_QUARENTENA} value={quarantine.ID_QUARENTENA}>{quarantine.CODIGO_QUARENTENA} - {quarantine.DESCRICAO}</MenuItem>
              ))}
            </TextField>
            <Button
              onClick={(e) => handleAddNewWrQuarantine(e)}
              sx={{ width: "40%" }}
              variant="contained"
              startIcon={<AddIcon />}
              disabled={isDisabled}
              style={{
                display: isDisabled ? 'none' : 'inline-flex',
                alignItems: 'center',
              }}
            >
              Add Quarantine
            </Button>
          </Box>
          <Grid container marginTop={3}>
            <QuarantineGridItem
              grayBg={false}
              header={true}
              handleCloseWrQuarantine={(idWrQuarantine) => handleCloseWrQuarantine(idWrQuarantine)}
            />
            {wrQuarantines?.map((wrQuarantine, index) => (
              <QuarantineGridItem
                handleCloseWrQuarantine={(idWrQuarantine) => handleCloseWrQuarantine(idWrQuarantine)}
                grayBg={(index + 1) % 2 === 0}
                key={index}
                header={false}
                wrQuarantine={wrQuarantine}
              />
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}