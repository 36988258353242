import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";

import DataTableDefault from "../../../../../components/DataTable";
import { hasClaim } from "../../../../../storage/userClaims";
import { Label } from "../../../../../types/po";

interface DataTableProps {
  onEditLabel: (params: Label) => void;
  onDeleteLabel: (params: Label) => void;
  data: Label[];
  loading: boolean;
  [x: string]: any;
}
export default function DataTable({
  onEditLabel,
  onDeleteLabel,
  data,
  loading,
  ...props
}: DataTableProps) {
  const renderMenus = (row: any) => {
    return [
      <GridActionsCellItem
        sx={{ display: hasClaim("Label Data - Maintenance") ? "block" : "none" }}
        key={`edit-${row.label_ID_LABEL}`}
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={() => onEditLabel(row)}
        color="inherit"
        data-testid="edit-button"
      />,
      <GridActionsCellItem
        sx={{ display: hasClaim("Label Data - Maintenance") ? "block" : "none" }}
        key={`delete-${row.label_ID_LABEL}`}
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => onDeleteLabel(row)}
        color="inherit"
        data-testid="delete-button"
      />,
    ];
  };

  const columns: GridColDef[] = [
    { 
      field: 'po_NUMERO_PO', 
      headerName: 'PO Number',
      headerClassName: "super-app-theme--header",
    },
    { 
      field: 'poItem_NUM_LIN_PO', 
      headerName: 'PO Line',
      headerClassName: "super-app-theme--header",
    },
    { 
      field: 'centro_DSC_SIGLA', 
      headerName: 'Center/Plant',
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: any) => {
        return `${params?.row?.centro_DSC_SIGLA} - ${params?.row?.centro_NOME_CENTRO}`;
      },
    },
    { 
      field: 'po_SIGLA_INCOTERMS', 
      headerName: 'Incoterm',
      headerClassName: "super-app-theme--header",
    },
    { 
      field: 'po_DSC_INCOTERMS_COMPL', 
      headerName: 'Incoterm Complement',
      headerClassName: "super-app-theme--header",
      width: 160,
    },
    { 
      field: 'label_NUM_TRACKING_LABEL1', 
      headerName: 'Tracking Number 1',
      headerClassName: "super-app-theme--header",
      width: 140,
    },
    { 
      field: 'label_NUM_TRACKING_LABEL2', 
      headerName: 'Tracking Number 2', 
      headerClassName: "super-app-theme--header",
      width: 140,
    }, 
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }) => renderMenus(row),
    },
  ];

  return (
    <>
      <DataTableDefault
        rowId={data?.map((row) => row.label_ID_LABEL)}
        data-testid="label-data-table"
        data={data}
        columns={columns}
        loading={loading}
        {...props}
      />
    </>
  );
}
