import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DataTable from ".";
import { useTranslation } from "../../hooks/translation.hook";
import { translateByField } from "../../../../utils/translation";

export interface DatatableModalProps {
  open: boolean;
  callbackOnCancel: () => void;
  reportStructure: Array<any>;
  modalData: ModalDataProps;
}

export interface ModalDataProps {
  title: string,
  data: [{}],
}

export default function DatatableModal({
  open,
  callbackOnCancel,
  modalData,
  reportStructure,
}: DatatableModalProps) {
  const { translations } = useTranslation();

  const modalDataTranslated = modalData?.title === ''
  ? modalData
  : (Array.isArray(modalData?.data) ? modalData.data : []).map((dataItem: any) => {
      const newItem: any = {};
      Object.keys(dataItem).forEach((key: any) => {
        newItem[translateByField(key, translations)] = dataItem[key];
      });
      return newItem;
    });

  return (
    <Dialog 
      open={open} 
      aria-labelledby="datatable-dialog-title" 
      aria-describedby="datatable-dialog-description"
      maxWidth='lg'
      fullWidth={true}
    >
      <DialogContent>
        {modalData?.data?.length > 0 &&
          <DataTable
            dataRaw={ modalDataTranslated }
            data={ modalDataTranslated }
            reportStructure={ reportStructure }
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            loading={ false }
          />
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => callbackOnCancel()}>Voltar</Button>
      </DialogActions>
    </Dialog>
  )
}