import { ChangeEvent } from "react";

export const convertToNumber = (brNumber: string | number) => {
  const cleanedNumber = (brNumber || '0').toString().replace(/\./g, '').replace(',', '.');
  return parseFloat(cleanedNumber);
}

export const convertToBRNumber = (number: string | number) => {
  let [integerPart, decimalPart] = (number || '0.00').toString().split('.');
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return integerPart + ',' + decimalPart;
}

export const handleDecimalInputChange = (event: ChangeEvent<HTMLInputElement>, decimalQuantity = 2) => {
  const { value } = event.target;
  const formattedValue = value.replace(/[^0-9,]/g, "");

  if (formattedValue.includes(",")) {
    const [integerPart, decimalPart] = formattedValue.split(",");
    if (integerPart.length <= 10 && (!decimalPart || decimalPart.length <= decimalQuantity)) {
      event.target.value = formattedValue;
    } else if (decimalPart.length > decimalQuantity) {
      event.target.value = `${integerPart.slice(0, 10)},${decimalPart.slice(
        0,
        decimalQuantity
      )}`;
    }
  } else {
    event.target.value = formattedValue.slice(0, 10);
  }
};

type ConfigFormatCurrency = {
  fractionDigits?: number,
  integerDigits?: number,
  separator?: string
}

const formatWithOneComma = (str: string) => {
  if (!str.includes(',')) return str;
  const [first, ...rest] = str.split(',');
  return `${first},${rest.join('')}`;
}

export const formatDecimalFree = (rawValue: string | number, { integerDigits = 12, separator = ',' }: ConfigFormatCurrency) => {
  const formattedValue = formatWithOneComma(rawValue.toString().replace('.', ',').replace(/[^0-9,]/g, ""));
  const raw = formatWithOneComma(rawValue.toString());

  if (!formattedValue.includes(separator)) return {
    formatted: formattedValue.slice(0, integerDigits),
    raw
  };

  const [integerPart, decimalPart] = formattedValue.split(separator);
  if (integerPart.length <= integerDigits) return { formatted: formattedValue, raw };  
  return {
    formatted: integerPart.slice(0, integerDigits) + (decimalPart ? separator + decimalPart : ""),
    raw
  };
};

export const formatDecimal = (rawValue: string | number, { integerDigits, fractionDigits = 2, separator = ',' }: ConfigFormatCurrency) => {
  const cleanText = rawValue.toString().replace(/\D/g, '');
  const rawText = typeof integerDigits !== 'undefined' ? cleanText.slice(0, integerDigits + (fractionDigits)) : cleanText;
  const text = rawText.padStart(fractionDigits, '0').split('');
  text.splice(text.length - fractionDigits, 0, separator);
  const [int, float] = text.join('').split(separator);
  return {
    formatted: +int + separator + float.padEnd(fractionDigits, '0'),
    raw: `${int}.${float.padEnd(fractionDigits, '0')}`
  }
}

export const handleFormatDecimalChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, config: ConfigFormatCurrency, free = false) => {
  const data = free ? formatDecimalFree(event.target.value, config) : formatDecimal(event.target.value, config);
  event.target.value = data.formatted;
  return data;
}

export const formatNumberWithThousandsSeparator   = (rawValue: string | number | undefined) => {
  if (typeof rawValue === 'number') {
    rawValue = rawValue.toFixed(2);
  }
  
  const formattedValue = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(rawValue);

  return {
    formatted: formattedValue,
    raw: rawValue
  };
}