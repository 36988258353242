import { api } from "../../lib/api";
import { ReturnToken } from "../../types/signin";

const entity = "auth";

export const signIn = async (token?: string): Promise<ReturnToken> => {
  const { data } = await api.post(`/${entity}/login`, {
    token: token
  });
  return data;
};

export const decodeToken = async (token?: string): Promise<ReturnToken> => {
  const { data } = await api.post(
    `/${entity}/decode`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};
