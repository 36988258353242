import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { Center } from "../../types/center";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
}

const entity = "emailTypes";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string,
  isCombo?: boolean
):
  | Promise<{
      data: Center[];
      meta: List;
      error: Error;
      isLoading: boolean;
    }>
  | any => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Center[]>([]);
  const [meta, setMeta] = useState<List>({} as List);
  const [error, setError] = useState<Error>({} as Error);
  const [combo, setCombo] = useState<any>();

  const render = () => {
    if (token) {
      if (isCombo === false || isCombo === undefined) {
        return "pagination";
      }
    }
  };

  useEffect(() => {
    if (render() === "pagination") {
      (async function () {
        setIsLoading(true);
        const response = await dataTableUtils.findPaginate(params);
        const {total, data, currentPage, totalPages, limit} = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
        setIsLoading(false)
      })();
    }
  }, [isCombo, params, token]);

  useEffect(() => {
    if (token && isCombo === true) {
      setIsLoading(true);
      api
        .get(`/${entity}/getCombo?general=true`, {
          params,
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const { data } = response;
          setCombo(data);
        })
        .catch(setError)
        .finally(() => setIsLoading(false));
    }
  }, [isCombo, params, token]);

  return {
    data,
    combo,
    meta,
    error,
    isLoading,
  };
};

export const findById = async (id: number) => {
  const token = getToken();
  return await api.get(`/${entity}/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });
};

export const update = async (id: number, center?: Center): Promise<Center> => {
  const token = getToken();
  const { data } = await api.patch(`/${entity}/${id}`, center, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const isInUse = async (id: number | string): Promise<any> => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/isInUse/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const deleteCenter = async (id: number): Promise<boolean> => {
  const token = getToken();
  const { data  } = await api.delete(`/${entity}/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const create = async (center?: Center): Promise<Center> => {
  const token = getToken();
  const { data } = await api.post(`/${entity}`, center, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};
