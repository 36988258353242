import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Autocomplete,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { IncotermsType } from "../../../../../types/incoterms";
import { IncotermShipping } from "../../../../../types/incotermsShipping";

let incotermScheme = yup.object({
  SIGLA: yup.string().max(3).required(),
  ID_FRETE_INCO: yup.mixed().required(),
  ATIVO: yup.boolean().notRequired(),
});

interface IncotermsFormProps {
  incoterm: IncotermsType;
  onSubmit: (incoterm: IncotermsType) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  readOnly?: boolean;
  incotermsShipping: IncotermShipping[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
}

export default function IncotermForm({
  incoterm,
  incotermsShipping = [],
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  controlEdit,
 readOnly
}: IncotermsFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(incotermScheme),
  });

  const [erroMap, setErroMap] = useState<string[]>([]);

  useEffect(() => {
    setErroMap(errors);
  }, [errors]);

  useEffect(() => {
    if (incotermsShipping.length !== 0) {
      reset(incoterm);
    }
  }, [incoterm, incotermsShipping, reset]);

  const handleFormSubmit = (incoterm: any) => {
    setErroMap([]);
    onSubmit(incoterm);
  };

  const handleCancel = () => {
    setErroMap([]);
    reset({ SIGLA: "", ID_FRETE_INCO: "", ATIVO: true });
    onCancel();
  };

  const getIncotermFreteIncoValue = (incoterm) => {
    if (incoterm.hasOwnProperty("ID_FRETE_INCO")) {
      return incoterm.ID_FRETE_INCO === "COLLECT" ? 742 : 743;
    }
    return undefined;
  };

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="interterms-form"
    >
      {erroMap?.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="search"
        container
        marginTop={1}
        border={1}
        padding={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >
        <Grid container marginBottom={2} spacing={2}>
          <Grid item xs={2}>
            <Controller
              control={control}
              render={({ field: { value, ref, onBlur, name, onChange, ...field } }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="Abbreviation"
                  size="small"
                  variant="outlined"
                  defaultValue={value}
                  disabled={controlEdit.isInUse || readOnly}
                  error={!!formErrors.SIGLA}
                  InputLabelProps={{ shrink: !!value }}
                  {...register("SIGLA")}
                  inputProps={{ maxLength: 3, 'data-testid': 'SIGLA-input' }}
                />
              )}
              name="SIGLA"
            />
          </Grid>
          <Grid item xs={8}>
            <Controller
              defaultValue={getIncotermFreteIncoValue(incoterm)}
              control={control}
              render={({ field: { value, ref, onBlur, name, onChange, ...field } }) => (
                <>
                  <Autocomplete
                    disabled={controlEdit.isInUse || readOnly}
                    options={incotermsShipping || []}
                    getOptionLabel={(option) => `${option?.description}`}
                    value={
                      incotermsShipping.findLast(
                        (item) => item.description === value
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={controlEdit.isInUse || readOnly}
                        {...field}
                        label="Incoterms Freight Type"
                        size="small"
                        inputRef={ref}
                        error={!!formErrors.ID_FRETE_INCO}
                        data-testid="ID_FRETE_INCO-input"
                        variant="outlined"
                      />
                    )}
                    onChange={(_, data: any) =>
                      onChange(data?.description || null)
                    }
                  />
                </>
              )}
              name="ID_FRETE_INCO"
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              defaultValue={true}
              render={({ field: { value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!value}
                      inputProps={{ "aria-label": "controlled" }}
                      {...register("ATIVO")}
                    />
                  }
                  labelPlacement="start"
                  label="Status"
                  disabled={readOnly}
                />
              )}
              name="ATIVO"
              control={control}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" spacing={2} marginTop={2}>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </Grid>
          {!readOnly ? (
          <Grid marginLeft={2}>
            <Button
              variant="contained"
              disabled={isLoading}
              type="submit"
              data-testid="submit-button"
            >
              Confirm
            </Button>
          </Grid>
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
}
