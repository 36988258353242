/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import { PartnerService } from "../../types/partner";
import { ComboBoxType } from "../../types/general";
import { IFindInvoice, Invoice, InvoiceFormModel } from "../../types/invoice";
import { useParams } from "react-router-dom";
import { InvoiceItemPo } from "../../types/invoiceItemPo";
import { Warehouse } from "../../types/warehouse";
import { SearchParams } from "../../types/dashboard";

const entity = "invoices";

export type InvoiceType = {
  id: number;
  description: string;
};

export const useList = (
  token?: string
):
  | Promise<{ data: PartnerService[]; meta: List; }>
  | any => {
  const { id } = useParams<{ id: string | undefined }>();
  const [invoiceId, setInvoiceId] = useState<string | number>();
  const [invoiceAttachmentTypes, setInvoiceAttachmentTypes] =
    useState<ComboBoxType[]>();
  const [invoiceTypes, setInvoiceTypes] = useState<ComboBoxType[]>();
  const [currencies, setCurrencies] = useState<ComboBoxType[]>();
  const [incoterms, setIncoterms] = useState<ComboBoxType[]>();
  const [incotermsComplements, setIncotermsComplements] =
    useState<ComboBoxType[]>();
  const [serviceLevel, setServiceLevel] = useState<ComboBoxType[]>();
  const [domesticCarrier, setDomesticCarrier] = useState<ComboBoxType[]>();
  const [countries, setCountries] = useState<ComboBoxType[]>();
  const [transportationMode, setTransportationMode] =
    useState<ComboBoxType[]>();
  const [freightForwarder, setFreightForwarder] = useState<ComboBoxType[]>();
  const [broker, setBroker] = useState<ComboBoxType[]>();
  const [customRegimes, setCustomRegimes] = useState<ComboBoxType[]>();

  useEffect(() => {
    if (token) {
      api
        .get("/invoiceAttachmentTypes/getCombo", {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response: { data: ComboBoxType[] }) => {
          const { data } = response;
          setInvoiceAttachmentTypes(data);
        });
      api
        .get("/invoiceTypes/getCombo", {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response: { data: ComboBoxType[] }) => {
          const { data } = response;
          if (!id) {
            const typesToRemoveWhenCreateInvoice = ["Triage - International", "Triage - Domestic"];
            const filteredInvoiceTypes = data.filter((type: ComboBoxType) => !typesToRemoveWhenCreateInvoice.includes(type.description));

            setInvoiceTypes(filteredInvoiceTypes);
            return;
          }
          setInvoiceTypes(data);
        });
      api
        .get("/currencies/getCombo", {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response: { data: ComboBoxType[] }) => {
          const { data } = response;
          setCurrencies(data);
        });
      api
        .get("/incoterms/getCombo", {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response: { data: ComboBoxType[] }) => {
          const { data } = response;
          setIncoterms(data);
        });
      api
        .get("/incotermsComplements/getCombo", {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response: { data: ComboBoxType[] }) => {
          const { data } = response;
          setIncotermsComplements(data);
        });
      api
        .get("/serviceLevels/getCombo", {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response: { data: ComboBoxType[] }) => {
          const { data } = response;
          setServiceLevel(data);
        });
      api
        .get("partners/getCombo?partnerType=CARRIER", {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response: { data: ComboBoxType[] }) => {
          const { data } = response;
          setDomesticCarrier(data);
        });
      api
        .get("/countries/getCombo", {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response: { data: ComboBoxType[] }) => {
          const { data } = response;
          setCountries(data);
        });
      api
        .get("/transportationModes/getCombo", {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response: { data: ComboBoxType[] }) => {
          const { data } = response;
          setTransportationMode(data);
        });
      api
        .get("/partners/getCombo?partnerType=FREIGHT FORWARDER", {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response: { data: ComboBoxType[] }) => {
          const { data } = response;
          setFreightForwarder(data);
        });
      api
        .get("/partners/getCombo?partnerType=BROKER", {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response: { data: ComboBoxType[] }) => {
          const { data } = response;
          setBroker(data);
        });
      api
        .get("/customsRegimes/getCombo", {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response: { data: ComboBoxType[] }) => {
          const { data } = response;
          setCustomRegimes(data);
        });
    }
  }, [token]);

  return {
    invoiceId,
    setInvoiceId,
    invoiceAttachmentTypes,
    invoiceTypes,
    currencies,
    incoterms,
    incotermsComplements,
    serviceLevel,
    domesticCarrier,
    countries,
    transportationMode,
    freightForwarder,
    broker,
    customRegimes,
  };
};

export const update = async (
  invoice: InvoiceFormModel,
  save: boolean,
  isTracking?: boolean | null,
): Promise<InvoiceFormModel> => {
  const token = await getToken();
  const { data } = await api.put(`/${entity}/update`, invoice, {
    params: {
      save,
      isTracking
    },
    headers: { 
      authorization: `Bearer ${token}`, 
      "Content-Type": "multipart/form-data", 
    },
  });

  return data;
}

export const unlinkInvoiceWarehouse = async (
  id?: number,
): Promise<{invoice: PartnerService, success: boolean}> => {
  const token = await getToken();
  const { data } = await api.patch(`/${entity}/unlinkInvoiceWarehouse/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return { invoice: data.object, success: data.success };
};

export const create = async (
  invoice: FormData,
  save: boolean
): Promise<InvoiceFormModel> => {
  const token = getToken();
  const { data } = await api.post(`/${entity}/create`, invoice, {
    params: {
      save
    },
    headers: { 
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

export const searchInvoice = async (findParams?: {
  NUMERO_PO?: string;
  NUM_INVOICE: string;
  DATA_INVOICE: string;
}): Promise<IFindInvoice> => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/find`, {
    headers: { authorization: `Bearer ${token}` },
    params: findParams,
  });
  return data;
};

export const findInvoicePoItems = async (invoiceId: number) => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/${invoiceId}/po-items`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
}

export const createInvoicePoItem = async (invoiceId: number, invoicePoItem: Partial<InvoiceItemPo>, params: { confirm: boolean }) => {
  const token = getToken();
  const { data } = await api.post(`/${entity}/${invoiceId}/po-items`, invoicePoItem, {
    headers: { authorization: `Bearer ${token}` },
    params
  });

  return data;
}

export const updateInvoicePoItem = async (invoiceId:number, invoicePoItemId: number, invoicePoItem: Partial<InvoiceItemPo>, params: { confirm: boolean }) => {
  const token = getToken();
  const { data } = await api.put(`/${entity}/${invoiceId}/po-items/${invoicePoItemId}`, invoicePoItem, {
    headers: { authorization: `Bearer ${token}` },
    params
  });

  return data;
}

export const removeInvoicePoItem = async (invoiceId: number, invoicePoItemId: number) => {
  const token = getToken();
  const { data } = await api.delete(`/${entity}/${invoiceId}/po-items/${invoicePoItemId}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
}

export const getInvoice = async (id: number): Promise<InvoiceFormModel> => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
}

export const findBoardingAuthorizationById = async (id: number): Promise<any> => {
  const token = await getToken();
  const { data } = await api.get(`/${entity}/${id}/boardingAuthorization`,{
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const validSearchParams = (filters?: SearchParams) => {
  const fields = ['page', 'limit'];
  return Object.fromEntries(
    Object.entries(filters || {}).filter(([key, value]) => {
      return typeof value === 'string' || Array.isArray(value) || fields.includes(key)
    })
  );
}

export const getInvoices = async (filters?: SearchParams): Promise<Invoice[]> => {
  const token = getToken();

  const { data } = await api.get(`/${entity}/findList`, {
    headers: { authorization: `Bearer ${token}` },
    params: filters,
  });

  return data;
};

export const getInvoiceTypes = async (): Promise<InvoiceType[]> => {
  const token = getToken();

  const { data } = await api.get(`/invoiceTypes/getCombo`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const updateHAWB = async (warehouse: Warehouse & {ID_INVOICE?: number, ORIGINAL_DSC_HOUSE: string}): Promise<boolean> => {
  const token = getToken();

  const { data } = await api.put(`/${entity}/hawb`, {
    headers: { authorization: `Bearer ${token}` },
    body: warehouse
  });

  return data;
};
