import { api } from "../../lib/api";
import { getToken } from "../../storage/auth";
import { SearchParams, TriageStatus } from "../../types/triage";
import { Triage } from "../../types/triage";

const entity = "triages";
const pieceEntity = "triageVolume";
const noteEntity = "triageNote";
const triageInvoiceEntity = "triageInvoice";
const triageInvoiceFileEntity = "triageInvoiceFile";
const fileEntity = "triageFile";

interface IList {
  format?: string,
  ID_CENTRO?: number;
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
  status?: boolean;
}

export const getTriagesCount = async (ID_CENTRO: number): Promise<{status: TriageStatus, total: number}[]> => {
  const token = getToken();

  const { data } = await api.get(`/${entity}/findCount`, {
    params:{ 
      ID_CENTRO
    },
    headers: { authorization: `Bearer ${token}` },
  });

  return data.object as {status: TriageStatus, total: number}[];
};

export const validSearchParams = (filters?: SearchParams) => Object.fromEntries(
  Object.entries(filters || {}).filter(([key, value]) => {
    if (typeof value === 'string' || Array.isArray(value)) {
        return true;
    }
    return false;
  })
);

function getApiData(url: string, params?: IList) {
  const token = getToken();

  return api.get(url, {
    params,
    headers: {authorization: `Bearer ${token}`},
  }).then((response) => response.data);
}

function postApiData(url: string, body: any, params?: IList) {
  const token = getToken();

  return api.post(url, body, {
    headers: {authorization: `Bearer ${token}`},
  }).then((response) => response.data);
}

function putApiData(url: string, body: any, params?: IList) {
  const token = getToken();

  return api.put(url, body, {
    headers: {authorization: `Bearer ${token}`},
  }).then((response) => response.data);
}

function deleteApiData(url: string, body?: any) {
  const token = getToken();

  return api.delete(url, {
    data: body,
    headers: {authorization: `Bearer ${token}`},
  }).then((response) => response.data);
}

export const getTriages = async (filters?: SearchParams | IList): Promise<Triage[]> => {
  const token = getToken();

  let url = `${entity}/find?`;

  const { data } = await api.get(url, {
    params: filters,
    headers: { authorization: `Bearer ${token}` },
  });

  return data.object as Triage[];
};

export const getTriage = async (id: number) => {
  const response = await getApiData(`/${entity}/${id}`);

  return response.object;
}

export const getPieces = async (triageId: number) => {
  const response = await getApiData(`/${pieceEntity}/${triageId}`);

  return response.object;
}

export const getNotes = async (triageId: number) => {
  const response = await getApiData(`/${noteEntity}/${triageId}`);

  return response.object;
}

export const getInvoices = async (triageId: number) => {
  const response = await getApiData(`/${triageInvoiceEntity}/${triageId}`);

  return response.object;
}

export const getInvoiceFiles = async (triageInvoiceId: number) => {
  const response = await getApiData(`/${triageInvoiceFileEntity}/${triageInvoiceId}`);

  return response.object;
}

export const getInvoiceFile = async (triageInvoiceFileId: number, params: IList): Promise<Buffer> => {
  const token = getToken();

  const response = await api.get(`/${triageInvoiceFileEntity}/download/${triageInvoiceFileId}`, {
    params,
    headers: {
      authorization: `Bearer ${token}`,
    },
    responseType: "arraybuffer",
  });

  return response.data;
}

export const uploadInvoiceFile = async (file: any, triageInvoiceId: number) => {
  const formData = new FormData();
  formData.append("files", file);

  const token = getToken();

  const response = api.post(`/${triageInvoiceFileEntity}/${triageInvoiceId}`, formData, {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  }).then((response) => response.data);

  return response;
}

export const removeInvoiceFile = async (idFile: number) => {
  await deleteApiData(`/${triageInvoiceFileEntity}/${idFile}`);
}

export const sendEmailInvoice = async (triageInvoiceId: number) => {
  const token = getToken();

  const response = await api.get(`/${triageInvoiceFileEntity}/${triageInvoiceId}/sendMail`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}

export const validateInvoices = async (triageInvoiceId: number) => {
  const token = getToken();

  const response = await api.put(`/${triageInvoiceEntity}/${triageInvoiceId}/validate`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}

export const getFiles = async (triageId: number) => {
  const response = await getApiData(`/${fileEntity}/${triageId}`);

  return response.object;
}

export const getFile = async (triageFileId: number, params: IList): Promise<Buffer> => {
  const token = getToken();

  const response = await api.get(`/${fileEntity}/download/${triageFileId}`, {
    params,
    headers: {
      authorization: `Bearer ${token}`,
    },
    responseType: "arraybuffer",
  });

  return response.data;
}

export const addNote = async (body: any) => {
  await postApiData(`/${noteEntity}`, body);
}

export const addInvoice = async (body: any) => {
  return await postApiData(`/${triageInvoiceEntity}`, body);
}

export const deleteInvoice = async (idInvoiceTriagem: number) => {
  await deleteApiData(`/${triageInvoiceEntity}/${idInvoiceTriagem}`);
}

export const getBINSCombo = async (params: IList) => {
  const response = await getApiData(`/locationBins/getCombo`, params);

  return response;
}

export const updatePiece = async (body: any) => {
  await putApiData(`/${pieceEntity}`, body);
}

export const uploadFile = async (file: any, triageId: number) => {
  const formData = new FormData();
  formData.append("files", file);

  const token = getToken();

  const response = api.post(`/${fileEntity}/${triageId}`, formData, {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  }).then((response) => response.data);

  return response;
}

export const removeFile = async (idFile: number) => {
  await deleteApiData(`/${fileEntity}/${idFile}`);
}

export const updateTriage = async (params: any) => {
  const body = {
    ...params,
  };

  await putApiData(`/${entity}`, body);
}
