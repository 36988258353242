import { getLocal } from "./auth";

const userKey = 'userInfo'

export const hasClaim = (userClaim: string) => {
  const userClaims = getUserClaim();
  return userClaims?.includes(userClaim)
}

export const getUserClaim = (): string[] => {
  const userLocal = getLocal(userKey);

  if (!userLocal)  return [];

  try {
    const parsed = JSON.parse(userLocal);
    return parsed?.claims || [];
  } catch (error) {
    return [];
  }
}