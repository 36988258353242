import { InvoiceType } from "./invoice";
import { Warehouse } from "./warehouse";

export type Triage = {
    ID_TRIAGEM: number,
    ID_WAREHOUSE: number,
    DSC_WAREHOUSE: string,
    DSC_CARRIER: string,
    QTDE_VOL_TOTAL: number,
    DATA_RECEB: Date,
    ID_CENTRO: number,
    ID_TIPO_INVOICE: InvoiceType,
    ID_STATUS_TRIAGEM: TriageStatus,
    CODIGO_GRUPO_COMPRADOR: string,
    ID_GRUPO_COMPRADOR: number,
    NUM_PO: number,
    warehouse: Warehouse,
    DATA_WAITING_ATTACH: Date;
    DATA_UNDER_ANALYSIS: Date;
    DATA_MISSING: Date;
    DATA_PENDING: Date;
    DATA_LOGISTICS: Date;
    DATA_RECEIVING: Date;
    DATA_ESTN_UNDER_ANALYSIS: Date;
    DATA_ESTN_UNDER_MISSING: Date;
    DATA_ESTN_UNDER_PENDING: Date;
    DATA_ESTN_UNDER_LOGISTICS: Date;
    DATA_ESTN_UNDER_RECEIVING: Date;
}

export enum TriageStatus {
    WAITING_ATTACHMENTS = "Waiting Attachments",
    UNDER_ANALYSIS = "Under Analysis",
    MISSING_INVOICE = "Missing Invoice",
    PENDING = "Pending",
    WAITING_TO_MOVE_TO_RECEIVING = "Waiting to Move to Receiving",
    CANCELED = "Canceled",
    FINALIZED = "Finalized",
    UNDER_LOGISTICS_ANALYSIS = "Under Logistics Analysis",
}

export type TriageCards = {
    status: TriageStatus,
    total: number,
}

export const openStatuses = [
    TriageStatus.WAITING_ATTACHMENTS,
    TriageStatus.UNDER_ANALYSIS,
    TriageStatus.MISSING_INVOICE,
    TriageStatus.UNDER_LOGISTICS_ANALYSIS,
    TriageStatus.PENDING,
    TriageStatus.WAITING_TO_MOVE_TO_RECEIVING,
];

export const triage2Date = {
    [TriageStatus.WAITING_ATTACHMENTS]: 'DATA_WAITING_ATTACH',
    [TriageStatus.UNDER_ANALYSIS]: 'DATA_UNDER_ANALYSIS',
    [TriageStatus.MISSING_INVOICE]: 'DATA_MISSING',
    [TriageStatus.UNDER_LOGISTICS_ANALYSIS]: 'DATA_LOGISTICS',
    [TriageStatus.PENDING]: 'DATA_PENDING',
    [TriageStatus.WAITING_TO_MOVE_TO_RECEIVING]: 'DATA_RECEIVING',
};

export type SearchParams = {
    ID_CENTRO: string,
    ID_STATUS_TRIAGE?: string,
    ID_TIPO_INVOICE?: string,
    NUM_WAREHOUSE?: string,
    SEARCH?: string,
    pageSize?: string;
    page?: string;
}