import React, {useState} from 'react';
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    LabelList,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import {useCurrentPng} from 'recharts-to-png';

const StackedBarWithLinesChart = ({
    data,
    TriageGraphConfig,
    MediaAgingKey,
    HighestAgingKey,
    dataLegendKey,
    onImageGenerated,
}) => {

    const [getPng, { ref: chartRef }] = useCurrentPng();
    const [isLoading, setIsLoading] = useState(true);

    React.useEffect(() => {
        if (data && data.length > 0) {
            setTimeout(() => {
                handleGenerateImage();
                setIsLoading(false);
            }, 2500);
        }
    }, [data]);

    const calculateTotal = (entry) => {
        return TriageGraphConfig.reduce((total, config) => {
            const value = entry[config.dataKey] || 0;
            return total + value;
        }, 0);
    };

    const renderTotalLabel = (props) => {
        const { x, y, width, index } = props;
        const total = calculateTotal(data[index]);
        return (
            <text
                x={x + width / 2}
                y={y - 5} // Acima da barra
                fill="#000"
                textAnchor="middle"
                fontWeight="bold"
                style={{ fontFamily: 'Roboto, sans-serif', fontSize: '12px' }}
            >
                {total}
            </text>
        );
    };

    const calculateMaxValue = () => {
        let maxBarValue = 0;
        let maxLineValue = 0;

        data.forEach((entry) => {
            const total = calculateTotal(entry);
            if (total > maxBarValue) {
                maxBarValue = total;
            }
        });

        data.forEach((entry) => {
            const highestAging = entry[HighestAgingKey] || 0;
            const mediaAging = entry[MediaAgingKey] || 0;
            maxLineValue = Math.max(maxLineValue, highestAging, mediaAging);
        });

        return Math.ceil(Math.max(maxBarValue, maxLineValue) * 1.08);
    };


    const maxYAxisValue = calculateMaxValue();

    const renderLineLabelBelow = (props) => {
        const { x, y, value, stroke } = props;
        return (
            <text
                x={x}
                y={y + 15} // Abaixo do ponto
                fill={stroke}
                textAnchor="middle"
                fontWeight="bold"
                style={{ fontFamily: 'Roboto, sans-serif', fontSize: '12px' }}
            >
                {value}
            </text>
        );
    };

    async function handleGenerateImage (){
        try {
            const dataUrl = await getPng();
            if (dataUrl && onImageGenerated) {
                onImageGenerated(dataUrl);
            }
        } catch (err) {
            console.error('Erro ao gerar a imagem:', err);
        }
    };


    return (
        <div>
            <div ref={chartRef}>
                <ResponsiveContainer width="100%" height={350}>
                    <ComposedChart data={data}  ref={chartRef}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey={dataLegendKey}
                            style={{ fontFamily: 'Roboto, sans-serif', fontSize: '12px' }}
                        />
                        <YAxis
                            yAxisId="left"
                            domain={[0, maxYAxisValue]}
                            style={{ fontFamily: 'Roboto, sans-serif', fontSize: '12px' }}
                        />
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            domain={[0, maxYAxisValue]}
                            style={{ fontFamily: 'Roboto, sans-serif', fontSize: '12px' }}
                        />
                        <Tooltip
                            contentStyle={{
                                fontFamily: 'Roboto, sans-serif',
                                fontSize: '12px',
                            }}
                        />
                        <Legend
                            wrapperStyle={{
                                fontFamily: 'Roboto, sans-serif',
                                fontSize: '12px',
                            }}
                        />
                        {/* Barras empilhadas */}
                        {TriageGraphConfig.map((config, index) => (
                            <Bar
                                key={index}
                                yAxisId="left"
                                dataKey={config.dataKey}
                                stackId="a"
                                fill={config.fill}
                                name={config.name}
                            >
                                {config.hasLabelList && <LabelList content={renderTotalLabel} />}
                            </Bar>
                        ))}
                        {/* Linhas representando AvgAging e HighestAging */}
                        <Line
                            yAxisId="right"
                            type="monotone"
                            dataKey={HighestAgingKey}
                            stroke="#88c72d"
                            strokeWidth={2}
                            dot={{ r: 4 }}
                            name="Highest Aging"
                        >
                            {/* Adicionar labels abaixo dos pontos da linha */}
                            <LabelList content={renderLineLabelBelow} />
                        </Line>
                        <Line
                            yAxisId="right"
                            type="monotone"
                            dataKey={MediaAgingKey}
                            stroke="#f09639"
                            strokeWidth={2}
                            dot={{ r: 4 }}
                            name="Avg Aging"
                        >
                            {/* Adicionar labels abaixo dos pontos da linha */}
                            <LabelList content={renderLineLabelBelow} />
                        </Line>
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default StackedBarWithLinesChart;
