import { useNavigate } from 'react-router-dom';
import { MouseEvent, useState, useMemo, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, Grid, Menu, MenuItem, TextField, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton'
import { Inventory, Folder, FilePresentOutlined } from '@mui/icons-material'
import { GridColDef, GridExpandMoreIcon } from '@mui/x-data-grid'
import { Invoice, InvoiceStatus, InvoiceStatusDate, InvoiceType } from '../../../types/invoice';
import DataTableDefault from '../../../components/DataTable';
import { Company } from '../../../types/company';
import { Center } from '../../../types/center';
import { SearchParams } from '../../../types/dashboard';
import TrackingDialog from './TrackingInvoiceDialog';
import HouseDialog from './HouseInvoiceDialog';
import { ComboBoxType } from '../../../types/general';

type DashboardInvoicesTableProps = {
  data: Invoice[]
  companies: Company[]
  centers: Center[]
  invoiceTypes: ComboBoxType[]
  searchParams: SearchParams
  onChangeSearchParam: (key: string) => ((e: any) => void);
  fetchData: () => void
  onUpdate: () => void
  reset: () => void
  onPaginationModelChange: (paginationModel: any) => void
  pagination: boolean
  initialState: {
    pagination: { paginationModel: { pageSize: number } },
  }
  paginationModel: any
  pageSizeOptions: number[]
  paginationMode: string
  rowCount: number
}

const searchFieldOptions: ComboBoxType[] = [
  {
    id: 'NUM_INVOICE',
    description: 'Invoice',
  },
  {
    // PO
    id: 'NUMERO_PO',
    description: 'PO',
  },
  {
    // PROCESSO_EMBARQUE
    id: 'CODIGO_HOUSE_HAWB',
    description: 'HAWB',
  },
  {
    // PROCESSO_EMBARQUE
    id: 'CODIGO_MAWB',
    description: 'MAWB',
  },
  {
    id: 'NUM_WAREHOUSE',
    description: 'WR',
  },
  {
    id: 'NUM_TRACKING',
    description: 'Domestic Tracking Number',
  },
  {
    // PROCESSO EMBARQUE
    id: 'CODIGO_PROCESSO',
    description: 'Process Code',
  },
  {
    // PARCEIRO
    id: 'NUM_MFIR',
    description: 'MFIR Supplier',
  },
  {
    // AUTORIZACAO_EMBARQUE
    id: 'NUM_REVISAO',
    description: 'Authorization Number',
  },
]

export default function DashboardInvoicesTable({
  data,
  companies,
  centers,
  invoiceTypes,
  searchParams,
  onChangeSearchParam,
  onUpdate,
  fetchData,
  reset,
  onPaginationModelChange,
  pagination,
  initialState,
  paginationModel,
  pageSizeOptions,
  paginationMode,
  rowCount
}: Readonly<DashboardInvoicesTableProps>) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [editTracking, setEditTracking] = useState<boolean>(false)
  const [editHouse, setEditHouse] = useState<boolean>(false)
  const [invoiceSelected, setInvoiceSelected] = useState<Invoice>()
  const [menuRowId, setMenuRowId] = useState<number | null>(null)
  const [menuPosition, setMenuPosition] = useState<{ top: number, left: number } | null>(null);
  const [inputValue, setInputValue] = useState("");
  const searchInputRef = useRef<HTMLInputElement | null>(null);


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  }

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      onChangeSearchParam('SEARCH_TEXT')({ target: { value: inputValue } });
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [inputValue, 500]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>, rowId: number) => {
    const buttonRect = event.currentTarget.getBoundingClientRect();
    setAnchorEl(event.currentTarget);
    setMenuPosition({ top: buttonRect.bottom, left: buttonRect.left });
    setMenuRowId(rowId);
  }

  const handleClose = () => {
    setAnchorEl(null);
    setMenuPosition(null);
    setMenuRowId(null);
  }

  const handleInvoiceDetails = (event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>, invoice: Invoice) => {
    const path = `/invoice/details/${invoice.ID_INVOICE}`;
    return (event.ctrlKey || event.metaKey) ? window.open(window.location.origin + path, '_blank') : navigate(path);
  }

  const handleInvoiceLinks = (event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>, invoice: Invoice, name: 'process' | 'authorization') => {
    const pathnames = {
      process: `/boarding-process/${invoice.ID_PROCESSO_EMBARQUE}`,
      authorization: `/shipmentAuthorization/${invoice.ID_AUTORIZACAO_EMBARQUE}` 
    }
    const path = pathnames[name];
    return (event.ctrlKey || event.metaKey) ? window.open(window.location.origin + path, '_blank') : navigate(path);
  }

  const handleEditTracking = (invoice: Invoice) => {
    setInvoiceSelected(invoice)
    setEditTracking(true)
  }

  const handleEditHouse = (invoice: Invoice) => {
    setInvoiceSelected(invoice)
    setEditHouse(true)
  }

  const columns: GridColDef[] = [
    {
      field: 'DSC_SIGLA',
      headerName: 'CENTER (PLANT)',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return [row.centro?.DSC_SIGLA]
      }
    },
    {
      field: 'NUM_INVOICE',
      headerName: 'INVOICE',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      minWidth: 115
    },
    {
      field: 'ID_PO',
      headerName: 'PO',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return [row.po?.NUMERO_PO]
      }
    },
    {
      field: 'ID_WAREHOUSE',
      headerName: 'WR',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return [row?.warehouse?.NUM_WAREHOUSE]
      },
    },
    {
      field: 'NUM_TRACKING',
      headerName: 'TRACKING NUMBER',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
    },
    {
      field: 'CODIGO_PROCESSO',
      headerName: 'PROCESS',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return [row.processoEmbarque?.CODIGO_PROCESSO]
      }
    },
    {
      field: 'CODIGO_HOUSE_HAWB',
      headerName: 'HOUSE',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return [row.processoEmbarque?.CODIGO_HOUSE_HAWB]
      }
    },
    {
      field: 'ID_AUTORIZACAO_EMBARQUE',
      headerName: 'AUTHORIZATION',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
    },
    {
      field: 'ID_PRIORIDADE',
      headerName: 'SERVICE LEVEL',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return [row.serviceLevel?.DSC_PRIORIDADE]
      }
    },
    {
      field: 'ID_MODAL',
      headerName: 'MODAL',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
    },
    {
      field: 'Freight Forwarder',
      headerName: 'FREIGHT FORWARDER',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return [row.parceiroAgenteCarga?.NOME_PARCEIRO]
      }
    },
    {
      field: 'Incoterms',
      headerName: 'INCOTERMS',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return [row.incoterms?.SIGLA]
      }
    },
    {
      field: 'Incoterms Complement',
      headerName: 'INCOTERMS COMPLEMENT',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return [row.incotermsComplement?.DSC_INCOTERMS_COMPL]
      }
    },
    {
      field: 'Aging',
      headerName: 'AGING',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        if (!row?.ID_STATUS_INVOICE) {
          return [];
        }
        const dateField = InvoiceStatusDate[row.ID_STATUS_INVOICE];
        const date = row[dateField];
        const days = dayjs().diff(date, 'days');
        return [days || 0];
      }
    },
    {
      field: 'ID_STATUS_INVOICE',
      headerName: 'STATUS',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell-break-word',
      flex: 1,
      minWidth: 130,
      renderCell: ({ row }) => {
        let classes = '';
        if (typeof searchParams.IN_SLA === 'boolean') {
          classes += 'badge';
          if (searchParams.IN_SLA) {
            classes += ' in-sla';
          } else {
            classes += ' out-of-sla';
          }
        }

        return [
          <div key={row.ID_STATUS_INVOICE} className={classes}>
            {row.ID_STATUS_INVOICE}
          </div>
        ]
      }
    },
    {
      field: 'DSC_Actions',
      type: 'actions',
      headerName: 'ACTION',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          <Button
            data-testid="menu-view-button"
            onClick={(event) => handleClick(event, row.ID_INVOICE)}
            style={{ border: '1px solid', borderRadius: '5px', paddingTop: 0, paddingBottom: 0 }}
          >
            <span style={{ fontSize: '11px' }}>VIEW</span> <GridExpandMoreIcon style={{ fontSize: '11px' }} />
          </Button>
          <Menu
            id="simple-menu-1"
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && menuRowId === row.ID_INVOICE}
            onClose={handleClose}
            anchorReference="anchorPosition"
            data-testid="simple-menu-1"
            anchorPosition={menuPosition ? { top: menuPosition.top, left: menuPosition.left } : undefined}
          >
            <MenuItem data-testid="menu-item-invoice" onClick={(event) => handleInvoiceDetails(event, row)}>Invoice</MenuItem>
            {!!row.ID_PROCESSO_EMBARQUE && <MenuItem data-testid="menu-item-process" onClick={(e) => handleInvoiceLinks(e, row, 'process')}>Process</MenuItem>}
            {!!row.ID_AUTORIZACAO_EMBARQUE && <MenuItem data-testid="menu-item-authorization" onClick={(e) => handleInvoiceLinks(e, row, 'authorization')}>Shipment Authorization</MenuItem>}
            {row?.ID_STATUS_INVOICE === InvoiceStatus.DOMESTIC_TRANSIT
              &&[InvoiceType.INBOUND_DOMESTIC, InvoiceType.TRIAGE_DOMESTIC].includes(row?.ID_TIPO_INVOICE)
              && (
                <MenuItem onClick={() => handleEditTracking(row)}>Tracking</MenuItem>
              )
            }
            {row?.ID_STATUS_INVOICE === InvoiceStatus.CUSTOMS_CLEARANCE
              && row?.ID_TIPO_INVOICE === InvoiceType.TRIAGE_INTERNATIONAL
              && row?.ID_WAREHOUSE
              && (
                <MenuItem onClick={() => handleEditHouse(row)}>House</MenuItem>
              )
            }
          </Menu>
        </>
      ),
    },
  ];

  const companiesOptions = useMemo(() => {
    return (companies || []).map(company => ({ id: company.ID_EMPRESA!, description: company.SIGLA_EMPRESA }));
  }, [companies]);

  const centersOptions = useMemo(() => {
    return (centers || []).filter((center) => !center.IND_PLANTA_BLOQUEADA).map(center => ({ id: center.ID_CENTRO!, description: `${center.DSC_SIGLA} - ${center.NOME_CENTRO}` }));
  }, [centers]);

  return (
    <>
      <Grid container spacing={2} marginBottom={2}>
        {invoiceSelected && editTracking &&
          <TrackingDialog invoice={invoiceSelected} open={editTracking} onClose={() => setEditTracking(false)} onUpdate={onUpdate}/>
        }
        {invoiceSelected?.ID_WAREHOUSE && editHouse &&
          <HouseDialog invoice={invoiceSelected} open={editHouse} onClose={() => setEditHouse(false)} onUpdate={onUpdate}/>
        }

        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={() => {
              reset();
              if (searchInputRef.current) searchInputRef.current.value = '';
            }}
            data-testid="search-button"
          >
            Reset Filter
          </Button>
        </Grid>

        <Grid item xs={12} sm={2.5}>
          <FormControl style={{ width: '100%' }} disabled={!!searchParams.WAITING_PO} size="small">
            <Autocomplete
              multiple
              disabled={!!searchParams.WAITING_PO}
              options={companiesOptions}
              getOptionLabel={(option: ComboBoxType) =>
                `${option.description}`
              }
              size='small'
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Company"
                  size="small"
                  variant="outlined"
                />
              )}
              value={
                companiesOptions
                  .filter(item => searchParams.ID_EMPRESA?.some(value => value === item.id))
              }
              onChange={(_, data: ComboBoxType[]) => {
                onChangeSearchParam('ID_EMPRESA')({ target: { value: data.map(item => item.id) } });
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl style={{ width: '100%' }} disabled={!!searchParams.WAITING_PO || (searchParams.ID_EMPRESA?.length ?? 0) > 1} size="small">
            <Autocomplete
              multiple
              disabled={!!searchParams.WAITING_PO || (searchParams.ID_EMPRESA?.length ?? 0) > 1}
              options={centersOptions}
              getOptionLabel={(option: ComboBoxType) =>
                `${option.description}`
              }
              size='small'
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Plant"
                  size="small"
                  variant="outlined"
                />
              )}
              value={
                centersOptions
                  .filter(item => searchParams.ID_CENTRO?.some(value => value === item.id))
              }
              onChange={(_, data: ComboBoxType[]) => {
                onChangeSearchParam('ID_CENTRO')({ target: { value: data.map(item => item.id) } });
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl style={{ width: '100%' }} size="small">
            <Autocomplete
              options={invoiceTypes}
              getOptionLabel={(option) => option.description}
              value={
                invoiceTypes?.findLast((item: ComboBoxType) => item?.description === searchParams.ID_TIPO_INVOICE) ||
                null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Invoice Type"
                  size="small"
                  variant="outlined"
                />
              )}
              onChange={(_: any, data: any) => {
                onChangeSearchParam('ID_TIPO_INVOICE')({ target: { value: data?.description } })
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={1.5}>
          <FormControlLabel
            control={
              <Checkbox checked={!!searchParams.INTERCOMPANY}
                onChange={onChangeSearchParam('INTERCOMPANY')}
              />
            }
            label="Intercompany"
          />
        </Grid>
        <Grid item xs={12} sm={1.5} md>
          <FormControlLabel
            control={
              <Checkbox checked={!!searchParams.WAITING_PO}
                onChange={onChangeSearchParam('WAITING_PO')}
              />
            }
            label="Waiting PO"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={5} gap={1} display='flex' justifyContent="space-between" alignItems="center">
          <Grid item xs={6} display='flex' alignItems="center">
            <FormControl style={{ width: '100%' }} size="small">
              <Autocomplete
                options={searchFieldOptions}
                getOptionLabel={(option) => option.description}
                value={
                  searchFieldOptions?.findLast((item: ComboBoxType) => item?.id === searchParams.SEARCH_FIELD) ||
                  null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Field"
                    size="small"
                    variant="outlined"
                  />
                )}
                onChange={(_: any, data: any) => {
                  onChangeSearchParam('SEARCH_FIELD')({ target: { value: data?.id } })
                }}
              />
            </FormControl>
          </Grid>
          <Grid xs={6} display='flex' alignItems="center">
            <TextField
              size="small"
              label="Search"
              variant="outlined"
              sx={{ width: 200 }}
              defaultValue={searchParams.SEARCH_TEXT}
              onChange={(event) => handleInputChange(event)}
              inputRef={searchInputRef}
            />
          </Grid>
        </Grid>

        <Grid item xs={5} display='flex' gap={2}>
        </Grid>

        <Grid item xs={2} gap={2} display='flex' justifyContent="flex-end">
          <Grid item display='flex' alignItems="center">
            <Tooltip title='Process' placement='top'>
              <IconButton
                href="/boarding-process"
                style={{ borderRadius: '4px', border: '1px solid #1976d2' }}
                aria-label='New Process'
                size='small'
              >
                <Inventory style={{ color: '#1976d2' }} />
              </IconButton>
            </Tooltip> 
          </Grid>

          <Grid item display='flex' alignItems="center">
            <Tooltip title='Shipment Authorization' placement='top'>
              <IconButton
                href="/shipmentAuthorization"
                style={{ borderRadius: '4px', border: '1px solid #1976d2' }}
                aria-label='Shipment Authorization'
                size='small'
              >
                <Folder style={{ color: '#1976d2' }} />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item display='flex' alignItems="center">
            <Tooltip title='New Invoice' placement='top'>
              <IconButton
                href="/invoice"
                style={{ borderRadius: '4px', border: '1px solid #1976d2' }}
                aria-label='New Invoice'
                size='small'
              >
                <FilePresentOutlined style={{ color: '#1976d2' }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} sm={12} display='flex' justifyContent="space-between">
          <Grid item xs={8} sm={8} display='flex' justifyContent="flex-start">
            <Grid item>
              <TextField
                size="small"
                label="Search"
                variant="outlined"
                sx={{ width: 200 }}
                defaultValue={searchParams.SEARCH_TEXT}
                onChange={(event) => handleInputChange(event)}
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                onClick={fetchData}
                sx={{ width: 200 }}
                data-testid="search-button"
              >
                Search
              </Button>

              <Button
                variant="outlined"
                onClick={reset}
                sx={{ marginLeft: 3 }}
                data-testid="search-button"
              >
                Reset Filter
              </Button>
            </Grid>
          </Grid>
        
          <Grid item xs={4} sm={4} display='flex' justifyContent="flex-end">
            <Grid item>
              <IconButton
                href="/shipmentAuthorization"
                style={{ borderRadius: '4px', border: '1px solid #1976d2' }}
                aria-label='Shipment Authorization'
                size='small'
              >
                <Inventory style={{ color: '#1976d2' }} />
              </IconButton>
            </Grid>

            <Grid item marginRight={3}>
              <IconButton
              href="/boarding-process"
                style={{ borderRadius: '4px', border: '1px solid #1976d2' }}
                aria-label='New Process'
                size='small'
              >
                <Folder style={{ color: '#1976d2' }} />
              </IconButton>
            </Grid>

            <Grid item marginRight={3}>
              <IconButton
                href="/invoice"
                style={{ borderRadius: '4px', border: '1px solid #1976d2' }}
                aria-label='New Invoice'
                size='small'
              >
                <FilePresentOutlined style={{ color: '#1976d2' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
      <Grid container spacing={0} marginBottom={5}>
        <Grid item xs={12}>
          <DataTableDefault
            data-testid='invoice-data-table'
            data={data}
            columns={columns}
            sx={{
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal"
              },
            }}
            onPaginationModelChange={onPaginationModelChange}
            pagination={pagination}
            initialState={initialState}
            paginationModel={paginationModel}
            pageSizeOptions={pageSizeOptions}
            paginationMode={paginationMode}
            rowCount={rowCount}
          />
        </Grid>
      </Grid>
    </>
  )
}
