import { useEffect, useState } from "react";
import { getToken } from "../../storage/auth";
import { WrQuarantine, WrQuarantineData } from "../../types/wrQuarantine";
import { api } from "../../lib/api";

const entity = "wrQuarantine";

export const useList = (
  idWarehouse?: number | null,
): {
  wrQuarantines: WrQuarantineData[] | undefined,
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>,
} => {
  const token = getToken();
  const [wrQuarantines, setWrQuarantines] = useState<WrQuarantineData[]>([]);
  const [refetch, setRefetch] = useState<boolean>(false);

  useEffect(() => {
    (() => {
      let url: string = "?";

      if (idWarehouse) {
        url += `idWarehouse=${idWarehouse}`
      }

      if (idWarehouse) {
        api
          .get(`/${entity}${url}`, {
            headers: { authorization: `Bearer ${token}` },
          })
          .then((response: any) => {
            const { data } = response;
            setWrQuarantines(data);
            setRefetch(false);
          })
      }
    })()
  }, [idWarehouse, refetch]);

  return { wrQuarantines, setRefetch };
};

export const create = async (
  ID_INVOICE: number,
  wrQuarantine: WrQuarantine,
): Promise<{ success: boolean, message: string[] }> => {
  const token = getToken();

  const { data } = await api.post(`/${entity}?ID_INVOICE=${ID_INVOICE}`, wrQuarantine, {
    headers: { authorization: `Bearer ${token}` },
  });

  return { success: data.success, message: data.message };
}

export const closeWrQuarantine = async (
  ID_INVOICE: number,
  idWrQuarantine: number,
  idLoginUsuarioFechamento: number,
  idWarehouse?: number
): Promise<{ success: boolean, messages: string[] }> => {
  const token = getToken();

  const { data } = await api.post(`/${entity}/closeWrQuarantine/${idWrQuarantine}?ID_INVOICE=${ID_INVOICE}`, { idLoginUsuarioFechamento, idWarehouse }, {
    headers: { authorization: `Bearer ${token}` },
  });

  return { success: data.success, messages: data.messages };
}