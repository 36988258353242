import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { BoardingProcessAttachmentType, Process } from "../../types/process";

interface IList {
    keyword?: string;
    page?: number;
    limit?: number;
    sort?: string;
}

const entity = "boardingProcessAttachmentType";

export const useList = (
    params?: IList,
    token?: string,
):
    | { boardingProcessAttachmentType: BoardingProcessAttachmentType[]; isLoading: boolean } => {
    const [isLoading, setIsLoading] = useState(true);
    const [boardingProcessAttachmentType, setBoardingProcessAttachmentType] = useState<BoardingProcessAttachmentType[]>([]);

    useEffect(() => {
        (async function () {
            setIsLoading(true);
            api.get(`${entity}/getCombo`, {
                headers: { authorization: `Bearer ${token}` },
            })
                .then((response) => {
                    const { data } = response;
                    setBoardingProcessAttachmentType(data);
                })
        })();
    }, [params, token]);

    return { boardingProcessAttachmentType, isLoading };
};