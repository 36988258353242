import { useEffect, useState } from "react";

import { api, createApiInstance } from "../../lib/api";
import { buyerGroupSup } from "../../types/buyerGroupSup";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import { DataTableUtils } from "../../components/DataTable/DataTableUtils";
import { ComboBoxType } from "../../types/general";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
  status?: boolean;
}

const entity = "buyerGroupsSup";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string,
  isCombo?: boolean
):
  | Promise<{ data: buyerGroupSup[]; meta: List; isLoading: boolean }>
  | any => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<buyerGroupSup[]>([{} as buyerGroupSup]);
  const [meta, setMeta] = useState<List>({} as List);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await dataTableUtils.findPaginate(params);
        const { total, data, currentPage, totalPages, limit } = response.data;
        setData(data);
        setMeta({
          total,
          totalPages,
          limit,
          currentPage,
        });
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [params, token]);

  return { data, meta, isLoading };
};

export const create = async (file?: any): Promise<any> => {
  const token = getToken();
  return await api.post(`/${entity}`, file, {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getCompanyCenter = async (): Promise<ComboBoxType[]> => {
  const token = getToken();
  const { data } = await api.get(`/centers/getCentersWithCompaniesCombo`, {
    headers: { authorization: `Bearer ${token}` },
  });
  
  return data;
};

export const findCodeBuyerGroupSup = async (id: string | number): Promise<ComboBoxType[]> => {
  const token = getToken();
  const { data } = await api.get(`/buyerGroupsSup/getComboCodeBuyerGroupSup?ID_CENTRO=${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return data;
};

export const exportAll = async (params) => {
  let xlsxData = [
    {
      label: "Company - Plan",
      value: (row: any) => `${row.GEMP_SIGLA_EMPRESA} - ${row.GCEN_DSC_SIGLA} - ${row.GCEN_NOME_CENTRO}`
    },
    { label: "Code Buyer Group", value: "GC_CODIGO_GRUPO" },
    { label: "Buyer Name", value: "GC_NOME_COMPRADOR" },
    { label: "Supervisor Name", value: "NOME_SUPERVISOR" },
    { label: "Manager Name", value: "NOME_GERENTE" },
  ]
  await dataTableUtils.exportAll({params, xlsxData, filename: "Airport"});
};
