import { yupResolver } from '@hookform/resolvers/yup';
import { createContext, useEffect, useState, ReactNode, useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { findFields, getReportCategory } from '../../../../services/report';

const defaultProvider = {
  register: (key: string) => Object,
  handleSubmit: () => {},
  formState: (key: string) => Object,
  reset: (key: Object) => {},
  control: null,
  getValues: (key: string) => {},
  watch: (key: string) => {},
  entities: [],
  setEntities: (key: string) => {},
  isLoading: false,
  relations: [],
  relationsToShow: [],
  formErrors: {},
};

interface CustomReportContextType {
  watch: (fieldName: string) => any;
  reset: (data?: any) => void;
  handleSubmit: () => void;
  entities: any;
  register: (fieldName: string) => void;
  formErrors: any;
  isLoading: boolean;
}

const CustomReportContext = createContext<CustomReportContextType>(defaultProvider);

type Props = {
  children: ReactNode,
  ID_RELATORIO?: number,
};

const customReportScheme = yup.object({
  ID_RELATORIO: yup.number().required(),
  NOME_CUSTOM: yup.string().max(60).nullable(),
  FILTRO_PADRAO: yup.boolean().required(),
  customReportFields: yup.array().of(
      yup.object({
        ORDER: yup.number().required(),
        NOME_TABELA: yup.string().max(60).required(),
        NOME_TABELA_ALIAS: yup.string().max(60).required(),
        NOME_TABELA_ORIGEM: yup.string().max(50),
        NOME_TABELA_ORIGEM_ALIAS: yup.string().max(50),
        NOME_ATRIBUTO: yup.string().max(60).required(),
        customReportFieldValues: yup.array().of(
            yup.object({
              DATA_INICIO: yup.date().nullable(),
              DATA_FIM: yup.date().nullable(),
              VALOR: yup.string().nullable(),
            })
        ),
      })
  ).min(1)
});

const CustomReportProvider = ({ children, ID_RELATORIO }: Props) => {
  const [entities, setEntities] = useState([]);
  const [relations, setRelations] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [relationsToShow, setRelationsToShow] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    control,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(customReportScheme),
  });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                // Fetch fields with await
                const fields = await findFields();
                setEntities(fields?.entities || []);  // Default to an empty array if undefined
                reset({
                    ID_RELATORIO: ID_RELATORIO,
                    FILTRO_PADRAO: false,
                    customReportFields: [],
                });

                // Process relations from the fetched fields
                const localRelations = [];
                fields?.entities?.forEach((field) => {
                    field?.relations?.forEach((relation) => {
                        localRelations.push({
                            ...relation,
                            origem: field.tableName,
                        });
                    });
                });
                setRelations(localRelations);

                // Fetch report categories
                const reportCategory = await getReportCategory(ID_RELATORIO);
                const relationToShowLocal: any[] | ((prevState: never[]) => never[]) = [];
                reportCategory.forEach((reportCategoryItem: { categoriaRel: { reportCategoryTables: any[]; }; }) => {
                    reportCategoryItem.categoriaRel.reportCategoryTables.forEach((categoryTableItem) => {
                        relationToShowLocal.push(categoryTableItem);
                    });
                });
                setRelationsToShow(relationToShowLocal);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [ID_RELATORIO, reset]);


  return (
      <CustomReportContext.Provider
          value={{
            register,
            handleSubmit,
            formState: { errors: formErrors },
            reset,
            control,
            getValues,
            watch,
            entities,
            setEntities,
            formErrors,
            isLoading,
            relations,
            relationsToShow,
          }}
      >
        {children}
      </CustomReportContext.Provider>
  );
};

const useCustomReport = () => useContext(CustomReportContext);

export { CustomReportContext, CustomReportProvider, useCustomReport };
