import { Autocomplete, Collapse, Grid, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { ComboBoxType } from "../../../../types/general";
import { findComboBox } from "../../../../services/report";
import dayjs from "dayjs";
import { useFieldsToHide } from "../hooks/fieldsToHide.hook";
import { useTranslation } from "../hooks/translation.hook";
import { translate } from "../../../utils/translation";

export default function CustomReportValue({
  expanded,
  column,
  onHandle,
  currentValue,
  relation,
}) {

  const [comboValues, setComboValues] = useState<ComboBoxType[]>([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const {customColumn} = useFieldsToHide();
  const { translations } = useTranslation();

  useEffect(() => {
    const comboActive = isComboBox()
    if(comboActive) {
      findComboBox(comboActive.route, comboActive?.params).then((comboBox) => {
        setComboValues(comboBox);
      });
    }
  }, [])

  const onChange = (type: string, data) => {
    const dataToSave = [];
    if(type === 'start-date') {
      const formattedDate = data ? dayjs(data).toISOString() : null;
      setStartDate(formattedDate);
      dataToSave.push({
        DATA_INICIO: formattedDate,
        DATA_FIM: endDate,
      });
    } else if(type === 'end-date') {
      const formattedDate = data ? dayjs(data).toISOString() : null;
      setEndDate(formattedDate);
      dataToSave.push({
        DATA_INICIO: startDate,
        DATA_FIM: formattedDate,
      });
    } else if(type === 'combo') {
      data.forEach((dataItem) => {
        dataToSave.push({
          VALOR: dataItem.id,
        });
      })
    } else if(data !== '') {
      dataToSave.push({
        VALOR: data,
      });
    }
    
    onHandle(dataToSave);
  }

  const isComboBox = () => {
    return customColumn.find((item) => {
      if(item.tableName) {
        return item.name  === column.columnName && relation.tableName === item.tableName;
      }
      return item.name  === column.columnName
    });
  }

  return <>
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <Grid
        container
        style={{
          border: '1px solid',
          borderColor: '#bdbdbd',
          borderRadius: '2px',
          backgroundColor: '#f5f5f5',
          padding: '24px 12px',
          borderTop: 'none',
        }}
      >
        {['timestamp', 'Date'].includes(column.type) ?
          (<Grid item xs={12} display='flex' gap={2}>
            <DatePicker
              sx={{
                "& .MuiInputBase-root": { height: "39px" },
                "& .MuiFormLabel-root": { top: "-7px" },
              }}
              label={translate(relation.tableName, column.columnName, translations)}
              slotProps={{
                textField: {
                  variant: "outlined",
                },
              }}
              value={currentValue?.customReportFieldValues?.[0]?.DATA_INICIO ? dayjs(currentValue.customReportFieldValues[0].DATA_INICIO) : null}
              onChange={(date: any) => {
                onChange('start-date', date);
              }}
            />
            <Typography marginTop={1}>to</Typography>
            <DatePicker
              sx={{
                "& .MuiInputBase-root": { height: "39px" },
                "& .MuiFormLabel-root": { top: "-7px" },
              }}
              label={translate(relation.tableName, column.columnName, translations)}
              slotProps={{
                textField: {
                  variant: "outlined",
                },
              }}
              value={currentValue?.customReportFieldValues?.[0]?.DATA_FIM ? dayjs(currentValue.customReportFieldValues[0].DATA_FIM) : null}
              onChange={(date: any) => {
                onChange('end-date', date);
              }}
            />
          </Grid>)
          : isComboBox() ? (
            <Grid item md={6} xs={12}>
              <Autocomplete
                multiple
                options={comboValues}
                getOptionLabel={(option: ComboBoxType) =>
                  `${option.description}`
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label={translate(relation.tableName, column.columnName, translations)}
                    size="small"
                    variant="outlined"
                  />
                )}
                value={comboValues
                  .filter(item => currentValue?.customReportFieldValues?.some(value => value.VALOR == item.id))
                }
                onChange={(_: any, data: any) => {
                  onChange('combo', data);
                }}
              />
            </Grid>
          )
          : column?.enum ? (
            <Grid item md={6} xs={12}>
              <Autocomplete
                multiple
                options={column.enum.map((enumItem) => ({ description: enumItem, id: enumItem }))}
                getOptionLabel={(option: ComboBoxType) =>
                  `${option.description}`
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label={translate(relation.tableName, column.columnName, translations)}
                    size="small"
                    variant="outlined"
                  />
                )}
                value={column.enum
                  .map(enumItem => ({ description: enumItem, id: enumItem }))
                  .filter(item => currentValue?.customReportFieldValues?.some(value => value.VALOR === item.description))
                }
                onChange={(_: any, data: any) => {
                  onChange('combo', data);
                }}
              />
            </Grid>
          )
          : (
          <Grid item md={6} xs={12}>
            <TextField
              InputLabelProps={{ shrink: true }}
              label={translate(relation.tableName, column.columnName, translations)}
              size="small"
              variant="outlined"
              fullWidth
              value={currentValue?.customReportFieldValues?.[0]?.VALOR || undefined}
              onChange={(event) => onChange('text', event.target.value)}
            />
          </Grid>
        )}
      </Grid>
    </Collapse> 
  </>
}