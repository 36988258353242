import Process from ".";

export const processRoutes = [
  {
    path: "/boarding-process",
    element: <Process />,
  },
  {
    path: "/boarding-process/:id",
    element: <Process />,
  },
];