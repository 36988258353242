import { Download } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { isAxiosError } from "axios";
import { useEffect, useState } from "react";

import Search from "../../../components/Search";
import {
  create,
  deleteCurrency,
  exportAll,
  update,
  useList,
  isInUse,
} from "../../../services/currencies";
import { getLocal, getToken } from "../../../storage/auth";
import { Currency } from "../../../types/currency";
import CurrencyForm from "./components/CurrencyForm";
import DataTable from "./components/DataTable";
import { hasClaim } from "../../../storage/userClaims";
import { useSnackbar } from "notistack";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
}
export default function Currencies() {
  const { enqueueSnackbar } = useSnackbar();
  const [currency, setCurrency] = useState<Currency>({
    ATIVO: true,
  } as Currency);
  const [currencyId, setCurrencyId] = useState<string | number>();
  const [isSaving, setIsSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [token, setToken] = useState<string>();
  const [userValidation, setUserValidation] = useState<string>();
  const [controlEdit, setControlEdit] = useState({
    loading: false,
    isInUse: false,
  });
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = (getLocal("userInfo")) as any;
    setToken(getTokens);
    setUserValidation(JSON.parse(getUser));
  };

  useEffect(() => {
    getTokenSession();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [errors, setErrors] = useState<string[]>();
  const { data, meta, isLoading } = useList(params, token);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };

  const handleViewCurrency = (id: string | number) => {
    setErrors([]);
    setReadOnly(true);
    setCurrency(
        data.findLast((item) => item.ID_MOEDA === id) || ({} as Currency)
    );
    setModalOpen(true);
  }

  const handleEditCurrency = async (id: string | number) => {
    setErrors([])
    setReadOnly(false);
    const editCurrency = data.filter((item) => item.ID_MOEDA === id);
    if (editCurrency.length) {
      const { ID_MOEDA, SIGLA_MOEDA, DSC_MOEDA, ATIVO } = editCurrency[0];
      setCurrency({ ID_MOEDA, SIGLA_MOEDA, DSC_MOEDA, ATIVO });
      setModalOpen(true);
    }
    try {
      setControlEdit({
        loading: true,
        isInUse: true,
      });
      const { inUse } = await isInUse(id);
      setControlEdit({
        loading: false,
        isInUse: inUse,
      });
    } catch (error) {
      enqueueSnackbar("It's not posible to edit, try again later", {
        variant: "error",
      });
      setControlEdit({
        loading: false,
        isInUse: true,
      });
    }
  };

  const handleDeleteCurrencyConfirmation = (id: string | number) => {
    setCurrencyId(id);
    setOpenDeleteModal(true);
  };

  const handleDeleteCurrency = async () => {
    if (currencyId)
      try {
        setIsSaving(true);
        await deleteCurrency(currencyId);
        enqueueSnackbar("Currency deleted successful", { variant: "success" });
        setParams({ ...params });
      } catch (err: any) {
        enqueueSnackbar("Currency is being used", { variant: "error" });
      }
    setIsSaving(false);
    setOpenDeleteModal(false);
  };

  const handleFormSubmit = async (newCurrency: Currency) => {
    try {
      setIsSaving(true);
      if (newCurrency.ID_MOEDA) {
        await update(newCurrency.ID_MOEDA, newCurrency);
        enqueueSnackbar("Currency updated successful", { variant: "success" });
      } else {
        await create(newCurrency);
        enqueueSnackbar("Currency created successful", { variant: "success" });
      }
      setCurrency({ ATIVO: true } as Currency);
      setParams({ ...params });
      setModalOpen(false);
      setIsSaving(false);
      setErrors([]);
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setErrors([err.response?.data?.message]);
      }
      setIsSaving(false);
    }
    setIsSaving(false);
  };

  const exportCurrencies = async () => {
    try {
      await exportAll(params);
    } catch (err) {}
  };

  const handleCancelEditCurrency = () => {
    setCurrency({} as Currency);
    setErrors([]);
    setModalOpen(false);
    setControlEdit({
      loading: false,
      isInUse: false,
    });
  };

  return (
    <Grid marginTop={4}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={9}>
          <Typography variant="h5">Currency</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" marginTop={1}>
        <Grid item xs={2}>
          {!modalOpen ? (
            <Grid item xs={2}>
              <Button
                sx={{
                  display: hasClaim("Currency - Maintenance")
                    ? "block"
                    : "none",
                }}
                variant="contained"
                onClick={() => {
                  setControlEdit({
                    loading: false,
                    isInUse: false,
                  });
                  setReadOnly(false);
                  setErrors([])
                  setModalOpen(true);
                }}
                fullWidth
                data-testid="new-button"
              >
                New
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {modalOpen ? (
        <Grid container alignItems="center">
          <CurrencyForm
            onSubmit={handleFormSubmit}
            currency={currency}
            onCancel={handleCancelEditCurrency}
            isLoading={isSaving}
            errors={errors}
            controlEdit={controlEdit}
            readOnly={readOnly}
          />
        </Grid>
      ) : null}
      <Search onChange={handleRequest} />
      <Grid container marginTop={2} justifyContent="flex-end">
        <Button
          variant="text"
          onClick={exportCurrencies}
          startIcon={<Download />}
        >
          Export XLSX
        </Button>
      </Grid>
      <Grid>
        <DataTable
          loading={isLoading}
          data={data}
          userValidation={userValidation}
          onDeleteCurrency={handleDeleteCurrencyConfirmation}
          onEditCurrency={handleEditCurrency}
          onViewCurrency={handleViewCurrency}
          rowCount={meta.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          paginationModel={paginationModel}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Currency</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete this Currency?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button onClick={handleDeleteCurrency} data-testid='delete-modal-confirm'>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
