import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import Container from "@mui/material/Container";
import { SnackbarProvider } from "notistack";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import { UserProvider } from "../../providers/User/User";
import { decodeToken } from "../../services/signin";
import { getToken, setLocal } from "../../storage/auth";

import "../../styles/globals.css";
import useMiddleware from "../../lib/middleware";
import { menuList } from "../../components/Header/components/Nav/config";
import { allowAccessThisRoute, getRoutes } from "../utils/permissions";

const theme = createTheme({});

export default function PrivateLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { isLogged, isLoading } = useMiddleware({ route: pathname });

  if (!isLoading && !isLogged) {
    navigate("/signin");
  }

  const getTokenSearch = async () => {
    let getBearerToken = getToken();
    let result;
    if (getBearerToken) {
      result = await decodeToken(getBearerToken);
    }
    if (result) {
      setLocal("userInfo", JSON.stringify(result));
    }
  };

  const menuListString = getRoutes(menuList);
  const isAllowed = allowAccessThisRoute(menuListString, pathname);

  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE || "";
    getTokenSearch();
  }, []);

  const isFullScreen = ["/dashboard"].includes(pathname);

  return (
    <>
      {!isAllowed ? (
        <Backdrop
        sx={{ backgroundColor: '#1976d2', color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        >  
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
          
            <img
              src="/images/logo.png"
              alt="Embraer"
              data-testid="logo-header"
            />
            <Alert sx={{ marginTop: 2 }} severity="info">You don't have access to this page.</Alert>
          </Box>
        </Backdrop>
      ) :
      isLoading || !isLogged ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
          data-testid="backdrop"
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <UserProvider>
          <ThemeProvider theme={theme}>
            <SnackbarProvider transitionDuration={500}>
              <Header />
              <div className="breadcrumb">
                <Container>{/* <BreadCrumb path={pathname} /> */}</Container>
              </div>
              <Container id="detail" sx={{
                ...(isFullScreen && {
                  maxWidth: "none !important",
                  paddingX: "30px",
                  paddingY: "10px"
                })
              }}>
                <Outlet />
              </Container>
            </SnackbarProvider>
          </ThemeProvider>
        </UserProvider>
      )}
    </>
  );
}
