import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import { PackageTypesProps } from "../../types/packageType";
import { DataTableUtils } from "../../components/DataTable/DataTableUtils";

interface IList {
  keyword?: string
  page?: number
  limit?: number
  sort?: string
}

const entity = "packageTypes";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
    params?: IList,
    token?: string
): { data: PackageTypesProps[]; meta: List; isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<PackageTypesProps[]>([
    {} as PackageTypesProps,
  ])
  const [meta, setMeta] = useState<List>({} as List)

  useEffect(() => {
    if (token) {
      ;(async function () {
        setIsLoading(true)
        const response = await dataTableUtils.findPaginate(params)
        const { total, data, currentPage, totalPages, limit } = response.data
        setData(data)
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }))
        setIsLoading(false)
      })()
    }
  }, [params, token])

  return { data, meta, isLoading }
}

export const isInUse = async (id: number | string): Promise<any> => {
  const { data } = await api.get(`/${entity}/isInUse/${id}`)

  return data
}

export const update = async (
    id: number | string,
    currency?: PackageTypesProps
): Promise<PackageTypesProps> => {
  const { data } = await api.patch(`/${entity}/${id}`, currency)

  return data
}
export const deleteCargo = async (
    id: number | string
): Promise<PackageTypesProps> => {
  const { data } = await api.delete(`/${entity}/${id}`, )

  return data
}

export const create = async (currency: any): Promise<any> => {
  const { data } = await api.post(`/${entity}`, currency)

  return data
}

export const createCopy = async (
    currency: PackageTypesProps[]
): Promise<PackageTypesProps[]> => {
  const { data } = await api.post(`/${entity}/copy`, currency)

  return data
}

export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: 'Id', value: 'ID_TIPO_PACKAGE' },
    { label: 'Description', value: 'DSC_TIPO_PACKAGE' },
    { label: 'Sap Code', value: 'CODIGO_SAP_EDI' },
    {
      label: 'Status',
      value: (row: any) => (row.ATIVO ? 'Active' : 'Inactive'),
    },
  ]

  await dataTableUtils.exportAll({ params, xlsxData, filename: 'PackageType' })
}

export const findAll = async (): Promise<PackageTypesProps[]> => {
  const response = await api.get<PackageTypesProps[]>(`/${entity}`)
  return response.data
}

export const findAllActives = async (): Promise<PackageTypesProps[]> => {
  const params = {
    page: 1,
    limit: 1000,
    url: "findPaginate" 
  }
  const response = await api.get(`/${entity}/${params.url}`, {
    params
  });

  return response.data.data as PackageTypesProps[];
}
