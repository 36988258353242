import { Card, Grid, Typography } from "@mui/material";
import { AnchorY } from "@mui/x-charts";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { formatDecimalFree } from "../../app/utils/inputs";

export type GraphData = {
  label: string;
  total: number;
  value: number;
};

type CardGraphicProps = {
  title: string;
  data: GraphData[];
  position?: null | AnchorY;
}

const COLORS = [
  '#0A75BD',
  '#FFCC55',
  '#33FF57',
  '#FF33A1',
  '#FF4E02',
  '#8C33FF',
  '#33A1FF',
  '#FF0000',
  '#FFaff8',
  '#00FFFF',
  '#FFD700',
];

export default function CardGraphic({
  title,
  data,
  position = 'bottom',
}: Readonly<CardGraphicProps>) {
  return (
    <Card id="card-graphic">
      <Typography fontWeight="bold" textAlign="center" marginTop={3}>{ title }</Typography>

      <Grid container spacing={2} display='flex' alignContent='center' marginTop={-5}>
        <PieChart
          colors={COLORS}
          series={[
            {
              data,
              innerRadius: 30,
              outerRadius: 120,
              cx: '60%',
              arcLabel: (item: { value: number }) => {
                const percentage = item.value.toFixed(2);
                const { formatted } = formatDecimalFree(percentage, { fractionDigits: 2 });
                return `${formatted}%`;
              },
              valueFormatter: (item: Partial<GraphData>): string => {
                const percentage = item.value.toFixed(2);
                const { formatted } = formatDecimalFree(percentage, { fractionDigits: 2 });
                return `${item.total} (${formatted}%)`
              },
            },
          ]}
          height={400}
          slotProps={{
            legend: {
              direction: 'row',
              position: { 
                vertical: (position || 'middle'), 
                horizontal: 'middle',
              },
              padding: 0,
              labelStyle: {
                fontSize: 12,
              },
              itemMarkWidth: 20,
              itemMarkHeight: 5,
            },
          }}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'black',
              fontWeight: 'bold',
              fontSize: 12,
            },
          }}
        />
      </Grid>
    </Card>
  );
}