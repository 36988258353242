import VisibilityIcon from "@mui/icons-material/Visibility";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import DataTableDefault from "../../../../../components/DataTable";
import { formatDatetime } from "../../../../utils/date";
import { findLogDetails } from "../../../../../services/logInterface";
import { LogInterface } from "../../../../../types/logInterface";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Paper, styled, Table, TableBody, TableCell, TableRow, Tooltip } from "@mui/material";
import { useState } from "react";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

interface DataTableProps {
  data: LogInterface[];
  [x: string]: any;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function DataTable({
  data,
  ...props
}: DataTableProps) {
  const [dialogInputModal, setDialogInputModal] = useState(false);
  const [dialogInputData, setDialogInputData] = useState<LogInterface>();
  const [dialogInputJsonData, setDialogInputJsonData] = useState('');

  const [dialogDetailModal, setDialogDetailModal] = useState(false);
  const [dialogDetailData, setDialogDetailData] = useState();

  const onView = (row: LogInterface) => {
    setDialogInputModal(true);
    setDialogInputData(row);
    setDialogInputJsonData((row.DSC_JSON_REQUEST && dialogInputData?.DSC_JSON_REQUEST !== '""') ? row.DSC_JSON_REQUEST : row.DSC_JSON_RESPONSE);
  }

  const onViewButton = (type: string) => {
    type === 'request' ? setDialogInputJsonData(dialogInputData?.DSC_JSON_REQUEST || '') : setDialogInputJsonData(dialogInputData?.DSC_JSON_RESPONSE || '')
  }

  const onLogDetail = (isToGetDetails: number) => {
    setDialogDetailModal(true);
    findLogDetails(isToGetDetails).then((response) => {
      setDialogDetailData(response);
    });
  }

  const renderMenus = (row: any) => {
    return [
      <Tooltip title='Request/Response'>
        <GridActionsCellItem
          key={`view-${row.ID_INTEGRACAO_SAP_LOG}`}
          icon={<VisibilityIcon />}
          label="View"
          onClick={() => onView(row)}
          color="inherit"
          data-testid="view-button"
          disabled={
            (!row.DSC_JSON_REQUEST ||row.DSC_JSON_REQUEST === '""')
            && (!row.DSC_JSON_RESPONSE || row.DSC_JSON_RESPONSE === '""')
          }
        />
      </Tooltip>,
      <Tooltip title='Errors'>
        <GridActionsCellItem
          key={`log-detail-${row.ID_INTEGRACAO_SAP_LOG}`}
          icon={<StickyNote2Icon />}
          label="Details"
          onClick={() => onLogDetail(row.ID_INTEGRACAO_SAP_LOG)}
          color="inherit"
          data-testid="log-detail"
          disabled={!row.COM_ERRO}
        />
      </Tooltip>,
    ];
  };

  const columns: GridColDef[] = [
    {
      field: "ID_INTEGRACAO_SAP_LOG",
      headerName: "Id",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "ID_CENTRO",
      headerName: "Company - Plant",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        if(params.row.GE_SIGLA_EMPRESA) {
          return `${params.row.GE_SIGLA_EMPRESA} - ${params.row.GC_DSC_SIGLA} - ${params.row.GC_NOME_CENTRO}`;
        } else {
          return '-'
        }
        
      },
      minWidth: 300,
    },
    {
      field: "ID_INTEGRACAO_SAP",
      headerName: "Interface Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "DATA_OCORRENCIA",
      headerClassName: "super-app-theme--header",
      headerName: "Date",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => formatDatetime(params.row.DATA_OCORRENCIA)
    },
    {
      field: "DSC_MENSAGEM",
      headerName: "Message",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }) => renderMenus(row),
    },
  ];
  return (
    <>
      <DataTableDefault
        rowId={data.map((row) => row.ID_INTEGRACAO_SAP_LOG)}
        data-testid="custom-regime-data-table"
        data={data}
        columns={columns}
        {...props}
      />

      <Dialog
        open={dialogDetailModal}
        onClose={() => setDialogDetailModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='lg'
      >
        <DialogContent>
          <DialogTitle id="alert-dialog-title">Error Message</DialogTitle>
          <DialogContentText id="alert-dialog-description">
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableBody>
                {dialogDetailData && dialogDetailData?.map((row) => (
                  <StyledTableRow key={row.name}>
                    <TableCell align="left">{row.DSC_MENSAGEM_ERRO}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <Button
              sx={{ marginTop: '14px' }}
              variant="contained"
              startIcon={<ArrowBackIosIcon />}
              onClick={() => setDialogDetailModal(false)}
            >
              Return
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={dialogInputModal}
        onClose={() => setDialogInputModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='lg'
      >
        <DialogContent>
          <DialogTitle id="alert-dialog-title">Data</DialogTitle>
          <DialogContentText id="alert-dialog-description">
            <Box sx={{ display: 'flex', gap: '14px' }}>
              {(dialogInputData?.DSC_JSON_REQUEST && dialogInputData?.DSC_JSON_REQUEST !== '""') && <Button
                sx={{
                  marginTop: '14px',
                  backgroundColor: (dialogInputJsonData === dialogInputData?.DSC_JSON_REQUEST) ?  '#1565c0' : '#1976d2'
                }}
                variant="contained"
                startIcon={<FileUploadIcon />}
                onClick={() => onViewButton('request')}
              >
                Request
              </Button>}
              {(dialogInputData?.DSC_JSON_RESPONSE && dialogInputData?.DSC_JSON_RESPONSE !== '""') && <Button
                sx={{
                  marginTop: '14px',
                  backgroundColor: (dialogInputJsonData === dialogInputData?.DSC_JSON_RESPONSE) ?  '#1565c0' : '#1976d2'
                }}
                variant="contained"
                startIcon={<FileDownloadIcon />}
                onClick={() => onViewButton('response')}
              >
                Response
              </Button>}
            </Box>
            
            <Paper
              sx={{ padding: '14px', marginTop: '14px' }}
              elevation={6}
            >
              <pre>
                { (dialogInputJsonData) ? JSON.stringify(JSON.parse(dialogInputJsonData), null, 2) : '' }
              </pre>
            </Paper>
            <Button
              sx={{ marginTop: '14px' }}
              variant="contained"
              startIcon={<ArrowBackIosIcon />}
              onClick={() => setDialogInputModal(false)}
            >
              Return
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
