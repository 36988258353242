import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import { AutomaticAuthorizationScheduleProps } from "../../types/automaticAuthorizationSchedule";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";
import { ComboBoxType } from "../../types/general";
import { ReportScheduleProps } from "../../types/reportSchedule";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
  ID_CENTRO?: number;
  ID_RELATORIO?: number;
  ID_RELATORIO_CUSTOM?: number;
}

const entity = "reportSchedule";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
    params?: IList,
    token?: string
): { data: any[]; meta: List; isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [meta, setMeta] = useState<List>({} as List);

  useEffect(() => {
    if (!token || !params?.ID_CENTRO || params.page === undefined || params.limit === undefined) {
      return;
    }

    setIsLoading(true);
    setData([]); // Limpa os dados antes de cada nova requisição
    if (token && params?.ID_CENTRO) {
      (async function () {
        try {
          const response = await new DataTableUtils('reportSchedule').findPaginate(params);

          // Garantindo que 'response' e 'response.data' existam
          if (response && response.data) {
            const { total, data, currentPage, totalPages, limit } = response.data;
            const mappedData = data.map((item: any) => ({
              ...item,
              ID_RELATORIO: item.GRC_ID_RELATORIO,
            }));

            setData(mappedData);
            setMeta({ total, currentPage, totalPages, limit });
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setIsLoading(false);
        }
      })();
    } else {
      setIsLoading(false);
    }
  }, [params, token]);

  return { data, meta, isLoading };
};

export const update = async (
  id: number | string,
  values?: ReportScheduleProps
): Promise<ReportScheduleProps> => {
  const { data } = await api.patch(`/${entity}/${id}`, values);

  return data;
};

export const deleteAuthorizationSchedule = async (
  id: number | string
): Promise<AutomaticAuthorizationScheduleProps> => {
  const { data } = await api.delete(`/${entity}/${id}`);

  return data;
};

export const findReport = async (): Promise<ComboBoxType[]> => {
  const { data } = await api.get(`/reports/getCombo`);

  return data;
};

export const findReportCustom = async (
  id: number | string
): Promise<ComboBoxType[]> => {
    const { data } = await api.get(`/customReports/getCombo?ID_RELATORIO=${id}`);

    return data;
};

export const create = async (values: any): Promise<any> => {
  const { data } = await api.post(`/${entity}`, values);

  return data;
};