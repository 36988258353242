import { useState, useEffect } from 'react';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Search from "./Search";
import {getInterfaces, useList} from "../../../services/interfaceReprocess";
import DataTable from "./DataTable";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";

export default function SendVisibilities() {
    const [interfaces, setInterfaces] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCenter, setSelectedCenter] = useState(null);

    const [params, setParams] = useState<IParams>({ page: 1, limit: 10 } as IParams);
    const { data } = useList(params);

    const onSearch = (data) => {
        setParams({ ...params, ...data });
    }

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getInterfaces();
                setInterfaces(response);
                console.log('Data:', response);
            } catch (error) {
                console.error('Error fetching Interfaces:', error);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, [selectedCenter]);

    return (
        <Grid marginTop={4}>
            <Grid container spacing={1} direction="column" marginBottom={3}>
                <Grid item xs={10}>
                    <Typography variant="h5">Interface Reprocess</Typography>
                </Grid>
            </Grid>


            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Search
                    setParams={setParams}
                    interfaces={interfaces}
                    onSubmit={(data) => onSearch(data)}
                />
            </LocalizationProvider>

            <DataTable
                data={data}
                loading={isLoading}
                pageSizeOptions={[10, 25, 50, 100]}

                initialState={{
                  pagination: { paginationModel: { pageSize: params.limit } },
                }}
                rowId={data && data[0] ? data[0].map((row: any) => row.ID_INTEGRACAO_REPROC) : []}
                data-testid="custom-data-table"

                onPaginationModelChange={(e: any) => {
                    const newPage = e.page + 1;
                    const newLimit = e.pageSize;
                    setParams({ ...params, page: newPage, limit: newLimit });
                }}
                pagination
                paginationMode="server"
                rowCount={data[1] || 0}
            />

        </Grid>
    );
};
