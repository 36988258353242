import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { ComboBoxType } from "../../../../../types/general";
import { Controller, UseFormClearErrors, UseFormRegister, UseFormSetError, UseFormSetValue } from "react-hook-form";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Invoice, InvoiceType } from "../../../../../types/invoice";
import { handleFormatDecimalChange, formatDecimalFree } from "../../../../utils/inputs";
import DatePickerWrapper from "../../../../../components/wrappers/DatePickerWrapper";

interface InvoiceDataProps {
  invoiceTypes: ComboBoxType[];
  currencies: ComboBoxType[];
  incoterms: ComboBoxType[];
  incotermsComplements: ComboBoxType[];
  serviceLevel: ComboBoxType[];
  domesticCarrier: ComboBoxType[];
  countries: ComboBoxType[];
  transportationMode: ComboBoxType[];
  freightForwarder: ComboBoxType[];
  broker: ComboBoxType[];
  customRegimes: ComboBoxType[];
  control: any;
  formErrors: { [key: string]: string };
  register: UseFormRegister<any>;
  getReceivedDate?: (value: string | null) => void;
  watch: (key: string) => {};
  invoice?: Invoice;
  disableAll?: boolean;
  setError: UseFormSetError<Invoice>,
  clearErrors: UseFormClearErrors<Invoice>,
  invoiceDate: string | null
}

export default function InvoiceData({
  invoiceTypes,
  currencies,
  incoterms,
  incotermsComplements,
  serviceLevel,
  domesticCarrier,
  countries,
  transportationMode,
  freightForwarder,
  broker,
  customRegimes,
  control,
  formErrors,
  register,
  getReceivedDate,
  watch,
  invoice,
  disableAll = false,
  setError,
  invoiceDate,
  clearErrors
}: InvoiceDataProps) {
  const { id: idInvoice } = useParams<{ id: string }>();

  const invoiceType = watch('ID_TIPO_INVOICE')

  return (
    <>
      <Box>
        <Grid container marginY={2} spacing={2}>
          <Grid item xs={3}>
            <Controller
              name="ID_TIPO_INVOICE"
              control={control}
              defaultValue={invoice?.ID_TIPO_INVOICE || undefined}
              render={({ field: { value, ref, onChange, ...field } }) => (
                <>
                  {
                    invoiceTypes && <Autocomplete
                      options={invoiceTypes}
                      getOptionLabel={(option) => option.description}
                      value={
                        invoiceTypes?.findLast(
                          (item: ComboBoxType) => item?.description === value
                        ) || null
                      }
                      disabled={disableAll || !!idInvoice}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: !!(watch("ID_TIPO_INVOICE") && (watch("ID_TIPO_INVOICE") as string).length > 0) }}
                          label="Invoice Type*"
                          size="small"
                          inputRef={ref}
                          error={!!formErrors.ID_TIPO_INVOICE}
                          variant="outlined"
                          disabled={disableAll || !!idInvoice}
                          {...register("ID_TIPO_INVOICE")}
                        />
                      )}
                      onChange={(_: any, data: any) => {
                        onChange(data?.description || null);
                      }}
                    />
                  }
                </>
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="DATA_RECEBIMENTO"
              control={control}
              defaultValue={invoice?.DATA_RECEBIMENTO && dayjs(invoice?.DATA_RECEBIMENTO).isValid() ? dayjs(invoice.DATA_RECEBIMENTO).toISOString() : null}
              render={({ field: { onChange, ref, value, ...field } }) => (
                <DatePickerWrapper
                  sx={{
                    width: 1,
                  }}
                  closeOnSelect
                  slotProps={{ textField: { size: 'small' } }}
                  label="Received Date*"
                  value={value ? dayjs(value) : null}
                  inputRef={ref}
                  onChange={(date) => {
                    const formattedDate = date && dayjs(date).isValid() ? dayjs(date).toISOString() : null;
                    onChange(formattedDate);
                    if (getReceivedDate) {
                      getReceivedDate(formattedDate);
                    }
                    
                    const today = dayjs().startOf('day');
                    const receivedDate =  dayjs(date).startOf('day');
                    const currentInvoiceDate = dayjs(invoiceDate, 'MM/DD/YYYY');

                    if (!!formErrors.DATA_RECEBIMENTO) clearErrors('DATA_RECEBIMENTO');
                    if (receivedDate < currentInvoiceDate) setError('DATA_RECEBIMENTO', { type: 'custom', message: `Invoice Received Date is less than Invoice Date` });
                    if (receivedDate > today) setError('DATA_RECEBIMENTO', { type: 'custom', message: `Invoice Received Date is greater than ${today.format('MM/DD/YY')}` });
                    if (receivedDate < today.subtract(4, 'month')) setError('DATA_RECEBIMENTO', { type: 'custom', message: `Invoice Date must be greater than ${today.subtract(4, 'month').format('MM/DD/YY')}` });
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      {...field}
                      variant="outlined"
                      error={!!formErrors.DATA_RECEBIMENTO}
                    />
                  )}
                  error={!!formErrors.DATA_RECEBIMENTO}
                  disabled={disableAll}
                  inputFormat="MM/DD/YYYY"
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              defaultValue={invoice?.ID_MOEDA || undefined}
              name="ID_MOEDA"
              control={control}
              render={({ field: { value, ref, onChange, ...field } }) => (
                <>
                  {
                    currencies && <Autocomplete
                      options={currencies}
                      getOptionLabel={(option) => option.description}
                      value={
                        currencies?.findLast((item: ComboBoxType) => item?.id === value) ||
                        null
                      }
                      disabled={disableAll}
                      renderInput={(params) => (
                        <TextField
                        {...params}
                        {...field}
                        label="Currency*"
                        size="small"
                        InputLabelProps={{ shrink: !!value }}
                        inputRef={ref}
                        error={!!formErrors.ID_MOEDA}
                        variant="outlined"
                        disabled={disableAll}
                        />
                        )}
                      onChange={(_: any, data: any) => {
                        onChange(data?.id || null);
                      }}
                    />
                  }
                </>
              )}
            />
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={3}>
            <Controller
              name="VALOR_TOTAL"
              control={control}
              defaultValue={formatDecimalFree(invoice?.VALOR_TOTAL || "", { integerDigits: 12 }).formatted}
              rules={{
                pattern: {
                  value: /^\d{1,11}(,\d{0,3})?$/,
                  message: "Invalid format. Example: 10000,000 or 123421312,234"
                }
              }}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <TextField
                  data-testid="valor-total-input"
                  type="text"
                  fullWidth
                  label="Total Invoice Value*"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: !!value }}
                  value={value && formatDecimalFree(value, { integerDigits: 12 }).formatted}
                  onChange={(event) => {
                    const { raw } = handleFormatDecimalChange(event, { integerDigits: 12 }, true);
                    onChange(raw);
                  }}
                  onBlur={onBlur}
                  inputRef={ref}
                  error={!!error}
                  helperText={error ? error.message : null}
                  disabled={disableAll}
                />
              )}
            />
          </Grid>

          <Grid item xs={3}>
            <Controller
              defaultValue={formatDecimalFree(invoice?.VALOR_TAXAS_EXTRAS || "", { integerDigits: 12 }).formatted}
              name="VALOR_TAXAS_EXTRAS"
              control={control}
              rules={{
                pattern: {
                  value: /^\d{1,11}(,\d{0,3})?$/,
                  message: "Invalid format. Example: 10000,000 or 123421312,234"
                }
              }}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <TextField
                  data-testid="description-input"
                  type="text"
                  fullWidth
                  label="Extra Charge"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    shrink: !!value
                  }}
                  value={value && formatDecimalFree(value, { integerDigits: 12 }).formatted}
                  onChange={(event) => {
                    const { raw } = handleFormatDecimalChange(event, { integerDigits: 12 }, true);
                    onChange(raw);
                  }}
                  onBlur={onBlur}
                  inputRef={ref}
                  error={!!error}
                  helperText={error ? error.message : null}
                  disabled={disableAll}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="ID_INCOTERMS"
              control={control}
              render={({ field: { value, ref, onChange, ...field } }) => (
                <>
                  {
                    incoterms && <Autocomplete
                      options={incoterms}
                      getOptionLabel={(option) => option.description}
                      value={
                        incoterms?.findLast((item: ComboBoxType) => item?.id === value) ||
                        null
                      }
                      disabled={disableAll}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...field}
                          label="Incoterms*"
                          size="small"
                          InputLabelProps={{ shrink: !!value }}
                          error={!!formErrors.ID_INCOTERMS}
                          variant="outlined"
                        />
                      )}
                      onChange={(_: any, data: any) => {
                        onChange(data?.id || null);
                      }}
                    />
                  }
                </>
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="ID_INCOTERMS_COMPL"
              control={control}
              render={({ field: { value, ref, onChange, ...field } }) => (
                <>
                  {
                    incotermsComplements && <Autocomplete
                      options={incotermsComplements}
                      getOptionLabel={(option) => option.description}
                      value={
                        incotermsComplements?.findLast(
                          (item: ComboBoxType) => item?.id === value
                        ) || null
                      }
                      disabled={disableAll}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...field}
                          label="Incoterms Complement*"
                          size="small"
                          InputLabelProps={{ shrink: !!value }}
                          error={!!formErrors.ID_INCOTERMS_COMPL}
                          variant="outlined"
                        />
                      )}
                      onChange={(_: any, data: any) => {
                        onChange(data?.id || null);
                      }}
                    />
                  }
                </>
              )}
            />
          </Grid>

          <Grid item xs={3}>
            <Controller
              name="ID_PRIORIDADE"
              control={control}
              render={({ field: { value, ref, onChange, ...field } }) => (
                <>
                  {
                    serviceLevel && <Autocomplete
                      options={serviceLevel}
                      getOptionLabel={(option) => option.description}
                      value={
                        serviceLevel?.findLast(
                          (item: ComboBoxType) => item?.id === value
                        ) || null
                      }
                      disabled={disableAll}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...field}
                          label="Service Level*"
                          size="small"
                          InputLabelProps={{ shrink: !!value }}
                          error={!!formErrors.ID_PRIORIDADE}
                          variant="outlined"
                        />
                      )}
                      onChange={(_: any, data: any) => {
                        onChange(data?.id || null);
                      }}
                    />
                  }
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="ID_PARCEIRO_DOMESTIC_CARRIER"
              control={control}
              render={({ field: { value, ref, onChange, ...field } }) => (
                <>
                  {
                    domesticCarrier && <Autocomplete
                      options={domesticCarrier}
                      getOptionLabel={(option) => option.description}
                      value={
                        domesticCarrier?.findLast(
                          (item: ComboBoxType) => item?.id === value
                        ) || null
                      }
                      disabled={disableAll}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...field}
                          label="Domestic Carrier"
                          size="small"
                          InputLabelProps={{ shrink: !!value }}
                          error={!!formErrors.ID_PARCEIRO_DOMESTIC_CARRIER}
                          variant="outlined"
                        />
                      )}
                      onChange={(_: any, data: any) => {
                        onChange(data?.id || null);
                      }}
                    />
                  }
                </>
              )}
            />
          </Grid>
          <Grid item xs={3}>
          <Controller
            name="NUM_TRACKING"
            control={control}
            defaultValue={invoice?.NUM_TRACKING || ""}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
              <TextField
                data-testid="description-input"
                fullWidth
                label="Tracking Number /ASN"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: !!value
                }}
                value={value?.toUpperCase()}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                inputProps={{ maxLength: 50 }}
                error={!!error}
                helperText={error ? error.message : null}
                disabled={disableAll}
              />
            )}
          />
          </Grid>

          {["Inbound - International", "Triage - International"].includes(
            invoiceType as string
          ) && (
            <>
              <Grid item xs={3}>
                <Controller
                  name="ID_PAIS_ORIGEM"
                  control={control}
                  render={({ field: { value, ref, onChange, ...field } }) => (
                    <>
                      {
                        countries && <Autocomplete
                          options={countries}
                          getOptionLabel={(option) => option.description}
                          value={
                            countries?.findLast(
                              (item: ComboBoxType) => item?.id === value
                            ) || null
                          }
                          disabled={disableAll}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Country of Source*"
                              size="small"
                              InputLabelProps={{ shrink: !!value }}
                              error={!!formErrors.ID_PAIS_ORIGEM}
                              variant="outlined"
                            />
                          )}
                          onChange={(_: any, data: any) => {
                            onChange(data?.id || null);
                          }}
                        />
                      }
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="ID_MODAL"
                  control={control}
                  render={({ field: { value, ref, onChange, ...field } }) => (
                    <>
                      {
                        transportationMode && <Autocomplete
                          options={transportationMode}
                          getOptionLabel={(option) => option.description}
                          disabled={disableAll}
                          value={
                            transportationMode?.findLast(
                              (item: ComboBoxType) => item?.id === value
                            ) || null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={value}
                              InputLabelProps={
                                { 
                                  shrink: !!(watch("ID_MODAL") && (watch("ID_MODAL") as string).length > 0)
                                }
                              }
                              label="Transportation Mode*"
                              size="small"
                              error={!!formErrors.ID_MODAL}
                              variant="outlined"
                              {...register("ID_MODAL")}
                            />
                          )}
                          onChange={(_: any, data: any) => {
                            onChange(data?.id || null);
                          }}
                        />
                      }
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="ID_PARCEIRO_AGENTE_CARGA"
                  control={control}
                  render={({ field: { value, ref, onChange, ...field } }) => (
                    <>
                      {
                        freightForwarder && <Autocomplete
                          options={freightForwarder}
                          getOptionLabel={(option) => option.description}
                          value={
                            freightForwarder?.findLast(
                              (item: ComboBoxType) => item?.id === value
                            ) || null
                          }
                          disabled={disableAll}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Freight Forwarder*"
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              error={!!formErrors.ID_PARCEIRO_AGENTE_CARGA}
                              variant="outlined"
                            />
                          )}
                          onChange={(_: any, data: any) => {
                            onChange(data?.id || null);
                          }}
                        />
                      }
                    </>
                  )}
                />
              </Grid>

              <Grid item xs={6}>
              <Controller
                  name="ID_PARCEIRO_BROKER"
                  control={control}
                  render={({ field: { value, ref, onChange, ...field } }) => (
                    <>
                      {
                        broker && <Autocomplete
                          options={broker}
                          getOptionLabel={(option) => option.description}
                          value={
                            broker?.findLast(
                              (item: ComboBoxType) => item?.id === value
                            ) || null
                          }
                          disabled={disableAll}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Broker*"
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              error={!!formErrors.ID_PARCEIRO_BROKER}
                              variant="outlined"
                            />
                          )}
                          onChange={(_: any, data: any) => {
                            onChange(data?.id || null);
                          }}
                        />
                      }
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller 
                  name="NUM_PESO_BRUTO"
                  control={control}
                  defaultValue={formatDecimalFree(invoice?.NUM_PESO_BRUTO || "", { integerDigits: 12 }).formatted}
                  render={({ field: { value, onChange, ...restField } }) => {
                    return (
                      <TextField
                        type="text"
                        fullWidth
                        label="Gross Weight (kg)"
                        variant="outlined"
                        size="small"
                        disabled={disableAll}
                        {...restField}
                        value={value && formatDecimalFree(value, { integerDigits: 12 }).formatted}
                        error={!!formErrors.NUM_PESO_BRUTO}
                        onChange={(event) => {
                          const { raw } = handleFormatDecimalChange(event, { integerDigits: 12 }, true);
                          onChange(raw);
                        }}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="ID_REGIME"
                  control={control}
                  render={({ field: { value, ref, onChange, ...field } }) => (
                    <>
                      {
                        customRegimes && <Autocomplete
                          options={customRegimes}
                          getOptionLabel={(option) => option.description}
                          value={
                            customRegimes?.findLast(
                              (item: ComboBoxType) => item?.id === value
                            ) || null
                          }
                          disabled={disableAll}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Custom Regime*"
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              error={!!formErrors.ID_REGIME}
                              variant="outlined"
                            />
                          )}
                          onChange={(_: any, data: any) => {
                            onChange(data?.id || null);
                          }}
                        />
                      }
                    </>
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
}
