import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import { blue, red } from "@mui/material/colors";
import DataTableDefault from "../../../../../components/DataTable";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { Tooltip } from '@mui/material';
import { InvoiceItemPo } from "../../../../../types/invoiceItemPo";
import { formatNumber } from "../../../../../lib/format-number";
import dayjs from "dayjs";

interface DataTableProps {
  onDelete?: (invoicePoItem: Partial<InvoiceItemPo>) => void;
  onEdit?: (invoicePoItem: Partial<InvoiceItemPo>) => void;
  data: InvoiceItemPo[];
  mode?: "View" | "Edit"
  rowCount: number;
  [x: string]: any;
}
export default function DataTable({
  data = [],
  onDelete,
  onEdit,
  mode = "View",
  ...props
}: DataTableProps) {

  const formatIntField = (row: InvoiceItemPo, field: keyof InvoiceItemPo) => {
    const value = row[field];
    return value && +value >= 0 ? +value : "";
  }

  const columns: GridColDef[] = [
    ...(mode === "View"
      ? []
      : [
          {
            field: "Action",
            headerName: "Actions",
            headerClassName: "super-app-theme--header",
            minWidth: 90,
            flex: 1,
            renderCell: ({ row }: { row: InvoiceItemPo }) => {
              return [
                <GridActionsCellItem
                  sx={{
                    color: red[500],
                  }}
                  key={`delete-${row.ID_INVOICE_PO_ITEM}`}
                  icon={<DeleteIcon />}
                  label="Delete"
                  data-testid="delete-button"
                  onClick={() => onDelete && onDelete(row)}
                />,
                <GridActionsCellItem
                  sx={{
                    color: blue[500],
                  }}
                  key={`edit-${row.ID_INVOICE_PO_ITEM}`}
                  icon={<EditIcon />}
                  label="edit"
                  data-testid="edit-button"
                  onClick={() => onEdit && onEdit(row)}
                />,
              ];
            },
          },
        ]),
    {
      field: "NUM_LIN_PO",
      headerName: "PO Line",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "NUM_CEMB",
      headerName: "CEMB",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }) => {
        return [formatIntField(row, "NUM_CEMB")];
      }
    },
    {
      field: "NUM_PN",
      headerName: "Part Number",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 220,
      renderCell: ({ row }) => {
        return [row?.NUM_PN];
      }
    },
    {
      field: "DSC_PN",
      headerName: "PN Description",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 240,
    },
    {
      field: "DSC_SERIAL_NUMBER",
      headerName: "Serial Number(s)",
      headerClassName: "super-app-theme--header",
      width: 240,
      renderCell: ({ row }) => {
        return (
          <div style={{ 
            whiteSpace: 'normal', 
            wordBreak: 'break-word', 
            overflow: 'hidden',
            display: 'block',
            height: '56px',
            maxHeight: '56px'
          }}>
            <Tooltip 
              title={row?.DSC_SERIAL_NUMBER} 
              placement="top-start" 
              arrow
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              }}
              componentsProps={{
                tooltip: {
                  sx: {
                    maxWidth: '300px',
                    fontSize: '0.875rem',
                  },
                },
              }}
            >
              <span>{row?.DSC_SERIAL_NUMBER}</span>
            </Tooltip>
          </div>
        );
      }
    },
    {
      field: "SIGLA_PAIS_ENTREGA",
      headerName: "Country of Origin",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 140,
      renderCell: ({ row }) => {
        return [row.paisOrigem.SIGLA_PAIS];
      },
    },
    {
      field: "QTDE_PN",
      headerName: "Quantity",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }) => {
        return [formatNumber(row.QTDE_PN, 3)];
      }
    },
    {
      field: "ID_UM",
      headerName: "Unit",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: ({ row }) => {
        return [row.idUm?.SIGLA];
      }
    },
    {
      field: "CURRENCY",
      headerName: "Currency",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 80,
      renderCell: ({ row }) => {
        return [row.po?.moedaPo?.SIGLA_MOEDA];
      },
    },
    {
      field: "NUM_VLR_UNIT",
      headerName: "Unit Value",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 160,
      renderCell: ({ row }) => {
        return [formatNumber(+row.NUM_VLR_UNIT, 4)];
      }
    },
    {
      field: "VALOR_TOTAL",
      headerName: "Total Value",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 160,
      renderCell: ({ row }) => {
        return [formatNumber(+row.VALOR_TOTAL, 4)];
      }
    },
    ...(mode === "View"
      ? [
          {
            field: "DG",
            headerName: "DG",
            headerClassName: "super-app-theme--header",
            flex: 1,
            renderCell: ({ row }: { row: InvoiceItemPo }) => {
              return [+row?.poItem?.DSC_DG! === 1 ? "Yes" : "No"];
            },
          },
          {
            field: "SERVICE_LEVEL",
            headerName: "Service Level",
            headerClassName: "super-app-theme--header",
            flex: 1,
            minWidth: 120,
            renderCell: ({ row }: { row: InvoiceItemPo }) => {
              return [row.poItem?.prioridade?.DSC_PRIORIDADE];
            },
          },
          {
            field: "CENTER",
            headerName: "Center",
            headerClassName: "super-app-theme--header",
            flex: 1,
            minWidth: 80,
            renderCell: ({ row }: { row: InvoiceItemPo }) => {
              return [row.poItem?.SIGLA_CENTRO];
            },
          },
          {
            field: "DEP",
            headerName: "DEP",
            headerClassName: "super-app-theme--header",
            flex: 1,
            renderCell: ({ row }: { row: InvoiceItemPo }) => {
              return [row.poItem?.DSC_DEPOSITO];
            },
          },
          {
            field: "ADDRESS",
            headerName: "Address",
            headerClassName: "super-app-theme--header",
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }: { row: InvoiceItemPo }) => {
              const address = [
                row.poItem?.DSC_ENDERECO_ENTREGA,
                row.poItem?.DSC_CIDADE_ENTREGA,
                row.poItem?.DSC_ESTADO_ENTREGA,
              ]
                .filter((item) => !!item)
                .join(", ");
      
              return [address];
            },
          },
          {
            field: "DSC_HTS",
            headerName: "HTS Code",
            headerClassName: "super-app-theme--header",
            flex: 1,
            minWidth: 120,
            renderCell: ({ row }: { row: InvoiceItemPo }) => {
              return [row?.DSC_NCM_HTS];
            },
          },
          {
            field: "DSC_FALTA_LIC_EXPO",
            headerName: "Missing Lic EXPO",
            headerClassName: "super-app-theme--header",
            flex: 1,
            minWidth: 140,
            renderCell: ({ row }: { row: InvoiceItemPo }) => {
              return [row.DSC_FALTA_LIC_EXPO === true ? 'Yes' : 'No'];
            }
          },
          {
            field: "IMPORT_LICENSE",
            headerName: "Import License",
            headerClassName: "super-app-theme--header",
            flex: 1,
            minWidth: 120,
          },
          {
            field: "IMPORT_LICENSE_STATUS",
            headerName: "Import License Status",
            headerClassName: "super-app-theme--header",
            flex: 1,
            minWidth: 180,
          },
          {
            field: "DATA_2_CONFERENCIA",
            headerName: "Goods Receipt",
            headerClassName: "super-app-theme--header",
            flex: 1,
            minWidth: 120,
            renderCell: (params: any) => {
              return params?.row?.DATA_2_CONFERENCIA && dayjs(params?.row?.DATA_2_CONFERENCIA).format("MM/DD/YYYY HH:mm")
            }
          },
          {
            field: "QTDE_2_CONFERENCIA",
            headerName: "Received Quantity",
            headerClassName: "super-app-theme--header",
            flex: 1,
            minWidth: 180,
          },
        ]
      : []),
  ];

  return (
    <>
      <DataTableDefault
        rowId={data?.map((row: InvoiceItemPo) => row.ID_PO_ITEM)}
        data-testid="unit-data-table"
        data={data}
        columns={columns}
        {...props}
      />
    </>
  );
}
