import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import DataTableDefault from "../../../../../components/DataTable";
import { AutomaticAuthorizationScheduleProps } from "../../../../../types/automaticAuthorizationSchedule";
import { hasClaim } from "../../../../../storage/userClaims";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

interface DataTableProps {
  onEdit: (id: number | string) => void;
  onDelete: (id: number | string) => void;
  onView: (id: number | string) => void;
  data: AutomaticAuthorizationScheduleProps[];
  [x: string]: any;
}
export default function DataTable({
  onEdit,
  onDelete,
  onView,
  data,
  ...props
}: DataTableProps) {
  const renderMenus = (row: any) => {
    return [
      <GridActionsCellItem
        sx={{
          display:
            hasClaim("Shipment Authorization - Scheduler - Maintenance") ||
            hasClaim("Shipment Authorization - Scheduler - Visualization")
              ? "block"
              : "none",
        }}
        key={`view-${row.ID_AGENDA_AUTORIZACAO_EMBARQUE}`}
        icon={<VisibilityIcon />}
        label="View"
        onClick={() => onView(row.ID_AGENDA_AUTORIZACAO_EMBARQUE)}
        color="inherit"
        data-testid="view-button"
      />,
      <GridActionsCellItem
        sx={{
          display: hasClaim("Shipment Authorization - Scheduler - Maintenance")
            ? "block"
            : "none",
        }}
        key={`edit-${row.ID_AGENDA_AUTORIZACAO_EMBARQUE}`}
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={() => onEdit(row.ID_AGENDA_AUTORIZACAO_EMBARQUE)}
        color="inherit"
        data-testid="edit-button"
      />,
      <GridActionsCellItem
        sx={{
          display: hasClaim("Shipment Authorization - Scheduler - Maintenance")
            ? "block"
            : "none",
        }}
        key={`delete-${row.ID_AGENDA_AUTORIZACAO_EMBARQUE}`}
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => onDelete(row.ID_AGENDA_AUTORIZACAO_EMBARQUE)}
        color="inherit"
        data-testid="delete-button"
      />,
    ];
  };

  const columns: GridColDef[] = [
    {
      field: "HORA_AGEND",
      headerName: "Time",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `${params.row.HORA_AGEND} - ${params.row.DSC_PERIODO}`;
      },
    },
    {
      field: "AGEND_MONDAY",
      headerName: "Monday",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<Date>) =>
        params.value ? (
          <TaskAltIcon color="success" />
        ) : (
          <HighlightOffIcon fontSize="medium" />
        ),
    },
    {
      field: "AGEND_TUESDAY",
      headerName: "Tuesday",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<Date>) =>
        params.value ? (
          <TaskAltIcon color="success" />
        ) : (
          <HighlightOffIcon fontSize="medium" />
        ),
    },
    {
      field: "AGEND_WEDNESDAY",
      headerName: "Wednesday",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<Date>) =>
        params.value ? (
          <TaskAltIcon color="success" />
        ) : (
          <HighlightOffIcon fontSize="medium" />
        ),
    },
    {
      field: "AGEND_THURSDAY",
      headerName: "Thursday",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<Date>) =>
        params.value ? (
          <TaskAltIcon color="success" />
        ) : (
          <HighlightOffIcon fontSize="medium" />
        ),
    },
    {
      field: "AGEND_FRIDAY",
      headerName: "Friday",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<Date>) =>
        params.value ? (
          <TaskAltIcon color="success" />
        ) : (
          <HighlightOffIcon fontSize="medium" />
        ),
    },
    {
      field: "AGEND_SATURDAY",
      headerName: "Saturday",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<Date>) =>
        params.value ? (
          <TaskAltIcon color="success" />
        ) : (
          <HighlightOffIcon fontSize="medium" />
        ),
    },
    {
      field: "AGEND_SUNDAY",
      headerName: "Sunday",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<Date>) =>
        params.value ? (
          <TaskAltIcon color="success" />
        ) : (
          <HighlightOffIcon fontSize="medium" />
        ),
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }) => renderMenus(row),
    },
  ];
  return (
    <>
      {data.length === 0 ? (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert variant="outlined" severity="info">
            To view the data you need to select a Company - Plant and Consolidation Center
          </Alert>
        </Stack>
      ) : (
        <DataTableDefault
          rowId={data.map((row) => row.ID_AGENDA_AUTORIZACAO_EMBARQUE)}
          data-testid="custom-regime-data-table"
          data={data}
          columns={columns}
          {...props}
        />
      )}
    </>
  );
}
