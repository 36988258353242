import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { GridCloseIcon } from '@mui/x-data-grid';
import { Invoice } from '../../../../types/invoice';
import dayjs from 'dayjs';

type TrackingStatusDialogProps = {
  open: boolean;
  onClose: () => void;
  data?: Invoice['trackingNumber']
  carrier?: string;
};

const TrackingStatusDialog: FC<TrackingStatusDialogProps> = ({ open, onClose, data, carrier }) => {
  const rows = [
    { info: { label: 'Pick Up', value: data?.DSC_PICKUP }, date: { label: 'Pick Up Date', value: data?.DATA_PICKUP } },
    { info: { label: 'Status', value: data?.DSC_DELIVERED }, date: { label: 'Delivered Date', value: data?.DATA_DELIVERED } },
    { info: { label: 'Exception', value: data?.DSC_EXCEPTION }, date: { label: 'Exception Date', value: data?.DATA_EXCEPTION } },
  ]

  return (
    <Grid marginTop={4} alignItems='center'>
      <Dialog open={open} onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='md'
        style={{ padding: '10px' }}>
        <DialogTitle id="form-dialog-title" style={{ padding: 7 }}>
          <Grid container alignItems={"center"} width={"100%"}>
            <Grid style={{ paddingLeft: 10 }} sm={10}>Tracking Status</Grid>
            <Grid sm={2} container justifyContent="center">
              <IconButton
                edge="start"
                color="inherit"
                onClick={onClose}
                aria-label="close"
              >
                <GridCloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            padding: '15px 30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
          }}
        >
          <Grid container>
            <Typography fontWeight="bold" marginBottom={2}>{data?.DSC_TRACKING} - {carrier}</Typography>
            {rows.map(({ info, date }) => (
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                key={info.label}
              >
                <Grid xs={5} item container>
                  <Typography marginRight={1}>{info.label}: </Typography>
                  <Typography>{info.value ?? ''}</Typography>
                </Grid>
                <Grid xs={7} item container style={{ justifyContent: "flex-end" }}>
                  <Typography marginRight={1}>{date.label}:</Typography>
                  <Typography>{(date.value && dayjs(date.value).format('MM/DD/YYYY')) ?? ''}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 20 }}>
          <Button sx={{
            marginRight: "auto",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            color: "currentColor",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              color: "currentColor",
            },
            minWidth: 100
          }} variant="contained" onClick={onClose} color="secondary">
            Return
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default TrackingStatusDialog;
