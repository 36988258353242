import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { enqueueSnackbar } from "notistack";
import { isAxiosError } from "axios";

import InputUpload from "../../../../../../components/Input/InputUpload";
import { TriageFile } from "../../types/files";
import { getLocal } from "../../../../../../storage/auth";

type AttachmentType = {
  ID_TRIAGEM_INVOICE_ANEXO?: number;
  FILE: File;
  NOME_ARQUIVO: string;
  DSC_PATH: string;
  ID_TRIAGEM_ARQUIVO?: number;
}

interface UploadDocumentsProps {
  attachments: TriageFile[];
  id: string | number;
  onFinish: () => void;
  getFile: (param1: any, param2: any) => any;
  removeFile: (param1: any) => void;
  uploadFile: (param1: any, param2: any) => void;
  isDisabled: boolean;
  isDisabledStream?: boolean;
}

export default function UploadDocuments({
  attachments,
  onFinish,
  isDisabled,
  isDisabledStream,
  removeFile,
  getFile,
  uploadFile,
  id,
}: UploadDocumentsProps) {  
  const [userValidation, setUserValidation] = useState<{ claims: string [] }>();

  useEffect(() => {
    getTokenSession();
  }, []);

  const addInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files && event.target?.files?.length > 0) {
      const files = event.target.files;
      if (files) {
        const fileToAdd = files[0];

        if (fileToAdd.type !== "application/pdf") {
          return enqueueSnackbar("File type is not allowed. Please select a PDF file", { variant: "error"});
        }

        addFile(fileToAdd);
      }
    }
  }

  const getTokenSession = async () => {
    const getUser = (await getLocal("userInfo")) as any;
    setUserValidation(JSON.parse(getUser));
  }

  const addFile = async (fileToAdd: File) => {
    try {
      if (!id) return;

      await uploadFile(fileToAdd, +id);
      if(onFinish) onFinish();
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        enqueueSnackbar(`${error?.response?.data?.message}`, { variant: "error" });
      }
    }
  }

  const removeInput = async (index: number, attachment: AttachmentType): Promise<void> => {
    try {
      attachments.splice(index, 1);

      await removeFile(attachment?.ID_TRIAGEM_ARQUIVO || attachment?.ID_TRIAGEM_INVOICE_ANEXO);
      if(onFinish) onFinish();
    } catch (error) {
      if (isAxiosError(error)) {
        enqueueSnackbar(`${error?.response?.data?.message}`, { variant: "error" });
      }
    }
  }

  const isRemoveButtonVisible = useMemo(() => {
    return userValidation?.claims && userValidation.claims.some((claim) => {
      return ['Triage - Analysis', 'Triage - Buyer'].includes(claim);
    });
  }, [userValidation]);

  return (
    <div>
      <Typography marginBottom={2} fontWeight="bold">Files</Typography>
      <InputUpload
        onInput={addInput}
        disabled={isDisabled}
        accept="application/pdf"
        onDrop={(files: FileList) => {
          addInput({ target: { files } } as ChangeEvent<HTMLInputElement>);
        }}
      />

      <Grid container marginY={2} spacing={2}>
        {
          (attachments && attachments.length > 0) &&
          <Grid item xs={12}>
            <Table size="small">
              <TableHead>
                <TableRow>
      
                  <TableCell scope="row">
                    File Name
                  </TableCell>
                  <TableCell scope="row" />
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  attachments.map((attachment: any, index: number) => (
                    <TableRow
                      key={`invoice-table-${index}`}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 }}}
                    >
                      <TableCell scope="row">
                        {attachment.FILE?.name || attachment.NOME_ARQUIVO}
                      </TableCell>
                      <TableCell sx={{ minWidth: '120px' }} align="right">
                        <Button
                          sx={{ minWidth: 0 }}
                          color="primary"
                          onClick={() => getFile(attachment?.ID_TRIAGEM_ARQUIVO || attachment?.ID_TRIAGEM_INVOICE_ANEXO, { format: 'online' })
                            .then((buffer: Buffer) => {
                              const blob = new Blob([buffer], { type: 'application/pdf' })
                              const url = window.URL.createObjectURL(blob)
                              window.open(url)
                            })
                            .catch(() => {
                              enqueueSnackbar('Could not open file', { variant: 'error' })
                            })
                          }
                          disabled={isDisabledStream}
                          disableRipple={true}
                        >
                          <VisibilityIcon />
                        </Button>
                        <Button
                          sx={{ minWidth: 0, color: 'rgba(0, 0, 0, 0.4)' }}
                          color="primary"
                          onClick={() => getFile(attachment?.ID_TRIAGEM_ARQUIVO || attachment?.ID_TRIAGEM_INVOICE_ANEXO, { format: 'attachment' })
                            .then((buffer: Buffer) => {
                              const blob = new Blob([buffer], { type: 'application/pdf' })
                              const url = window.URL.createObjectURL(blob)
                              const tempLink = document.createElement('a');
                              tempLink.href = url;
                              tempLink.setAttribute('download', '');
                              tempLink.style.display = 'none';
                              document.body.appendChild(tempLink);
                              tempLink.click();
                              document.body.removeChild(tempLink);
                            })
                            .catch(() => {
                              enqueueSnackbar('Could not open file', { variant: 'error' })
                            })
                          }
                          disabled={isDisabledStream}
                          disableRipple={true}
                        >
                          <FileDownloadIcon />
                        </Button>
                        {
                          isRemoveButtonVisible && <Button
                            sx={{ minWidth: 0 }}
                            color="error"
                            onClick={() => removeInput(index, attachment)}
                            disabled={isDisabled}
                            data-testid="btn-delete-icon"
                          >
                            <DeleteIcon />
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </Grid>
        }
      </Grid>
    </div>
  )
}