import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import {
  Alert, Autocomplete,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { PickupZone } from "../../../../../types/pickupZone";

let pickupZoneScheme = yup.object({
  SIGLA: yup.string().required().max(3),
  // ORIGEM: yup.string().required().max(30),
  ID_TIPO_EMBARQUE: yup.string().required().max(30),
  DESCRICAO: yup.string().required().max(100),
  ATIVO: yup.boolean().notRequired().default(true),
});

interface PickupZoneFormProps {
  pickupZone: PickupZone;
  onSubmit: (pickupZone: PickupZone) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly?: boolean;
}

const OriginOptions = [
  {
    value: "DOMESTIC",
    label: "DOMESTIC",
  },
  {
    value: "INTERNATIONAL",
    label: "INTERNATIONAL",
  },
]

export default function PickupZoneForm({
  pickupZone,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  controlEdit,
  readOnly
}: PickupZoneFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(pickupZoneScheme),
  });

  useEffect(() => {
    reset(pickupZone);
  }, [pickupZone, reset]);

  const handleFormSubmit = (newPickupZone: any) => {
    // console.log('newPickupZone', newPickupZone)
    onSubmit(newPickupZone);
  };

  const handleCancel = () => {
    reset({ SIGLA: "", ID_TIPO_EMBARQUE: "", DESCRICAO: "", ATIVO: true });
    onCancel();
  };

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="pickupZone-form"
    >
      {errors.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        data-testid="search"
        container
        marginTop={1}
        border={1}
        padding={2}
        spacing={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >
        <Grid container spacing={2} marginBottom={2}>
          <Grid item md={2} xs={4}>
            <TextField
              fullWidth
              label="Abbreviation"
              variant="outlined"
              size="small"
              disabled={controlEdit.isInUse || readOnly}
              {...register("SIGLA")}
              error={!!formErrors.SIGLA}
              inputProps={{ maxLength: 3, 'data-testid': 'abbreviation-input' }}
            />
          </Grid>
          <Grid item md={8} xs={8}>
            {/*<TextField*/}
            {/*  data-testid="origin-input"*/}
            {/*  fullWidth*/}
            {/*  label="Origin"*/}
            {/*  variant="outlined"*/}
            {/*  disabled={controlEdit.isInUse || readOnly}*/}
            {/*  size="small"*/}
            {/*  {...register("ORIGEM")}*/}
            {/*  error={!!formErrors.ORIGEM}*/}
            {/*  inputProps={{ maxLength: 30 }}*/}
            {/*/>*/}
            <Controller
                defaultValue={undefined}
                control={control}
                render={({ field: { value, ref, onChange, ...field } }) => (
                    <Autocomplete
                        options={OriginOptions || []}
                        getOptionLabel={(option) => option.label}
                        disabled={readOnly}
                        value={
                            OriginOptions?.findLast((item) => item?.value === value) ||
                            null
                        }
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                {...field}
                                label="Origin"
                                size="small"
                                inputRef={ref}
                                error={!!formErrors.ID_TIPO_EMBARQUE}
                                disabled={readOnly}
                                variant="outlined"
                                data-testid='ID_TIPO_EMBARQUE-input'
                            />
                        )}
                        onChange={(_: any, data: any) => {
                          onChange(data?.value || null);
                        }}
                    />
                )}
                name="ID_TIPO_EMBARQUE"
            />
          </Grid>
          <Grid item md={8} xs={8}>
            <TextField
              fullWidth
              label="Description"
              variant="outlined"
              disabled={controlEdit.isInUse || readOnly}
              size="small"
              {...register("DESCRICAO")}
              error={!!formErrors.DESCRICAO}
              inputProps={{ maxLength: 100, 'data-testid': 'description-input' }}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              render={({ field: { value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!value}
                      disabled={readOnly}
                      inputProps={{ "aria-label": "controlled" }}
                      {...register("ATIVO")}
                    />
                  }
                  labelPlacement="start"
                  label="Status"
                />
              )}
              name="ATIVO"
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" marginTop={2} spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={isLoading}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          {!readOnly ? (
              <Grid item style={{ marginLeft: 16 }}>
                <Button
                    variant="contained"
                    disabled={isLoading}
                    type="submit"
                    data-testid="submit-button"
                >
                  Confirm
                </Button>
              </Grid>
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
}
