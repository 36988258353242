import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Chip } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import DataTableDefault from "../../../../../components/DataTable";
import { CarrierProps } from "../../../../../types/carrier";
import { hasClaim } from "../../../../../storage/userClaims";

interface DataTableProps {
  onEdit: (id: number | string) => void;
  onDelete: (id: number) => void;
  onView: (id: number | string) => void;
  data: CarrierProps[];
  [x: string]: any;
}
export default function DataTable({
  onEdit,
  onDelete,
  onView,
  data,
  ...props
}: DataTableProps) {
  const renderMenus = (row: any) => {
    return [
      <GridActionsCellItem
        sx={{
          display:
            hasClaim("Carrier - Maintenance") ||
            hasClaim("Carrier - Visualization")
              ? "block"
              : "none",
        }}
        key={`view-${row.ID_TRANSPORTADOR}`}
        icon={<VisibilityIcon />}
        label="View"
        onClick={() => onView(row.ID_TRANSPORTADOR)}
        color="inherit"
        data-testid="view-button"
      />,
      <GridActionsCellItem
        sx={{
          display: hasClaim("Carrier - Maintenance") ? "block" : "none",
        }}
        key={`edit-${row.ID_TRANSPORTADOR}`}
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={() => onEdit(row.ID_TRANSPORTADOR)}
        color="inherit"
        data-testid="edit-button"
      />,
      <GridActionsCellItem
        sx={{
          display: hasClaim("Carrier - Maintenance") ? "block" : "none",
        }}
        key={`delete-${row.ID_TRANSPORTADOR}`}
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => onDelete(row.ID_TRANSPORTADOR)}
        color="inherit"
        data-testid="delete-button"
      />,
    ];
  };

  const columns: GridColDef[] = [
    {
      field: "SIGLA_TRANSPORTADOR",
      headerName: "Carrier",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 300,
      renderCell: (params: GridRenderCellParams<any>) => {
        return `${params.row.SIGLA_TRANSPORTADOR} - ${params.row.NOME_TRANSPORTADOR}`;
      },
    },
    {
      field: "ID_MODAL_TRANSPORTE",
      headerName: "Transportation Mode",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "ATIVO",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Date>) =>
        params.value ? (
          <Chip label="Active" color="primary" />
        ) : (
          <Chip label="Inactive" variant="outlined" />
        ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 160,
      getActions: ({ row }) => renderMenus(row),
    },
  ];
  return (
    <>
      <DataTableDefault
        rowId={data.map((row) => row.ID_TRANSPORTADOR)}
        data-testid="custom-regime-data-table"
        data={data}
        columns={columns}
        {...props}
      />
    </>
  );
}
