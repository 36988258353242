import {createContext, useEffect, useState, ReactNode, useContext, useMemo} from 'react'
import { findTranslations } from '../../../../services/translation'

const defaultProvider = {
  translations: null,
  translationsFields: [],
}

const TranslationContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

const TranslationProvider = ({ children }: Props) => {
  const [translations, setTranslations] = useState();
  const [translationsFields, setTranslationsFields] = useState();

  useEffect(() => {
    findTranslations().then((response) => {
      setTranslations(response);
      let translationItemFields = {};
      Object.values(response).forEach((translationItem) => {
        translationItemFields = { ...translationItemFields, ...translationItem };
      })
      setTranslationsFields(translationItemFields);
    })
  }, [])

  const values = useMemo(() => ({
    translations,
    translationsFields,
  }), [translations, translationsFields]);

  return (
      <TranslationContext.Provider value={values}>
        {children}
      </TranslationContext.Provider>
  );
}

const useTranslation = () => useContext(TranslationContext)

export { TranslationContext, TranslationProvider, useTranslation }
