import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import { CustomChannelProps } from "../../types/customChannel";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
}

const entity = "customChannels";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string
): { data: CustomChannelProps[]; meta: List; isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<CustomChannelProps[]>([
    {} as CustomChannelProps,
  ]);
  const [meta, setMeta] = useState<List>({} as List);
  useEffect(() => {
    if (token) {
      (async function () {
        setIsLoading(true);
        const response = await dataTableUtils.findPaginate(params);
        const {total, data, currentPage, totalPages, limit} = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
        setIsLoading(false)
      })();
    }
  }, [params, token]);

  return { data, meta, isLoading };
};

export const update = async (
  id: number | string,
  currency?: CustomChannelProps
): Promise<CustomChannelProps> => {
  const token = getToken();
  const { data } = await api.patch(`/${entity}/${id}`, currency, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};
export const deleteCustomChannel = async (
  id: number | string
): Promise<CustomChannelProps> => {
  const token = getToken();
  const { data } = await api.delete(`/${entity}/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const isInUse = async (id: number | string): Promise<any> => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/isInUse/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const create = async (currency: any): Promise<any> => {
  const token = getToken();
  const { data } = await api.post(`/${entity}`, currency, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: "Id", value: "ID_CANAL" },
    { label: "Description", value: "DESCRICAO" },
    { label: "EDI Code", value: "CODIGO_EDI" },
    {
      label: "Status",
      value: (row: any) => (row.ATIVO ? "Active" : "Inactive"),
    },
  ]
  await dataTableUtils.exportAll({params, xlsxData, filename: "CustomChannel"});
};
