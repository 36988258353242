import GoogdsTriage from ".";
import GoodsTriageDetails from "./goodsTriageDetails";
import GoodsTriageLog from "./log";

export const goodsTriageRoutes = [
  {
    path: "/goods-triage",
    element: <GoogdsTriage />
  },
  {
    path: "/goods-triage/log/:id",
    element: <GoodsTriageLog />,
  },
  {
    path: "/goods-triage/details/:id",
    element: <GoodsTriageDetails />
  }
]
