import {
  Button,
  Grid,
  Link,
  Typography,
} from "@mui/material";

export default function Operational() {
  return (
    <Grid marginTop={4}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h5">Operational</Typography>
        </Grid>
      </Grid>
      <Grid>
        <Link href='/invoice'>
          <Button>add invoice</Button>
        </Link>
      </Grid>
    </Grid>
  );
}
