import React from 'react';
import { CardContent, Typography, Grid, Divider } from '@mui/material';

function InfoCard({ data, consolidationCenters }: any) {

  const consolidationCenter = consolidationCenters?.find((item: any) => item?.ID_CENTRO_CONSOLIDADOR === data?.ID_CENTRO_CONSOLIDADOR);
  return (
    <CardContent style={{ background: '#f2f2f2' }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">WR:</Typography>
          <Typography variant="body2" color="textSecondary">{data?.NUM_WAREHOUSES?.join(', ')}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">Modal:</Typography>
          <Typography variant="body2" color="textSecondary">{data?.MODALS?.join(', ')}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">Invoices:</Typography>
          <Typography variant="body2" color="textSecondary">{data?.INVOICES?.join(', ')}</Typography>
        </Grid>
        
        <Divider flexItem variant="fullWidth" style={{ width: '100%', marginLeft: 10, marginTop: 10 }} />
        
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">Material Type:</Typography>
          <Typography variant="body2" color="textSecondary">{data?.TIPO_MATERIAIS?.join([', '])}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">DC:</Typography>
          <Typography variant="body2" color="textSecondary">{consolidationCenter?.pais?.SIGLA_PAIS || ''} - {consolidationCenter?.NOME}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">Regime:</Typography>
          <Typography variant="body2" color="textSecondary">{data?.REGIMES?.join(', ')}</Typography>
        </Grid>

        <Divider flexItem variant="fullWidth" style={{ width: '100%', marginLeft: 10, marginTop: 10 }} />

        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">Government Authority:</Typography>
          <Typography variant="body2" color="textSecondary">{data?.ORGAO_ANUENTES.join(', ')}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">Incoterm:</Typography>
          <Typography variant="body2" color="textSecondary">{data?.INCOTERMS?.join([', '])}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">Gross Weight:</Typography>
          <Typography variant="body2" color="textSecondary">{data?.NUM_PESO_BRUTO}</Typography>
        </Grid>

        <Divider flexItem variant="fullWidth" style={{ width: '100%', marginLeft: 10, marginTop: 10 }} />

        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">Service Level:</Typography>
          <Typography variant="body2" color="textSecondary">{data?.PRIORIDADES?.join([', '])}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" color="textSecondary">On Hand Date:</Typography>
          <Typography variant="body2" color="textSecondary">{data?.DATA_ON_HAND.split('T')[0]}</Typography>
        </Grid>
      </Grid>
    </CardContent>
  );
}

export default InfoCard;
