import { cloneElement } from "react";
import Reports from ".";
import { CustomReportProvider } from "./hooks/customReportData.hook";
import { FieldsToHideProvider } from "./hooks/fieldsToHide.hook";
import { TranslationProvider } from "./hooks/translation.hook";
import SendVisibilities from "./sendVisibilities";
import { useLocation } from "react-router-dom";

const RouteWithReload = ({ children }) => {
  const location = useLocation();

  return cloneElement(children, { key: location.pathname });
};

export const reportRoutes = [
  {
    path: "/reports/operational-pipeline",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={1}>
              <Reports ID_RELATORIO={1} title='Reports: Operational - Pipeline'/>
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/operational-discrepancy",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={2}>
              <Reports ID_RELATORIO={2} title='Reports: Operational - Discrepancy'/>
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/operational-pending",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={3}>
              <Reports ID_RELATORIO={3} title='Reports: Operational - Pending'/>
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/operational-missing",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={4}>
              <Reports ID_RELATORIO={4} title='Reports: Operational - Missing'/>
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/operational-receiving-control",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={5}>
              <Reports ID_RELATORIO={5} title='Reports: Operational - Receiving Control'/>
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/customs-audit",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={6}>
              <Reports ID_RELATORIO={6} title='Reports: Operational - Customs Audit'/>
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/operational-missing-entry-form",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={7}>
              <Reports ID_RELATORIO={7} title='Reports: Operational - Missing Entry Form'/>
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/invoice-migo",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={8}>
              <Reports ID_RELATORIO={8} title='Reports: Operational - Invoice MIGO'/>
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/triage-picking-list",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={10}>
              <Reports ID_RELATORIO={10} title='Reports: Triage - Picking List'/>
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/triage-pipeline",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={9}>
              <Reports ID_RELATORIO={9} title='Reports: Triage - Pipeline'/>
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/inventory",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={12}>
              <Reports ID_RELATORIO={12} title='Reports: Inventory'/>
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/general",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={11}>
              <Reports ID_RELATORIO={11} title='Reports: General'/>
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: `/reports/send-visibilities`,
    element: (
      <RouteWithReload>
        <SendVisibilities />
      </RouteWithReload>
    )
  }
];
