import { Autocomplete, Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { SearchParams, TriageStatus } from "../../../types/triage";
import { Triage, TriageCards, openStatuses } from "../../../types/triage";
import { useList as useCarriers } from "../../../services/carrier"
import { getCentersWithCompaniesCombo } from "../../../services/centers"
import CardPipelineTriage from "../goodsTriage/CardPipeline";
import DashboardTriagesTable from "./DashboardTriagesTable";
import { getTriages, getTriagesCount } from "../../../services/triage";
import { ComboBox } from "../../../types/comboBox";
import { InvoiceType } from "../../../types/invoice";
import { getToken } from "../../../storage/auth";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { ComboBoxType } from "../../../types/general";
import { useSearchParams } from "react-router-dom";
import { useSetStateWrapper } from "../../utils/wrappers";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  url: string;
  param: string;
}

const InitialStatuses: TriageCards[] = openStatuses.map((status) => {
  return {
    status,
    total: 0
  }
});

export const parseToURLSearchParams = (data: object) =>
  new URLSearchParams(
    Object.entries(data)
      .filter(([, value]) => !!value)
      .map(([key, value]) => [key, JSON.stringify(value)])
  );

export const parseToSearchParams = (data: URLSearchParams): Partial<SearchParams> => {
  try {
    return Object.fromEntries(Array.from(data.entries()).map(([key, value]) => [key, JSON.parse(value)]));
  } catch {
    return {};
  }
}

export default function DashboardTriage() {
  const [statuses, setStatuses] = useState<TriageCards[]>(InitialStatuses);
  const [triages, setTriages] = useState<Triage[]>([]);
  const [triagesCount, setTriagesCount] = useState<{status: TriageStatus, total: number}[]>([]);
  const [token, setToken] = useState<string>("");
  const [invoiceTypes, setInvoiceTypes] = useState([
    { id: InvoiceType.TRIAGE_DOMESTIC, description: InvoiceType.TRIAGE_DOMESTIC },
    { id: InvoiceType.TRIAGE_INTERNATIONAL, description: InvoiceType.TRIAGE_INTERNATIONAL },
  ]);
  const [centers, setCenters] = useState<ComboBox[]>([]);
  const [defaultParams, setDefaultParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [triageParams, setTriageParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);
  

  const [reset, setReset] = useState<boolean>(false);

  const { data: carriers } = useCarriers(defaultParams, token, false);

  const [urlSearchParams, setURLSearchParams] = useSearchParams();
  const [searchParams, setSearchParamsOriginal] = useState<SearchParams>({
    ...parseToSearchParams(urlSearchParams)
  } as SearchParams);

  const setSearchParams = useSetStateWrapper(setSearchParamsOriginal, (newSearchParams) => {
    setURLSearchParams(parseToURLSearchParams(newSearchParams));
  });

  const loadData = async () => {
    getTriagesCount(+searchParams.ID_CENTRO)
      .then((response) => {
        setTriagesCount(response);
      });
    getTriages({ ...searchParams, ...triageParams })
      .then((response) => {
        setTriages(response);
      });    
  };

  const loadTriages = async () => {
    const params = { ...searchParams, ...triageParams };

    getTriages(params)
      .then((response) => {
        const dataMock: any = {};
        response?.forEach((item: Triage) => {
          dataMock[item?.DSC_WAREHOUSE] = item;
        });
        setTriages(Object.values(dataMock));
      });   
  }

  const handleChangeSearchParam = (key: string) => (e: any) => {
    setSearchParams({ ...searchParams, [key]: e.target.checked !== undefined ? e.target.checked : e.target.value })
  };

  const loadToken = async () => {
    const getTokens = (await getToken()) as any;
    setToken(getTokens);
  };

  const loadCenters = async () => {
    const rawData = await getCentersWithCompaniesCombo();
    const data = [...rawData.filter(({ blocked }) => !blocked)];
    setCenters(data);

    const currentParams = { ...searchParams };
    currentParams.ID_CENTRO = searchParams.ID_CENTRO ?? String(data[0].id);

    setSearchParams(currentParams);
  }

  const searchRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    loadToken();
    loadCenters();
  }, []);

  useEffect(() => {
    if (searchParams.ID_CENTRO) {
      loadData();
    }
  }, [searchParams]);

  useEffect(() => {
    loadTriages();
  }, [triageParams]);

  const onTriageStatusCardClick = (status: TriageStatus) => {
    const currentParams = { ...searchParams };
    currentParams.ID_STATUS_TRIAGE = status;
    setSearchParams(currentParams);
  }

  const isLoadingData = useMemo(() => {
    return (centers === undefined || centers[0] === undefined) || invoiceTypes === undefined;
  }, [centers, invoiceTypes]);

  const countTotal = useMemo(() => {
    return triagesCount
      .filter(item => !searchParams?.ID_STATUS_TRIAGE || item.status === searchParams?.ID_STATUS_TRIAGE)
      .reduce((acc, item) => acc += item.total, 0);
  }, [triagesCount, searchParams]);


  return isLoadingData ? (
    <LoadingOverlay isLoading={true} />
  ) : (
    <div id="dashboard">
      <Typography fontSize={21} style={{ marginTop: 30 }}>
        <strong>
          Goods Triage
        </strong>
      </Typography>
      <Grid container marginTop={4} spacing={1}>
        {
          Object.values(openStatuses).map((status, index) => {
            const count = triagesCount.find((value) => value.status === status)?.total || 0;
            return (
              <Grid key={index} item xs={12} sm={6} md>
                <CardPipelineTriage
                  status={status}
                  total={count}
                  selectedStatus={searchParams.ID_STATUS_TRIAGE}
                  onSelectStatus={onTriageStatusCardClick}
                />
              </Grid>
            );
          })
        }
      </Grid>

      <Box
        display="flex"
        gap={2}
        flexDirection="row"
        style={{
          marginTop: 65,
        }}
      >
        <Grid item xs={12} sm={6} style={{ width: '300vh' }}>
          <Autocomplete
            options={centers}
            getOptionLabel={(option: ComboBoxType) =>
              `${option.description}`
            }
            size='small'
            renderInput={(params) => (
              <TextField
                {...params}
                label="Plant"
                size="small"
                variant="outlined"
              />
            )}
            style={{ width: '100%' }}
            value={
              centers
                .find(item => (searchParams.ID_CENTRO || centers[0].id).toString() === item.id.toString())
            }
            onChange={(_, data: ComboBoxType) => {
              if (!data || !data?.id) return;
              const currentParams = { ...searchParams };
              currentParams.ID_CENTRO = String(data.id);
              setSearchParams({ ...currentParams });
            }}
          />
        </Grid>

        <Grid item xs={12} sm={3} style={{ width: '100%' }}>
          {!reset && (
            <Autocomplete
              options={invoiceTypes}
              getOptionLabel={(option: ComboBoxType) => option && `${option.description}`}
              size='small'
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Triage Type"
                  size="small"
                  variant="outlined"
                />
              )}
              style={{ width: '100%' }}
              value={invoiceTypes.find(item => searchParams?.ID_TIPO_INVOICE === item?.id)}
              onChange={(_, data: ComboBoxType) => {
                const currentParams = { ...searchParams };
                delete currentParams.ID_TIPO_INVOICE;
                if (data?.id) currentParams.ID_TIPO_INVOICE = String(data?.id);

                setSearchParams({ ...currentParams });
              }}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={4} style={{ width: '100%' }}>
          <TextField
            fullWidth
            defaultValue={searchParams.SEARCH ?? ''}
            inputRef={searchRef}
            label="Search"
            size="small"
            InputLabelProps={{
              shrink: true
            }}
            style={{
              maxWidth: "100%"
            }}
            onChange={(element) => {
              const currentParams = { ...searchParams };
              if (element.target.value === "") {
                currentParams.SEARCH = undefined;
                setSearchParams({ ...currentParams });
                return;
              }
              delete currentParams.ID_STATUS_TRIAGE;
              currentParams.SEARCH = String(element.target.value);
              setSearchParams({ ...currentParams });
            }}
          />
        </Grid>

        <Grid item xs={12} sm={1} style={{ width: '100%' }}>
          <Button
            variant="outlined"
            onClick={async () => {
              const currentParams = { 
                ID_CENTRO: String(centers?.[0].id),
                ID_TIPO_INVOICE: '',
                ID_STATUS_TRIAGE: '',
                SEARCH: '',
              } as SearchParams;
              searchRef.current!.value = '';
              await setReset(true);
              await setSearchParams(currentParams);
              setReset(false);
            }}
            data-testid="search-button"
          >
            Reset Filter
          </Button>
        </Grid>
      </Box>
      
      <Box style={{ marginTop: 30 }}>
        <DashboardTriagesTable
          data={triages}
          count={countTotal}
          handleRequest={setTriageParams}
          params={triageParams}
          carriers={carriers}
          searchParams={searchParams}
          onChangeSearchParam={handleChangeSearchParam}
        />
      </Box>
    </div>
  );
}
