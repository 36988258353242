import { isAxiosError } from "axios";
import { useEffect, useState } from "react";

import { Download } from "@mui/icons-material";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@mui/material";

import Search from "../../../components/Search";
import { useList as useListCenter } from "../../../services/centers";
import { useList as useListCompanies } from "../../../services/companies";
import { create, createCopy, deleteShipment, exportAll, update, useList, isInUse, getCenters } from "../../../services/shipment";
import { getLocal, getToken } from "../../../storage/auth";
import { hasClaim } from "../../../storage/userClaims";
import { ShipmentProps } from "../../../types/shipment";
import Copy from "../../../components/Copy";
import DataTable from "./components/DataTable";
import CustomRegimeForm from "./components/Form";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import { useSnackbar } from "notistack";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
}

export default function ShipmentInstructions() {
  const { enqueueSnackbar } = useSnackbar();
  const [shipment, setShipment] = useState<ShipmentProps>({
    ATIVO: true,
  } as ShipmentProps);

  const [shipmentId, setShipmentId] = useState<string | number>();
  const [isSaving, setIsSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCopyOpen, setModalCopyOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userValidation, setUserValidation] = useState<string>();
  const [token, setToken] = useState<string>();
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = (getLocal("userInfo")) as any;
    setToken(getTokens);
    setUserValidation(JSON.parse(getUser));
  };

  useEffect(() => {
    getTokenSession();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [errors, setErrors] = useState<string[]>();
  const { data, meta, isLoading } = useList(params, token);
  const { combo: dataCenterCombo, comboShipment } = useListCenter(params, true);
  const [controlEdit, setControlEdit] = useState({
    loading: false,
    isInUse: false,
  });

  const { data: dataCompanies } = useListCompanies(undefined, token);

  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };

  const handleEditShipment = async (id: string | number) => {
    setErrors([]);
    setReadOnly(false);
    const editCustomRegime = data.find((item: ShipmentProps) => item.ID_INSTRUCAO_EMBARQUE === id);

    if (editCustomRegime) {
      setShipment({
        ...editCustomRegime,
        ID_CENTRO: editCustomRegime.GC_ID_CENTRO,
      });
      setModalOpen(true);
    }

    try {
      setControlEdit({
        loading: true,
        isInUse: true,
      });
      const { inUse } = await isInUse(id);
      setControlEdit({
        loading: false,
        isInUse: inUse,
      });
    } catch (error) {
      enqueueSnackbar("It's not posible to edit, try again later", {
        variant: "error",
      });
      setControlEdit({
        loading: false,
        isInUse: true,
      });
    }
  };

  const handleDeleteCustomRegimeConfirmation = (id: string | number) => {
    setShipmentId(id);
    setOpenDeleteModal(true);
  };

  const handleDeleteCustomRegime = async () => {
    if (shipmentId) {
      try {
        setIsSaving(true);
        await deleteShipment(shipmentId);
        enqueueSnackbar("Shipment Instruction deleted successful", {
          variant: "success",
        });
        setParams({ ...params });
      } catch (err: any) {
        if (err.response.status) {
          enqueueSnackbar("Shipment Instruction is being used", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(err.response.data.message[0], { variant: "error" });
        }
      }
      setIsSaving(false);
      setOpenDeleteModal(false);
    }
  };

  const handleFormSubmit = async (newShipment: ShipmentProps) => {
    try {
      setIsSaving(true);
      if (newShipment.ID_INSTRUCAO_EMBARQUE) {
        await update(newShipment.ID_INSTRUCAO_EMBARQUE, newShipment);
        enqueueSnackbar("Shipment Instruction updated successful", {
          variant: "success",
        });
      } else {
        await create(newShipment);
        enqueueSnackbar("Shipment Instruction created successful", {
          variant: "success",
        });
      }
      setShipment({ ATIVO: true } as ShipmentProps);
      setParams({ ...params });
      setModalOpen(false);
      setIsSaving(false);
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setErrors([err.response?.data?.message]);
      }
      setIsSaving(false);
    }
    setIsSaving(false);
  };

  const handleCopy = async (copyCustomRegime: any[], newCustomRegimes: { ID_CENTRO_DESTINATION: any; }) => {
    try {
      setIsSaving(true);

      const copyToValue: ShipmentProps[]  = copyCustomRegime.map((row) => {
        return {
          DSC_INSTRUCAO: row.DSC_INSTRUCAO,
          ID_CENTRO: newCustomRegimes.ID_CENTRO_DESTINATION,
          ATIVO: row.ATIVO,
        } as unknown as ShipmentProps;
      });

      await createCopy(copyToValue);
      setParams({ ...params });
      setIsSaving(false);
      setModalCopyOpen(false);
      setParams({ ...params });
      enqueueSnackbar("Shipment Instruction copied successful", {
        variant: "success",
      });
    } catch (error: any) {
      setErrors([error]);
    }
  };

  const exportCustomsRegimes = async () => {
    try {
      await exportAll(params);
    } catch (err) {}
  };

  const handleCancelEditCustomRegime = () => {
    setShipment({} as ShipmentProps);
    setErrors([]);
    setModalOpen(false);
    setControlEdit({
      loading: false,
      isInUse: false,
    });
  };

  const handleCancelCopyCustomRegime = () => {
    setParams({
      ...params,
      limit: 200,
    });
    setModalCopyOpen(false);
  };

  const onViewCustomRegime = (id: string | number) => {
    setErrors([]);
    setReadOnly(true);
    const viewCustomRegime = data.find((item: ShipmentProps) => item.ID_INSTRUCAO_EMBARQUE === id);

    if (viewCustomRegime) {
      setShipment({
        ...viewCustomRegime,
        ID_CENTRO: viewCustomRegime.GC_ID_CENTRO,
      });
      setModalOpen(true);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "DSC_INSTRUCAO",
      headerName: "Description",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "ATIVO",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Date>) =>
          params.value ? (
              <Chip label="Active" color="primary" />
          ) : (
              <Chip label="Inactive" variant="outlined" />
          ),
    },
  ];

  return (
    <Grid marginTop={4}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h5">Shipment Instruction</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" marginTop={1}>
        <Grid item xs={2}>
          {!modalOpen ? (
            <Grid item xs={2}>
              <Button
                sx={{
                  display: hasClaim("Shipment Instruction - Maintenance")
                    ? "block"
                    : "none",
                }}
                variant="contained"
                onClick={() => {
                  setControlEdit({
                    loading: false,
                    isInUse: false,
                  });
                  setErrors([])
                  setModalOpen(true);
                }}
                fullWidth
              >
                New
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {modalOpen ? (
        <Grid container alignItems="center">
          <CustomRegimeForm
            onSubmit={handleFormSubmit}
            shipment={shipment}
            center={dataCenterCombo}
            companies={dataCompanies}
            onCancel={handleCancelEditCustomRegime}
            isLoading={isSaving}
            errors={errors}
            controlEdit={controlEdit}
            readOnly={readOnly}
          />
        </Grid>
      ) : null}
      <Search centerPlant={dataCenterCombo} onChange={handleRequest} />
      <Grid
        container
        marginTop={2}
        marginBottom={2}
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Grid item xs={3} justifyContent="center">
          <Button
            style={{ width: "100%", backgroundColor: "#d3d3d3", color: "#000" }}
            onClick={() => {
              setModalCopyOpen(true);
            }}
          >
            Copy
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="text"
            onClick={exportCustomsRegimes}
            startIcon={<Download />}
          >
            Export XLSX
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <DataTable
          loading={isLoading}
          data={data}
          center={dataCenterCombo}
          companies={dataCompanies}
          userValidation={userValidation}
          onDelete={handleDeleteCustomRegimeConfirmation}
          onEdit={handleEditShipment}
          onView={onViewCustomRegime}
          rowCount={meta.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          paginationModel={paginationModel}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>
      <Dialog
        onClose={handleCancelEditCustomRegime}
        open={modalCopyOpen}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle>Copy Company - Plant</DialogTitle>
        <DialogContent dividers={true}>
          <Copy
            dataCenterCombo={dataCenterCombo}
            handleCopy={handleCopy}
            comboRegime={comboShipment}
            data={data}
            companies={dataCompanies}
            onCancel={handleCancelCopyCustomRegime}
            isLoading={isSaving}
            errors={errors}

            idKey="shipmentinstructionid"
            columns={columns}
            getCenters={getCenters}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete Shipment Instruction
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete this Shipment Instruction?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button onClick={handleDeleteCustomRegime}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
