import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DataTable from "./table/tableDiscrepancy";
import { enqueueSnackbar } from "notistack";
import { closeInvoiceDicrepancyById, createInvoiceDiscrepancy, deleteInvoiceDiscrepancyById } from "../../../../services/invoiceDiscrepancy";
import { getDiscrepancies } from "../../../../services/discrepancy";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import { Invoice, InvoicesSections } from "../../../../types/invoice";

interface InvoiceDiscrepancyProps {
  invoice: Invoice | null;
  onUpdate: (sectionNewStatus?: { [key: string]: boolean }) => void;
  isDisabled: boolean;
}
const InvoiceDiscrepancy = ({ invoice, onUpdate, isDisabled }: InvoiceDiscrepancyProps) => {
  const [discrepancies, setDiscrepancies] = useState<any[]>([]);
  const [invoiceDiscrepancy, setInvoiceDiscrepancy] = useState<any>({
    ID_DIVERGENCIA: '',
    DSC_OBSERVACAO: '',
    NUM_PO: '',
  });
  const [invoiceDiscrepancyId, setInvoiceDiscrepancyId] = useState<number | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openClosingModal, setOpenClosingModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });

  const fetchDiscrepancies = async () => {
    try {
      const data = await getDiscrepancies();
      setDiscrepancies(data)
    } catch (error) {
      enqueueSnackbar("Could not load discrepancies", {
        variant: "error",
      });
    }
  }

  const handleDeleteDiscrepanciesConfirmation = async () => {
    if (invoiceDiscrepancyId) {
      try {
        setIsSaving(true);
        await deleteInvoiceDiscrepancyById(invoiceDiscrepancyId);
        enqueueSnackbar("Discrepancy deleted successful", {
          variant: "success",
        });
        setParams({ ...params });
        onUpdate({ [InvoicesSections.DISCREPANCY]: true });
      } catch (err: any) {
        if (err?.response?.status) {
          enqueueSnackbar("Discrepancy is being used", { variant: "error" });
        } else {
          enqueueSnackbar(err.response.data.message[0], { variant: "error" });
        }
      }
      setIsSaving(false);
      setOpenDeleteModal(false);
    }
  };

  const handleCreateConfirmation = async () => {
    if (invoiceDiscrepancy?.ID_DIVERGENCIA && invoiceDiscrepancy?.DSC_OBSERVACAO) {
      setIsSaving(true);
      try {
        const { NUM_PO, ...invoiceDiscrepancyData } = invoiceDiscrepancy;
        if (invoiceDiscrepancy?.NUM_PO) {
          await createInvoiceDiscrepancy({
            ...invoiceDiscrepancyData,
            NUM_PO_MANUAL: invoiceDiscrepancy.NUM_PO,
            ID_INVOICE: invoice!.ID_INVOICE
          })
        } else {
          await createInvoiceDiscrepancy({
            ...invoiceDiscrepancyData,
            NUM_PO_MANUAL: invoice!.po?.NUMERO_PO,
            ID_INVOICE: invoice!.ID_INVOICE
          })
        }

        enqueueSnackbar("Discrepancy created successfuly", {
          variant: "success",
        });
        setOpenCreateModal(false);
        setParams({ ...params });
        onUpdate({ [InvoicesSections.DISCREPANCY]: true });
      } catch (err: any) {
        if (err?.response?.status) {
          enqueueSnackbar("Discrepancy is being used", { variant: "error" });
        } else if (err?.response?.data?.message?.length) {
          enqueueSnackbar(err.response.data.message[0], { variant: "error" });
        } else {
          enqueueSnackbar("Could not create discrepancy", { variant: "error" });
        }
      } finally {
        setIsSaving(false);
      }
    } else {
      enqueueSnackbar("Complete form before saving", { variant: "warning" });
    }
  };

  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };

  const handleDeleteDiscrepancies = (id: number) => {
    setInvoiceDiscrepancyId(id);
    setOpenDeleteModal(true);
  };

  const handleChangeField = (field: string) => (e: any) => {
    setInvoiceDiscrepancy((prevState: any) => ({ ...prevState, [field]: e.target.value }))
  }

  const handleCloseInvoiceDiscrepancy = (id: number) => {
    setInvoiceDiscrepancyId(id);
    setOpenClosingModal(true);
  }

  const handleCloseInvoiceDiscrepancyConfirmation = async () => {
    if (invoiceDiscrepancyId) {
      setIsSaving(true)
      try {
        await closeInvoiceDicrepancyById(invoiceDiscrepancyId);
        onUpdate({ [InvoicesSections.DISCREPANCY]: true });
        enqueueSnackbar('Invoice Discrepancy closed successfully', { variant: 'success' })
      } catch (error) {
        enqueueSnackbar('Could not close invoice discrepancy', { variant: 'error' })
      } finally {
        setIsSaving(false)
        setOpenClosingModal(false)
      }
    }
  }

  useEffect(() => {
    fetchDiscrepancies();
  }, []);

  return (
    <>
      <LoadingOverlay isLoading={isSaving} />
      <Paper
        variant="outlined"
        sx={{ marginTop: 3, paddingX: 2, paddingBottom: 3 }}
      >
        <Grid>
          <Button
            sx={{ width: 120, marginY: '5px' }}
            onClick={() => setOpenCreateModal(true)}
            variant="outlined"
            disabled={isDisabled}
            style={{
              display: isDisabled ? 'none' : 'inline-flex',
              alignItems: 'center',
            }}
          >
            New
          </Button>
        </Grid>
        <Grid>
          <DataTable
            data={invoice?.invoiceDiscrepancies!}
            onDelete={handleDeleteDiscrepancies}
            onCloseInvoiceDiscrepancy={handleCloseInvoiceDiscrepancy}
            rowCount={invoice?.invoiceDiscrepancies?.length || 0}
            onPaginationModelChange={(e: any) => {
              setPaginationModel(e);
              handleRequest({ page: e.page + 1, limit: e.pageSize });
            }}
            paginationModel={paginationModel}
            pagination
            initialState={{
              pagination: { paginationModel: { pageSize: params.limit } },
            }}
            pageSizeOptions={[1, 10, 25, 50, 100]}
            // paginationMode="server"
            isDisabled={isDisabled}
          />
        </Grid>
      </Paper>

      <Dialog
        open={openClosingModal}
        onClose={() => setOpenClosingModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Close Discrepancy</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to close this Discrepancy?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenClosingModal(false)}>Cancel</Button>
          <Button
            data-testid="confirmation-button"
            onClick={handleCloseInvoiceDiscrepancyConfirmation}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Discrepancy</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete this Discrepancy?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button
            data-testid="confirmation-button"
            onClick={handleDeleteDiscrepanciesConfirmation}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          Manual Discrepancy
          <Button component="span" onClick={() => setOpenCreateModal(false)}>
            <CloseIcon />
          </Button>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <Grid>
              PO:
              {invoice?.ID_PO ? (
                <Typography
                sx={{
                  color: "#5b9dc7",
                  fontWeight: "bold",
                }}
              >
                {invoice?.po?.NUMERO_PO}
              </Typography>
              ) : (
                <TextField
                  sx={{ width: 540 }}
                  size="small"
                  variant="outlined"
                  onChange={handleChangeField('NUM_PO')}
                  inputProps={{ maxLength: 12 }}
                />
              )}
            </Grid>
            <Grid>Discrepancy</Grid>
            <Grid>
              <Select
                labelId="id-divergencyselect-label"
                id="id-divergencyselect"
                sx={{ width: 540 }}
                value={invoiceDiscrepancy?.ID_DIVERGENCIA}
                onChange={handleChangeField('ID_DIVERGENCIA')}
              >
                {discrepancies.map((discrepancy, i) => (
                  <MenuItem key={i} value={discrepancy.id}>{discrepancy.description}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid>Notes</Grid>
            <Grid>
              <TextField
                inputProps={{
                  maxLength: 500,
                }}
                sx={{ width: 540 }}
                size="small"
                variant="outlined"
                rows={4}
                multiline
                onChange={handleChangeField('DSC_OBSERVACAO')}
              />
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            onClick={() => setOpenCreateModal(false)}
            variant="contained"
            color="primary"
            sx={{ width: 120 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            data-testid="confirmation-button"
            onClick={handleCreateConfirmation}
            sx={{ width: 120 }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InvoiceDiscrepancy;
