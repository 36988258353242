/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { List } from "../../types/resquest";
import { getToken } from "../../storage/auth";
import {SelectedAutoDiscrepancy, SelectedInvoiceType, SlaService} from "../../types/sla";
import {Center} from "../../types/center";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
  status?: boolean;
}

const entity = "sla";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
  params?: IList,
  token?: string,
):
  | Promise<{ data: SlaService[]; meta: List; isLoading: boolean }>
  | any => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<SlaService[]>([{} as SlaService]);
  const [slaId, setSlaId] = useState<string | number>();
  const [meta, setMeta] = useState<List>({} as List);

  const [center, setCenter] = useState<Center[]>([]);
  const [invoiceType, setInvoiceType] = useState<SelectedInvoiceType[]>([]);
  const [autoDiscrepancy, setDiscrepancy] = useState<SelectedAutoDiscrepancy[]>([]);
  const [serviceLevels, setServiceLevels] = useState<Center[]>([]);

  const render = () => {
    if (token)  return "paginate";
  };

  useEffect(() => {
    if (render() === "paginate") {
      setIsLoading(true);

      getApiData(`/${entity}/findPaginate`, params)
        .then((response) => {
          const { total, data, currentPage, totalPages, limit } = response;
          setData(data);
          setMeta((prevState) => ({ ...prevState, total, totalPages, limit, currentPage }));
          setIsLoading(false)
        });

      getApiData(`/centers/getCentersWithCompaniesCombo`).then(response => setCenter(response));
      getApiData(`/invoiceTypes/getCombo`).then(response => setInvoiceType(response));
      getApiData(`/discrepancies/getComboAuto`).then(response => setDiscrepancy(response));
      getApiData(`/serviceLevels/getCombo`).then(response => setServiceLevels(response));
    }
  }, [params, token]);

  return { data, meta, isLoading, slaId, setSlaId, center, invoiceType, autoDiscrepancy, serviceLevels }
};

function getApiData(url: string, params?: IList) {
  const token = getToken();

  return api.get(url, {
    params,
    headers: {authorization: `Bearer ${token}`},
  }).then((response) => response.data);
}

export const findSlaById = async (id: string | number) => {
  return await getApiData(`/${entity}/${id}`);
};



export const update = async (
  id: number | string,
  sla?: SlaService
): Promise<SlaService> => {
  const token = getToken();
  const { data } = await api.patch(`/${entity}/${id}`, sla, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const create = async (sla?: SlaService): Promise<SlaService> => {
  const token = getToken();
  const { data } = await api.post(`/${entity}`, sla, {
    headers: { authorization: `Bearer ${token}` },
  });
  return data;
};

export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: "Company", value: "GE_SIGLA_EMPRESA" },
    { label: "Center Abreviation", value: "GC_DSC_SIGLA" },
    { label: "Center Name", value: "GC_NOME_CENTRO" },

    { label: "INVOICE TYPE", value: "ID_TIPO_INVOICE" },
    { label: "STATUS", value: "ID_STATUS_INVOICE" },
    { label: "RULE", value: "GD_DSC_DIVERGENCIA" },
    { label: "MODAL", value: "ID_MODAL" },
    { label: "SERVICE LEVEL", value: "GP_DSC_PRIORIDADE" },
    { label: "FREIGHT INCOTERM", value: "ID_FRETE_INCO" },
    { label: "MAIN SLA", value: "SLA_PRINCIPAL" },
    { label: "SLA SECONDARY", value: "SLA_SECUNDARIO" },
  ];

  await dataTableUtils.exportAll({params, xlsxData, filename: "SLA"});
};

export const findEntityIsInUse = async (id: string | number): Promise<boolean> => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/isInUse/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return data.inUse;
};

export const getCenters = async (center: { id: any; }) => {
  if(!center?.id) return;
  const { data } =  await dataTableUtils.findPaginate({
    ID_CENTRO: center.id,
    discrepancy: true,
    limit: 10,
    page: 1
  });
  return data;
}

export const createCopy = async (currency: { ID_TIPO_INVOICE: any; ID_STATUS_INVOICE: any; ID_MODAL: any; ID_FRETE_INCO: any; SLA_PRINCIPAL: any; SLA_SECUNDARIO: any; ID_PRIORIDADE: any; DSC_PENDENCIA: any; ID_DIVERGENCIA: any; TODAS_DIVERGENCIAS: any; ID_CENTRO: any; }[]) => {
  const { data } = await api.post(`/${entity}/copy`, currency );

  return data;
};