import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";

import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { DiscrepancyProps } from "../../../../../types/discrepancy";

// const useStyles = styled(() => {
//   customTextField: {
//     "& .MuiInputBase-input": {
//       height: "20px",
//     },
//   },
// });

const useStyles = styled("div")({
    customTextField: {
        "& .MuiInputBase-input": {
        height: "20px",
        },
    },
});

let discrepancyPropsScheme = yup.object({
  DSC_DIVERGENCIA: yup.string().max(30).required(),
  DSC_MENSAGEM: yup.string().max(200).required(),
  ATIVO: yup.boolean().notRequired(),
});

interface DiscrepancyPropsFormProps {
  discrepancy: DiscrepancyProps;
  onSubmit: (unitMeasurement: DiscrepancyProps) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly?: boolean;
}

export default function DiscrepancyPropsForm({
  discrepancy,
  onSubmit,
  onCancel,
  isLoading = false,
  errors = [],
  controlEdit,
  readOnly
}: DiscrepancyPropsFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(discrepancyPropsScheme),
  });

  const classes = useStyles;

  useEffect(() => {
      reset(discrepancy);
  }, [discrepancy, reset]);

  const handleFormSubmit = (newDiscrepancy: any) => {
    const newData = {
      ID_DIVERGENCIA: discrepancy.ID_DIVERGENCIA,
      ...newDiscrepancy,
      ID_GRUPO_DIVERGENCIA: "MANUAL",
      DIVERG_AUTO: false,
    };
    onSubmit(newData);
  };

  const handleCancel = () => {
    onCancel();
  };
  return (
    <form
      style={{ width: "100%" }}
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="unitMeasurement-form"
    >
      {errors.map((error) => (
        <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
          {error}
        </Alert>
      ))}
      <Grid
        container
        spacing={2}
        marginTop={1}
        border={1}
        padding={2}
        borderColor={"#7b7b7b"}
        borderRadius={2}
        alignItems="center"
      >
        <Grid marginBottom={2}>
          <Grid item md={9} xs={9}>
            <Controller
              defaultValue={discrepancy.DSC_DIVERGENCIA || undefined}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  type="text"
                  disabled={controlEdit.isInUse || readOnly}
                  label="Description"
                  size="small"
                  variant="outlined"
                  defaultValue={value}
                  error={!!formErrors.DSC_DIVERGENCIA}
                  InputLabelProps={{ shrink: !!value }}
                  {...register("DSC_DIVERGENCIA")}
                  inputProps={{ maxLength: 30, 'data-testid': 'Description-textfield' }}
                />
              )}
              name="DSC_DIVERGENCIA"
            />
            <Grid marginTop={2} width={600}>
              <Controller
                defaultValue={discrepancy.DSC_MENSAGEM || undefined}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    disabled={controlEdit.isInUse || readOnly}
                    type="text"
                    label="Message"
                    size="medium"
                    variant="outlined"
                    defaultValue={value}
                    error={!!formErrors.DSC_MENSAGEM}
                    InputLabelProps={{ shrink: !!value }}
                    {...register("DSC_MENSAGEM")}
                    inputProps={{ maxLength: 200, 'data-testid': 'message-textfield' }}
                    className={classes.customTextField}
                    multiline
                    maxRows={4}
                  />
                )}
                name="DSC_MENSAGEM"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid>
          <Grid marginTop={4} item xs={12}>
            <Controller
              defaultValue={
                discrepancy.ATIVO === undefined ? true : discrepancy.ATIVO
              }
              render={({ field: { value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!value}
                      disabled={readOnly}
                      inputProps={{ "aria-label": "controlled" }}
                      {...register("ATIVO")}
                    />
                  }
                  labelPlacement="start"
                  label="Status"
                />
              )}
              name="ATIVO"
              control={control}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" marginTop={2} spacing={2}>
          <Grid>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={isLoading}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          {!readOnly ? (
          <Grid marginLeft={2}>
            <Button
              variant="contained"
              disabled={isLoading}
              type="submit"
              data-testid="submit-button"
            >
              Confirm
            </Button>
          </Grid>
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
}
