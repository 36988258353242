export const downloadFile = (file: File|null) => {
  if (!file) return;

  const fileURL = URL.createObjectURL(file);
  const tempLink = document.createElement('a');
  tempLink.href = fileURL;
  tempLink.setAttribute('download', file.name);
  tempLink.style.display = 'none';
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  URL.revokeObjectURL(fileURL);
};

export const downloadFileFromUrl = (baseUrl: string, filename?: string) => {
  if (!baseUrl) return;

  const url = `${process.env.CDN_BASE_URL}${baseUrl}`;

  const tempLink = document.createElement('a');

  tempLink.href = url;
  tempLink.setAttribute('download', filename ?? '');
  tempLink.style.display = 'none';
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};

export const viewFileInNewTab = (file: File|null) => {
  if (!file) return;

  const fileURL = URL.createObjectURL(file);
  const tempLink = document.createElement('a');
  tempLink.href = fileURL;
  tempLink.target = '_blank';
  tempLink.style.display = 'none';
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};

export const viewFileInNewTabFromUrl = (baseUrl: string) => {
  if (!baseUrl) return;

  const url = `${process.env.CDN_BASE_URL}${baseUrl}`;

  const tempLink = document.createElement('a');
  tempLink.href = url;
  tempLink.target = '_blank';
  tempLink.style.display = 'none';
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};