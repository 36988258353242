import { useEffect, useState } from "react";
import { List } from "../../types/resquest";
import { api } from "../../lib/api";
import { PickupZone } from "../../types/pickupZone";
import { getToken } from "../../storage/auth";
import {DataTableUtils} from "../../components/DataTable/DataTableUtils";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
}

const entity = "pickupZones";
const dataTableUtils = new DataTableUtils(entity);

export const useList = (
    params?: IList,
    token?: string
): { data: PickupZone[]; meta: List; error: Error; isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<PickupZone[]>([]);
  const [meta, setMeta] = useState<List>({} as List);
  const [error] = useState<Error>({} as Error);

  useEffect(() => {
    if (token) {
      (async function () {
        setIsLoading(true);
        const response = await dataTableUtils.findPaginate(params);
        const {total, data, currentPage, totalPages, limit} = response.data;
        setData(data);
        setMeta((prevState) => ({
          ...prevState,
          total,
          totalPages,
          limit,
          currentPage,
        }));
        setIsLoading(false)
      })();
    }
  }, [params, token]);

  return {data, meta, error, isLoading};
};

export const findById = async (id: number) => {
  return await api.get(`/${entity}/${id}`);
};

export const isInUse = async (id: number | string): Promise<any> => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/isInUse/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const update = async (
  id: number,
  pickupZone?: PickupZone
): Promise<PickupZone> => {
  const token = getToken();

  const { data } = await api.patch(`/${entity}/${id}`, pickupZone, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};
export const deletePickupZone = async (
  id: number | string
): Promise<boolean> => {
  const token = getToken();
  const { data } = await api.delete(`/${entity}/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const create = async (pickupZone?: PickupZone): Promise<PickupZone> => {
  const token = getToken();

  const { data } = await api.post(`/${entity}`, pickupZone, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const exportAll = async (params: any) => {
  let xlsxData = [
    { label: "Id", value: "ID_PICK_UP_ZONE" },
    { label: "Abbreviation", value: "SIGLA" },
    { label: "Description", value: "DESCRICAO" },
    { label: "Origin", value: "ORIGEM" },
    {
      label: "Status",
      value: (row: any) => (row.ATIVO ? "Active" : "Inactive"),
    },
  ]
  await dataTableUtils.exportAll({params, xlsxData, filename: "PickupZone"});
};
