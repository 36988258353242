import { Download } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { isAxiosError } from "axios";
import { useEffect, useState } from "react";

import Search from "../../../components/Search";
import {
  create,
  deleteServiceLevel,
  exportAll,
  update,
  useList,
  isInUse,
} from "../../../services/serviceLevel";
import { getLocal, getToken } from "../../../storage/auth";
import { ServiceLevel } from "../../../types/serviceLevel";
import DataTable from "./components/DataTable";
import ServiceLevelForm from "./components/ServiceLevelForm";
import { hasClaim } from "../../../storage/userClaims";
import { useSnackbar } from "notistack";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
}

export default function ServiceLevels() {
  const { enqueueSnackbar } = useSnackbar();
  const [serviceLevel, setServiceLevel] = useState<ServiceLevel>({
    ATIVO: true,
  } as ServiceLevel);
  const [serviceLevelId, setServiceLevelId] = useState<string | number>();
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [token, setToken] = useState<string>();
  const [userValidation, setUserValidation] = useState<string>();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [controlEdit, setControlEdit] = useState({
    loading: false,
    isInUse: false,
  });

  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = (getLocal("userInfo")) as any;
    setToken(getTokens);
    setUserValidation(JSON.parse(getUser));
  };

  useEffect(() => {
    getTokenSession();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const [errors, setErrors] = useState<string[]>();
  const { data, meta, isLoading } = useList(params, token);

  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
  };

  const handleEditServiceLevel = async (id: string | number) => {
    setErrors([])
    setReadOnly(false);

    const editServiceLevel = data.find((item) => item.ID_PRIORIDADE === id);

    if (editServiceLevel) {
      const { ID_PRIORIDADE, DSC_PRIORIDADE, CODIGO_SAP, ATIVO } = editServiceLevel;
      setServiceLevel({ ID_PRIORIDADE, DSC_PRIORIDADE, CODIGO_SAP, ATIVO });
      setModalOpen(true);
    }

    try {
      setControlEdit({
        loading: true,
        isInUse: true,
      });
      const { inUse } = await isInUse(id);
      setControlEdit({
        loading: false,
        isInUse: inUse,
      });
    } catch (error) {
      enqueueSnackbar("It's not posible to edit, try again later", {
        variant: "error",
      });
      setControlEdit({
        loading: false,
        isInUse: true,
      });
    }
  };

  const handleDeleteServiceLevelConfirmation = (id: string | number) => {
    setServiceLevelId(id);
    setOpenDeleteModal(true);
  };

  const handleDeleteServiceLevel = async () => {
    if (serviceLevelId) {
      try {
        setIsSaving(true);
        await deleteServiceLevel(serviceLevelId);
        enqueueSnackbar("Service Level deleted successful", {
          variant: "success",
        });
        setParams({ ...params });
      } catch (err: any) {
        if (err.response.status) {
          enqueueSnackbar("Service Level is being used", { variant: "error" });
        } else {
          enqueueSnackbar(err.response.data.message[0], { variant: "error" });
        }
      }
      setIsSaving(false);
      setOpenDeleteModal(false);
    }
  };

  const handleFormSubmit = async (newServiceLevel: ServiceLevel) => {
    const serviceLevelDto = {
      DSC_PRIORIDADE: newServiceLevel.DSC_PRIORIDADE,
      CODIGO_SAP: newServiceLevel.CODIGO_SAP,
      ATIVO: newServiceLevel.ATIVO,
    };

    try {
      setIsSaving(true);
      if (newServiceLevel.ID_PRIORIDADE) {
        await update(newServiceLevel.ID_PRIORIDADE, serviceLevelDto);
        enqueueSnackbar("Service Level updated successful", {
          variant: "success",
        });
      } else {
        await create(serviceLevelDto);
        enqueueSnackbar("Service Level created successful", {
          variant: "success",
        });
      }
      setServiceLevel({ ATIVO: true } as ServiceLevel);
      setParams({ ...params });
      setModalOpen(false);
      setIsSaving(false);
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setErrors([err.response?.data?.message]);
      }
      setIsSaving(false);
    }
    setIsSaving(false);
  };

  const exportServiceLevel = async () => {
    try {
      await exportAll(params);
    } catch (err) {}
  };

  const handleCancelEditServiceLevel = () => {
    setServiceLevel({} as ServiceLevel);
    setErrors([]);
    setModalOpen(false);
    setControlEdit({
      loading: false,
      isInUse: false,
    });
  };

  const onViewServiceLevel = (id: string | number) => {
    setErrors([])
    setReadOnly(true);

    const editServiceLevel = data.find((item) => item.ID_PRIORIDADE === id);

    if (editServiceLevel) {
      const { ID_PRIORIDADE, DSC_PRIORIDADE, CODIGO_SAP, ATIVO } = editServiceLevel;
      setServiceLevel({ ID_PRIORIDADE, DSC_PRIORIDADE, CODIGO_SAP, ATIVO });
      setModalOpen(true);
    }
  };

  return (
    <Grid marginTop={4}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={9}>
          <Typography variant="h5">Service Level</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" marginTop={1}>
        <Grid item xs={2}>
          {!modalOpen ? (
            <Grid item xs={2}>
              <Button
                variant="contained"
                onClick={() => {
                  setControlEdit({
                    loading: false,
                    isInUse: false,
                  });
                  setErrors([])
                  setModalOpen(true);
                }}
                fullWidth
                sx={{
                  display: hasClaim("Service Level - Maintenance")
                    ? "block"
                    : "none",
                }}
                data-testid="new-button"
              >
                New
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {modalOpen ? (
        <Grid container alignItems="center">
          <ServiceLevelForm
            onSubmit={handleFormSubmit}
            serviceLevel={serviceLevel}
            onCancel={handleCancelEditServiceLevel}
            isLoading={isSaving}
            errors={errors}
            controlEdit={controlEdit}
            readOnly={readOnly}
          />
        </Grid>
      ) : null}
      <Search onChange={handleRequest} />
      <Grid container marginTop={2} justifyContent="flex-end">
        <Button
          variant="text"
          onClick={exportServiceLevel}
          startIcon={<Download />}
        >
          Export XLSX
        </Button>
      </Grid>
      <Grid>
        <DataTable
          loading={isLoading}
          data={data}
          userValidation={userValidation}
          onDeleteServiceLevel={handleDeleteServiceLevelConfirmation}
          onEditServiceLevel={handleEditServiceLevel}
          onViewServiceLevel={onViewServiceLevel}
          rowCount={meta.total || 0}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            handleRequest({ page: e.page + 1, limit: e.pageSize });
          }}
          paginationModel={paginationModel}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: params.limit } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
        />
      </Grid>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Service Level</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete this Service Level?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
          <Button onClick={handleDeleteServiceLevel} data-testid="delete-modal-confirm">Confirm</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
